import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DkuMatTooltipModule } from '@shared/components/dku-mat-tooltip/dku-mat-tooltip.module';
import { WarningIconComponent } from './warning-icon.component';

@NgModule({
    declarations: [
        WarningIconComponent
    ],
    imports: [
        CommonModule,
        DkuMatTooltipModule
    ],
    exports: [
        WarningIconComponent
    ]
})
export class WarningIconModule { }
