import { Component, Inject, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ChartConfigurationCopyPasteService } from '@features/simple-report/services';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';

@Component({
    selector: 'chart-options-menu',
    templateUrl: './chart-options-menu.component.html',
    styleUrls: ['./chart-options-menu.component.less']
})
export class ChartOptionsMenuComponent {
 
    @Input()
    chart: ChartDef;

    @Input()
    chartValid: boolean;

    @Input()
    isInInsight: boolean;

    @Input()
    version: string;
    
    @Input()
    canCopyToClipboard: boolean = false;

    @Input()
    canPasteFromClipboard: boolean = false;

    @Input()
    canImageDownload: boolean = false;

    @Input()
    canExcelDownload: boolean = false;

    @Input()
    canDelete: boolean = false;

    @Input()
    canDuplicate: boolean = false;

    @Input()
    isOnThumbnail: boolean = false;

    @Output()
    onDeleteChart: EventEmitter<void> = new EventEmitter();

    @Output()
    onDuplicateChart: EventEmitter<void> = new EventEmitter();

    @Output()
    onImageDownload: EventEmitter<void> = new EventEmitter();

    @Output()
    onExcelDownload: EventEmitter<void> = new EventEmitter();

    @Output()
    onChartPasteSuccess: EventEmitter<{ chartDef: any, pasteAfter: boolean }> = new EventEmitter();

    public osIcon: string;

    constructor(
        private chartConfigurationCopyPasteService: ChartConfigurationCopyPasteService,
        @Inject('DetectUtils') private detectUtilsService: any) {
            this.osIcon = this.detectUtilsService.getOS() === 'macos' ? '⌘' : 'Ctrl';
    }


    pasteFromClipboard(): void {
        this.chartConfigurationCopyPasteService.pasteChartFromClipboard({ valid: this.chartValid, isInInsight: this.isInInsight }, undefined).then((res: { chartDef: any, pasteAfter: boolean }) => {
            this.onChartPasteSuccess.emit(res);
        });
    }

    copyToClipboard(): void {
        this.chartConfigurationCopyPasteService.copyChartToClipboard(this.chart, this.version);
    }

    delete(): void {
        this.onDeleteChart.emit() 
    }

    duplicate(): void {
        this.onDuplicateChart.emit();
    }

    downloadAsAnExcel(): void {
        this.onExcelDownload.emit();
    }
    downloadAsAnImage(): void {
        this.onImageDownload.emit();
    }
}
