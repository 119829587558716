export namespace DatasetNotebookService {
    /** 
     * Translated from enum com.dataiku.dip.server.services.DatasetNotebookService$NotebookType
     * Via: com.dataiku.dip.server.services.DatasetNotebookService$SimpleNotebook
     */
    export enum NotebookType {
        SQL = "SQL",
        JUPYTER = "JUPYTER",
    }
    /** 
     * Translated from class com.dataiku.dip.server.services.DatasetNotebookService$SimpleNotebook
     * Via: com.dataiku.dip.server.services.NavigatorService$DatasetFullInfo
     */
    export interface _SimpleNotebook {
        analyzedDataset: string;
        associatedRecipe: string;
        connection: string;
        creator: string;
        id: string;
        language: string;
        lastModified: number;
        name: string;
        projectKey: string;
        type: DatasetNotebookService.NotebookType;
    }
    
    export type SimpleNotebook = _SimpleNotebook;
}