import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Abstract2SampTestCard, Variable, Chi2IndTestCard } from 'src/generated-sources';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { CardWizardService } from '../../../card-wizard/card-wizard.service';
import { CardWizardVariable } from '@features/eda/card-models';
import { Observable } from 'rxjs';


@UntilDestroy()
@Component({
    selector: 'chi2-ind-test-card-config',
    templateUrl: './chi2-ind-test-card-config.component.html',
    styleUrls: [
        '../../../../shared-styles/card-wizard.less',
        './chi2-ind-test-card-config.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class Chi2IndTestCardConfigComponent implements OnInit, OnChanges, OnDestroy {
    @Input() params: Chi2IndTestCard;
    @Output() paramsChange = new EventEmitter<Chi2IndTestCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    allVariables$: Observable<CardWizardVariable[]>;

    configForm = this.fb.group({
        xColumn: this.fb.control(null, [Validators.required]),
        yColumn: this.fb.control(null, [Validators.required]),
        maxValuesX: this.fb.control(null),
        maxValuesY: this.fb.control(null)
    });

    constructor(
        private fb: UntypedFormBuilder,
        private cardWizardService: CardWizardService
    ) {
        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((formValue) => this.paramsChange.emit({
                ...this.params,
                xColumn: formValue.xColumn,
                yColumn: formValue.yColumn,
                maxValuesX: formValue.maxValuesX,
                maxValuesY: formValue.maxValuesY
            }));

        this.configForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
    }

    ngOnInit() {
        this.allVariables$ = this.cardWizardService.availableVariables(this.params.type);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.configForm.patchValue({
                xColumn: this.params.xColumn,
                yColumn: this.params.yColumn,
                maxValuesX: this.params.maxValuesX,
                maxValuesY: this.params.maxValuesY
            });
        }
    }

    ngOnDestroy() {

    }
}
