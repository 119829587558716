import { uniqueId } from 'lodash';
import { BehaviorSubject } from 'rxjs';

export abstract class ChartZoomControlInstance {
    public id: string;
    public canZoomIn$ = new BehaviorSubject<boolean>(true);
    public canZoomOut$ = new BehaviorSubject<boolean>(false);
    public canResetZoom$ = new BehaviorSubject<boolean>(false);
    public hasResetButton$ = new BehaviorSubject<boolean>(true);

    constructor() {
        this.id = uniqueId();
    }

    abstract init(...args: any): void;
    abstract zoomIn(...args: any): void;
    abstract zoomOut(...args: any): void;
    abstract resetZoom(...args: any): void;
}
