import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ReactiveInputModule } from '../../reactive-input';
import { ResetButtonModule } from '../../reset-button';
import { TextFormattingFormModule } from '../text-formatting-form';
import { TableFormattingFormComponent } from './table-formatting-form.component';

@NgModule({
    declarations: [
        TableFormattingFormComponent
    ],
    imports: [
        ReactiveFormsModule,
        ReactiveInputModule,
        TextFormattingFormModule,
        CommonModule,
        ResetButtonModule
    ],
    exports: [
        TableFormattingFormComponent
    ]
})
export class TableFormattingFormModule { }
