import { Injectable } from '@angular/core';
import { GridComponentOption } from 'echarts';

class LegendContext {
    item: {
        label: string,
        color: string,
        index: number
    };
    echartInstance: any;
    mouseEnter: boolean;
    transparency: number;
}

@Injectable({
    providedIn: 'root'
})
export class EChartsLegendsService {
    constructor() { }

    /**
     * Adjusts legends in frame
     */
    adjustLegends($legendZone: any, legendPlacement: string, grid: GridComponentOption | Array<GridComponentOption>) {
        // If the legend placement was INNER_TOP_LEFT or INNER_BOTTOM_*, we need to display it

        let top: number;
        let bottom: number;
        let right: number;
        let left: number;

        if (Array.isArray(grid)) {
            const gridTop = grid[0].top as number;
            const gridBottom = grid[0].bottom as number;
            const gridLeft = grid[0].left as number;
            const gridRight = grid[0].right as number;

            let highestTop = gridTop ? gridTop : 0;
            top = gridTop ? gridTop : 0;
            bottom = gridBottom ? gridBottom : 0;
            left = gridLeft ? gridLeft : 0;
            right = gridRight ? gridRight : 0;

            grid.forEach((item) => {
                const itemTop = item.top as number;

                if (itemTop) {
                    top = Math.min(top, itemTop);
                    if (itemTop >= highestTop) {
                        highestTop = itemTop;
                        if (item.bottom) {
                            bottom = item.bottom as number;
                        }
                    }
                }

                left = item.left ? Math.max(left, item.left as number) : left;
                right = item.right ? Math.max(right, item.right as number) : right;
            });
        } else {
            top = grid.top as number;
            bottom = grid.bottom as number;
            left = grid.left as number;
            right = grid.right as number;
        }

        const setMaxSize = () => {
            $legendZone
                .css('max-height', 'calc(100% - ' + (top + bottom) + 'px)')
                .css('max-width', '25%')
                .css('visibility', 'visible');
        };

        switch (legendPlacement) {
            case 'INNER_TOP_LEFT':
                $legendZone.css('left', left).css('top', top);
                setMaxSize();
                break;
            case 'INNER_TOP_RIGHT':
                $legendZone.css('right', right).css('top', top);
                setMaxSize();
                break;
            case 'INNER_BOTTOM_LEFT':
                $legendZone.css('left', left).css('bottom', bottom);
                setMaxSize();
                break;
            case 'INNER_BOTTOM_RIGHT':
                $legendZone.css('right', right).css('bottom', bottom);
                setMaxSize();
                break;
            default:
                break;
        }
    }

    /**
     * Used to hook e-chart behaviors on legend's mouseover/mouseout (or other interactions if needed)
     * It is called when the legend and the chart are already drawn
     * @param {Object} echartInstance
     * @param {number} transparency     Alpha of the current color settings
     * @param {Legend[]} legends
     * @param {function} onLegendHover Callback to be called when hovering the legend. Called with three parameters: the label of the item lauching the event, the echart instance and a boolean valued to true if the event is a mouseover, false if it's mouseout.
     */
    setupLegends(echartInstance: any, transparency: number, legends: Array<any>, onLegendHover: (legendContext: LegendContext) => void) {
        const mapActions = (item: any) => {
            // legend can be split in 2 with a dummy item between named 'separator'
            if (!item.separator) {
                const itemLabel: string = item.label.label;
                const legendContext: LegendContext = {
                    item: {
                        label: itemLabel,
                        color: item.color,
                        index: item.id
                    },
                    echartInstance,
                    mouseEnter: true,
                    transparency
                };

                item.focusFn = () => {
                    if (echartInstance) {
                        const highlight = onLegendHover;
                        highlight(legendContext);
                    }
                };

                item.unfocusFn = () => {
                    if (echartInstance) {
                        const downplay = onLegendHover;
                        downplay({ ...legendContext, mouseEnter: false });
                    }
                };
            }
            return item;
        };


        const updatedLegends = (legends || []).map((legend) => {
            legend.items = (legend.items || []).map(mapActions);
            return legend;
        });

        return updatedLegends;
    }
}
