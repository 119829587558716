import type { LabelingAnswer } from './labeling-answer';
import type { LabelingMetrics } from './labeling-metrics';
import type { _LabelingMetrics } from './labeling-metrics';

/** 
 * Translated from class com.dataiku.dip.labeling.LabelingTaskStats
 * Via: UIModel annotation in the class hierarchy
 */
export interface _LabelingTaskStats {
    globalRecordsStat: LabelingTaskStats.RecordsStat;
    perAnnotatorRecordsStats: { [key in string]: LabelingTaskStats.LabelerStats };
    perClassCount: { [key in string]: number };
}

export type LabelingTaskStats = _LabelingTaskStats;

export namespace LabelingTaskStats {
    /** 
     * Translated from class com.dataiku.dip.labeling.LabelingTaskStats$LabelerStats
     * Via: com.dataiku.dip.labeling.LabelingTaskStats
     */
    export interface _LabelerStats {
        metrics: LabelingMetrics;
        perAnswerStatusCount: { [key in LabelingAnswer.AnswerStatus]: number };
    }
    
    export type LabelerStats = _LabelerStats;
    
    /** 
     * Translated from enum com.dataiku.dip.labeling.LabelingTaskStats$RecordStatus
     * Via: com.dataiku.dip.labeling.LabelingTaskStats$RecordsStat
     */
    export enum RecordStatus {
        INVALID = "INVALID",
        TO_LABEL = "TO_LABEL",
        PARTIALLY_LABELED = "PARTIALLY_LABELED",
        READY_FOR_REVIEW = "READY_FOR_REVIEW",
        REVIEWED = "REVIEWED",
    }
    /** 
     * Translated from class com.dataiku.dip.labeling.LabelingTaskStats$RecordsStat
     * Via: com.dataiku.dip.labeling.LabelingTaskStats
     */
    export interface _RecordsStat {
        perRecordStatusCount: { [key in LabelingTaskStats.RecordStatus]: number };
    }
    
    export type RecordsStat = _RecordsStat;
}