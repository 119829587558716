import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { isEqual, isNil } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { D3V3, D3V3Zoom } from '../interfaces';
import { ChartZoomLoaderService } from '../services';
import { ChartZoomControlInstance } from './chart-zoom-control-instance.model';

class ZoomOptions {
    scale: number;
    translate: number[];
    xRange?: number[] | null;
    yRange?: number[] | null;
}

export class D3V3ZoomControlInstance extends ChartZoomControlInstance {
    private logger: any;
    private d3Zoom: D3V3Zoom | null;
    private defaultScale: number | null = 1;
    private defaultTranslate: [number, number] | null = [0, 0];
    private scaleMin = 1;
    private scaleMax = 100;
    private scaleStep = 0.1;
    private zoomEndTimeout: NodeJS.Timeout;
    private zoomEndDelay = 250;
    private zooming = false;
    private active: boolean = true;
    private enabled: boolean = true;
    private forceUpdate = true;

    //  Creating vars which hold scale and translate values between 'zoom' and 'zoomend' events
    private transientScale: number;
    private transientTranslate: [number, number];

    //  Creating vars which hold previous scale and translate values
    private currentScale: number;
    private currentTranslate: [number, number];

    private zoomOptions$ = new BehaviorSubject<ZoomOptions | null>(null);

    constructor(
        private loggerFactory: any,
        private chartZoomLoaderService: ChartZoomLoaderService,
        private d3: D3V3,
        private d3Canvas: any,
        private xScale: any,
        private yScale: any
    ) {
        super();
        this.logger = this.loggerFactory(({ serviceName: `D3V3ZoomControlInstance::${this.id}`, objectName: 'Service' }));
        this.active = this.isActivated();
    }

    init(scaleMin: number, scaleMax: number, onZoom: () => void, onZoomEnd: () => void, zoomOptions: ChartDef.ZoomOptions) {
        this.scaleMin = scaleMin;
        //  Limiting zoom to 10000x the original scale
        this.scaleMax = Math.min(scaleMax, 10000);
        this.enabled = this.active && zoomOptions.enabled;

        this.transientScale = zoomOptions.scale;
        this.transientTranslate = [...zoomOptions.translate] as [number, number];

        let updatedTranslate: [number, number] = zoomOptions.translate as [number, number];

        if (zoomOptions.xRange && zoomOptions.yRange) {
            const xOriginalRange = this.xScale.range();
            const yOriginalRange = this.yScale.range();
            const xOriginalRangeDiff = Math.abs(xOriginalRange[1] - xOriginalRange[0]);
            const yOriginalRangeDiff = Math.abs(yOriginalRange[1] - yOriginalRange[0]);

            const xRangeDiff = Math.abs(zoomOptions.xRange[1] - zoomOptions.xRange[0]);
            const yRangeDiff = Math.abs(zoomOptions.yRange[1] - zoomOptions.yRange[0]);

            const translateAdjustment = [
                xOriginalRangeDiff / xRangeDiff,
                yOriginalRangeDiff / yRangeDiff
            ];

            updatedTranslate = [
                zoomOptions.translate[0] * translateAdjustment[0],
                zoomOptions.translate[1] * translateAdjustment[1]
            ];
        }

        let preventZoom = false;

        this.d3Zoom = this.d3.behavior
            .zoom()
            .x(this.xScale)
            .y(this.yScale)
            .scaleExtent([this.scaleMin, this.scaleMax])
            .scale(zoomOptions.scale)
            .translate(updatedTranslate)
            .on('zoom', () => {
                if (this.isEnabled() || this.forceUpdate) {
                    if (this.hasReachedExtremeScale(this.currentScale, this.d3.event.scale) && isEqual(this.currentTranslate, this.d3.event.translate)) {
                        preventZoom = true;
                    } else {
                        preventZoom = false;
                        this.chartZoomLoaderService.displayLoader(true, this.id);
                        this.zooming = true;
                        this.transientScale = this.d3.event.scale;
                        this.transientTranslate = this.d3.event.translate;
                        clearTimeout(this.zoomEndTimeout);
                        onZoom();
                    }
                }
            })
            .on('zoomend', () => {
                if (this.isEnabled() || this.forceUpdate) {
                    /**
                     * When zooming is stopped, create a delay before
                     * redrawing the full plot
                     */
                    this.zoomEndTimeout = setTimeout(() => {
                        if (!preventZoom) {
                            this.currentScale = this.transientScale;
                            this.currentTranslate = this.transientTranslate;

                            onZoomEnd();

                            this.zoomOptions$.next({
                                scale: this.currentScale,
                                translate: this.currentTranslate,
                                xRange: this.xScale.range(),
                                yRange: this.yScale.range()
                            });

                            this.canZoomIn$.next(this.canZoomIn());
                            this.canZoomOut$.next(this.canZoomOut());
                            this.canResetZoom$.next(this.canResetZoom());

                            this.zooming = false;
                            this.chartZoomLoaderService.displayLoader(false, this.id);
                        }
                    }, this.zoomEndDelay);

                    this.forceUpdate = false;
                }
            });

        this.d3Zoom.event(this.d3Canvas);

        // Add zoom behaviour
        this.d3Canvas.call(this.d3Zoom);
    }

    zoomIn() {
        if (!this.d3 || !this.d3Zoom) {
            this.logger.warn('Zoom controls were not initialized');
            return;
        }

        const currentScale = this.d3Zoom.scale();
        if (currentScale < this.scaleMax) {
            this.dispatchMousewheel(-1);
            this.d3Zoom.event(this.d3Canvas);
        }
    }

    zoomOut() {
        if (!this.d3 || !this.d3Zoom) {
            this.logger.warn('Zoom controls were not initialized');
            return;
        }

        const currentScale = this.d3Zoom.scale();
        if (currentScale > this.scaleMin) {
            this.dispatchMousewheel(1);
            this.d3Zoom.event(this.d3Canvas);
        }
    }

    resetZoom() {
        if (!this.d3 || !this.d3Zoom || isNil(this.defaultScale) || isNil(this.defaultTranslate)) {
            this.logger.warn('Zoom controls were not initialized');
            return;
        }

        this.d3Zoom.scale(this.defaultScale);
        this.d3Zoom.translate(this.defaultTranslate);
        this.d3Zoom.event(this.d3Canvas);
    }

    setZoomOptions(zoomOptions: ChartDef.ZoomOptions | null) {
        if (!this.d3 || !this.d3Zoom) {
            this.logger.warn('Zoom controls were not initialized');
            return;
        }

        if (isNil(zoomOptions)) {
            this.zoomOptions$.next(null);
        } else {
            this.enabled = this.active && zoomOptions.enabled;

            const previousZoomOptions = this.zoomOptions$.getValue();
            const hasSameScale = previousZoomOptions?.scale === zoomOptions?.scale;
            const hasSameTranslate = previousZoomOptions?.translate === zoomOptions?.translate;

            if (!hasSameScale || !hasSameTranslate) {
                this.forceUpdate = true;
                this.d3Zoom.scale(zoomOptions.scale);
                this.d3Zoom.translate(zoomOptions.translate as [number, number]);
                this.d3Zoom.event(this.d3Canvas);
            }
        }
    }

    getZoomOptions(): Observable<ZoomOptions | null> {
        return this.zoomOptions$.asObservable();
    }

    isActivated() {
        return !isNil(this.xScale.invert) && !isNil(this.yScale.invert);
    }

    isEnabled() {
        return this.isActivated() && this.enabled;
    }

    isZooming() {
        return this.zooming;
    }

    isZoomed() {
        return (this.transientScale && !isEqual(this.transientScale, this.defaultScale)) || (this.transientTranslate && !isEqual(this.transientTranslate, this.defaultTranslate));
    }

    canZoomIn() {
        return this.currentScale < this.scaleMax;
    }

    canZoomOut() {
        return this.currentScale > this.scaleMin;
    }

    canResetZoom() {
        return this.canZoomOut() || !isEqual(this.currentTranslate, this.defaultTranslate);
    }

    private dispatchMousewheel(deltaFactor: number): void {
        const height = this.d3Canvas[0][0].clientHeight;
        const width = this.d3Canvas[0][0].clientWidth;

        this.d3Zoom?.center([width / 2, height / 2]);
        const wheelEvt = new WheelEvent('wheel', { deltaY: height * this.scaleStep * deltaFactor });
        this.d3Canvas[0][0].dispatchEvent(wheelEvt);

        //resetting the center to be able to zoom on mouse cursor
        this.d3Zoom?.center(null);
    }

    private hasReachedExtremeScale(previousScale: number, currentScale: number) {
        return (previousScale <= this.scaleMin && currentScale <= this.scaleMin) || (previousScale >= this.scaleMax && currentScale >= this.scaleMax);
    }
}
