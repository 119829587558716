/** 
 * Translated from enum com.dataiku.dip.dataflow.exec.joinlike.JoinType
 * Via: com.dataiku.dip.dataflow.exec.joinlike.JoinDescBase
 */
export enum JoinType {
    INNER = "INNER",
    LEFT = "LEFT",
    RIGHT = "RIGHT",
    FULL = "FULL",
    CROSS = "CROSS",
    LEFT_ANTI = "LEFT_ANTI",
    RIGHT_ANTI = "RIGHT_ANTI",
    ADVANCED = "ADVANCED",
}