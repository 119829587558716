import { Component, ViewChild, ChangeDetectionStrategy, Input, Inject, HostListener, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, catchError, of } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridReadyEvent, CellValueChangedEvent, GridApi, GetContextMenuItemsParams, GetMainMenuItemsParams, MenuItemDef, StatusPanelDef, CellKeyDownEvent, FullWidthCellKeyDownEvent, CellKeyPressEvent, ColumnApi, Column, CellEditingStoppedEvent, DragStoppedEvent, DragStartedEvent, CellEditingStartedEvent, CellClickedEvent, IRowNode, SuppressKeyboardEventParams, CellRangeParams, ProcessDataFromClipboardParams } from 'ag-grid-community';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { WaitingService } from '@core/overlays/waiting.service';

import { SpreadsheetDiffs, EditCell, AddRows, ReorderCol, EditRow } from './spreadsheet-diffs';
import { ContextOptions, DatasetBuilder, CustomRowData, RowSide, ColSide, WellDefinedColDef, colNameToField, nodeAccessorToCustomRowAccessor, generateBaseColDef, generateBaseRowData, selectEntireGrid } from './utils';
import { VersionTag, SchemaColumn } from 'src/generated-sources';
import { fairAny } from 'dku-frontend-core';
import {LegacyDialogsService} from "@shared/components/dialogs/legacy-dialogs.service";
import { SpreadsheetCellEditorComponent } from './spreadsheet-cell-editor/spreadsheet-cell-editor.component';
import { SpreadsheetColumnHeaderComponent } from './spreadsheet-column-header';
import { APIError } from '@core/dataiku-api/api-error';
import _ from 'lodash';


@Component({
    selector: 'editable-dataset-spreadsheet',
    templateUrl: './editable-dataset-spreadsheet.component.html',
    styleUrls: ['./editable-dataset-spreadsheet.component.less'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class EditableDatasetSpreadsheetComponent implements OnInit, OnDestroy {

    @Input() projectKey: string;
    @Input() datasetId: string;

    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    readonly apiError$: BehaviorSubject<APIError | undefined> = new BehaviorSubject<APIError | undefined>(undefined);

    private gridApi!: GridApi;
    private spreadsheetDiffs: SpreadsheetDiffs;
    private diffsChangedSubscription: Subscription;
    private lastSavedSpreadsheetDiffs!: SpreadsheetDiffs;
    private createModal: (template : string, scope : fairAny, controllerName?: string) => void;

    private columnApi!: ColumnApi;
    private datasetVersionTag: VersionTag;
    private isDragging: boolean = false;
    private isEditingCell: boolean = false;
    private isInModal: boolean = false;
    private deletedColumns: WellDefinedColDef[] = [];
    private readonly COLUMN_HEADER_CLASS = "ag-header-cell";
    private readonly INDEX_COLUMN_ID = "0";
    private readonly SOURCE_PASTE_EVENT = "paste";
    private readonly CONTEXT_MENU_SEPARATOR = 'separator';
    private readonly INDEX_COLUMN_WIDTH = 47; // wide enough to display "100000" without overflow
    private prevColOrder: WellDefinedColDef[] = [];
    private _hasUnsavedChanges: boolean;
    private _hasConflictingChanges: boolean;

    private indexColDef: ColDef = {
        valueGetter: "node.rowIndex + 1",
        resizable: false,
        editable: false,
        lockPosition: true,
        pinned: 'left',
        suppressNavigable: true,
        suppressFillHandle: true,
        lockPinned: true,
        suppressMenu: true,
        cellClass: ['ag-grid-row-index-cell'],
        headerComponentParams: {
            isIndexCol: true,
        },
        // Override default header tooltip set by `defaultColDef`
        headerTooltip: "Click to select all cells",
        width: this.INDEX_COLUMN_WIDTH,
        suppressKeyboardEvent: this.suppressKeyboardEvent.bind(this)
    };

    public readonly defaultColDef: ColDef = {
        editable: true,
        cellEditor: SpreadsheetCellEditorComponent,
        headerComponent: SpreadsheetColumnHeaderComponent,
        resizable: true,
        headerTooltip: "Click to select all cells in column",
        menuTabs: ['generalMenuTab'],
        // About the same as explore view's default width
        width: 180,

        suppressKeyboardEvent: this.suppressKeyboardEvent.bind(this)
    };

    private readonly CLEAR_BIG_GRID_THRESHOLD_NB_CELLS = 25000;

    constructor(
        private dataikuAPI: DataikuAPIService,
        private waitingService: WaitingService,
        private legacyDialogsService: LegacyDialogsService,
        @Inject('$rootScope') private $rootScope: fairAny,
        @Inject("CreateModalFromTemplate") private CreateModalFromTemplate: fairAny,
        @Inject('DatasetUtils') private datasetUtils: fairAny,
    ) {
        this.createModal = (template : string, scope : fairAny, controllerName?: string) => {
            this.isInModal = true;
            CreateModalFromTemplate(template, scope, controllerName).finally(() => this.isInModal = false);
        };
    }

    /**
     * defines the status bar, printing the number of rows and their statistics
     */
    public readonly statusBar: { statusPanels: StatusPanelDef[] } = {
        statusPanels: [
            {statusPanel: 'agTotalRowCountComponent', align: 'left'},
            {statusPanel: 'agSelectedRowCountComponent'},
            {statusPanel: 'agAggregationComponent', statusPanelParams: {aggFuncs: ['min', 'max', 'avg']}},
        ]
    };

    ngOnInit() {
        const newScope = this.$rootScope.$new();
        this.legacyDialogsService.checkChangesBeforeLeaving(newScope, () => this._hasUnsavedChanges);
        this.lastSavedSpreadsheetDiffs = new SpreadsheetDiffs();
    }

    public canUndo() {
        return this.spreadsheetDiffs && this.spreadsheetDiffs.canUndo();
    }

    public canRedo() {
        return this.spreadsheetDiffs && this.spreadsheetDiffs.canRedo();
    }

    /**
     * Sets up the grid by importing initial data from the backend. Called when grid is rendered for the first time.
     */
    public onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.gridApi.hideOverlay(); // hide the initial load overlay as there is already the DSS spinner
        this.spreadsheetDiffs = new SpreadsheetDiffs();
        this.columnApi = params.columnApi;
        this.diffsChangedSubscription = this.spreadsheetDiffs.diffsChanged$.subscribe(() => {
            // Having conflicting changes implies that the displayed data are not the data saved on backend side
            this._hasUnsavedChanges = this._hasConflictingChanges || !this.spreadsheetDiffs?.equals(this.lastSavedSpreadsheetDiffs);
        });
        this.fillGridWithCurrentDatasetData();
    }

    private fillGridWithCurrentDatasetData() {
        this.dataikuAPI.datasets.getData(this.projectKey, this.datasetId)
            .pipe(this.waitingService.bindSpinner())
            .subscribe(dataset => {
                this.datasetVersionTag = dataset.versionTag;

                // setting the columns definitions
                const newColumnDefs: WellDefinedColDef[] = !dataset.schema ? [generateBaseColDef(this.spreadsheetDiffs)] : dataset.schema.columns.map((columnDesc: SchemaColumn) => (
                    {
                        field: colNameToField(columnDesc.name),
                        headerName: columnDesc.name,
                        headerComponentParams: {
                            schemaColumn: columnDesc,
                            markChanged: false,
                            spreadsheetDiffs: this.spreadsheetDiffs,
                            isIndexCol: false,
                        }
                    }
                ));

                // setting the content of the rows
                const newRowData = !(dataset.data && dataset.data.length > 0) ? [generateBaseRowData()] : dataset.data.map((line: string[], index: number) => {
                    const rowData: CustomRowData = {
                        initIndex: index,
                        dirty: false,
                        data: {}
                    };
                    newColumnDefs.forEach((colDef, colIndex) => {
                        this.autoSizeCellHeight(line[colIndex], colDef, false); // no need to redraw, all rows will drawn for the first time just after
                        rowData.data[nodeAccessorToCustomRowAccessor(colDef.field)] = line[colIndex];
                    });
                    return rowData;
                });
                const colDefs: ColDef[] = [this.indexColDef, ...newColumnDefs];
                this.gridApi.setColumnDefs(colDefs);
                this.gridApi.setRowData(newRowData);
                this.gridApi.refreshHeader();
                // Clear undo/redo stack when the grid is reloaded
                this.resetDiffs();
                this._hasConflictingChanges = false;
            });
    }

    /**
     * Clear the current and the last saved spreadsheet action history.
     */
    private resetDiffs() {
        this.lastSavedSpreadsheetDiffs.clear();
        this.spreadsheetDiffs.clear();
    }

    private getColumnIndexByColId(colId: string): number | undefined {
        return this.columnApi.getColumns()?.findIndex(col => col.getColId() === colId);
    }

    public importDataset() {
        // Initialize the scope of the import modal
        const newScope = this.$rootScope.$new();

        // Refresh the dataset view by fetching the dataset from the API
        newScope.loadData = this.fillGridWithCurrentDatasetData.bind(this);

        // Save dataset using the API
        newScope.saveDataset = () => {
            const dataset = this.getDatasetFromGrid();

            return this.dataikuAPI.datasets.save(this.projectKey, this.datasetId, dataset)
            .toPromise();
        };

        // Get the list of datasets that can be imported to the current editable dataset
        this.datasetUtils.listDatasetsUsabilityInAndOut(this.projectKey, "sync").then(
            (response: fairAny) =>
                newScope.availableInputDatasets = response[0].filter((ds: fairAny) =>
                    // exclude current dataset from the list
                    ds.name != this.datasetId || ds.projectKey != this.projectKey
                )
        );
        // Get the current dataset from the API
        this.dataikuAPI.datasets.get(this.projectKey, this.datasetId, this.projectKey)
            .pipe(this.waitingService.bindSpinner())
            .subscribe(
                datasetMetadata => {
                    newScope.dataset = datasetMetadata;

                    newScope.dataset.managed = true;    //Editable datasets are always managed
                    newScope.dataset.params = {
                        importSourceType: 'DATASET',
                        importDatasetSmartName: '',
                        importMode: 'REPLACE'
                    };
                    newScope.currentTab = 'source';
                    // For now, set the dataset to dirty whenever the import modal is opened
                    // All changes will be automatically saved before the import
                    // TODO: update to grid state once implemented
                    newScope.datasetSaved = false;

                    this.createModal("/templates/datasets/editable-dataset-import-modal.html", newScope, "EditableDatasetImportController");
                }
            );
    }

    public onDragStarted(e: DragStartedEvent): void {
        if (e.target.className.includes(this.COLUMN_HEADER_CLASS)) {
            this.prevColOrder = (this.gridApi.getColumnDefs() as WellDefinedColDef[]);
        }
        this.isDragging = true;
    }

    public onDragStopped(e: DragStoppedEvent): void {
        if (e.target.className.includes(this.COLUMN_HEADER_CLASS)) {
            const newColOrder = (this.gridApi.getColumnDefs() as WellDefinedColDef[]);
            if (newColOrder && newColOrder.some((newCol, i) => newCol.field !== this.prevColOrder[i].field)) {
                // No need to execute diff because already done by AG Grid
                this.spreadsheetDiffs.register(new ReorderCol(this.prevColOrder, newColOrder, this.gridApi));
            }
        }
        this.isDragging = false;
    }

    /**
     * Event handler when a cell value is changed
     */
    public onCellValueChanged(e: CellValueChangedEvent): void {
        if (e.rowIndex === null) return;
        // Change any integer to string
        // This is useful in particular for fill handle: AG Grid generates new values as integers, which are transformed into floats by the backend,
        // which are then displayed with trailing zeros in the grid after reload, and this is not what we want
        if (Number.isInteger(e.newValue)) {
            const valueStr = e.newValue.toString();
            e.newValue = valueStr;
            e.value = valueStr;
            e.data.data[nodeAccessorToCustomRowAccessor(e.column.getColId())] = valueStr;
        }

        // Mark changed row as dirty so they are sent to the backend later on save
        e.node.data.dirty = true;

        this.autoSizeCellHeight(e.value, e.column.getColDef(), true);

        // Value changed and dragging: fill handle case
        // Value changed and pasted: copy paste case
        if (this.isDragging || e.source === this.SOURCE_PASTE_EVENT) {
            // No need to execute diff because already done by AG Grid
            this.spreadsheetDiffs.register(new EditCell(e.node, e.column.getColDef(), this.gridApi, e.oldValue, e.value));
        }
    }

    /**
     * Adjust grid size if clipboard data overflows
     * @param params Clipboard data
     * @returns {string[][] | null} Data that will then be passed to AG Grid native clipboard handler
     */
    processDataFromClipboard = (
        params: ProcessDataFromClipboardParams
    ): string[][] | null => {
        const data = [...params.data];

        const gridLastRowIndex = params.api.getModel().getRowCount() - 1;
        const gridColsCount = this.columnApi.getColumns()?.length;
        if (gridColsCount == null) return null;
        const focusedCell = params.api.getFocusedCell();
        const focusedRowIndex = focusedCell?.rowIndex;
        const focusedColId = focusedCell?.column.getColId();
        if (focusedRowIndex == null) return null;
        if (focusedColId == null) return null;
        const focusedColIndex = this.getColumnIndexByColId(focusedColId);
        if (focusedColIndex == null) return null;

        // Add cols if overflow
        if (focusedColIndex + data[0].length > gridColsCount) {
            const resultLastColIndex = focusedColIndex + data[0].length - 1;
            const numColsToAdd = resultLastColIndex - (gridColsCount - 1);
            const contextOptions = new ContextOptions(this.gridApi, this.columnApi, this.spreadsheetDiffs);
            contextOptions.addColumns(gridColsCount - 1, ColSide.RIGHT, numColsToAdd, this.deletedColumns);
        }

        // Add rows if overflow
        if (focusedRowIndex + data.length - 1 > gridLastRowIndex) {
            const resultLastRowIndex = focusedRowIndex + data.length - 1;
            const numRowsToAdd = resultLastRowIndex - gridLastRowIndex;
            const offsetDataToAddIndex = gridLastRowIndex - focusedRowIndex + 1;
            const rowsToAdd: CustomRowData[] = [];
            for (let i = 0; i < numRowsToAdd; i++) {
                const index = offsetDataToAddIndex + i;
                const rowDataFromClipboard = data.slice(index, index + 1)[0];
                // Create row object
                const row: CustomRowData = {
                    data: {},
                    // Mark it as dirty so that it will be saved when building the dataset from grid
                    dirty: true,
                    // New line has an initIndex of -1
                    initIndex: -1
                };
                let currentColumn: fairAny = focusedCell?.column;
                rowDataFromClipboard.forEach((item: string) => {
                    if (!currentColumn) {
                        return;
                    }
                    _.set(row, currentColumn.colDef.field, item);
                    currentColumn = params.columnApi.getDisplayedColAfter(currentColumn);
                });
                rowsToAdd.push(row);
            }
            // Add prepared rows, starting after the last row of grid
            this.spreadsheetDiffs.registerAndExecute(new AddRows(rowsToAdd, gridLastRowIndex + 1, params.api));
        }

        // Return the data and let AG Grid handle the rest (update cell value/overwrite current row)
        return data;
    };


    public onCellEditingStopped(e: CellEditingStoppedEvent) {
        if(e.oldValue !== e.value && e.value !== undefined) {
            // No need to execute diff because already done by AG Grid
            this.spreadsheetDiffs.register(new EditCell(e.node, e.column.getColDef(), this.gridApi, e.oldValue, e.value));
        }
        this.isEditingCell = false;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onCellEditingStarted(e: CellEditingStartedEvent) {
        this.isEditingCell = true;
    }

    /**
     * Ignore native AG Grid keyboard handler.
     * @param params
     * @returns `true` when the key pressed should be ignored by AG Grid native handler.
     */
    private suppressKeyboardEvent(params: SuppressKeyboardEventParams) {
        const keyPressed = params.event.key;
        return keyPressed === 'Backspace' || keyPressed === 'Delete' || (params.event.metaKey && keyPressed === 'a');
    }

    /**
     * @params e - context of the event
     *
     * Called when any key is pressed
     */
    public onCellKeyDown(e: CellKeyDownEvent | FullWidthCellKeyDownEvent): void {
        if (!e || !e.event) {
            return;
        }

        const keyboardEvent = e.event as KeyboardEvent;
        const keyPress = keyboardEvent.key;
        if(keyPress === 'Enter') {
            this.handleEnterKeyPress(e.node.rowIndex, (e as CellKeyPressEvent).column.getColId());
        }
        if (keyPress === 'Tab' && !keyboardEvent.shiftKey) {
            this.handleTabKeyPress(e.node.rowIndex, (e as CellKeyPressEvent).column.getColId());
        }
    }

    public onCellClicked(event: CellClickedEvent) {
        if (event.column.getColId() === this.INDEX_COLUMN_ID && event.rowIndex != null) {
            this.selectEntireRow(event.rowIndex);
        }
    }

    /**
     * Clear all current selection and select all cells of the row in parameter
     * @param rowIndex Index of row to be selected
     */
    private selectEntireRow(rowIndex: number) {
        this.gridApi.clearRangeSelection();
        this.gridApi.clearFocusedCell();
        this.gridApi.addCellRange({
            rowStartIndex: rowIndex,
            rowEndIndex: rowIndex,
            columns: (this.columnApi.getColumns() ?? []).slice(1) // do not select the index column
        });
    }

    /**
     * Warning: META MAY ONLY WORKS ON MACS
     */
    @HostListener('window:keydown.meta.a', ['$event'])
    private handleMetaAKeyPress(event: KeyboardEvent) {
        if (!this.isEditingCell && !this.isInModal) {
            event.preventDefault();
            selectEntireGrid(this.gridApi, this.columnApi);
        }
    }

    private handleEnterKeyPress(rowIndex?: number | null, colId?: string | null) {
        if (rowIndex == null || colId == null) return;
        const numberOfDisplayedRows = this.gridApi.getDisplayedRowCount();
        this.gridApi.clearRangeSelection();
        if (rowIndex === numberOfDisplayedRows - 1 && !this.isEditingCell) {
            // Enter is pressed while editing the last line
            // We need to check isEditingCell since we want the AG Grid default behavior of entering edit mode before inserting a new line
            this.insertEmptyRow(numberOfDisplayedRows);
            // Enter edit mode on the added row, same column
            this.gridApi.startEditingCell({
                rowIndex: rowIndex + 1,
                colKey: colId
            });
        }
    }

    private handleTabKeyPress(rowIndex?: number | null, colId?: string | null) {
        const cols = this.columnApi.getColumns();
        if (!cols || rowIndex == null || colId == null) return;
        const numberOfDisplayedRows = this.gridApi.getDisplayedRowCount();
        this.gridApi.clearRangeSelection();
        if (rowIndex === numberOfDisplayedRows - 1 && cols[cols?.length - 1].getColId() === colId) {
            // Tab is pressed while the last cell of the last line is selected
            this.insertEmptyRow(numberOfDisplayedRows);
            // Enter edit mode on the added row, first column
            this.gridApi.startEditingCell({
                rowIndex: rowIndex + 1,
                // Because index column is at index 0, we need to add 1 to the index of the first column
                colKey: cols[1].getColId()
            });
        }
    }

    private insertEmptyRow(position: number) {
        const newRowData: CustomRowData = {
            initIndex: -1,
            dirty: true,
            data: {}
        };
        // Enable the noMultiDiff flag to isolate the edit cell operation and the add new row operation
        this.spreadsheetDiffs.registerAndExecute(new AddRows([newRowData], position, this.gridApi), true);
        // Remove the focus on the last cell
        this.gridApi.clearFocusedCell();
    }

    @HostListener('window:keydown.delete', ['$event'])
    @HostListener('window:keydown.backspace', ['$event'])
    private handleBackspaceAndDeleteKeyPress() {
        const cellRanges = this.gridApi.getCellRanges();
        if (!this.isEditingCell && !this.isInModal && cellRanges) {
            cellRanges.forEach(cells => {
                if (cells.startRow == null || cells.endRow == null) return;
                const startRowIndex = Math.min(cells.startRow.rowIndex, cells.endRow.rowIndex);
                const endRowIndex = Math.max(cells.startRow.rowIndex, cells.endRow.rowIndex);
                this.clearCells(startRowIndex, endRowIndex, cells.columns);
            });
        }
    }

    private clearCells(fromRowIndex: number, toRowIndex: number, columns: Column[]): void {
        const nbRows = toRowIndex - fromRowIndex + 1;
        const nbColumns = columns.length;
        const nbCells = nbRows * nbColumns;
        if (nbCells > this.CLEAR_BIG_GRID_THRESHOLD_NB_CELLS) {
            // Display a loading overlay for big grid because clearing may take a long time
            this.gridApi.showLoadingOverlay();
            setTimeout(() => {
                this._clearCells(fromRowIndex, toRowIndex, columns);
                this.gridApi.hideOverlay();
            }, 15); // setTimeout 15 necessary to be sure the loading overlay is displayed before starting the long operation
        } else {
            this._clearCells(fromRowIndex, toRowIndex, columns);
        }
    }

    private _clearCells(fromRowIndex: number, toRowIndex: number, columns: Column[]): void {
        const totalColCount = (this.gridApi.getColumnDefs() ?? []).length;
        this.gridApi.forEachNode(rowNode => {
            if (!rowNode || rowNode.rowIndex == null || rowNode.rowIndex < fromRowIndex || rowNode.rowIndex > toRowIndex) {
                return;
            }

            rowNode.data.dirty = true;

            if (columns.length + 1 === totalColCount) {
                // Trying to clear all columns (except index column): edit whole row to improve perf
                this.spreadsheetDiffs.registerAndExecute(new EditRow(rowNode, rowNode.data, {
                    initIndex: rowNode.data.initIndex,
                    dirty: rowNode.data.dirty,
                    data: {}
                }));
            } else {
                columns.forEach(column => {
                    const columnId = column.getColId();
                    if (columnId !== undefined && columnId !== null) {
                        this.spreadsheetDiffs.registerAndExecute(new EditCell(rowNode, column.getColDef(), this.gridApi, rowNode.data.data[nodeAccessorToCustomRowAccessor(columnId)], null));
                    }
                });
            }
        });
    }

    @HostListener('window:keydown.meta.s', ['$event'])
    private handleMetaSKeyPressed(event: KeyboardEvent) {
        event.preventDefault();
        if (this.hasUnsavedChanges) this.save();
    }

    /**
     * Saves the grid in the backend. Changes are commited.
     */
    public save(): void {
        const res = this.getDatasetFromGrid();

        this.dataikuAPI.datasets.save(this.projectKey, this.datasetId, res)
            .pipe(this.waitingService.bindSpinner())
            .pipe(
                catchError((error: APIError) => {
                    this.apiError$.next(error);
                    return of(undefined);
                })
            )
            .subscribe((res) => {
                // If save failed, do nothing so that current state is kept
                if (res == null) return;
                if ('canBeSaved' in res && res.canBeSaved === false) {
                    this._hasConflictingChanges = true;
                } else {
                    if ('versionTag' in res) {
                        this.datasetVersionTag = res.versionTag;
                    }
                    this.lastSavedSpreadsheetDiffs = this.spreadsheetDiffs.clone();
                    this._hasUnsavedChanges = false;
                    this._hasConflictingChanges = false;
                }
            });
    }

    private getDatasetFromGrid() {
        const cols = this.gridApi.getColumnDefs() as (WellDefinedColDef)[];
        const rowsIterator = (callback: (rowData: CustomRowData) => void) => this.gridApi.forEachNode(x => callback(x.data));

        return DatasetBuilder.buildDataset(cols, this.datasetVersionTag, rowsIterator);
    }

    /**
     * Undo / redo changes
     *
     * Warning: META MAY ONLY WORKS ON MACS
     */
    @HostListener('window:keydown.meta.z', ['$event'])
    public undoChange = () => {
        if (!this.isInModal && this.canUndo()) {
            this.spreadsheetDiffs.undo();
        }
    };
    @HostListener('window:keydown.meta.shift.z', ['$event'])
    public redoChange = () => {
        if (!this.isInModal && this.canRedo()) {
            this.spreadsheetDiffs.redo();
        }
    };

    /**
     * @param params - parameters of the click
     *
     * Available items in the context menu of cells
     */
    getContextMenuItems = (params: GetContextMenuItemsParams) : (string | MenuItemDef)[] => {
        const allColDefs = this.gridApi.getColumnDefs() as WellDefinedColDef[];

        const isRightClickOnIndexColumn = params.column?.getColId() === this.INDEX_COLUMN_ID;
        if (isRightClickOnIndexColumn && params.node?.rowIndex != null) this.selectEntireRow(params.node?.rowIndex);

        // When selection is on non-contiguous rows/cols, remove current range selection and focus only on the cell where context menu is requested
        if ((this.gridApi.getCellRanges() ?? []).length > 1
            && params.node?.rowIndex != null && params.column) {
            this.clearAndSetNewCellRange({rowStartIndex: params.node?.rowIndex, rowEndIndex: params.node?.rowIndex, columns: [params.column]});
            this.gridApi.setFocusedCell(params.node?.rowIndex, params.column);
        }

        const selectedRowIndexes = new Set<number>(); // strangely enough getSelectedNodes / getSelectedRows always return empty lists
        const selectedCols = new Set<Column>();
        const selectedRows: IRowNode[] = [];

        const cellRange = params.api.getCellRanges()?.[0];
        if (cellRange == null) {
            return [];
        }
        const minRowIndex = Math.min(cellRange.startRow?.rowIndex ?? -1, cellRange.endRow?.rowIndex ?? -1);
        const maxRowIndex = Math.max(cellRange.startRow?.rowIndex ?? -1, cellRange.endRow?.rowIndex ?? -1);
        if (minRowIndex == -1 || maxRowIndex == -1) {
            return [];
        }
        for (let i = minRowIndex; i <= maxRowIndex; i++) { selectedRowIndexes.add(i); }
        cellRange.columns.forEach(col => {
            if (col.getColId() !== this.INDEX_COLUMN_ID) { // do not consider the index column
                selectedCols.add(col);
            }
        });

        const selectedColIndexes = [...selectedCols]
            .map(col => allColDefs.findIndex(colDef => colDef.field === col.getColId()))
            .filter(colIndex => colIndex !== -1);    // -1 if index not found
        params.api.forEachNode(row => {
            if (selectedRowIndexes.has(row.rowIndex ?? -1)) {
                selectedRows.push(row);
            }
        });

        return this.buildContextMenuItems(Array.from(selectedCols), selectedColIndexes, selectedRows, Array.from(selectedRowIndexes), isRightClickOnIndexColumn);
    };

    private buildContextMenuItems(selectedCols: Column[], selectedColIndexes: number[], selectedRows: IRowNode[], selectedRowIndexes: number[], isRightClickOnIndexColumn: boolean) {
        const contextOptions = new ContextOptions(this.gridApi, this.columnApi, this.spreadsheetDiffs);
        const nbSelectedRows = selectedRowIndexes.length;
        const nbSelectedCols = selectedCols.length;
        const topmostSelectedRowIndex = Math.min(...selectedRowIndexes);
        const bottommostSelectedRowIndex = Math.max(...selectedRowIndexes);
        const leftmostSelectedColIndex = Math.min(...selectedColIndexes);
        const rightmostSelectedColIndex = Math.max(...selectedColIndexes);
        const pluralRow = (nbSelectedRows != 1 ? "s" : "");
        const pluralCol = (nbSelectedCols != 1 ? "s" : "");

        const clearSelectedRows = () => {
            const allColumns = this.columnApi.getColumns() ?? [];
            selectedRows.forEach(row => {
                    const rowIndex = row.rowIndex;
                    if (rowIndex != null) {
                        this.clearCells(rowIndex, rowIndex, allColumns);
                    }
                }
            );
        };

        const addRowsAboveSelection = () => {
            contextOptions.addRows(topmostSelectedRowIndex, RowSide.ABOVE, nbSelectedRows);
        };

        const addRowsBelowSelection = () => {
            contextOptions.addRows(bottommostSelectedRowIndex, RowSide.BELOW, nbSelectedRows);

            this.clearAndSetNewCellRange({ rowStartIndex: bottommostSelectedRowIndex + 1, rowEndIndex: bottommostSelectedRowIndex + nbSelectedRows, columns: [...selectedCols] });
        };

        const addColumnsLeftSelection = () => {
            contextOptions.addColumns(leftmostSelectedColIndex, ColSide.LEFT, nbSelectedCols, this.deletedColumns);

            const allColumns = this.columnApi.getColumns() ?? [];
            const allColDefs = this.gridApi.getColumnDefs() as WellDefinedColDef[];
            this.clearAndSetNewCellRange({
                rowStartIndex: topmostSelectedRowIndex,
                rowEndIndex: bottommostSelectedRowIndex,
                columnStart: allColumns.find(column => column.getColId() === allColDefs[leftmostSelectedColIndex].field),
                columnEnd: allColumns.find(column => column.getColId() === allColDefs[leftmostSelectedColIndex + nbSelectedCols - 1].field)
            });
        };

        const addColumnsRightSelection = () => {
            contextOptions.addColumns(rightmostSelectedColIndex, ColSide.RIGHT, nbSelectedCols, this.deletedColumns);

            const allColumns = this.columnApi.getColumns() ?? [];
            const allColDefs = this.gridApi.getColumnDefs() as WellDefinedColDef[];
            this.clearAndSetNewCellRange({
                rowStartIndex: topmostSelectedRowIndex,
                rowEndIndex: bottommostSelectedRowIndex,
                columnStart: allColumns.find(column => column.getColId() === allColDefs[rightmostSelectedColIndex + 1].field),
                columnEnd: allColumns.find(column => column.getColId() === allColDefs[rightmostSelectedColIndex + nbSelectedCols].field)
            });
        };

        const removeSelectedRows = () => {
            contextOptions.deleteRows(selectedRows);
        };

        const removeSelectedColumns = () => this.deletedColumns.push(...contextOptions.deleteColumns(selectedCols));

        const autoSizeAllColumns = () => contextOptions.autoSizeAllColumns();

        const cellMenu: (string | MenuItemDef)[] = [
            {
                name: `Add ${nbSelectedRows} row${pluralRow} above`,
                action: addRowsAboveSelection
            },
            {
                name: `Add ${nbSelectedRows} row${pluralRow} below`,
                action: addRowsBelowSelection
            },
            this.CONTEXT_MENU_SEPARATOR,
            {
                name: `Add ${nbSelectedCols} column${pluralCol} left`,
                action: addColumnsLeftSelection,
            },
            {
                name: `Add ${nbSelectedCols} column${pluralCol} right`,
                action: addColumnsRightSelection,
            },
            this.CONTEXT_MENU_SEPARATOR,
            {
                name: `Remove ${nbSelectedRows} row${pluralRow}`,
                action: removeSelectedRows
            },
            {
                name: `Remove ${nbSelectedCols} column${pluralCol}`,
                action: removeSelectedColumns,
            },
            this.CONTEXT_MENU_SEPARATOR,
            {
                name: "Autosize all columns",
                action: autoSizeAllColumns,
            }
        ];

        const indexMenu: (string | MenuItemDef)[] = [
            {
                name: `Add ${nbSelectedRows} row${pluralRow} above`,
                action: addRowsAboveSelection
            },
            {
                name: `Add ${nbSelectedRows} row${pluralRow} below`,
                action: addRowsBelowSelection
            },
            this.CONTEXT_MENU_SEPARATOR,
            {
                name: `Remove ${nbSelectedRows} row${pluralRow}`,
                action: removeSelectedRows
            },
            this.CONTEXT_MENU_SEPARATOR,
            {
                name: `Clear ${nbSelectedRows} row${nbSelectedRows != 1 ? "s" : ""}`,
                action: clearSelectedRows
            }
        ];

        return isRightClickOnIndexColumn ? indexMenu : cellMenu;
    }

    /**
     * @param params - parameters of the click
     *
     * Available items in the main menu of column headers
     */
    getMainMenuItems: (params: GetMainMenuItemsParams) => (string | MenuItemDef)[] = (params) => {
        const contextOptions = new ContextOptions(params.api, params.columnApi, this.spreadsheetDiffs);
        const allColDefs = params.api.getColumnDefs() as WellDefinedColDef[];
        const targetColumn = params.column;
        const colIndex = allColDefs.findIndex(colDef => colDef.field === targetColumn.getColId());
        const headerName: string = targetColumn.getColDef().headerName ?? "";

        const result: (string | MenuItemDef)[] = [
            {
                name: `Edit column "${headerName}"`,
                action: () => contextOptions.updateColumnDef(this.$rootScope.$new(), targetColumn, this.createModal)
            },
            this.CONTEXT_MENU_SEPARATOR,
            {
                name: "Add 1 column left",
                action: () => contextOptions.addColumns(colIndex, ColSide.LEFT, 1, this.deletedColumns)
            },
            {
                name: "Add 1 column right",
                action: () => contextOptions.addColumns(colIndex, ColSide.RIGHT, 1, this.deletedColumns)
            },
            this.CONTEXT_MENU_SEPARATOR,
            {
                name: `Remove column "${headerName}"`,
                action: () => this.deletedColumns.push(...contextOptions.deleteColumns([targetColumn]))
            },
            {
                name: "Remove empty rows",
                action: () => {
                    contextOptions.deleteEmptyRows();
                }
            },
            this.CONTEXT_MENU_SEPARATOR,
            {
                name: `Clear column "${headerName}"`,
                action: () => this.clearCells(0, this.gridApi.getDisplayedRowCount(), [targetColumn])
            },
            this.CONTEXT_MENU_SEPARATOR,
            {
                name: "Autosize all columns",
                action: () => contextOptions.autoSizeAllColumns()
            }
        ];
        return result;
    };

    private clearAndSetNewCellRange(cellRange: CellRangeParams) {
        this.gridApi.clearRangeSelection();
        this.gridApi.clearFocusedCell();
        this.gridApi.addCellRange(cellRange);
    }

    private autoSizeCellHeight(cellValue: string | null, colDef: ColDef, redraw: boolean) {
        // Enable autoHeight (and wrapText) on the whole column ONLY if it has 1+ multi-line cell to avoid performance issues
        // Check first whether autoHeight is already set to avoid searching a possibly large string when not necessary
        if (!colDef.autoHeight && cellValue && (cellValue.includes('\n') || cellValue.includes('\r'))) {
            colDef.autoHeight = true;
            colDef.wrapText = true;
            colDef.cellStyle = {...colDef.cellStyle, whiteSpace: 'pre'};
            if (redraw) {
                this.gridApi.setColumnDefs(this.gridApi.getColumnDefs() ?? []);
                this.gridApi.redrawRows(); // must redraw all rows to apply autoHeight on all of them
            }
        }
        // No need to reset autoHeight to false here because it's acceptable to have autoHeight for columns that do not need it i.e. columns with no multi-line cells
        // It will be reset correctly on next page reload anyway
    }

    /**
     * Return whether there is any unsaved change on the grid.
     */
    public get hasUnsavedChanges(): boolean {
        return this._hasUnsavedChanges;
    }

    public get hasConflictingChanges(): boolean {
        return this._hasConflictingChanges;
    }

    public resetApiError() {
        this.apiError$.next(undefined);
    }

    ngOnDestroy() {
        this.diffsChangedSubscription.unsubscribe();
        // Clear this flag otherwise the dialog service will keep prompting on every route transition
        this._hasUnsavedChanges = false;
    }
}
