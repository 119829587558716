import { Injectable } from "@angular/core";
import { APIError, ErrorContext, catchAPIError } from "@core/dataiku-api/api-error";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { resolveSmartName } from "@utils/loc";
import { SchemaColumn } from "generated-sources";
import { BehaviorSubject, Observable, distinctUntilChanged, map } from "rxjs";

@UntilDestroy()
@Injectable()
export class EdaRecipeService implements ErrorContext {

    private error$ = new BehaviorSubject<APIError | undefined>(undefined);

    constructor(
        private DataikuAPI: DataikuAPIService,
    ) {}

    fetchInputColumns(datasetRef: string, projectKey: string): Observable<SchemaColumn[]> {
        const loc = resolveSmartName(projectKey, datasetRef);
        return this.DataikuAPI.datasets.get(loc.projectKey, loc.id, projectKey).pipe(
            catchAPIError(this),
            map(ds => ds.schema.columns),
        );
    }

    getError(): Observable<APIError | undefined> {
        return this.error$.pipe(distinctUntilChanged());
    }

    pushError(error?: APIError): void {
        this.error$.next(error);
    }
}
