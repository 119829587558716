import { Pipe, PipeTransform } from "@angular/core";
import { ManagedFolder } from "@shared/utils/managed-folder";
import { AnyLoc, LabelingTask } from "src/generated-sources";
import { ReviewStatus } from "./labeling-task-review/services/labeling-review.service";
import { UIBoundingBox, UINamedEntity } from "./models/annotation";
import { ImageLabelingTaskInfo, LabelingTaskInfo, TextLabelingTaskInfo } from "./services/labeling.service";

export function isTaskSetProperly(task: LabelingTaskInfo): boolean {
    return !missingMandatorySetting(task);
}

export function missingMandatorySetting(task: LabelingTaskInfo): string | null {
    if (isImageTask(task.type)) {
        return !task.idColumn ? "Path column" : null;
    } else {
        const dataColumn = (task as TextLabelingTaskInfo).dataColumn
        return !dataColumn ? "Text column" : null;
    }
}

export function getImageURLFromTask(task: ImageLabelingTaskInfo, imagePath: string): string {
    return getImageURL(task.projectKey, task.labelingTaskId, task.getManagedFolderLoc(), imagePath);
}

export function getImageURL(contextProjectKey: string, labelingTaskId: string, managedFolderLoc: AnyLoc, imagePath: string): string{
    return `/dip/api/labelingtasks/preview-image?labelingTaskId=${labelingTaskId}&contextProjectKey=${encodeURIComponent(contextProjectKey)}&projectKey=${encodeURIComponent(managedFolderLoc.projectKey)}&odbId=${encodeURIComponent(managedFolderLoc.id)}&itemPath=${encodeURIComponent(imagePath)}&contentType=${encodeURIComponent(ManagedFolder.getSupportedContentType(imagePath))}`;
}

export function isTextTask(type: LabelingTask.LabelingTaskType) {
    return type === LabelingTask.LabelingTaskType.NAMED_ENTITY_EXTRACTION;
}

export function isImageTask(type: LabelingTask.LabelingTaskType) {
    return type === LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION || type === LabelingTask.LabelingTaskType.OBJECT_DETECTION;
}

export function getItemName(type: LabelingTask.LabelingTaskType): string {
    switch(type) {
        case LabelingTask.LabelingTaskType.OBJECT_DETECTION:
        case LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION:
            return 'image';
        case LabelingTask.LabelingTaskType.NAMED_ENTITY_EXTRACTION:
            return 'document'
        default:
            return 'item';
    }
}

export function statusIconAndColor(status: ReviewStatus): {color: string, icon: string} {
    let color, icon;
    switch (status) {
        case ReviewStatus.VALIDATED:
            color = '#4CAF50';
            icon = 'dku-icon-label-validate-20';
            break;

        case ReviewStatus.CONFLICTING:
            color = '#F28C37';
            icon = 'dku-icon-label-conflict-20';
            break;

        case ReviewStatus.CONSENSUS:
            color = '#3B99FC';
            icon = 'dku-icon-label-review-20';
            break;

        case ReviewStatus.REJECTED:
            color = '#CE1228';
            icon = 'dku-icon-label-reject-20';
            break;
    }
    return {icon, color}
}

export function getIdName(type: LabelingTask.LabelingTaskType) {
    switch(type) {
        case LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION:
        case LabelingTask.LabelingTaskType.OBJECT_DETECTION:
            return "image path";
        default:
            return "ID";
    }
}

export function getAverageRectangleFromAnnotations(annotations: UIBoundingBox[]): { width: number, height: number, top: number, left: number } {
    const total = annotations.length;
    return {
        width: computeRoundedAverage(annotations.map(bbox => bbox.width)),
        height: computeRoundedAverage(annotations.map(bbox => bbox.height)),
        top: computeRoundedAverage(annotations.map(bbox => bbox.top)),
        left: computeRoundedAverage(annotations.map(bbox => bbox.left))
    };

    // round values to facilitate conversion between canvas and bbox
    function computeRoundedAverage(items: number[]) {
        return Math.round(items.reduce((a, b) => a + b, 0) / total);
    }
}

export function namedEntityAnnotationsSortingFn(a: UINamedEntity, b: UINamedEntity) {
    if (a.beginningIndex - b.beginningIndex !== 0) {
        return a.beginningIndex - b.beginningIndex;
    }
    return b.endIndex - a.endIndex;
}

@Pipe({ name: 'formattedAnnotatorName'})
export class FormattedAnnotatorNamePipe implements PipeTransform  {
    transform(annotatorId: string, annotators: {[login: string]: any}) {
        if (annotators[annotatorId]) {
            return annotators[annotatorId].displayName;
        } else {
            return annotatorId + " (Unknown)";
        }
    }
}

@Pipe({
    name: 'categoryLabel'
})
export class CategoryLabelPipe implements PipeTransform {
    transform(type: LabelingTask.LabelingTaskType, plural: boolean = false): string {
        switch(type) {
            case LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION:
                return 'class' + (plural ? 'es' : '');
            default:
                return plural ? 'categories' : 'category';
        }
    }
}
