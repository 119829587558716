import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { Distribution } from './../../distributions/distribution';
import type { ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _AvailableResult } from './../available-result';
import type { _ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.TestDistribution
 * Via: UIModel annotation in the class hierarchy
 */
export interface _TestDistribution extends Computation._UnivariateComputation {
    type: "test_distribution";
    distribution: Distribution.FittedDistribution;
}

export type TestDistribution = _TestDistribution;

export namespace TestDistribution {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.TestDistribution in PolyJSON mappings */
    export const type = "test_distribution";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$KolmogorovSmirnovTestResult
     * Via: com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$StatTests
     */
    export interface _KolmogorovSmirnovTestResult extends _ImmutableValueObject {
        pvalue: number;
        statistic: number;
    }
    
    export type KolmogorovSmirnovTestResult = _KolmogorovSmirnovTestResult;
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$LikelihoodTestsResult
     * Via: com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$StatTests
     */
    export interface _LikelihoodTestsResult extends _ImmutableValueObject {
        aic?: number | null;
        aicc?: number | null;
        bic?: number | null;
        ll?: number | null;
    }
    
    export type LikelihoodTestsResult = _LikelihoodTestsResult;
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$PlotData
     * Via: com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$TestDistributionResult
     */
    export interface _PlotData extends _ImmutableValueObject {
        pdf?: TestDistribution.ProbabilityDensityFunction | null;
        pmf?: TestDistribution.ProbabilityMassFunction | null;
        qq: TestDistribution.QQPlotData;
    }
    
    export type PlotData = _PlotData;
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$ProbabilityDensityFunction
     * Via: com.dataiku.dip.eda.worksheets.cards.ShapiroNormalityTestCard$ShapiroNormalityTestCardResult
     */
    export interface _ProbabilityDensityFunction extends _ImmutableValueObject {
        probs: number[];
        xvals: number[];
    }
    
    export type ProbabilityDensityFunction = _ProbabilityDensityFunction;
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$ProbabilityMassFunction
     * Via: com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$PlotData
     */
    export interface _ProbabilityMassFunction extends _ImmutableValueObject {
        probs: number[];
        xvals: number[];
    }
    
    export type ProbabilityMassFunction = _ProbabilityMassFunction;
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$QQPlotData
     * Via: com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$PlotData
     */
    export interface _QQPlotData extends _ImmutableValueObject {
        empirical: number[];
        percentile: number[];
        theoretical: number[];
    }
    
    export type QQPlotData = _QQPlotData;
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$StatTests
     * Via: com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$TestDistributionResult
     */
    export interface _StatTests extends _ImmutableValueObject {
        ks?: TestDistribution.KolmogorovSmirnovTestResult | null;
        ll: TestDistribution.LikelihoodTestsResult;
    }
    
    export type StatTests = _StatTests;
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$TestDistributionResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _TestDistributionResult extends _AvailableResult {
        type: "test_distribution";
        plot: TestDistribution.PlotData;
        test: TestDistribution.StatTests;
    }
    
    export type TestDistributionResult = _TestDistributionResult;

    export namespace TestDistributionResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$TestDistributionResult in PolyJSON mappings */
        export const type = "test_distribution";
    }
}