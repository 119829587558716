import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { noEmptyKeysValidator } from '@utils/no-empty-keys-validator';
import { LabelingTask, TextLabelingTask, TokenizationParam } from 'src/generated-sources';
import { getItemName, isTextTask } from '../utils';

@UntilDestroy()
@Component({
    selector: 'labeling-task-settings',
    templateUrl: './labeling-task-settings.component.html',
    styleUrls: [
        './labeling-task-settings.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelingTaskSettingsComponent implements OnChanges {
    @Input() labelingTask: LabelingTask;
    @Output() partialTaskChange = new EventEmitter<Partial<LabelingTask>>();
    @Output() partialTaskValidityChange = new EventEmitter<boolean>();

    settingsForm: UntypedFormGroup;
    itemName = getItemName;
    isTextTask = isTextTask;

    readonly tokenizationSplitOptions = [
        {
            value: TokenizationParam.TokenizationSplitMechanism.WHITESPACE,
            text: "By whitespace (default)"
        },
        {
            value: TokenizationParam.TokenizationSplitMechanism.CHARACTER,
            text: "By character"
        }
    ];

    private readonly tokenizationFormGroup = this.fb.group({
        engine: this.fb.control(null),
        splitMechanism: this.fb.control(null),
    });

    private readonly objectDetectionIOUConflictThresholdFormControl = this.fb.control(null, [Validators.min(0), Validators.max(1)]);

    readonly LabelingTaskType = LabelingTask.LabelingTaskType;

    constructor(private fb: UntypedFormBuilder) {
        this.settingsForm = this.fb.group({
            classes: this.fb.control(null, [noEmptyKeysValidator()]),
            instructions: this.fb.control(null),
            minNbAnnotatorsPerRecord: this.fb.control(null),
            autoValidateAnswers: this.fb.control(false),
            labelColumnName: this.fb.control(null, [Validators.required]),
        });

        this.settingsForm.valueChanges
            .pipe(
                untilDestroyed(this)
            )
            .subscribe(formValue => {
                this.partialTaskChange.emit(formValue);
            });

        this.settingsForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.partialTaskValidityChange.emit(this.settingsForm.valid));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.labelingTask && this.labelingTask) {
            if (isTextTask(this.labelingTask.type)) {
                this.settingsForm.addControl("tokenizationParam", this.tokenizationFormGroup, {emitEvent: false});
            } else {
                this.settingsForm.addControl("objectDetectionIOUConflictThreshold", this.objectDetectionIOUConflictThresholdFormControl, {emitEvent: false});
            }
            this.settingsForm.patchValue(this.labelingTask, { emitEvent: false });
        }
    }
}
