import type { OverrideTable } from './../../variables/override-table';
import type { ProcessorWithRecordedReport } from './../processor-with-recorded-report';
import type { _OverrideTable } from './../../variables/override-table';

/** 
 * Translated from class com.dataiku.dip.shaker.model.ScriptStep
 * Via: com.dataiku.dip.shaker.model.SerializedShakerScript
 */
export interface _ScriptStep {
    alwaysShowComment: boolean;
    comment: string;
    designTimeReport: ProcessorWithRecordedReport.ProcessorRecordedReport;
    disabled: boolean;
    mainColor: string;
    metaType: ScriptStep.MetaType;
    name: string;
    overrideTable: OverrideTable;
    preview: boolean;
    secondaryColor: string;
}

/**
    Translate from class com.dataiku.dip.shaker.model.ScriptStep
    @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
*/
export type ScriptStep = _ScriptStep;

export namespace ScriptStep {
    /** 
     * Translated from enum com.dataiku.dip.shaker.model.ScriptStep$MetaType
     * Via: com.dataiku.dip.shaker.model.ScriptStep
     */
    export enum MetaType {
        PROCESSOR = "PROCESSOR",
        GROUP = "GROUP",
    }
}