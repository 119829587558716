import type { AlphanumFacetWithMap } from './../facet/alphanum-facet-with-map';
import type { DataService } from './data-service';
import type { FilterFacet } from './../../pivot/backend/model/filter-facet';
import type { InteractiveSearchService } from './../../datasets/elasticsearch/interactive-search-service';
import type { MemColumn } from './../../datalayer/memimpl/mem-column';
import type { MemTable } from './../../datalayer/memimpl/mem-table';
import type { NumericalVariableAnalysis } from './../analysis/numerical-variable-analysis';
import type { SampleMetadata } from './sample-metadata';
import type { SchemaColumn } from './../../../../../../../../dss-core/src/main/java/com/dataiku/dip/coremodel/schema-column';
import type { SerializedShakerScript } from './../model/serialized-shaker-script';
import type { SerializedTableChunk } from './serialized-table-chunk';
import type { WarningsContext } from 'dku-frontend-core/generated-sources/dku-core/src/main/java/com/dataiku/dip/warnings/warnings-context';
import type { _AlphanumFacetWithMap } from './../facet/alphanum-facet-with-map';
import type { _FilterFacet } from './../../pivot/backend/model/filter-facet';
import type { _NumericalVariableAnalysis } from './../analysis/numerical-variable-analysis';
import type { _SampleMetadata } from './sample-metadata';
import type { _SchemaColumn } from './../../../../../../../../dss-core/src/main/java/com/dataiku/dip/coremodel/schema-column';
import type { _SerializedTableChunk } from './serialized-table-chunk';

/** 
 * Translated from class com.dataiku.dip.shaker.server.SerializedMemTableV2
 * Via: UIModel annotation in the class hierarchy
 */
export interface _SerializedMemTableV2 {
    allColumnNames: string[];
    coloringScheme: SerializedShakerScript.TableColoringScheme;
    filterFacets: FilterFacet[];
    headers: SerializedMemTableV2.Header[];
    initialChunk: SerializedTableChunk;
    initialCols: number;
    initialRows: number;
    newColumnsSelection: SerializedShakerScript.DisplayedColumnsSelection;
    newRecipeSchema: DataService.ShakerRecipeSchema;
    previewRowsAdded: number;
    previewRowsDeleted: number;
    sampleBuildDate: number;
    sampleMetadata: SampleMetadata;
    scriptChange: MemTable.MemTableChange;
    totalDeletedKeptRows: number;
    totalDeletedRows: number;
    totalEmptyCells: number;
    totalFullCells: number;
    totalKeptRows: number;
    totalRows: number;
    usedSampleId: string;
    warnings: WarningsContext.SerializedWarnings;
}

/** List of all concrete subclasses of com.dataiku.dip.shaker.server.SerializedMemTableV2 */
export type SerializedMemTableV2 =
    | InteractiveSearchService.InitialChunkSearchESDatasetThread.ElasticSearchSerializedMemTable
    | _SerializedMemTableV2;

export namespace SerializedMemTableV2 {
    /** 
     * Translated from class com.dataiku.dip.shaker.server.SerializedMemTableV2$Header
     * Via: com.dataiku.dip.shaker.server.SerializedMemTableV2
     */
    export interface _Header {
        analysisForColoring: NumericalVariableAnalysis;
        datasetSchemaColumn: SchemaColumn;
        deletedMeaningName?: string | null;
        distinctValues: number;
        facetForColoring: AlphanumFacetWithMap;
        isDouble: boolean;
        meaningOrigin: MemColumn.MeaningOrigin;
        name: string;
        ncharsToShow: number;
        possibleTypes: SerializedMemTableV2.MeaningResult[];
        progressBarChunks: SerializedMemTableV2.Header.ProgressBarChunk[];
        progressBarType: SerializedMemTableV2.Header.ProgressBarType;
        recipeSchemaColumn: DataService.ShakerRecipeSchemaColumn;
        selectedType: SerializedMemTableV2.MeaningResult;
        state: string;
    }
    
    export type Header = _Header;
    
    /** 
     * Translated from class com.dataiku.dip.shaker.server.SerializedMemTableV2$MeaningResult
     * Via: com.dataiku.dip.shaker.server.SerializedMemTableV2$Header
     */
    export interface _MeaningResult {
        name: string;
        nbEmpty: number;
        nbNOK: number;
        nbOK: number;
        score: number;
    }
    
    export type MeaningResult = _MeaningResult;

    export namespace Header {
        /** 
         * Translated from class com.dataiku.dip.shaker.server.SerializedMemTableV2$Header$ProgressBarChunk
         * Via: com.dataiku.dip.shaker.server.SerializedMemTableV2$Header
         */
        export interface _ProgressBarChunk {
            cls: string;
            lower: number;
            ratio: number;
            upper: number;
            value: string;
        }
        
        export type ProgressBarChunk = _ProgressBarChunk;
        
        /** 
         * Translated from enum com.dataiku.dip.shaker.server.SerializedMemTableV2$Header$ProgressBarType
         * Via: com.dataiku.dip.shaker.server.SerializedMemTableV2$Header
         */
        export enum ProgressBarType {
            MEANING_VALIDITY = "MEANING_VALIDITY",
            MEANING_VALIDITY_MUTED = "MEANING_VALIDITY_MUTED",
            NONE = "NONE",
            VALUE_COLORING_ANUM = "VALUE_COLORING_ANUM",
            VALUE_COLORING_NUM = "VALUE_COLORING_NUM",
        }
    }
}