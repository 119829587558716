import type { AuditTrailTargetSettings } from './audit-trail-target-settings';
import type { _AuditTrailTargetSettings } from './audit-trail-target-settings';

/** 
 * Translated from class com.dataiku.dip.security.audit.model.KafkaTargetSettings
 * Via: UIModel annotation in the class hierarchy
 */
export interface _KafkaTargetSettings extends _AuditTrailTargetSettings {
    type: "KAFKA";
    connection: string;
    topic: string;
}

export type KafkaTargetSettings = _KafkaTargetSettings;

export namespace KafkaTargetSettings {
    /** Type of class com.dataiku.dip.security.audit.model.KafkaTargetSettings in PolyJSON mappings */
    export const type = "KAFKA";
}