import { Injectable } from '@angular/core';
import { JoinType, JoinRecipePayloadParams } from 'generated-sources';

@Injectable({
    providedIn: 'root'
})
export class JoinDisplayNamesService {

    getJoinTypeName(joinType: JoinType): string {
        switch (joinType) {
            case JoinType.LEFT: return "Left join";
            case JoinType.INNER: return "Inner join";
            case JoinType.FULL: return "Outer join";
            case JoinType.RIGHT: return "Right join";
            case JoinType.CROSS: return "Cross join";
            case JoinType.ADVANCED: return "Advanced join";
            case JoinType.LEFT_ANTI: return "Left anti join";
            case JoinType.RIGHT_ANTI: return "Right anti join";
        }
    }

    getMatchingTypeName(matchingType: JoinRecipePayloadParams.MatchingType): string {
        const matchingTypeName = JoinRecipePayloadParams.MatchingType.displayNames[matchingType];
        if (matchingTypeName.length > 2) {
            return "~";
        }
        return matchingTypeName;
    }
}
