<button class="dropdown-toggle" (click)="$event.stopPropagation()" [class.dropdown-toggle--on-thumbnail]="isOnThumbnail"
    class="btn btn--secondary btn--text btn--icon btn--dku-icon" [matMenuTriggerFor]="optionsMenu">
    <i class="dku-icon-menu-vertical-dots-16 text-important"></i>
</button>

<mat-menu #optionsMenu>
    <ng-template matMenuContent>
        <button *ngIf="canImageDownload" mat-menu-item (click)="downloadAsAnImage()"
            class="df aic qa_charts_export-as-img">
            <i class="mright4 dku-icon-arrow-download-12"></i>Download as an image
        </button>
        <button *ngIf="canExcelDownload" mat-menu-item (click)="downloadAsAnExcel()" class="df aic">
            <i class="mright4 dku-icon-arrow-download-12"></i>Download as an Excel document
        </button>
        <mat-divider></mat-divider>
        <button *ngIf="canCopyToClipboard" mat-menu-item (click)="copyToClipboard()" class="df aic">
            <div class="cp-illustration-prefix">Copy chart to clipboard</div>
            <div class="cp-illustration-commands">
                <span class="cp-illustration-commands--key cmd">{{ osIcon }}</span>
                <span class="cp-illustration-commands--plus">+</span>
                <span class="cp-illustration-commands--key">c</span>
            </div>
        </button>
        <button *ngIf="canPasteFromClipboard" mat-menu-item (click)="pasteFromClipboard()" class="df aic">
            <div class="cp-illustration-prefix">Paste chart from clipboard</div>
            <div class="cp-illustration-commands">
                <span class="cp-illustration-commands--key cmd">{{ osIcon }}</span>
                <span class="cp-illustration-commands--plus">+</span>
                <span class="cp-illustration-commands--key">v</span>
            </div>
        </button>
        <mat-divider></mat-divider>
        <button *ngIf="canDuplicate" mat-menu-item (click)="duplicate()" class="df aic qa_charts_duplicate-chart">
            <i class="mright4 dku-icon-copy-12"></i>Duplicate
        </button>
        <button *ngIf="canDelete" mat-menu-item (click)="delete()" class="df aic text-error qa_charts_delete-chart">
            <i class="mright4 dku-icon-trash-12"></i>Delete
        </button>
    </ng-template>
</mat-menu>
