import type { AbstractNSampTestCard } from './common/abstract-nsamp-test-card';
import type { _AbstractNSampTestCard } from './common/abstract-nsamp-test-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.OneWayANOVACard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _OneWayANOVACard extends _AbstractNSampTestCard {
    type: "oneway_anova";
}

export type OneWayANOVACard = _OneWayANOVACard;

export namespace OneWayANOVACard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.OneWayANOVACard in PolyJSON mappings */
    export const type = "oneway_anova";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.OneWayANOVACard$OneWayANOVACardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _OneWayANOVACardResult extends AbstractNSampTestCard._AbstractNSampleTestCardResult {
        type: "oneway_anova";
        pvalue: number;
        statistic: number;
    }
    
    export type OneWayANOVACardResult = _OneWayANOVACardResult;

    export namespace OneWayANOVACardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.OneWayANOVACard$OneWayANOVACardResult in PolyJSON mappings */
        export const type = "oneway_anova";
    }
}