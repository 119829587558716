import type { CardResult } from './../card-result';
import type { CategoricalHistogramCard } from './categorical-histogram-card';
import type { UnivariateCard } from './../univariate-card';
import type { _CardResult } from './../card-result';
import type { _UnivariateCard } from './../univariate-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateFrequencyTableCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _UnivariateFrequencyTableCard extends _UnivariateCard {
    type: "univariate_frequency_table";
    maxValues: number;
}

export type UnivariateFrequencyTableCard = _UnivariateFrequencyTableCard;

export namespace UnivariateFrequencyTableCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateFrequencyTableCard in PolyJSON mappings */
    export const type = "univariate_frequency_table";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateFrequencyTableCard$UnivariateFrequencyTableCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _UnivariateFrequencyTableCardResult extends _CardResult {
        type: "univariate_frequency_table";
        distinctCount: number;
        frequencies: CategoricalHistogramCard.HistogramData;
    }
    
    export type UnivariateFrequencyTableCardResult = _UnivariateFrequencyTableCardResult;

    export namespace UnivariateFrequencyTableCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateFrequencyTableCard$UnivariateFrequencyTableCardResult in PolyJSON mappings */
        export const type = "univariate_frequency_table";
    }
}