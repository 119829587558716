import { Component, ChangeDetectionStrategy, Inject, inject, DestroyRef, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { catchAPIError, SimpleObservableErrorContext } from '@core/dataiku-api/api-error';
import { distinctUntilChanged, map, Observable, switchMap, filter, startWith, shareReplay } from 'rxjs';
import { DataCollectionsService } from '../../services/data-collections.service';
import { ITaggingService, SerializedProject, TaggableObjectsService, UIDataCollection } from 'src/generated-sources';
import { ProjectsService } from '@shared/services/projects.service';
import { fieldNonNullableGuard } from '@utils/objects';
import { observeFormControl } from '@utils/form-control-observer';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AccessibleObjectOption } from '@shared/components/accessible-objects-selector/accessible-objects-selector.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fairAny } from 'dku-frontend-core';

interface AddObjectToCollectionModalOptions {
    id: string;
    displayName: string;
}

export interface AddObjectToCollectionModalResult {
    response: UIDataCollection.AddObjectsResponse,
    requestedObjectsRefs: TaggableObjectsService.TaggableObjectRef[]
}

export type AuthorizationOptions = 'READ' | 'DISCOVER' | 'NONE';

const AUTHORIZATION_DESCRIPTIONS = [
    '&quot;None&quot; will prevent users from seeing the dataset in the data collection at all',
    '&quot;Allow Discover&quot; will allow users to see the dataset metadata',
    '&quot;Allow Read&quot; will allow users to additionally preview the dataset from the collection'
];

const REQUESTED_OBJECT_AUTHORIZATION_MAPPING: {
    [key in AuthorizationOptions]: SerializedProject.ReaderAuthorization.Mode[]
} = {
    NONE: [],
    DISCOVER: [SerializedProject.ReaderAuthorization.Mode.DISCOVER],
    READ: [SerializedProject.ReaderAuthorization.Mode.DISCOVER, SerializedProject.ReaderAuthorization.Mode.READ],
};

type ObjectOption = AccessibleObjectOption & {
    ref: TaggableObjectsService.TaggableObjectRef,
    authorizationOptions: {
        label: string;
        value: string;
        disabled: boolean;
    }[],
    suggestQuickSharing: boolean, // controls whether checkbox is enabled
    quickSharingTooltip: string, // explanation for when disabled
    enableQuickSharing: boolean, // controls whether the checkbox is checked by default
};

function makeObjectOption(obj: UIDataCollection.ObjectAuthorizationsWithDisplayName): ObjectOption {
    const showDiscoverable = !obj.allAuthorized && !obj.authorizations.includes(SerializedProject.ReaderAuthorization.Mode.READ);
    const showNone = showDiscoverable && !obj.authorizations.includes(SerializedProject.ReaderAuthorization.Mode.DISCOVER);
    return {
        ref: obj.ref,
        type: obj.ref.type,
        id: obj.ref.id,
        label: obj.displayName,
        localProject: true,
        usable: true,
        subtype: obj.subtype,

        authorizationOptions: [
            { label: 'None', value: 'NONE', disabled: !showNone},
            { label: 'Allow discover', value: 'DISCOVER', disabled: !showDiscoverable},
            { label: 'Allow read', value: 'READ', disabled: false},
        ],

        suggestQuickSharing: obj.canManageExposedElements && !obj.isQuicklyShareable,
        quickSharingTooltip: obj.isQuicklyShareable
            ? 'Quick sharing is already enabled on this dataset'
            : obj.canManageExposedElements
                ? ''
                : 'You don\'t have the permission to change this option',
        enableQuickSharing: obj.canManageExposedElements ? true : obj.isQuicklyShareable
    };
}

@Component({
    selector: 'dss-add-object-to-collection-modal',
    templateUrl: './add-object-to-collection-modal.component.html',
    styleUrls: ['./add-object-to-collection-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddObjectToCollectionModalComponent {
    errorContext = new SimpleObservableErrorContext();

    readonly authPopoverMessage = `Datasets with no object authorization will not be visible in the data-collection for users who don't have read access to the project. \n<a target="_blank" href="${this.$rootScope.versionDocRoot as string}data-catalog/data-collections/permissions-and-dataset-visibility.html">More details in the documentation</a>`;

    readonly projectKeyControl = new FormControl<string | null>({ value: null, disabled: false }, Validators.required);
    readonly objectOptionsForm = new FormArray<ReturnType<typeof this.createObjectFormGroup>>([]);
    readonly selectedObjectsControl = new FormControl<ObjectOption[]>({
        value: [],
        disabled: true
    }, {
        nonNullable: true,
        validators: Validators.required
    });

    readonly fullForm = new FormGroup({
        type: new FormControl(ITaggingService.TaggableType.DATASET, {nonNullable: true}),
        projectKey: this.projectKeyControl,
        selectedObjects: this.selectedObjectsControl,
        objectOptions: this.objectOptionsForm
    },
        // this global validator is required otherwise form would be considered as valid while dataset list is loading (because control is disabled)
        () => this.selectedObjectsControl.enabled ? null : {invalid: 'loading in progress'}
    );

    // the list of projects (loaded once)
    readonly projects$ = this.projectsService.list().pipe(
        catchAPIError(this.errorContext),
        // only show projects the user can actually publish from
        map(projects => projects.filter(project => project.canPublishToDataCollections))
    );

    // type of object (hidden form, only DATASET for now)
    readonly selectedType$ = observeFormControl(this.fullForm).pipe(
        filter(fieldNonNullableGuard('type')),
        map(({type}) => type)
    );

    // list of datasets to show in the multi-select menu. undefined while loading.
    readonly selectableObjects$: Observable<ObjectOption[] | undefined> = observeFormControl(this.fullForm).pipe(
        distinctUntilChanged((a, b) => a.projectKey === b.projectKey && a.type === b.type),
        filter(fieldNonNullableGuard('projectKey')), filter(fieldNonNullableGuard('type')),
        switchMap(({projectKey, type}) => {
            return this.dataCollectionsService.getPublishableObjects(projectKey, type).pipe(
                map(objects => objects.map(obj => makeObjectOption(obj))),
                startWith(undefined),
            );
        }),
        startWith(undefined),
        shareReplay(1),
        catchAPIError(this.errorContext)
    );

    // undefined when selection form is disabled
    readonly selectedObjects$ = observeFormControl(this.fullForm).pipe(
        map(({selectedObjects}) => selectedObjects),
        distinctUntilChanged()
    );

    readonly modalTitle: string;
    readonly objectAuthorizationDescriptions = AUTHORIZATION_DESCRIPTIONS;
    addActionPending = false;

    private readonly destroyRef = inject(DestroyRef);

    constructor(
        private dialogRef: MatDialogRef<AddObjectToCollectionModalComponent, AddObjectToCollectionModalResult>,
        @Inject(MAT_DIALOG_DATA) private data: AddObjectToCollectionModalOptions,
        private dataCollectionsService: DataCollectionsService,
        private projectsService: ProjectsService,
        private cd: ChangeDetectorRef,
        @Inject('$rootScope') public $rootScope: fairAny
    ) {
        this.modalTitle = 'Add datasets to "' + this.data.displayName + '"';

        // delete objects selection when changing the selectable list & disabled selectedObjectsControl while loading
        this.selectableObjects$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((selectableObjects) => {
            if(selectableObjects) {
                this.selectedObjectsControl.reset();
                this.selectedObjectsControl.enable();
            } else {
                this.selectedObjectsControl.disable();
            }
        });

        // when the selected objects are changed, add or remove elements to the formArray
        this.selectedObjects$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((selectedObjects) => {
            const currentValues = this.objectOptionsForm.getRawValue();
            this.objectOptionsForm.clear();
            selectedObjects?.forEach(obj => {
                const currentValue = currentValues.find(c => c.ref === obj.ref);
                this.objectOptionsForm.push(this.createObjectFormGroup(obj, currentValue));
            });
        });
    }

    cancel (): void {
        this.dialogRef.close();
    }

    confirm (): void {
        const { projectKey, objectOptions, selectedObjects } = this.fullForm.getRawValue();
        if(projectKey === null) return; // we clicked on add while the form is invalid, should never happen.

        const objectsToAdd: UIDataCollection.AddObject[] = objectOptions.map((o, i) => ({
            ref: o.ref,
            requestQuickSharing: selectedObjects[i].suggestQuickSharing && o.enableQuickSharing,
            requestedReaderAuthorizations: REQUESTED_OBJECT_AUTHORIZATION_MAPPING[o.requestedReaderAuthorizations]
        }));
        this.addActionPending = true;
        this.dataCollectionsService.addObjects(this.data.id, objectsToAdd).pipe(
            catchAPIError(this.errorContext),
        ).subscribe({
            next: (response) => {
                this.dialogRef.close({
                    response: response,
                    requestedObjectsRefs: objectsToAdd.map(({ref}) => ref)
                });
            },
            complete: () => {
                this.addActionPending = false;
                this.cd.detectChanges();
            }
        });
    }

    private createObjectFormGroup(object: ObjectOption, currentValue?: {enableQuickSharing: boolean, requestedReaderAuthorizations: AuthorizationOptions}) {
        return new FormGroup({
           ref: new FormControl<TaggableObjectsService.TaggableObjectRef>(object.ref, {nonNullable: true}),
           requestedReaderAuthorizations: new FormControl<AuthorizationOptions>(currentValue?.requestedReaderAuthorizations ?? 'READ', {nonNullable: true}),
           enableQuickSharing: new FormControl({
               value: currentValue?.enableQuickSharing ?? object.enableQuickSharing,
               disabled: !object.suggestQuickSharing,
           }, {nonNullable: true}),
       });
   }
}
