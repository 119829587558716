/** 
 * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubMetricParams
 * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams
 */
export interface _DeepHubMetricParams {
    confidenceScoreThresholdOptimMetric: DeepHubMetricParams.ConfidenceScoreThresholdOptimizationMetric;
    evaluationMetric: DeepHubMetricParams.EvaluationMetric;
}

export type DeepHubMetricParams = _DeepHubMetricParams;

export namespace DeepHubMetricParams {
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.prediction.DeepHubMetricParams$ConfidenceScoreThresholdOptimizationMetric
     * Via: com.dataiku.dip.analysis.model.prediction.DeepHubMetricParams
     */
    export enum ConfidenceScoreThresholdOptimizationMetric {
        F1 = "F1",
        PRECISION = "PRECISION",
        RECALL = "RECALL",
    }
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.prediction.DeepHubMetricParams$EvaluationMetric
     * Via: com.dataiku.dip.analysis.model.prediction.DeepHubMetricParams
     */
    export enum EvaluationMetric {
        AVERAGE_PRECISION_IOU50 = "AVERAGE_PRECISION_IOU50",
        AVERAGE_PRECISION_IOU75 = "AVERAGE_PRECISION_IOU75",
        AVERAGE_PRECISION_ALL_IOU = "AVERAGE_PRECISION_ALL_IOU",
        ROC_AUC = "ROC_AUC",
        PRECISION = "PRECISION",
        RECALL = "RECALL",
        F1 = "F1",
        ACCURACY = "ACCURACY",
        LOG_LOSS = "LOG_LOSS",
        AVERAGE_PRECISION = "AVERAGE_PRECISION",
    }
}