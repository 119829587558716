import type { UnitRootTest } from './unit-root-test';
import type { _UnitRootTest } from './unit-root-test';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestKPSS
 * Via: UIModel annotation in the class hierarchy
 */
export interface _UnitRootTestKPSS extends _UnitRootTest {
    type: "unit_root_kpss";
    nLags?: number | null;
    regressionMode: UnitRootTestKPSS.RegressionMode;
}

export type UnitRootTestKPSS = _UnitRootTestKPSS;

export namespace UnitRootTestKPSS {
    /** Type of class com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestKPSS in PolyJSON mappings */
    export const type = "unit_root_kpss";
    
    /** 
     * Translated from enum com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestKPSS$RegressionMode
     * Via: com.dataiku.dip.eda.worksheets.cards.UnitRootTestKPSSCard
     */
    export enum RegressionMode {
        CONSTANT = "CONSTANT",
        CONSTANT_WITH_TREND = "CONSTANT_WITH_TREND",
    }
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestKPSS$UnitRootTestKPSSResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _UnitRootTestKPSSResult extends UnitRootTest._UnitRootTestResult {
        type: "unit_root_kpss";
    }
    
    export type UnitRootTestKPSSResult = _UnitRootTestKPSSResult;

    export namespace UnitRootTestKPSSResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestKPSS$UnitRootTestKPSSResult in PolyJSON mappings */
        export const type = "unit_root_kpss";
    }
}