import type { Grouping } from './grouping';
import type { GroupingResult } from './grouping-result';
import type { _Grouping } from './grouping';
import type { _GroupingResult } from './grouping-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.grouping.CrossGrouping
 * Via: UIModel annotation in the class hierarchy
 */
export interface _CrossGrouping extends _Grouping {
    type: "cross";
    groupings: Grouping[];
}

export type CrossGrouping = _CrossGrouping;

export namespace CrossGrouping {
    /** Type of class com.dataiku.dip.eda.compute.grouping.CrossGrouping in PolyJSON mappings */
    export const type = "cross";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.grouping.CrossGrouping$CrossGroupingResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _CrossGroupingResult extends _GroupingResult {
        type: "cross";
        groups: GroupingResult[];
    }
    
    export type CrossGroupingResult = _CrossGroupingResult;

    export namespace CrossGroupingResult {
        /** Type of class com.dataiku.dip.eda.compute.grouping.CrossGrouping$CrossGroupingResult in PolyJSON mappings */
        export const type = "cross";
    }
}