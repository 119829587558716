import type { SanityCheckInfoMessages } from './../../../../../../../../app-platform/src/main/java/com/dataiku/dip/sanitycheck/sanity-check-info-messages';
import type { _SanityCheckInfoMessages } from './../../../../../../../../app-platform/src/main/java/com/dataiku/dip/sanitycheck/sanity-check-info-messages';

/** 
 * Translated from class com.dataiku.dip.coremodel.InfoMessage
 * Via: UIModel annotation in the class hierarchy
 */
export interface _InfoMessage {
    code: string;
    column: number;
    context: string;
    details: string;
    governLink: string;
    isFatal: boolean;
    line: number;
    lineTo: number;
    message: string;
    severity: InfoMessage.Severity;
    title: string;
}

/** List of all concrete subclasses of com.dataiku.dip.coremodel.InfoMessage */
export type InfoMessage =
    | _InfoMessage
    | SanityCheckInfoMessages.TrustedInfoMessage;

export namespace InfoMessage {
    /** 
     * Translated from enum com.dataiku.dip.coremodel.InfoMessage$FixabilityCategory
     * Via: UIModel annotation in the class hierarchy
     */
    export enum FixabilityCategory {
        USER_CONFIG = "USER_CONFIG",
        USER_CONFIG_DATASET = "USER_CONFIG_DATASET",
        USER_CONFIG_FOLDER = "USER_CONFIG_FOLDER",
        USER_CONFIG_OR_BUILD = "USER_CONFIG_OR_BUILD",
        USER_CONFIG_OR_ADMIN = "USER_CONFIG_OR_ADMIN",
        ADMIN_INSTALLATION = "ADMIN_INSTALLATION",
        ADMIN_SETTINGS_SECURITY = "ADMIN_SETTINGS_SECURITY",
        ADMIN_SETTINGS_CONNECTIONS = "ADMIN_SETTINGS_CONNECTIONS",
        ADMIN_SETTINGS_CONTAINERS = "ADMIN_SETTINGS_CONTAINERS",
        ADMIN_SETTINGS_CODEENVS = "ADMIN_SETTINGS_CODEENVS",
        ADMIN_SETTINGS_INTERNAL_CODEENVS = "ADMIN_SETTINGS_INTERNAL_CODEENVS",
        ADMIN_SETTINGS_PLUGINS = "ADMIN_SETTINGS_PLUGINS",
        ADMIN_SETTINGS_MISSING_PLUGIN = "ADMIN_SETTINGS_MISSING_PLUGIN",
        ADMIN_SETTINGS_USER_PROFILE = "ADMIN_SETTINGS_USER_PROFILE",
        ADMIN_SETTINGS_MISC = "ADMIN_SETTINGS_MISC",
        ADMIN_TROUBLESHOOTING = "ADMIN_TROUBLESHOOTING",
        PROJECT_SECURITY = "PROJECT_SECURITY",
        API_CALL = "API_CALL",
        READ_FUTURE_LOG = "READ_FUTURE_LOG",
        DATA = "DATA",
        MISSING_APP = "MISSING_APP",
        GOVERNANCE_STATUS = "GOVERNANCE_STATUS",
        THIRD_PARTY_SYSTEM_CONNECTION = "THIRD_PARTY_SYSTEM_CONNECTION",
        HADOOP_INTEGRATION = "HADOOP_INTEGRATION",
        THIRD_PARTY_SYSTEM = "THIRD_PARTY_SYSTEM",
        IRRELEVANT = "IRRELEVANT",
        UNKNOWN = "UNKNOWN",
    }
    /** 
     * Translated from class com.dataiku.dip.coremodel.InfoMessage$InfoMessages
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _InfoMessages {
        anyMessage: boolean;
        error: boolean;
        fatal: boolean;
        maxSeverity: InfoMessage.Severity;
        messages: InfoMessage[];
        success: boolean;
        warning: boolean;
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.coremodel.InfoMessage$InfoMessages */
    export type InfoMessages =
        | InfoMessage._InfoMessages
        | SanityCheckInfoMessages;
    
    /** 
     * Translated from enum com.dataiku.dip.coremodel.InfoMessage$Severity
     * Via: com.dataiku.dip.coremodel.InfoMessage
     */
    export enum Severity {
        SUCCESS = "SUCCESS",
        INFO = "INFO",
        WARNING = "WARNING",
        ERROR = "ERROR",
    }
}