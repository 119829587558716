import { AxisDef } from '@model-main/pivot/backend/model/axis-def';
import { AxisSortPrune } from '@model-main/pivot/backend/model/axis-sort-prune';
import { PivotTableResponse } from '@model-main/pivot/backend/model/pivot-table-response';
import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { SamplingParam } from '@model-main/datasets/sampling-param';

export const pivotTableTensorResponseWithExtraFrameOrFacet: PivotTableTensorResponse = {
    axisLabels: [
        [
            {
                label: '___dku_no_value___'
            },
            {
                label: 'M'
            },
            {
                label: 'F'
            }
        ],
        [
            {
                label: 'MacOS X'
            },
            {
                label: 'Windows'
            },
            {
                label: 'Linux'
            }
        ]
    ] as Array<any>,
    axisDefs: [
        {
            column: 'gender',
            type: AxisDef.Type.ALPHANUM,
            sortPrune: {
                sortType: AxisSortPrune.SortType.AGGREGATION,
                sortAscending: false,
                aggregationSortId: 0,
                generateOthersCategory: true,
                forceLastPositionOthers: true,
                filters: [],
                maxValues: 20
            }
        },
        {
            column: 'user_agent_os',
            type: AxisDef.Type.ALPHANUM,
            sortPrune: {
                sortType: AxisSortPrune.SortType.AGGREGATION,
                sortAscending: false,
                aggregationSortId: 0,
                generateOthersCategory: true,
                forceLastPositionOthers: true,
                filters: [],
                maxValues: 20
            }
        }
    ] as Array<any>,
    sequenceId: 0,
    aggregations: [
        {
            numAxes: 2,
            axisLengths: [
                4,
                4
            ],
            tensor: [
                65.08917197452229,
                53.18468085106383,
                55.098755186721995,
                0,
                56.164940828402365,
                57.35354200988468,
                50.62917079616782,
                0,
                55.17213114754098,
                60.488188976377955,
                45.50790513833992,
                0,
                0,
                0,
                0,
                0
            ],
            axes: [
                [
                    58.773549000951476,
                    56.43458781362007,
                    51.54113712374582,
                    0
                ],
                [
                    55.42835931700074,
                    54.213416444048676,
                    53.24471830985915,
                    0
                ]
            ],
            multipliers: [
                1,
                4
            ]
        }
    ] as Array<any>,
    counts: {
        numAxes: 2,
        axisLengths: [
            4,
            4
        ],
        tensor: [
            314,
            1175,
            1205,
            0,
            676,
            3035,
            3027,
            0,
            61,
            254,
            253,
            0,
            0,
            0,
            0,
            0
        ],
        axes: [
            [
                1051,
                4464,
                4485,
                0
            ],
            [
                2694,
                6738,
                568,
                0
            ]
        ],
        multipliers: [
            1,
            4
        ]
    },
    filterFacets: [],
    beforeFilterRecords: 10000,
    afterFilterRecords: 10000,
    engine: PivotTableResponse.PivotEngine.LINO,
    sampleMetadata: {
        samplingMethod: SamplingParam.SamplingMethod.HEAD_SEQUENTIAL,
        maxRecords: 10000,
        targetRatio: 0.02,
        hasFilter: false,
        partitionCount: 0,
        sampleIsWholeDataset: false,
        sampleRecordCount: -1,
        datasetRecordCount: -1,
        recordCountIsApproximate: false,
        recordCountIsObsolete: false,
        memoryLimitReached: false,
        memoryLimitInMB: 0
    },
    columnsSummary: undefined as any
};
