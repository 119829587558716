import { ChartZoomControl } from './chart-zoom-control.model';
import { Injectable } from '@angular/core';
import { LeafletMap } from '@features/simple-report/interfaces';
import { LeafletZoomControlInstance } from '@features/simple-report/models';
import { ChartZoomControlStoreService } from './chart-zoom-control-store.service';

@Injectable({
    providedIn: 'root'
})
export class LeafletZoomControlService extends ChartZoomControl {

    constructor(chartZoomControlStore: ChartZoomControlStoreService) {
        super(chartZoomControlStore);
    }

    create(map: LeafletMap, saveMap: () => void): string {
        const zoomControlInstance = new LeafletZoomControlInstance();
        const zoomControlInstanceId = zoomControlInstance.id;
        this.chartZoomControlStore.set(zoomControlInstanceId, zoomControlInstance);
        this.init(zoomControlInstanceId, map, saveMap);
        return zoomControlInstanceId;
    }
}
