import { Component, Input, Inject, Output, EventEmitter } from '@angular/core';
import { IState } from '@shared/models/i-state.model';
import { ChartIconUtils } from '@shared/models/chart-icon-utils.model';
import { ChartTypePickerCustomOption } from './chart-type-picker-custom-option.model';
import { ChartTypePickerOptions } from './chart-type-picker-options.model';
import { ChartTypeDef } from './chart-type-def.model';
import { ChartType } from '@model-main/pivot/frontend/model/chart-type';

@Component({
    selector: 'chart-type-picker',
    templateUrl: './chart-type-picker.component.html',
    styleUrls: ['./chart-type-picker.component.less']
})
export class ChartTypePickerComponent {
    private options: ChartTypePickerCustomOption[] = [];

    selectParams: Record<string, any> = {
        class: 'chart-type-picker__dropdown'
    };
    selectedChartId: string | undefined;
    sortedOptions: ChartTypePickerCustomOption[] = [];
    chartTypes: ChartTypeDef[] | undefined = [];
    optionClass: string = 'chart-type-picker__option';
    tooltipClass: string = 'mat-tooltip--right';
    classLastSuggestedChart: string = 'chart-type-picker__option--last-suggested-chart';
    classMissingPlugin: string = 'chart-type-picker__option--missing-plugin';

    @Input()
    set chartTypePickerOptions(value: ChartTypePickerOptions | undefined) {
        this.options = this.getOptions(value);
        this.sortedOptions = [...this.options];
        this.chartTypes = value?.chartTypes.concat(value?.webappTypes);

        //  Memorize selected option (always the one which is selected)
        const selectedOption = this.options.find(option => option.selected);
        this.selectedChartId = selectedOption?.id;

        if (this.selectedChartId) {
            this.sortedOptions = this.sortOptions(this.options, this.selectedChartId);
        }
    }

    @Output()
    chartChange = new EventEmitter<string>();

    constructor(
        @Inject('$state') private $state: IState,
        @Inject('ChartIconUtils') private chartIconUtils: ChartIconUtils
    ) {
    }

    private mapOptions(
        charts: ChartTypeDef[],
        compare: (chart: ChartTypeDef) => boolean,
        getImageSrc: (chart: ChartTypeDef) => string
    ): ChartTypePickerCustomOption[] {
        return charts.map(chart => {
            const option: ChartTypePickerCustomOption = {
                id: chart.id,
                displayName: chart.displayName,
                isWebapp: chart.isWebapp,
                imgSrc: getImageSrc(chart),
                selected: compare(chart),
                classes: [this.optionClass],
                chart
            };

            if (chart.isRequiredPluginInstalled === false || chart.tooltip) {

                option.tooltipDelayShow = 300;
                option.tooltipClass = this.tooltipClass;

                if (chart.isRequiredPluginInstalled === false) {
                    option.title = 'This chart requires the Reverse Geocoding plugin. Click to install it.';
                    option.classes?.push(this.classMissingPlugin);
                } else if (chart.tooltip) {
                    option.title = chart.tooltip;
                }
            }

            return option;
        });
    }

    private getOptions(chartTypePickerOptions: ChartTypePickerOptions | undefined): ChartTypePickerCustomOption[] {
        const isInAnalysis = this.$state.current.name.includes('analysis');
        let options: ChartTypePickerCustomOption[] = [];

        if (chartTypePickerOptions) {
            const chartCompare = (chart: ChartTypeDef) => (chart.type === chartTypePickerOptions.chartType && chart.variant === chartTypePickerOptions.chartVariant)
            //In older imported projects we can still see the colored pivot table, which is no longer a separate chart type
            //It needs to be translated to a regular pivot table
            || (chart.type === chartTypePickerOptions.chartType && chart.type === ChartType.pivot_table);

            const getImageSrc = (chart: ChartTypeDef) => {
                return this.chartIconUtils.computeChartIcon(chart.type, chart.variant, isInAnalysis, chart.webappType);
            };

            const optionsFromCharts = this.mapOptions(
                chartTypePickerOptions.chartTypes || [],
                chartCompare,
                getImageSrc
            );

            const webappCompare = (webapp: ChartTypeDef) => webapp.webappType === chartTypePickerOptions.webappType;

            const optionsFromWebapps = this.mapOptions(
                chartTypePickerOptions.webappTypes || [],
                webappCompare,
                getImageSrc
            );

            options = [...optionsFromCharts, ...optionsFromWebapps];
        }

        return options;
    }

    /**
     * Places the suggested charts at the beginning of the list, puts the ones previously suggested back in their places
     * @param options - default options for dku-bs-select
     * @param selectedChartId - id of the chart selected by the user
     */
    private sortOptions(options: ChartTypePickerCustomOption[], selectedChartId: string): ChartTypePickerCustomOption[] {
        let sortedOptions = [...options];
        const selectedOptionIndex = sortedOptions.findIndex(option => option.id === selectedChartId);

        if (selectedOptionIndex >= 0) {

            const lastSelectedOption = this.options.find(option => option.selected);
            if (lastSelectedOption) {
                lastSelectedOption.selected = false;
            }

            const lastSuggestedOption = sortedOptions.find(item => item.classes?.includes(this.classLastSuggestedChart));
            if (lastSuggestedOption) {
                const index = lastSelectedOption?.classes?.indexOf(this.classLastSuggestedChart);
                if (index) {
                    lastSuggestedOption.classes?.splice(index, 1);
                }
            }

            const selectedOption = sortedOptions[selectedOptionIndex];
            selectedOption.selected = true;

            const similars = selectedOption?.chart.similar;

            if (similars?.length) {
                sortedOptions.splice(selectedOptionIndex, 1)[0];
                let result: { similarOptions: ChartTypePickerCustomOption[], otherOptions: ChartTypePickerCustomOption[] } = { similarOptions: [], otherOptions: [] };

                result = sortedOptions.reduce((acc, option) => {
                    if (similars.includes(option.id)) {
                        acc.similarOptions.push(option);
                    } else {
                        acc.otherOptions.push(option);
                    }
                    return acc;
                }, result);

                result.similarOptions[result.similarOptions.length - 1].classes?.push(this.classLastSuggestedChart);

                sortedOptions = [...result.similarOptions, selectedOption, ...result.otherOptions];
            }
        }

        return sortedOptions;
    }

    selectChartId(chartId?: string, sort = true): void {
        if (chartId) {
            const oldSelectedChartOptionIndex = this.options.findIndex(option => option.id === this.selectedChartId);
            const newSelectedChartOptionIndex = this.options.findIndex(option => option.id === chartId);

            if (newSelectedChartOptionIndex >= 0) {
                if (sort) {
                    this.sortedOptions = this.sortOptions(this.options, chartId);
                }
                if (oldSelectedChartOptionIndex !== newSelectedChartOptionIndex) {
                    this.selectedChartId = chartId;
                    if (this.chartTypes && this.chartTypes[newSelectedChartOptionIndex].isRequiredPluginInstalled === false) {
                        this.redirectToPluginPage(chartId);
                    } else {
                        this.chartChange.next(this.selectedChartId);
                    }
                }
            }
        }
    }

    applyCustomSearch = (searchTerm: string, chart: ChartTypePickerCustomOption) => {
        const chartType = chart.chart;

        if (chartType && searchTerm) {
            const searchWords = searchTerm.toLowerCase().split(/[, ]+/);
            let wordsToMatch = searchWords.length;

            let searchArray = chartType.displayName.toLowerCase().split(' ');
            if (chartType.keywords) {
                searchArray = searchArray.concat(chartType.keywords);
            }

            for (let i = 0; i < searchWords.length; i++) {
                const formattedWord = searchWords[i].trim();
                for (let i = 0; i < searchArray.length; i++) {
                    if (searchArray[i].includes(formattedWord)) {
                        wordsToMatch--;
                        break;
                    }
                }
            }
            if (!wordsToMatch) {
                return true;
            }
        }
        return false;
    };

    redirectToPluginPage = (chartId: string) => {
        const chartType = this.chartTypes?.find(item => item.id === chartId);
        if (chartType && chartType.requiredPluginId) {
            this.$state.go('plugins.store', { pluginid: chartType.requiredPluginId });
        }
    };
}
