import { Injectable } from '@angular/core';
import { ResolutionResult } from '@model-main/labeling/consensus/resolution-result';
import { DkuHttpService, FutureWatcherService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LabelingAnswer, LabelingRecord, LabelingRecordToAnnotate, LabelingTask, LabelingTaskStats, ReviewRecordInfo, SerializedMemTableV2, SerializedTableChunk, VerifiedLabelingAnswer } from 'src/generated-sources';

@Injectable({providedIn: 'root'})
export class PrivateLabelingTasksAPI {
    constructor(private dkuHttp: DkuHttpService, private futureWatcherService: FutureWatcherService) {
    }

    get(projectKey: string, labelingTaskId: string): Observable<LabelingTask> {
        return this.dkuHttp.request('GET', '/labelingtasks/get', { projectKey, id: labelingTaskId }, false);
    }

    saveAnswer(projectKey: string, labelingAnswer: Partial<LabelingAnswer>): Observable<LabelingAnswer> {
        return this.dkuHttp.request('POST', '/labelinganswers/save', { projectKey, labelingAnswer }, false);
    }

    resolveRecord(projectKey: string, labelingTaskId: string, labelingAnswer: Partial<LabelingAnswer>): Observable<VerifiedLabelingAnswer> {
        return this.dkuHttp.request('POST', '/labelingtasks/resolve-record', { projectKey, labelingTaskId, labelingAnswer}, false);
    }

    resolveConsensualRecords(projectKey: string, labelingTaskId: string): Observable<ResolutionResult> {
        return this.futureWatcherService.requestFuture<ResolutionResult>(
            'POST',
             '/labelingtasks/resolve-consensual-records',
            { projectKey, labelingTaskId}
        ).pipe(this.futureWatcherService.waitForResult());
    }
    getAnswerFromAnnotator(projectKey: string, labelingTaskId: string, recordId: string): Observable<LabelingAnswer | null> {
        return this.dkuHttp.request('GET', '/labelinganswers/get-annotation-from-annotator', { projectKey, labelingTaskId, recordId }, false).pipe(
            map((answer) => answer ? answer as LabelingAnswer : null)
        );
    }

    deleteAnswersForRecordId(projectKey: string, labelingTaskId: string, recordId: string): Observable<void> {
        return this.dkuHttp.request('POST', '/labelinganswers/delete-annotations-from-record', { projectKey, labelingTaskId, recordId }, false);
    }

    listReviewRecordInfo(projectKey: string, labelingTaskId: string, recordIds: string[]): Observable<ReviewRecordInfo[]> {
        return this.dkuHttp.request('POST', '/labelinganswers/list-review-record-info', { projectKey, labelingTaskId, recordIds }, false);
    }

    getRecordToAnnotate(projectKey: string, labelingTaskId: string, currentRecordId?: string): Observable<LabelingRecordToAnnotate> {
        return this.dkuHttp.request('GET', '/labelingtasks/get-next-record-to-annotate', { projectKey, labelingTaskId, currentRecordId}, false);
    }
    getRecord(projectKey: string, labelingTaskId: string, recordId: string): Observable<LabelingRecord> {
        return this.dkuHttp.request('GET', '/labelingtasks/get-record', { projectKey, labelingTaskId, recordId }, false);
    }

    getRecordIdsToReview(projectKey: string, labelingTaskId: string): Observable<string[]> {
        return this.dkuHttp.request('GET', '/labelingtasks/get-record-ids-to-review', { projectKey, labelingTaskId }, false);
    }

    getReviewedRecordIds(projectKey: string, labelingTaskId: string): Observable<string[]> {
        return this.dkuHttp.request('GET', '/labelingtasks/get-reviewed-record-ids', { projectKey, labelingTaskId }, false);
    }

    refreshImagesDataSample(projectKey: string, labelingTaskId: string, nbRows: number) : Observable<SerializedMemTableV2>{
        return this.dkuHttp.request('GET', '/labelingtasks/refresh-images-data-sample', {projectKey, labelingTaskId, nbRows}, false);
    }

    getImagesDataChunk(projectKey: string, labelingTaskId: string, nbRows: number, offset: number) : Observable<SerializedTableChunk>{
        return this.dkuHttp.request('GET', '/labelingtasks/get-images-data-chunk', { projectKey, labelingTaskId, offset, nbRows }, false);
    }

    getStats(projectKey: string,  labelingTaskId: string): Observable<LabelingTaskStats> {
        return this.dkuHttp.request('GET', '/labelingtasks/get-stats', { projectKey, labelingTaskId }, false);
    }

    listAnnotatorIds(projectKey: string,  labelingTaskId: string): Observable<string[]> {
        return this.dkuHttp.request('GET', '/labelingtasks/list-annotator-ids', { projectKey, labelingTaskId }, false);
    }

    countAnswers(projectKey: string,  labelingTaskId: string): Observable<number> {
        return this.dkuHttp.request('GET', '/labelinganswers/count', { projectKey, labelingTaskId }, false).pipe(
            map((res: any) => res.allAnswersCount)
        );
    }
}

