export namespace PredictionModelingParams {
    /** 
     * Translated from class com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GpuCodeParams
     * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams
     */
    export interface _GpuCodeParams {
        gpuList: number[];
        useGPU: boolean;
    }
    
    /**
        Translate from class com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GpuCodeParams
        @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
    */
    export type GpuCodeParams = _GpuCodeParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibCustomGridParams
     * Via: com.dataiku.dip.analysis.model.ClusteringModelingParams
     */
    export interface _MLLibCustomGridParams {
        enabled: boolean;
        initializationCode: string;
        name: string;
    }
    
    export type MLLibCustomGridParams = _MLLibCustomGridParams;

    export namespace GridSearchParams {
        /** 
         * Translated from enum com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GridSearchParams$Strategy
         * Via: com.dataiku.dip.analysis.model.ModelTrainInfo$PreSearchDescription
         */
        export enum Strategy {
            GRID = "GRID",
            RANDOM = "RANDOM",
            BAYESIAN = "BAYESIAN",
        }
    }
}