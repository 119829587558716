import type { CheckTimeStepRegularity } from './check-time-step-regularity';
import type { DebugCard } from './debug-card';
import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { ListMostFrequentValues } from './list-most-frequent-values';
import type { SuggestCards } from './suggest-cards';
import type { _CheckTimeStepRegularity } from './check-time-step-regularity';
import type { _DebugCard } from './debug-card';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _ListMostFrequentValues } from './list-most-frequent-values';
import type { _SuggestCards } from './suggest-cards';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.queries.InteractiveQuery
 * Via: UIModel annotation in the class hierarchy
 */
export interface _InteractiveQuery extends _ImmutableValueObject {
}

/** List of all concrete subclasses of com.dataiku.dip.eda.worksheets.queries.InteractiveQuery */
export type InteractiveQuery =
    | CheckTimeStepRegularity
    | DebugCard
    | ListMostFrequentValues
    | SuggestCards;

export namespace InteractiveQuery {
    /** Types of class com.dataiku.dip.eda.worksheets.queries.InteractiveQuery in PolyJSON mappings */
    export const type = ["check_time_step_regularity", "debug_card", "list_most_frequent_values", "suggest_cards", ] as const;
    
    export function isCheckTimeStepRegularity(obj: InteractiveQuery): obj is CheckTimeStepRegularity { return obj["type"] == "check_time_step_regularity"; }
    export function isDebugCard(obj: InteractiveQuery): obj is DebugCard { return obj["type"] == "debug_card"; }
    export function isInteractiveQuery(obj: InteractiveQuery): obj is InteractiveQuery { return ["check_time_step_regularity", "debug_card", "list_most_frequent_values", "suggest_cards", ].includes(obj["type"]); }
    export function isListMostFrequentValues(obj: InteractiveQuery): obj is ListMostFrequentValues { return obj["type"] == "list_most_frequent_values"; }
    export function isSuggestCards(obj: InteractiveQuery): obj is SuggestCards { return obj["type"] == "suggest_cards"; }
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.queries.InteractiveQuery$InteractiveQueryResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _InteractiveQueryResult {
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.eda.worksheets.queries.InteractiveQuery$InteractiveQueryResult */
    export type InteractiveQueryResult =
        | CheckTimeStepRegularity.CheckTimeStepRegularityResult
        | DebugCard.DebugCardResult
        | ListMostFrequentValues.ListMostFrequentValuesResult
        | SuggestCards.SuggestCardsResult;

    export namespace InteractiveQueryResult {
        /** Types of class com.dataiku.dip.eda.worksheets.queries.InteractiveQuery$InteractiveQueryResult in PolyJSON mappings */
        export const type = ["check_time_step_regularity", "debug_card", "list_most_frequent_values", "suggest_cards", ] as const;
        
        export function isCheckTimeStepRegularityResult(obj: InteractiveQuery.InteractiveQueryResult): obj is CheckTimeStepRegularity.CheckTimeStepRegularityResult { return obj["type"] == "check_time_step_regularity"; }
        export function isDebugCardResult(obj: InteractiveQuery.InteractiveQueryResult): obj is DebugCard.DebugCardResult { return obj["type"] == "debug_card"; }
        export function isInteractiveQueryResult(obj: InteractiveQuery.InteractiveQueryResult): obj is InteractiveQuery.InteractiveQueryResult { return ["check_time_step_regularity", "debug_card", "list_most_frequent_values", "suggest_cards", ].includes(obj["type"]); }
        export function isListMostFrequentValuesResult(obj: InteractiveQuery.InteractiveQueryResult): obj is ListMostFrequentValues.ListMostFrequentValuesResult { return obj["type"] == "list_most_frequent_values"; }
        export function isSuggestCardsResult(obj: InteractiveQuery.InteractiveQueryResult): obj is SuggestCards.SuggestCardsResult { return obj["type"] == "suggest_cards"; }
    }
}