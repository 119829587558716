/** 
 * Translated from class com.dataiku.dip.fs.FilesSelectionRule
 * Via: com.dataiku.dip.fs.FilesSelectionRules
 */
export interface _FilesSelectionRule {
    expr: string;
    matchingMode: FilesSelectionRule.MatchingMode;
    mode: FilesSelectionRule.Mode;
}

export type FilesSelectionRule = _FilesSelectionRule;

export namespace FilesSelectionRule {
    /** 
     * Translated from enum com.dataiku.dip.fs.FilesSelectionRule$MatchingMode
     * Via: com.dataiku.dip.fs.FilesSelectionRule
     */
    export enum MatchingMode {
        FULL_PATH = "FULL_PATH",
        FILENAME = "FILENAME",
    }
    /** 
     * Translated from enum com.dataiku.dip.fs.FilesSelectionRule$Mode
     * Via: com.dataiku.dip.fs.FilesSelectionRule
     */
    export enum Mode {
        GLOB = "GLOB",
        REGEXP = "REGEXP",
    }
}