import type { AnumGrouping } from './anum-grouping';
import type { BinnedGrouping } from './binned-grouping';
import type { CrossGrouping } from './cross-grouping';
import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { MergeGrouping } from './merge-grouping';
import type { MultiAnumGrouping } from './multi-anum-grouping';
import type { SubsampledGrouping } from './subsampled-grouping';
import type { SubsetGrouping } from './subset-grouping';
import type { TopNTimeGrouping } from './top-ntime-grouping';
import type { UnionGrouping } from './union-grouping';
import type { _AnumGrouping } from './anum-grouping';
import type { _BinnedGrouping } from './binned-grouping';
import type { _CrossGrouping } from './cross-grouping';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _MergeGrouping } from './merge-grouping';
import type { _MultiAnumGrouping } from './multi-anum-grouping';
import type { _SubsampledGrouping } from './subsampled-grouping';
import type { _SubsetGrouping } from './subset-grouping';
import type { _TopNTimeGrouping } from './top-ntime-grouping';
import type { _UnionGrouping } from './union-grouping';

/** 
 * Translated from class com.dataiku.dip.eda.compute.grouping.Grouping
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Grouping extends _ImmutableValueObject {
}

/** List of all concrete subclasses of com.dataiku.dip.eda.compute.grouping.Grouping */
export type Grouping =
    | AnumGrouping
    | BinnedGrouping
    | CrossGrouping
    | MergeGrouping
    | MultiAnumGrouping
    | SubsampledGrouping
    | SubsetGrouping
    | TopNTimeGrouping
    | UnionGrouping;

export namespace Grouping {
    /** Types of class com.dataiku.dip.eda.compute.grouping.Grouping in PolyJSON mappings */
    export const type = ["anum", "binned", "cross", "merge", "multi_anum", "subsampled", "subset", "topn_time", "union", ] as const;
    
    export function isAnumGrouping(obj: Grouping): obj is AnumGrouping { return obj["type"] == "anum"; }
    export function isBinnedGrouping(obj: Grouping): obj is BinnedGrouping { return obj["type"] == "binned"; }
    export function isCrossGrouping(obj: Grouping): obj is CrossGrouping { return obj["type"] == "cross"; }
    export function isGrouping(obj: Grouping): obj is Grouping { return ["anum", "binned", "cross", "merge", "multi_anum", "subsampled", "subset", "topn_time", "union", ].includes(obj["type"]); }
    export function isMergeGrouping(obj: Grouping): obj is MergeGrouping { return obj["type"] == "merge"; }
    export function isMultiAnumGrouping(obj: Grouping): obj is MultiAnumGrouping { return obj["type"] == "multi_anum"; }
    export function isSubsampledGrouping(obj: Grouping): obj is SubsampledGrouping { return obj["type"] == "subsampled"; }
    export function isSubsetGrouping(obj: Grouping): obj is SubsetGrouping { return obj["type"] == "subset"; }
    export function isTopNTimeGrouping(obj: Grouping): obj is TopNTimeGrouping { return obj["type"] == "topn_time"; }
    export function isUnionGrouping(obj: Grouping): obj is UnionGrouping { return obj["type"] == "union"; }
}