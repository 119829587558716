import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class PrivateExploresAPI {
    constructor(private dkuHttp: DkuHttpService) {
    }

    get(projectKey: string, datasetSmartName: string): Observable<any> {
        return this.dkuHttp.request('GET', '/explores/get', { projectKey, datasetSmartName }, false);
    }
}
