import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash';
import { D3V3 } from '../../interfaces';
import { D3V3ZoomControlInstance } from '../../models';
import { ChartZoomLoaderService } from './chart-zoom-loader.service';
import { ChartZoomControlStoreService } from './chart-zoom-control-store.service';
import { ChartZoomControl } from './chart-zoom-control.model';

@Injectable({
    providedIn: 'root'
})
export class D3ChartZoomControlService extends ChartZoomControl {
    constructor(@Inject('Logger') private loggerFactory: any, private chartZoomLoaderService: ChartZoomLoaderService, chartZoomControlStore: ChartZoomControlStoreService) {
        super(chartZoomControlStore);
    }

    create(d3: D3V3, d3Canvas: any, xScale: any, yScale: any): string {
        const zoomControlInstance = new D3V3ZoomControlInstance(
            this.loggerFactory,
            this.chartZoomLoaderService,
            d3,
            d3Canvas,
            xScale,
            yScale
        );
        const zoomControlInstanceId = zoomControlInstance.id;

        this.chartZoomControlStore.set(zoomControlInstanceId, zoomControlInstance);

        return zoomControlInstanceId;
    }

    isActivated(id: string): boolean {
        const zoomControlInstance = this.chartZoomControlStore.get(id) as D3V3ZoomControlInstance;
        return !isNil(zoomControlInstance) && zoomControlInstance.isActivated();
    }

    isEnabled(id: string): boolean {
        const zoomControlInstance = this.chartZoomControlStore.get(id) as D3V3ZoomControlInstance;
        return !isNil(zoomControlInstance) && zoomControlInstance.isEnabled();
    }

    isZooming(id: string): boolean {
        const zoomControlInstance = this.chartZoomControlStore.get(id) as D3V3ZoomControlInstance;
        return !isNil(zoomControlInstance) && zoomControlInstance.isZooming();
    }

    isZoomed(id: string): boolean {
        const zoomControlInstance = this.chartZoomControlStore.get(id) as D3V3ZoomControlInstance;
        return !isNil(zoomControlInstance) && zoomControlInstance.isZoomed();
    }
}
