export namespace FlowComputable {
    /** 
     * Translated from enum com.dataiku.dip.dataflow.graph.FlowComputable$FCType
     * Via: com.dataiku.dip.server.UsabilityComputer$ListSettings
     */
    export enum FCType {
        DATASET = "DATASET",
        SAVED_MODEL = "SAVED_MODEL",
        MODEL_EVALUATION_STORE = "MODEL_EVALUATION_STORE",
        MANAGED_FOLDER = "MANAGED_FOLDER",
        STREAMING_ENDPOINT = "STREAMING_ENDPOINT",
        RETRIEVABLE_KNOWLEDGE = "RETRIEVABLE_KNOWLEDGE",
    }
}