import type { CardResult } from './card-result';
import type { HeatmapParams } from './common/heatmap-params';
import type { MultivariateCard } from './multivariate-card';
import type { _CardResult } from './card-result';
import type { _HeatmapParams } from './common/heatmap-params';
import type { _MultivariateCard } from './multivariate-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.CorrelationMatrixCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _CorrelationMatrixCard extends _MultivariateCard {
    type: "correlation_matrix";
    heatmapParams: HeatmapParams;
    metric: CorrelationMatrixCard.CorrelationMetric;
}

export type CorrelationMatrixCard = _CorrelationMatrixCard;

export namespace CorrelationMatrixCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.CorrelationMatrixCard in PolyJSON mappings */
    export const type = "correlation_matrix";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.CorrelationMatrixCard$CorrelationMatrixCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _CorrelationMatrixCardResult extends _CardResult {
        type: "correlation_matrix";
        partialErrors?: string[] | null;
        scores: number[];
    }
    
    export type CorrelationMatrixCardResult = _CorrelationMatrixCardResult;
    
    /** 
     * Translated from enum com.dataiku.dip.eda.worksheets.cards.CorrelationMatrixCard$CorrelationMetric
     * Via: com.dataiku.dip.eda.worksheets.cards.CorrelationMatrixCard
     */
    export enum CorrelationMetric {
        PEARSON = "PEARSON",
        SPEARMAN = "SPEARMAN",
    }

    export namespace CorrelationMatrixCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.CorrelationMatrixCard$CorrelationMatrixCardResult in PolyJSON mappings */
        export const type = "correlation_matrix";
    }
}