import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _AvailableResult } from './../available-result';
import type { _ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.Quantiles
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Quantiles extends Computation._UnivariateComputation {
    type: "quantiles";
    confidence?: number | null;
    freqs: number[];
}

export type Quantiles = _Quantiles;

export namespace Quantiles {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.Quantiles in PolyJSON mappings */
    export const type = "quantiles";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.Quantiles$QuantileDesc
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _QuantileDesc extends _ImmutableValueObject {
        freq: number;
        lower?: number | null;
        quantile: number;
        upper?: number | null;
    }
    
    export type QuantileDesc = _QuantileDesc;
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.Quantiles$QuantilesResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _QuantilesResult extends _AvailableResult {
        type: "quantiles";
        quantiles: Quantiles.QuantileDesc[];
    }
    
    export type QuantilesResult = _QuantilesResult;

    export namespace QuantilesResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.Quantiles$QuantilesResult in PolyJSON mappings */
        export const type = "quantiles";
    }
}