import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RegressionTypes } from '../../../constants';
import { RegressionLineOptionsService } from './regression-line-options.service';

@Component({
    selector: 'regression-line-form',
    templateUrl: './regression-line-form.component.html',
    styleUrls: ['../forms.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegressionLineFormComponent implements OnInit {
    @Output() regressionLineChange = new EventEmitter<Partial<ChartDef.Regression>>();

    public REGRESSION_TYPES = ChartDef.RegressionType;
    public regressionTypes;
    public labelPositionOptions;
    public fontSizeOptions: number[];
    public colors;
    public cutValuesWarning = false;
    public logWarning = false;
    public expWarning = false;

    public regressionLineForm: UntypedFormGroup = new UntypedFormGroup({
        show: new UntypedFormControl(null),
        displayFormula: new UntypedFormControl(null),
        type: new UntypedFormControl(null),
        lineColor: new UntypedFormControl('#000'),
        lineSize: new UntypedFormControl(1),
        labelPosition: new UntypedFormControl(ChartDef.RegressionLinePosition.INSIDE_END),
        textFormatting: new UntypedFormControl()
    });

    constructor(regressionLineOptions: RegressionLineOptionsService) {
        this.regressionTypes = regressionLineOptions.regressionTypes;
        this.labelPositionOptions = regressionLineOptions.labelPositionOptions;
        this.colors = regressionLineOptions.colors;
    }

    @Input()
    set displayLogWarning(value: boolean) {
        this.logWarning = value;
        this.updateWarning();
    }

    @Input()
    set displayExpWarning(value: boolean) {
        this.expWarning = value;
        this.updateWarning();
    }

    @Input()
    set regressionLine(value: ChartDef.Regression) {
        if (!this.equals(value, this.regressionLineForm.getRawValue())) {
            this.regressionLineForm.patchValue(value);
        }
    }

    hasRegressionLine() {
        return this.regressionLineForm.value.show;
    }

    addRegression() {
        this.regressionLineForm.patchValue({ show: true });
    }

    deleteRegression() {
        this.regressionLineForm.patchValue({ show: false });
    }

    updateWarning() {
        this.cutValuesWarning = this.logWarning && this.regressionLineForm.value.type === RegressionTypes.LOGARITHMIC || this.expWarning && this.regressionLineForm.value.type === RegressionTypes.EXPONENTIAL;
    }

    ngOnInit(): void {
        this.regressionLineForm.valueChanges
            .pipe(
                debounceTime(10),
                distinctUntilChanged(this.equals)
            )
            .subscribe((nextValue: Partial<ChartDef.Regression>) => {
                this.updateWarning();
                this.regressionLineChange.emit({ ...nextValue });
            });
    }

    private equals(regressionLine: ChartDef.Regression, regressionLineForm: Partial<ChartDef.Regression>) {
        return regressionLine && regressionLine.lineColor === regressionLineForm.lineColor &&
            regressionLine.lineSize === regressionLineForm.lineSize &&
            regressionLine.displayFormula === regressionLineForm.displayFormula &&
            regressionLine.show === regressionLineForm.show &&
            regressionLine.type === regressionLineForm.type &&
            regressionLine.labelPosition === regressionLineForm.labelPosition &&
            regressionLine.textFormatting === regressionLineForm.textFormatting;
    }
}
