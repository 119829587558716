import { Injectable } from '@angular/core';
import { Workspace } from '@model-main/workspaces/workspace';
import { LeftPaneState, WorkspaceListType } from '../models';
import { ObjectSort, ObjectSortProperties } from '@features/workspaces/features/workspace-page/models';

@Injectable({
    providedIn: 'root',
})
export class WorkspaceLocalStorageService {

    constructor() { }

    private getLocalStorageKeyPrefix(userId: string, workspaceKey: string) {
        return `dss.workspaces.params.${userId}.${workspaceKey}`;
    }

    private getLocalStorageKey(userId: string, workspaceKey: string, object: Workspace.WorkspaceObject) {
        if (object?.reference) {
            return `${this.getLocalStorageKeyPrefix(userId, workspaceKey)}.${object.reference.projectKey}.${object.reference.type}.${object.reference.id}`;
        }
        return null;
    }

    getLocalObjectParams(userId: string, workspaceKey: string, object: Workspace.WorkspaceObject) {
        const key = this.getLocalStorageKey(userId, workspaceKey, object);
        if (key) {
            const item = localStorage.getItem(key);
            if (item) {
                return JSON.parse(item);
            }
        }
        return null;
    }

    saveLocalObjectParams(userId: string, workspaceKey: string, object: Workspace.WorkspaceObject, params: { [key: string]: string }) {
        const key = this.getLocalStorageKey(userId, workspaceKey, object);
        if (key) {
            localStorage.setItem(key, JSON.stringify(params));
        }
    }

    saveLastOpenedWorkspace(workspaceKey: string) {
        localStorage.setItem('lastOpenedWorkspace', workspaceKey);
    }

    getLastOpenedWorkspace() {
        return localStorage.getItem('lastOpenedWorkspace');
    }

    saveLeftPaneState(state: LeftPaneState) {
        localStorage.setItem('leftPaneState', state);
    }

    getLeftPaneState(): LeftPaneState {
        const state = localStorage.getItem('leftPaneState');
        return LeftPaneState.CLOSED == state ? LeftPaneState.CLOSED : LeftPaneState.OPEN;
    }

    saveListType(listType: WorkspaceListType) {
        localStorage.setItem('listType', listType);
    }

    getListType(): WorkspaceListType {
        const listType = localStorage.getItem('listType');
        return WorkspaceListType.LIST === listType ? WorkspaceListType.LIST : WorkspaceListType.TILE;
    }

    saveObjectSortType(objectSort: ObjectSort) {
        localStorage.setItem('objectSortType', objectSort.by);
    }

    saveObjectSortReverse(objectSort: ObjectSort) {
        localStorage.setItem('objectSortReverse', objectSort.reverse.toString());
    }

    getObjectSort(): ObjectSort {
        const objectSortType = localStorage.getItem('objectSortType');
        const objectSortReverse = localStorage.getItem('objectSortReverse');
        return ObjectSortProperties.TYPE === objectSortType ? { by: ObjectSortProperties.TYPE, reverse: objectSortReverse === "true" } : { by: ObjectSortProperties.DISPLAY_NAME, reverse: objectSortReverse === "true" };
    } 

    removeUnusedLocalObjectParams(userId: string, workspace: Workspace) {
        const keyPrefix = this.getLocalStorageKeyPrefix(userId, workspace.workspaceKey);
        const isNotUsed = (key: string) => !workspace.workspaceObjects.some(object => this.getLocalStorageKey(userId, workspace.workspaceKey, object) === key);
        let key;

        for (let i = localStorage.length - 1; i >= 0; --i) {
            key = localStorage.key(i);
            if (key?.startsWith(keyPrefix) && isNotUsed(key)) {
                localStorage.removeItem(key);
            }
        }
    }
}
