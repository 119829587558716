import { ChartZoomControlInstance } from '../../models';
import { ChartZoomControlStoreService } from './chart-zoom-control-store.service';

export abstract class ChartZoomControl {
    constructor(protected chartZoomControlStore: ChartZoomControlStoreService) {}

    init(id: string, ...args: any): ChartZoomControlInstance {
        const zoomControlInstance = this.chartZoomControlStore.get(id);
        zoomControlInstance.init(...args);
        return zoomControlInstance;
    }

    abstract create(...args: any): string;
}
