import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, NgZone, OnChanges, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors, Validator, NG_VALIDATORS } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import type { CompareWithFn } from '@ng-select/ng-select/lib/ng-select.component';
import { NgSelectActionsBoxComponent } from '@shared/components/ng-select-actions-box/ng-select-actions-box.component';
import { isDefined, isObject } from '@utils/objects';
import { normalizeTextForSearch } from '@utils/string-utils';
import _ from 'lodash';
import { IsAny } from 'type-fest';


/**
 * Local constants
 */
export const DEFAULT_GHOST_SECTION_TITLE = "Invalid options";


/**
 * Typing of property accessor and inference of item value type
 */
type PropertyAccessorKey<ItemType> = keyof ItemType;
type PropertyAccessorFn<ItemType, PropertyType> = (value: ItemType) => PropertyType;

export type PropertyAccessor<ItemType, PropertyType> =
    PropertyAccessorKey<ItemType>
    | PropertyAccessorFn<ItemType, PropertyType>
    | undefined;

export type PropertyAccessorReturnType<ItemType, PropertyAccessorType extends PropertyAccessor<ItemType, unknown>> =
    PropertyAccessorType extends PropertyAccessorKey<ItemType>
        ? ItemType[PropertyAccessorType]
            : PropertyAccessorType extends PropertyAccessorFn<ItemType, infer R>
                ? R
                : ItemType;

// When 'bindValue' is not set, ValueBinderType is inferred to 'any' instead of 'undefined'. Consequently, we explicitely decide to treat 'any' as 'undefined' in this case (but it is wrong in the general case)
export type InferredValueType<ItemType, ValueBinderType extends PropertyAccessor<ItemType, unknown>> = PropertyAccessorReturnType<ItemType, (IsAny<ValueBinderType> extends true ? undefined : ValueBinderType)>;


function readProperty<ItemType, PropertyType>(item: ItemType, accessor: PropertyAccessor<ItemType, PropertyType>) : PropertyType | ItemType {
    if (typeof accessor == 'string' || typeof accessor == 'number') {
        return item[accessor] as PropertyType;
    }
    if (typeof accessor == 'function') {
        return accessor(item);
    }
    return item;
}


/**
 * Lightweight wrapper around <ng-select> designed for simple text-based select menus
 * - Large list are supported: virtual scroll is always enabled
 * - Dropdown is always appended to <body>
 * - Compatible with AngularJS (via ngUpgrade)
 */
@Component({
    selector: 'basic-select',
    templateUrl: './basic-select.component.html',
    styleUrls: ['./basic-select.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: BasicSelectComponent,
        multi: true
    },
    {
        provide: NG_VALIDATORS,
        useExisting: BasicSelectComponent,
        multi: true
    }]
})
export class BasicSelectComponent<
    ItemType extends {},
    ValueBinderType extends PropertyAccessor<ItemType, unknown>,
    ValueType extends InferredValueType<ItemType, ValueBinderType>
> implements ControlValueAccessor, OnChanges, Validator {
    // List of items
    @Input() items?: ItemType[];

    // Property of each item containing the label
    @Input() bindLabel?: PropertyAccessor<ItemType, string>;

    // Whether a search box should be displayed
    @Input() searchable?: boolean;

    // Text to display when nothing is selected
    @Input() placeholder = '';

    // Single vs multiple selection
    @Input() multiple = false;

    // Show action boxes to select/deselect all displayed values
    @Input() actionsBox = false;

    @Input() titlePrefix = '';

    // If provided in multiple mode, when N items are selected,
    // the input will show 'N {{ summarizedSelectionItemsType }} selected'
    // instead of the default comma-separated list of items
    @Input() summarizedSelectionItemsType: string | undefined = undefined;

    // Property of each item containing the value
    @Input() bindValue: ValueBinderType;

    // Property of each item containing the annotation
    @Input() bindAnnotation?: PropertyAccessor<ItemType, string>;

    // Property of each item containing the group name, in order to group items in categories
    @Input() groupBy?: PropertyAccessor<ItemType, string>;

    // Derive a key that uniquely represent each value (similar to "track by" in AngularJS "ng-options")
    // This can be used when the value are JS objects that cannot be compared by identity.
    // Note: the key/function is derived from the *value* of items (and NOT from the items themselves)
    @Input() trackBy?: PropertyAccessor<ValueType, unknown>;

    // Text to display when there is no items matching the search term
    @Input() noEntryFoundText?: string;

    // Text to display in the search box
    @Input() searchPlaceholderText?: string;

    // Use customize option template
    // Angular2+ only (templates are not supported in AngularJS)
    @Input() optionTemplateRef?: TemplateRef<{ $implicit: ItemType }>;

    // Whether or not the presence of ghost values should make the underlying form control invalid
    // Ghost values may appear in the dropdown list of options if the items list is updated
    // without first clearing from the selection the selected items that are not in the updated list of items
    // Angular2+ only
    @Input() invalidateOnGhosts: boolean = false;

    // Optional user-defined way to derive a label for the ghost items, when `bindValue` is set
    // and the label cannot be inferred as for the other items.
    // Note: the key/function is derived from the *value* of items (and NOT from the items themselves)
    @Input() bindGhostLabel?: PropertyAccessor<ValueType, string>;

    // Title of the section displaying ghost items in the dropdown
    @Input() ghostSectionTitle?: string;

    // Message displayed in the warning tooltip next to ghost items
    @Input() ghostItemsTooltip?: string;

    // Visible for testing purpose
    @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;
    @ViewChild(NgSelectActionsBoxComponent) ngSelectActionsBox: NgSelectActionsBoxComponent;

    selectedValue: ValueType | ValueType[] | undefined;
    ungroupedItems: UIRegularItem<ItemType, ValueType>[] = [];
    groupedUiItems?: UIRegularGroup<ItemType, ValueType>[];
    ghostItems: UIGhostItem<ItemType, ValueType>[] = [];
    allItems: UIInputItem<ItemType, ValueType>[] = [];
    trackByFn: (value: ValueType) => unknown;
    searchFn?: (term: string, item: UIItem<ItemType, ValueType>) => boolean;
    compareWith: CompareWithFn;

    constructor(
        private cd: ChangeDetectorRef,
        private ngZone: NgZone
    ) { }

    @HostBinding('class.has-ghost-items') get hasGhostItems() {
        return this.invalidateOnGhosts && this.ghostItems.length > 0;
    }

    // fix bindValue type
    get bindValueAccessor(): PropertyAccessor<ItemType, ValueType> {
        return this.bindValue as PropertyAccessor<ItemType, ValueType>;
    }

    // for testing purpose only
    get nVisibilityComputed(): number {
        if (this.ungroupedItems.length === 0) {
            return 0;
        }

        return this.ungroupedItems[0].cache.nOps;
    }

    get ghostItemsSectionTitle(): string {
        return this.ghostSectionTitle || DEFAULT_GHOST_SECTION_TITLE;
    }

    get ghostItemsTooltipMessage(): string {
        if (this.ghostItemsTooltip) {
            return this.ghostItemsTooltip;
        }
        if (this.multiple) {
            return 'This option is invalid and should be unselected.';
        }
        return  'This option is invalid: select another option.';
    }

    onChange: (value: unknown) => void = () => { };

    onValidatorChange = () => { };

    // Re-sync our internal UI items from the 'items' inputs and the selected value
    updateUIItems() {
        const sharedCache = new VisibilityCache<ItemType, ValueType>();

        // ungrouped regular items
        this.ungroupedItems = (this.items ?? [])
            .map(item => makeRegularItem(item, this.bindLabel, this.bindValueAccessor, this.bindAnnotation, this.trackByFn, sharedCache));

        // ghost items
        this.ghostItems = [];
        if (isDefined(this.selectedValue)) {
            let selectedItems;
            if (this.multiple) {
                selectedItems = (this.selectedValue as ValueType[]).map((value) => makeGhostItem(value, this.bindLabel, this.bindValueAccessor, this.bindAnnotation, this.bindGhostLabel, this.trackByFn, sharedCache));
            } else {
                selectedItems = [makeGhostItem(this.selectedValue as ValueType, this.bindLabel, this.bindValueAccessor, this.bindAnnotation, this.bindGhostLabel, this.trackByFn, sharedCache)];
            }
            this.ghostItems = _.differenceWith(selectedItems, this.ungroupedItems, (selected, listed) => _.isEqual(selected.trackBy, listed.trackBy));
        }

        // grouped items
        if (this.groupBy) {
            this.groupedUiItems = [];
            const groupBy = this.groupBy;

            const groupedItems = _.groupBy(this.ungroupedItems, (item) => String(readProperty(item.originalItem, groupBy)));
            const groupKeys = _.sortBy(Object.keys(groupedItems), group => normalizeTextForSearch(group));

            this.groupedUiItems = groupKeys.map(groupKey => makeRegularGroup(groupKey, groupedItems[groupKey], sharedCache));
            // remove the separator from the last group (unused)
            if (this.groupedUiItems.length > 0) {
                this.groupedUiItems[this.groupedUiItems.length - 1].items.pop();
            }
        } else {
            this.groupedUiItems = undefined;
        }

        // all items passed to <ng-select>
        let ghostUiItems: UIGhostGroupItem<ItemType, ValueType>[];
        const ghostSeparator = makeSeparator(sharedCache);
        const ghostHeader = makeGhostHeader(this.ghostItemsSectionTitle, sharedCache);
        if (this.ghostItems.length > 0) {
            if (this.groupedUiItems !== undefined) {
                if (this.multiple) {
                    ghostUiItems = [...this.ghostItems, ghostSeparator];
                } else {
                    ghostUiItems = this.ghostItems;
                }
                this.allItems = [makeGhostGroup(this.ghostItemsSectionTitle, ghostUiItems), ...this.groupedUiItems];
            } else {
                if (this.multiple) {
                    ghostUiItems = [ghostHeader, ...this.ghostItems, ghostSeparator];
                } else {
                    ghostUiItems = this.ghostItems;
                }
                this.allItems = [...ghostUiItems, ...this.ungroupedItems];
            }
        } else {
            this.allItems = this.groupedUiItems || this.ungroupedItems;
        }

        sharedCache.setState(this.ungroupedItems, this.ghostItems, ghostSeparator, ghostHeader, this.groupedUiItems);
    }

    modelChanged(newValue: ValueType | ValueType[] | undefined) {
        this.selectedValue = newValue;
        this.updateUIItems();
        this.onChange?.(newValue);
    }

    open() {
        this.ngSelect.open();
    }

    ngOnChanges() {
        const trackBy = this.trackBy;
        this.trackByFn = (value: ValueType) => readProperty(value, trackBy);

        this.searchFn = (term: string, item: UIItem<ItemType, ValueType>): boolean => {
            return item.cache.isVisible(term, item);
        };

        this.compareWith = (a: UIItem<ItemType, ValueType>, b: ValueType): boolean => {
            // Note: while the signature of this function looks surprisingly non-symmetric, it matches what ng-select passes to it
            return (a.itemType === 'regular' || a.itemType === 'ghost') && _.isEqual(readProperty(a.value, this.trackByFn), readProperty(b, this.trackByFn));
        };

        this.updateUIItems();

        this.onValidatorChange();
    }

    writeValue(value: ValueType | ValueType[] | undefined): void {
        // writeValue() is not called from within Angular zone when a change of ngModel occurred in AngularJS (sc-102154)
        this.ngZone.run(() => {
            this.selectedValue = value;
            this.updateUIItems();
            this.cd.markForCheck();
        });
    }

    validate(): ValidationErrors | null {
        if (this.invalidateOnGhosts) {
            return (this.ghostItems.length > 0) ? { hasGhostItems: true } : null;
        }
        return null;
    }

    registerOnChange(fn: typeof this.onChange) {
        this.onChange = fn;
    }

    registerOnTouched() { }

    registerOnValidatorChange(onValidatorChange: typeof this.onValidatorChange) {
        this.onValidatorChange = onValidatorChange;
    }

    clearGhosts() {
        this.ngSelect.itemsList.filteredItems.filter((i) => i.value.itemType === 'ghost').forEach((i) => this.ngSelect.unselect(i));
    }
}

interface UIItemBase<ItemType, ValueType> {
    /** Properties below are consumed by <ng-select> */

    // <ng-select> interprets this to determine wether an item is selectable
    disabled: boolean;

    // <ng-select> interprets this as being the displayed label
    label: string;

    /** Properties below are used by us only */

    // the cache itself
    cache: VisibilityCache<ItemType, ValueType>;
}

// A real item object passed to <ng-select>
interface UIRealItem<ItemType, ValueType> extends UIItemBase<ItemType, ValueType>{
    /** Properties below are consumed by <ng-select>  */

    // <ng-select> interprets this as the value of this item
    value: ValueType;

    /** Properties below are used by us only */

    // Result of applying the trackByFn on this item
    trackBy: unknown;

    // Normalized label of this item (pre-computed to speed up the search)
    normalizedLabel: string;

    // Annotation to display for this item
    annotation: string | null;
}

// A ghost item that is still in the selection but is not a valid option anymore
// This may occur when the list of items changes but the selected options are not cleared beforehand
interface UIGhostItem<ItemType, ValueType> extends UIRealItem<ItemType, ValueType> {
    /** Properties below are used by us only */

    // This is a ghost item
    itemType: 'ghost';
}

// A real item object passed to <ng-select> wrapping the items passed by the user to <basic-select>
interface UIRegularItem<ItemType, ValueType> extends UIRealItem<ItemType, ValueType> {
    /** Properties below are used by us only */

    // This is a real item
    itemType: 'regular';

    // The original item provided by the consumer of this component
    // (UIRegularItem is used to wrap items provided by the user of <basic-select [items]="...">)
    originalItem: ItemType;
}

// Fake items used for display purposes only
interface UIFakeItem<ItemType, ValueType> extends UIItemBase<ItemType, ValueType> {
    /** Properties below are consumed by <ng-select> */

    // <ng-select> should not let the user select this (fake) item
    disabled: true;
}

// Special fake item to display a header at the beginning of the ghost items section
interface UIGhostHeaderItem<ItemType, ValueType> extends UIFakeItem<ItemType, ValueType> {
    /** Properties below are used by us only */

    // This is not really a menu item. We trick <ng-select> so that we can display a header at the beginning of the ghost items section
    itemType: 'ghostHeader';
}

// Special fake item which visually acts as visual separator in between regular items groups
interface UISeparatorItem<ItemType, ValueType> extends UIFakeItem<ItemType, ValueType> {
    /** Properties below are used by us only */

    // This is not really a menu item. We trick <ng-select> so that we can display an horizontal separator at the end of each group (in grouped mode only)
    itemType: 'separator';
}

type UIRegularGroupItem<ItemType, ValueType> = UIRegularItem<ItemType, ValueType> | UISeparatorItem<ItemType, ValueType>;

// Group of items (Grouped items)
type UIRegularGroup<ItemType, ValueType> = {
    /** Properties below are consumed by <ng-select> */

    items: UIRegularGroupItem<ItemType, ValueType>[];
    label: string;

    /** Properties below are used by us only */

    // This is a regular items group
    groupType: 'regular';
};

type UIGhostGroupItem<ItemType, ValueType> = UIGhostItem<ItemType, ValueType> | UIGhostHeaderItem<ItemType, ValueType> | UISeparatorItem<ItemType, ValueType>;

type UIGhostGroup<ItemType, ValueType> = {
    /** Properties below are consumed by <ng-select> */

    items: UIGhostGroupItem<ItemType, ValueType>[];
    label: string;

    /** Properties below are used by us only */

    // This is a ghost items group
    groupType: 'ghost';
};

type UIItem<ItemType, ValueType> = UIRegularGroupItem<ItemType, ValueType> | UIGhostGroupItem<ItemType, ValueType>;

type UIInputItem<ItemType, ValueType> = UIRegularItem<ItemType, ValueType> | UIGhostGroupItem<ItemType, ValueType> | UIRegularGroup<ItemType, ValueType> | UIGhostGroup<ItemType, ValueType>;


function makeRegularItem<ItemType, ValueType>(
    item: ItemType,
    bindLabel: PropertyAccessor<ItemType, string>,
    bindValue: PropertyAccessor<ItemType, ValueType>,
    bindAnnotation: PropertyAccessor<ItemType, string>,
    trackByFn: PropertyAccessor<ValueType, unknown>,
    cache: VisibilityCache<ItemType, ValueType>,
): UIRegularItem<ItemType, ValueType> {
    const label = String(readProperty(item, bindLabel));
    const value = readProperty(item, bindValue) as ValueType; // ValueType could be equal to ItemType if bindValue is undefined
    let annotation: string | null = null;
    if (bindAnnotation) {
        const annotationProp = readProperty(item, bindAnnotation);
        if (annotationProp != null) {
            annotation = String(annotationProp);
        }
    }
    const normalizedLabel = normalizeTextForSearch(label);
    const trackBy = readProperty(value, trackByFn);

    return {
        disabled: false,
        itemType: 'regular',
        label,
        normalizedLabel,
        value,
        annotation,
        originalItem: item,
        trackBy,
        cache,
    };
}

function makeGhostItem<ItemType, ValueType>(
    value: ValueType,
    bindLabel: PropertyAccessor<ItemType, string>,
    bindValue: PropertyAccessor<ItemType, ValueType>,
    bindAnnotation: PropertyAccessor<ItemType, string>,
    bindGhostLabel: PropertyAccessor<ValueType, string>,
    trackByFn: PropertyAccessor<ValueType, unknown>,
    cache: VisibilityCache<ItemType, ValueType>
): UIGhostItem<ItemType, ValueType> {
    // if bindValue is not set, the value is the item itself
    if (bindValue === undefined) {
        const item = value as any as ItemType; // ItemType == ValueType in this case but TS can't know
        return {
            ...makeRegularItem(item, bindLabel, bindValue, bindAnnotation, trackByFn, cache),
            itemType: 'ghost'
        };
    }

    // otherwise, we have to infer the missing properties
    const trackBy = readProperty(value, trackByFn);

    // infer label
    let label: string;
    if (bindGhostLabel !== undefined) {
        label = String(readProperty(value, bindGhostLabel));
    } else {
        label = (isDefined(trackBy) && !isObject(trackBy)) ? String(trackBy) : "unknown";
    }

    const normalizedLabel = normalizeTextForSearch(label);
    const annotation = null;
    return {
        disabled: false,
        itemType: 'ghost',
        label,
        normalizedLabel,
        value,
        annotation,
        trackBy,
        cache,
    };
}

function makeRegularGroup<ItemType, ValueType>(
    name: string,
    group_items: UIRegularItem<ItemType, ValueType>[],
    cache: VisibilityCache<ItemType, ValueType>
): UIRegularGroup<ItemType, ValueType> {
    // Work around https://github.com/ng-select/ng-select/issues/1991 by prefixing group names with a space
    const label = ` ${name}`;
    const items: UIRegularGroupItem<ItemType, ValueType>[] = [
        ...group_items,
        makeSeparator(cache),
    ];

    return {
        label,
        items,
        groupType: 'regular'
    };
}

function makeGhostGroup<ItemType, ValueType>(
    name: string,
    group_items: UIGhostGroupItem<ItemType, ValueType>[]
): UIGhostGroup<ItemType, ValueType> {
    return {
        label: name,
        items: group_items,
        groupType: 'ghost'
    };
}

function makeGhostHeader<ItemType, ValueType>(
    name: string,
    cache: VisibilityCache<ItemType, ValueType>
): UIGhostHeaderItem<ItemType, ValueType> {
    return {
        itemType: 'ghostHeader',
        label: name,
        disabled: true,
        cache,
    };
}

function makeSeparator<ItemType, ValueType>(
    cache: VisibilityCache<ItemType, ValueType>
): UISeparatorItem<ItemType, ValueType> {
    return {
        itemType: 'separator',
        label: ' -----', // for testing only (never displayed)
        disabled: true,
        cache,
    };
}

/*
* Since we are introducing fake items as separators in this select, we need to
* decide by ourselves whether a separator must be displayed when the items are
* filtered using the search box.
*
* Note: ng-select use an item-wise filter function which is impractical to
* decide whether the separator should be displayed. So we do a first pass to
* pre-compute the visibility. This implementation leverages caching for better
* efficiency.
*/
class VisibilityCache<ItemType, ValueType> {
    // for testing only (assert linear complexity)
    public nOps = 0;

    private searchTerm = '';
    private items: UIRegularItem<ItemType, ValueType>[] = [];
    private groups?: UIRegularGroup<ItemType, ValueType>[];
    private ghosts: UIGhostItem<ItemType, ValueType>[] = [];
    private ghostSeparator: UISeparatorItem<ItemType, ValueType>;
    private ghostHeader: UIGhostHeaderItem<ItemType, ValueType>;

    // holds the visibility of the element for the given search term
    private visibility: Map<UIItem<ItemType, ValueType>, boolean> = new Map();

    setState(items: UIRegularItem<ItemType, ValueType>[], ghosts: UIGhostItem<ItemType, ValueType>[], ghostSeparator: UISeparatorItem<ItemType, ValueType>, ghostHeader: UIGhostHeaderItem<ItemType, ValueType>, groups?: UIRegularGroup<ItemType, ValueType>[]) {
        this.items = items;
        this.groups = groups;
        this.ghosts = ghosts;
        this.ghostSeparator = ghostSeparator;
        this.ghostHeader = ghostHeader;
    }

    isVisible(searchTerm: string, item: UIItem<ItemType, ValueType>): boolean {
        // fast path to avoid unnecessary computation
        if (searchTerm === '') {
            return true;
        }

        if (this.searchTerm !== searchTerm) {
            this.searchTerm = searchTerm;
            const normalizedSearchTerm = normalizeTextForSearch(searchTerm);
            this.refreshVisibility(normalizedSearchTerm);
        }

        return this.visibility.get(item) ?? false;
    }

    private refreshVisibility(normalizedSearchTerm: string) {
        this.nOps = 0;
        this.visibility = new Map<UIItem<ItemType, ValueType>, boolean>();

        // grouping: a group is visible iif it contains at least one visible regular item
        let hasPreviousVisibleGroup = false;

        // set visibility of regular items
        if (this.groups == null) {
            // no grouping: a regular item is visible iif the normalized label matches
            for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i];
                const labelMatch = item.normalizedLabel.indexOf(normalizedSearchTerm) !== -1;
                this.visibility.set(item, labelMatch);
                this.nOps++;
                hasPreviousVisibleGroup ||= labelMatch;
            }
        } else {
            // run backwards to know whether we have to display separators
            for (let i = this.groups.length - 1; i >= 0; i--) {
                const group = this.groups[i];
                let atLeastOneLabelMatch = false;

                for (let j = 0; j < group.items.length; j++) {
                    const item = group.items[j];

                    if (item.itemType === "regular") {
                        // a regular item is visible iif the normalized label matches
                        const labelMatch = item.normalizedLabel.indexOf(normalizedSearchTerm) !== -1;
                        this.visibility.set(item, labelMatch);
                        atLeastOneLabelMatch ||= labelMatch;

                    } else {
                        // a separator is visible iif the group is visible and there is another visible group after
                        // note: a separator is always the last item in the group
                        this.visibility.set(item, atLeastOneLabelMatch && hasPreviousVisibleGroup);
                    }
                    this.nOps++;
                }
                hasPreviousVisibleGroup ||= atLeastOneLabelMatch;
            }
        }

        // set visibility of ghost items
        let atLeastOneLabelMatch = false;
        for (let i = 0; i < this.ghosts.length; i++) {
            const item = this.ghosts[i];
            const labelMatch = item.normalizedLabel.indexOf(normalizedSearchTerm) !== -1;
            this.visibility.set(item, labelMatch);
            atLeastOneLabelMatch ||= labelMatch;
            this.nOps++;
        }
        this.visibility.set(this.ghostHeader, atLeastOneLabelMatch);
        this.visibility.set(this.ghostSeparator, atLeastOneLabelMatch && hasPreviousVisibleGroup);
    }
}
