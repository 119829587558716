import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'clearable-searchbar',
    templateUrl: './clearable-searchbar.component.html',
    styleUrls: ['./clearable-searchbar.component.less']
})
export class ClearableSearchbarComponent implements OnInit {

    defaultPlaceholder = 'Search...';
    searchTerm: UntypedFormControl;

    @ViewChild("input") input: ElementRef;

    @Output()
    searchTermChange = new EventEmitter<string>();

    @Input() hasPrefixIcon: boolean;
    @Input() isInputDisabled: boolean;
    @Input() inputPlaceholder: string;
    @Input() isClearable: boolean;

    ngOnInit(): void {
        this.searchTerm = new UntypedFormControl({ value: '', disabled: this.isInputDisabled });
        this.searchTerm.valueChanges.subscribe(searchTerm => {
            this.searchTermChange.next(searchTerm);
        });
    }

    enableInput() {
        this.searchTerm.enable();
    }

    disableInput() {
        this.searchTerm.disable();
    }

    clearSearch() {
        this.searchTerm.setValue('');
    }

}
