/** 
 * Translated from class com.dataiku.dip.pivot.backend.model.AxisSortPrune
 * Via: UIModel annotation in the class hierarchy
 */
export interface _AxisSortPrune {
    aggregationSortId: number;
    filters: AxisSortPrune.MeasureFilter[];
    generateOthersCategory: boolean;
    maxValues: number;
    sortAscending: boolean;
    sortType: AxisSortPrune.SortType;
}

export type AxisSortPrune = _AxisSortPrune;

export namespace AxisSortPrune {
    /** 
     * Translated from class com.dataiku.dip.pivot.backend.model.AxisSortPrune$MeasureFilter
     * Via: com.dataiku.dip.pivot.backend.model.AxisSortPrune
     */
    export interface _MeasureFilter {
        maxValue: number;
        measureFilterId: number;
        minValue: number;
    }
    
    export type MeasureFilter = _MeasureFilter;
    
    /** 
     * Translated from enum com.dataiku.dip.pivot.backend.model.AxisSortPrune$SortType
     * Via: com.dataiku.dip.pivot.backend.model.AxisSortPrune
     */
    export enum SortType {
        NATURAL = "NATURAL",
        AGGREGATION = "AGGREGATION",
        COUNT = "COUNT",
    }
}