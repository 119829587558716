<form class="dkuform-chart-options" [formGroup]="referenceLineForm">
    <div class="control-group">
        <label class="control-label">Value</label>
        <div class="controls horizontal-flex">
            <input type="number" placeholder="None" formControlName="value" [autofocus]="true" data-qa-charts-reference-line-value />
            <span *ngIf="isPercent$ | async">%</span>
        </div>
    </div>
    <div class="horizontal-flex">
        <div class="control-group">
            <label class="control-label">Color</label>
            <div class="controls flex">
                <dss-reactive-input [inputTemplate]="colorPickerInput" formControlName="lineColor">
                    <ng-template #colorPickerInput let-value="value" let-onValueChange="onValueChange">
                        <chart-color-picker class="flex mx-center-children" [itemsPerRow]="10" [color]="value"
                            [colors]="colors" (colorChange)="onValueChange($event)"></chart-color-picker>
                    </ng-template>
                </dss-reactive-input>
            </div>
        </div>
        <div class="control-group flex mbot0">
            <label class="control-label">Stroke width</label>
            <div class="controls">
                <label>
                    <dss-reactive-input [inputTemplate]="sliderInput" formControlName="lineSize">
                        <ng-template #sliderInput let-value="value" let-onValueChange="onValueChange">
                            <dku-slider [value]="value" [min]="1" [max]="5" (valueChange)="onValueChange($event)">
                            </dku-slider>
                        </ng-template>
                    </dss-reactive-input>
                </label>
            </div>
        </div>
    </div>

    <div class="horizontal-flex">
        <div class="control-group flex-no-basis">
            <label class="control-label">Style</label>
            <div class="controls">
                <ng-select formControlName="lineType" bindLabel="name" bindValue="id" appendTo="body"
                    [items]="styleOptions" [clearable]="false">
                </ng-select>
            </div>
        </div>
        <div class="control-group flex-no-basis" *ngIf="(hasOnlyOneAxis$ | async) === false">
            <label class="control-label control-label--flex">
                <span>Display on</span>
                <dss-warning-icon *ngIf="(isAxisAvailable$ | async) === false" [tooltip]="(isAxisAvailableTooltip$ | async) || 'N/A'"></dss-warning-icon>
            </label>
            <div class="controls">
                <ng-select formControlName="axis" bindLabel="name" bindValue="id" appendTo="body"
                    [items]="axisOptions$ | async" [clearable]="false">
                </ng-select>
            </div>
        </div>
    </div>

    <div class="control-group mtop8">
        <div class="controls">
            <label>
                <input type="checkbox" formControlName="displayValue" />
                <span>Display value</span>
            </label>
        </div>
    </div>

    <ng-container *ngIf="referenceLineForm.value.displayValue">
        <div class="horizontal-flex">
            <div class="control-group flex-no-basis">
                <label class="control-label">Prefix</label>
                <div class="controls">
                    <input type="text" placeholder="None" formControlName="prefix" />
                </div>
            </div>

            <div class="control-group flex-no-basis">
                <label class="control-label">Suffix</label>
                <div class="controls">
                    <input type="text" placeholder="None" formControlName="suffix" />
                </div>
            </div>
        </div>

        <div class="control-group">
            <label class="control-label">Position</label>
            <div class="controls">
                <ng-select formControlName="labelPosition" bindLabel="name" bindValue="id" appendTo="body"
                    [items]="labelPositionOptions" [clearable]="false">
                </ng-select>
            </div>
        </div>

        <div class="control-group" *ngIf="(isPercent$ | async) === false">
            <label class="control-label">Multiplier</label>
            <div class="controls">
                <ng-select formControlName="multiplier" bindLabel="name" bindValue="id" appendTo="body"
                    [items]="multiplierOptions" [clearable]="false">
                </ng-select>
            </div>
        </div>

        <dss-reactive-input [inputTemplate]="textFormattingForm" formControlName="valueFormatting">
            <ng-template #textFormattingForm let-value="value" let-onValueChange="onValueChange">
                <text-formatting-form [textFormatting]="value" (textFormattingChange)="onValueChange($event)" [enableReset]="true"></text-formatting-form>
            </ng-template>
        </dss-reactive-input>
    </ng-container>
</form>
