/** 
 * Translated from class com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams
 * Via: com.dataiku.dip.analysis.model.core.ResolvedPreprocessingParams
 */
export interface _FeaturePreprocessingParams {
    autoReason: FeaturePreprocessingParams.FeatureHandlingReason;
    customHandlingCode: string;
    customProcessorWantsMatrix: boolean;
    name: string;
    role: FeaturePreprocessingParams.Role;
    sendToInput: string;
    state: FeaturePreprocessingParams.FeatureState;
    type: FeaturePreprocessingParams.FeatureType;
}

/**
    Translate from class com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams
    @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
*/
export type FeaturePreprocessingParams = _FeaturePreprocessingParams;

export namespace FeaturePreprocessingParams {
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams$FeatureHandlingReason
     * Via: com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams
     */
    export enum FeatureHandlingReason {
        REJECT_ZERO_VARIANCE = "REJECT_ZERO_VARIANCE",
        REJECT_MISSING = "REJECT_MISSING",
        REJECT_IDENTIFIER = "REJECT_IDENTIFIER",
        REJECT_DEFAULT_TEXT_HANDLING = "REJECT_DEFAULT_TEXT_HANDLING",
        REJECT_CARDINALITY = "REJECT_CARDINALITY",
    }
    /** 
     * Translated from class com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams$FeatureState
     * Via: com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams
     */
    export interface _FeatureState {
        autoModifiedByDSS: boolean;
        dssWantsToSet: FeaturePreprocessingParams;
        previousSettings: FeaturePreprocessingParams;
        recordedMeaning: string;
        userModified: boolean;
    }
    
    export type FeatureState = _FeatureState;
    
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams$FeatureType
     * Via: com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams
     */
    export enum FeatureType {
        NUMERIC = "NUMERIC",
        CATEGORY = "CATEGORY",
        VECTOR = "VECTOR",
        TEXT = "TEXT",
        IMAGE = "IMAGE",
    }
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams$Role
     * Via: com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams
     */
    export enum Role {
        INPUT = "INPUT",
        REJECT = "REJECT",
        WEIGHT = "WEIGHT",
        PROFILING = "PROFILING",
        TARGET = "TARGET",
        TIMESERIES_IDENTIFIER = "TIMESERIES_IDENTIFIER",
        TIME = "TIME",
        TREATMENT = "TREATMENT",
    }
}