import { ValidatorFn, AbstractControl } from '@angular/forms';
import { SimpleKeyValue } from 'dku-frontend-core';

/*
    Validate that a SimpleKeyValue object has no empty keys
*/
export function noEmptyKeysValidator(): ValidatorFn {
    return function validate(control: AbstractControl) {
        const isValid = !control || (control.value || []).every((obj: SimpleKeyValue) => containsNonSpaces(obj.key));

        return isValid ? null : {
            hasEmptyKeys: true
        };
    };

    function containsNonSpaces(value: string) {
        return (value && value.toString() || '').trim().length;
    }
}

