import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.less']
})
export class DeleteDialogComponent  {
    // Use remove for variables, but delete for cards since we're not actually deleting the variable/column
    get removalText(): string {
        return this.data.type === 'column_card' ? 'remove' : 'delete';
    }

    constructor(
        public dialogRef: MatDialogRef<DeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            title: string,
            type?: string,
        }
    ) { }
}
