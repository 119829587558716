import { UsabilityComputer } from 'src/generated-sources';
import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PrivateFlowZonesAPI } from './private-flow-zones-api';

@Injectable({providedIn: 'root'})
export class PrivateFlowAPI {
    constructor(
        private dkuHttp: DkuHttpService,
        public zones: PrivateFlowZonesAPI
    ) {}

    listUsableComputables(projectKey?: string, filter?: UsabilityComputer.ListSettings): Observable<UsabilityComputer.UsableComputable[]> {
        return this.dkuHttp.request('GET', '/flow/list-usable-computable', { projectKey, filter: JSON.stringify(filter) }, false);
    }
}
