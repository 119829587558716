import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Abstract2SampTestCard, AbstractNSampTestCard, Card, Variable } from 'src/generated-sources';
import { assertNever } from 'dku-frontend-core';

type SupportedTypes = Abstract2SampTestCard | AbstractNSampTestCard;

interface Population {
    column: Variable;
    values: string[];
}

@Component({
    selector: 'list-compared-populations',
    templateUrl: './list-compared-populations.component.html',
    styleUrls: ['./list-compared-populations.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComparedPopulationsComponent {
    @Input() params: SupportedTypes;

    get isBuildingPopulationsAutomatically() {
        if (Card.isAbstractNSampTestCard(this.params)) {
            return !this.params.yValues;
        }

        if (Card.isAbstract2SampTestCard(this.params)) {
            return this.params.value1 == null
                && this.params.value2 == null;
        }

        return false;
    }

    get maxNbOfAutomaticPopulations() {
        if (Card.isAbstractNSampTestCard(this.params)) {
            return this.params.maxGroups;
        }

        if (Card.isAbstract2SampTestCard(this.params)) {
            const isAuto = this.params.value1 == null
                && this.params.value2 == null;
            return isAuto ? 2 : 0;
        }

        return 0;
    }

    get manualPopulations(): Population[] {
        if (Card.isAbstractNSampTestCard(this.params)) {
            if (this.params.yValues) {
                return this.params.yValues.map(value => ({
                    column: this.params.yColumn,
                    values: [value]
                }));
            } else {
                // Automatically determined groups
                return [];
            }
        }

        if (Card.isAbstract2SampTestCard(this.params)) {
            const isAuto = this.params.value1 == null
                && this.params.value2 == null;

            if (isAuto) {
                return [];
            }

            return [
                {
                    column: this.params.yColumn,
                    values: this.params.value1!,
                },
                {
                    column: this.params.yColumn,
                    values: this.params.value2!,
                },
            ];
        }

        // Won't compile if a new card type is added to 'SupportedTypes'
        // and is not handled by this function
        return assertNever(this.params);
    }
}
