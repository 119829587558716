/** 
 * Translated from class com.dataiku.dip.labeling.ImageViewSettings
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ImageViewSettings {
    annotationParams: ImageViewSettings.AnnotationParams;
    defaultViewOnExplore: boolean;
    enabled: boolean;
    managedFolderSmartId: string;
    pathColumn: string;
}

export type ImageViewSettings = _ImageViewSettings;

export namespace ImageViewSettings {
    /** 
     * Translated from class com.dataiku.dip.labeling.ImageViewSettings$AnnotationParams
     * Via: com.dataiku.dip.labeling.ImageViewSettings
     */
    export interface _AnnotationParams {
        annotationCategories: string[];
        annotationColumn: string;
        annotationType: ImageViewSettings.AnnotationType;
        enabled: boolean;
    }
    
    export type AnnotationParams = _AnnotationParams;
    
    /** 
     * Translated from enum com.dataiku.dip.labeling.ImageViewSettings$AnnotationType
     * Via: com.dataiku.dip.labeling.ImageViewSettings$AnnotationParams
     */
    export enum AnnotationType {
        OBJECT_DETECTION = "OBJECT_DETECTION",
        IMAGE_CLASSIFICATION = "IMAGE_CLASSIFICATION",
    }
}