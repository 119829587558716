import type { BivariateCard } from './../bivariate-card';
import type { CardResult } from './../card-result';
import type { CardWithConfidenceInterval } from './../common/card-with-confidence-interval';
import type { CardWithConfidenceLevel } from './../common/card-with-confidence-level';
import type { SummaryCardBuilder } from './../common/summary-card-builder';
import type { _BivariateCard } from './../bivariate-card';
import type { _CardResult } from './../card-result';
import type { _CardWithConfidenceInterval } from './../common/card-with-confidence-interval';
import type { _CardWithConfidenceLevel } from './../common/card-with-confidence-level';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateSummaryStatsCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _BivariateSummaryStatsCard extends _BivariateCard, _CardWithConfidenceInterval, _CardWithConfidenceLevel {
    type: "bivariate_summary";
    compute: BivariateSummaryStatsCard.StatsToCompute;
    confidenceLevel: number;
    showConfidenceInterval: boolean;
}

export type BivariateSummaryStatsCard = _BivariateSummaryStatsCard;

export namespace BivariateSummaryStatsCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateSummaryStatsCard in PolyJSON mappings */
    export const type = "bivariate_summary";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateSummaryStatsCard$BivariateSummaryStatsCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _BivariateSummaryStatsCardResult extends _CardResult {
        type: "bivariate_summary";
        stats: SummaryCardBuilder.ComputedStat[];
    }
    
    export type BivariateSummaryStatsCardResult = _BivariateSummaryStatsCardResult;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateSummaryStatsCard$StatsToCompute
     * Via: com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateSummaryStatsCard
     */
    export interface _StatsToCompute {
        chi2: boolean;
        completeCount: boolean;
        covariance: boolean;
        kendallTau: boolean;
        kolmogorovSmirnov: boolean;
        mutualInformation: boolean;
        nSampleMood: boolean;
        oneWayAnova: boolean;
        pearson: boolean;
        spearman: boolean;
        totalCount: boolean;
    }
    
    export type StatsToCompute = _StatsToCompute;

    export namespace BivariateSummaryStatsCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateSummaryStatsCard$BivariateSummaryStatsCardResult in PolyJSON mappings */
        export const type = "bivariate_summary";
    }
}