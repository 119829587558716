import type { AnumGrouping } from './anum-grouping';
import type { BinnedGrouping } from './binned-grouping';
import type { CrossGrouping } from './cross-grouping';
import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { MergeGrouping } from './merge-grouping';
import type { MultiAnumGrouping } from './multi-anum-grouping';
import type { SubsampledGrouping } from './subsampled-grouping';
import type { SubsetGrouping } from './subset-grouping';
import type { TopNTimeGrouping } from './top-ntime-grouping';
import type { UnionGrouping } from './union-grouping';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';

/** 
 * Translated from class com.dataiku.dip.eda.compute.grouping.GroupingResult
 * Via: UIModel annotation in the class hierarchy
 */
export interface _GroupingResult extends _ImmutableValueObject {
}

/** List of all concrete subclasses of com.dataiku.dip.eda.compute.grouping.GroupingResult */
export type GroupingResult =
    | AnumGrouping.AnumGroupingResult
    | BinnedGrouping.BinnedGroupingResult
    | CrossGrouping.CrossGroupingResult
    | MergeGrouping.MergeGroupingResult
    | MultiAnumGrouping.MultiAnumGroupingResult
    | SubsampledGrouping.SubsampledGroupingResult
    | SubsetGrouping.SubsetGroupingResult
    | TopNTimeGrouping.TopNTimeGroupingResult
    | UnionGrouping.UnionGroupingResult;

export namespace GroupingResult {
    /** Types of class com.dataiku.dip.eda.compute.grouping.GroupingResult in PolyJSON mappings */
    export const type = ["anum", "binned", "cross", "merge", "multi_anum", "subsampled", "subset", "topn_time", "union", ] as const;
    
    export function isAnumGroupingResult(obj: GroupingResult): obj is AnumGrouping.AnumGroupingResult { return obj["type"] == "anum"; }
    export function isBinnedGroupingResult(obj: GroupingResult): obj is BinnedGrouping.BinnedGroupingResult { return obj["type"] == "binned"; }
    export function isCrossGroupingResult(obj: GroupingResult): obj is CrossGrouping.CrossGroupingResult { return obj["type"] == "cross"; }
    export function isGroupingResult(obj: GroupingResult): obj is GroupingResult { return ["anum", "binned", "cross", "merge", "multi_anum", "subsampled", "subset", "topn_time", "union", ].includes(obj["type"]); }
    export function isMergeGroupingResult(obj: GroupingResult): obj is MergeGrouping.MergeGroupingResult { return obj["type"] == "merge"; }
    export function isMultiAnumGroupingResult(obj: GroupingResult): obj is MultiAnumGrouping.MultiAnumGroupingResult { return obj["type"] == "multi_anum"; }
    export function isSubsampledGroupingResult(obj: GroupingResult): obj is SubsampledGrouping.SubsampledGroupingResult { return obj["type"] == "subsampled"; }
    export function isSubsetGroupingResult(obj: GroupingResult): obj is SubsetGrouping.SubsetGroupingResult { return obj["type"] == "subset"; }
    export function isTopNTimeGroupingResult(obj: GroupingResult): obj is TopNTimeGrouping.TopNTimeGroupingResult { return obj["type"] == "topn_time"; }
    export function isUnionGroupingResult(obj: GroupingResult): obj is UnionGrouping.UnionGroupingResult { return obj["type"] == "union"; }
}