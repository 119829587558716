<form class="dkuform-chart-options" [formGroup]="zoomOptionsForm">
    <div class="control-group">
        <div class="controls">
            <label>
                <input type="checkbox" formControlName="enabled" />
                Enable zoom
            </label>
            <label>
                <input type="checkbox" formControlName="persisted" />
                Preserve zoom
            </label>
        </div>
    </div>
</form>
