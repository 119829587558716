import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, inject, DestroyRef, ElementRef, AfterViewInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

interface FoldableSectionAPI {
    getId: () => string;
    open: () => void;
    scrollIntoView: () => void;
}

@Component({
    selector: 'dss-foldable-section',
    templateUrl: './foldable-section.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FoldableSectionComponent implements AfterViewInit {
    @Output() openedChange = new EventEmitter<boolean>();
    @Output() loaded = new EventEmitter<FoldableSectionAPI>();

    public opened$ = new BehaviorSubject<boolean>(false);

    private destroyRef = inject(DestroyRef);

    constructor(private elementRef: ElementRef) {}

    @Input()
    set opened(value: boolean) {
        this.opened$.next(!!value);
    }

    ngAfterViewInit(): void {
        this.loaded.next({
            getId: () => {
                return (this.elementRef.nativeElement as HTMLElement).id;
            },
            open: () => {
                this.opened$.next(true);
            },
            scrollIntoView: () => {
                (this.elementRef.nativeElement as HTMLElement).scrollIntoView();
            }
        });

        //  Calling it in after view init to be sure that every input change is handled before emitting new changes
        this.opened$.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged()).subscribe(value => this.openedChange.next(value));
    }

    toggle() {
        this.opened$.next(!this.opened$.value);
    }
}
