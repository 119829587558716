import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DkuMatTooltipModule } from '@shared/components/dku-mat-tooltip/dku-mat-tooltip.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { DkuSliderModule } from '@migration/modules';
import { ChartColorPickerModule } from '../../chart-color-picker';
import { ReactiveInputModule } from '../../reactive-input';
import { WarningIconModule } from '../../warning-icon';
import { RegressionLineFormComponent } from './regression-line-form.component';
import { TextFormattingFormModule } from '../text-formatting-form';

@NgModule({
    declarations: [
        RegressionLineFormComponent
    ],
    imports: [
        CommonModule,
        DkuMatTooltipModule,
        ReactiveFormsModule,
        ReactiveInputModule,
        NgSelectModule,
        ChartColorPickerModule,
        DkuSliderModule,
        TextFormattingFormModule,
        WarningIconModule
    ],
    exports: [
        RegressionLineFormComponent
    ]
})
export class RegressionLineFormModule { }
