/** 
 * Translated from enum com.dataiku.dip.pivot.frontend.model.Multiplier
 * Via: UIModel annotation in the class hierarchy
 */
export enum Multiplier {
    Auto = "Auto",
    None = "None",
    Thousands = "Thousands",
    Millions = "Millions",
    Billions = "Billions",
}