import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    selector: 'rename-worksheet-dialog',
    templateUrl: './rename-worksheet-dialog.component.html',
    styleUrls: ['./rename-worksheet-dialog.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenameWorksheetDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<RenameWorksheetDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            name: string
        }
    ) {}
}
