import type { Distribution } from './distribution';
import type { _Distribution } from './distribution';

/** 
 * Translated from class com.dataiku.dip.eda.compute.distributions.Poisson
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Poisson extends _Distribution {
    type: "poisson";
}

export type Poisson = _Poisson;

export namespace Poisson {
    /** Type of class com.dataiku.dip.eda.compute.distributions.Poisson in PolyJSON mappings */
    export const type = "poisson";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.distributions.Poisson$FittedPoisson
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FittedPoisson extends Distribution._FittedDistribution {
        type: "poisson";
        lambda: number;
    }
    
    export type FittedPoisson = _FittedPoisson;

    export namespace FittedPoisson {
        /** Type of class com.dataiku.dip.eda.compute.distributions.Poisson$FittedPoisson in PolyJSON mappings */
        export const type = "poisson";
    }
}