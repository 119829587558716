import { Inject, Injectable } from '@angular/core';
import { ChartZoomControlTypes } from '../../enums';
import { ChartZoomControlInstance } from '../../models';
import { EChartsZoomControlService } from './echarts-zoom-control.service';
import { D3ChartZoomControlService } from './d3-chart-zoom-control.service';
import { ChartZoomControlStoreService } from './chart-zoom-control-store.service';
import { ChartZoomControl } from './chart-zoom-control.model';
import { LeafletZoomControlService } from './leaflet-zoom-control.service';

@Injectable({
    providedIn: 'root'
})
export class ChartZoomControlAdapterService {
    private logger: any;

    constructor(
        @Inject('Logger') private loggerFactory: any,
        private chartZoomControlStore: ChartZoomControlStoreService,
        private d3ChartZoomControlService: D3ChartZoomControlService,
        private echartsZoomControlService: EChartsZoomControlService,
        private leafletZoomControlService: LeafletZoomControlService
    ) {
        this.logger = this.loggerFactory(({ serviceName: 'ChartZoomControlAdapterService', objectName: 'Service' }));
    }

    create(type: ChartZoomControlTypes, ...args: any): string | undefined {
        const chartZoomControlService = this.getService(type);

        if (!chartZoomControlService) {
            this.logger.warn('Zoom controls are not implemented for ' + type);
            return;
        }

        return chartZoomControlService.create(...args);
    }

    init(type: ChartZoomControlTypes, id: string, ...args: any): ChartZoomControlInstance | undefined {
        const chartZoomControlService = this.getService(type);

        if (!chartZoomControlService) {
            this.logger.warn('Zoom controls are not implemented for ' + type);
            return;
        }

        return chartZoomControlService.init(id, ...args);
    }

    clear(id: string) {
        this.chartZoomControlStore.clear(id);
    }

    get(id: string): ChartZoomControlInstance {
        return this.chartZoomControlStore.get(id);
    }

    isActivated(id: string): boolean {
        return this.d3ChartZoomControlService.isActivated(id);
    }

    isEnabled(id: string): boolean {
        return this.d3ChartZoomControlService.isEnabled(id);
    }

    isZoomed(id: string): boolean {
        return this.d3ChartZoomControlService.isZoomed(id);
    }

    isZooming(id: string): boolean {
        return this.d3ChartZoomControlService.isZooming(id);
    }

    private getService(type: ChartZoomControlTypes): ChartZoomControl | undefined {
        switch(type) {
            case ChartZoomControlTypes.D3:
                return this.d3ChartZoomControlService;
            case ChartZoomControlTypes.ECHARTS:
                return this.echartsZoomControlService;
            case ChartZoomControlTypes.LEAFLET:
                return this.leafletZoomControlService;
            default:
                return;
        }
    }
}
