import type { OneSampleShapiroTestRecipePayloadParams } from './stats/one-sample-shapiro-test-recipe-payload-params';
import type { OneSampleSignTestRecipePayloadParams } from './stats/one-sample-sign-test-recipe-payload-params';
import type { OneSampleTTestRecipePayloadParams } from './stats/one-sample-ttest-recipe-payload-params';
import type { RecipePayloadParams } from './../recipe-payload-params';
import type { SplitBySpec } from './../../eda/worksheets/models/split-by-spec';
import type { _OneSampleShapiroTestRecipePayloadParams } from './stats/one-sample-shapiro-test-recipe-payload-params';
import type { _OneSampleSignTestRecipePayloadParams } from './stats/one-sample-sign-test-recipe-payload-params';
import type { _OneSampleTTestRecipePayloadParams } from './stats/one-sample-ttest-recipe-payload-params';
import type { _RecipePayloadParams } from './../recipe-payload-params';
import type { _SplitBySpec } from './../../eda/worksheets/models/split-by-spec';

/** 
 * Translated from class com.dataiku.dip.recipes.eda.StatsRecipePayloadParams
 * Via: UIModel annotation in the class hierarchy
 */
export interface _StatsRecipePayloadParams<TestStat = any> extends _RecipePayloadParams {
    addComputationTimestamp: boolean;
    confidenceLevel: number;
    splitBy?: SplitBySpec | null;
    stats: TestStat[];
}

/** List of all concrete subclasses of com.dataiku.dip.recipes.eda.StatsRecipePayloadParams */
export type StatsRecipePayloadParams =
    | OneSampleShapiroTestRecipePayloadParams
    | OneSampleSignTestRecipePayloadParams
    | OneSampleTTestRecipePayloadParams;

export namespace StatsRecipePayloadParams {
    /** Types of class com.dataiku.dip.recipes.eda.StatsRecipePayloadParams in PolyJSON mappings */
    export const type = ["recipe_one-sample-shapiro-test", "recipe_one-sample-sign-test", "recipe_one-sample-t-test", ] as const;
    
    export function isStatsRecipePayloadParams(obj: StatsRecipePayloadParams): obj is StatsRecipePayloadParams { return ["recipe_one-sample-shapiro-test", "recipe_one-sample-sign-test", "recipe_one-sample-t-test", ].includes(obj["type"]); }
    export function isOneSampleShapiroTestRecipePayloadParams(obj: StatsRecipePayloadParams): obj is OneSampleShapiroTestRecipePayloadParams { return obj["type"] == "recipe_one-sample-shapiro-test"; }
    export function isOneSampleSignTestRecipePayloadParams(obj: StatsRecipePayloadParams): obj is OneSampleSignTestRecipePayloadParams { return obj["type"] == "recipe_one-sample-sign-test"; }
    export function isOneSampleTTestRecipePayloadParams(obj: StatsRecipePayloadParams): obj is OneSampleTTestRecipePayloadParams { return obj["type"] == "recipe_one-sample-t-test"; }
}