import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { UnivariateFrequencyTableCard } from 'src/generated-sources';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'univariate-frequency-table-card-config',
    templateUrl: './univariate-frequency-table-card-config.component.html',
    styleUrls: ['./univariate-frequency-table-card-config.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnivariateFrequencyTableCardConfigComponent implements OnChanges, OnDestroy {
    @Input() params: UnivariateFrequencyTableCard;
    @Output() paramsChange = new EventEmitter<UnivariateFrequencyTableCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    configForm = this.fb.group({
        maxValues: this.fb.control(null, [
            Validators.required,
            Validators.min(1),
            Validators.max(100000) // Prevent stupidity
        ])
    });

    constructor(private fb: UntypedFormBuilder) {
        this.configForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(formValue => this.paramsChange.emit({
                ...this.params,
                maxValues: formValue.maxValues
            }));

        this.configForm.statusChanges.pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.configForm.patchValue({
                maxValues: this.params.maxValues
            });
        }
    }

    ngOnDestroy() {
    }
}
