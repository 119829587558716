import type { Distribution } from './distribution';
import type { _Distribution } from './distribution';

/** 
 * Translated from class com.dataiku.dip.eda.compute.distributions.LogNormal
 * Via: UIModel annotation in the class hierarchy
 */
export interface _LogNormal extends _Distribution {
    type: "lognormal";
}

export type LogNormal = _LogNormal;

export namespace LogNormal {
    /** Type of class com.dataiku.dip.eda.compute.distributions.LogNormal in PolyJSON mappings */
    export const type = "lognormal";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.distributions.LogNormal$FittedLogNormal
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FittedLogNormal extends Distribution._FittedDistribution {
        type: "lognormal";
        logmean: number;
        logstd: number;
    }
    
    export type FittedLogNormal = _FittedLogNormal;

    export namespace FittedLogNormal {
        /** Type of class com.dataiku.dip.eda.compute.distributions.LogNormal$FittedLogNormal in PolyJSON mappings */
        export const type = "lognormal";
    }
}