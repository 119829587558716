import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Role } from '@shared/models';
import type { BasePermissions, ITaggingService } from 'src/generated-sources';
import { fairAny } from 'dku-frontend-core';
import { UsersService } from '@model-main/server/services/users-service';
import { PublicUser } from '@model-main/security/model/public-user';
interface EnrichedPermissionItem extends BasePermissions.PermissionItem {
    displayName: string;
    exists: boolean;
    role: Role;
}
@Component({
    selector: 'base-permissions-edition',
    templateUrl: './base-permissions-edition.component.html',
    styleUrls: ['./base-permissions-edition.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasePermissionsEditionComponent implements OnChanges {
    @Input() objectType: ITaggingService.TaggableType;
    @Input() permissions: BasePermissions.PermissionItem[];
    /** List of roles that can be given to a user or group, with their labels, description and corresponding permissions */
    @Input() roleList: Role[];
    /** List of users than can be added in the object. Warning: here user's login is stored as 'login' */
    @Input() users?: Array<UsersService.UIUser> | null;
    /** List of groups that can be added in the workspace. */
    @Input() groups?: Array<string> | null;
    /** True if the user has the rights to edit permissions */
    @Input() canEdit = false;
    /** True if editing the object instead of creating it. */
    @Input() editionMode: boolean;

    @Output() permissionsChange = new EventEmitter<BasePermissions.PermissionItem[]>();

    DEFAULT_PERMISSION: BasePermissions.Permissions = { admin: false, write: true, read: true };

    availableUsers?: Array<UsersService.UIUser> | null;
    currentUser: PublicUser;
    memberFilter = '';

    constructor(
        @Inject('$rootScope') private $rootScope: fairAny,
    ) {
        this.currentUser = this.$rootScope.appConfig.user;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setPermission([...this.permissions], false);
        if (changes.users) {
            this.availableUsers = this.editionMode ? this.users : this.users?.filter(user => user.login !== this.currentUser.login);
        }
    }

    setPermission(permissions: BasePermissions.PermissionItem[], emit = true): void {
        this.permissions = permissions;
        if (emit) {
            this.permissionsChange.emit(this.permissions);
        }
    }

    mapGroup(group: string): string {
        return group === '$$ALL_USERS$$' ? 'All users' : group;
    }

    getRoleFromPermission(permission: BasePermissions.Permissions): Role | undefined {
        return this.roleList.find(item => item && item.permissions && item.permissions.admin === permission.admin
            && item.permissions.write === permission.write && item.permissions.read === permission.read);
    }

    getPermissionFromRole(roleName: string): BasePermissions.Permissions | undefined {
        const role = this.roleList.find(item => item && item.role === roleName);
        return role ? role.permissions : undefined;
    }

    mapPermission(permission: BasePermissions.PermissionItem): EnrichedPermissionItem | null {
        const role = this.getRoleFromPermission(permission);
        if (!role) return null;
        if (permission.user) {
            const user = this.users?.find(user => user.login === permission.user);
            const displayName = user?.displayName || `${permission.user} (deleted)`;
            return { ...permission, displayName, exists: !!user, role };
        } else if (permission.group) {
            return { ...permission, displayName: this.mapGroup(permission.group), exists: true, role };
        }
        return null;
    }

    trackPermission(index: number, permission: BasePermissions.PermissionItem) {
        if (permission.user) {
            return 'u_' + permission.user;
        }
        if (permission.group) {
            return 'g_' + permission.group;
        }
        return permission;
    }

    onMemberSearchChange(memberFilter: string) {
        this.memberFilter = memberFilter;
    }

    onGroupPermissionsChange(groups: string[]): void {
        this.setPermission([
            ...groups.map(group =>
                this.permissions.find(permission => permission.group === group) || ({ ...this.DEFAULT_PERMISSION, group })
            ),
            ...this.permissions.filter(permission => !permission.group),
        ]);
    }

    onUserPermissionChange(users: string[]): void {
        this.setPermission([
            ...this.permissions.filter(permission => !permission.user),
            ...users.map(user =>
                this.permissions.find(permission => permission.user === user) || ({ ...this.DEFAULT_PERMISSION, user })
            ),
        ]);
    }

    deletePermission(permissionToDelete: EnrichedPermissionItem): void {
        const newPermissions = [
            ...this.permissions.filter(permission => permission.user !== permissionToDelete.user || permission.group !== permissionToDelete.group)
        ];
        this.setPermission(newPermissions);
    }

    updatePermissionRole(permissionToUpdate: EnrichedPermissionItem, newRole: string): void {
        const permission = this.permissions.find(permission => permission.user === permissionToUpdate.user && permission.group === permissionToUpdate.group);
        const newPermissions = this.getPermissionFromRole(newRole);

        if (permission && newPermissions) {
            Object.assign(permission, newPermissions);
            this.setPermission([...this.permissions]);
        }
    }

}
