import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ErrorContextService } from '@shared/services/error-context.service';

type SetDataCollectionColorOptions = {
    displayName: string;
    color: string;
}

@Component({
    selector: 'lib-set-data-collection-color-modal',
    templateUrl: './set-data-collection-color-modal.component.html',
    styleUrls: ['./set-data-collection-color-modal.component.less'],
    providers: [ErrorContextService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetDataCollectionColorModalComponent {
    public readonly displayName: string;
    public selectedColor: string;

    constructor(
        private dialogRef: MatDialogRef<SetDataCollectionColorModalComponent, string>,
        @Inject(MAT_DIALOG_DATA) private options: SetDataCollectionColorOptions,
    ) {
        this.displayName = options.displayName;
        this.selectedColor = options.color;
    }

    setColor(color: string): void {
        this.selectedColor = color;
    }

    confirm(): void {
        this.dialogRef.close(this.selectedColor);
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
