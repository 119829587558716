import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { Variable, BinningMode } from 'src/generated-sources';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Abstract2DPivotCard } from '@model-main/eda/worksheets/cards/bivariate_header/abstract2-dpivot-card';


@UntilDestroy()
@Component({
    selector: 'abstract-2d-pivot-card-config',
    templateUrl: './abstract-2d-pivot-card-config.component.html',
    styleUrls: ['./abstract-2d-pivot-card-config.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class Abstract2DPivotCardConfigComponent implements OnDestroy, OnChanges {
    @Input() params: Abstract2DPivotCard;
    @Output() paramsChange = new EventEmitter<Abstract2DPivotCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    configForm = this.fb.group({
        xBinningConfig: this.fb.control(null, [Validators.required]),
        yBinningConfig: this.fb.control(null, [Validators.required])
    });

    constructor(private fb: UntypedFormBuilder) {
        this.configForm.statusChanges.pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
        this.configForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(formValue => {
                this.paramsChange.emit({
                    ...this.params,
                    maxValuesX: formValue.xBinningConfig.maxValues,
                    maxValuesY: formValue.yBinningConfig.maxValues,
                    groupWithOthersX: formValue.xBinningConfig.groupWithOthers,
                    groupWithOthersY: formValue.yBinningConfig.groupWithOthers,
                    xBinningMode: formValue.xBinningConfig.binningMode,
                    yBinningMode: formValue.yBinningConfig.binningMode,
                    xCustomBinningBoundaries: formValue.xBinningConfig.customBinningBoundaries,
                    yCustomBinningBoundaries: formValue.yBinningConfig.customBinningBoundaries,
                });
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            const xBinningMode = (this.params.xColumn.type === Variable.Type.CATEGORICAL) ? BinningMode.AUTO : (this.params.xBinningMode ? this.params.xBinningMode : BinningMode.AUTO);
            const yBinningMode = (this.params.yColumn.type === Variable.Type.CATEGORICAL) ? BinningMode.AUTO : (this.params.yBinningMode ? this.params.yBinningMode : BinningMode.AUTO);

            this.configForm.patchValue({
                xBinningConfig:  {
                    maxValues: this.params.maxValuesX,
                    groupWithOthers: this.params.groupWithOthersX,
                    binningMode: xBinningMode,
                    customBinningBoundaries: this.params.xCustomBinningBoundaries,
                },
                yBinningConfig: {
                    maxValues: this.params.maxValuesY,
                    groupWithOthers: this.params.groupWithOthersY,
                    binningMode: yBinningMode,
                    customBinningBoundaries: this.params.yCustomBinningBoundaries,
                }
            });
        }
    }

    ngOnDestroy() {
    }

    get isXCategorical(): boolean {
        return this.params.xColumn.type === Variable.Type.CATEGORICAL;
    }


    get isYCategorical(): boolean {
        return this.params.yColumn.type === Variable.Type.CATEGORICAL;
    }
}



