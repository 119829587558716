import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, Output } from "@angular/core";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Observable, ReplaySubject, combineLatest, distinctUntilChanged, filter, map, startWith, switchMap, shareReplay } from "rxjs";
import _ from "lodash";
import { assertNever, deepDistinctUntilChanged, observeInput } from "dku-frontend-core";
import { APIError } from "@core/dataiku-api/api-error";
import { markAllAsDirty } from "@utils/form-controls";
import { EdaRecipeService } from "@features/eda/recipe/eda-recipe.service";
import {
    AbstractTestStat,
    OneSampleShapiroTestRecipePayloadParams,
    OneSampleShapiroTestStat,
    OneSampleSignTestRecipePayloadParams,
    OneSampleSignTestStat,
    OneSampleTTestRecipePayloadParams,
    OneSampleTTestStat,
    SchemaColumn,
    SerializedRecipe,
    SplitBySpec,
    StatsRecipePayloadParams
} from "generated-sources";

/**
 * Typing for the config form values.
 */

type ConfigFormValue = {
    confidenceLevel: number;
    addComputationTimestamp: boolean;
};

type SplitByUiData = {
    columns: SchemaColumn[];
    splitBy: SplitBySpec | null;
};

type TestStatUiData = {
    columns: SchemaColumn[];
    stats: AbstractTestStat[];
};

const FORM_DEFAULTS = {
    confidenceLevel: 0.95,
    addComputationTimestamp: false,
};

/**
 * The main component
 */

@Component({
    selector: 'stats-recipe-settings',
    templateUrl: './stats-recipe-settings.component.html',
    styleUrls: [
        '../../shared-styles/forms.less',
        './stats-recipe-settings.component.less',
    ],
    providers: [EdaRecipeService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatsRecipeSettingsComponent {

    /**
     * The recipe project key.
     */
    @Input() projectKey: string;

    /**
     * The recipe input.
     */
    @Input() recipeInput: SerializedRecipe.RecipeInput;

    /**
     * The recipe payload parameters.
     */
    @Input() payload: StatsRecipePayloadParams;

    /**
     * Event emitter for forwarding the payload changes.
     */
    @Output() payloadChange = new EventEmitter<StatsRecipePayloadParams>();

    /**
     * Event emitter for forwarding any api error.
     */
    @Output() apiErrorChange = new EventEmitter<APIError>();

    destroyRef = inject(DestroyRef);

    configForm: UntypedFormGroup;
    columns$: Observable<SchemaColumn[]>;
    splitByUiData$: Observable<SplitByUiData>;
    statsUiData$: Observable<TestStatUiData>;
    isInvalid$: Observable<boolean>;

    private splitByValid$ = new ReplaySubject<boolean>(1);
    private statsValid$ = new ReplaySubject<boolean>(1);
    private lastEmittedPayload: StatsRecipePayloadParams | null = null;

    private recipeInput$: Observable<SerializedRecipe.RecipeInput> =
        observeInput(this, "recipeInput");

    private payload$: Observable<StatsRecipePayloadParams> =
        observeInput(this, "payload").pipe(
            deepDistinctUntilChanged(),
            distinctUntilChanged((prev, curr) => _.isEqual(curr, this.lastEmittedPayload)),
        );

    constructor(
        fb: UntypedFormBuilder,
        private edaRecipeService: EdaRecipeService,
    ) {
        this.configForm = fb.group({
            confidenceLevel: fb.control(FORM_DEFAULTS.confidenceLevel, [
                Validators.required,
                Validators.min(0.5),
                Validators.max(1 - (1e-15))
            ]),
            addComputationTimestamp: fb.control(FORM_DEFAULTS.addComputationTimestamp, [
                Validators.required
            ]),
        });

        this.edaRecipeService.getError().pipe(
            filter(err => err != null),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe(err => {
            this.apiErrorChange.emit(err);
        });

        this.isInvalid$ = combineLatest([
            this.configForm.statusChanges.pipe(
                startWith(false),
                distinctUntilChanged(),
                map(() => this.configForm.valid),
            ),
            this.splitByValid$.pipe(
                distinctUntilChanged(),
            ),
            this.statsValid$.pipe(
                distinctUntilChanged()
            )
        ]).pipe(
            map(([configFormValid, splitByValid, statsValid]) =>
                !(configFormValid && splitByValid && statsValid)
            ),
        );

        this.columns$ = this.recipeInput$.pipe(
            map(input => input.ref),
            distinctUntilChanged(),
            switchMap(datasetRef =>
                this.edaRecipeService.fetchInputColumns(datasetRef, this.projectKey)
            ),
            shareReplay(1)
        );

        // split-by settings inputs
        const splitBy$ = this.payload$.pipe(
            map(payload => payload.splitBy ?? null),
            distinctUntilChanged(),
        );

        this.splitByUiData$ = combineLatest([splitBy$, this.columns$]).pipe(
            map(([splitBy, columns]) => ({ splitBy, columns })),
        );

        // statistical tests settings inputs
        const stats$ = this.payload$.pipe(
            map(payload => payload.stats ?? []),
            deepDistinctUntilChanged(),
        );

        this.statsUiData$ = combineLatest([stats$, this.columns$]).pipe(
            map(([stats, columns]) => ({ stats, columns })),
        );

        // payload / config form bindings
        this.configForm.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef),
        ).subscribe((formValue: ConfigFormValue) => {
            this.updatePayload(formValue);
        });

        this.payload$.pipe(
            takeUntilDestroyed(this.destroyRef),
        ).subscribe(payload => {
            this.patchConfigForm(payload);
        });
    }

    get sectionTitle(): string {
        const payloadType = this.payload.type;
        switch (payloadType) {
            case OneSampleTTestRecipePayloadParams.type:
                return "One sample Student T-test";
            case OneSampleSignTestRecipePayloadParams.type:
                return "One sample Sign test";
            case OneSampleShapiroTestRecipePayloadParams.type:
                return "Shapiro-Wilk normality test";
            default:
                assertNever(payloadType);
        }
    }

    isOneSampleTTest(stats: AbstractTestStat[]): stats is OneSampleTTestStat[] {
        return StatsRecipePayloadParams.isOneSampleTTestRecipePayloadParams(this.payload);
    }

    isOneSampleSignTest(stats: AbstractTestStat[]): stats is OneSampleSignTestStat[] {
        return StatsRecipePayloadParams.isOneSampleSignTestRecipePayloadParams(this.payload);
    }

    isOneSampleShapiroTest(stats: AbstractTestStat[]): stats is OneSampleShapiroTestStat[] {
        return StatsRecipePayloadParams.isOneSampleShapiroTestRecipePayloadParams(this.payload);
    }

    updateTestStat(stats: AbstractTestStat[]): void {
        const payload = {
            ...this.payload,
            stats
        };
        const payloadType = payload.type;
        switch (payloadType) {
            case OneSampleTTestRecipePayloadParams.type:
                this.emitPayload(payload as OneSampleTTestRecipePayloadParams);
                break;
            case OneSampleSignTestRecipePayloadParams.type:
                this.emitPayload(payload as OneSampleSignTestRecipePayloadParams);
                break;
            case OneSampleShapiroTestRecipePayloadParams.type:
                this.emitPayload(payload as OneSampleShapiroTestRecipePayloadParams);
                break;
            default:
                assertNever(payloadType);
        }
    }

    updateTestStatValidity(valid: boolean): void {
        this.statsValid$.next(valid);
    }

    updateSplitBy(splitBy: SplitBySpec | null): void {
        const payload = {
            ...this.payload,
            splitBy,
        };

        this.emitPayload(payload);
    }

    updateSplitByValidity(valid: boolean): void {
        this.splitByValid$.next(valid);
    }

    private patchConfigForm(payload: StatsRecipePayloadParams): void {
        this.configForm.patchValue({
            confidenceLevel: payload.confidenceLevel,
            addComputationTimestamp: payload.addComputationTimestamp,
        });

        // better looking invalid controls after init
        markAllAsDirty(this.configForm);
    }

    private updatePayload(formValue: ConfigFormValue): void {
        const payload = {
            ...this.payload,
            confidenceLevel: formValue.confidenceLevel,
            addComputationTimestamp: formValue.addComputationTimestamp,
        };

        this.emitPayload(payload);
    }

    private emitPayload(payload: StatsRecipePayloadParams): void {
        this.lastEmittedPayload = payload;
        this.payloadChange.emit(payload);
    }
}
