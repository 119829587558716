import type { ColumnDesc } from './../joinlike/column-desc';
import type { ComputedColumn } from './../computedcolumn/computed-column';
import type { FilterDesc } from './../../../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type { JoinDescBase } from './../joinlike/join-desc-base';
import type { JoinInputDescBase } from './../joinlike/join-input-desc-base';
import type { JoinLikeRecipePayloadParams } from './../joinlike/join-like-recipe-payload-params';
import type { MatchingConditionBase } from './../joinlike/matching-condition-base';
import type { _ColumnDesc } from './../joinlike/column-desc';
import type { _ComputedColumn } from './../computedcolumn/computed-column';
import type { _FilterDesc } from './../../../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type { _JoinDescBase } from './../joinlike/join-desc-base';
import type { _JoinInputDescBase } from './../joinlike/join-input-desc-base';
import type { _JoinLikeRecipePayloadParams } from './../joinlike/join-like-recipe-payload-params';
import type { _MatchingConditionBase } from './../joinlike/matching-condition-base';

/** 
 * Translated from class com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams
 * Via: UIModel annotation in the class hierarchy
 */
export interface _JoinRecipePayloadParams extends _JoinLikeRecipePayloadParams<JoinRecipePayloadParams.InputDesc, JoinRecipePayloadParams.MatchingCondition, JoinRecipePayloadParams.JoinDesc> {
    computedColumns: ComputedColumn[];
    enableAutoCastInJoinConditions: boolean;
    postFilter: FilterDesc;
}

export type JoinRecipePayloadParams = _JoinRecipePayloadParams;

export namespace JoinRecipePayloadParams {
    /** 
     * Translated from class com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams$InputDesc
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _InputDesc extends _JoinInputDescBase {
    }
    
    export type InputDesc = _InputDesc;
    
    /** 
     * Translated from class com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams$JoinDesc
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _JoinDesc extends _JoinDescBase<JoinRecipePayloadParams.MatchingCondition> {
        customSQLCondition: string;
        outerJoinOnTheLeft: boolean;
        rightLimit: JoinRecipePayloadParams.RightLimitDesc;
    }
    
    export type JoinDesc = _JoinDesc;
    
    /** 
     * Translated from class com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams$MatchingCondition
     * Via: com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams
     */
    export interface _MatchingCondition extends _MatchingConditionBase {
        caseInsensitive: boolean;
        dateDiffUnit: string;
        maxDistance: number;
        maxMatches: number;
        normalizeText: boolean;
        strict: boolean;
        type: JoinRecipePayloadParams.MatchingType;
        windowFrom: number;
        windowTo: number;
    }
    
    export type MatchingCondition = _MatchingCondition;
    
    /** 
     * Translated from enum com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams$MatchingType
     * Via: com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams$MatchingCondition
     */
    export enum MatchingType {
        EQ = "EQ",
        LTE = "LTE",
        LT = "LT",
        GTE = "GTE",
        GT = "GT",
        NE = "NE",
        WITHIN_RANGE = "WITHIN_RANGE",
        K_NEAREST = "K_NEAREST",
        K_NEAREST_INFERIOR = "K_NEAREST_INFERIOR",
        CONTAINS = "CONTAINS",
        STARTS_WITH = "STARTS_WITH",
        WITHIN_WINDOW_OF = "WITHIN_WINDOW_OF",
    }
    /** 
     * Translated from class com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams$RightLimitDesc
     * Via: com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams$JoinDesc
     */
    export interface _RightLimitDesc {
        decisionColumn: ColumnDesc;
        enabled: boolean;
        maxMatches: number;
        strict: boolean;
        type: JoinRecipePayloadParams.SelectionType;
    }
    
    export type RightLimitDesc = _RightLimitDesc;
    
    /** 
     * Translated from enum com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams$SelectionType
     * Via: com.dataiku.dip.dataflow.exec.join.JoinRecipePayloadParams$RightLimitDesc
     */
    export enum SelectionType {
        KEEP_ALL = "KEEP_ALL",
        KEEP_LARGEST = "KEEP_LARGEST",
        KEEP_SMALLEST = "KEEP_SMALLEST",
    }

    export namespace MatchingType {
        export const displayNames: { [key in JoinRecipePayloadParams.MatchingType]: string } = {
          "CONTAINS": "Contains",
          "EQ": "\u003d",
          "GT": "\u003e",
          "GTE": "\u003e\u003d",
          "K_NEAREST": "Nearest match",
          "K_NEAREST_INFERIOR": "Nearest match before",
          "LT": "\u003c",
          "LTE": "\u003c\u003d",
          "NE": "!\u003d",
          "STARTS_WITH": "Starts with",
          "WITHIN_RANGE": "Within range",
          "WITHIN_WINDOW_OF": "Within window of"
        };
    }
}