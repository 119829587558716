import type { Distribution } from './distribution';
import type { _Distribution } from './distribution';

/** 
 * Translated from class com.dataiku.dip.eda.compute.distributions.Triangular
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Triangular extends _Distribution {
    type: "triangular";
}

export type Triangular = _Triangular;

export namespace Triangular {
    /** Type of class com.dataiku.dip.eda.compute.distributions.Triangular in PolyJSON mappings */
    export const type = "triangular";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.distributions.Triangular$FittedTriangular
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FittedTriangular extends Distribution._FittedDistribution {
        type: "triangular";
        a: number;
        b: number;
        c: number;
    }
    
    export type FittedTriangular = _FittedTriangular;

    export namespace FittedTriangular {
        /** Type of class com.dataiku.dip.eda.compute.distributions.Triangular$FittedTriangular in PolyJSON mappings */
        export const type = "triangular";
    }
}