import type { CardResult } from './../card-result';
import type { Filter } from './../../../compute/filtering/filter';
import type { ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { UnivariateCard } from './../univariate-card';
import type { _CardResult } from './../card-result';
import type { _Filter } from './../../../compute/filtering/filter';
import type { _ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _UnivariateCard } from './../univariate-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.univariate_header.CategoricalHistogramCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _CategoricalHistogramCard extends _UnivariateCard {
    type: "categorical_histogram";
    groupOthers: boolean;
    highlightFilter?: Filter | null;
    maxValues: number;
}

export type CategoricalHistogramCard = _CategoricalHistogramCard;

export namespace CategoricalHistogramCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.univariate_header.CategoricalHistogramCard in PolyJSON mappings */
    export const type = "categorical_histogram";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.univariate_header.CategoricalHistogramCard$CategoricalHistogramCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _CategoricalHistogramCardResult extends _CardResult {
        type: "categorical_histogram";
        histogram: CategoricalHistogramCard.HistogramData;
    }
    
    export type CategoricalHistogramCardResult = _CategoricalHistogramCardResult;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.univariate_header.CategoricalHistogramCard$HistogramData
     * Via: com.dataiku.dip.eda.worksheets.cards.univariate_header.CategoricalHistogramCard$CategoricalHistogramCardResult
     */
    export interface _HistogramData extends _ImmutableValueObject {
        counts: number[];
        filters: Filter[];
        highlightedCounts?: number[] | null;
        totalCount: number;
    }
    
    export type HistogramData = _HistogramData;

    export namespace CategoricalHistogramCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.univariate_header.CategoricalHistogramCard$CategoricalHistogramCardResult in PolyJSON mappings */
        export const type = "categorical_histogram";
    }
}