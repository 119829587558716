import type { FilterDesc } from './../../../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type { JoinDescBase } from './../joinlike/join-desc-base';
import type { JoinInputDescBase } from './../joinlike/join-input-desc-base';
import type { JoinLikeRecipePayloadParams } from './../joinlike/join-like-recipe-payload-params';
import type { MatchingConditionBase } from './../joinlike/matching-condition-base';
import type { _FilterDesc } from './../../../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type { _JoinDescBase } from './../joinlike/join-desc-base';
import type { _JoinInputDescBase } from './../joinlike/join-input-desc-base';
import type { _JoinLikeRecipePayloadParams } from './../joinlike/join-like-recipe-payload-params';
import type { _MatchingConditionBase } from './../joinlike/matching-condition-base';

/** 
 * Translated from class com.dataiku.dip.dataflow.exec.geojoin.GeoJoinRecipePayloadParams
 * Via: UIModel annotation in the class hierarchy
 */
export interface _GeoJoinRecipePayloadParams extends _JoinLikeRecipePayloadParams<JoinInputDescBase, GeoJoinRecipePayloadParams.MatchingCondition, GeoJoinRecipePayloadParams.JoinDesc> {
    postFilter: FilterDesc;
}

export type GeoJoinRecipePayloadParams = _GeoJoinRecipePayloadParams;

export namespace GeoJoinRecipePayloadParams {
    /** 
     * Translated from enum com.dataiku.dip.dataflow.exec.geojoin.GeoJoinRecipePayloadParams$DistanceUnit
     * Via: com.dataiku.dip.dataflow.exec.geojoin.GeoJoinRecipePayloadParams$MatchingCondition
     */
    export enum DistanceUnit {
        METER = "METER",
        KILOMETER = "KILOMETER",
        MILE = "MILE",
        YARD = "YARD",
        FOOT = "FOOT",
        NAUTICAL_MILE = "NAUTICAL_MILE",
    }
    /** 
     * Translated from class com.dataiku.dip.dataflow.exec.geojoin.GeoJoinRecipePayloadParams$JoinDesc
     * Via: com.dataiku.dip.dataflow.exec.geojoin.GeoJoinRecipePayloadParams
     */
    export interface _JoinDesc extends _JoinDescBase<GeoJoinRecipePayloadParams.MatchingCondition> {
    }
    
    export type JoinDesc = _JoinDesc;
    
    /** 
     * Translated from class com.dataiku.dip.dataflow.exec.geojoin.GeoJoinRecipePayloadParams$MatchingCondition
     * Via: com.dataiku.dip.dataflow.exec.geojoin.GeoJoinRecipePayloadParams
     */
    export interface _MatchingCondition extends _MatchingConditionBase {
        threshold: number;
        type: GeoJoinRecipePayloadParams.MatchingType;
        unit: GeoJoinRecipePayloadParams.DistanceUnit;
    }
    
    export type MatchingCondition = _MatchingCondition;
    
    /** 
     * Translated from enum com.dataiku.dip.dataflow.exec.geojoin.GeoJoinRecipePayloadParams$MatchingType
     * Via: com.dataiku.dip.dataflow.exec.geojoin.GeoJoinRecipePayloadParams$MatchingCondition
     */
    export enum MatchingType {
        EQ = "EQ",
        DWITHIN = "DWITHIN",
        WITHIN = "WITHIN",
        BEYOND = "BEYOND",
        TOUCHES = "TOUCHES",
        CONTAINS = "CONTAINS",
        DISJOINT = "DISJOINT",
        INTERSECTS = "INTERSECTS",
    }
}