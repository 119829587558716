import type { Distribution } from './distribution';
import type { _Distribution } from './distribution';

/** 
 * Translated from class com.dataiku.dip.eda.compute.distributions.Laplace
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Laplace extends _Distribution {
    type: "laplace";
}

export type Laplace = _Laplace;

export namespace Laplace {
    /** Type of class com.dataiku.dip.eda.compute.distributions.Laplace in PolyJSON mappings */
    export const type = "laplace";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.distributions.Laplace$FittedLaplace
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FittedLaplace extends Distribution._FittedDistribution {
        type: "laplace";
        b: number;
        mu: number;
    }
    
    export type FittedLaplace = _FittedLaplace;

    export namespace FittedLaplace {
        /** Type of class com.dataiku.dip.eda.compute.distributions.Laplace$FittedLaplace in PolyJSON mappings */
        export const type = "laplace";
    }
}