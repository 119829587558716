import type { AppHomepageTile } from './app-homepage-tile';
import type { CodeEnvModel } from './../code/code-env-model';
import type { FlowComputable } from './../dataflow/graph/flow-computable';
import type { GeneralSettingsDAO } from './../dao/general-settings-dao';
import type { ProjectExportOptions } from './../projects/importexport/model/project-export-options';
import type { _AppHomepageTile } from './app-homepage-tile';

/** 
 * Translated from class com.dataiku.dip.coremodel.AppManifest
 * Via: com.dataiku.dip.projects.apps.AppsService$AppTemplateData
 */
export interface _AppManifest {
    accessRequestsEnabled: GeneralSettingsDAO.InheritableEnabledSetting.LocalValue;
    allowedMissingCodeEnvs: CodeEnvModel.UsedCodeEnvRef[];
    allowedMissingConnections: string[];
    description: string;
    homepageSections: AppManifest.AppHomepageSection[];
    id: string;
    imgColor: string;
    imgPattern: number;
    instanceFeatures: AppManifest.AppInstancesFeatures;
    instantiationPermission: AppManifest.AppInstantiationPermission;
    label: string;
    limitedVisibilityEnabled: GeneralSettingsDAO.InheritableEnabledSetting.LocalValue;
    projectExportManifest: AppManifest.AppProjectExportManifest;
    shortDesc: string;
    showInitials: boolean;
    tags: string[];
    useAppHomepage: boolean;
    useAsRecipeSettings: AppManifest.AppUseAsRecipeSettings;
}

export type AppManifest = _AppManifest;

export namespace AppManifest {
    /** 
     * Translated from class com.dataiku.dip.coremodel.AppManifest$AppHomepageSection
     * Via: com.dataiku.dip.coremodel.AppManifest
     */
    export interface _AppHomepageSection {
        sectionText: string;
        sectionTitle: string;
        tiles: AppHomepageTile[];
    }
    
    export type AppHomepageSection = _AppHomepageSection;
    
    /** 
     * Translated from class com.dataiku.dip.coremodel.AppManifest$AppInstancesFeatures
     * Via: com.dataiku.dip.coremodel.AppManifest
     */
    export interface _AppInstancesFeatures {
        showCodeNavLink: boolean;
        showFlowNavLink: boolean;
        showLabNavLink: boolean;
        showSwitchToProjectViewButton: boolean;
        showVersionControlFeatures: boolean;
    }
    
    export type AppInstancesFeatures = _AppInstancesFeatures;
    
    /** 
     * Translated from enum com.dataiku.dip.coremodel.AppManifest$AppInstantiationPermission
     * Via: com.dataiku.dip.coremodel.AppManifest
     */
    export enum AppInstantiationPermission {
        EVERYBODY = "EVERYBODY",
        USE_APP_MASTER_PERMISSIONS = "USE_APP_MASTER_PERMISSIONS",
    }
    /** 
     * Translated from class com.dataiku.dip.coremodel.AppManifest$AppProjectExportManifest
     * Via: com.dataiku.dip.coremodel.AppManifest
     */
    export interface _AppProjectExportManifest {
        exportAllDatasets: boolean;
        exportAllInputDatasets: boolean;
        exportAllInputManagedFolders: boolean;
        exportAnalysisModels: boolean;
        exportGitRepository: boolean;
        exportInsightsData: boolean;
        exportLabelingTasks: boolean;
        exportManagedFS: boolean;
        exportManagedFolders: boolean;
        exportModelEvaluationStores: boolean;
        exportProjectResources: boolean;
        exportSavedModels: boolean;
        exportUploads: boolean;
        includedCodeStudios: ProjectExportOptions.IncludedCodeStudio[];
        includedDatasetsData: ProjectExportOptions.IncludedDatasetData[];
        includedLabelingTasks: ProjectExportOptions.IncludedLabelingTask[];
        includedManagedFolders: ProjectExportOptions.IncludedManagedFolder[];
        includedModelEvaluationStores: ProjectExportOptions.IncludedModelEvaluationStore[];
        includedSavedModels: ProjectExportOptions.IncludedSavedModel[];
    }
    
    export type AppProjectExportManifest = _AppProjectExportManifest;
    
    /** 
     * Translated from class com.dataiku.dip.coremodel.AppManifest$AppUseAsRecipeSettings
     * Via: com.dataiku.dip.coremodel.AppManifest
     */
    export interface _AppUseAsRecipeSettings {
        category: string;
        icon: string;
        inputRoles: AppManifest.AppUseAsRecipeSettings.RoleMapping[];
        outputRoles: AppManifest.AppUseAsRecipeSettings.RoleMapping[];
        runScenarioTile: AppHomepageTile.RunScenarioTile;
        variablesEditionTile: AppHomepageTile.ProjectVariablesTile;
    }
    
    export type AppUseAsRecipeSettings = _AppUseAsRecipeSettings;

    export namespace AppUseAsRecipeSettings {
        /** 
         * Translated from class com.dataiku.dip.coremodel.AppManifest$AppUseAsRecipeSettings$RoleMapping
         * Via: com.dataiku.dip.coremodel.AppManifest$AppUseAsRecipeSettings
         */
        export interface _RoleMapping {
            objectId: string;
            roleLabel: string;
            type: FlowComputable.FCType;
        }
        
        export type RoleMapping = _RoleMapping;
    }
}