<form class="dkuform-chart-options" [formGroup]="tableFormattingForm" data-qa-table-formatting-form>
    <div *ngFor="let option of tableFormattingOptions">
        <label *ngIf="option.show" class="control-label">{{ option.label }}</label>
        <dss-reactive-input [inputTemplate]="textFormattingForm" *ngIf="option.show"
            [formControlName]="option.formControlName">
            <ng-template #textFormattingForm let-value="value" let-onValueChange="onValueChange">
                <text-formatting-form [showFontColorSettings]="option.showColorSettings"
                    [showBackgroundSettings]="false" [textFormatting]="value" [id]="option.id"
                    (textFormattingChange)="onValueChange($event)" [showFontLabel]="false" [defaultFontSize]="12">
                </text-formatting-form>
            </ng-template>
        </dss-reactive-input>
    </div>
</form>

<reset-button (onReset)="resetTableFormatting()" [hasLabel]="true"></reset-button>
