<form class="dkuform-horizontal"
    [formGroup]="configForm"
>
    <div class="control-group">
        <label class="control-label">
            Split column
        </label>

        <div class="controls">
            <basic-select [items]="(selectableColumns$ | async) ?? []"
                formControlName="selection"
                bindLabel="name"
                bindAnnotation="type"
                [trackBy]="trackSelectableColumn"
                [searchable]="true"
                [invalidateOnGhosts]="true"
                ghostItemsTooltip="This column does not exist in the input dataset."
            ></basic-select>
        </div>
    </div>

    <ng-container *ngIf="uiData.isSplitByEnabled">
        <div class="control-group">
            <label class="control-label">
                Treat column as
            </label>
            <div class="controls">
                <basic-select formControlName="selectionType"
                    [items]="COLUMN_TYPES"
                    bindLabel="label"
                    bindValue="key"
                ></basic-select>
            </div>
        </div>

        <ng-container *ngIf="uiData.isCategorical; else numerical">
            <div class="control-group">
                <label class="control-label">
                    Max nb. of categories

                    <i *ngIf="configForm.controls.maxValues.invalid"
                        class="icon-warning-sign icon-warning-color mleft8"
                        matTooltip="The max nb. of categories must be at least 1"
                        matTooltipPosition="below"
                    ></i>
                </label>

                <div class="controls">
                    <input formControlName="maxValues"
                        type="number" min="1"
                    >
                </div>
            </div>

            <div class="control-group">
                <label class="control-label"
                    for="cb_group_with_others"
                >
                    Add a group for other rows
                </label>

                <div class="controls">
                    <input formControlName="groupOthers"
                        id="cb_group_with_others"
                        type="checkbox"
                    >

                    <div class="help-inline">
                        Creates an extra group for all other, remaining rows
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-template #numerical>
            <div class="control-group">
                <label class="control-label">
                    Binning mode
                </label>
                <div class="controls">
                    <basic-select formControlName="binningMode"
                        [items]="BINNING_MODES"
                        bindLabel="label"
                        bindValue="key"
                    ></basic-select>
                </div>
            </div>

            <div class="control-group">
                <label class="control-label">
                    <ng-container *ngIf="uiData.isAutoBinning">
                        Max nb. of bins

                        <i *ngIf="configForm.controls.maxValues.invalid"
                            class="icon-warning-sign icon-warning-color mleft8"
                            matTooltip="The nb. of bins must be at least 1"
                            matTooltipPosition="below"
                        ></i>
                    </ng-container>

                    <ng-container *ngIf="uiData.isFixedNumberBinning">
                        Nb. of bins

                        <i *ngIf="configForm.controls.maxValues.invalid"
                            class="icon-warning-sign icon-warning-color mleft8"
                            matTooltip="The nb. of bins must be at least 1"
                            matTooltipPosition="below"
                        ></i>
                    </ng-container>

                    <ng-container *ngIf="uiData.isCustomBinning">
                        Custom bin boundaries

                        <i *ngIf="configForm.controls.customBoundaries.invalid"
                            class="icon-warning-sign icon-warning-color mleft8"
                            matTooltip="There must be at least one custom bin boundary"
                            matTooltipPosition="below"
                        ></i>
                    </ng-container>
                </label>

                <div class="controls" >
                    <input *ngIf="uiData.isAutoBinning || uiData.isFixedNumberBinning"
                        formControlName="maxValues"
                        type="number" min="1"
                    >

                    <simple-number-list *ngIf="uiData.isCustomBinning"
                        class="custom-binning"
                        formControlName="customBoundaries"
                    ></simple-number-list>
                </div>
            </div>
        </ng-template>

        <div class="control-group">
            <label class="control-label"
                for="cb_group_with_all"
            >
                Add a group for all rows
            </label>

            <div class="controls">
                <input formControlName="groupAll"
                    id="cb_group_with_all"
                    type="checkbox"
                >

                <div class="help-inline">
                    Creates a group for all rows in the input dataset
                </div>
            </div>
        </div>
    </ng-container>
</form>
