import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTMLTooltipModule } from '../html-tooltip';
import { BadgeComponent } from './badge.component';

@NgModule({
    declarations: [
        BadgeComponent
    ],
    imports: [
        CommonModule,
        HTMLTooltipModule
    ],
    exports: [
        BadgeComponent
    ]
})
export class BadgeModule { }
