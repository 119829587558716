/** 
 * Translated from class com.dataiku.dip.dataflow.exec.filter.FilterDesc
 * Via: UIModel annotation in the class hierarchy
 */
export interface _FilterDesc {
    distinct: boolean;
    enabled: boolean;
    expression?: string | null;
    language?: FilterDesc.ExpressionLanguage | null;
    uiData?: FilterDesc.FilterUiData | null;
}

export type FilterDesc = _FilterDesc;

export namespace FilterDesc {
    /** 
     * Translated from enum com.dataiku.dip.dataflow.exec.filter.FilterDesc$ExpressionLanguage
     * Via: com.dataiku.dip.dataflow.exec.filter.FilterDesc
     */
    export enum ExpressionLanguage {
        GREL = "GREL",
        SQL = "SQL",
    }
    /** 
     * Translated from class com.dataiku.dip.dataflow.exec.filter.FilterDesc$FilterUiCondition
     * Via: com.dataiku.dip.dataflow.exec.filter.FilterDesc$FilterUiData
     */
    export interface _FilterUiCondition {
        col: string;
        date: string;
        date2: string;
        input: string;
        items: FilterDesc.FilterUiListConditionValue[];
        num: number;
        operator: string;
        string: string;
        subCondition: FilterDesc;
        time: string;
        time2: string;
        unit: string;
    }
    
    export type FilterUiCondition = _FilterUiCondition;
    
    /** 
     * Translated from class com.dataiku.dip.dataflow.exec.filter.FilterDesc$FilterUiData
     * Via: com.dataiku.dip.dataflow.exec.filter.FilterDesc
     */
    export interface _FilterUiData {
        conditions: FilterDesc.FilterUiCondition[];
        mode: string;
    }
    
    export type FilterUiData = _FilterUiData;
    
    /** 
     * Translated from class com.dataiku.dip.dataflow.exec.filter.FilterDesc$FilterUiListConditionValue
     * Via: com.dataiku.dip.dataflow.exec.filter.FilterDesc$FilterUiCondition
     */
    export interface _FilterUiListConditionValue {
        date: string;
        num: number;
        string: string;
        time: string;
        unit: string;
    }
    
    export type FilterUiListConditionValue = _FilterUiListConditionValue;
}