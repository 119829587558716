import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.EmpiricalCDF
 * Via: UIModel annotation in the class hierarchy
 */
export interface _EmpiricalCDF extends Computation._UnivariateComputation {
    type: "empirical_cdf";
}

export type EmpiricalCDF = _EmpiricalCDF;

export namespace EmpiricalCDF {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.EmpiricalCDF in PolyJSON mappings */
    export const type = "empirical_cdf";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.EmpiricalCDF$EmpiricalCDFResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _EmpiricalCDFResult extends _AvailableResult {
        type: "empirical_cdf";
        probs: number[];
        xvals: number[];
    }
    
    export type EmpiricalCDFResult = _EmpiricalCDFResult;

    export namespace EmpiricalCDFResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.EmpiricalCDF$EmpiricalCDFResult in PolyJSON mappings */
        export const type = "empirical_cdf";
    }
}