import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { ComputationResult } from './../computation-result';
import type { _AvailableResult } from './../available-result';
import type { _Computation } from './../computation';
import type { _ComputationResult } from './../computation-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.multivariate.PCA
 * Via: UIModel annotation in the class hierarchy
 */
export interface _PCA extends Computation._MultivariateComputation {
    type: "pca";
    inputDataPrefix: string;
    projectionComputation: Computation;
    projectionDimension: number;
}

export type PCA = _PCA;

export namespace PCA {
    /** Type of class com.dataiku.dip.eda.compute.computations.multivariate.PCA in PolyJSON mappings */
    export const type = "pca";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.multivariate.PCA$PCAResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _PCAResult extends _AvailableResult {
        type: "pca";
        eigenvalues: number[];
        eigenvectors: number[][];
        projectionComputationResult: ComputationResult;
    }
    
    export type PCAResult = _PCAResult;

    export namespace PCAResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.multivariate.PCA$PCAResult in PolyJSON mappings */
        export const type = "pca";
    }
}