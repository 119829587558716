<a class="workspace-object-list-element" [ngClass]="transition" (animationend)="transition['workspace-object-list-element--transitioning'] = false" [href]="link?.href" [target]="link?.target" [rel]="link?.features">

    <div class="workspace-object-list__border" [ngClass]="background || 'universe-background'"></div>

    <div class="workspace-object-list-element__title">
        {{ object.displayName || object.reference?.id }}
    </div>
    <star-interest
        [ngClass]="{ 'workspace-object-list-element__star--disabled' : !object.reference }"
        class="workspace-object-list-element__star"
        [status]="object.starred"
        (toggle)="onToggleStar($event)"
        (click)="$event.preventDefault()"
    ></star-interest>

    <div class="workspace-object-list-element__icon">
        <workspace-object-link-favicon *ngIf="displayType === WorkspaceListType.LIST && type === WorkspaceNonTaggableType.LINK" [object]="object" [iconSize]="displayType === WorkspaceListType.LIST ? 24 : 16"></workspace-object-link-favicon>
        <i *ngIf="type === TaggableType.DATASET" class="universe-color dataset" [ngClass]="(object.subType || type) | datasetTypeToIcon:(displayType === WorkspaceListType.LIST ? 24 : 16) | toModernIcon:displayType === WorkspaceListType.LIST ? 24 : 16"></i>
        <i *ngIf="type === WorkspaceNonTaggableType.STORY" class="workspace-object-datasory-icon__color" [ngClass]="'icon-dku-datastory' | toModernIcon:displayType === WorkspaceListType.LIST ? 24 : 16"></i>
        <i *ngIf="type !== WorkspaceNonTaggableType.STORY && type !== TaggableType.DATASET && !(displayType === WorkspaceListType.LIST && type === WorkspaceNonTaggableType.LINK)" [ngClass]="[type | typeToIcon:(displayType === WorkspaceListType.LIST ? 24 : 16) | toModernIcon:displayType === WorkspaceListType.LIST ? 24 : 16, type | typeToColor]"></i>
    </div>

    <markdown 
        class="workspace-object-list-element__description markdown-in-list"
        [from]="object.shortDesc || object.description">
    </markdown>

    <workspace-object-thumbnail class="workspace-object-list-element__thumbnail" [object]="object" [workspaceKey]="workspaceKey">
    </workspace-object-thumbnail>

    <div class="workspace-object-list-element__type">
        {{ type | lowercase | niceConst }}
        <i class="dku-icon-arrow-external-link-12 mleft4 dibvam link-std" *ngIf="openExternally"></i>
    </div>

    <div *ngIf="canEditObject" class="workspace-object-list-element__actions">
        <button *ngIf="type === WorkspaceNonTaggableType.STORY" class="btn btn--text btn--secondary btn--icon btn--dku-icon" (click)="onDuplicate()">
            <i class="dku-icon-copy-16"></i>
        </button>
        <button class="btn btn--text btn--secondary btn--icon btn--dku-icon" (click)="onEdit()">
            <i class="dku-icon-edit-16"></i>
        </button>
        <button class="btn btn--text btn--danger btn--icon btn--dku-icon" (click)="onRemove()">
            <i class="dku-icon-trash-16"></i>
        </button>
    </div>
</a>
