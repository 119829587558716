import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { Grouping } from './../../grouping/grouping';
import type { _AvailableResult } from './../available-result';
import type { _Grouping } from './../../grouping/grouping';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.MoodTestNSamp
 * Via: UIModel annotation in the class hierarchy
 */
export interface _MoodTestNSamp extends Computation._UnivariateComputation {
    type: "mood_test_nsamp";
    grouping: Grouping;
}

export type MoodTestNSamp = _MoodTestNSamp;

export namespace MoodTestNSamp {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.MoodTestNSamp in PolyJSON mappings */
    export const type = "mood_test_nsamp";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.MoodTestNSamp$MoodTestNSampResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _MoodTestNSampResult extends _AvailableResult {
        type: "mood_test_nsamp";
        pvalue: number;
        statistic: number;
    }
    
    export type MoodTestNSampResult = _MoodTestNSampResult;

    export namespace MoodTestNSampResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.MoodTestNSamp$MoodTestNSampResult in PolyJSON mappings */
        export const type = "mood_test_nsamp";
    }
}