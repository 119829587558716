import type { Dimension } from './dimension';
import type { _Dimension } from './dimension';

/** 
 * Translated from class com.dataiku.dip.partitioning.TimeDimension
 * Via: UIModel annotation in the class hierarchy
 */
export interface _TimeDimension extends _Dimension {
    mappedPeriod: TimeDimension.Period;
}

export type TimeDimension = _TimeDimension;

export namespace TimeDimension {
    /** 
     * Translated from enum com.dataiku.dip.partitioning.TimeDimension$Period
     * Via: com.dataiku.dip.partitioning.TimeDimension
     */
    export enum Period {
        YEAR = "YEAR",
        MONTH = "MONTH",
        DAY = "DAY",
        HOUR = "HOUR",
    }
}