export namespace DSSRunnableResult {
    /** 
     * Translated from enum com.dataiku.dip.scheduler.runnables.DSSRunnableResult$ResultType
     * Via: com.dataiku.dip.scheduler.reports.ReportItem$ProducedByRunnable
     */
    export enum ResultType {
        NONE = "NONE",
        RESULT_TABLE = "RESULT_TABLE",
        FILE = "FILE",
        FOLDER_FILE = "FOLDER_FILE",
        HTML = "HTML",
        JSON_OBJECT = "JSON_OBJECT",
        URL = "URL",
    }
}