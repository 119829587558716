import type { OneSampleTTestStat } from './one-sample-ttest-stat';
import type { StatsRecipePayloadParams } from './../stats-recipe-payload-params';
import type { _OneSampleTTestStat } from './one-sample-ttest-stat';
import type { _StatsRecipePayloadParams } from './../stats-recipe-payload-params';

/** 
 * Translated from class com.dataiku.dip.recipes.eda.stats.OneSampleTTestRecipePayloadParams
 * Via: UIModel annotation in the class hierarchy
 */
export interface _OneSampleTTestRecipePayloadParams extends _StatsRecipePayloadParams<OneSampleTTestStat> {
    type: "recipe_one-sample-t-test";
}

export type OneSampleTTestRecipePayloadParams = _OneSampleTTestRecipePayloadParams;

export namespace OneSampleTTestRecipePayloadParams {
    /** Type of class com.dataiku.dip.recipes.eda.stats.OneSampleTTestRecipePayloadParams in PolyJSON mappings */
    export const type = "recipe_one-sample-t-test";
}