import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.models.Variable
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Variable extends _ImmutableValueObject {
    name: string;
    type: Variable.Type;
}

export type Variable = _Variable;

export namespace Variable {
    /** 
     * Translated from enum com.dataiku.dip.eda.worksheets.models.Variable$Type
     * Via: com.dataiku.dip.eda.worksheets.models.Variable
     */
    export enum Type {
        CATEGORICAL = "CATEGORICAL",
        CONTINUOUS = "CONTINUOUS",
    }
}