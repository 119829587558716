import type { AxisDef } from './../../backend/model/axis-def';

/** 
 * Translated from class com.dataiku.dip.pivot.frontend.model.ChartFilter
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ChartFilter {
    active: boolean;
    allValuesInSample: boolean;
    column: string;
    columnType?: AxisDef.Type | null;
    dateFilterOption?: ChartFilter.DateRelativeOption | null;
    dateFilterPart?: ChartFilter.DateFilterPart | null;
    dateFilterType?: ChartFilter.DateFilterType | null;
    excludeOtherValues: boolean;
    excludedValues?: { [key in string]: boolean } | null;
    explicitConditions?: ChartFilter.ExplicitCondition[] | null;
    explicitExclude?: boolean | null;
    filterSelectionType?: ChartFilter.FilterSelectionType | null;
    filterType: ChartFilter.FilterType;
    id: string;
    isA: string;
    isAGlobalFilter: boolean;
    maxValue?: number | null;
    minValue?: number | null;
    selectedValues?: { [key in string]: boolean } | null;
    timezone?: string | null;
}

export type ChartFilter = _ChartFilter;

export namespace ChartFilter {
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartFilter$DateFilterPart
     * Via: com.dataiku.dip.shaker.filter.FilterRequest$FilterElement
     */
    export enum DateFilterPart {
        HOUR_OF_DAY = "HOUR_OF_DAY",
        DAY_OF_WEEK = "DAY_OF_WEEK",
        DAY_OF_MONTH = "DAY_OF_MONTH",
        MONTH_OF_YEAR = "MONTH_OF_YEAR",
        WEEK_OF_YEAR = "WEEK_OF_YEAR",
        QUARTER_OF_YEAR = "QUARTER_OF_YEAR",
        YEAR = "YEAR",
        INDIVIDUAL = "INDIVIDUAL",
    }
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartFilter$DateFilterType
     * Via: com.dataiku.dip.shaker.filter.FilterRequest$FilterElement
     */
    export enum DateFilterType {
        RANGE = "RANGE",
        RELATIVE = "RELATIVE",
        PART = "PART",
    }
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartFilter$DateRelativeOption
     * Via: com.dataiku.dip.shaker.filter.FilterRequest$FilterElement
     */
    export enum DateRelativeOption {
        THIS = "THIS",
        LAST = "LAST",
        NEXT = "NEXT",
        TO = "TO",
    }
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartFilter$ExplicitCondition
     * Via: com.dataiku.dip.pivot.backend.model.RowFilter
     */
    export interface _ExplicitCondition {
        column: string;
        columnType: AxisDef.Type;
        dateFilterType: ChartFilter.DateFilterType;
        maxValue: number;
        minValue: number;
        singleValue: string;
    }
    
    export type ExplicitCondition = _ExplicitCondition;
    
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartFilter$FilterSelectionType
     * Via: com.dataiku.dip.pivot.frontend.model.ChartFilter
     */
    export enum FilterSelectionType {
        RANGE_OF_VALUES = "RANGE_OF_VALUES",
        SINGLE_SELECT = "SINGLE_SELECT",
        MULTI_SELECT = "MULTI_SELECT",
    }
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartFilter$FilterType
     * Via: com.dataiku.dip.pivot.backend.model.RowFilter
     */
    export enum FilterType {
        NUMERICAL_FACET = "NUMERICAL_FACET",
        DATE_FACET = "DATE_FACET",
        INTERACTIVE_DATE_FACET = "INTERACTIVE_DATE_FACET",
        ALPHANUM_FACET = "ALPHANUM_FACET",
        EXPLICIT = "EXPLICIT",
    }
}