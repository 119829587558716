import type { AppManifest } from './../../coremodel/app-manifest';
import type { InfoMessage } from 'dku-frontend-core/generated-sources/dku-core/src/main/java/com/dataiku/dip/coremodel/info-message';
import type { ProjectsService } from './../../server/services/projects-service';
import type { PublicUser } from './../../security/model/public-user';
import type { _AppManifest } from './../../coremodel/app-manifest';
import type { _PublicUser } from './../../security/model/public-user';



export namespace AppsService {
    /** 
     * Translated from class com.dataiku.dip.projects.apps.AppsService$AppInstantiationResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AppInstantiationResult extends InfoMessage._InfoMessages {
        done: boolean;
        projectKey: string;
        targetProjectKey: string;
    }
    
    export type AppInstantiationResult = _AppInstantiationResult;
    
    /** 
     * Translated from class com.dataiku.dip.projects.apps.AppsService$AppListItem
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AppListItem {
        appId: string;
        appVersion: string;
        defaultImgColor: string;
        description: string;
        imgColor: string;
        imgPattern: number;
        instanceCount: number;
        instanceOwners: PublicUser[];
        isAppImg: boolean;
        label: string;
        lastInstantiation: number;
        objectImgHash: number;
        onlyLimitedVisibility: boolean;
        origin: AppsService.AppOrigin;
        originProjectKey: string;
        shortDesc: string;
        showInitials: boolean;
        tags: string[];
        useAsRecipe: boolean;
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.projects.apps.AppsService$AppListItem */
    export type AppListItem =
        | AppsService._AppListItem
        | AppsService.AppTemplateData
        | AppsService.AppTemplateInstanceData
        | AppsService.AppTemplatePageData;
    
    /** 
     * Translated from enum com.dataiku.dip.projects.apps.AppsService$AppOrigin
     * Via: com.dataiku.dip.projects.apps.AppsService$AppListItem
     */
    export enum AppOrigin {
        PROJECT = "PROJECT",
        PLUGIN = "PLUGIN",
    }
    /** 
     * Translated from class com.dataiku.dip.projects.apps.AppsService$AppTemplateData
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AppTemplateData extends AppsService._AppListItem {
        canAdminOriginProjectKey: boolean;
        instances: ProjectsService.HomepageProjectItem[];
        manifest: AppManifest;
        objectFullImgHash: number;
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.projects.apps.AppsService$AppTemplateData */
    export type AppTemplateData =
        | AppsService._AppTemplateData
        | AppsService.AppTemplateInstanceData
        | AppsService.AppTemplatePageData;
    
    /** 
     * Translated from class com.dataiku.dip.projects.apps.AppsService$AppTemplateInstanceData
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AppTemplateInstanceData extends AppsService._AppTemplateData {
        originExists: boolean;
    }
    
    export type AppTemplateInstanceData = _AppTemplateInstanceData;
    
    /** 
     * Translated from class com.dataiku.dip.projects.apps.AppsService$AppTemplatePageData
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AppTemplatePageData extends AppsService._AppTemplateData {
    }
    
    export type AppTemplatePageData = _AppTemplatePageData;
}