/** 
 * Translated from class com.dataiku.dip.futures.FutureProgressState
 * Via: com.dataiku.dip.futures.FutureProgressStateSnapshot
 */
export interface _FutureProgressState {
    cur: number;
    depth: number;
    important: boolean;
    msSinceStart: number;
    name: string;
    startTimestamp: number;
    target: number;
    unit: FutureProgressState.StateUnit;
}

/**
    Translate from class com.dataiku.dip.futures.FutureProgressState
    @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
*/
export type FutureProgressState = _FutureProgressState;

export namespace FutureProgressState {
    /** 
     * Translated from enum com.dataiku.dip.futures.FutureProgressState$StateUnit
     * Via: com.dataiku.dip.futures.FutureProgressState
     */
    export enum StateUnit {
        SIZE = "SIZE",
        FILES = "FILES",
        RECORDS = "RECORDS",
        NONE = "NONE",
    }
}