/** 
 * Translated from class com.dataiku.dip.pivot.backend.model.DateAxisParams
 * Via: UIModel annotation in the class hierarchy
 */
export interface _DateAxisParams {
    maxBinNumberForAutomaticMode: number;
    mode: DateAxisParams.BinningMode;
}

export type DateAxisParams = _DateAxisParams;

export namespace DateAxisParams {
    /** 
     * Translated from enum com.dataiku.dip.pivot.backend.model.DateAxisParams$BinningMode
     * Via: com.dataiku.dip.pivot.backend.model.DateAxisParams
     */
    export enum BinningMode {
        AUTOMATIC = "AUTOMATIC",
        SECOND = "SECOND",
        QUARTER_OF_MINUTE = "QUARTER_OF_MINUTE",
        MINUTE = "MINUTE",
        QUARTER_OF_HOUR = "QUARTER_OF_HOUR",
        HOUR = "HOUR",
        QUARTER_OF_DAY = "QUARTER_OF_DAY",
        DAY = "DAY",
        WEEK = "WEEK",
        MONTH = "MONTH",
        QUARTER = "QUARTER",
        YEAR = "YEAR",
        HOUR_OF_DAY = "HOUR_OF_DAY",
        DAY_OF_WEEK = "DAY_OF_WEEK",
        DAY_OF_MONTH = "DAY_OF_MONTH",
        MONTH_OF_YEAR = "MONTH_OF_YEAR",
        WEEK_OF_YEAR = "WEEK_OF_YEAR",
        QUARTER_OF_YEAR = "QUARTER_OF_YEAR",
    }
}