import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.Kurtosis
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Kurtosis extends Computation._UnivariateComputation {
    type: "kurtosis";
}

export type Kurtosis = _Kurtosis;

export namespace Kurtosis {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.Kurtosis in PolyJSON mappings */
    export const type = "kurtosis";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.Kurtosis$KurtosisResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _KurtosisResult extends _AvailableResult {
        type: "kurtosis";
        value: number;
    }
    
    export type KurtosisResult = _KurtosisResult;

    export namespace KurtosisResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.Kurtosis$KurtosisResult in PolyJSON mappings */
        export const type = "kurtosis";
    }
}