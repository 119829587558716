export namespace NPSSurveyService {
    /** 
     * Translated from class com.dataiku.dip.server.services.NPSSurveyService$NPSSurveySettings
     * Via: com.dataiku.dip.server.services.UsersService$UserDTOBase
     */
    export interface _NPSSurveySettings {
        nextAction: number;
        state: NPSSurveyService.NPSSurveyState;
    }
    
    export type NPSSurveySettings = _NPSSurveySettings;
    
    /** 
     * Translated from enum com.dataiku.dip.server.services.NPSSurveyService$NPSSurveyState
     * Via: com.dataiku.dip.server.services.NPSSurveyService$NPSSurveySettings
     */
    export enum NPSSurveyState {
        INITIAL = "INITIAL",
        INITIAL_POSTPONED = "INITIAL_POSTPONED",
        RECURRING = "RECURRING",
        RECURRING_POSTPONED = "RECURRING_POSTPONED",
        OPTOUT = "OPTOUT",
    }
}