import type { Distribution } from './distribution';
import type { _Distribution } from './distribution';

/** 
 * Translated from class com.dataiku.dip.eda.compute.distributions.Beta
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Beta extends _Distribution {
    type: "beta";
}

export type Beta = _Beta;

export namespace Beta {
    /** Type of class com.dataiku.dip.eda.compute.distributions.Beta in PolyJSON mappings */
    export const type = "beta";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.distributions.Beta$FittedBeta
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FittedBeta extends Distribution._FittedDistribution {
        type: "beta";
        alpha: number;
        beta: number;
        lower: number;
        upper: number;
    }
    
    export type FittedBeta = _FittedBeta;

    export namespace FittedBeta {
        /** Type of class com.dataiku.dip.eda.compute.distributions.Beta$FittedBeta in PolyJSON mappings */
        export const type = "beta";
    }
}