import type { InteractiveQuery } from './interactive-query';
import type { _InteractiveQuery } from './interactive-query';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.queries.ListMostFrequentValues
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ListMostFrequentValues extends _InteractiveQuery {
    type: "list_most_frequent_values";
    column: string;
    maxValues: number;
}

export type ListMostFrequentValues = _ListMostFrequentValues;

export namespace ListMostFrequentValues {
    /** Type of class com.dataiku.dip.eda.worksheets.queries.ListMostFrequentValues in PolyJSON mappings */
    export const type = "list_most_frequent_values";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.queries.ListMostFrequentValues$ListMostFrequentValuesResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ListMostFrequentValuesResult extends InteractiveQuery._InteractiveQueryResult {
        type: "list_most_frequent_values";
        values: string[];
    }
    
    export type ListMostFrequentValuesResult = _ListMostFrequentValuesResult;

    export namespace ListMostFrequentValuesResult {
        /** Type of class com.dataiku.dip.eda.worksheets.queries.ListMostFrequentValues$ListMostFrequentValuesResult in PolyJSON mappings */
        export const type = "list_most_frequent_values";
    }
}