import type { UserAttributes } from './user-attributes';
import type { _UserAttributes } from './user-attributes';

/** 
 * Translated from class com.dataiku.dip.security.auth.ExternalUser
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ExternalUser {
    profile: string;
    status: ExternalUser.Status;
    userAttributes: UserAttributes;
}

export type ExternalUser = _ExternalUser;

export namespace ExternalUser {
    /** 
     * Translated from enum com.dataiku.dip.security.auth.ExternalUser$Status
     * Via: com.dataiku.dip.security.auth.ExternalUser
     */
    export enum Status {
        NOT_PROVISIONED = "NOT_PROVISIONED",
        UNSYNCED = "UNSYNCED",
        SYNCED = "SYNCED",
        SOURCE_CONFLICT = "SOURCE_CONFLICT",
    }
}