import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DkuMatTooltipModule } from '@shared/components/dku-mat-tooltip/dku-mat-tooltip.module';
import { ReactiveInputModule, WarningIconModule } from '../../modules';
import { AxisTicksConfigurationFormComponent } from './axis-ticks-configuration-form.component';


@NgModule({
    declarations: [
        AxisTicksConfigurationFormComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        DkuMatTooltipModule,
        NgSelectModule,
        ReactiveFormsModule,
        ReactiveInputModule,
        WarningIconModule
    ],
    exports: [
        AxisTicksConfigurationFormComponent
    ]
})

export class AxisTicksConfigurationFormModule { }
