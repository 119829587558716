import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'reset-button',
    templateUrl: './reset-button.component.html'
})

export class ResetButtonComponent {
    @Output() onReset = new EventEmitter<void>();

    label$ = new BehaviorSubject<string>('Reset');
    tooltip$ = new BehaviorSubject<string>('Reset');
    hasTooltip$ = new BehaviorSubject<boolean>(false);
    hasLabel$ = new BehaviorSubject<boolean>(false);

    constructor() { }

    @Input()
    set label(value: string | undefined) {
        if (value) {
            this.label$.next(value);
        }
    }

    @Input()
    set tooltip(value: string | null | undefined) {
        if (value) {
            this.tooltip$.next(value);
        }
    }

    @Input()
    set hasLabel(value: boolean) {
        this.hasLabel$.next(!!value);
    }

    @Input()
    set hasTooltip(value: boolean) {
        this.hasTooltip$.next(!!value);
    }
}
