import { FormArray, AbstractControl, ValidatorFn, AsyncValidatorFn, AbstractControlOptions } from "@angular/forms";

// Extension of FormArray that manages the list of controls automatically from values
// Controls are created on-demand from a factory, whereas you need to sync
// values & controls manually with FormArray
export class FormArrayRepeat<TControl extends AbstractControl<any> = any> extends FormArray<TControl> {
    constructor(
        private controlFactory: () => TControl,
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        super([], validatorOrOpts, asyncValidator);
    }

    // Add a new control at the end (shortcut)
    add() {
        this.push(this.controlFactory());
    }

    // Override Angular's method
    patchValue(value: any[], options: { onlySelf?: boolean, emitEvent?: boolean } = {}): void {
        this.updateControls(value);
        super.patchValue(value, options);
    }

    // Override Angular's method
    setValue(value: any[], options: { onlySelf?: boolean, emitEvent?: boolean } = {}): void {
        this.updateControls(value);
        super.setValue(value, options);
    }

    // Override Angular's method
    reset(value: any = [], options: { onlySelf?: boolean, emitEvent?: boolean } = {}): void {
        this.updateControls(value);
        super.reset(value, options);
    }

    private updateControls(value: any) {
        if (!value) {
            value = [];
        }
        const expectedNbControls = value.length;
        while (this.controls.length > expectedNbControls) {
            this.removeAt(this.controls.length - 1);
        }
        while (this.controls.length < expectedNbControls) {
            this.push(this.controlFactory());
        }
    }
}
