import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { ChartTensorDataWrapper } from './chart-tensor-data-wrapper.model';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { DoubleDataTensor } from '@model-main/pivot/backend/dss/double-data-tensor';
import { AxisHandler } from 'generated-sources';


/**
 * A wrapper for easily access the data stored in a PivotTableTensorResponse
 * @param data
 * @param axesDef: a map from axis names to axis idx in the tensor response, axis names can then be used to retrieve the data instead of the idx for better code readability
 */
// This service is mocked in mocks.js to maintain angularjs karma unit tests.
export class ChartRadarDataWrapper extends ChartTensorDataWrapper {

    private min: number;
    private max: number;
    private polygonsSource: ChartDef.PolygonSources;
    private aggregations: DoubleDataTensor[];
    private axisLabels: AxisHandler.AxisElt[][];

    constructor(public data: PivotTableTensorResponse, public axesDef: Record<string, number> = {}, public chartDef: any) {
        super(data, axesDef);
        this.polygonsSource = chartDef.radarOptions.polygonsSource;

        const polygonsExtends = this.data.aggregations.map((_, index) => this.getAggrExtent(index));
        this.max = Math.max(...polygonsExtends.map((extend) => extend[1] || 0).flat());
        this.min = Math.min(...polygonsExtends.map((extend) => extend[0] || 0).flat());

        this.axisLabels = [
            this.data.axisLabels[0].map((dimension) => ({...dimension, column: dimension.label})),
            chartDef.genericMeasures.map((measure: any) => ({...measure, label: measure.column}))
        ];  // map values for tooltips when this.polygonsSource === DIMENSIONS


        if(!this.shouldDrawPolygonsFromMeasures()) {
            // Rearrange aggregations when dimensions are polygons, creating one polygon per dimension item
            const newAggregations: any[] = Array.from(
                new Array(this.data.aggregations[0].axisLengths[0]),
                () => ({
                    axisLengths: [this.data.aggregations.length],
                    multipliers: [1],
                    numAxes: 1,
                    tensor: [],
                    hasNullValues: false
                })
            );

            this.data.aggregations.forEach((agg) => {
                agg.tensor.forEach((value, index) => {
                    newAggregations[index].tensor.push(value);
                });
            });

            this.aggregations = newAggregations;
        } else {
            this.aggregations = this.data.aggregations;
        }
    }

    aggr(aggrIdx: number): any {
        const aggr: any = {};

        aggr.get = (coord: Record<string, number>) => {
            if(this.shouldDrawPolygonsFromMeasures()) {
                return this.aggregations[aggrIdx].tensor;
            } else {
                return this.aggregations[coord?.color !== undefined ? coord.color : aggrIdx].tensor;
            }
        };

        aggr.getAxisValue = (axisName: string, axisCoord: number) => (
            this.aggregations[aggrIdx].axes[this.axesDef[axisName]][axisCoord]
        );

        return aggr;
    }

    getAxisLabels(axisName: string): Array<any> | undefined {
        return this.axisLabels[this.axesDef[axisName]];
    }

    /**
     * Returns minimum for all polygons
    */
    getMinValue(): number {
        return this.min;
    }

    /**
     * Returns maximum for all polygons
    */
    getMaxValue(): number {
        return this.max;
    }

    shouldDrawPolygonsFromMeasures(): boolean {
        return this.polygonsSource === ChartDef.PolygonSources.MEASURES;
    }
}
