import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopularDatasetsTableComponent } from './components/popular-datasets-table/popular-datasets-table.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { DataikuAPIModule } from '@core/dataiku-api/dataiku-api.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { MatSortModule } from '@angular/material/sort';
import { ComponentsModule } from "../../shared/components/components.module";
import { DkuMatTooltipModule } from '@shared/components/dku-mat-tooltip/dku-mat-tooltip.module';
import { MigrationModule } from '@migration/migration.module';


@NgModule({
    declarations: [
        PopularDatasetsTableComponent,
    ],
    exports: [
        PopularDatasetsTableComponent,
    ],
    imports: [
        CommonModule,
        DataikuAPIModule,
        MatTableModule,
        MatSortModule,
        PipesModule,
        ComponentsModule,
        DkuMatTooltipModule,
        MigrationModule,
    ]
})
export class PopularDatasetsModule { }
