<h2 class="foldable-title" (click)="toggle()">
    <i [ngClass]="{
        'dku-icon-chevron-right-16': (opened$ | async) === false,
        'dku-icon-chevron-down-16': opened$ | async,
      }"></i>
    <ng-content select=".title"></ng-content>
</h2>
<div class="options-zone" [hidden]="(opened$ | async) === false">
    <ng-content select=".content"></ng-content>
</div>
