/** 
 * Translated from enum com.dataiku.dip.pivot.frontend.model.DigitGrouping
 * Via: UIModel annotation in the class hierarchy
 */
export enum DigitGrouping {
    DEFAULT = "DEFAULT",
    NOTHING_COMMA = "NOTHING_COMMA",
    US = "US",
    SI = "SI",
    POINT_COMMA = "POINT_COMMA",
    SPACE_POINT = "SPACE_POINT",
}