import type { CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { PACF } from './../../compute/computations/timeseries/pacf';
import type { TimeSeriesCard } from './time-series-card';
import type { _CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { _TimeSeriesCard } from './time-series-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.ACFPlotCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ACFPlotCard extends _TimeSeriesCard, _CardWithConfidenceLevel {
    type: "acf_plot";
    adjusted: boolean;
    confidenceLevel: number;
    isPartial: boolean;
    nLags?: number | null;
    pacfMethod: PACF.Method;
    showSummary: boolean;
}

export type ACFPlotCard = _ACFPlotCard;

export namespace ACFPlotCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.ACFPlotCard in PolyJSON mappings */
    export const type = "acf_plot";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.ACFPlotCard$ACFPlotCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ACFPlotCardResult extends TimeSeriesCard._TimeSeriesCardResult {
        type: "acf_plot";
        coefficients: number[];
        confidenceIntervals: number[][];
    }
    
    export type ACFPlotCardResult = _ACFPlotCardResult;

    export namespace ACFPlotCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.ACFPlotCard$ACFPlotCardResult in PolyJSON mappings */
        export const type = "acf_plot";
    }
}