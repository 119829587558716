import type { BivariateCard } from './bivariate-card';
import type { CardResult } from './card-result';
import type { Distribution2D } from './../../compute/distributions/distribution2-d';
import type { Fit2DDistribution } from './../../compute/computations/bivariate/fit2-ddistribution';
import type { _BivariateCard } from './bivariate-card';
import type { _CardResult } from './card-result';
import type { _Distribution2D } from './../../compute/distributions/distribution2-d';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.Fit2DDistributionCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Fit2DDistributionCard extends _BivariateCard {
    type: "fit_2d_distribution";
    distribution: Distribution2D;
    xResolution: number;
    yResolution: number;
}

export type Fit2DDistributionCard = _Fit2DDistributionCard;

export namespace Fit2DDistributionCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.Fit2DDistributionCard in PolyJSON mappings */
    export const type = "fit_2d_distribution";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.Fit2DDistributionCard$Fit2DDistributionCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _Fit2DDistributionCardResult extends _CardResult {
        type: "fit_2d_distribution";
        result: Fit2DDistribution.Fit2DDistributionResult;
    }
    
    export type Fit2DDistributionCardResult = _Fit2DDistributionCardResult;

    export namespace Fit2DDistributionCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.Fit2DDistributionCard$Fit2DDistributionCardResult in PolyJSON mappings */
        export const type = "fit_2d_distribution";
    }
}