import { Inject, Injectable } from '@angular/core';
import { FrontendChartDef } from '../interfaces';
import { CustomMeasure } from '@model-main/pivot/frontend/model/custom-measure';
import { MeasureDef } from '@model-main/pivot/frontend/model/measure-def';
import { Aggregation } from '@model-main/pivot/backend/model/aggregation';


@Injectable({
    providedIn: 'root'
})
export class ChartCustomMeasuresService {

    // example: {0: { genericMeasures: [0, 1], colorMeasure: [0] }, ...}
    public impactedCharts: Record<number, Record<string, [number]>> = [];

    constructor( @Inject('ChartTypeChangeUtils') private chartTypeChangeUtilsService: any) {}

    /**
     * Apply the `modifyFunction` to the matching matching measures list.
     */
    public modifyMeasuresInCharts(charts: Array<{def: FrontendChartDef}>, newMeasure: CustomMeasure | undefined, modifyFunction: Function) {
        Object.entries(this.impactedCharts).forEach(([chartIndex, measuresByProperty]) => {
            Object.entries(measuresByProperty).forEach(([measureProperty, measureIndexes]) => {
                const measures = charts[Number(chartIndex)].def[measureProperty as keyof FrontendChartDef] || [];
                modifyFunction(measures, measureIndexes, newMeasure);
            });
        });
    }

    /**
     * Iterate through charts and all defined measures. If one is found,
     * we add map its index to the property name, which is itself linked
     * to the chart index.
     */
    public setImpactedCharts(charts: Array<{def: FrontendChartDef}>, name: string, formula?: string) {
        this.impactedCharts = charts.reduce((impacted: any, chart: any, chartIndex: number) => {
            this.chartTypeChangeUtilsService.getChartDefMeasuresProperties().forEach((property: string) => {
                const measures = chart.def[property] || [];
                measures.forEach((measure: MeasureDef, measureIndex: number) => {
                    if (measure.column === name && measure.type === 'CUSTOM' && ((formula && measure.customFunction === formula) || !formula)) {
                        impacted[chartIndex] = impacted[chartIndex] || {};
                        impacted[chartIndex][property] = impacted[chartIndex][property] || [];
                        impacted[chartIndex][property].push(measureIndex);
                    }
                });
            });
            return impacted;
        }, {});
    }
    
    private removeByIndicesInPlace(measures: Array<CustomMeasure>, indices: [number]) {
        indices.sort((a: number, b: number) => b - a).forEach((index: number) => measures.splice(index, 1));
    }

    private replaceByIndicesInPlace(measures: Array<MeasureDef>, indices: [number], newMeasure: CustomMeasure) {
        indices.forEach((index: number) => measures[index] = { ...measures[index], customFunction: newMeasure.formula, column: newMeasure.name });
    }

    public removeByNameInPlace(measures: Array<CustomMeasure>, name: string) {
        for (let i = 0; i < measures.length; i++) {
            if (measures[i].name === name) {
                this.removeByIndicesInPlace(measures, [i]);
                break;
            }
        }
    }

    public deleteMeasureFromCharts(charts: Array<{def: FrontendChartDef}>) {
        this.modifyMeasuresInCharts(charts, undefined, (measures: any, measureIndexes: any) => {
            this.removeByIndicesInPlace(measures, measureIndexes);
        });
    }
    
    public replaceMeasureFromCharts(charts: Array<{def: FrontendChartDef}>, newMeasure: CustomMeasure) {
        this.modifyMeasuresInCharts(charts, newMeasure, (measures: any, measureIndexes: any, newMeasure: any) => {
            this.replaceByIndicesInPlace(measures, measureIndexes, newMeasure);
        });
    }

    public getCustomMeasure(chartAggregation: Aggregation, measures: Array<{name:string, function: string}>) {
        const defaultCustomMeasure = { column: chartAggregation.column, function: chartAggregation.customFunction, isDefaultMeasure: true };
        if (measures) {
            const cmFound = measures.find(cm => cm.name === defaultCustomMeasure.column && cm.function === defaultCustomMeasure.function);
            if (cmFound !== undefined) {
                return cmFound;
            }
        }
        return defaultCustomMeasure;
    };


}
