import { Injectable } from '@angular/core';
import { utcFormat } from 'd3-time-format';
import { FrontendChartDef, FrontendNumberFormattingOptions } from '../../interfaces';
import { ChartTensorDataWrapper } from '../../models';
import { NumberFormatterService } from './number-formatter.service';

@Injectable({
    providedIn: 'root'
})
/**
 * Utils to compute format for measures, dimensions labels (agnostic, used for both d3 and echarts)
 * (!) This service previously was in:
 * - static/dataiku/js/simple_report/services/formatting/chart-formatting.service.js
 * - static/dataiku/js/simple_report/services/chart-formatting.service.js
 */
export class ChartFormattingService {
    constructor(
        private numberFormatterService: NumberFormatterService
    ) {
    }

    createMeasureFormatters(chartDef: FrontendChartDef, chartData: ChartTensorDataWrapper, labelsResolution: number): Array<(value: number) => string> {
        const formatters = [];
        let mIdx = 0;

        /*
         *  Measure formatters are based on aggregated data
         *  @TODO : handle unaggregated data like scatter?
         */
        if (chartData instanceof ChartTensorDataWrapper) {
            chartDef.genericMeasures.forEach(measure => {
                let extent = chartData.getAggrExtent(mIdx++);
                formatters.push(this.getForRange(extent[0] || 0, extent[1] || 0, labelsResolution, measure));
            });

            if (chartDef.xMeasure?.length) {
                let extent = chartData.getAggrExtent(mIdx++);
                formatters.push(this.getForRange(extent[0] || 0, extent[1] || 0, labelsResolution, chartDef.xMeasure[0]));
            }

            if (chartDef.yMeasure?.length) {
                let extent = chartData.getAggrExtent(mIdx++);
                formatters.push(this.getForRange(extent[0] || 0, extent[1] || 0, labelsResolution, chartDef.yMeasure[0]));
            }

            if (chartDef.sizeMeasure?.length) {
                let extent = chartData.getAggrExtent(mIdx++);
                formatters.push(this.getForRange(extent[0] || 0, extent[1] || 0, labelsResolution, chartDef.sizeMeasure[0]));
            }

            if (chartDef.colorMeasure?.length) {
                let extent = chartData.getAggrExtent(mIdx++);
                formatters.push(this.getForRange(extent[0] || 0, extent[1] || 0, labelsResolution, chartDef.colorMeasure[0]));
            }

            chartDef.tooltipMeasures.forEach((measure) => {
                let extent = chartData.getAggrExtent(mIdx++);
                formatters.push(this.getForRange(extent[0] || 0, extent[1] || 0, labelsResolution, measure));
            });
        }

        return formatters;
    }

    /**
     * Simple wrapper for numberFormatterService.getForRange
     * We force legacy options coma and strippedValues that we always want the same way in the context of charts.
     * Returns a number formatter configured in the context of a range of values.
     */
    getForRange(
        minValue: number,
        maxValue: number,
        numValues: number,
        measureOptions?: any,
        shouldFormatInPercentage?: boolean) {
        return this.numberFormatterService.getForRange(minValue, maxValue, numValues, true, true, measureOptions, shouldFormatInPercentage);
    }

    /**
     * Simply use getForRange twice and join the results with a "-"
     */
    getForRangeThenJoin(minValue: number, maxValue: number, numValues: number, measureOptions: any): (extent: [number, number]) => string {
        return (extent: [number, number]) => extent
            .map(value => this.getForRange(minValue, maxValue, numValues, measureOptions)(value))
            .join('-');
    }

    /**
     * Get a number formatter from measure or dimension formatting options.
     *
     * To be used mostly in contexts where we have room to display the number correctly, for instance:
     * - in a tooltip
     * - in a table
     *
     * Not to be used in:
     * - an axis
     * - a legend
     * - value displayed in chart
     *
     * @param                       measureOrUAorDimensionsOptions  - measures / UAs / numerical dimensions options
     * @returns {(value: number) => string}
     */
    getForIsolatedNumber(measureOrUAOrDimensionsOptions?: any): (value: number) => string {
        return this.numberFormatterService.get(measureOrUAOrDimensionsOptions as FrontendNumberFormattingOptions);
    }

    /**
     * Converts an epoch timestamp to date in universal time
     * @param {number} msEpoch - epoch timestamp
     * @returns {string} date as YYYY-MM-DD
     */
    getForDate() {
        return (msEpoch: number) => (utcFormat('%Y-%m-%d')(new Date(msEpoch)));
    }

    /**
     * getForAxis is used to get correct format for a given axis (it is used by d3 and echarts, so it should stay agnostic)
     *
     * @param { number }            minValue
     * @param { number }            maxValue
     * @param { number }            numValues
     * @param { FormattingOptions } [formattingOptions = {}]   The number formatting options. Can be omitted to use the default formatting.
     * @param { boolean }           shouldFormatInPercentage
     */
    getForAxis(
        minValue: number,
        maxValue: number,
        numValues: number,
        formattingOptions: FrontendNumberFormattingOptions,
        shouldFormatInPercentage = false) {
        return this.getForRange(minValue, maxValue, numValues, formattingOptions, shouldFormatInPercentage);
    }

    getForOrdinalAxis(value: any): any {
        if (value === '___dku_no_value___') {
            return 'No value';
        }
        return value;
    }

    getForBinnedAxis(tickExtents: any, formattingOptions = {}) {
        const finalFormattingOptions = {
            ...formattingOptions,
            shouldFormatInPercentage: false,
            stripZeros: true
        };

        const minValue = tickExtents[0][0];
        const maxValue = tickExtents[tickExtents.length - 1][1];
        const numValues = tickExtents.length * 2;

        return (_: any, index: number) => this.getForRangeThenJoin(minValue, maxValue, numValues, finalFormattingOptions)(tickExtents[index]);
    }

    getForRadialChart(
        minValue: number,
        maxValue: number,
        measureOptions?: any,
        shouldFormatInPercentage?: boolean) {
        // numValues set to 1000 because we don't want too much precision to avoid wasting space
        return this.numberFormatterService.getForRange(minValue, maxValue, 1000, true, true, measureOptions, shouldFormatInPercentage);
    }

    getForLegend(
        formattingOptions: any = {},
        range: [number, number] | [undefined, undefined],
        numValues = 10): (value: number) => string
    {
        return this.getForRange(range[0] || 0, range[1] || 0, numValues, formattingOptions);
    }
}
