import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual } from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { TextFormattingFormComponent } from '../text-formatting-form';


@UntilDestroy()
@Component({
    selector: 'table-formatting-form',
    templateUrl: './table-formatting-form.component.html'
})
export class TableFormattingFormComponent implements OnInit {
    @Output() tableFormattingChange = new EventEmitter<ChartDef.PivotTableFormatting>();
    @ViewChildren(TextFormattingFormComponent) textFormattingComponents: QueryList<TextFormattingFormComponent>;

    public tableFormattingOptions = [
        {
            label: 'Row headers',
            formControlName: 'rowHeaders',
            showColorSettings: true,
            show: true,
            id: 'table-formatting__row-headers'
        },
        {
            label: 'Column headers',
            formControlName: 'columnHeaders',
            showColorSettings: true,
            show: true,
            id: 'table-formatting__column-headers'
        },
        {
            label: 'Values',
            formControlName: 'values',
            showColorSettings: true,
            show: true,
            id: 'table-formatting__values'
        }
    ];

    public tableFormattingForm = new UntypedFormGroup({
        rowHeaders: new UntypedFormControl(),
        columnHeaders: new UntypedFormControl(),
        values: new UntypedFormControl()
    });

    @Input()
    set hasRowHeaders(value: boolean) {
        this.tableFormattingOptions[0].show = !!value;
        if (value) {
            this.tableFormattingForm.addControl('rowHeaders', new UntypedFormControl({ fontSize: 12, fontColor: '#333' }));
        } else {
            this.tableFormattingForm.removeControl('rowHeaders');
        }
    }

    @Input()
    set canSetValuesColor(value: boolean) {
        this.tableFormattingOptions[2].showColorSettings = !!value;
    }

    @Input()
    set tableFormatting(value: ChartDef.PivotTableFormatting) {
        if (!this.equals(value, this.tableFormattingForm.getRawValue())) {
            this.tableFormattingForm.patchValue(value);
        }
    }

    ngOnInit() {
        this.tableFormattingForm.valueChanges
            .pipe(
                untilDestroyed(this),
                debounceTime(10),
                distinctUntilChanged(this.equals)
            )
            .subscribe((nextValue: ChartDef.PivotTableFormatting) => {
                this.tableFormattingChange.emit({ ...nextValue });
            });
    }

    equals(tableFormatting: ChartDef.PivotTableFormatting, tableFormattingForm: ChartDef.PivotTableFormatting): boolean {
        return tableFormatting && isEqual(tableFormatting.rowHeaders, tableFormattingForm.rowHeaders) && isEqual(tableFormatting.columnHeaders, tableFormattingForm.columnHeaders) &&
        isEqual(tableFormatting.values, tableFormattingForm.values);
    }

    public resetTableFormatting() {
        this.textFormattingComponents.forEach(component => component.resetFont());
    }
}
