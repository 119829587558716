import type { BinningMode } from './../../models/binning-mode';
import type { BoxPlotFragment } from './../fragments/box-plot-fragment';
import type { CardResult } from './../card-result';
import type { Filter } from './../../../compute/filtering/filter';
import type { NumericalHistogramFragment } from './../fragments/numerical-histogram-fragment';
import type { UnivariateCard } from './../univariate-card';
import type { _BoxPlotFragment } from './../fragments/box-plot-fragment';
import type { _CardResult } from './../card-result';
import type { _Filter } from './../../../compute/filtering/filter';
import type { _NumericalHistogramFragment } from './../fragments/numerical-histogram-fragment';
import type { _UnivariateCard } from './../univariate-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.univariate_header.NumericalHistogramCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _NumericalHistogramCard extends _UnivariateCard {
    type: "numerical_histogram";
    binningMode: BinningMode;
    customBinningBoundaries: number[];
    highlightFilter?: Filter | null;
    nbBins: number;
    showBoxPlot: boolean;
    showHistogram: boolean;
}

export type NumericalHistogramCard = _NumericalHistogramCard;

export namespace NumericalHistogramCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.univariate_header.NumericalHistogramCard in PolyJSON mappings */
    export const type = "numerical_histogram";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.univariate_header.NumericalHistogramCard$NumericalHistogramCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _NumericalHistogramCardResult extends _CardResult {
        type: "numerical_histogram";
        boxPlot?: BoxPlotFragment | null;
        histogram?: NumericalHistogramFragment | null;
    }
    
    export type NumericalHistogramCardResult = _NumericalHistogramCardResult;

    export namespace NumericalHistogramCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.univariate_header.NumericalHistogramCard$NumericalHistogramCardResult in PolyJSON mappings */
        export const type = "numerical_histogram";
    }
}