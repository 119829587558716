import { Pipe, PipeTransform } from '@angular/core';
import { format, formatDefaultLocale } from 'd3-format';

@Pipe({
  name: 'smartNumber'
})
export class SmartNumberPipe implements PipeTransform {
    constructor() {
        //  This is mandatory as d3-format uses a different minus character: "−" (U+2212) instead of "-"
        formatDefaultLocale({
            minus: '-',
            thousands: ',',
            grouping: [3],
            currency: ['$', ''],
            decimal: '.'
        });
    }

    transform(d: number| null | undefined, trim0: boolean = false): string {
        const expFormatter = format(".2e");
        const siFormatter = format(trim0? ".2~s" : ".2s");
        const digitFormatters : ((n: number)=>string)[] = [];
        for (let i = 0; i < 6; i++) {
            digitFormatters.push(format("." + i + "f"));
        }
        if (typeof d != "number") {
            return "NA";
        }
        const abs = Math.abs(d);
        if (abs >= 1e12) {
            return expFormatter(d);
        } else if (abs >= 100000) {
             return siFormatter(d);
        } else if (abs >= 100) {
            return digitFormatters[0](d);
        } else if (abs >= 1) {
            if (abs % 1 === 0) return digitFormatters[0](d);
            return digitFormatters[2](d);
        } else if (abs === 0) {
            return digitFormatters[0](d);
        } else if (abs < 0.00001) {
            return d.toPrecision(3);
        } else {
            const x = Math.min(5, 2 - (Math.log10(abs) | 0));
            return digitFormatters[x](d);
        }
    }
}
