import type { BoxPlotFragment } from './fragments/box-plot-fragment';
import type { CardResult } from './card-result';
import type { CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { UnivariateCard } from './univariate-card';
import type { _BoxPlotFragment } from './fragments/box-plot-fragment';
import type { _CardResult } from './card-result';
import type { _CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { _UnivariateCard } from './univariate-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.SignTest1SampCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _SignTest1SampCard extends _UnivariateCard, _CardWithConfidenceLevel {
    type: "sign_test_1samp";
    confidenceLevel: number;
    hypothesizedMedian: number;
}

export type SignTest1SampCard = _SignTest1SampCard;

export namespace SignTest1SampCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.SignTest1SampCard in PolyJSON mappings */
    export const type = "sign_test_1samp";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.SignTest1SampCard$SignTest1SampCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _SignTest1SampCardResult extends _CardResult {
        type: "sign_test_1samp";
        boxPlot: BoxPlotFragment;
        nbEqual: number;
        nbLarger: number;
        nbSmaller: number;
        pvalue: number;
    }
    
    export type SignTest1SampCardResult = _SignTest1SampCardResult;

    export namespace SignTest1SampCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.SignTest1SampCard$SignTest1SampCardResult in PolyJSON mappings */
        export const type = "sign_test_1samp";
    }
}