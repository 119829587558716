import type { Distribution } from './distribution';
import type { _Distribution } from './distribution';

/** 
 * Translated from class com.dataiku.dip.eda.compute.distributions.Pareto
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Pareto extends _Distribution {
    type: "pareto";
}

export type Pareto = _Pareto;

export namespace Pareto {
    /** Type of class com.dataiku.dip.eda.compute.distributions.Pareto in PolyJSON mappings */
    export const type = "pareto";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.distributions.Pareto$FittedPareto
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FittedPareto extends Distribution._FittedDistribution {
        type: "pareto";
        scale: number;
        shape: number;
    }
    
    export type FittedPareto = _FittedPareto;

    export namespace FittedPareto {
        /** Type of class com.dataiku.dip.eda.compute.distributions.Pareto$FittedPareto in PolyJSON mappings */
        export const type = "pareto";
    }
}