import { Injectable } from '@angular/core';
import { rgb, RGBColor } from 'd3-color';
import { isEmpty } from 'lodash';

class RGBA {
    r: number;
    g: number;
    b: number;
    a: number;
}

@Injectable({
    providedIn: 'root'
})
export class ColorUtilsService {
    /**
     * Desaturate a color
     * @param {string} color
     * @return {RGBColor} color
     */
    desaturate(color: string): RGBColor {
        const col = rgb(color);
        let mean = (col.r + col.g + col.b) / 5;
        mean = mean - (mean - 255) * 0.8;
        return rgb(mean, mean, mean);
    }

    /**
     * Make a darker color. Supports rgba in input (but drops the a)
     * @param {string} color
     * @return {string} color
     */
    darken(color: string): string {
        const match = /^rgba\(([\d]+),([\d]+),([\d]+),([\d]+|[\d]*.[\d]+)\)/.exec(color);
        let rgbColor;
        if (match) {
            rgbColor = rgb(+match[1], +match[2], +match[3]);
        } else {
            rgbColor = rgb(color);
        }
        return rgbColor.darker().formatHsl();
    }

    /**
     * Add transparency to a color
     * @param {string} color
     * @param {number} transparency
     * @returns {string} rgba color
     */
    toRgba(color: string, transparency: number) {
        if (isEmpty(color)) {
            color = '#000'; //  Default color when empty to mimic d3 3.x version
        }
        const { r, g, b } = rgb(color);
        transparency = !isNaN(transparency) ? transparency : 1;
        return ['rgba(', r, ',', g, ',', b, ',', transparency, ')'].join('');
    }

    /**
     * Parse a rgba color and return the hex code.
     * For example 'rgba(255, 255, 255, 1)' will return '#ffffff'
     * @param {string} rgba
     * @returns {string} hex color
     */
    toHex(rgba: string) {
        const match = /^rgba\((-?\d+),(-?\d+),(-?\d+),\d.?\d*\)/.exec(rgba);
        if (match) {
            return match.splice(1).reduce((result, value) =>
                result + Math.max(Math.min(255, parseInt(value)), 0).toString(16).padStart(2, '0')
            , '#');
        }
        throw new Error(`Invalid rgba color : ${rgba}}`);
    }

    /**
     * Parse a string to RGBA.
     * @param {string} str String representing a color.
     * @return {RGBA} Red, green, blue and alpha values.
     */
    strToRGBA(str: string): RGBA {
        const ctx = document.createElement('canvas').getContext('2d');
        let rgba: RGBA = {
            r: 0,
            g: 0,
            b: 0,
            a: 0
        };

        if (ctx) {
            const regex = /^((rgba)|rgb)[\D]+([\d.]+)[\D]+([\d.]+)[\D]+([\d.]+)[\D]*?([\d.]+|$)/i;
            let match;

            // Default to black for invalid color strings
            ctx.fillStyle = '#000';

            // Use canvas to convert the string to a valid color string
            ctx.fillStyle = str;
            match = regex.exec(ctx.fillStyle);

            if (match) {
                rgba = {
                    r: +match[3],
                    g: +match[4],
                    b: +match[5],
                    a: +match[6]
                };

                // Workaround to mitigate a Chromium bug where the alpha value is rounded incorrectly
                rgba.a = +rgba.a.toFixed(2);
            } else {
                match = ctx.fillStyle
                    .replace('#', '')
                    .match(/.{2}/g);

                const rgbaArray = match ? match.map((h) => parseInt(h, 16)) : [0, 0, 0];

                rgba = {
                    r: rgbaArray[0],
                    g: rgbaArray[1],
                    b: rgbaArray[2],
                    a: 1
                };
            }
        }

        return rgba;
    }

    /**
     * Convert RGBA to Hex.
     * @param {RGBA} rgba Red, green, blue and alpha values.
     * @param {boolean} withAlpha Whether we add alpha value or not in conversion.
     * @return {string} Hex color string.
     */
    RGBAToHex(rgba: RGBA, withAlpha?: boolean): string {
        let R = rgba.r.toString(16);
        let G = rgba.g.toString(16);
        let B = rgba.b.toString(16);
        let A = '';

        if (rgba.r < 16) {
            R = '0' + R;
        }

        if (rgba.g < 16) {
            G = '0' + G;
        }

        if (rgba.b < 16) {
            B = '0' + B;
        }

        if (withAlpha && rgba.a < 1) {
            const alpha = Math.round((rgba.a * 255)) || 0;
            A = alpha.toString(16);

            if (alpha < 16) {
                A = '0' + A;
            }
        }

        return '#' + R + G + B + A;
    }

    /**
     * Convert RGBA values to a CSS rgb/rgba string.
     * @param {RGBA} rgba Red, green, blue and alpha values.
     * @param {boolean} withAlpha Whether we add alpha value or not in conversion.
     * @return {string} CSS color string.
     */
    RGBAToStr(rgba: RGBA, withAlpha?: boolean): string {
        if (!withAlpha || rgba.a === 1) {
            return `rgb(${rgba.r},${rgba.g},${rgba.b})`;
        } else {
            return `rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`;
        }
    }

    /**
     * Retrieves luminance based on the formula provided here:
     * https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
     * @param rgba color from which we compute luminance
     * @returns 
     */
    getLuminance(rgba: RGBA): number {
        const sRGB = (c: number) => (c / 255) <= 0.03928 ? c / 255 : ((c / 255 + 0.055) / 1.055) ** 2.4;
        return 0.2126 * sRGB(rgba.r) + 0.7152 * sRGB(rgba.g) + 0.0722 * sRGB(rgba.b);
    }

    //  Get the color produced by two overlayed colors
    getBlendedColor(foregroundColor?: string | null, backgroundColor?: string | null): string {
        const foregroundColorRGBA = this.strToRGBA(foregroundColor || '#ffffff00'); // if not defined, we consider it transparent
        const backgroundColorRGBA = this.strToRGBA(backgroundColor || '#ffffff00'); // if not defined, we consider it transparent

        const foregroundAlpha = foregroundColorRGBA.a; // Extract alpha value
        const resultColorRGBA: RGBA = {
            r: Math.round(foregroundColorRGBA.r * foregroundAlpha + backgroundColorRGBA.r * (1 - foregroundAlpha)),
            g: Math.round(foregroundColorRGBA.g * foregroundAlpha + backgroundColorRGBA.g * (1 - foregroundAlpha)),
            b: Math.round(foregroundColorRGBA.b * foregroundAlpha + backgroundColorRGBA.b * (1 - foregroundAlpha)),
            a: 1 // The alpha value of the resulting color is set to 1 (fully opaque)
        };

        return this.RGBAToHex(resultColorRGBA, true);
    }

    getFontContrastColor(color?: string): string {
        if (!color) {
            return '#333';
        }

        const colorRGBA = this.strToRGBA(color);
        const whiteRGBA = this.strToRGBA('#fff');
        const blackRGBA = this.strToRGBA('#333');

        const whiteContrastRatio = (this.getLuminance(whiteRGBA) + 0.05) / (this.getLuminance(colorRGBA) + 0.05);
        const blackContrastRatio = (this.getLuminance(colorRGBA) + 0.05) / (this.getLuminance(blackRGBA) + 0.05);

        return whiteContrastRatio < blackContrastRatio ? '#333' : '#fff';
    }
}
