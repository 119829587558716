import type { UnitRootTest } from './unit-root-test';
import type { _UnitRootTest } from './unit-root-test';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestZA
 * Via: UIModel annotation in the class hierarchy
 */
export interface _UnitRootTestZA extends _UnitRootTest {
    type: "unit_root_za";
    nLags?: number | null;
    regressionMode: UnitRootTestZA.RegressionMode;
}

export type UnitRootTestZA = _UnitRootTestZA;

export namespace UnitRootTestZA {
    /** Type of class com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestZA in PolyJSON mappings */
    export const type = "unit_root_za";
    
    /** 
     * Translated from enum com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestZA$RegressionMode
     * Via: com.dataiku.dip.eda.worksheets.cards.UnitRootTestZACard
     */
    export enum RegressionMode {
        CONSTANT_ONLY = "CONSTANT_ONLY",
        TREND_ONLY = "TREND_ONLY",
        CONSTANT_WITH_TREND = "CONSTANT_WITH_TREND",
    }
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestZA$UnitRootTestZAResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _UnitRootTestZAResult extends UnitRootTest._UnitRootTestResult {
        type: "unit_root_za";
    }
    
    export type UnitRootTestZAResult = _UnitRootTestZAResult;

    export namespace UnitRootTestZAResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestZA$UnitRootTestZAResult in PolyJSON mappings */
        export const type = "unit_root_za";
    }
}