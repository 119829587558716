<ng-select
    [items]="allItems"
    [bindLabel]="'label'"
    [bindValue]="'value'"
    [searchable]="false"
    [placeholder]="placeholder || 'Nothing selected'"
    [virtualScroll]="true"
    class="
        ng-select--without-ng-option-padding
        ng-select--without-ng-option-disabled-opacity
        ng-select--no-wrap-selected-items
        ng-select--without-highlighting-of-selected-items-if-mutiple
    "
    [clearable]="false"
    [searchFn]="searchFn"
    [trackByFn]="trackByFn"
    [groupBy]="groupedUiItems ? 'items' : ''"
    [compareWith]="compareWith"
    [multiple]="multiple"
    [hideSelected]="!multiple && ghostItems.length > 0"
    [closeOnSelect]="!multiple"
    [style.--ng-select-title-prefix-text]="(titlePrefix ? titlePrefix + ':&nbsp;' : '') | escapeCssString"
    [bufferAmount]="30"
    appendTo="body"
    [ngModel]="selectedValue"
    (ngModelChange)="modelChanged($event)">
    <ng-template ng-header-tmp>
        <ng-select-search-input [placeholder]="searchPlaceholderText" *ngIf="searchable"></ng-select-search-input>
        <ng-select-actions-box *ngIf="actionsBox"></ng-select-actions-box>
    </ng-template>
    <ng-template ng-optgroup-tmp let-item="item">
        <ng-container
            [ngTemplateOutlet]="sectionHeader"
            [ngTemplateOutletContext]="{ label: item.label, isGhost: item.groupType === 'ghost' }"
        ></ng-container>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <ng-container *ngIf="item.itemType === 'regular'">
            <div class="checkmark" *ngIf="multiple">
                <ng-select-pseudo-checkbox></ng-select-pseudo-checkbox>
            </div>
            <div class="item"
                [class.item--grouped]="!!groupedUiItems"
                [class.item--with-checkmark]="multiple"
            >
                <ng-container
                    *ngIf="optionTemplateRef; else noTemplate"
                    [ngTemplateOutlet]="optionTemplateRef"
                    [ngTemplateOutletContext]="{ $implicit: item.originalItem }"
                ></ng-container>
                <ng-template #noTemplate>
                    <span class="item-label">{{ item.label }}</span>
                    <span class="item-annotation">{{ item.annotation }}</span>
                </ng-template>
            </div>
        </ng-container>
        <div class="separator" *ngIf="item.itemType === 'separator'"><hr></div>
        <ng-container *ngIf="item.itemType === 'ghost'">
            <div class="checkmark item-ghost" *ngIf="multiple">
                <ng-select-pseudo-checkbox></ng-select-pseudo-checkbox>
            </div>
            <div class="item"
                [class.item--with-checkmark]="multiple"
            >
                <span class="item-label item-ghost">
                    <i class="icon-warning-sign icon-warning-color mleft4 mright4"
                    [matTooltip]="ghostItemsTooltipMessage"
                    matTooltipPosition="left"
                    ></i>
                    {{ item.label }}
                </span>
                <span class="item-annotation">{{ item.annotation }}</span>
            </div>
        </ng-container>
        <div class="ng-option-disabled ng-optgroup" *ngIf="item.itemType === 'ghostHeader'">
            <ng-container
                [ngTemplateOutlet]="sectionHeader"
                [ngTemplateOutletContext]="{ label: item.label, isGhost: true }"
            ></ng-container>
        </div>
    </ng-template>
    <ng-template ng-notfound-tmp>
        <div class="item">{{ noEntryFoundText || 'No items found' }}</div>
    </ng-template>
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <!-- Template for the menu trigger in multiple mode -->
        <span class="title-prefix" *ngIf="titlePrefix"><!-- Content set by CSS --></span>
        <i *ngIf="ghostItems.length > 0" class="icon-warning-sign icon-warning-color mleft4 mright4"></i>
        <ng-container *ngIf="!summarizedSelectionItemsType">
            <ng-container *ngFor="let item of items; let first = first">
                {{ (!first ? ',' : '')}}
                <span [class.item-ghost]="item.itemType === 'ghost'">{{ (!first ? '&nbsp;' : '') + item.label }}</span>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="summarizedSelectionItemsType">
            <span [class.item-ghost]="ghostItems.length > 0">
                <ng-container *ngIf="items.length > 1">
                    {{ items.length }} {{ summarizedSelectionItemsType }} selected
                </ng-container>
                <ng-container *ngIf="items.length === 1">
                    {{ items[0].label }}
                </ng-container>
            </span>
        </ng-container>
    </ng-template>
    <ng-template ng-label-tmp let-item="item">
        <span class="title-prefix" *ngIf="titlePrefix"><!-- Content set by CSS --></span>
        <i *ngIf="ghostItems.length > 0" 
            class="icon-warning-sign icon-warning-color mleft4 mright4"
            [matTooltip]="ghostItemsTooltipMessage"
            matTooltipPosition="left"
        ></i>
        <span [class.item-ghost]="item.itemType === 'ghost'">{{ item.label }}</span>
    </ng-template>
</ng-select>
<!--
    [bufferAmount] was set to a value higher than default (=4) in order to work around a known bug of <ng-select>'s virtual scroll
    See https://github.com/ng-select/ng-select/issues/1994
-->

<ng-template #sectionHeader let-label="label" let-isGhost="isGhost" >
    <div class="ghost-bin" *ngIf="isGhost">
        <button class="btn btn--text btn--danger btn--icon">
            <i 
                class="dku-icon-trash-16" 
                (click)="clearGhosts()" 
                matTooltip="Clear invalid selections"
                matTooltipPosition="right"
                tabindex="-1">
            </i>
        </button>
    </div>
    <div class="group"
        [class.group--with-ghost-bin]="isGhost"
    >
        {{ label }}
    </div>
</ng-template>