import type { PredictionModelingParams } from './prediction/prediction-modeling-params';

/** 
 * Translated from class com.dataiku.dip.analysis.model.ClusteringModelingParams
 * Via: com.dataiku.dip.analysis.model.clustering.ClusteringMLTask
 */
export interface _ClusteringModelingParams {
    custom_mllib: PredictionModelingParams.MLLibCustomGridParams[];
    custom_python: ClusteringModelingParams.ClusteringCustomPythonParams[];
    db_scan_clustering: ClusteringModelingParams.DBScanParams;
    gmm_clustering: ClusteringModelingParams.GMMParams;
    h2o_kmeans: ClusteringModelingParams.H2OKMeansModelingParams;
    isolation_forest: ClusteringModelingParams.IsolationForestParams;
    kmeans_clustering: ClusteringModelingParams.KMeansParams;
    metrics: ClusteringModelingParams.MetricParams;
    mini_batch_kmeans_clustering: ClusteringModelingParams.MiniBatchKMeansParams;
    mllib_gaussian_mixture_clustering: ClusteringModelingParams.MLLibGaussianMixtureParams;
    mllib_kmeans_clustering: ClusteringModelingParams.MLLibKMeansParams;
    spectral_clustering: ClusteringModelingParams.SpectralParams;
    two_step: ClusteringModelingParams.TwoStepParams;
    vertica_kmeans_clustering: ClusteringModelingParams.VerticaKMeansParams;
    ward_clustering: ClusteringModelingParams.WardParams;
}

export type ClusteringModelingParams = _ClusteringModelingParams;

export namespace ClusteringModelingParams {
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ClusteringModelingParams$ClusteringCustomPythonParams
     * Via: com.dataiku.dip.analysis.model.ClusteringModelingParams
     */
    export interface _ClusteringCustomPythonParams {
        code: string;
        custom_has_k: boolean;
        enabled: boolean;
        k: number[];
        name: string;
    }
    
    export type ClusteringCustomPythonParams = _ClusteringCustomPythonParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ClusteringModelingParams$DBScanParams
     * Via: com.dataiku.dip.analysis.model.ClusteringModelingParams
     */
    export interface _DBScanParams {
        enabled: boolean;
        epsilon: number[];
        min_sample_ratio: number;
    }
    
    export type DBScanParams = _DBScanParams;
    
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.ClusteringModelingParams$EvaluationMetric
     * Via: com.dataiku.dip.analysis.model.ClusteringModelingParams$MetricParams
     */
    export enum EvaluationMetric {
        SILHOUETTE = "SILHOUETTE",
        INERTIA = "INERTIA",
        NB_CLUSTERS = "NB_CLUSTERS",
        CUSTOM = "CUSTOM",
    }
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ClusteringModelingParams$GMMParams
     * Via: com.dataiku.dip.analysis.model.ClusteringModelingParams
     */
    export interface _GMMParams {
        enabled: boolean;
        k: number[];
        max_iterations: number;
        seed: number;
    }
    
    export type GMMParams = _GMMParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ClusteringModelingParams$H2OKMeansModelingParams
     * Via: com.dataiku.dip.analysis.model.ClusteringModelingParams
     */
    export interface _H2OKMeansModelingParams {
        enabled: boolean;
        init: string;
        k: number[];
        max_iterations: number;
        seed: number;
    }
    
    export type H2OKMeansModelingParams = _H2OKMeansModelingParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ClusteringModelingParams$IsolationForestParams
     * Via: com.dataiku.dip.analysis.model.ClusteringModelingParams
     */
    export interface _IsolationForestParams {
        bootstrap: boolean;
        contamination: number;
        enabled: boolean;
        k: number;
        max_anomalies: number;
        max_depth: number;
        max_features: number;
        max_samples: number;
        n_estimators: number;
        n_samples: number;
        seed: number;
    }
    
    export type IsolationForestParams = _IsolationForestParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ClusteringModelingParams$KMeansParams
     * Via: com.dataiku.dip.analysis.model.ClusteringModelingParams
     */
    export interface _KMeansParams {
        enabled: boolean;
        k: number[];
        n_jobs: number;
        seed: number;
    }
    
    export type KMeansParams = _KMeansParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ClusteringModelingParams$MLLibGaussianMixtureParams
     * Via: com.dataiku.dip.analysis.model.ClusteringModelingParams
     */
    export interface _MLLibGaussianMixtureParams {
        enabled: boolean;
        k: number[];
        max_iterations: number;
        seed: number;
    }
    
    export type MLLibGaussianMixtureParams = _MLLibGaussianMixtureParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ClusteringModelingParams$MLLibKMeansParams
     * Via: com.dataiku.dip.analysis.model.ClusteringModelingParams
     */
    export interface _MLLibKMeansParams {
        enabled: boolean;
        k: number[];
        max_iterations: number;
        seed: number;
    }
    
    export type MLLibKMeansParams = _MLLibKMeansParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ClusteringModelingParams$MetricParams
     * Via: com.dataiku.dip.analysis.model.ClusteringModelingParams
     */
    export interface _MetricParams {
        customEvaluationMetricCode: string;
        customEvaluationMetricGIB: boolean;
        evaluationMetric: ClusteringModelingParams.EvaluationMetric;
    }
    
    export type MetricParams = _MetricParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ClusteringModelingParams$MiniBatchKMeansParams
     * Via: com.dataiku.dip.analysis.model.ClusteringModelingParams
     */
    export interface _MiniBatchKMeansParams {
        enabled: boolean;
        k: number[];
        seed: number;
    }
    
    export type MiniBatchKMeansParams = _MiniBatchKMeansParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ClusteringModelingParams$SpectralParams
     * Via: com.dataiku.dip.analysis.model.ClusteringModelingParams
     */
    export interface _SpectralParams {
        affinity_nearest_neighbors: boolean;
        affinity_polynomial: boolean;
        affinity_rbf: boolean;
        affinity_sigmoid: boolean;
        coef0: number;
        enabled: boolean;
        gamma: number[];
        k: number[];
        seed: number;
    }
    
    export type SpectralParams = _SpectralParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ClusteringModelingParams$TwoStepParams
     * Via: com.dataiku.dip.analysis.model.ClusteringModelingParams
     */
    export interface _TwoStepParams {
        enabled: boolean;
        kmeans_k: number;
        max_iterations: number;
        n_clusters: number;
        seed: number;
    }
    
    export type TwoStepParams = _TwoStepParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ClusteringModelingParams$VerticaKMeansParams
     * Via: com.dataiku.dip.analysis.model.ClusteringModelingParams
     */
    export interface _VerticaKMeansParams {
        description: string;
        enabled: boolean;
        epsilon: number[];
        k: number[];
        max_iterations: number;
    }
    
    export type VerticaKMeansParams = _VerticaKMeansParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ClusteringModelingParams$WardParams
     * Via: com.dataiku.dip.analysis.model.ClusteringModelingParams
     */
    export interface _WardParams {
        enabled: boolean;
        k: number[];
    }
    
    export type WardParams = _WardParams;
}