import { UntypedFormGroup } from '@angular/forms';

// Extension of FormGroup that allows having extra properties.
// This overrides private methods of Angular code and thus may break unexpectedly in a future release.
export class TolerantFormGroup extends UntypedFormGroup {
    originalValue: { [key: string]: any } = [];

    setValue(value: { [key: string]: any }, options: { onlySelf?: boolean, emitEvent?: boolean } = {}): void {
        Object.keys(value).forEach(name => {
            if (this.controls[name]) {
                this.controls[name].setValue(value[name], { onlySelf: true, emitEvent: options.emitEvent });
            }
        });

        this.originalValue = value;

        this.updateValueAndValidity(options);
    }
    
    _updateValue(): void {
        const reducedValue = (this as any)._reduceValue();
        (this as { value: any }).value = { ...this.originalValue, ...reducedValue };
    }
}
