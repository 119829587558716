import type { ChecksSet } from './../metrics/checks-set';
import type { PartitioningScheme } from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/partitioning/partitioning-scheme';
import type { PredictionMLTask } from './../analysis/model/prediction/prediction-mltask';
import type { ProbesSet } from './../metrics/probes-set';
import type { SerializedDataset } from './../coremodel/serialized-dataset';
import type { TaggableObjectsService } from './../server/services/taggable-objects-service';
import type { _ChecksSet } from './../metrics/checks-set';
import type { _PartitioningScheme } from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/partitioning/partitioning-scheme';
import type { _ProbesSet } from './../metrics/probes-set';

/** 
 * Translated from class com.dataiku.dip.mec.ModelEvaluationStore
 * Via: com.dataiku.dip.server.UsabilityComputer$UsableComputable
 */
export interface _ModelEvaluationStore extends TaggableObjectsService._TaggableObject {
    displayParams: ModelEvaluationStore.DisplayParams;
    flowOptions: SerializedDataset.FlowOptions;
    governSyncOptOut: boolean;
    governTimestampLabel: string;
    id: string;
    metrics: ProbesSet;
    metricsChecks: ChecksSet;
    name: string;
    partitioning: PartitioningScheme;
    projectKey: string;
    rebuildBehavior: SerializedDataset.RebuildBehavior;
}

export type ModelEvaluationStore = _ModelEvaluationStore;

export namespace ModelEvaluationStore {
    /** 
     * Translated from class com.dataiku.dip.mec.ModelEvaluationStore$DisplayParams
     * Via: com.dataiku.dip.mec.ModelEvaluationStore
     */
    export interface _DisplayParams {
        alsoDisplayedLabels: string[];
        barLabels: string[];
        disabledMERunIds: string[];
        displayedMetrics: string[];
        graphStyle: ModelEvaluationStore.GraphStyle;
        pinnedMetrics: string[];
        predictionType: PredictionMLTask.PredictionType;
        showConfidenceIntervals: boolean;
        sortColumn: string;
        sortDescending: boolean;
        xLabel: string;
        yLabels: string[];
    }
    
    export type DisplayParams = _DisplayParams;
    
    /** 
     * Translated from enum com.dataiku.dip.mec.ModelEvaluationStore$GraphStyle
     * Via: com.dataiku.dip.mec.ModelEvaluationStore$DisplayParams
     */
    export enum GraphStyle {
        BAR = "BAR",
        LINE = "LINE",
    }
}