import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { WT1Service } from 'dku-frontend-core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ColorsService } from '@shared/graphics/colors.service';
import { fairAny } from 'dku-frontend-core';
import { ECharts, EChartsOption } from 'echarts';
import { encodeHTML } from 'entities';
import _ from 'lodash';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { skip } from 'rxjs/operators';
import { MetricInfo, RunViewModel } from '../experiment-tracking.service';
import { RunMetric } from 'src/generated-sources';
import { SmartNumberPipe } from '@shared/pipes/number-pipes/smart-number.pipe';
import { formatTime } from '../utils';

enum DisplayMode {
    LAST_VALUES,
    STEPS
}

@UntilDestroy()
@Component({
    selector: 'chart-runs-metrics',
    templateUrl: './chart-runs-metrics.component.html',
    styleUrls: ['./chart-runs-metrics.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartRunsMetricsComponent implements OnChanges, OnInit, OnDestroy {
    readonly RUN_ID_PARAM = "Run Id";

    @Input() runs?: RunViewModel[];

    @Output() clickOnRun = new EventEmitter<string>();
    @Output() hoverOnRun = new EventEmitter<string>();
    @Output() requestRefresh = new EventEmitter<void>();

    availableMetricKeys: string[] | null;
    availableParameterKeys: string[] | null;
    chartsOptions: (EChartsOption & { metric: string, metricInfo: MetricInfo })[] | undefined;
    refresh$: Subject<void> = new ReplaySubject();

    form = this.fb.group({
        selectedParameter: new UntypedFormControl(undefined, []),
        selectedMetrics: new UntypedFormControl(undefined, []),
        autoRefresh: new UntypedFormControl(false, [])
    });

    pinnedCharts = new Set<string>();

    DisplayModeEnum: typeof DisplayMode = DisplayMode;

    displayMode: DisplayMode = DisplayMode.LAST_VALUES;
    togglableDisplayMode = false;
    displayStepsAsTSDiff = true;
    intervalId: number | undefined;

    constructor(
        private fb: UntypedFormBuilder,
        private colorsService: ColorsService,
        private wt1Service: WT1Service,
        private smartNumberPipe: SmartNumberPipe,
    ) { }

    ngOnInit(): void {
        this.form.patchValue({ selectedParameter: this.RUN_ID_PARAM });

        this.computeAvailableMetricKeys();
        this.computeAvailableParameterKeys();

        const selectedMetricsAndParemeter$ = combineLatest([
            this.form.get('selectedMetrics')!.valueChanges,
            this.form.get('selectedParameter')!.valueChanges,
            this.refresh$])
            .pipe(
                untilDestroyed(this)
            );

        selectedMetricsAndParemeter$.subscribe(
            ([selectedMetricsParam, selectedParameterParam]) => {
                const selectedMetrics: string[] = selectedMetricsParam;
                const selectedParameter: string = selectedParameterParam;
                const maxStep = _.flatMap(this.runs!, r => r.data.metrics).map(m => m.step).reduce((max, curr) => Math.max(max, curr), -Infinity);
                let maxDuration = 0;
                selectedMetrics.forEach(sm => {
                    this.runs!.forEach(r => {
                        const runMetricData: RunMetric[] = r.data.metrics.filter(m => m.key == sm);
                        if (runMetricData.length < 2) {
                            return;
                        }
                        const duration = runMetricData[runMetricData.length - 1].timestamp - runMetricData[0].timestamp;
                        maxDuration = Math.max(maxDuration, duration);
                    });
                });
                if (!maxStep && !maxDuration) {
                    this.togglableDisplayMode = false;
                    this.displayMode = DisplayMode.LAST_VALUES;
                } else {
                    this.togglableDisplayMode = true;
                }
                if (!selectedMetrics || !selectedMetrics.length) {
                    this.chartsOptions = undefined;
                    return;
                }
                let newOptions: fairAny;
                if (this.displayMode == DisplayMode.LAST_VALUES) {
                    newOptions = this.buildLastValuesChartsOptions(selectedMetrics, selectedParameter);
                } else {
                    newOptions = this.buildStepsChartsOptions(selectedMetrics, maxStep, maxDuration);
                }
                if (this.chartsOptions && this.chartsOptions.length == newOptions.length) {
                    for (let i = 0; i < this.chartsOptions.length; i++) {
                        let chartOption: fairAny = this.chartsOptions[i];
                        let newOption: fairAny = newOptions[i];
                        this.updateChartOption(chartOption, newOption);
                    }
                } else {
                    this.chartsOptions = newOptions;
                }

            }
        );

        this.publishWT1ToggleMetricsEventExceptOnInit(selectedMetricsAndParemeter$);

        if (this.availableMetricKeys && this.availableMetricKeys.length) {
            // select all metrics, displaying charts and caping at 10 initially
            this.form.patchValue({
                selectedMetrics: this.availableMetricKeys.slice(0, 10)
            });
        }

        this.form.get('autoRefresh')!.valueChanges.subscribe(status => {
            if (status) {
                if (!this.intervalId) {
                    this.intervalId = window.setInterval(() => {
                        this.requestRefresh.emit();
                    }, 10000);
                    this.refresh$.next();
                }
            } else {
                if (this.intervalId) {
                    window.clearInterval(this.intervalId);
                    this.intervalId = undefined;
                }
            }
        });
    }

    /**
     * Allows to completely refresh the {chartOption} object by removing the properties not present in {newOption}
     * and updating/adding the ones that exist in both. This is necessary to avoid flickering when updating a chart.
     */
    private updateChartOption(chartOption: fairAny, newOption: fairAny) {
        let echartsObject: ECharts = chartOption.echartsObject;

        Object.keys(chartOption).forEach(key => {
            if (!newOption.hasOwnProperty(key) && key !== 'echartsObject') {
                delete chartOption[key];
            }
        });
        Object.keys(newOption).forEach(key => chartOption[key] = newOption[key]);

        echartsObject.setOption(newOption, true);
    }

    private buildStepsChartsOptions(selectedMetrics: string[], maxStep: number, maxDuration: number): any {
        return selectedMetrics.map(
            (sm: string) => {
                const mergedMetricInfo = new MetricInfo(sm);
                let series: any = this.runs!.map(r => {
                    const runMetricData: RunMetric[] = r.data.metrics
                        .filter(m => m.key == sm)
                        .map(rm => {
                            return { ...rm, value: rm.invalidValue ? undefined : rm.value };
                        });
                    let data: (any | null | undefined)[];
                    if (!this.displayStepsAsTSDiff) {
                        const maxMetricStep = Math.max(...runMetricData.map(rm => rm.step));
                        if (runMetricData.length == 0) {
                            data = [];
                        } else if (runMetricData.length == 1) {
                            if (r.keptEpoch !== undefined) {
                                data = [];
                                if (r.keptEpoch != 0) {
                                    data.push({ value: [0, NaN, NaN] });
                                }
                                data.push({ value: [r.keptEpoch, runMetricData[0].value, NaN], symbolSize: 3 })
                                if (r.keptEpoch != maxStep) {
                                    data.push({ value: [maxStep, NaN, NaN] });
                                }
                            } else {
                                data = [{ value: [0, runMetricData[0].value, NaN] }, { value: [maxStep, runMetricData[0].value, NaN] }];
                            }
                        } else {
                            data = runMetricData.map(rm => {
                                if (r.keptEpoch === rm.step) {
                                    return {
                                        symbol: "pin",
                                        symbolSize: 10,
                                        value: [rm.step, rm.value]
                                    };
                                }
                                return {
                                    value: [rm.step, rm.value]
                                };
                            });
                            if (maxMetricStep < maxStep) {
                                data.push({ value: [maxStep, data[data.length - 1][1]] });
                            }
                        }
                    } else {
                        const maxMetricTS = Math.max(...runMetricData.map(rm => rm.timestamp));
                        if (runMetricData.length == 0) {
                            data = [];
                        } else if (runMetricData.length == 1) {
                            if (r.keptEpochTimestamp !== undefined) {
                                data = [];
                                if (r.keptEpochTimestamp != 0) {
                                    data.push({ value: [0, NaN, NaN] })
                                }
                                data.push({ value: [r.keptEpochTimestamp, runMetricData[0].value, NaN], symbolSize: 3 });
                                if (r.keptEpochTimestamp != maxDuration) {
                                    data.push({ value: [maxDuration, NaN, NaN] })
                                }
                            }
                            else {
                                data = [{ value: [0, runMetricData[0].value, NaN] }, { value: [maxDuration, runMetricData[0].value, NaN] }];
                            }

                        } else {
                            const firstMetricTS = runMetricData[0].timestamp;
                            data = runMetricData.map(rm => {
                                if (r.keptEpoch === rm.step) {
                                    return {
                                        symbol: "pin",
                                        symbolSize: 10,
                                        value: [rm.timestamp - firstMetricTS, rm.value]
                                    };
                                }
                                return {
                                    value: [rm.timestamp - firstMetricTS, rm.value]
                                };
                            });
                            if ((maxMetricTS - firstMetricTS) < maxDuration) {
                                data.push({ value: [maxDuration, data[data.length - 1][1]] });
                            }
                        }
                    }
                    mergedMetricInfo.pushMetricInfo(r.metrics.get(sm));
                    const color = this.colorsService.getColorForVariable(r.info.runId);
                    return {
                        animation: false,
                        type: 'line',
                        showSymbol: true,
                        symbolSize: 1,
                        symbol: 'circle',
                        smooth: false,
                        name: r.info.runId,
                        lineStyle: {
                            type: 'solid',
                            width: 1,
                            color
                        },
                        emphasis: {
                            itemStyle: {
                                borderWidth: 4,
                                borderColor: color
                            },
                            lineStyle: {
                                width: 1
                            }
                        },
                        data,
                    }
                });
                const color = this.runs!.map(r => this.colorsService.getColorForVariable(r.info.runId));
                return {
                    color,
                    xAxis: {
                        type: 'value',
                        axisLine: { show: true },
                        axisTick: { show: true },
                        splitNumber: this.isChartPinned(sm) ? 6 : 3,
                        axisLabel: {
                            hideOverlap: true,
                            formatter: (value: number): string => {
                                return this.displayStepsAsTSDiff ? formatTime(value, maxDuration) : this.smartNumberPipe.transform(value);
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisTick: { show: true },
                        axisLine: { show: true },
                        axisLabel: {
                            formatter: (value: number): string => {
                                return this.smartNumberPipe.transform(value);
                            }
                        }
                    },
                    series,
                    grid: {
                        top: 10,
                        bottom: 20,
                        left: 45,
                        right: 20
                    },
                    tooltip: {
                        trigger: 'item',
                        showContent: true,
                        formatter: (params: fairAny) => {
                            if (!this.displayStepsAsTSDiff) {
                                let ret = "<table><tbody>";
                                ret += `<tr><td></td><td><b>Step</b></td><td>${params.data.value[0]}</td></tr>`;
                                const currentHoveredRun = this.runs![params.seriesIndex];
                                this.hoverOnRun.emit(currentHoveredRun.info.runId)
                                let displayedRuns = 0;
                                const MAX_RUNS_DISPLAYED = 6;
                                this.runs!.forEach((currentRun, currentIndex) => {
                                    let value = NaN;
                                    const currentSeries = series[currentIndex];
                                    if ((series[currentIndex].data.length == 0) || displayedRuns == MAX_RUNS_DISPLAYED) {
                                        return;
                                    }
                                    displayedRuns++;
                                    const nonNaNValues = currentSeries.data.filter((x : any) => !Number.isNaN(x.value[1]));
                                    const valuesAtStep = nonNaNValues.filter((x: any) => x.value[0] == params.dataIndex);
                                    if (valuesAtStep.length > 0) {
                                        const stepValue = valuesAtStep[0];
                                        value = stepValue.value[1];
                                    } else if (nonNaNValues.length > 0) {
                                        value = nonNaNValues[nonNaNValues.length - 1].value[1];
                                    } 
                                    ret += `<tr><td>${params.marker.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/i, this.colorsService.getColorForVariable(series[currentIndex].name))}</td><td><b>${this.getTooltipDisplayName(currentRun)}:</b></td><td>${this.smartNumberPipe.transform(value)}</td></tr>`;
                                });
                                if (displayedRuns == MAX_RUNS_DISPLAYED) {
                                    ret += `<tr><td colspan="3">...</td></tr>`;
                                }
                                ret += "</tbody></table>";
                                return ret;
                            } else {
                                return `${params.marker} ${formatTime(params.value[0], maxDuration)}: ${this.smartNumberPipe.transform(params.value[1])}`;
                            }
                        },
                        confine: true,
                    },
                    axisPointer: {
                        show: true,
                        snap: true,
                        lineStyle: {
                            type: "dashed"
                        },
                        triggerTooltip: false,
                        label: {
                            formatter: (params: fairAny) => {
                                let value = params.value;
                                if (params.axisDimension === 'x' && this.displayStepsAsTSDiff) {
                                    value = formatTime(value, maxDuration);
                                } else {
                                    value = this.smartNumberPipe.transform(value);
                                }
                                return value;
                            }
                        }
                    },
                    metric: sm,
                    metricInfo: mergedMetricInfo
                }
            });
    }

    private buildLastValuesChartsOptions(selectedMetrics: string[], selectedParameter: string): any {
        return selectedMetrics.map(
            (m: string) => {
                let isInteger = false;
                let isFloatingPoint = false;
                if (selectedParameter !== this.RUN_ID_PARAM) {
                    // check for parameter settings uniqueness
                    const combinations = new Set(this.runs!.map(r => {
                        if (r.params.has(selectedParameter)) {
                            return r.params.get(selectedParameter);
                        } else {
                            return "-";
                        }
                    }));
                    const combinationsAsArray = <Array<any>>Array.from(combinations);
                    isInteger = combinationsAsArray.every(cur => (cur === "-") || Math.floor(cur) == cur);
                    isFloatingPoint = combinationsAsArray.every(cur => (cur === "-") || !isNaN(cur));
                }
                const mergedMetricInfo = new MetricInfo(m);
                let series: any = this.runs!.map(r => {
                    const metricInfo = r.metrics.get(m);
                    const lastInvalidValue = metricInfo?.lastInvalidValue;
                    const value = lastInvalidValue ? undefined : metricInfo?.lastValue;
                    let name;

                    if (selectedParameter !== this.RUN_ID_PARAM) {
                        name = r.params.get(selectedParameter) || "-";
                    } else {
                        name = r.info.runId;
                    }

                    if (isInteger) {
                        name = parseInt(name);
                    } else if (isFloatingPoint) {
                        name = parseFloat(name);
                    }

                    let data;
                    if (isInteger || isFloatingPoint) {
                        data = [[name, value]];
                    } else {
                        data = value ? [value] : [];
                    }
                    mergedMetricInfo.pushMetricInfo(metricInfo);
                    return {
                        type: (isInteger || isFloatingPoint) ? 'scatter' : 'bar',
                        name,
                        label: name,
                        data,
                        symbolSize: 8,
                        color: this.colorsService.getColorForVariable(r.info.runId)
                    };
                });
                return {
                    animation: false,
                    tooltip: {
                        trigger: 'item',
                        confine: true,
                        formatter: (params: fairAny) => {
                            const value = Array.isArray(params.value) ? params.value[1] : params.value;
                            let ret = `${params.marker}<b>${encodeHTML(m)}</b>: ${value.toFixed(4)}<br>`;
                            ret += "<table><tbody>";
                            const currentRun = this.runs![params.seriesIndex];
                            if (selectedParameter !== this.RUN_ID_PARAM) {
                                const paramValue = currentRun.params.has(selectedParameter) ? currentRun.params.get(selectedParameter) : '-';
                                ret += `<tr><td><b>${selectedParameter}:</b></td><td>${paramValue}</td></tr>`;
                            }
                            ret += `<tr><td><b>${currentRun.origin == "analysis" ? "Run name:" : "Run Id:"}</b></td><td>${this.getTooltipDisplayName(currentRun)}</td></tr>`;
                            ret += "</tbody></table>";
                            this.hoverOnRun.emit(currentRun.info.runId);
                            return ret;
                        }
                    },
                    series,
                    xAxis: {
                        type: (isInteger || isFloatingPoint) ? 'value' : 'category',
                        axisLine: { show: true },
                        axisTick: { show: true },
                        axisLabel: { show: (isInteger || isFloatingPoint) }
                    },
                    yAxis: {
                        type: 'value',
                        axisTick: { show: true },
                        axisLine: { show: true }
                    },
                    grid: {
                        top: 10,
                        bottom: 20,
                        left: 45,
                        right: 20
                    },
                    metric: m,
                    metricInfo: mergedMetricInfo
                };
            }
        );
    }


    getTooltipDisplayName(run: RunViewModel): string {
        if (run.origin == "analysis") {
            return run.runNameId;
        }
        return run.info.runId;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.runs) {
            const oldAvailableMetrics = this.availableMetricKeys;
            this.computeAvailableMetricKeys();

            if ((!oldAvailableMetrics || !oldAvailableMetrics.length) && this.availableMetricKeys && this.availableMetricKeys.length) {
                // select all metrics, displaying charts and caping at 10 initially
                this.form.patchValue({
                    selectedMetrics: this.availableMetricKeys.slice(0, 10)
                });
            }
            this.computeAvailableParameterKeys();
            this.refresh$.next();
        }
    }

    ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    computeAvailableMetricKeys(): void {
        if (!this.runs) {
            this.availableMetricKeys = [];
            return;
        }
        this.availableMetricKeys = _.uniq(_.flatMap(this.runs, r => r.data.metrics).map(m => m.key)).sort();
    }

    computeAvailableParameterKeys(): void {
        if (!this.runs) {
            this.availableParameterKeys = [];
            return;
        }
        this.availableParameterKeys = _.uniq(_.flatMap(this.runs, r => r.data.runParams).map(p => p.key)).sort();
        this.availableParameterKeys.push(this.RUN_ID_PARAM);
    }

    isChartPinned(metricName: string): boolean {
        return !!this.pinnedCharts.has(metricName);
    }

    updateChartSplitNumber(chartOption: fairAny, splitNumber: number) {
        chartOption.xAxis.splitNumber = splitNumber;
        chartOption.echartsObject.setOption(chartOption, true);
    }

    pinChart(chartOption: fairAny): void {
        if (this.displayMode == DisplayMode.STEPS) {
            this.updateChartSplitNumber(chartOption, 6);
        }

        this.pinnedCharts.add(chartOption.metric);
    }

    unpinChart(chartOption: fairAny): void {
        if (this.displayMode == DisplayMode.STEPS) {
            this.updateChartSplitNumber(chartOption, 3);
        }

        this.pinnedCharts.delete(chartOption.metric);
    }

    onChartInit(echartsObject: ECharts, chartOptions: EChartsOption) {
        chartOptions.echartsObject = echartsObject;
        if (echartsObject) {
            echartsObject.on('click', (event: fairAny) => {
                this.clickOnRun.emit(this.runs![event.seriesIndex].info.runId);
            });
        }
    }

    setDisplayMode(newDisplayMode: DisplayMode) {
        this.displayMode = newDisplayMode;
        this.refresh$.next();
    }

    toggleStepsDisplayMode(value: boolean) {
        this.displayStepsAsTSDiff = value;
        this.refresh$.next();
    }

    publishWT1ToggleMetricsEventExceptOnInit(selectedMetricsAndParemeter$: Observable<[string[], string[], void]>) {
        // Don't publish a WT1 event on first emission, since this one is for init and is not a real user event.
        selectedMetricsAndParemeter$.pipe(
            skip(1)
        ).subscribe(
            ([selectedMetricsParam, selectedParameterParam]) => {
                this.wt1Service.event('experiment-tracking-runs-charts-toggle-metrics-or-params',
                    {
                        metricsCount: selectedMetricsParam?.length || 0,
                        param: selectedParameterParam
                    });
            }
        );
    }

    clickAndHoverOnRun(runId: string) {
        this.hoverOnRun.emit(runId);
        this.clickOnRun.emit(runId);
    }

    refresh(): void {
        this.requestRefresh.emit();
    }
}
