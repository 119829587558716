import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.MutualInformation
 * Via: UIModel annotation in the class hierarchy
 */
export interface _MutualInformation extends Computation._BivariateComputation {
    type: "mutual_information";
}

export type MutualInformation = _MutualInformation;

export namespace MutualInformation {
    /** Type of class com.dataiku.dip.eda.compute.computations.bivariate.MutualInformation in PolyJSON mappings */
    export const type = "mutual_information";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.MutualInformation$MutualInformationResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _MutualInformationResult extends _AvailableResult {
        type: "mutual_information";
        value: number;
    }
    
    export type MutualInformationResult = _MutualInformationResult;

    export namespace MutualInformationResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.bivariate.MutualInformation$MutualInformationResult in PolyJSON mappings */
        export const type = "mutual_information";
    }
}