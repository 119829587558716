import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';
import { BehaviorSubject } from 'rxjs';

class TooltipOptions {
    message: string;
    position: TooltipPosition = 'above';
    class: string = 'mat-tooltip--above';
    iconOptions: TooltipIconOptions = {
        style: 'filled',
        status: 'warning',
        size: 'std'
    };
}

class TooltipIconOptions {
    size: 'std' | 'lg';
    status: 'warning' | 'std';
    style: 'outline' | 'filled';
}

@Component({
    selector: 'dss-warning-icon',
    templateUrl: './warning-icon.component.html',
    styleUrls: ['./warning-icon.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarningIconComponent {
    private tooltipOptions = new TooltipOptions();
    public tooltip$ = new BehaviorSubject<string | null>(null);
    public tooltipOptions$ = new BehaviorSubject<TooltipOptions>(this.tooltipOptions);

    @Input() set tooltip(value: string) {
        this.tooltipOptions.message = value;
        this.tooltipOptions$.next(this.tooltipOptions);
    }

    @Input() set tooltipPosition(value: TooltipPosition) {
        this.tooltipOptions.position = value;
        this.tooltipOptions.class = this.getTooltipClass(value);
        this.tooltipOptions$.next(this.tooltipOptions);
    }

    @Input() set tooltipIconOptions(value: TooltipIconOptions) {
        this.tooltipOptions.iconOptions = value;
        this.tooltipOptions$.next(this.tooltipOptions);
    }

    private getTooltipClass(position: string): string {
        switch (position) {
            case 'above':
                return 'mat-tooltip--above';
            default:
                return '';
        }
    }
}
