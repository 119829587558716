import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { ResamplerSettings } from './../../resampling/resampler-settings';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.GuessTimeStep
 * Via: UIModel annotation in the class hierarchy
 */
export interface _GuessTimeStep extends Computation._UnivariateComputation {
    type: "guess_time_step";
    seriesIdentifiers: string[];
}

export type GuessTimeStep = _GuessTimeStep;

export namespace GuessTimeStep {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.GuessTimeStep in PolyJSON mappings */
    export const type = "guess_time_step";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.GuessTimeStep$GuessTimeStepResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _GuessTimeStepResult extends _AvailableResult {
        type: "guess_time_step";
        nTimeUnits: number;
        timeUnit: ResamplerSettings.TimeUnit;
    }
    
    export type GuessTimeStepResult = _GuessTimeStepResult;

    export namespace GuessTimeStepResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.GuessTimeStep$GuessTimeStepResult in PolyJSON mappings */
        export const type = "guess_time_step";
    }
}