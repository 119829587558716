import type { CardResult } from './../card-result';
import type { CardWithConfidenceInterval } from './../common/card-with-confidence-interval';
import type { CardWithConfidenceLevel } from './../common/card-with-confidence-level';
import type { Quantiles } from './../../../compute/computations/univariate/quantiles';
import type { UnivariateCard } from './../univariate-card';
import type { _CardResult } from './../card-result';
import type { _CardWithConfidenceInterval } from './../common/card-with-confidence-interval';
import type { _CardWithConfidenceLevel } from './../common/card-with-confidence-level';
import type { _UnivariateCard } from './../univariate-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.univariate_header.QuantilesTableCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _QuantilesTableCard extends _UnivariateCard, _CardWithConfidenceInterval, _CardWithConfidenceLevel {
    type: "quantile_table";
    confidenceLevel: number;
    customFreqs?: number[] | null;
    showConfidenceInterval: boolean;
}

export type QuantilesTableCard = _QuantilesTableCard;

export namespace QuantilesTableCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.univariate_header.QuantilesTableCard in PolyJSON mappings */
    export const type = "quantile_table";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.univariate_header.QuantilesTableCard$QuantilesTableCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _QuantilesTableCardResult extends _CardResult {
        type: "quantile_table";
        quantiles: Quantiles.QuantileDesc[];
    }
    
    export type QuantilesTableCardResult = _QuantilesTableCardResult;

    export namespace QuantilesTableCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.univariate_header.QuantilesTableCard$QuantilesTableCardResult in PolyJSON mappings */
        export const type = "quantile_table";
    }
}