import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TagsWithCount } from '@shared/components/tags-selector/tags-selector.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { observeFormControl } from '@utils/form-control-observer';
import { combineLatest, map, Observable, ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { deepDistinctUntilChanged } from 'dku-frontend-core';
import { SortByOptions, SortOrder } from '@features/data-collections/shared/models/data-collections-filter.model';


interface TopBarInternalForm {
    search: string;
    selectedTags: string[];
    sortBy: SortByOptions;
}

export interface DataCollectionsFilters extends TopBarInternalForm {
    sortOrder: SortOrder;
}

@UntilDestroy()
@Component({
    selector: 'data-collections-top-bar',
    templateUrl: './data-collections-top-bar.component.html',
    styleUrls: ['./data-collections-top-bar.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCollectionsTopBarComponent implements OnChanges {
    @Input() canCreateDataCollections: boolean = false;
    @Input() availableTags!: TagsWithCount;
    @Output() newCollection = new EventEmitter<void>();
    @Input() filters!: DataCollectionsFilters;
    @Output() filtersChange = new EventEmitter<DataCollectionsFilters>();

    readonly SORT_BY_OPTIONS_OBJ = Object.values(SortByOptions).map(opt => ({opt}));
    readonly filtersForm = new UntypedFormGroup({
        search: new UntypedFormControl(),
        selectedTags: new UntypedFormControl(),
        sortBy: new UntypedFormControl(),
    });

    sortOrder$ = new ReplaySubject<SortOrder>(1);
    filters$ = combineLatest([
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        observeFormControl(this.filtersForm) as Observable<TopBarInternalForm>,
        this.sortOrder$
    ]).pipe(
        map(([filters, sortOrder]) => ({...filters, sortOrder})),
        deepDistinctUntilChanged()
    );

    constructor(
    ) {
        this.filters$.pipe(
            untilDestroyed(this)
        ).subscribe((val) => this.filtersChange.emit(val));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.filters) {
            const formValue: TopBarInternalForm = {
                search: this.filters.search,
                selectedTags: this.filters.selectedTags,
                sortBy: this.filters.sortBy,
            };
            this.filtersForm.setValue(formValue);
            this.setSortOrder(this.filters.sortOrder);
        }
    }

    setSortOrder(val: SortOrder) {
        this.sortOrder$.next(val);
    }

    toggleSortOrder(currentSortOrder: SortOrder) {
        this.setSortOrder(currentSortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC);
    }
}
