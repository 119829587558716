import type { BasePermissions } from './../security/base-permissions';
import type { SerializedProject } from './../coremodel/serialized-project';
import type { TaggableObjectsService } from './../server/services/taggable-objects-service';



export namespace UIDataCollection {
    /** 
     * Translated from class com.dataiku.dip.datacollections.UIDataCollection$AbstractDataCollectionItemInfo
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AbstractDataCollectionItemInfo {
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.datacollections.UIDataCollection$AbstractDataCollectionItemInfo */
    export type AbstractDataCollectionItemInfo =
        | UIDataCollection.DatasetInfo
        | UIDataCollection.DiscoverableDatasetInfo;
    
    /** 
     * Translated from class com.dataiku.dip.datacollections.UIDataCollection$AbstractDatasetInfo
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AbstractDatasetInfo extends UIDataCollection._AbstractDataCollectionItemInfo {
        datasetType: string;
        description: string;
        name: string;
        sourceProjectKey: string;
        sourceProjectName: string;
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.datacollections.UIDataCollection$AbstractDatasetInfo */
    export type AbstractDatasetInfo =
        | UIDataCollection.DatasetInfo
        | UIDataCollection.DiscoverableDatasetInfo;
    
    /** 
     * Translated from class com.dataiku.dip.datacollections.UIDataCollection$AddObject
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AddObject {
        ref: TaggableObjectsService.TaggableObjectRef;
        requestQuickSharing: boolean;
        requestedReaderAuthorizations: SerializedProject.ReaderAuthorization.Mode[];
    }
    
    export type AddObject = _AddObject;
    
    /** 
     * Translated from class com.dataiku.dip.datacollections.UIDataCollection$AddObjectsResponse
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AddObjectsResponse {
        allDataStewardsDefined: boolean;
        modified: boolean;
    }
    
    export type AddObjectsResponse = _AddObjectsResponse;
    
    /** 
     * Translated from class com.dataiku.dip.datacollections.UIDataCollection$CreationInfo
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _CreationInfo {
        color: string;
        description: string;
        displayName: string;
        permissions: BasePermissions.PermissionItem[];
    }
    
    export type CreationInfo = _CreationInfo;
    
    /** 
     * Translated from class com.dataiku.dip.datacollections.UIDataCollection$DataCollectionInfo
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _DataCollectionInfo {
        color: string;
        currentUserPermissions: BasePermissions.Permissions;
        description: string;
        displayName: string;
        id: string;
        items: UIDataCollection.AbstractDataCollectionItemInfo[];
        permissions: BasePermissions.PermissionItem[];
        tags: string[];
    }
    
    export type DataCollectionInfo = _DataCollectionInfo;
    
    /** 
     * Translated from class com.dataiku.dip.datacollections.UIDataCollection$DatasetInfo
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _DatasetInfo extends UIDataCollection._AbstractDatasetInfo {
        type: "dataset";
        columnCount: number;
        lastBuiltOn: number;
        usedInProjectCount: number;
    }
    
    export type DatasetInfo = _DatasetInfo;
    
    /** 
     * Translated from class com.dataiku.dip.datacollections.UIDataCollection$DiscoverableDatasetInfo
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _DiscoverableDatasetInfo extends UIDataCollection._AbstractDatasetInfo {
        type: "discoverable-dataset";
    }
    
    export type DiscoverableDatasetInfo = _DiscoverableDatasetInfo;
    
    /** 
     * Translated from class com.dataiku.dip.datacollections.UIDataCollection$ListItem
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ListItem {
        id: string;
        metadata: UIDataCollection.MetadataInfo;
    }
    
    export type ListItem = _ListItem;
    
    /** 
     * Translated from class com.dataiku.dip.datacollections.UIDataCollection$ListItemWithDetails
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ListItemWithDetails {
        color: string;
        description: string;
        displayName: string;
        id: string;
        itemCount: number;
        lastModifiedOn: number;
        tags: string[];
    }
    
    export type ListItemWithDetails = _ListItemWithDetails;
    
    /** 
     * Translated from class com.dataiku.dip.datacollections.UIDataCollection$MetadataInfo
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _MetadataInfo {
        color: string;
        description: string;
        displayName: string;
        tags: string[];
    }
    
    export type MetadataInfo = _MetadataInfo;
    
    /** 
     * Translated from class com.dataiku.dip.datacollections.UIDataCollection$ObjectAuthorizations
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ObjectAuthorizations {
        allAuthorized: boolean;
        authorizations: SerializedProject.ReaderAuthorization.Mode[];
        canManageExposedElements: boolean;
        isQuicklyShareable: boolean;
        ref: TaggableObjectsService.TaggableObjectRef;
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.datacollections.UIDataCollection$ObjectAuthorizations */
    export type ObjectAuthorizations =
        | UIDataCollection._ObjectAuthorizations
        | UIDataCollection.ObjectAuthorizationsWithDisplayName;
    
    /** 
     * Translated from class com.dataiku.dip.datacollections.UIDataCollection$ObjectAuthorizationsWithDisplayName
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ObjectAuthorizationsWithDisplayName extends UIDataCollection._ObjectAuthorizations {
        displayName: string;
        subtype: string;
    }
    
    export type ObjectAuthorizationsWithDisplayName = _ObjectAuthorizationsWithDisplayName;

    export namespace AbstractDataCollectionItemInfo {
        /** Types of class com.dataiku.dip.datacollections.UIDataCollection$AbstractDataCollectionItemInfo in PolyJSON mappings */
        export const type = ["dataset", "discoverable-dataset", ] as const;
        
        export function isAbstractDataCollectionItemInfo(obj: UIDataCollection.AbstractDataCollectionItemInfo): obj is UIDataCollection.AbstractDataCollectionItemInfo { return ["dataset", "discoverable-dataset", ].includes(obj["type"]); }
        export function isAbstractDatasetInfo(obj: UIDataCollection.AbstractDataCollectionItemInfo): obj is UIDataCollection.AbstractDatasetInfo { return ["dataset", "discoverable-dataset", ].includes(obj["type"]); }
        export function isDatasetInfo(obj: UIDataCollection.AbstractDataCollectionItemInfo): obj is UIDataCollection.DatasetInfo { return obj["type"] == "dataset"; }
        export function isDiscoverableDatasetInfo(obj: UIDataCollection.AbstractDataCollectionItemInfo): obj is UIDataCollection.DiscoverableDatasetInfo { return obj["type"] == "discoverable-dataset"; }
    }

    export namespace AbstractDatasetInfo {
        /** Types of class com.dataiku.dip.datacollections.UIDataCollection$AbstractDatasetInfo in PolyJSON mappings */
        export const type = ["dataset", "discoverable-dataset", ] as const;
    }

    export namespace DatasetInfo {
        /** Type of class com.dataiku.dip.datacollections.UIDataCollection$DatasetInfo in PolyJSON mappings */
        export const type = "dataset";
    }

    export namespace DiscoverableDatasetInfo {
        /** Type of class com.dataiku.dip.datacollections.UIDataCollection$DiscoverableDatasetInfo in PolyJSON mappings */
        export const type = "discoverable-dataset";
    }
}