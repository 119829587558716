import type { Abstract2DPivotCard } from './abstract2-dpivot-card';
import type { BivariateFrequencyTableFragment } from './../fragments/bivariate-frequency-table-fragment';
import type { CardResult } from './../card-result';
import type { _Abstract2DPivotCard } from './abstract2-dpivot-card';
import type { _BivariateFrequencyTableFragment } from './../fragments/bivariate-frequency-table-fragment';
import type { _CardResult } from './../card-result';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateFrequencyTableCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _BivariateFrequencyTableCard extends _Abstract2DPivotCard {
    type: "bivariate_frequency_table";
}

export type BivariateFrequencyTableCard = _BivariateFrequencyTableCard;

export namespace BivariateFrequencyTableCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateFrequencyTableCard in PolyJSON mappings */
    export const type = "bivariate_frequency_table";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateFrequencyTableCard$BivariateFrequencyTableCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _BivariateFrequencyTableCardResult extends _CardResult {
        type: "bivariate_frequency_table";
        table: BivariateFrequencyTableFragment;
    }
    
    export type BivariateFrequencyTableCardResult = _BivariateFrequencyTableCardResult;

    export namespace BivariateFrequencyTableCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateFrequencyTableCard$BivariateFrequencyTableCardResult in PolyJSON mappings */
        export const type = "bivariate_frequency_table";
    }
}