<!-- column color options without icons and with labels -->
<div class="custom-colors" [class.custom-colors--hidden]="isHidden$ | async"
    *ngIf="(displayedWithIcons$ | async) === false">
    <ul class="custom-colors__wrapper" *ngIf="hasColorDefs$ | async">
        <li class="custom-colors__item" *ngFor="let colorDef of colorDefs$ | async; trackBy: identify">
            <chart-color-picker [color]="colorDef.color" [colors]="colors$ | async" [itemsPerRow]="itemsPerRow$ | async"
                (colorChange)="setColor(colorDef, $event)">
            </chart-color-picker>
            <div class="custom-colors__item-label" textOverflowTooltip [textTooltip]="colorDef.label">
            </div>
        </li>
    </ul>
    <reset-button (onReset)="resetCustomColors()" [hasLabel]="true"></reset-button>
</div>

<!-- inline color options with icons only -->
<div class="custom-colors-inline" *ngIf="displayedWithIcons$ | async">
    <div class="custom-colors__item" *ngFor="let colorDef of colorDefs$ | async; trackBy: identify">
        <chart-color-picker [icon]="colorDef.icon" [color]="colorDef.color" [colors]="colors$ | async"
            [itemsPerRow]="itemsPerRow$ | async" (colorChange)="setColor(colorDef, $event)">
        </chart-color-picker>
    </div>
</div>
