import type { BivariateCard } from './bivariate-card';
import type { BivariateFrequencyTableFragment } from './fragments/bivariate-frequency-table-fragment';
import type { CardResult } from './card-result';
import type { CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { _BivariateCard } from './bivariate-card';
import type { _BivariateFrequencyTableFragment } from './fragments/bivariate-frequency-table-fragment';
import type { _CardResult } from './card-result';
import type { _CardWithConfidenceLevel } from './common/card-with-confidence-level';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.Chi2IndTestCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Chi2IndTestCard extends _BivariateCard, _CardWithConfidenceLevel {
    type: "chi2_independence_test";
    confidenceLevel: number;
    maxValuesX: number;
    maxValuesY: number;
}

export type Chi2IndTestCard = _Chi2IndTestCard;

export namespace Chi2IndTestCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.Chi2IndTestCard in PolyJSON mappings */
    export const type = "chi2_independence_test";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.Chi2IndTestCard$Chi2IndTestCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _Chi2IndTestCardResult extends _CardResult {
        type: "chi2_independence_test";
        dof: number;
        freqTableData: BivariateFrequencyTableFragment;
        pvalue: number;
        statistic: number;
    }
    
    export type Chi2IndTestCardResult = _Chi2IndTestCardResult;

    export namespace Chi2IndTestCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.Chi2IndTestCard$Chi2IndTestCardResult in PolyJSON mappings */
        export const type = "chi2_independence_test";
    }
}