<new-in-version-popover 
    targetVersion="12" 
    popoverId="new-chart" 
    featureName="Radar chart"
    displayMode="corner">
    <div popoverContent>
        <p>
            Radar Charts are a way of comparing multiple quantitative variables.
            This chart is useful for <strong>seeing which variables are scoring high or 
            low within a dataset</strong>, making them suited for displaying performance.
        </p>
    </div>
    <div popoverTarget>
        <div class="chart-type-picker">
            <div class="controls">
                <ng-select
                id="qa_charts_chart-type-picker"
                [items]="sortedOptions"
                bindLabel="displayName"
                bindValue="id"
                [ngModel]="selectedChartId"
                (ngModelChange)="selectChartId($event)"
                [searchable]="false"
                [clearable]="false"
                class="chart-type-picker"
                [searchFn]="applyCustomSearch"
                dropdownPosition="bottom"
                notFoundText="No chart types found"
                [hideSelected]="true"
                [appendTo]="'.chart-type-picker.ng-select'">
        
                    <ng-template ng-option-tmp ng-label-tmp let-item="item" let-index="index">
                    <div class="chart-type-picker__option-container" id="{{item.selected ? item.id + '__selected' : item.id}}">
                        <div *ngIf="item.isWebapp; then webappOptionTemplate else chartOptionTemplate"></div>
                            <ng-template #webappOptionTemplate>
                                <div title="{{item.title}}" class="{{item.classes}}">
                                    <i class='chart-type-picker__option-icon {{item.imgSrc}}'></i>
                                    <div class='chart-type-picker__option-label'>{{item.displayName}}</div>
                                    <span class='chart-type-picker__plugin-label dku-tiny-text'>plugin</span>
                                </div>
                            </ng-template>
        
                            
                            <ng-template #chartOptionTemplate>
                                <div 
                                class="{{item.classes}}" 
                                matTooltip="{{item.title}}" 
                                matTooltipShowDelay="{{item.tooltipDelayShow}}"
                                matTooltipPosition="right"
                                matTooltipClass="{{item.tooltipClass}}">
                                    <img class='chart-type-picker__option-img' src='{{item.imgSrc}}'/>
                                    <div class='chart-type-picker__option-label'>{{item.displayName}}</div>
                                </div>
                            </ng-template>
                        </div>
                    </ng-template>
        
                    <ng-template ng-header-tmp>
                        <ng-select-search-input
                        placeholder="Search by name or keywords...">
                        </ng-select-search-input>
                    </ng-template>
        
                </ng-select>
            </div>
        </div>
    </div>
</new-in-version-popover>
