/** 
 * Translated from class com.dataiku.dip.dataflow.exec.autofeaturegeneration.TimeWindow
 * Via: com.dataiku.dip.dataflow.exec.autofeaturegeneration.AutoFeatureGenerationRecipePayloadParams$InputDesc
 */
export interface _TimeWindow {
    from: number;
    to: number;
    windowUnit: TimeWindow.WindowUnit;
}

export type TimeWindow = _TimeWindow;

export namespace TimeWindow {
    /** 
     * Translated from enum com.dataiku.dip.dataflow.exec.autofeaturegeneration.TimeWindow$WindowUnit
     * Via: com.dataiku.dip.dataflow.exec.autofeaturegeneration.TimeWindow
     */
    export enum WindowUnit {
        SECOND = "SECOND",
        MINUTE = "MINUTE",
        HOUR = "HOUR",
        DAY = "DAY",
        WEEK = "WEEK",
        MONTH = "MONTH",
        YEAR = "YEAR",
    }
}