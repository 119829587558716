import type { Grouping } from './grouping';
import type { GroupingResult } from './grouping-result';
import type { _Grouping } from './grouping';
import type { _GroupingResult } from './grouping-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.grouping.MultiAnumGrouping
 * Via: UIModel annotation in the class hierarchy
 */
export interface _MultiAnumGrouping extends _Grouping {
    type: "multi_anum";
    columns: string[];
}

export type MultiAnumGrouping = _MultiAnumGrouping;

export namespace MultiAnumGrouping {
    /** Type of class com.dataiku.dip.eda.compute.grouping.MultiAnumGrouping in PolyJSON mappings */
    export const type = "multi_anum";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.grouping.MultiAnumGrouping$MultiAnumGroupingResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _MultiAnumGroupingResult extends _GroupingResult {
        type: "multi_anum";
        columns: string[];
        groupValues: string[][];
    }
    
    export type MultiAnumGroupingResult = _MultiAnumGroupingResult;

    export namespace MultiAnumGroupingResult {
        /** Type of class com.dataiku.dip.eda.compute.grouping.MultiAnumGrouping$MultiAnumGroupingResult in PolyJSON mappings */
        export const type = "multi_anum";
    }
}