<div *ngIf="isAuto$ | async" class="color-picker-item-icon" (click)="togglePalette()" #colorPickerBtn
    [ngClass]="{ 'color-picker-item-icon--open' : isPaletteToggled$ | async }" [matTooltip]="autoTooltip" matTooltipPosition="above">
    <div class="color-picker-item-icon__main">
        <i *ngIf="icon$ | async as icon" class="{{icon}} main-icon"></i>
        <div class="color-indicator auto-color-indicator" [style.background-color]="displayedColor$ | async"></div>
    </div>

    <span class="caret"></span>
</div>

<div *ngIf="(isAuto$ | async) === false" class="color-picker-item-icon" (click)="togglePalette()" #colorPickerBtn
    [ngClass]="{ 'color-picker-item-icon--open' : isPaletteToggled$ | async }">
    <div class="color-picker-item-icon__main">
        <i *ngIf="icon$ | async as icon" class="{{icon}} main-icon"></i>
        <div class="color-indicator" [class.white-color-indicator]="isWhite$ | async"
            [style.background-color]="displayedColor$ | async"></div>
    </div>

    <span class="caret"></span>
</div>

<ng-template #colorPalette>
    <div id="dku-contextual-menu" class="color-picker-palette">
        <ul class="color-palette" [attr.style]="paletteStyle$ | async">
            <li *ngFor="let color of colors$ | async" (click)="setColor($event, color)">
                <chart-color-picker-item [color]="color"
                    [selectedColor]="displayedColor$ | async"></chart-color-picker-item>
            </li>
        </ul>
        <chart-color-picker-alpha *ngIf="displayAlpha$ | async" [color]="displayedColor$ | async"
            (colorChange)="onAlphaChange($event)"></chart-color-picker-alpha>
    </div>
</ng-template>
