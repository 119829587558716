import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { Curve } from './../../curves/curve';
import type { ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _AvailableResult } from './../available-result';
import type { _Curve } from './../../curves/curve';
import type { _ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.FitCurve
 * Via: UIModel annotation in the class hierarchy
 */
export interface _FitCurve extends Computation._BivariateComputation {
    type: "fit_curve";
    curve: Curve;
}

export type FitCurve = _FitCurve;

export namespace FitCurve {
    /** Type of class com.dataiku.dip.eda.compute.computations.bivariate.FitCurve in PolyJSON mappings */
    export const type = "fit_curve";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.FitCurve$FitCurveResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FitCurveResult extends _AvailableResult {
        type: "fit_curve";
        parametrized: Curve.ParametrizedCurve;
        plot: FitCurve.PlotData;
        scores: FitCurve.ScoreData;
    }
    
    export type FitCurveResult = _FitCurveResult;
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.FitCurve$PlotData
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _PlotData extends _ImmutableValueObject {
        x: number[];
        y: number[];
    }
    
    export type PlotData = _PlotData;
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.FitCurve$ScoreData
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ScoreData extends _ImmutableValueObject {
        r2: number;
        rmse: number;
    }
    
    export type ScoreData = _ScoreData;

    export namespace FitCurveResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.bivariate.FitCurve$FitCurveResult in PolyJSON mappings */
        export const type = "fit_curve";
    }
}