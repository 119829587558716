import type { InfoMessage } from './../coremodel/info-message';



export namespace WarningsContext {
    /** 
     * Translated from class com.dataiku.dip.warnings.WarningsContext$SerializedThrowable
     * Via: com.dataiku.dip.warnings.WarningsContext$StoredWarning
     */
    export interface _SerializedThrowable {
        clazz: string;
        code: string;
        fixability: InfoMessage.FixabilityCategory;
        message: string;
        stack: string;
        title: string;
    }
    
    export type SerializedThrowable = _SerializedThrowable;
    
    /** 
     * Translated from class com.dataiku.dip.warnings.WarningsContext$SerializedWarnings
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _SerializedWarnings {
        totalCount: number;
        warnings: { [key in WarningsContext.WarningType]: WarningsContext.WarningTypeData };
    }
    
    export type SerializedWarnings = _SerializedWarnings;
    
    /** 
     * Translated from class com.dataiku.dip.warnings.WarningsContext$StoredWarning
     * Via: com.dataiku.dip.warnings.WarningsContext$WarningTypeData
     */
    export interface _StoredWarning {
        message: string;
        t: WarningsContext.SerializedThrowable;
        timestamp: number;
    }
    
    export type StoredWarning = _StoredWarning;
    
    /** 
     * Translated from enum com.dataiku.dip.warnings.WarningsContext$WarningType
     * Via: com.dataiku.dip.warnings.WarningsContext$SerializedWarnings
     */
    export enum WarningType {
        INPUT_DATA_BAD_DATA = "INPUT_DATA_BAD_DATA",
        INPUT_DATA_BAD_DATE = "INPUT_DATA_BAD_DATE",
        INPUT_DATA_BAD_INT = "INPUT_DATA_BAD_INT",
        INPUT_DATA_INT_OOR = "INPUT_DATA_INT_OOR",
        INPUT_DATA_BAD_FLOAT = "INPUT_DATA_BAD_FLOAT",
        INPUT_DATA_BAD_DOUBLE = "INPUT_DATA_BAD_DOUBLE",
        INPUT_DATA_BAD_BOOLEAN = "INPUT_DATA_BAD_BOOLEAN",
        INPUT_DATA_BAD_GEO = "INPUT_DATA_BAD_GEO",
        INPUT_DATA_BAD_NBCOLS = "INPUT_DATA_BAD_NBCOLS",
        INPUT_DATA_EXTRA_COLUMNS = "INPUT_DATA_EXTRA_COLUMNS",
        INPUT_DATA_MISSING_COLUMNS = "INPUT_DATA_MISSING_COLUMNS",
        INPUT_ELASTICSEARCH_INDEX_NOT_FOUND = "INPUT_ELASTICSEARCH_INDEX_NOT_FOUND",
        INPUT_ELASTICSEARCH_COUNT_MISMATCH = "INPUT_ELASTICSEARCH_COUNT_MISMATCH",
        INPUT_ELASTICSEARCH_BAD_QUERY = "INPUT_ELASTICSEARCH_BAD_QUERY",
        INPUT_ELASTICSEARCH_RESULT_WINDOW_TOO_LARGE = "INPUT_ELASTICSEARCH_RESULT_WINDOW_TOO_LARGE",
        INPUT_DATA_VERY_LONG = "INPUT_DATA_VERY_LONG",
        MEMSIZE_TRUNCATED_SAMPLE = "MEMSIZE_TRUNCATED_SAMPLE",
        INPUT_DATA_LINE_DOES_NOT_PARSE = "INPUT_DATA_LINE_DOES_NOT_PARSE",
        OUTPUT_DATA_BAD_DATE = "OUTPUT_DATA_BAD_DATE",
        OUTPUT_DATA_BAD_INT = "OUTPUT_DATA_BAD_INT",
        OUTPUT_DATA_BAD_DOUBLE = "OUTPUT_DATA_BAD_DOUBLE",
        OUTPUT_DATA_INT_OOR = "OUTPUT_DATA_INT_OOR",
        OUTPUT_DATA_BAD_FLOAT = "OUTPUT_DATA_BAD_FLOAT",
        OUTPUT_DATA_BAD_BOOLEAN = "OUTPUT_DATA_BAD_BOOLEAN",
        HIVE_METASTORE_SYNCHRONIZE = "HIVE_METASTORE_SYNCHRONIZE",
        MISSING_SOURCE_PARTITION = "MISSING_SOURCE_PARTITION",
        IMPALA_METADATA_SYNCHRONIZE = "IMPALA_METADATA_SYNCHRONIZE",
        SHAKER_BAD_DATE = "SHAKER_BAD_DATE",
        SHAKER_COLUMN_IGNORED = "SHAKER_COLUMN_IGNORED",
        PARTITION_MODE_UNSUPPORTED = "PARTITION_MODE_UNSUPPORTED",
        DATA_DRIFT_COMPUTATION_ERROR = "DATA_DRIFT_COMPUTATION_ERROR",
        OUTPUT_DATA_SQL_BAD_DATA = "OUTPUT_DATA_SQL_BAD_DATA",
        OUTPUT_DATA_BAD_TYPE = "OUTPUT_DATA_BAD_TYPE",
        CSV_OUTPUT_BAD_COMPLEX_VALUE = "CSV_OUTPUT_BAD_COMPLEX_VALUE",
        CSV_OUTPUT_BAD_DATE = "CSV_OUTPUT_BAD_DATE",
        OUTPUT_DATA_ELASTICSEARCH_ERROR = "OUTPUT_DATA_ELASTICSEARCH_ERROR",
        COMMUNITY_EDITION_TRUNCATED_OUTPUT = "COMMUNITY_EDITION_TRUNCATED_OUTPUT",
        SAMPLING_BAD_COLUMN = "SAMPLING_BAD_COLUMN",
        SQL_SCRIPT_INFER_SCHEMA_FAILED = "SQL_SCRIPT_INFER_SCHEMA_FAILED",
        GOVERNANCE_ISSUE = "GOVERNANCE_ISSUE",
        INPUT_DATA_BAD_TYPE = "INPUT_DATA_BAD_TYPE",
        ENGINE_DEPRECATED = "ENGINE_DEPRECATED",
        ENGINE_UNSUPPORTED = "ENGINE_UNSUPPORTED",
        DEPRECATED_SPARK = "DEPRECATED_SPARK",
        ML_DIAGNOSTICS_TRAINING_OVERFIT = "ML_DIAGNOSTICS_TRAINING_OVERFIT",
        ML_DIAGNOSTICS_MODELING_PARAMETERS = "ML_DIAGNOSTICS_MODELING_PARAMETERS",
        ML_DIAGNOSTICS_LEAKAGE_DETECTION = "ML_DIAGNOSTICS_LEAKAGE_DETECTION",
        ML_DIAGNOSTICS_DATASET_SANITY_CHECKS = "ML_DIAGNOSTICS_DATASET_SANITY_CHECKS",
        ML_DIAGNOSTICS_MODEL_CHECK = "ML_DIAGNOSTICS_MODEL_CHECK",
        ML_DIAGNOSTICS_RUNTIME = "ML_DIAGNOSTICS_RUNTIME",
        ML_DIAGNOSTICS_REPRODUCIBILITY = "ML_DIAGNOSTICS_REPRODUCIBILITY",
        ML_DIAGNOSTICS_ML_ASSERTIONS = "ML_DIAGNOSTICS_ML_ASSERTIONS",
        ML_DIAGNOSTICS_ABNORMAL_PREDICTIONS_DETECTION = "ML_DIAGNOSTICS_ABNORMAL_PREDICTIONS_DETECTION",
        ML_DIAGNOSTICS_SCORING_DATASET_SANITY_CHECKS = "ML_DIAGNOSTICS_SCORING_DATASET_SANITY_CHECKS",
        ML_DIAGNOSTICS_EVALUATION_DATASET_SANITY_CHECKS = "ML_DIAGNOSTICS_EVALUATION_DATASET_SANITY_CHECKS",
        ML_DIAGNOSTICS_TIMESERIES_RESAMPLING_CHECKS = "ML_DIAGNOSTICS_TIMESERIES_RESAMPLING_CHECKS",
        STATISTICS_ENGINE_ISSUE = "STATISTICS_ENGINE_ISSUE",
    }
    /** 
     * Translated from class com.dataiku.dip.warnings.WarningsContext$WarningTypeData
     * Via: com.dataiku.dip.warnings.WarningsContext$SerializedWarnings
     */
    export interface _WarningTypeData {
        count: number;
        stored: WarningsContext.StoredWarning[];
        type: WarningsContext.WarningType;
    }
    
    export type WarningTypeData = _WarningTypeData;
}