import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, Inject, ChangeDetectorRef } from '@angular/core';
import { DataCollectionsService } from '../shared/services/data-collections.service';
import { catchAPIError } from '@core/dataiku-api/api-error';
import { UIDataCollection } from 'src/generated-sources';
import { BehaviorSubject, map, merge, noop, Subject, switchMap, withLatestFrom } from 'rxjs';
import { ErrorContextService } from '@shared/services/error-context.service';
import { ActivityIndicatorService } from '@shared/services/activity-indicator.service';
import { fairAny } from 'dku-frontend-core';
import { DataCollectionsModalService } from '../shared/services/data-collections-modal.service';
import { EscapeHtmlPipe } from '@shared/pipes/escaping/escape-html.pipe';
import { dataCollectionFullItemRefEquals, getDataCollectionItemDisplayName, getDataCollectionFullItemRef, DataCollectionFullItemRef } from '../utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { INFO_TAB, SCHEMA_TAB, TabDefinition } from '@shared/components/collapsible-right-panel-layout/collapsible-right-panel-layout.component';

@UntilDestroy()
@Component({
    selector: 'dss-data-collection-page',
    templateUrl: './data-collection-page.component.html',
    providers: [ErrorContextService],
    styleUrls: ['./data-collection-page.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCollectionPageComponent implements OnChanges {
    @Input() readonly id!: string;

    isRightPanelOpened = true;
    rightPanelSelectedTab = 'info';
    get rightPanelTabs(): TabDefinition<string>[] {
        const tabs: TabDefinition<string>[] = [INFO_TAB];
        if (this.selectedDataCollectionItem?.type === "dataset" && this.selectedDataCollectionItem?.columnCount > 0) {
            tabs.push(SCHEMA_TAB);
        }
        return tabs;
    }

    // value pushed will be selected if defined. Otherwise, will reselect currently selected item.
    private readonly dataCollectionInfoRefreshTrigger$ = new BehaviorSubject<DataCollectionFullItemRef | undefined>(undefined);
    private readonly dataCollectionId$ = new Subject<string>();

    dataCollectionInfo?: UIDataCollection.DataCollectionInfo;
    selectedDataCollectionItem?: UIDataCollection.AbstractDataCollectionItemInfo;

    mayPublishToDataCollections: boolean;

    constructor(
        @Inject("$state") private $state: fairAny,
        private activityIndicatorService: ActivityIndicatorService,
        private errorContextService: ErrorContextService,
        private dataCollectionsService: DataCollectionsService,
        private dataCollectionsModalService: DataCollectionsModalService,
        private cd: ChangeDetectorRef,
    ) {
        this.mayPublishToDataCollections = this.dataCollectionsService.mayPublishToDataCollections();

        merge( // creates an observable that fires with [itemToSelect, id] but with itemToSelect being defined only if the change comes from dataCollectionInfoRefreshTrigger$
            this.dataCollectionInfoRefreshTrigger$.pipe(withLatestFrom(this.dataCollectionId$)),
            this.dataCollectionId$.pipe(map(id => ([undefined, id] as const))),
        ).pipe(
            switchMap(([selectAfter, id]) => {
                return this.dataCollectionsService.getInfo(id).pipe(
                catchAPIError(this.errorContextService),
                    map(info => ([selectAfter, info] as const))
                );
            }),
            untilDestroyed(this),
        ).subscribe(([selectAfter, res]) => {
            this.dataCollectionInfo = res;

            // we reselect either the selectAfter item if it's defined, or the previously selected item if there was any & it's still there, or we unselect
            const refToSelect = selectAfter
                ? selectAfter
                : this.selectedDataCollectionItem !== undefined
                    ? getDataCollectionFullItemRef(res.id, this.selectedDataCollectionItem)
                    : undefined;

            this.selectedDataCollectionItem = refToSelect
                ? res.items.find(i => dataCollectionFullItemRefEquals(getDataCollectionFullItemRef(res.id, i), refToSelect))
                : undefined;

            this.cd.detectChanges();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.id) {
            this.dataCollectionId$.next(this.id);
        }
    }

    updateMetadata(currentInfo: UIDataCollection.DataCollectionInfo, metadataChange: Partial<UIDataCollection.MetadataInfo>) {
        this.dataCollectionsService.updateMetadata(currentInfo.id, {
            displayName: currentInfo.displayName,
            description: currentInfo.description,
            tags: currentInfo.tags,
            color: currentInfo.color,
            ...metadataChange,
        }).pipe(
            catchAPIError(this.errorContextService),
        ).subscribe(() => {
            this.updateInfoIfNeeded(currentInfo.id, (info) => ({
                ...info,
                ...metadataChange,
            }));
            this.activityIndicatorService.success("Saved");
        });
    }

    editPermissions(dataCollectionInfo: UIDataCollection.DataCollectionInfo) {
        this.dataCollectionsModalService.openPermissionsModal(dataCollectionInfo).then(() => {
            this.forceDataCollectionInfoUpdateFromServer();
            this.activityIndicatorService.success("Permissions updated");
        }, noop); // rejected means not updated
    }

    deleteCollection(dataCollectionInfo: UIDataCollection.DataCollectionInfo) {
        this.dataCollectionsModalService.openDeleteModal(dataCollectionInfo.displayName).then(() => {
            this.dataCollectionsService.delete(dataCollectionInfo.id).pipe(
                catchAPIError(this.errorContextService),
            ).subscribe(() => {
                this.$state.go('^.home');
                const escapedName = new EscapeHtmlPipe().transform(dataCollectionInfo.displayName);
                this.activityIndicatorService.success(`Data collection "${escapedName}" has been deleted`);
            });
        }, noop); // rejects means deletion not confirmed
    }

    addObjectsToCollection(dataCollectionInfo: UIDataCollection.DataCollectionInfo): void {
        this.dataCollectionsModalService.openAddObjectModal({id: this.id, displayName: dataCollectionInfo.displayName}).then((result) => {
            const dataStewardWarning: string = result.response.allDataStewardsDefined ? "" : "<div>No Data Steward set in dataset details.</div>";
            const newObjectRef: DataCollectionFullItemRef = {
                dataCollectionId: this.id,
                type: 'DATASET',
                projectKey: result.requestedObjectsRefs[0].projectKey,
                id: result.requestedObjectsRefs[0].id,
            };
            if (result.response.modified) {
                const escapedObjectLabel: string = new EscapeHtmlPipe().transform(result.requestedObjectsRefs[0].id);
                const message: string = `${escapedObjectLabel} added to collection!`;
                if (dataStewardWarning) {
                    this.activityIndicatorService.warning(message + dataStewardWarning, 10000);
                } else {
                    this.activityIndicatorService.success(message);
                }
                this.forceDataCollectionInfoUpdateFromServer(newObjectRef);
            } else {
                const message: string = "Dataset already in collection.";
                if (dataStewardWarning) {
                    this.activityIndicatorService.warning(message + dataStewardWarning, 10000);
                } else {
                    this.activityIndicatorService.info(message);
                }
                this.selectedDataCollectionItem = dataCollectionInfo.items.find(
                    (i) => dataCollectionFullItemRefEquals(getDataCollectionFullItemRef(dataCollectionInfo.id, i), newObjectRef)
                );
                this.cd.detectChanges();
            }
        }, noop); // rejection means modal closed without adding
    }

    removeSelectedItem(currentId: string, item: UIDataCollection.AbstractDataCollectionItemInfo) {
        this.dataCollectionsModalService.openRemoveItemModal(getDataCollectionItemDisplayName(item)).then(() => {
            // unselect if needed
            if(item === this.selectedDataCollectionItem) {
                this.selectedDataCollectionItem = undefined;
            }

            this.dataCollectionsService.removeItem(currentId, item).pipe(
                catchAPIError(this.errorContextService),
            ).subscribe(() => {
                this.activityIndicatorService.success("Saved");
                this.updateInfoIfNeeded(currentId, (info) => ({
                    ...info,
                    items: info.items.filter(i => i !== item),
                }));
            });
        }, noop); // reject means unconfirmed
    }

    /**
         * Update the current details under the condition that the selectedItem is still referring to the same item than when the action was started.
         * @param ref the ref fo the itemDetails to update
         * @param update how to update the details
         */
    private updateInfoIfNeeded(
        id: string,
        update: (details: UIDataCollection.DataCollectionInfo) => UIDataCollection.DataCollectionInfo,
    ) {
        if(id === this.dataCollectionInfo?.id) {
            this.dataCollectionInfo = update(this.dataCollectionInfo);
            this.cd.detectChanges();
        }
    }

    private forceDataCollectionInfoUpdateFromServer(selectAfterRefresh?: DataCollectionFullItemRef) {
        this.dataCollectionInfoRefreshTrigger$.next(selectAfterRefresh);
    }
}
