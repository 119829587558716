<form class="dkuform-horizontal"
    [formGroup]="configForm"
>
    <ng-container *ngIf="statsForm.length > 0 else placeholder">
        <label class="control-title">
            Test variable
            <i class="icon-info-sign mleft4"
                matTooltip="The variable on which the hypothesis is tested"
                matTooltipPosition="right"
            ></i>
        </label>
        <label class="control-title">
            Hypothesized mean
            <i class="icon-info-sign mleft4"
                matTooltip="The hypothesized mean of the test variable over the population from which your sample was drawn"
                matTooltipPosition="right"
            ></i>
        </label>
    </ng-container>

    <ng-template #placeholder>
        <div class="text-center">Add statistical tests to run this recipe.</div>
    </ng-template>

    <div *ngFor="let statsFormControl of statsForm.controls; let index = index;" class="common-styles-only">
        <div class="control">
            <basic-select
                [formControl]="statsFormControl.controls.column"
                [items]="(columns$ | async) ?? []"
                bindLabel="name"
                bindValue="name"
                bindAnnotation="type"
                [searchable]="true"
                [invalidateOnGhosts]="true"
                ghostItemsTooltip="This column does not exist in the input dataset."
            ></basic-select>
        </div>
        <div class="control">
            <input type="number" [formControl]="statsFormControl.controls.hypothesizedMean">
        </div>
        <editable-list-delete (onDelete)="deleteTest(index)"></editable-list-delete>
    </div>

    <div 
        [class.text-left]="statsForm.length > 0" 
        [class.text-center]="statsForm.length <= 0"
    >
        <button type="button"
                class="btn btn--primary btn--text btn--dku-icon"
                (click)="addTest()">
            <i class="dku-icon-plus-16"></i>Add a statistical test
        </button>
    </div>
</form>
