export namespace DKUApp {
    /** 
     * Translated from class com.dataiku.dip.DKUApp$DSSVersion
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _DSSVersion {
        conf_version: string;
        product_commitid: string;
        product_version: string;
    }
    
    export type DSSVersion = _DSSVersion;
    
    /** 
     * Translated from enum com.dataiku.dip.DKUApp$LicensingMode
     * Via: UIModel annotation in the class hierarchy
     */
    export enum LicensingMode {
        NONE = "NONE",
        SAAS = "SAAS",
        ON_PREMISE_CONNECTED = "ON_PREMISE_CONNECTED",
        ON_PREMISE_DISCONNECTED = "ON_PREMISE_DISCONNECTED",
    }
}