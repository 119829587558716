import type { ChartDef } from './../../pivot/frontend/model/chart-def';
import type { CodeEnvSelection } from './../../code/code-env-selection';
import type { ContainerExecSelection } from './../../containers/exec/container-exec-selection';
import type { CustomMeasure } from './../../pivot/frontend/model/custom-measure';
import type { MLSparkParams } from './../ml/mlspark-params';
import type { SerializedShakerScript } from './../../shaker/model/serialized-shaker-script';
import type { SimpleKeyValue } from 'dku-frontend-core/generated-sources/dku-core/src/main/java/com/dataiku/dip/coremodel/simple-key-value';
import type { SparkConstants } from './../ml/spark-constants';
import type { WarningsContext } from 'dku-frontend-core/generated-sources/dku-core/src/main/java/com/dataiku/dip/warnings/warnings-context';
import type { _ChartDef } from './../../pivot/frontend/model/chart-def';
import type { _CodeEnvSelection } from './../../code/code-env-selection';
import type { _ContainerExecSelection } from './../../containers/exec/container-exec-selection';
import type { _CustomMeasure } from './../../pivot/frontend/model/custom-measure';
import type { _MLSparkParams } from './../ml/mlspark-params';
import type { _SerializedShakerScript } from './../../shaker/model/serialized-shaker-script';
import type { _SimpleKeyValue } from 'dku-frontend-core/generated-sources/dku-core/src/main/java/com/dataiku/dip/coremodel/simple-key-value';

/** 
 * Translated from class com.dataiku.dip.analysis.model.MLTask
 * Via: com.dataiku.dip.dao.SavedModel
 */
export interface _MLTask {
    backendType: MLTask.BackendType;
    containerSelection: ContainerExecSelection;
    customMeasures: CustomMeasure[];
    diagnosticsSettings: MLTask.DiagnosticsSettings;
    envSelection: CodeEnvSelection;
    id: string;
    initiator: string;
    labels: SimpleKeyValue[];
    maxConcurrentModelTraining: number;
    name: string;
    predictionDisplayCharts: MLTask.PredictedDataChart[];
    predictionDisplayScript: SerializedShakerScript;
    sparkCheckpoint: SparkConstants.Checkpoint;
    sparkCheckpointDir: string;
    sparkParams: MLSparkParams;
    taskType: MLTask.MLTaskType;
}

/**
    Translate from class com.dataiku.dip.analysis.model.MLTask
    @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
*/
export type MLTask = _MLTask;

export namespace MLTask {
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.MLTask$BackendType
     * Via: com.dataiku.dip.analysis.model.MLTask
     */
    export enum BackendType {
        PY_MEMORY = "PY_MEMORY",
        MLLIB = "MLLIB",
        VERTICA = "VERTICA",
        H2O = "H2O",
        KERAS = "KERAS",
        DEEP_HUB = "DEEP_HUB",
    }
    /** 
     * Translated from class com.dataiku.dip.analysis.model.MLTask$DiagnosticSetting
     * Via: com.dataiku.dip.analysis.model.MLTask$DiagnosticsSettings
     */
    export interface _DiagnosticSetting {
        enabled: boolean;
        type: WarningsContext.WarningType;
    }
    
    export type DiagnosticSetting = _DiagnosticSetting;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.MLTask$DiagnosticsSettings
     * Via: com.dataiku.dip.analysis.model.MLTask
     */
    export interface _DiagnosticsSettings {
        enabled: boolean;
        settings: MLTask.DiagnosticSetting[];
    }
    
    export type DiagnosticsSettings = _DiagnosticsSettings;
    
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.MLTask$MLTaskType
     * Via: com.dataiku.dip.dao.SavedModel
     */
    export enum MLTaskType {
        PREDICTION = "PREDICTION",
        CLUSTERING = "CLUSTERING",
        LLM_GENERIC_PROMPTABLE_COMPLETION = "LLM_GENERIC_PROMPTABLE_COMPLETION",
        LLM_GENERIC_RAW = "LLM_GENERIC_RAW",
        LLM_CLASSIFICATION = "LLM_CLASSIFICATION",
    }
    /** 
     * Translated from class com.dataiku.dip.analysis.model.MLTask$PredictedDataChart
     * Via: com.dataiku.dip.analysis.model.MLTask
     */
    export interface _PredictedDataChart {
        def: ChartDef;
    }
    
    export type PredictedDataChart = _PredictedDataChart;
}