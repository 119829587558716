/** 
 * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem
 * Via: com.dataiku.dip.server.services.ScenarioReportsService$ScenarioItem
 */
export interface _ReportTargetItem {
}

/** List of all concrete subclasses of com.dataiku.dip.scheduler.reports.ReportTargetItem */
export type ReportTargetItem =
    | ReportTargetItem.BundleItem
    | ReportTargetItem.ClusterItem
    | ReportTargetItem.CodeStudioItem
    | ReportTargetItem.ContinuousActivityItem
    | ReportTargetItem.DashboardExportItem
    | ReportTargetItem.DatasetItem
    | ReportTargetItem.DatasetPartitionItem
    | ReportTargetItem.DeploymentItem
    | ReportTargetItem.FlowDocumentationExportItem
    | ReportTargetItem.InlineHtmlItem
    | ReportTargetItem.InlineResultTableItem
    | ReportTargetItem.InsightItem
    | ReportTargetItem.JobsItem
    | ReportTargetItem.JsonObjectItem
    | ReportTargetItem.JupyterNotebookItem
    | ReportTargetItem.KeptFileItem
    | ReportTargetItem.LambdaServiceItem
    | ReportTargetItem.ManagedFolderFileItem
    | ReportTargetItem.ManagedFolderItem
    | ReportTargetItem.ManagedFolderPartitionItem
    | ReportTargetItem.ModelDocumentationExportItem
    | ReportTargetItem.ModelEvaluationStoreItem
    | ReportTargetItem.ModelEvaluationStorePartitionItem
    | ReportTargetItem.ProjectItem
    | ReportTargetItem.PythonItem
    | ReportTargetItem.RMarkdownReportItem
    | ReportTargetItem.RecipeItem
    | ReportTargetItem.RetrievableKnowledgeItem
    | ReportTargetItem.SQLConnectionItem
    | ReportTargetItem.SavedModelItem
    | ReportTargetItem.SavedModelPartitionItem
    | ReportTargetItem.ScenarioItem
    | ReportTargetItem.ScenarioStepItem
    | ReportTargetItem.ScenarioTriggerItem
    | ReportTargetItem.StreamingEndpointItem
    | ReportTargetItem.UrlItem
    | ReportTargetItem.WebAppItem
    | ReportTargetItem.WikiArticleExportItem
    | ReportTargetItem.WikiExportItem;

export namespace ReportTargetItem {
    /** Types of class com.dataiku.dip.scheduler.reports.ReportTargetItem in PolyJSON mappings */
    export const type = ["BUNDLE", "CLUSTER", "CODE_STUDIO", "CONTINUOUS_ACTIVITY", "DASHBOARD_EXPORT", "DATASET", "DATASET_PARTITION", "DEPLOYMENT", "FLOW_DOCUMENTATION_EXPORT", "INLINE_HTML", "INLINE_RESULT_TABLE", "INSIGHT", "JOBS", "JSON_OBJECT", "JUPYTER_NOTEBOOK", "KEPT_FILE", "LAMBDA_SERVICE", "MANAGED_FOLDER_FILE", "MANAGED_FOLDER", "MANAGED_FOLDER_PARTITION", "MODEL_DOCUMENTATION_EXPORT", "MODEL_EVALUATION_STORE", "MODEL_EVALUATION_STORE_PARTITION", "PROJECT", "PYTHON", "RMARKDOWN_REPORT", "RECIPE", "RETRIEVABLE_KNOWLEDGE", "SQL_CONNECTION", "SAVED_MODEL", "SAVED_MODEL_PARTITION", "SCENARIO", "SCENARIO_STEP", "SCENARIO_TRIGGER", "STREAMING_ENDPOINT", "URL", "WEBAPP", "WIKI_EXPORT_ARTICLE", "WIKI_EXPORT", ] as const;
    
    export function isReportTargetItem(obj: ReportTargetItem): obj is ReportTargetItem { return ["BUNDLE", "CLUSTER", "CODE_STUDIO", "CONTINUOUS_ACTIVITY", "DASHBOARD_EXPORT", "DATASET", "DATASET_PARTITION", "DEPLOYMENT", "FLOW_DOCUMENTATION_EXPORT", "INLINE_HTML", "INLINE_RESULT_TABLE", "INSIGHT", "JOBS", "JSON_OBJECT", "JUPYTER_NOTEBOOK", "KEPT_FILE", "LAMBDA_SERVICE", "MANAGED_FOLDER_FILE", "MANAGED_FOLDER", "MANAGED_FOLDER_PARTITION", "MODEL_DOCUMENTATION_EXPORT", "MODEL_EVALUATION_STORE", "MODEL_EVALUATION_STORE_PARTITION", "PROJECT", "PYTHON", "RMARKDOWN_REPORT", "RECIPE", "RETRIEVABLE_KNOWLEDGE", "SQL_CONNECTION", "SAVED_MODEL", "SAVED_MODEL_PARTITION", "SCENARIO", "SCENARIO_STEP", "SCENARIO_TRIGGER", "STREAMING_ENDPOINT", "URL", "WEBAPP", "WIKI_EXPORT_ARTICLE", "WIKI_EXPORT", ].includes(obj["type"]); }
    export function isBundleItem(obj: ReportTargetItem): obj is ReportTargetItem.BundleItem { return obj["type"] == "BUNDLE"; }
    export function isClusterItem(obj: ReportTargetItem): obj is ReportTargetItem.ClusterItem { return obj["type"] == "CLUSTER"; }
    export function isCodeStudioItem(obj: ReportTargetItem): obj is ReportTargetItem.CodeStudioItem { return obj["type"] == "CODE_STUDIO"; }
    export function isContinuousActivityItem(obj: ReportTargetItem): obj is ReportTargetItem.ContinuousActivityItem { return obj["type"] == "CONTINUOUS_ACTIVITY"; }
    export function isDashboardExportItem(obj: ReportTargetItem): obj is ReportTargetItem.DashboardExportItem { return obj["type"] == "DASHBOARD_EXPORT"; }
    export function isDatasetItem(obj: ReportTargetItem): obj is ReportTargetItem.DatasetItem { return obj["type"] == "DATASET"; }
    export function isDatasetPartitionItem(obj: ReportTargetItem): obj is ReportTargetItem.DatasetPartitionItem { return obj["type"] == "DATASET_PARTITION"; }
    export function isDeploymentItem(obj: ReportTargetItem): obj is ReportTargetItem.DeploymentItem { return obj["type"] == "DEPLOYMENT"; }
    export function isFlowDocumentationExportItem(obj: ReportTargetItem): obj is ReportTargetItem.FlowDocumentationExportItem { return obj["type"] == "FLOW_DOCUMENTATION_EXPORT"; }
    export function isInlineHtmlItem(obj: ReportTargetItem): obj is ReportTargetItem.InlineHtmlItem { return obj["type"] == "INLINE_HTML"; }
    export function isInlineResultTableItem(obj: ReportTargetItem): obj is ReportTargetItem.InlineResultTableItem { return obj["type"] == "INLINE_RESULT_TABLE"; }
    export function isInsightItem(obj: ReportTargetItem): obj is ReportTargetItem.InsightItem { return obj["type"] == "INSIGHT"; }
    export function isJobsItem(obj: ReportTargetItem): obj is ReportTargetItem.JobsItem { return obj["type"] == "JOBS"; }
    export function isJsonObjectItem(obj: ReportTargetItem): obj is ReportTargetItem.JsonObjectItem { return obj["type"] == "JSON_OBJECT"; }
    export function isJupyterNotebookItem(obj: ReportTargetItem): obj is ReportTargetItem.JupyterNotebookItem { return obj["type"] == "JUPYTER_NOTEBOOK"; }
    export function isKeptFileItem(obj: ReportTargetItem): obj is ReportTargetItem.KeptFileItem { return obj["type"] == "KEPT_FILE"; }
    export function isLambdaServiceItem(obj: ReportTargetItem): obj is ReportTargetItem.LambdaServiceItem { return obj["type"] == "LAMBDA_SERVICE"; }
    export function isManagedFolderFileItem(obj: ReportTargetItem): obj is ReportTargetItem.ManagedFolderFileItem { return obj["type"] == "MANAGED_FOLDER_FILE"; }
    export function isManagedFolderItem(obj: ReportTargetItem): obj is ReportTargetItem.ManagedFolderItem { return obj["type"] == "MANAGED_FOLDER"; }
    export function isManagedFolderPartitionItem(obj: ReportTargetItem): obj is ReportTargetItem.ManagedFolderPartitionItem { return obj["type"] == "MANAGED_FOLDER_PARTITION"; }
    export function isModelDocumentationExportItem(obj: ReportTargetItem): obj is ReportTargetItem.ModelDocumentationExportItem { return obj["type"] == "MODEL_DOCUMENTATION_EXPORT"; }
    export function isModelEvaluationStoreItem(obj: ReportTargetItem): obj is ReportTargetItem.ModelEvaluationStoreItem { return obj["type"] == "MODEL_EVALUATION_STORE"; }
    export function isModelEvaluationStorePartitionItem(obj: ReportTargetItem): obj is ReportTargetItem.ModelEvaluationStorePartitionItem { return obj["type"] == "MODEL_EVALUATION_STORE_PARTITION"; }
    export function isProjectItem(obj: ReportTargetItem): obj is ReportTargetItem.ProjectItem { return obj["type"] == "PROJECT"; }
    export function isPythonItem(obj: ReportTargetItem): obj is ReportTargetItem.PythonItem { return obj["type"] == "PYTHON"; }
    export function isRMarkdownReportItem(obj: ReportTargetItem): obj is ReportTargetItem.RMarkdownReportItem { return obj["type"] == "RMARKDOWN_REPORT"; }
    export function isRecipeItem(obj: ReportTargetItem): obj is ReportTargetItem.RecipeItem { return obj["type"] == "RECIPE"; }
    export function isRetrievableKnowledgeItem(obj: ReportTargetItem): obj is ReportTargetItem.RetrievableKnowledgeItem { return obj["type"] == "RETRIEVABLE_KNOWLEDGE"; }
    export function isSQLConnectionItem(obj: ReportTargetItem): obj is ReportTargetItem.SQLConnectionItem { return obj["type"] == "SQL_CONNECTION"; }
    export function isSavedModelItem(obj: ReportTargetItem): obj is ReportTargetItem.SavedModelItem { return obj["type"] == "SAVED_MODEL"; }
    export function isSavedModelPartitionItem(obj: ReportTargetItem): obj is ReportTargetItem.SavedModelPartitionItem { return obj["type"] == "SAVED_MODEL_PARTITION"; }
    export function isScenarioItem(obj: ReportTargetItem): obj is ReportTargetItem.ScenarioItem { return obj["type"] == "SCENARIO"; }
    export function isScenarioStepItem(obj: ReportTargetItem): obj is ReportTargetItem.ScenarioStepItem { return obj["type"] == "SCENARIO_STEP"; }
    export function isScenarioTriggerItem(obj: ReportTargetItem): obj is ReportTargetItem.ScenarioTriggerItem { return obj["type"] == "SCENARIO_TRIGGER"; }
    export function isStreamingEndpointItem(obj: ReportTargetItem): obj is ReportTargetItem.StreamingEndpointItem { return obj["type"] == "STREAMING_ENDPOINT"; }
    export function isUrlItem(obj: ReportTargetItem): obj is ReportTargetItem.UrlItem { return obj["type"] == "URL"; }
    export function isWebAppItem(obj: ReportTargetItem): obj is ReportTargetItem.WebAppItem { return obj["type"] == "WEBAPP"; }
    export function isWikiArticleExportItem(obj: ReportTargetItem): obj is ReportTargetItem.WikiArticleExportItem { return obj["type"] == "WIKI_EXPORT_ARTICLE"; }
    export function isWikiExportItem(obj: ReportTargetItem): obj is ReportTargetItem.WikiExportItem { return obj["type"] == "WIKI_EXPORT"; }
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$BundleItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _BundleItem extends _ReportTargetItem {
        type: "BUNDLE";
        bundleId: string;
        projectKey: string;
    }
    
    export type BundleItem = _BundleItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$ClusterItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _ClusterItem extends _ReportTargetItem {
        type: "CLUSTER";
        clusterId: string;
    }
    
    export type ClusterItem = _ClusterItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$CodeStudioItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _CodeStudioItem extends _ReportTargetItem {
        type: "CODE_STUDIO";
        codeStudioId: string;
        projectKey: string;
    }
    
    export type CodeStudioItem = _CodeStudioItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$ContinuousActivityItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _ContinuousActivityItem extends _ReportTargetItem {
        type: "CONTINUOUS_ACTIVITY";
        continuousActivityId: string;
        projectKey: string;
    }
    
    export type ContinuousActivityItem = _ContinuousActivityItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$DashboardExportItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _DashboardExportItem extends _ReportTargetItem {
        type: "DASHBOARD_EXPORT";
        dashboardId: string;
        dashboardName: string;
        folderId: string;
        folderName: string;
        projectKey: string;
    }
    
    export type DashboardExportItem = _DashboardExportItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$DatasetItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _DatasetItem extends _ReportTargetItem {
        type: "DATASET";
        datasetName: string;
        projectKey: string;
    }
    
    export type DatasetItem = _DatasetItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$DatasetPartitionItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _DatasetPartitionItem extends _ReportTargetItem {
        type: "DATASET_PARTITION";
        datasetName: string;
        partition: string;
        projectKey: string;
    }
    
    export type DatasetPartitionItem = _DatasetPartitionItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$DeploymentItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _DeploymentItem extends _ReportTargetItem {
        type: "DEPLOYMENT";
        deploymentId: string;
    }
    
    export type DeploymentItem = _DeploymentItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$FlowDocumentationExportItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _FlowDocumentationExportItem extends _ReportTargetItem {
        type: "FLOW_DOCUMENTATION_EXPORT";
        folderId: string;
        folderName: string;
        projectKey: string;
    }
    
    export type FlowDocumentationExportItem = _FlowDocumentationExportItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$InlineHtmlItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _InlineHtmlItem extends _ReportTargetItem {
        type: "INLINE_HTML";
        data: string;
    }
    
    export type InlineHtmlItem = _InlineHtmlItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$InlineResultTableItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _InlineResultTableItem extends _ReportTargetItem {
        type: "INLINE_RESULT_TABLE";
        table: { [key in string]: any/* com.google.gson.JsonElement */ };
    }
    
    export type InlineResultTableItem = _InlineResultTableItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$InsightItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _InsightItem extends _ReportTargetItem {
        type: "INSIGHT";
        insightId: string;
        projectKey: string;
    }
    
    export type InsightItem = _InsightItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$JobsItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _JobsItem extends _ReportTargetItem {
        type: "JOBS";
    }
    
    export type JobsItem = _JobsItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$JsonObjectItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _JsonObjectItem extends _ReportTargetItem {
        type: "JSON_OBJECT";
        object: { [key in string]: any/* com.google.gson.JsonElement */ };
    }
    
    export type JsonObjectItem = _JsonObjectItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$JupyterNotebookItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _JupyterNotebookItem extends _ReportTargetItem {
        type: "JUPYTER_NOTEBOOK";
        notebookId: string;
        projectKey: string;
    }
    
    export type JupyterNotebookItem = _JupyterNotebookItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$KeptFileItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _KeptFileItem extends _ReportTargetItem {
        type: "KEPT_FILE";
        fileType: string;
        mimeType: string;
        path: string;
    }
    
    export type KeptFileItem = _KeptFileItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$LambdaServiceItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _LambdaServiceItem extends _ReportTargetItem {
        type: "LAMBDA_SERVICE";
        projectKey: string;
        serviceId: string;
    }
    
    export type LambdaServiceItem = _LambdaServiceItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$ManagedFolderFileItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _ManagedFolderFileItem extends _ReportTargetItem {
        type: "MANAGED_FOLDER_FILE";
        folderId: string;
        itemPath: string;
        projectKey: string;
    }
    
    export type ManagedFolderFileItem = _ManagedFolderFileItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$ManagedFolderItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _ManagedFolderItem extends _ReportTargetItem {
        type: "MANAGED_FOLDER";
        folderId: string;
        projectKey: string;
    }
    
    export type ManagedFolderItem = _ManagedFolderItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$ManagedFolderPartitionItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _ManagedFolderPartitionItem extends _ReportTargetItem {
        type: "MANAGED_FOLDER_PARTITION";
        folderId: string;
        partition: string;
        projectKey: string;
    }
    
    export type ManagedFolderPartitionItem = _ManagedFolderPartitionItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$ModelDocumentationExportItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _ModelDocumentationExportItem extends _ReportTargetItem {
        type: "MODEL_DOCUMENTATION_EXPORT";
        folderId: string;
        folderName: string;
        fullModelId: string;
        mlTaskType: string;
        projectKey: string;
    }
    
    export type ModelDocumentationExportItem = _ModelDocumentationExportItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$ModelEvaluationStoreItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _ModelEvaluationStoreItem extends _ReportTargetItem {
        type: "MODEL_EVALUATION_STORE";
        evaluationStoreId: string;
        projectKey: string;
    }
    
    export type ModelEvaluationStoreItem = _ModelEvaluationStoreItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$ModelEvaluationStorePartitionItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _ModelEvaluationStorePartitionItem extends _ReportTargetItem {
        type: "MODEL_EVALUATION_STORE_PARTITION";
        evaluationStoreId: string;
        partition: string;
        projectKey: string;
    }
    
    export type ModelEvaluationStorePartitionItem = _ModelEvaluationStorePartitionItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$ProjectItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _ProjectItem extends _ReportTargetItem {
        type: "PROJECT";
        projectKey: string;
    }
    
    export type ProjectItem = _ProjectItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$PythonItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _PythonItem extends _ReportTargetItem {
        type: "PYTHON";
    }
    
    export type PythonItem = _PythonItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$RMarkdownReportItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _RMarkdownReportItem extends _ReportTargetItem {
        type: "RMARKDOWN_REPORT";
        projectKey: string;
        reportId: string;
    }
    
    export type RMarkdownReportItem = _RMarkdownReportItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$RecipeItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _RecipeItem extends _ReportTargetItem {
        type: "RECIPE";
        projectKey: string;
        recipeName: string;
    }
    
    export type RecipeItem = _RecipeItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$RetrievableKnowledgeItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _RetrievableKnowledgeItem extends _ReportTargetItem {
        type: "RETRIEVABLE_KNOWLEDGE";
        projectKey: string;
        retrievableKnowledgeId: string;
    }
    
    export type RetrievableKnowledgeItem = _RetrievableKnowledgeItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$SQLConnectionItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _SQLConnectionItem extends _ReportTargetItem {
        type: "SQL_CONNECTION";
        connection: string;
    }
    
    export type SQLConnectionItem = _SQLConnectionItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$SavedModelItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _SavedModelItem extends _ReportTargetItem {
        type: "SAVED_MODEL";
        modelId: string;
        projectKey: string;
    }
    
    export type SavedModelItem = _SavedModelItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$SavedModelPartitionItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _SavedModelPartitionItem extends _ReportTargetItem {
        type: "SAVED_MODEL_PARTITION";
        modelId: string;
        partition: string;
        projectKey: string;
    }
    
    export type SavedModelPartitionItem = _SavedModelPartitionItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$ScenarioItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _ScenarioItem extends _ReportTargetItem {
        type: "SCENARIO";
        projectKey: string;
        scenarioId: string;
    }
    
    export type ScenarioItem = _ScenarioItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$ScenarioStepItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _ScenarioStepItem extends _ReportTargetItem {
        type: "SCENARIO_STEP";
        projectKey: string;
        scenarioId: string;
        stepId: string;
    }
    
    export type ScenarioStepItem = _ScenarioStepItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$ScenarioTriggerItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _ScenarioTriggerItem extends _ReportTargetItem {
        type: "SCENARIO_TRIGGER";
        projectKey: string;
        scenarioId: string;
        triggerId: string;
    }
    
    export type ScenarioTriggerItem = _ScenarioTriggerItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$StreamingEndpointItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _StreamingEndpointItem extends _ReportTargetItem {
        type: "STREAMING_ENDPOINT";
        projectKey: string;
        streamingEndpointId: string;
    }
    
    export type StreamingEndpointItem = _StreamingEndpointItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$UrlItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _UrlItem extends _ReportTargetItem {
        type: "URL";
        url: string;
    }
    
    export type UrlItem = _UrlItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$WebAppItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _WebAppItem extends _ReportTargetItem {
        type: "WEBAPP";
        projectKey: string;
        webAppId: string;
    }
    
    export type WebAppItem = _WebAppItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$WikiArticleExportItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _WikiArticleExportItem extends _ReportTargetItem {
        type: "WIKI_EXPORT_ARTICLE";
        articleId: string;
        articleName: string;
        folderId: string;
        folderName: string;
        projectKey: string;
    }
    
    export type WikiArticleExportItem = _WikiArticleExportItem;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportTargetItem$WikiExportItem
     * Via: com.dataiku.dip.scheduler.reports.ReportTargetItem
     */
    export interface _WikiExportItem extends _ReportTargetItem {
        type: "WIKI_EXPORT";
        folderId: string;
        folderName: string;
        projectKey: string;
    }
    
    export type WikiExportItem = _WikiExportItem;

    export namespace BundleItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$BundleItem in PolyJSON mappings */
        export const type = "BUNDLE";
    }

    export namespace ClusterItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$ClusterItem in PolyJSON mappings */
        export const type = "CLUSTER";
    }

    export namespace CodeStudioItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$CodeStudioItem in PolyJSON mappings */
        export const type = "CODE_STUDIO";
    }

    export namespace ContinuousActivityItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$ContinuousActivityItem in PolyJSON mappings */
        export const type = "CONTINUOUS_ACTIVITY";
    }

    export namespace DashboardExportItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$DashboardExportItem in PolyJSON mappings */
        export const type = "DASHBOARD_EXPORT";
    }

    export namespace DatasetItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$DatasetItem in PolyJSON mappings */
        export const type = "DATASET";
    }

    export namespace DatasetPartitionItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$DatasetPartitionItem in PolyJSON mappings */
        export const type = "DATASET_PARTITION";
    }

    export namespace DeploymentItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$DeploymentItem in PolyJSON mappings */
        export const type = "DEPLOYMENT";
    }

    export namespace FlowDocumentationExportItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$FlowDocumentationExportItem in PolyJSON mappings */
        export const type = "FLOW_DOCUMENTATION_EXPORT";
    }

    export namespace InlineHtmlItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$InlineHtmlItem in PolyJSON mappings */
        export const type = "INLINE_HTML";
    }

    export namespace InlineResultTableItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$InlineResultTableItem in PolyJSON mappings */
        export const type = "INLINE_RESULT_TABLE";
    }

    export namespace InsightItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$InsightItem in PolyJSON mappings */
        export const type = "INSIGHT";
    }

    export namespace JobsItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$JobsItem in PolyJSON mappings */
        export const type = "JOBS";
    }

    export namespace JsonObjectItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$JsonObjectItem in PolyJSON mappings */
        export const type = "JSON_OBJECT";
    }

    export namespace JupyterNotebookItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$JupyterNotebookItem in PolyJSON mappings */
        export const type = "JUPYTER_NOTEBOOK";
    }

    export namespace KeptFileItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$KeptFileItem in PolyJSON mappings */
        export const type = "KEPT_FILE";
    }

    export namespace LambdaServiceItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$LambdaServiceItem in PolyJSON mappings */
        export const type = "LAMBDA_SERVICE";
    }

    export namespace ManagedFolderFileItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$ManagedFolderFileItem in PolyJSON mappings */
        export const type = "MANAGED_FOLDER_FILE";
    }

    export namespace ManagedFolderItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$ManagedFolderItem in PolyJSON mappings */
        export const type = "MANAGED_FOLDER";
    }

    export namespace ManagedFolderPartitionItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$ManagedFolderPartitionItem in PolyJSON mappings */
        export const type = "MANAGED_FOLDER_PARTITION";
    }

    export namespace ModelDocumentationExportItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$ModelDocumentationExportItem in PolyJSON mappings */
        export const type = "MODEL_DOCUMENTATION_EXPORT";
    }

    export namespace ModelEvaluationStoreItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$ModelEvaluationStoreItem in PolyJSON mappings */
        export const type = "MODEL_EVALUATION_STORE";
    }

    export namespace ModelEvaluationStorePartitionItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$ModelEvaluationStorePartitionItem in PolyJSON mappings */
        export const type = "MODEL_EVALUATION_STORE_PARTITION";
    }

    export namespace ProjectItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$ProjectItem in PolyJSON mappings */
        export const type = "PROJECT";
    }

    export namespace PythonItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$PythonItem in PolyJSON mappings */
        export const type = "PYTHON";
    }

    export namespace RMarkdownReportItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$RMarkdownReportItem in PolyJSON mappings */
        export const type = "RMARKDOWN_REPORT";
    }

    export namespace RecipeItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$RecipeItem in PolyJSON mappings */
        export const type = "RECIPE";
    }

    export namespace RetrievableKnowledgeItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$RetrievableKnowledgeItem in PolyJSON mappings */
        export const type = "RETRIEVABLE_KNOWLEDGE";
    }

    export namespace SQLConnectionItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$SQLConnectionItem in PolyJSON mappings */
        export const type = "SQL_CONNECTION";
    }

    export namespace SavedModelItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$SavedModelItem in PolyJSON mappings */
        export const type = "SAVED_MODEL";
    }

    export namespace SavedModelPartitionItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$SavedModelPartitionItem in PolyJSON mappings */
        export const type = "SAVED_MODEL_PARTITION";
    }

    export namespace ScenarioItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$ScenarioItem in PolyJSON mappings */
        export const type = "SCENARIO";
    }

    export namespace ScenarioStepItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$ScenarioStepItem in PolyJSON mappings */
        export const type = "SCENARIO_STEP";
    }

    export namespace ScenarioTriggerItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$ScenarioTriggerItem in PolyJSON mappings */
        export const type = "SCENARIO_TRIGGER";
    }

    export namespace StreamingEndpointItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$StreamingEndpointItem in PolyJSON mappings */
        export const type = "STREAMING_ENDPOINT";
    }

    export namespace UrlItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$UrlItem in PolyJSON mappings */
        export const type = "URL";
    }

    export namespace WebAppItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$WebAppItem in PolyJSON mappings */
        export const type = "WEBAPP";
    }

    export namespace WikiArticleExportItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$WikiArticleExportItem in PolyJSON mappings */
        export const type = "WIKI_EXPORT_ARTICLE";
    }

    export namespace WikiExportItem {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportTargetItem$WikiExportItem in PolyJSON mappings */
        export const type = "WIKI_EXPORT";
    }
}