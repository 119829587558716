import { DkuHttpService, TypedFutureResponse } from 'dku-frontend-core';
import { Injectable } from '@angular/core';
import { UIPopularDataset } from 'generated-sources';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class PrivatePopularDatasetsAPI {
    constructor(
            private dkuHttp: DkuHttpService
        ) {
    }

    list(): Observable<UIPopularDataset.PopularDatasetList> {
        return this.dkuHttp.request("GET", "/popular-datasets/list", undefined, false);
    }

    compute(): Observable<TypedFutureResponse<boolean>> {
        return this.dkuHttp.request("POST", "/popular-datasets/compute");
    }
}
