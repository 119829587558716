import type { DSSRunnableResult } from './../runnables/dssrunnable-result';
import type { ReportTargetItem } from './report-target-item';
import type { SmartLogTail } from 'dku-frontend-core/generated-sources/dku-core/src/main/java/com/dataiku/dip/utils/smart-log-tail';
import type { WarningsContext } from 'dku-frontend-core/generated-sources/dku-core/src/main/java/com/dataiku/dip/warnings/warnings-context';
import type { _ReportTargetItem } from './report-target-item';
import type { _SmartLogTail } from 'dku-frontend-core/generated-sources/dku-core/src/main/java/com/dataiku/dip/utils/smart-log-tail';

/** 
 * Translated from class com.dataiku.dip.scheduler.reports.ReportItem
 * Via: com.dataiku.dip.server.services.ScenarioReportsService$ScenarioItem
 */
export interface _ReportItem {
    end: number;
    info: { [key in string]: any/* java.lang.Object */ };
    logTail: SmartLogTail;
    outcome: ReportItem.Outcome;
    start: number;
    target: ReportTargetItem;
    thrown: WarningsContext.SerializedThrowable;
    warnings: WarningsContext.SerializedWarnings;
}

/** List of all concrete subclasses of com.dataiku.dip.scheduler.reports.ReportItem */
export type ReportItem =
    | ReportItem.ActivatedPublishedPackage
    | ReportItem.BuiltDataset
    | ReportItem.BuiltEvaluationStore
    | ReportItem.BuiltManagedFolder
    | ReportItem.BuiltReport
    | ReportItem.BuiltRetrievableKnowledge
    | ReportItem.BuiltSavedModel
    | ReportItem.BuiltStreamingEndpoint
    | ReportItem.CheckedDataset
    | ReportItem.CheckedFlowElementConsistency
    | ReportItem.CodeExecuted
    | ReportItem.ComputedMetrics
    | ReportItem.CreatedDashboardExport
    | ReportItem.CreatedFlowDocumentExport
    | ReportItem.CreatedJupyterExport
    | ReportItem.CreatedModelDocumentExport
    | ReportItem.CreatedWikiArticleExport
    | ReportItem.CreatedWikiExport
    | ReportItem.HiveMetastoreSynchronized
    | ReportItem.JobExecuted
    | ReportItem.MadeBundle
    | ReportItem.MadeLambdaPackage
    | ReportItem.ProducedByRunnable
    | ReportItem.PublishedBundle
    | ReportItem.PublishedLambdaService
    | ReportItem.ReloadSchema
    | ReportItem.RestartCodeStudio
    | ReportItem.RestartWebApp
    | ReportItem.ScenarioDone
    | ReportItem.SetUpCluster
    | ReportItem.SnapshotRMarkdownReport
    | ReportItem.StartedCluster
    | ReportItem.StartedContinuousActivity
    | ReportItem.StepDone
    | ReportItem.StopCodeStudio
    | ReportItem.StoppedCluster
    | ReportItem.StoppedContinuousActivity
    | ReportItem.ToreDownCluster
    | ReportItem.TriggerFired
    | ReportItem.UpdatedFromHive;

export namespace ReportItem {
    /** Types of class com.dataiku.dip.scheduler.reports.ReportItem in PolyJSON mappings */
    export const type = ["ACTIVATED_PUBLISHED_PACKAGE", "BUILT_DATASET", "BUILT_EVALUATION_STORE", "BUILT_FOLDER", "BUILT_REPORT", "BUILT_RETRIEVABLE_KNOWLEDGE", "BUILT_MODEL", "BUILT_STREAMING_ENDPOINT", "CHECKED_DATASET", "CHECKED_FLOW_ELEMENT_CONSISTENCY", "CODE_EXECUTED", "COMPUTED_METRICS", "CREATED_DASHBOARD_EXPORT", "CREATED_FLOW_DOCUMENTATION_EXPORT", "CREATED_JUPYTER_EXPORT", "CREATED_MODEL_DOCUMENTATION_EXPORT", "CREATED_WIKI_ARTICLE_EXPORT", "CREATED_WIKI_EXPORT", "SYNCED_DATASET", "JOB_EXECUTED", "MADE_BUNDLE", "MADE_LAMBDA_PACKAGE", "PRODUCED_BY_RUNNABLE", "PUBLISHED_BUNDLE", "PUBLISHED_LAMBDA_PACKAGE", "RELOAD_SCHEMA", "RESTART_CODE_STUDIO", "RESTART_WEBAPP", "SCENARIO_DONE", "SET_UP_CLUSTER", "CREATED_RMARKDOWN_EXPORT", "STARTED_CLUSTER", "STARTED_CONTINUOUS_ACTIVITY", "STEP_DONE", "STOP_CODE_STUDIO", "STOPPED_CLUSTER", "STOPPED_CONTINUOUS_ACTIVITY", "TORE_DOWN_CLUSTER", "TRIGGER_FIRED", "UPDATED_FROM_HIVE", ] as const;
    
    export function isReportItem(obj: ReportItem): obj is ReportItem { return ["ACTIVATED_PUBLISHED_PACKAGE", "BUILT_DATASET", "BUILT_EVALUATION_STORE", "BUILT_FOLDER", "BUILT_REPORT", "BUILT_RETRIEVABLE_KNOWLEDGE", "BUILT_MODEL", "BUILT_STREAMING_ENDPOINT", "CHECKED_DATASET", "CHECKED_FLOW_ELEMENT_CONSISTENCY", "CODE_EXECUTED", "COMPUTED_METRICS", "CREATED_DASHBOARD_EXPORT", "CREATED_FLOW_DOCUMENTATION_EXPORT", "CREATED_JUPYTER_EXPORT", "CREATED_MODEL_DOCUMENTATION_EXPORT", "CREATED_WIKI_ARTICLE_EXPORT", "CREATED_WIKI_EXPORT", "SYNCED_DATASET", "JOB_EXECUTED", "MADE_BUNDLE", "MADE_LAMBDA_PACKAGE", "PRODUCED_BY_RUNNABLE", "PUBLISHED_BUNDLE", "PUBLISHED_LAMBDA_PACKAGE", "RELOAD_SCHEMA", "RESTART_CODE_STUDIO", "RESTART_WEBAPP", "SCENARIO_DONE", "SET_UP_CLUSTER", "CREATED_RMARKDOWN_EXPORT", "STARTED_CLUSTER", "STARTED_CONTINUOUS_ACTIVITY", "STEP_DONE", "STOP_CODE_STUDIO", "STOPPED_CLUSTER", "STOPPED_CONTINUOUS_ACTIVITY", "TORE_DOWN_CLUSTER", "TRIGGER_FIRED", "UPDATED_FROM_HIVE", ].includes(obj["type"]); }
    export function isActivatedPublishedPackage(obj: ReportItem): obj is ReportItem.ActivatedPublishedPackage { return obj["type"] == "ACTIVATED_PUBLISHED_PACKAGE"; }
    export function isBuiltDataset(obj: ReportItem): obj is ReportItem.BuiltDataset { return obj["type"] == "BUILT_DATASET"; }
    export function isBuiltEvaluationStore(obj: ReportItem): obj is ReportItem.BuiltEvaluationStore { return obj["type"] == "BUILT_EVALUATION_STORE"; }
    export function isBuiltManagedFolder(obj: ReportItem): obj is ReportItem.BuiltManagedFolder { return obj["type"] == "BUILT_FOLDER"; }
    export function isBuiltReport(obj: ReportItem): obj is ReportItem.BuiltReport { return obj["type"] == "BUILT_REPORT"; }
    export function isBuiltRetrievableKnowledge(obj: ReportItem): obj is ReportItem.BuiltRetrievableKnowledge { return obj["type"] == "BUILT_RETRIEVABLE_KNOWLEDGE"; }
    export function isBuiltSavedModel(obj: ReportItem): obj is ReportItem.BuiltSavedModel { return obj["type"] == "BUILT_MODEL"; }
    export function isBuiltStreamingEndpoint(obj: ReportItem): obj is ReportItem.BuiltStreamingEndpoint { return obj["type"] == "BUILT_STREAMING_ENDPOINT"; }
    export function isCheckedDataset(obj: ReportItem): obj is ReportItem.CheckedDataset { return obj["type"] == "CHECKED_DATASET"; }
    export function isCheckedFlowElementConsistency(obj: ReportItem): obj is ReportItem.CheckedFlowElementConsistency { return obj["type"] == "CHECKED_FLOW_ELEMENT_CONSISTENCY"; }
    export function isCodeExecuted(obj: ReportItem): obj is ReportItem.CodeExecuted { return obj["type"] == "CODE_EXECUTED"; }
    export function isComputedMetrics(obj: ReportItem): obj is ReportItem.ComputedMetrics { return obj["type"] == "COMPUTED_METRICS"; }
    export function isCreatedDashboardExport(obj: ReportItem): obj is ReportItem.CreatedDashboardExport { return obj["type"] == "CREATED_DASHBOARD_EXPORT"; }
    export function isCreatedFlowDocumentExport(obj: ReportItem): obj is ReportItem.CreatedFlowDocumentExport { return obj["type"] == "CREATED_FLOW_DOCUMENTATION_EXPORT"; }
    export function isCreatedJupyterExport(obj: ReportItem): obj is ReportItem.CreatedJupyterExport { return obj["type"] == "CREATED_JUPYTER_EXPORT"; }
    export function isCreatedModelDocumentExport(obj: ReportItem): obj is ReportItem.CreatedModelDocumentExport { return obj["type"] == "CREATED_MODEL_DOCUMENTATION_EXPORT"; }
    export function isCreatedWikiArticleExport(obj: ReportItem): obj is ReportItem.CreatedWikiArticleExport { return obj["type"] == "CREATED_WIKI_ARTICLE_EXPORT"; }
    export function isCreatedWikiExport(obj: ReportItem): obj is ReportItem.CreatedWikiExport { return obj["type"] == "CREATED_WIKI_EXPORT"; }
    export function isHiveMetastoreSynchronized(obj: ReportItem): obj is ReportItem.HiveMetastoreSynchronized { return obj["type"] == "SYNCED_DATASET"; }
    export function isJobExecuted(obj: ReportItem): obj is ReportItem.JobExecuted { return obj["type"] == "JOB_EXECUTED"; }
    export function isMadeBundle(obj: ReportItem): obj is ReportItem.MadeBundle { return obj["type"] == "MADE_BUNDLE"; }
    export function isMadeLambdaPackage(obj: ReportItem): obj is ReportItem.MadeLambdaPackage { return obj["type"] == "MADE_LAMBDA_PACKAGE"; }
    export function isProducedByRunnable(obj: ReportItem): obj is ReportItem.ProducedByRunnable { return obj["type"] == "PRODUCED_BY_RUNNABLE"; }
    export function isPublishedBundle(obj: ReportItem): obj is ReportItem.PublishedBundle { return obj["type"] == "PUBLISHED_BUNDLE"; }
    export function isPublishedLambdaService(obj: ReportItem): obj is ReportItem.PublishedLambdaService { return obj["type"] == "PUBLISHED_LAMBDA_PACKAGE"; }
    export function isReloadSchema(obj: ReportItem): obj is ReportItem.ReloadSchema { return obj["type"] == "RELOAD_SCHEMA"; }
    export function isRestartCodeStudio(obj: ReportItem): obj is ReportItem.RestartCodeStudio { return obj["type"] == "RESTART_CODE_STUDIO"; }
    export function isRestartWebApp(obj: ReportItem): obj is ReportItem.RestartWebApp { return obj["type"] == "RESTART_WEBAPP"; }
    export function isScenarioDone(obj: ReportItem): obj is ReportItem.ScenarioDone { return obj["type"] == "SCENARIO_DONE"; }
    export function isSetUpCluster(obj: ReportItem): obj is ReportItem.SetUpCluster { return obj["type"] == "SET_UP_CLUSTER"; }
    export function isSnapshotRMarkdownReport(obj: ReportItem): obj is ReportItem.SnapshotRMarkdownReport { return obj["type"] == "CREATED_RMARKDOWN_EXPORT"; }
    export function isStartedCluster(obj: ReportItem): obj is ReportItem.StartedCluster { return obj["type"] == "STARTED_CLUSTER"; }
    export function isStartedContinuousActivity(obj: ReportItem): obj is ReportItem.StartedContinuousActivity { return obj["type"] == "STARTED_CONTINUOUS_ACTIVITY"; }
    export function isStepDone(obj: ReportItem): obj is ReportItem.StepDone { return obj["type"] == "STEP_DONE"; }
    export function isStopCodeStudio(obj: ReportItem): obj is ReportItem.StopCodeStudio { return obj["type"] == "STOP_CODE_STUDIO"; }
    export function isStoppedCluster(obj: ReportItem): obj is ReportItem.StoppedCluster { return obj["type"] == "STOPPED_CLUSTER"; }
    export function isStoppedContinuousActivity(obj: ReportItem): obj is ReportItem.StoppedContinuousActivity { return obj["type"] == "STOPPED_CONTINUOUS_ACTIVITY"; }
    export function isToreDownCluster(obj: ReportItem): obj is ReportItem.ToreDownCluster { return obj["type"] == "TORE_DOWN_CLUSTER"; }
    export function isTriggerFired(obj: ReportItem): obj is ReportItem.TriggerFired { return obj["type"] == "TRIGGER_FIRED"; }
    export function isUpdatedFromHive(obj: ReportItem): obj is ReportItem.UpdatedFromHive { return obj["type"] == "UPDATED_FROM_HIVE"; }
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$ActivatedPublishedPackage
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _ActivatedPublishedPackage extends _ReportItem {
        type: "ACTIVATED_PUBLISHED_PACKAGE";
        deployerType: string;
        publishedPackageId: string;
    }
    
    export type ActivatedPublishedPackage = _ActivatedPublishedPackage;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$BuiltDataset
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _BuiltDataset extends _ReportItem {
        type: "BUILT_DATASET";
    }
    
    export type BuiltDataset = _BuiltDataset;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$BuiltEvaluationStore
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _BuiltEvaluationStore extends _ReportItem {
        type: "BUILT_EVALUATION_STORE";
        evaluationId: string;
    }
    
    export type BuiltEvaluationStore = _BuiltEvaluationStore;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$BuiltManagedFolder
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _BuiltManagedFolder extends _ReportItem {
        type: "BUILT_FOLDER";
    }
    
    export type BuiltManagedFolder = _BuiltManagedFolder;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$BuiltReport
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _BuiltReport extends _ReportItem {
        type: "BUILT_REPORT";
        runAs: string;
    }
    
    export type BuiltReport = _BuiltReport;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$BuiltRetrievableKnowledge
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _BuiltRetrievableKnowledge extends _ReportItem {
        type: "BUILT_RETRIEVABLE_KNOWLEDGE";
    }
    
    export type BuiltRetrievableKnowledge = _BuiltRetrievableKnowledge;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$BuiltSavedModel
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _BuiltSavedModel extends _ReportItem {
        type: "BUILT_MODEL";
        versionId: string;
    }
    
    export type BuiltSavedModel = _BuiltSavedModel;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$BuiltStreamingEndpoint
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _BuiltStreamingEndpoint extends _ReportItem {
        type: "BUILT_STREAMING_ENDPOINT";
    }
    
    export type BuiltStreamingEndpoint = _BuiltStreamingEndpoint;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$CheckedDataset
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _CheckedDataset extends _ReportItem {
        type: "CHECKED_DATASET";
        hasError: boolean;
        hasWarning: boolean;
    }
    
    export type CheckedDataset = _CheckedDataset;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$CheckedFlowElementConsistency
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _CheckedFlowElementConsistency extends _ReportItem {
        type: "CHECKED_FLOW_ELEMENT_CONSISTENCY";
        hasError: boolean;
        hasWarning: boolean;
    }
    
    export type CheckedFlowElementConsistency = _CheckedFlowElementConsistency;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$CodeExecuted
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _CodeExecuted extends _ReportItem {
        type: "CODE_EXECUTED";
        errorMessage: string;
    }
    
    export type CodeExecuted = _CodeExecuted;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$ComputedMetrics
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _ComputedMetrics extends _ReportItem {
        type: "COMPUTED_METRICS";
        metricCount: number;
    }
    
    export type ComputedMetrics = _ComputedMetrics;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$CreatedDashboardExport
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _CreatedDashboardExport extends _ReportItem {
        type: "CREATED_DASHBOARD_EXPORT";
    }
    
    export type CreatedDashboardExport = _CreatedDashboardExport;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$CreatedFlowDocumentExport
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _CreatedFlowDocumentExport extends _ReportItem {
        type: "CREATED_FLOW_DOCUMENTATION_EXPORT";
    }
    
    export type CreatedFlowDocumentExport = _CreatedFlowDocumentExport;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$CreatedJupyterExport
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _CreatedJupyterExport extends _ReportItem {
        type: "CREATED_JUPYTER_EXPORT";
        executedNotebook: boolean;
    }
    
    export type CreatedJupyterExport = _CreatedJupyterExport;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$CreatedModelDocumentExport
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _CreatedModelDocumentExport extends _ReportItem {
        type: "CREATED_MODEL_DOCUMENTATION_EXPORT";
    }
    
    export type CreatedModelDocumentExport = _CreatedModelDocumentExport;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$CreatedWikiArticleExport
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _CreatedWikiArticleExport extends _ReportItem {
        type: "CREATED_WIKI_ARTICLE_EXPORT";
    }
    
    export type CreatedWikiArticleExport = _CreatedWikiArticleExport;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$CreatedWikiExport
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _CreatedWikiExport extends _ReportItem {
        type: "CREATED_WIKI_EXPORT";
    }
    
    export type CreatedWikiExport = _CreatedWikiExport;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$HiveMetastoreSynchronized
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _HiveMetastoreSynchronized extends _ReportItem {
        type: "SYNCED_DATASET";
    }
    
    export type HiveMetastoreSynchronized = _HiveMetastoreSynchronized;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$JobExecuted
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _JobExecuted extends _ReportItem {
        type: "JOB_EXECUTED";
        jobId: string;
    }
    
    export type JobExecuted = _JobExecuted;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$MadeBundle
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _MadeBundle extends _ReportItem {
        type: "MADE_BUNDLE";
        bundleId: string;
    }
    
    export type MadeBundle = _MadeBundle;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$MadeLambdaPackage
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _MadeLambdaPackage extends _ReportItem {
        type: "MADE_LAMBDA_PACKAGE";
        packageId: string;
    }
    
    export type MadeLambdaPackage = _MadeLambdaPackage;
    
    /** 
     * Translated from enum com.dataiku.dip.scheduler.reports.ReportItem$Outcome
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export enum Outcome {
        SUCCESS = "SUCCESS",
        WARNING = "WARNING",
        FAILED = "FAILED",
        ABORTED = "ABORTED",
    }
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$ProducedByRunnable
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _ProducedByRunnable extends _ReportItem {
        type: "PRODUCED_BY_RUNNABLE";
        resultLabel: string;
        resultType: DSSRunnableResult.ResultType;
    }
    
    export type ProducedByRunnable = _ProducedByRunnable;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$PublishedBundle
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _PublishedBundle extends _ReportItem {
        type: "PUBLISHED_BUNDLE";
        bundleId: string;
    }
    
    export type PublishedBundle = _PublishedBundle;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$PublishedLambdaService
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _PublishedLambdaService extends _ReportItem {
        type: "PUBLISHED_LAMBDA_PACKAGE";
        packageId: string;
    }
    
    export type PublishedLambdaService = _PublishedLambdaService;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$ReloadSchema
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _ReloadSchema extends _ReportItem {
        type: "RELOAD_SCHEMA";
        detectedButNotInSchema: number;
        inSchemaButNotDetected: number;
    }
    
    export type ReloadSchema = _ReloadSchema;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$RestartCodeStudio
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _RestartCodeStudio extends _ReportItem {
        type: "RESTART_CODE_STUDIO";
        runAs: string;
    }
    
    export type RestartCodeStudio = _RestartCodeStudio;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$RestartWebApp
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _RestartWebApp extends _ReportItem {
        type: "RESTART_WEBAPP";
        runAs: string;
    }
    
    export type RestartWebApp = _RestartWebApp;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$ScenarioDone
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _ScenarioDone extends _ReportItem {
        type: "SCENARIO_DONE";
        abortMessage: string;
        login: string;
        runId: string;
        trigger: string;
    }
    
    export type ScenarioDone = _ScenarioDone;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$SetUpCluster
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _SetUpCluster extends _ReportItem {
        type: "SET_UP_CLUSTER";
    }
    
    export type SetUpCluster = _SetUpCluster;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$SnapshotRMarkdownReport
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _SnapshotRMarkdownReport extends _ReportItem {
        type: "CREATED_RMARKDOWN_EXPORT";
        execute: boolean;
    }
    
    export type SnapshotRMarkdownReport = _SnapshotRMarkdownReport;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$StartedCluster
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _StartedCluster extends _ReportItem {
        type: "STARTED_CLUSTER";
    }
    
    export type StartedCluster = _StartedCluster;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$StartedContinuousActivity
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _StartedContinuousActivity extends _ReportItem {
        type: "STARTED_CONTINUOUS_ACTIVITY";
    }
    
    export type StartedContinuousActivity = _StartedContinuousActivity;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$StepDone
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _StepDone extends _ReportItem {
        type: "STEP_DONE";
    }
    
    export type StepDone = _StepDone;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$StopCodeStudio
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _StopCodeStudio extends _ReportItem {
        type: "STOP_CODE_STUDIO";
        runAs: string;
    }
    
    export type StopCodeStudio = _StopCodeStudio;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$StoppedCluster
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _StoppedCluster extends _ReportItem {
        type: "STOPPED_CLUSTER";
    }
    
    export type StoppedCluster = _StoppedCluster;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$StoppedContinuousActivity
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _StoppedContinuousActivity extends _ReportItem {
        type: "STOPPED_CONTINUOUS_ACTIVITY";
    }
    
    export type StoppedContinuousActivity = _StoppedContinuousActivity;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$ToreDownCluster
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _ToreDownCluster extends _ReportItem {
        type: "TORE_DOWN_CLUSTER";
    }
    
    export type ToreDownCluster = _ToreDownCluster;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$TriggerFired
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _TriggerFired extends _ReportItem {
        type: "TRIGGER_FIRED";
    }
    
    export type TriggerFired = _TriggerFired;
    
    /** 
     * Translated from class com.dataiku.dip.scheduler.reports.ReportItem$UpdatedFromHive
     * Via: com.dataiku.dip.scheduler.reports.ReportItem
     */
    export interface _UpdatedFromHive extends _ReportItem {
        type: "UPDATED_FROM_HIVE";
    }
    
    export type UpdatedFromHive = _UpdatedFromHive;

    export namespace ActivatedPublishedPackage {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$ActivatedPublishedPackage in PolyJSON mappings */
        export const type = "ACTIVATED_PUBLISHED_PACKAGE";
    }

    export namespace BuiltDataset {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$BuiltDataset in PolyJSON mappings */
        export const type = "BUILT_DATASET";
    }

    export namespace BuiltEvaluationStore {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$BuiltEvaluationStore in PolyJSON mappings */
        export const type = "BUILT_EVALUATION_STORE";
    }

    export namespace BuiltManagedFolder {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$BuiltManagedFolder in PolyJSON mappings */
        export const type = "BUILT_FOLDER";
    }

    export namespace BuiltReport {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$BuiltReport in PolyJSON mappings */
        export const type = "BUILT_REPORT";
    }

    export namespace BuiltRetrievableKnowledge {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$BuiltRetrievableKnowledge in PolyJSON mappings */
        export const type = "BUILT_RETRIEVABLE_KNOWLEDGE";
    }

    export namespace BuiltSavedModel {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$BuiltSavedModel in PolyJSON mappings */
        export const type = "BUILT_MODEL";
    }

    export namespace BuiltStreamingEndpoint {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$BuiltStreamingEndpoint in PolyJSON mappings */
        export const type = "BUILT_STREAMING_ENDPOINT";
    }

    export namespace CheckedDataset {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$CheckedDataset in PolyJSON mappings */
        export const type = "CHECKED_DATASET";
    }

    export namespace CheckedFlowElementConsistency {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$CheckedFlowElementConsistency in PolyJSON mappings */
        export const type = "CHECKED_FLOW_ELEMENT_CONSISTENCY";
    }

    export namespace CodeExecuted {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$CodeExecuted in PolyJSON mappings */
        export const type = "CODE_EXECUTED";
    }

    export namespace ComputedMetrics {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$ComputedMetrics in PolyJSON mappings */
        export const type = "COMPUTED_METRICS";
    }

    export namespace CreatedDashboardExport {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$CreatedDashboardExport in PolyJSON mappings */
        export const type = "CREATED_DASHBOARD_EXPORT";
    }

    export namespace CreatedFlowDocumentExport {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$CreatedFlowDocumentExport in PolyJSON mappings */
        export const type = "CREATED_FLOW_DOCUMENTATION_EXPORT";
    }

    export namespace CreatedJupyterExport {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$CreatedJupyterExport in PolyJSON mappings */
        export const type = "CREATED_JUPYTER_EXPORT";
    }

    export namespace CreatedModelDocumentExport {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$CreatedModelDocumentExport in PolyJSON mappings */
        export const type = "CREATED_MODEL_DOCUMENTATION_EXPORT";
    }

    export namespace CreatedWikiArticleExport {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$CreatedWikiArticleExport in PolyJSON mappings */
        export const type = "CREATED_WIKI_ARTICLE_EXPORT";
    }

    export namespace CreatedWikiExport {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$CreatedWikiExport in PolyJSON mappings */
        export const type = "CREATED_WIKI_EXPORT";
    }

    export namespace HiveMetastoreSynchronized {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$HiveMetastoreSynchronized in PolyJSON mappings */
        export const type = "SYNCED_DATASET";
    }

    export namespace JobExecuted {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$JobExecuted in PolyJSON mappings */
        export const type = "JOB_EXECUTED";
    }

    export namespace MadeBundle {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$MadeBundle in PolyJSON mappings */
        export const type = "MADE_BUNDLE";
    }

    export namespace MadeLambdaPackage {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$MadeLambdaPackage in PolyJSON mappings */
        export const type = "MADE_LAMBDA_PACKAGE";
    }

    export namespace ProducedByRunnable {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$ProducedByRunnable in PolyJSON mappings */
        export const type = "PRODUCED_BY_RUNNABLE";
    }

    export namespace PublishedBundle {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$PublishedBundle in PolyJSON mappings */
        export const type = "PUBLISHED_BUNDLE";
    }

    export namespace PublishedLambdaService {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$PublishedLambdaService in PolyJSON mappings */
        export const type = "PUBLISHED_LAMBDA_PACKAGE";
    }

    export namespace ReloadSchema {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$ReloadSchema in PolyJSON mappings */
        export const type = "RELOAD_SCHEMA";
    }

    export namespace RestartCodeStudio {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$RestartCodeStudio in PolyJSON mappings */
        export const type = "RESTART_CODE_STUDIO";
    }

    export namespace RestartWebApp {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$RestartWebApp in PolyJSON mappings */
        export const type = "RESTART_WEBAPP";
    }

    export namespace ScenarioDone {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$ScenarioDone in PolyJSON mappings */
        export const type = "SCENARIO_DONE";
    }

    export namespace SetUpCluster {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$SetUpCluster in PolyJSON mappings */
        export const type = "SET_UP_CLUSTER";
    }

    export namespace SnapshotRMarkdownReport {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$SnapshotRMarkdownReport in PolyJSON mappings */
        export const type = "CREATED_RMARKDOWN_EXPORT";
    }

    export namespace StartedCluster {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$StartedCluster in PolyJSON mappings */
        export const type = "STARTED_CLUSTER";
    }

    export namespace StartedContinuousActivity {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$StartedContinuousActivity in PolyJSON mappings */
        export const type = "STARTED_CONTINUOUS_ACTIVITY";
    }

    export namespace StepDone {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$StepDone in PolyJSON mappings */
        export const type = "STEP_DONE";
    }

    export namespace StopCodeStudio {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$StopCodeStudio in PolyJSON mappings */
        export const type = "STOP_CODE_STUDIO";
    }

    export namespace StoppedCluster {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$StoppedCluster in PolyJSON mappings */
        export const type = "STOPPED_CLUSTER";
    }

    export namespace StoppedContinuousActivity {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$StoppedContinuousActivity in PolyJSON mappings */
        export const type = "STOPPED_CONTINUOUS_ACTIVITY";
    }

    export namespace ToreDownCluster {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$ToreDownCluster in PolyJSON mappings */
        export const type = "TORE_DOWN_CLUSTER";
    }

    export namespace TriggerFired {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$TriggerFired in PolyJSON mappings */
        export const type = "TRIGGER_FIRED";
    }

    export namespace UpdatedFromHive {
        /** Type of class com.dataiku.dip.scheduler.reports.ReportItem$UpdatedFromHive in PolyJSON mappings */
        export const type = "UPDATED_FROM_HIVE";
    }
}