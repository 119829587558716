import type { Distribution2D } from './distribution2-d';
import type { _Distribution2D } from './distribution2-d';

/** 
 * Translated from class com.dataiku.dip.eda.compute.distributions.JointNormal
 * Via: UIModel annotation in the class hierarchy
 */
export interface _JointNormal extends _Distribution2D {
    type: "joint_normal";
}

export type JointNormal = _JointNormal;

export namespace JointNormal {
    /** Type of class com.dataiku.dip.eda.compute.distributions.JointNormal in PolyJSON mappings */
    export const type = "joint_normal";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.distributions.JointNormal$FittedJointNormal
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FittedJointNormal extends Distribution2D._FittedDistribution2D {
        type: "joint_normal";
        covs: number[][];
        means: number[];
    }
    
    export type FittedJointNormal = _FittedJointNormal;

    export namespace FittedJointNormal {
        /** Type of class com.dataiku.dip.eda.compute.distributions.JointNormal$FittedJointNormal in PolyJSON mappings */
        export const type = "joint_normal";
    }
}