<div class="recipe-settings-section1 eda-recipe-settings-section">
    <h1 class="recipe-settings-section1-title">
        {{sectionTitle}}
    </h1>

    <div class="recipe-settings-section2">
        <div *ngIf="isInvalid$ | async"
            class="alert alert-warning"
        >
            <i class="icon-warning-sign icon-warning-color mright4"></i>
            Caution: the recipe settings are invalid.
            Running this recipe will likely fail or produce unexpected results.
        </div>

        <form [formGroup]="configForm"
            class="dkuform-horizontal"
        >
            <div class="control-group">
                <label class="control-label">
                    Confidence level

                    <i *ngIf="configForm.controls.confidenceLevel.invalid"
                        class="icon-warning-sign icon-warning-color mleft8"
                        matTooltip="The confidence level must belong to [0.5, 1)"
                        matTooltipPosition="below"
                    ></i>
                </label>

                <div class="controls">
                    <input formControlName="confidenceLevel"
                        type="number"
                        min="0.5" [max]="1-(1e-15)" step="0.01"
                    />

                    <div class="help-inline">
                        Used to determine the confidence level of tests
                    </div>
                </div>
            </div>

            <div class="control-group">
                <div class="control-label">
                    Add computation timestamp
                </div>

                <div class="controls">
                    <input type="checkbox"
                        formControlName="addComputationTimestamp"
                    />

                    <div class="help-inline">
                        Add a timestamp of when the recipe was executed
                    </div>
                </div>
            </div>
        </form>

        <div class="split-by-section">
            <h2 class="recipe-settings-section2-title">
                Split by
                <i class="icon-info-sign mleft4"
                    matTooltip="Used to split the statistical test on multiple populations"
                    matTooltipPosition="right"
                ></i>
            </h2>

            <recipe-split-by-editor
                *ngIf="splitByUiData$ | async as uiData"
                [columns]="uiData.columns"
                [splitBy]="uiData.splitBy"
                (splitByChange)="updateSplitBy($event)"
                (validityChange)="updateSplitByValidity($event)"
            ></recipe-split-by-editor>
        </div>

        <div class="stats-settings-section" *ngIf="statsUiData$ | async as uiData">
            <ttest-1samp-recipe-settings 
                *ngIf="isOneSampleTTest(uiData.stats)"
                [columns]="uiData.columns"
                [params]="uiData.stats"
                (paramsChange)="updateTestStat($event)"
                (validityChange)="updateTestStatValidity($event)"
            >
            </ttest-1samp-recipe-settings>
            <sign-test-1samp-recipe-settings 
                *ngIf="isOneSampleSignTest(uiData.stats)"
                [columns]="uiData.columns"
                [params]="uiData.stats"
                (paramsChange)="updateTestStat($event)"
                (validityChange)="updateTestStatValidity($event)"
            >
            </sign-test-1samp-recipe-settings>
            <shapiro-normality-test-recipe-settings 
                *ngIf="isOneSampleShapiroTest(uiData.stats)"
                [columns]="uiData.columns"
                [params]="uiData.stats"
                (paramsChange)="updateTestStat($event)"
                (validityChange)="updateTestStatValidity($event)"
            >
            </shapiro-normality-test-recipe-settings>
        </div>
    </div>
</div>
