import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.Skewness
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Skewness extends Computation._UnivariateComputation {
    type: "skewness";
}

export type Skewness = _Skewness;

export namespace Skewness {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.Skewness in PolyJSON mappings */
    export const type = "skewness";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.Skewness$SkewnessResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _SkewnessResult extends _AvailableResult {
        type: "skewness";
        value: number;
    }
    
    export type SkewnessResult = _SkewnessResult;

    export namespace SkewnessResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.Skewness$SkewnessResult in PolyJSON mappings */
        export const type = "skewness";
    }
}