import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { Grouping } from './../../grouping/grouping';
import type { _AvailableResult } from './../available-result';
import type { _Grouping } from './../../grouping/grouping';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.OneWayAnova
 * Via: UIModel annotation in the class hierarchy
 */
export interface _OneWayAnova extends Computation._UnivariateComputation {
    type: "one_way_anova";
    grouping: Grouping;
}

export type OneWayAnova = _OneWayAnova;

export namespace OneWayAnova {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.OneWayAnova in PolyJSON mappings */
    export const type = "one_way_anova";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.OneWayAnova$OneWayAnovaResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _OneWayAnovaResult extends _AvailableResult {
        type: "one_way_anova";
        pvalue: number;
        statistic: number;
    }
    
    export type OneWayAnovaResult = _OneWayAnovaResult;

    export namespace OneWayAnovaResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.OneWayAnova$OneWayAnovaResult in PolyJSON mappings */
        export const type = "one_way_anova";
    }
}