import { Injectable, Inject } from '@angular/core';
import { Observable, defer, from, identity } from 'rxjs';
import { map } from 'rxjs/operators';

import { DkuHttpService, HttpVerb } from 'dku-frontend-core';

import { EnrichedPromise } from '@core/dataiku-api/utils/enriched-promise';
import { realAny, Assert } from 'dku-frontend-core';
import { upgradePromiseCatchError } from '@core/dataiku-api/api-error';
import { WaitingService } from '@core/overlays/waiting.service';

export interface BackendResponse<T> {
    data: T;
    config: HttpConfig;
    headers: any;
}

export interface HttpConfig {
    url: string;
    method: HttpVerb;
    headers: any;
    params: any;
    paramSerializer: Function;
    transformRequest: Function;
    xsrfCookieName: string;
}

/**
 * Implementation of DkuHttpService which falls back into AngularJS 'APIXHRService'
 */
@Injectable({ providedIn: 'root' })
export class DkuLegacyHttpService extends DkuHttpService {
    private API_PATH = '/dip/api';

    constructor(@Inject('APIXHRService') private APIXHRService: any, private waitingService: WaitingService) {
        super();
    }

    public request<T>(method: HttpVerb, path: string, params?: object, withSpinner: boolean = true): Observable<T> {
        // defer() ensures we get a new promise at every subscription
        // ('from(promise)' would not work because the promise would not re-evaluated)
        return defer(() => {
            // Rely on AngularJS layer
            const legacyPromise: EnrichedPromise<BackendResponse<T>> =
                this.APIXHRService(method, this.API_PATH + path, this.stringifyParams(params || {}), "nospinner");
            // Upgrade promise to play well with ZoneJS
            const upgradedPromise = upgradePromiseCatchError(legacyPromise);
            // Convert into observable
            return from(upgradedPromise).pipe(
                withSpinner ? this.waitingService.bindSpinner() : identity,
                map(resp => resp.data)
            );
        });
    }

    private stringifyParams(params: realAny): object {
        Assert.trueish(params, 'Empty params object in private API'); // individual params may be nullish but we do want params object
        const ret: realAny = {};
        Object.keys(params).forEach(key => {
            const value = params[key];
            if (typeof value === 'object') {
                ret[key] = JSON.stringify(value);
            } else {
                ret[key] = value;
            }
        });
        return ret;
    }
}
