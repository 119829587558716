import type { EventServerTargetSettings } from './event-server-target-settings';
import type { KafkaTargetSettings } from './kafka-target-settings';
import type { Log4JTargetSettings } from './log4-jtarget-settings';
import type { _EventServerTargetSettings } from './event-server-target-settings';
import type { _KafkaTargetSettings } from './kafka-target-settings';
import type { _Log4JTargetSettings } from './log4-jtarget-settings';

/** 
 * Translated from class com.dataiku.dip.security.audit.model.AuditTrailTargetSettings
 * Via: UIModel annotation in the class hierarchy
 */
export interface _AuditTrailTargetSettings {
    routingKeys: string[];
    routingKeysFiltering: AuditTrailTargetSettings.RoutingKeyFiltering;
    topics: string[];
    topicsFiltering: AuditTrailTargetSettings.TopicsFiltering;
}

/** List of all concrete subclasses of com.dataiku.dip.security.audit.model.AuditTrailTargetSettings */
export type AuditTrailTargetSettings =
    | EventServerTargetSettings
    | KafkaTargetSettings
    | Log4JTargetSettings;

export namespace AuditTrailTargetSettings {
    /** Types of class com.dataiku.dip.security.audit.model.AuditTrailTargetSettings in PolyJSON mappings */
    export const type = ["EVENT_SERVER", "KAFKA", "LOG4J", ] as const;
    
    export function isAuditTrailTargetSettings(obj: AuditTrailTargetSettings): obj is AuditTrailTargetSettings { return ["EVENT_SERVER", "KAFKA", "LOG4J", ].includes(obj["type"]); }
    export function isEventServerTargetSettings(obj: AuditTrailTargetSettings): obj is EventServerTargetSettings { return obj["type"] == "EVENT_SERVER"; }
    export function isKafkaTargetSettings(obj: AuditTrailTargetSettings): obj is KafkaTargetSettings { return obj["type"] == "KAFKA"; }
    export function isLog4JTargetSettings(obj: AuditTrailTargetSettings): obj is Log4JTargetSettings { return obj["type"] == "LOG4J"; }
    
    /** 
     * Translated from enum com.dataiku.dip.security.audit.model.AuditTrailTargetSettings$RoutingKeyFiltering
     * Via: com.dataiku.dip.security.audit.model.AuditTrailTargetSettings
     */
    export enum RoutingKeyFiltering {
        ALL = "ALL",
        SELECTED = "SELECTED",
    }
    /** 
     * Translated from enum com.dataiku.dip.security.audit.model.AuditTrailTargetSettings$TopicsFiltering
     * Via: com.dataiku.dip.security.audit.model.AuditTrailTargetSettings
     */
    export enum TopicsFiltering {
        ALL = "ALL",
        SELECTED = "SELECTED",
    }
}