import type { CorrelationFunction } from './correlation-function';
import type { _CorrelationFunction } from './correlation-function';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.PACF
 * Via: UIModel annotation in the class hierarchy
 */
export interface _PACF extends _CorrelationFunction {
    type: "pacf";
    alpha: number;
    method: PACF.Method;
    nLags?: number | null;
}

export type PACF = _PACF;

export namespace PACF {
    /** Type of class com.dataiku.dip.eda.compute.computations.timeseries.PACF in PolyJSON mappings */
    export const type = "pacf";
    
    /** 
     * Translated from enum com.dataiku.dip.eda.compute.computations.timeseries.PACF$Method
     * Via: com.dataiku.dip.eda.worksheets.cards.ACFPlotCard
     */
    export enum Method {
        YULE_WALKER = "YULE_WALKER",
        OLS = "OLS",
        OLS_UNBIASED = "OLS_UNBIASED",
        LEVINSON_DURBIN = "LEVINSON_DURBIN",
    }
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.PACF$PACFResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _PACFResult extends CorrelationFunction._CorrelationFunctionResult {
        type: "pacf";
    }
    
    export type PACFResult = _PACFResult;

    export namespace PACFResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.timeseries.PACF$PACFResult in PolyJSON mappings */
        export const type = "pacf";
    }
}