import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.SignTest1Samp
 * Via: UIModel annotation in the class hierarchy
 */
export interface _SignTest1Samp extends Computation._UnivariateComputation {
    type: "sign_test_1samp";
    hypothesizedMedian: number;
}

export type SignTest1Samp = _SignTest1Samp;

export namespace SignTest1Samp {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.SignTest1Samp in PolyJSON mappings */
    export const type = "sign_test_1samp";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.SignTest1Samp$SignTest1SampResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _SignTest1SampResult extends _AvailableResult {
        type: "sign_test_1samp";
        nbEqual: number;
        nbLarger: number;
        nbSmaller: number;
        pvalue: number;
        pvalueAltGt: number;
        pvalueAltLt: number;
    }
    
    export type SignTest1SampResult = _SignTest1SampResult;

    export namespace SignTest1SampResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.SignTest1Samp$SignTest1SampResult in PolyJSON mappings */
        export const type = "sign_test_1samp";
    }
}