import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual, isNil, omit, pick } from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime, pairwise, startWith } from 'rxjs/operators';
import { D3V3ZoomControlInstance } from '../../../models';
import { ChartZoomControlStoreService } from '../../../services';

@UntilDestroy()
@Component({
    selector: 'zoom-options-form',
    templateUrl: './zoom-options-form.component.html',
    styleUrls: ['../forms.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZoomOptionsFormComponent implements OnInit, OnDestroy {
    @Output() zoomOptionsChange = new EventEmitter<Partial<ChartDef.ZoomOptions>>();

    public zoomOptionsForm: UntypedFormGroup = new UntypedFormGroup({
        persisted: new FormControl<boolean>(true),
        enabled: new FormControl<boolean>(true),
        scale: new FormControl<number | null>(null),
        translate: new FormControl<[number, number] | null>(null),
        xRange: new FormControl<[number, number] | null>(null),
        yRange: new FormControl<[number, number] | null>(null)
    });

    private subscription: Subscription;
    private zoomControlInstance: D3V3ZoomControlInstance | null = null;

    constructor(private chartZoomControlStore: ChartZoomControlStoreService) {}

    @Input()
    set zoomControlInstanceId(value: string | undefined) {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        if (isNil(value)) {
            this.zoomControlInstance = null;
        } else {
            this.zoomControlInstance = this.chartZoomControlStore.get(value) as D3V3ZoomControlInstance;
            this.subscription = this.zoomControlInstance.getZoomOptions()
                .subscribe(zoomOptions => {
                    if (!isNil(zoomOptions)) {
                        this.zoomOptionsForm.patchValue(zoomOptions);
                    }
                });
        }
    }

    @Input()
    set zoomOptions(value: ChartDef.ZoomOptions) {
        if (!isEqual(value, this.zoomOptionsForm.getRawValue())) {
            this.zoomOptionsForm.patchValue(value);
        }
    }

    ngOnInit(): void {
        this.zoomOptionsForm.valueChanges
            .pipe(untilDestroyed(this), startWith(null), pairwise(), debounceTime(10))
            .subscribe(([prevValue, nextValue]: Array<ChartDef.ZoomOptions>) => {
                if (!isEqual(prevValue, nextValue)) {
                    const updatedZoomOptions = { ...nextValue };

                    if (!updatedZoomOptions.enabled && !updatedZoomOptions.persisted) {
                        updatedZoomOptions.scale = 1;
                        updatedZoomOptions.translate = [0, 0];
                    }

                    if (this.zoomControlInstance) {
                        this.zoomControlInstance.setZoomOptions(updatedZoomOptions);
                    }

                    const formControlNames = ['persisted', 'enabled'];
                    const hasUpdatedFormControlsOnly = isEqual(omit(prevValue, formControlNames), omit(nextValue, formControlNames)) && !isEqual(pick(prevValue, formControlNames), pick(nextValue, formControlNames));

                    if (updatedZoomOptions.persisted || hasUpdatedFormControlsOnly) {
                        this.zoomOptionsChange.emit({ ...updatedZoomOptions });
                    }
                }
            });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
