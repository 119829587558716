import type { APIError } from 'dku-frontend-core/generated-sources/app-platform/src/main/java/com/dataiku/common/server/apierror';
import type { FutureProgressState } from 'dku-frontend-core/generated-sources/app-platform/src/main/java/com/dataiku/dip/futures/future-progress-state';
import type { PredictionModelingParams } from './prediction/prediction-modeling-params';
import type { _APIError } from 'dku-frontend-core/generated-sources/app-platform/src/main/java/com/dataiku/common/server/apierror';
import type { _FutureProgressState } from 'dku-frontend-core/generated-sources/app-platform/src/main/java/com/dataiku/dip/futures/future-progress-state';

/** 
 * Translated from class com.dataiku.dip.analysis.model.ModelTrainInfo
 * Via: com.dataiku.dip.analysis.model.ModelDetailsBase
 */
export interface _ModelTrainInfo {
    endTime: number;
    failure: APIError;
    fullRows: number;
    hyperparamsSearchTime: number;
    kfold: boolean;
    modelId: string;
    postSearchDescription: ModelTrainInfo.PostSearchDescription;
    preSearchDescription: ModelTrainInfo.PreSearchDescription;
    preprocessingTime: number;
    progress: ModelTrainInfo.TrainProgress;
    pythonVersion: string;
    resumed: boolean;
    sampleWeightsEnabled: boolean;
    sampleWeightsVariable: string;
    startTime: number;
    state: ModelTrainInfo.ModelTrainState;
    testRows: number;
    timeOrderingEnabled: boolean;
    timeVariable: string;
    trainRows: number;
    trainingTime: number;
}

export type ModelTrainInfo = _ModelTrainInfo;

export namespace ModelTrainInfo {
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.ModelTrainInfo$ModelTrainState
     * Via: com.dataiku.dip.analysis.model.ModelTrainInfo
     */
    export enum ModelTrainState {
        PENDING = "PENDING",
        RUNNING = "RUNNING",
        DONE = "DONE",
        FAILED = "FAILED",
        ABORTED = "ABORTED",
    }
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ModelTrainInfo$PostSearchDescription
     * Via: com.dataiku.dip.analysis.model.ModelTrainInfo
     */
    export interface _PostSearchDescription {
        importantParams: ModelTrainInfo.SearchParam[];
    }
    
    export type PostSearchDescription = _PostSearchDescription;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ModelTrainInfo$PreSearchDescription
     * Via: com.dataiku.dip.analysis.model.ModelTrainInfo
     */
    export interface _PreSearchDescription {
        gridLength: number;
        importantParams: ModelTrainInfo.SearchParam[];
        searchStrategy: PredictionModelingParams.GridSearchParams.Strategy;
    }
    
    export type PreSearchDescription = _PreSearchDescription;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ModelTrainInfo$SearchParam
     * Via: com.dataiku.dip.analysis.model.ModelTrainInfo$PostSearchDescription
     */
    export interface _SearchParam {
        cnt: number;
        id: string;
        max: string;
        min: string;
        name: string;
        val: string;
        vals: string[];
    }
    
    export type SearchParam = _SearchParam;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.ModelTrainInfo$TrainProgress
     * Via: com.dataiku.dip.analysis.model.ModelTrainInfo
     */
    export interface _TrainProgress {
        stack: FutureProgressState[];
        top_level_done: ModelTrainInfo.TrainProgress.TopLevelDone[];
        top_level_todo: string[];
    }
    
    export type TrainProgress = _TrainProgress;

    export namespace TrainProgress {
        /** 
         * Translated from class com.dataiku.dip.analysis.model.ModelTrainInfo$TrainProgress$TopLevelDone
         * Via: com.dataiku.dip.analysis.model.ModelTrainInfo$TrainProgress
         */
        export interface _TopLevelDone {
            name: string;
            time: number;
        }
        
        export type TopLevelDone = _TopLevelDone;
    }
}