import type { UnitRootTest } from './unit-root-test';
import type { _UnitRootTest } from './unit-root-test';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestADF
 * Via: UIModel annotation in the class hierarchy
 */
export interface _UnitRootTestADF extends _UnitRootTest {
    type: "unit_root_adf";
    nLags?: number | null;
    regressionMode: UnitRootTestADF.RegressionMode;
}

export type UnitRootTestADF = _UnitRootTestADF;

export namespace UnitRootTestADF {
    /** Type of class com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestADF in PolyJSON mappings */
    export const type = "unit_root_adf";
    
    /** 
     * Translated from enum com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestADF$RegressionMode
     * Via: com.dataiku.dip.eda.worksheets.cards.UnitRootTestADFCard
     */
    export enum RegressionMode {
        CONSTANT_ONLY = "CONSTANT_ONLY",
        CONSTANT_WITH_LINEAR_TREND = "CONSTANT_WITH_LINEAR_TREND",
        CONSTANT_WITH_LINEAR_QUADRATIC_TREND = "CONSTANT_WITH_LINEAR_QUADRATIC_TREND",
        NO_CONSTANT_NO_TREND = "NO_CONSTANT_NO_TREND",
    }
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestADF$UnitRootTestADFResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _UnitRootTestADFResult extends UnitRootTest._UnitRootTestResult {
        type: "unit_root_adf";
    }
    
    export type UnitRootTestADFResult = _UnitRootTestADFResult;

    export namespace UnitRootTestADFResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestADF$UnitRootTestADFResult in PolyJSON mappings */
        export const type = "unit_root_adf";
    }
}