import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _AvailableResult } from './../available-result';
import type { _ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep
 * Via: UIModel annotation in the class hierarchy
 */
export interface _MatchTimeStep extends Computation._UnivariateComputation {
    type: "match_time_step";
    maxSteps: number;
}

export type MatchTimeStep = _MatchTimeStep;

export namespace MatchTimeStep {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep in PolyJSON mappings */
    export const type = "match_time_step";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep$MatchTimeStepResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _MatchTimeStepResult extends _AvailableResult {
        type: "match_time_step";
        nSteps: number;
        steps: MatchTimeStep.MatchTimeStepResult.Step[];
    }
    
    export type MatchTimeStepResult = _MatchTimeStepResult;

    export namespace MatchTimeStepResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep$MatchTimeStepResult in PolyJSON mappings */
        export const type = "match_time_step";
        
        /** 
         * Translated from class com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep$MatchTimeStepResult$BusinessDaysStep
         * Via: com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep$MatchTimeStepResult$Step
         */
        export interface _BusinessDaysStep extends MatchTimeStep.MatchTimeStepResult._Step {
            type: "business_days_step";
            weekMask: string;
        }
        
        export type BusinessDaysStep = _BusinessDaysStep;
        
        /** 
         * Translated from class com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep$MatchTimeStepResult$IsoDurationStep
         * Via: com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep$MatchTimeStepResult$Step
         */
        export interface _IsoDurationStep extends MatchTimeStep.MatchTimeStepResult._Step {
            type: "iso_duration_step";
            duration: string;
        }
        
        export type IsoDurationStep = _IsoDurationStep;
        
        /** 
         * Translated from class com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep$MatchTimeStepResult$Step
         * Via: com.dataiku.dip.eda.worksheets.cards.fragments.TimeInfoFragment$StepInfo
         */
        export interface _Step extends _ImmutableValueObject {
        }
        
        /** List of all concrete subclasses of com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep$MatchTimeStepResult$Step */
        export type Step =
            | MatchTimeStep.MatchTimeStepResult.BusinessDaysStep
            | MatchTimeStep.MatchTimeStepResult.IsoDurationStep;

        export namespace BusinessDaysStep {
            /** Type of class com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep$MatchTimeStepResult$BusinessDaysStep in PolyJSON mappings */
            export const type = "business_days_step";
        }

        export namespace IsoDurationStep {
            /** Type of class com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep$MatchTimeStepResult$IsoDurationStep in PolyJSON mappings */
            export const type = "iso_duration_step";
        }

        export namespace Step {
            /** Types of class com.dataiku.dip.eda.compute.computations.univariate.MatchTimeStep$MatchTimeStepResult$Step in PolyJSON mappings */
            export const type = ["business_days_step", "iso_duration_step", ] as const;
            
            export function isBusinessDaysStep(obj: MatchTimeStep.MatchTimeStepResult.Step): obj is MatchTimeStep.MatchTimeStepResult.BusinessDaysStep { return obj["type"] == "business_days_step"; }
            export function isIsoDurationStep(obj: MatchTimeStep.MatchTimeStepResult.Step): obj is MatchTimeStep.MatchTimeStepResult.IsoDurationStep { return obj["type"] == "iso_duration_step"; }
            export function isStep(obj: MatchTimeStep.MatchTimeStepResult.Step): obj is MatchTimeStep.MatchTimeStepResult.Step { return ["business_days_step", "iso_duration_step", ].includes(obj["type"]); }
        }
    }
}