import type { AbstractCardContainer } from './common/abstract-card-container';
import type { CardWithConfidenceInterval } from './common/card-with-confidence-interval';
import type { CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { Filter } from './../../compute/filtering/filter';
import type { _AbstractCardContainer } from './common/abstract-card-container';
import type { _CardWithConfidenceInterval } from './common/card-with-confidence-interval';
import type { _CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { _Filter } from './../../compute/filtering/filter';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.WorksheetRootCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _WorksheetRootCard extends _AbstractCardContainer, _CardWithConfidenceInterval, _CardWithConfidenceLevel {
    type: "worksheet_root";
    confidenceLevel: number;
    highlightFilter?: Filter | null;
    showConfidenceInterval: boolean;
}

export type WorksheetRootCard = _WorksheetRootCard;

export namespace WorksheetRootCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.WorksheetRootCard in PolyJSON mappings */
    export const type = "worksheet_root";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.WorksheetRootCard$WorksheetRootCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _WorksheetRootCardResult extends AbstractCardContainer._AbstractCardContainerResult {
        type: "worksheet_root";
    }
    
    export type WorksheetRootCardResult = _WorksheetRootCardResult;

    export namespace WorksheetRootCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.WorksheetRootCard$WorksheetRootCardResult in PolyJSON mappings */
        export const type = "worksheet_root";
    }
}