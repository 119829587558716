/** 
 * Translated from class com.dataiku.dip.coremodel.ConditionalOutput
 * Via: com.dataiku.dip.dao.SavedModel
 */
export interface _ConditionalOutput {
    defaultOutput: string;
    input: string;
    name: string;
    rules: ConditionalOutput.Rule[];
}

export type ConditionalOutput = _ConditionalOutput;

export namespace ConditionalOutput {
    /** 
     * Translated from enum com.dataiku.dip.coremodel.ConditionalOutput$ComparisonOperation
     * Via: com.dataiku.dip.coremodel.ConditionalOutput$Rule
     */
    export enum ComparisonOperation {
        LT = "LT",
        LE = "LE",
        GT = "GT",
        GE = "GE",
    }
    /** 
     * Translated from class com.dataiku.dip.coremodel.ConditionalOutput$Rule
     * Via: com.dataiku.dip.coremodel.ConditionalOutput
     */
    export interface _Rule {
        operand: number;
        operation: ConditionalOutput.ComparisonOperation;
        output: string;
    }
    
    export type Rule = _Rule;
}