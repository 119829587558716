<div class="data-collection-header__container">
    <data-collection-path class="data-collection-header__path" [id]="id" [displayName]="displayName">
    </data-collection-path>

    <div class="data-collection-header__action-buttons">
        <button *ngIf="!isEmptyCollection && mayPublishToDataCollections && permissions.write"
            class="btn btn--primary btn--dku-icon"
            (click)="addObjectsToCollection.emit()"
        >
            <i class="dku-icon-plus-16"></i>
            Add Dataset
        </button>

        <button *ngIf="permissions.admin"
                class="btn btn--icon btn--dku-icon btn--no-background"
                [matMenuTriggerFor]="moreActionsMenu"
                matTooltip="Actions"
                matTooltipPosition="below"
        >
            <i class="dku-icon-menu-vertical-dots-16"></i>
        </button>
        <mat-menu #moreActionsMenu xPosition="before">
            <ng-template matMenuContent>
                <button mat-menu-item (click)="editPermissions.emit()">
                    <i class="dku-icon-edit-16"></i>
                    Edit security settings
                </button>
                <button mat-menu-item class="data-collection-header__delete-menu" (click)="deleteCollection.emit()">
                    <i class="dku-icon-trash-16"></i>
                    Delete
                </button>
            </ng-template>
        </mat-menu>
    </div>
</div>
