import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

/**
 * Utility component to display a "Select all" / "Clear all" buttons on top of the dropdown.
 * Only usable if "multiple" is true.
 * Usage:
 * - Set [clearable]="false" on <ng-select> to disable default clear behavior
 * - Add <ng-select-actions-box> to <ng-template ng-header-tmp>
 * - Set [multiple]="true" on <ng-select>
 */
@Component({
    selector: 'ng-select-actions-box',
    templateUrl: './ng-select-actions-box.component.html',
    styleUrls: ['./ng-select-actions-box.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgSelectActionsBoxComponent {

    // Visible for testing purpose
    @ViewChild('selectAllButton') selectAllButton: ElementRef;
    @ViewChild('clearAllButton') clearAllButton: ElementRef;

    constructor(public ngSelect: NgSelectComponent) {}

    onSelectAll() {
        this.ngSelect.itemsList.filteredItems.filter((i) => !i.disabled).forEach((i) => this.ngSelect.select(i));
    }

    onClearAll() {
        this.ngSelect.itemsList.filteredItems.filter((i) => !i.disabled).forEach((i) => this.ngSelect.unselect(i));
    }
}
