import type { ACFPlotCard } from './acfplot-card';
import type { Abstract2SampTestCard } from './common/abstract2-samp-test-card';
import type { AbstractCardContainer } from './common/abstract-card-container';
import type { AbstractHeaderCard } from './common/abstract-header-card';
import type { AbstractNSampTestCard } from './common/abstract-nsamp-test-card';
import type { BivariateBoxPlotCard } from './bivariate_header/bivariate-box-plot-card';
import type { BivariateFrequencyTableCard } from './bivariate_header/bivariate-frequency-table-card';
import type { BivariateHeaderCard } from './bivariate_header/bivariate-header-card';
import type { BivariateHistogramCard } from './bivariate_header/bivariate-histogram-card';
import type { BivariateSummaryStatsCard } from './bivariate_header/bivariate-summary-stats-card';
import type { CDFPlotCard } from './cdfplot-card';
import type { CategoricalHistogramCard } from './univariate_header/categorical-histogram-card';
import type { Chi2IndTestCard } from './chi2-ind-test-card';
import type { ColumnCard } from './common/column-card';
import type { CorrelationMatrixCard } from './correlation-matrix-card';
import type { DurbinWatsonCard } from './durbin-watson-card';
import type { EdaErrorCodes } from './../../eda-error-codes';
import type { Fit2DDistributionCard } from './fit2-ddistribution-card';
import type { FitCurveCard } from './fit-curve-card';
import type { FitDistributionCard } from './fit-distribution-card';
import type { GroupedCompiledCardWrapper } from './common/grouped-compiled-card-wrapper';
import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { KsTest2SampCard } from './ks-test2-samp-card';
import type { MannKendallTestCard } from './mann-kendall-test-card';
import type { MoodTest2SampCard } from './mood-test2-samp-card';
import type { MoodTestNSampCard } from './mood-test-nsamp-card';
import type { MosaicPlotCard } from './bivariate_header/mosaic-plot-card';
import type { NumericalHistogramCard } from './univariate_header/numerical-histogram-card';
import type { OneWayANOVACard } from './one-way-anovacard';
import type { PCACard } from './pcacard';
import type { PairwiseMoodTestCard } from './pairwise-mood-test-card';
import type { PairwiseTTestCard } from './pairwise-ttest-card';
import type { ParallelCoordinatesPlotCard } from './parallel-coordinates-plot-card';
import type { PlaygroundCard } from './playground-card';
import type { QuantilesTableCard } from './univariate_header/quantiles-table-card';
import type { STLDecompositionCard } from './stldecomposition-card';
import type { ScatterPlot3DCard } from './scatter-plot3-dcard';
import type { ScatterPlotCard } from './bivariate_header/scatter-plot-card';
import type { ShapiroNormalityTestCard } from './shapiro-normality-test-card';
import type { SignTest1SampCard } from './sign-test1-samp-card';
import type { TTest2SampCard } from './ttest2-samp-card';
import type { TZTest1SampCard } from './tztest1-samp-card';
import type { TimeSeriesCard } from './time-series-card';
import type { UnitRootTestADFCard } from './unit-root-test-adfcard';
import type { UnitRootTestCard } from './unit-root-test-card';
import type { UnitRootTestKPSSCard } from './unit-root-test-kpsscard';
import type { UnitRootTestZACard } from './unit-root-test-zacard';
import type { UnivariateFrequencyTableCard } from './univariate_header/univariate-frequency-table-card';
import type { UnivariateHeaderCard } from './univariate_header/univariate-header-card';
import type { UnivariateSummaryStatsCard } from './univariate_header/univariate-summary-stats-card';
import type { WorksheetRootCard } from './worksheet-root-card';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.CardResult
 * Via: UIModel annotation in the class hierarchy
 */
export interface _CardResult extends _ImmutableValueObject {
    computationCount: number;
    failedComputationsCount: number;
    notComputedCount: number;
    warnings?: string[] | null;
}

/** List of all concrete subclasses of com.dataiku.dip.eda.worksheets.cards.CardResult */
export type CardResult =
    | ACFPlotCard.ACFPlotCardResult
    | CDFPlotCard.CDFPlotCardResult
    | CardResult.UnavailableCardResult
    | Chi2IndTestCard.Chi2IndTestCardResult
    | CorrelationMatrixCard.CorrelationMatrixCardResult
    | DurbinWatsonCard.DurbinWatsonCardResult
    | Fit2DDistributionCard.Fit2DDistributionCardResult
    | FitCurveCard.FitCurveCardResult
    | FitDistributionCard.FitDistributionCardResult
    | KsTest2SampCard.KsTest2SampCardResult
    | MannKendallTestCard.MannKendallTestCardResult
    | MoodTest2SampCard.MoodTest2SampCardResult
    | MoodTestNSampCard.MoodTestNSampCardResult
    | OneWayANOVACard.OneWayANOVACardResult
    | PCACard.PCACardResult
    | PairwiseMoodTestCard.PairwiseMoodTestCardResult
    | PairwiseTTestCard.PairwiseTTestCardResult
    | ParallelCoordinatesPlotCard.ParallelCoordinatesPlotCardResult
    | PlaygroundCard.PlaygroundCardResult
    | STLDecompositionCard.STLDecompositionCardResult
    | ScatterPlot3DCard.ScatterPlot3DCardResult
    | ShapiroNormalityTestCard.ShapiroNormalityTestCardResult
    | SignTest1SampCard.SignTest1SampCardResult
    | TTest2SampCard.TTest2SampCardResult
    | TZTest1SampCard.TZTest1SampCardResult
    | UnitRootTestADFCard.UnitRootTestADFCardResult
    | UnitRootTestKPSSCard.UnitRootTestKPSSCardResult
    | UnitRootTestZACard.UnitRootTestZACardResult
    | WorksheetRootCard.WorksheetRootCardResult
    | BivariateBoxPlotCard.BivariateBoxPlotCardResult
    | BivariateFrequencyTableCard.BivariateFrequencyTableCardResult
    | BivariateHeaderCard.BivariateHeaderCardResult
    | BivariateHistogramCard.BivariateHistogramCardResult
    | BivariateSummaryStatsCard.BivariateSummaryStatsCardResult
    | MosaicPlotCard.MosaicPlotCardResult
    | ScatterPlotCard.ScatterPlotCardResult
    | ColumnCard.ColumnCardResult
    | GroupedCompiledCardWrapper.GroupedCardResult
    | CategoricalHistogramCard.CategoricalHistogramCardResult
    | NumericalHistogramCard.NumericalHistogramCardResult
    | QuantilesTableCard.QuantilesTableCardResult
    | UnivariateFrequencyTableCard.UnivariateFrequencyTableCardResult
    | UnivariateHeaderCard.UnivariateHeaderCardResult
    | UnivariateSummaryStatsCard.UnivariateSummaryStatsCardResult;

export namespace CardResult {
    /** Types of class com.dataiku.dip.eda.worksheets.cards.CardResult in PolyJSON mappings */
    export const type = ["acf_plot", "cdf_plot", "unavailable", "chi2_independence_test", "correlation_matrix", "durbin_watson", "fit_2d_distribution", "fit_curve", "fit_distribution", "ks_test_2samp", "mann_kendall_test", "mood_test_2samp", "mood_nsamp", "oneway_anova", "pca", "pairwise_mood", "pairwise_ttest", "parallel_coordinates_plot", "playground", "stl_decomposition", "scatter_plot_3d", "shapiro", "sign_test_1samp", "ttest_2samp", "ttest_ztest_1samp", "unit_root_test_adf", "unit_root_test_kpss", "unit_root_test_za", "worksheet_root", "bivariate_box_plot", "bivariate_frequency_table", "bivariate_header", "bivariate_histogram", "bivariate_summary", "mosaic_plot", "scatter_plot", "column_card", "groups", "categorical_histogram", "numerical_histogram", "quantile_table", "univariate_frequency_table", "univariate_header", "univariate_summary", ] as const;
    
    export function isACFPlotCardResult(obj: CardResult): obj is ACFPlotCard.ACFPlotCardResult { return obj["type"] == "acf_plot"; }
    export function isCDFPlotCardResult(obj: CardResult): obj is CDFPlotCard.CDFPlotCardResult { return obj["type"] == "cdf_plot"; }
    export function isCardResult(obj: CardResult): obj is CardResult { return ["acf_plot", "cdf_plot", "unavailable", "chi2_independence_test", "correlation_matrix", "durbin_watson", "fit_2d_distribution", "fit_curve", "fit_distribution", "ks_test_2samp", "mann_kendall_test", "mood_test_2samp", "mood_nsamp", "oneway_anova", "pca", "pairwise_mood", "pairwise_ttest", "parallel_coordinates_plot", "playground", "stl_decomposition", "scatter_plot_3d", "shapiro", "sign_test_1samp", "ttest_2samp", "ttest_ztest_1samp", "unit_root_test_adf", "unit_root_test_kpss", "unit_root_test_za", "worksheet_root", "bivariate_box_plot", "bivariate_frequency_table", "bivariate_header", "bivariate_histogram", "bivariate_summary", "mosaic_plot", "scatter_plot", "column_card", "groups", "categorical_histogram", "numerical_histogram", "quantile_table", "univariate_frequency_table", "univariate_header", "univariate_summary", ].includes(obj["type"]); }
    export function isUnavailableCardResult(obj: CardResult): obj is CardResult.UnavailableCardResult { return obj["type"] == "unavailable"; }
    export function isChi2IndTestCardResult(obj: CardResult): obj is Chi2IndTestCard.Chi2IndTestCardResult { return obj["type"] == "chi2_independence_test"; }
    export function isCorrelationMatrixCardResult(obj: CardResult): obj is CorrelationMatrixCard.CorrelationMatrixCardResult { return obj["type"] == "correlation_matrix"; }
    export function isDurbinWatsonCardResult(obj: CardResult): obj is DurbinWatsonCard.DurbinWatsonCardResult { return obj["type"] == "durbin_watson"; }
    export function isFit2DDistributionCardResult(obj: CardResult): obj is Fit2DDistributionCard.Fit2DDistributionCardResult { return obj["type"] == "fit_2d_distribution"; }
    export function isFitCurveCardResult(obj: CardResult): obj is FitCurveCard.FitCurveCardResult { return obj["type"] == "fit_curve"; }
    export function isFitDistributionCardResult(obj: CardResult): obj is FitDistributionCard.FitDistributionCardResult { return obj["type"] == "fit_distribution"; }
    export function isKsTest2SampCardResult(obj: CardResult): obj is KsTest2SampCard.KsTest2SampCardResult { return obj["type"] == "ks_test_2samp"; }
    export function isMannKendallTestCardResult(obj: CardResult): obj is MannKendallTestCard.MannKendallTestCardResult { return obj["type"] == "mann_kendall_test"; }
    export function isMoodTest2SampCardResult(obj: CardResult): obj is MoodTest2SampCard.MoodTest2SampCardResult { return obj["type"] == "mood_test_2samp"; }
    export function isMoodTestNSampCardResult(obj: CardResult): obj is MoodTestNSampCard.MoodTestNSampCardResult { return obj["type"] == "mood_nsamp"; }
    export function isOneWayANOVACardResult(obj: CardResult): obj is OneWayANOVACard.OneWayANOVACardResult { return obj["type"] == "oneway_anova"; }
    export function isPCACardResult(obj: CardResult): obj is PCACard.PCACardResult { return obj["type"] == "pca"; }
    export function isPairwiseMoodTestCardResult(obj: CardResult): obj is PairwiseMoodTestCard.PairwiseMoodTestCardResult { return obj["type"] == "pairwise_mood"; }
    export function isPairwiseTTestCardResult(obj: CardResult): obj is PairwiseTTestCard.PairwiseTTestCardResult { return obj["type"] == "pairwise_ttest"; }
    export function isParallelCoordinatesPlotCardResult(obj: CardResult): obj is ParallelCoordinatesPlotCard.ParallelCoordinatesPlotCardResult { return obj["type"] == "parallel_coordinates_plot"; }
    export function isPlaygroundCardResult(obj: CardResult): obj is PlaygroundCard.PlaygroundCardResult { return obj["type"] == "playground"; }
    export function isSTLDecompositionCardResult(obj: CardResult): obj is STLDecompositionCard.STLDecompositionCardResult { return obj["type"] == "stl_decomposition"; }
    export function isScatterPlot3DCardResult(obj: CardResult): obj is ScatterPlot3DCard.ScatterPlot3DCardResult { return obj["type"] == "scatter_plot_3d"; }
    export function isShapiroNormalityTestCardResult(obj: CardResult): obj is ShapiroNormalityTestCard.ShapiroNormalityTestCardResult { return obj["type"] == "shapiro"; }
    export function isSignTest1SampCardResult(obj: CardResult): obj is SignTest1SampCard.SignTest1SampCardResult { return obj["type"] == "sign_test_1samp"; }
    export function isTTest2SampCardResult(obj: CardResult): obj is TTest2SampCard.TTest2SampCardResult { return obj["type"] == "ttest_2samp"; }
    export function isTZTest1SampCardResult(obj: CardResult): obj is TZTest1SampCard.TZTest1SampCardResult { return obj["type"] == "ttest_ztest_1samp"; }
    export function isTimeSeriesCardResult(obj: CardResult): obj is TimeSeriesCard.TimeSeriesCardResult { return ["acf_plot", "durbin_watson", "mann_kendall_test", "stl_decomposition", "unit_root_test_adf", "unit_root_test_kpss", "unit_root_test_za", ].includes(obj["type"]); }
    export function isUnitRootTestADFCardResult(obj: CardResult): obj is UnitRootTestADFCard.UnitRootTestADFCardResult { return obj["type"] == "unit_root_test_adf"; }
    export function isUnitRootTestCardResult(obj: CardResult): obj is UnitRootTestCard.UnitRootTestCardResult { return ["unit_root_test_adf", "unit_root_test_kpss", "unit_root_test_za", ].includes(obj["type"]); }
    export function isUnitRootTestKPSSCardResult(obj: CardResult): obj is UnitRootTestKPSSCard.UnitRootTestKPSSCardResult { return obj["type"] == "unit_root_test_kpss"; }
    export function isUnitRootTestZACardResult(obj: CardResult): obj is UnitRootTestZACard.UnitRootTestZACardResult { return obj["type"] == "unit_root_test_za"; }
    export function isWorksheetRootCardResult(obj: CardResult): obj is WorksheetRootCard.WorksheetRootCardResult { return obj["type"] == "worksheet_root"; }
    export function isBivariateBoxPlotCardResult(obj: CardResult): obj is BivariateBoxPlotCard.BivariateBoxPlotCardResult { return obj["type"] == "bivariate_box_plot"; }
    export function isBivariateFrequencyTableCardResult(obj: CardResult): obj is BivariateFrequencyTableCard.BivariateFrequencyTableCardResult { return obj["type"] == "bivariate_frequency_table"; }
    export function isBivariateHeaderCardResult(obj: CardResult): obj is BivariateHeaderCard.BivariateHeaderCardResult { return obj["type"] == "bivariate_header"; }
    export function isBivariateHistogramCardResult(obj: CardResult): obj is BivariateHistogramCard.BivariateHistogramCardResult { return obj["type"] == "bivariate_histogram"; }
    export function isBivariateSummaryStatsCardResult(obj: CardResult): obj is BivariateSummaryStatsCard.BivariateSummaryStatsCardResult { return obj["type"] == "bivariate_summary"; }
    export function isMosaicPlotCardResult(obj: CardResult): obj is MosaicPlotCard.MosaicPlotCardResult { return obj["type"] == "mosaic_plot"; }
    export function isScatterPlotCardResult(obj: CardResult): obj is ScatterPlotCard.ScatterPlotCardResult { return obj["type"] == "scatter_plot"; }
    export function isAbstract2SampTestCardResult(obj: CardResult): obj is Abstract2SampTestCard.Abstract2SampTestCardResult { return ["ks_test_2samp", "mood_test_2samp", "ttest_2samp", ].includes(obj["type"]); }
    export function isAbstractCardContainerResult(obj: CardResult): obj is AbstractCardContainer.AbstractCardContainerResult { return ["worksheet_root", "bivariate_header", "column_card", "univariate_header", ].includes(obj["type"]); }
    export function isAbstractHeaderCardResult(obj: CardResult): obj is AbstractHeaderCard.AbstractHeaderCardResult { return ["bivariate_header", "univariate_header", ].includes(obj["type"]); }
    export function isAbstractNSampleTestCardResult(obj: CardResult): obj is AbstractNSampTestCard.AbstractNSampleTestCardResult { return ["mood_nsamp", "oneway_anova", "pairwise_mood", "pairwise_ttest", ].includes(obj["type"]); }
    export function isColumnCardResult(obj: CardResult): obj is ColumnCard.ColumnCardResult { return obj["type"] == "column_card"; }
    export function isGroupedCardResult(obj: CardResult): obj is GroupedCompiledCardWrapper.GroupedCardResult { return obj["type"] == "groups"; }
    export function isCategoricalHistogramCardResult(obj: CardResult): obj is CategoricalHistogramCard.CategoricalHistogramCardResult { return obj["type"] == "categorical_histogram"; }
    export function isNumericalHistogramCardResult(obj: CardResult): obj is NumericalHistogramCard.NumericalHistogramCardResult { return obj["type"] == "numerical_histogram"; }
    export function isQuantilesTableCardResult(obj: CardResult): obj is QuantilesTableCard.QuantilesTableCardResult { return obj["type"] == "quantile_table"; }
    export function isUnivariateFrequencyTableCardResult(obj: CardResult): obj is UnivariateFrequencyTableCard.UnivariateFrequencyTableCardResult { return obj["type"] == "univariate_frequency_table"; }
    export function isUnivariateHeaderCardResult(obj: CardResult): obj is UnivariateHeaderCard.UnivariateHeaderCardResult { return obj["type"] == "univariate_header"; }
    export function isUnivariateSummaryStatsCardResult(obj: CardResult): obj is UnivariateSummaryStatsCard.UnivariateSummaryStatsCardResult { return obj["type"] == "univariate_summary"; }
    
    /** 
     * Translated from enum com.dataiku.dip.eda.worksheets.cards.CardResult$UnavailabilityReason
     * Via: com.dataiku.dip.eda.worksheets.cards.CardResult$UnavailableCardResult
     */
    export enum UnavailabilityReason {
        NOT_COMPUTED = "NOT_COMPUTED",
        FAILURE = "FAILURE",
    }
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.CardResult$UnavailableCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _UnavailableCardResult extends _CardResult {
        type: "unavailable";
        code?: EdaErrorCodes | null;
        message?: string | null;
        reason: CardResult.UnavailabilityReason;
    }
    
    export type UnavailableCardResult = _UnavailableCardResult;

    export namespace UnavailableCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.CardResult$UnavailableCardResult in PolyJSON mappings */
        export const type = "unavailable";
    }
}