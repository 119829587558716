<ng-select
    class="ng-select--no-wrap-selected-items"
    data-qa-eda-split-by-dropdown
    [ngModel]="currentSelection"
    (ngModelChange)="changeCurrentSelection($event)"
    [placeholder]="placeholder"
    appendTo="body"
    [items]="selectableItems"
    [multiple]="multiple"
    [virtualScroll]="true"
    [closeOnSelect]="!multiple"
    [searchable]="false"
    [clearable]="false"
    bindLabel="displayName"
>
    <ng-template ng-option-tmp let-item="item">
        <ng-select-pseudo-checkbox *ngIf="multiple"></ng-select-pseudo-checkbox>
        <span [class.text-disabled]="item.fakeDisabled">
            <ng-container *ngTemplateOutlet="nameWithIcon; context: { $implicit: item }">
            </ng-container>
        </span>
    </ng-template>

    <ng-template ng-header-tmp>
        <ng-select-search-input></ng-select-search-input>
    </ng-template>

    <ng-template ng-label-tmp let-item="item">
        <!-- Template for the menu trigger in single mode -->
        <ng-container *ngTemplateOutlet="nameWithIcon; context: { $implicit: item }">
        </ng-container>
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items">
        <!-- Template for the men trigger in multiple mode -->
        <ng-container *ngIf="items.length > 1">
            {{ items.length }} variables selected
        </ng-container>
        <ng-container *ngIf="items.length === 1">
            <ng-container *ngTemplateOutlet="nameWithIcon; context: { $implicit: items[0] }">
            </ng-container>
        </ng-container>
    </ng-template>
</ng-select>

<ng-template #nameWithIcon let-item>
    <span class="name-with-icon" *ngIf="item">
        <i class="icon-font" *ngIf="isCategorical(item)"></i>
        <i class="icon-number" *ngIf="isContinuous(item)"></i>
        {{ item.displayName }}
    </span>
</ng-template>
