import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { ComputationResult } from './../computation-result';
import type { ResamplerSpec } from './../../resampling/resampler-spec';
import type { _AvailableResult } from './../available-result';
import type { _Computation } from './../computation';
import type { _ComputationResult } from './../computation-result';
import type { _ResamplerSpec } from './../../resampling/resampler-spec';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.common.ResampledComputation
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ResampledComputation extends _Computation {
    type: "resampled";
    computation: Computation;
    spec: ResamplerSpec;
}

export type ResampledComputation = _ResampledComputation;

export namespace ResampledComputation {
    /** Type of class com.dataiku.dip.eda.compute.computations.common.ResampledComputation in PolyJSON mappings */
    export const type = "resampled";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.common.ResampledComputation$ResampledComputationResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ResampledComputationResult extends _AvailableResult {
        type: "resampled";
        result: ComputationResult;
    }
    
    export type ResampledComputationResult = _ResampledComputationResult;

    export namespace ResampledComputationResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.common.ResampledComputation$ResampledComputationResult in PolyJSON mappings */
        export const type = "resampled";
    }
}