import type { Filter } from './filter';
import type { _Filter } from './filter';

/** 
 * Translated from class com.dataiku.dip.eda.compute.filtering.IntervalFilter
 * Via: UIModel annotation in the class hierarchy
 */
export interface _IntervalFilter extends _Filter {
    type: "interval";
    closed: IntervalFilter.ClosedMode;
    column: string;
    left: number;
    right: number;
}

export type IntervalFilter = _IntervalFilter;

export namespace IntervalFilter {
    /** Type of class com.dataiku.dip.eda.compute.filtering.IntervalFilter in PolyJSON mappings */
    export const type = "interval";
    
    /** 
     * Translated from enum com.dataiku.dip.eda.compute.filtering.IntervalFilter$ClosedMode
     * Via: com.dataiku.dip.eda.compute.filtering.IntervalFilter
     */
    export enum ClosedMode {
        LEFT = "LEFT",
        RIGHT = "RIGHT",
        BOTH = "BOTH",
        NEITHER = "NEITHER",
    }
}