import { Component, Input } from "@angular/core";
import { HTMLTooltipPosition } from "./html-tooltip-position.enum";

@Component({
	selector: 'html-tooltip-host',
	template: '<div class="html-tooltip"><div class="html-tooltip-content" [innerHTML]="content"></div><span class="html-tooltip-arrow {{tooltipPosition}}"></span></div>',
	styleUrls: ['./html-tooltip-host.component.less'],
})
export class HTMLTooltipHostComponent {
	@Input()
	content: string;
	@Input()
	tooltipPosition: HTMLTooltipPosition;
}
