<form class="dkuform-chart-options" [formGroup]="zoomOptionsForm">
    <div class="control-group">
        <div class="controls">
            <label>
                <input type="checkbox" formControlName="brush" />
                Brush
            </label>
        </div>
    </div>
</form>
