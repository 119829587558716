import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.Entropy
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Entropy extends Computation._UnivariateComputation {
    type: "entropy";
}

export type Entropy = _Entropy;

export namespace Entropy {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.Entropy in PolyJSON mappings */
    export const type = "entropy";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.Entropy$EntropyResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _EntropyResult extends _AvailableResult {
        type: "entropy";
        value: number;
    }
    
    export type EntropyResult = _EntropyResult;

    export namespace EntropyResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.Entropy$EntropyResult in PolyJSON mappings */
        export const type = "entropy";
    }
}