import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';
import type { _Computation } from './../computation';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.common.Count
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Count extends _Computation {
    type: "count";
}

export type Count = _Count;

export namespace Count {
    /** Type of class com.dataiku.dip.eda.compute.computations.common.Count in PolyJSON mappings */
    export const type = "count";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.common.Count$CountResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _CountResult extends _AvailableResult {
        type: "count";
        count: number;
    }
    
    export type CountResult = _CountResult;

    export namespace CountResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.common.Count$CountResult in PolyJSON mappings */
        export const type = "count";
    }
}