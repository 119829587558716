import type { AxisDef } from './axis-def';

/** 
 * Translated from class com.dataiku.dip.pivot.backend.model.Aggregation
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Aggregation {
    column: string;
    computeMode: Aggregation.ComputeMode;
    computeModeDim: number;
    customFunction?: string | null;
    function: Aggregation.Function;
    id: string;
    type: AxisDef.Type;
}

export type Aggregation = _Aggregation;

export namespace Aggregation {
    /** 
     * Translated from enum com.dataiku.dip.pivot.backend.model.Aggregation$ComputeMode
     * Via: com.dataiku.dip.pivot.backend.model.Aggregation
     */
    export enum ComputeMode {
        NORMAL = "NORMAL",
        PERCENTAGE = "PERCENTAGE",
        AVG_RATIO = "AVG_RATIO",
        CUMULATIVE = "CUMULATIVE",
        CUMULATIVE_PERCENTAGE = "CUMULATIVE_PERCENTAGE",
        DIFFERENCE = "DIFFERENCE",
    }
    /** 
     * Translated from enum com.dataiku.dip.pivot.backend.model.Aggregation$Function
     * Via: com.dataiku.dip.pivot.backend.model.Aggregation
     */
    export enum Function {
        COUNT = "COUNT",
        COUNTD = "COUNTD",
        SUM = "SUM",
        AVG = "AVG",
        MAX = "MAX",
        MIN = "MIN",
        DISTINCT = "DISTINCT",
        CUSTOM = "CUSTOM",
    }
}