import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { zipSameSize } from '@features/eda/echarts-utils';
import { CardAction, CardActionType } from '@features/eda/worksheet/cards/events';
import { NumberFormatterService } from '@features/simple-report/services';
import { Card } from '@model-main/eda/worksheets/cards/card';
import { STLDecompositionCard } from '@model-main/eda/worksheets/cards/stldecomposition-card';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ColorsService } from '@shared/graphics/colors.service';
import { NicePrecisionPipe } from '@shared/pipes/number-pipes/nice-precision.pipe';
import { DataZoomComponentOption, EChartsOption } from 'echarts';
import { encodeHTML } from 'entities';
import _ from 'lodash';
import { Subject, debounceTime } from 'rxjs';

const Y_AXIS_PADDING = {
    [STLDecompositionCard.SeriesKind.OBSERVED]: [4, 26],
    [STLDecompositionCard.SeriesKind.TREND]: [4, 36],
    [STLDecompositionCard.SeriesKind.SEASONALITY]: [4, 24],
    [STLDecompositionCard.SeriesKind.RESIDUALS]: [4, 30],
};

// Manual typing for the echarts event,
// because the definition is not exported.
type DataZoomEvent = {
    start: undefined,
    end: undefined,
    batch: {
        start: number,
        end: number,
    }[],
} | {
    start: number,
    end: number,
    batch: undefined,
};

@Component({
    selector: 'timeseries-decomposition-card-body',
    templateUrl: './timeseries-decomposition-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/chart.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/card-layout.less',
        './timeseries-decomposition-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class TimeseriesDecompositionCardBodyComponent implements OnChanges, OnInit {
    @Input() results: STLDecompositionCard.STLDecompositionCardResult;
    @Input() params: STLDecompositionCard;
    @Input() hasFixedHeight: boolean;
    @Input() readOnly: boolean;
    @Output() action = new EventEmitter<CardAction>();

    plotOptions: EChartsOption;
    SERIES_KIND = Object.values(STLDecompositionCard.SeriesKind);

    private dataZoomClick$ = new Subject<DataZoomEvent>();
    private zoomStart = 0;
    private zoomEnd = 100;

    constructor(
        private colorsService: ColorsService,
        private nicePrecisionPipe: NicePrecisionPipe,
        private numberFormatterService: NumberFormatterService,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results) {
            // reset the zoom options when the result changed
            this.zoomStart = 0;
            this.zoomEnd = 100;
        }

        if (changes.results || changes.params) {
            this.plotOptions = this.buildChartOptions();
        }
    }

    ngOnInit() {
        this.dataZoomClick$.pipe(
            debounceTime(300),
            untilDestroyed(this)
        ).subscribe(event => {
            this.zoomStart = event.start ?? event.batch[0].start;
            this.zoomEnd = event.end ?? event.batch[0].end;
        });
    }

    getColor(kind: STLDecompositionCard.SeriesKind): string {
        return this.colorsService.getColorForVariable(kind);
    }

    dataZoomClicked(event: DataZoomEvent) {
        this.dataZoomClick$.next(event);
    }

    formatSeriesKind(kind: STLDecompositionCard.SeriesKind) {
        return kind.charAt(0) + kind.substring(1).toLowerCase();
    }

    displaySeries(kind: STLDecompositionCard.SeriesKind) {
        if (!this.params.showCompactChart) {
            // expanded mode, no-op
            return;
        }

        const selectedSeries = [...this.params.selectedSeries];
        const idx = selectedSeries.indexOf(kind);

        if (idx < 0) {
            // display on
            selectedSeries.splice(this.SERIES_KIND.indexOf(kind), 0, kind);
        } else {
            // display off
            if (selectedSeries.length <= 1) {
                // prevent ending up with no chart at all in the screen
                return;
            }

            selectedSeries.splice(idx, 1);
        }

        this.updateCard({
            ...this.params,
            selectedSeries,
        });
    }

    toggleCompactChart() {
        this.updateCard({
            ...this.params,
            showCompactChart: !this.params.showCompactChart
        });
    }

    updateCard(card: Card) {
        if (this.readOnly) {
            return;
        }

        this.action.emit({
            type: CardActionType.UPDATE,
            newParams: card,
        });
    }

    private getRawSeries(kind: STLDecompositionCard.SeriesKind): number[] {
        switch (kind) {
            case STLDecompositionCard.SeriesKind.OBSERVED:
                return this.results.observed;
            case STLDecompositionCard.SeriesKind.TREND:
                return this.results.trend;
            case STLDecompositionCard.SeriesKind.SEASONALITY:
                return this.results.seasonal;
            case STLDecompositionCard.SeriesKind.RESIDUALS:
                return this.results.resid;
        }
    }

    private isSelected(kind: STLDecompositionCard.SeriesKind): boolean {
        if (this.params.showCompactChart) {
            return this.params.selectedSeries.includes(kind);
        }

        // all series are selected with the expanded mode
        return true;
    }

    private getXaxis(): EChartsOption["xAxis"] {
        const xAxis = [];
        const nSeries = this.params.showCompactChart ?
            1 : this.SERIES_KIND.length;

        for (let i = 0; i < nSeries; i++) {
            xAxis.push({
                show: i === nSeries - 1 || nSeries === 1,
                gridIndex: i,
                type: "time",
            });
        }
        return xAxis as EChartsOption["xAxis"];
    }

    private getYaxis(): EChartsOption["yAxis"] {
        const settings: EChartsOption["yAxis"] = [];

        for (let i = 0; i < this.SERIES_KIND.length; i++) {
            const kind = this.SERIES_KIND[i];
            if (!this.isSelected(kind)) {
                continue; // skip series
            }

            const rawSeries = this.getRawSeries(kind);
            const seriesMin = _.min(rawSeries) ?? 0;
            const seriesMax = _.max(rawSeries) ?? 0;
            const formatter = this.numberFormatterService.getForRange(seriesMin, seriesMax, rawSeries.length, false, true);

            if (this.params.showCompactChart) {
                settings.push({
                    gridIndex: this.params.showCompactChart ? 0 : i,
                    axisLabel: { formatter },
                });

            } else {
                const formattedMin = this.nicePrecisionPipe.transform(seriesMin, 3);

                settings.push({
                    gridIndex: this.params.showCompactChart ? 0 : i,
                    axisLabel: { formatter },
                    min: formattedMin,
                    name: this.formatSeriesKind(kind),
                    nameLocation: "middle",
                    nameGap: 60,
                    nameTextStyle: {
                        backgroundColor : "#cccccc",
                        padding: Y_AXIS_PADDING[kind],
                    },
                });
            }
        }

        return settings;
    }

    private getGrid(): EChartsOption["grid"] {
        if (this.params.showCompactChart) {
            // single chart
            return [
                { top : '2%', bottom: '12%', left: 60, right: 30 },
            ];
        }

        // multi charts
        return [
            { top : '2%', height: '17%', left: 110, right: 30 },
            { top: '25%', height: '17%', left: 110, right: 30 },
            { top: '48%', height: '17%', left: 110, right: 30 },
            { bottom: '12%', height: '17%', left: 110, right: 30 },
        ];
    }

    private getSeries(): EChartsOption["series"] {
        const settings: EChartsOption["series"] = [];
        for (let i = 0; i < this.SERIES_KIND.length; i++) {
            const kind = this.SERIES_KIND[i];
            if (!this.isSelected(kind)) {
                continue; // skip series
            }

            const color = this.getColor(kind);
            const options = {
                showSymbol: false,
                name: this.formatSeriesKind(kind),
                data: zipSameSize(this.results.time, this.getRawSeries(kind)),
                xAxisIndex: this.params.showCompactChart ? 0 : i,
                yAxisIndex: this.params.showCompactChart ? 0 : i,

                emphasis: {
                    itemStyle: {
                        borderColor: color,
                    },
                },
            };

            let seriesOptions: EChartsOption["series"];
            if (kind === STLDecompositionCard.SeriesKind.RESIDUALS) {
                seriesOptions = {
                    ...options,
                    type: "bar",
                    itemStyle: { color },
                };
            } else {
                seriesOptions = {
                    ...options,
                    type: "line",
                    lineStyle: { color },
                };
            }

            settings.push(seriesOptions);
        }

        return settings;
    }

    private buildChartOptions(): EChartsOption {
        return {
            xAxis: this.getXaxis(),
            yAxis: this.getYaxis(),
            grid: this.getGrid(),
            dataZoom: this.getDataZoom(),
            series: this.getSeries(),
            axisPointer: {
                link: [{ xAxisIndex: [0, 1, 2, 3] }],
            },
            tooltip: {
                trigger: "axis",
                formatter: this.getTooltipFormatter(),
            },
        };
    }

    private getDataZoom(): DataZoomComponentOption[] {
        return [{
            type: 'inside',
            xAxisIndex: [0, 1, 2, 3],
            start: this.zoomStart,
            end: this.zoomEnd,
        }, {
            type: 'slider',
            xAxisIndex: [0, 1, 2, 3],
            width: "80%",
            left: "10%",
            start: this.zoomStart,
            end: this.zoomEnd,
        }];
    }

    private getTooltipFormatter() {
        // Typings are not available for the params field.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (params: any) => {
            const dataIndex: number = params[0].dataIndex;
            const time = this.results.time[dataIndex];
            let tooltip = `Time: ${encodeHTML(time)} <br/>`;

            for (let i = 0; i < this.SERIES_KIND.length; i++) {
                const kind = this.SERIES_KIND[i];
                if (!this.isSelected(kind)) {
                    continue; // skip series
                }

                const label = this.formatSeriesKind(kind);
                const color = this.getColor(kind);
                const value = this.getRawSeries(kind)[dataIndex];
                // Casting to number then string to remove trailing zeros
                const formattedValue = Number(value.toPrecision(8)).toString();

                tooltip += `<span class="chart-tooltip-circle" style="background-color: ${encodeHTML(color)};"></span>`;
                tooltip += `${encodeHTML(label)}: ${encodeHTML(formattedValue)}`;
                tooltip += "<br/>";
            }

            return tooltip;
        };
    }
}
