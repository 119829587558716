
<clearable-searchbar
    class="workspace-object-filters__search"
    (searchTermChange)="searchFilterChanged($event)"
    [hasPrefixIcon]="true"
    [isClearable]="true">
</clearable-searchbar>

<ng-select
    placeholder="Type"
    [(ngModel)]="filters.types" (ngModelChange)="filterChanged()"
    [multiple]="true"
    appendTo="body"
    [closeOnSelect]="false"
    [items]="types"
    bindValue="value"
    [searchable]="false"
    [clearable]="false"
    class="workspace-object-filters__select ng-select--no-wrap-selected-items">
    <ng-template ng-option-tmp let-item="item">
        <ng-select-pseudo-checkbox></ng-select-pseudo-checkbox>
        <span class="name-with-icon" *ngIf="item" class="workspace-object-filters__select-option">
            <i [ngClass]="[item.value | typeToIcon:16 | toModernIcon:16, item.value | typeToColor]"></i>
        </span>
        <span class="workspace-object-filters__select-option-text">{{ item.value | niceConst }}</span>
    </ng-template>
    <ng-template ng-multi-label-tmp let-items="items">
        <!-- Template for the menu trigger in multiple mode -->
        <ng-container *ngIf="items.length > 1">
            <strong>{{ items.length }}</strong>&nbsp; Types
        </ng-container>
        <ng-container *ngIf="items.length === 1">
            <strong>{{ items.length }}</strong>&nbsp; Type
        </ng-container>
    </ng-template>
</ng-select>

<button class="workspace-object-filters__favorite btn btn--text btn--secondary btn--select btn--dku-icon" (click)="toggleFavorites(filters)">
    <i class="dku-icon-star-fill-16" [class.active]="filters.starred"></i>
    Favorites
</button>

<button *ngIf="hasFilters"
    type="button" 
    class="btn btn--text btn--primary mright8 mleft8" 
    (click)="clearFilters()">
    Clear filters
</button>

<sort-order-button class="workspace-object-filters__sort-order" [(value)]="sort.reverse" (valueChange)="sortChanged()">
</sort-order-button>

<ng-select
    [(ngModel)]="sort.by" (ngModelChange)="sortChanged()" appendTo="body" [searchable]="false" [clearable]="false" class="workspace-object-filters__select">
    <ng-option *ngFor="let sortType of sortProperties" [value]="sortType" class="workspace-object-filters__select-option">
        {{ sortType }}
    </ng-option>
</ng-select>
