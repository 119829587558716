import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.Pearson
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Pearson extends Computation._BivariateComputation {
    type: "pearson";
}

export type Pearson = _Pearson;

export namespace Pearson {
    /** Type of class com.dataiku.dip.eda.compute.computations.bivariate.Pearson in PolyJSON mappings */
    export const type = "pearson";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.Pearson$PearsonResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _PearsonResult extends _AvailableResult {
        type: "pearson";
        correlation: number;
        pvalue: number;
    }
    
    export type PearsonResult = _PearsonResult;

    export namespace PearsonResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.bivariate.Pearson$PearsonResult in PolyJSON mappings */
        export const type = "pearson";
    }
}