<ng-container *ngIf="ngSelect.multiple">
    <div class="bs-actionsbox">
        <div class="btn-group btn-block">
            <button
                class="btn btn--secondary"
                #selectAllButton
                (click)="onSelectAll()">
                Select All
            </button>
            <button
                class="btn btn--secondary"
                #clearAllButton
                (click)="onClearAll()">
                Clear All
            </button>
        </div>
    </div>
</ng-container>