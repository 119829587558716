import type { Abstract2DPivotCard } from './abstract2-dpivot-card';
import type { CardResult } from './../card-result';
import type { Filter } from './../../../compute/filtering/filter';
import type { ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _Abstract2DPivotCard } from './abstract2-dpivot-card';
import type { _CardResult } from './../card-result';
import type { _Filter } from './../../../compute/filtering/filter';
import type { _ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.MosaicPlotCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _MosaicPlotCard extends _Abstract2DPivotCard {
    type: "mosaic_plot";
    highlightFilter?: Filter | null;
}

export type MosaicPlotCard = _MosaicPlotCard;

export namespace MosaicPlotCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.bivariate_header.MosaicPlotCard in PolyJSON mappings */
    export const type = "mosaic_plot";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.MosaicPlotCard$MosaicPlotCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _MosaicPlotCardResult extends _CardResult {
        type: "mosaic_plot";
        mosaic: MosaicPlotCard.MosaicPlotData;
    }
    
    export type MosaicPlotCardResult = _MosaicPlotCardResult;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.MosaicPlotCard$MosaicPlotData
     * Via: com.dataiku.dip.eda.worksheets.cards.bivariate_header.MosaicPlotCard$MosaicPlotCardResult
     */
    export interface _MosaicPlotData extends _ImmutableValueObject {
        counts: number[];
        highlightedYPercentages?: number[] | null;
        totalCount: number;
        xPercentages: number[];
        xVals: Filter[];
        yPercentages: number[];
        yVals: Filter[];
    }
    
    export type MosaicPlotData = _MosaicPlotData;

    export namespace MosaicPlotCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.bivariate_header.MosaicPlotCard$MosaicPlotCardResult in PolyJSON mappings */
        export const type = "mosaic_plot";
    }
}