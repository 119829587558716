import { Injectable } from '@angular/core';
import { CatalogFacetMap } from '@shared/models';
import { Result, Source } from '@shared/models/query-result-models';
import { DkuHttpService } from 'dku-frontend-core';
import { UIDataCatalog } from 'generated-sources';
import { Observable } from 'rxjs';

type ExternalTableMetadata = {
    description: string;
    tags: string[];
};

@Injectable({ providedIn: 'root' })
export class PrivateCatalogAPI {
    constructor(
        private dkuHttp: DkuHttpService,
    ) { }

    search(query: string,  facets: CatalogFacetMap): Observable<Result<Source>> {
        return this.dkuHttp.request('POST', '/catalog/search', {query: query, facets: facets});
    }

    getDataSourceDatasetDetails(projectKey: string, id: string) : Observable<UIDataCatalog.DatasetDetails> {
        return this.dkuHttp.request('GET', '/catalog/get-data-source-dataset-details', { projectKey, id }, false);
    }

    flush(): Observable<void> {
        return this.dkuHttp.request('GET', '/catalog/flush', undefined, false);
    }

    getExternalTableMetadata(connection: string, catalog: string | undefined, schema: string, table: string) : Observable<ExternalTableMetadata>{
        return this.dkuHttp.request<ExternalTableMetadata>('GET', '/external-table/get-metadata', { connection, catalog, schema, table }, false);
    }
}
