import type { AbstractHeaderCard } from './../common/abstract-header-card';
import type { _AbstractHeaderCard } from './../common/abstract-header-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateHeaderCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _UnivariateHeaderCard extends _AbstractHeaderCard {
    type: "univariate_header";
    showBoxPlot: boolean;
    showCumulativeDistributionFunction: boolean;
    showFrequencyTable: boolean;
    showHistogram: boolean;
    showQuantile: boolean;
    showSummary: boolean;
}

export type UnivariateHeaderCard = _UnivariateHeaderCard;

export namespace UnivariateHeaderCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateHeaderCard in PolyJSON mappings */
    export const type = "univariate_header";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateHeaderCard$UnivariateHeaderCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _UnivariateHeaderCardResult extends AbstractHeaderCard._AbstractHeaderCardResult {
        type: "univariate_header";
    }
    
    export type UnivariateHeaderCardResult = _UnivariateHeaderCardResult;

    export namespace UnivariateHeaderCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateHeaderCard$UnivariateHeaderCardResult in PolyJSON mappings */
        export const type = "univariate_header";
    }
}