export namespace ATSurveyService {
    /** 
     * Translated from class com.dataiku.dip.server.services.ATSurveyService$ATSurveyOccurrence
     * Via: com.dataiku.dip.server.services.ATSurveyService$ATSurveySettings
     */
    export interface _ATSurveyOccurrence {
        nextAction: number;
    }
    
    export type ATSurveyOccurrence = _ATSurveyOccurrence;
    
    /** 
     * Translated from class com.dataiku.dip.server.services.ATSurveyService$ATSurveySettings
     * Via: com.dataiku.dip.server.services.UsersService$UserDTOBase
     */
    export interface _ATSurveySettings {
        nextAction: number;
        state: ATSurveyService.ATSurveyState;
        surveys: { [key in string]: ATSurveyService.ATSurveyOccurrence };
    }
    
    export type ATSurveySettings = _ATSurveySettings;
    
    /** 
     * Translated from enum com.dataiku.dip.server.services.ATSurveyService$ATSurveyState
     * Via: com.dataiku.dip.server.services.ATSurveyService$ATSurveySettings
     */
    export enum ATSurveyState {
        INITIAL = "INITIAL",
        OPTOUT = "OPTOUT",
    }
}