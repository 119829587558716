import type { AbstractHeaderCard } from './../common/abstract-header-card';
import type { Variable } from './../../models/variable';
import type { _AbstractHeaderCard } from './../common/abstract-header-card';
import type { _Variable } from './../../models/variable';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHeaderCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _BivariateHeaderCard extends _AbstractHeaderCard {
    type: "bivariate_header";
    showBoxPlot: boolean;
    showFrequencyTable: boolean;
    showHistogram: boolean;
    showMosaicPlot: boolean;
    showScatterPlot: boolean;
    showSummary: boolean;
    yColumn: Variable;
}

export type BivariateHeaderCard = _BivariateHeaderCard;

export namespace BivariateHeaderCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHeaderCard in PolyJSON mappings */
    export const type = "bivariate_header";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHeaderCard$BivariateHeaderCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _BivariateHeaderCardResult extends AbstractHeaderCard._AbstractHeaderCardResult {
        type: "bivariate_header";
    }
    
    export type BivariateHeaderCardResult = _BivariateHeaderCardResult;

    export namespace BivariateHeaderCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHeaderCard$BivariateHeaderCardResult in PolyJSON mappings */
        export const type = "bivariate_header";
    }
}