import { Observable } from 'rxjs';
import { DkuHttpService } from 'dku-frontend-core';
import { Injectable } from '@angular/core';
import { ConnectionsIndexingService } from 'generated-sources';

@Injectable({ providedIn: 'root' })
export class PrivateConnectionsAPI {
    constructor(
        private dkuHttp: DkuHttpService,
    ) { }

    countIndexedAndUnindexed(): Observable<ConnectionsIndexingService.IndexedAndUnindexed> {
        return this.dkuHttp.request('GET', '/connections/count-indexed-and-unindexed', undefined, false);
    }

}
