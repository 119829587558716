import type { CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { MannKendallTest } from './../../compute/computations/timeseries/mann-kendall-test';
import type { TimeSeriesCard } from './time-series-card';
import type { _CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { _TimeSeriesCard } from './time-series-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.MannKendallTestCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _MannKendallTestCard extends _TimeSeriesCard, _CardWithConfidenceLevel {
    type: "mann_kendall_test";
    confidenceLevel: number;
}

export type MannKendallTestCard = _MannKendallTestCard;

export namespace MannKendallTestCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.MannKendallTestCard in PolyJSON mappings */
    export const type = "mann_kendall_test";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.MannKendallTestCard$MannKendallTestCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _MannKendallTestCardResult extends TimeSeriesCard._TimeSeriesCardResult {
        type: "mann_kendall_test";
        intercept?: number | null;
        pValue: number;
        slope?: number | null;
        statistic: number;
        tau: number;
        trend: MannKendallTest.Trend;
    }
    
    export type MannKendallTestCardResult = _MannKendallTestCardResult;

    export namespace MannKendallTestCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.MannKendallTestCard$MannKendallTestCardResult in PolyJSON mappings */
        export const type = "mann_kendall_test";
    }
}