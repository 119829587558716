import type { BinningMode } from './../../models/binning-mode';
import type { BivariateCard } from './../bivariate-card';
import type { BoxPlotFragment } from './../fragments/box-plot-fragment';
import type { CardResult } from './../card-result';
import type { Filter } from './../../../compute/filtering/filter';
import type { Variable } from './../../models/variable';
import type { _BivariateCard } from './../bivariate-card';
import type { _BoxPlotFragment } from './../fragments/box-plot-fragment';
import type { _CardResult } from './../card-result';
import type { _Filter } from './../../../compute/filtering/filter';
import type { _Variable } from './../../models/variable';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateBoxPlotCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _BivariateBoxPlotCard extends _BivariateCard {
    type: "bivariate_box_plot";
    binningMode: BinningMode;
    customBinningBoundaries: number[];
    groupOthers: boolean;
    maxValues: number;
}

export type BivariateBoxPlotCard = _BivariateBoxPlotCard;

export namespace BivariateBoxPlotCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateBoxPlotCard in PolyJSON mappings */
    export const type = "bivariate_box_plot";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateBoxPlotCard$BivariateBoxPlotCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _BivariateBoxPlotCardResult extends _CardResult {
        type: "bivariate_box_plot";
        aggregatedVariable: Variable;
        axisVals: Filter[];
        axisVariable: Variable;
        boxPlots: BoxPlotFragment[];
    }
    
    export type BivariateBoxPlotCardResult = _BivariateBoxPlotCardResult;

    export namespace BivariateBoxPlotCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateBoxPlotCard$BivariateBoxPlotCardResult in PolyJSON mappings */
        export const type = "bivariate_box_plot";
    }
}