import type { BoundingBox } from './objectdetection/bounding-box';
import type { ClassificationAnnotation } from './classification/classification-annotation';
import type { NamedEntity } from './entityextraction/named-entity';
import type { _BoundingBox } from './objectdetection/bounding-box';
import type { _ClassificationAnnotation } from './classification/classification-annotation';
import type { _NamedEntity } from './entityextraction/named-entity';

/** 
 * Translated from class com.dataiku.dip.labeling.Label
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Label<T = any> {
    annotations: T[];
}

/** List of all concrete subclasses of com.dataiku.dip.labeling.Label */
export type Label =
    | Label.ImageClassificationLabel
    | Label.NamedEntityExtractionLabel
    | Label.ObjectDetectionLabel;

export namespace Label {
    /** Types of class com.dataiku.dip.labeling.Label in PolyJSON mappings */
    export const type = ["IMAGE_CLASSIFICATION", "NAMED_ENTITY_EXTRACTION", "OBJECT_DETECTION", ] as const;
    
    export function isLabel(obj: Label): obj is Label { return ["IMAGE_CLASSIFICATION", "NAMED_ENTITY_EXTRACTION", "OBJECT_DETECTION", ].includes(obj["type"]); }
    export function isImageClassificationLabel(obj: Label): obj is Label.ImageClassificationLabel { return obj["type"] == "IMAGE_CLASSIFICATION"; }
    export function isNamedEntityExtractionLabel(obj: Label): obj is Label.NamedEntityExtractionLabel { return obj["type"] == "NAMED_ENTITY_EXTRACTION"; }
    export function isObjectDetectionLabel(obj: Label): obj is Label.ObjectDetectionLabel { return obj["type"] == "OBJECT_DETECTION"; }
    
    /** 
     * Translated from class com.dataiku.dip.labeling.Label$ImageClassificationLabel
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ImageClassificationLabel extends _Label<ClassificationAnnotation> {
        type: "IMAGE_CLASSIFICATION";
    }
    
    export type ImageClassificationLabel = _ImageClassificationLabel;
    
    /** 
     * Translated from class com.dataiku.dip.labeling.Label$NamedEntityExtractionLabel
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _NamedEntityExtractionLabel extends _Label<NamedEntity> {
        type: "NAMED_ENTITY_EXTRACTION";
    }
    
    export type NamedEntityExtractionLabel = _NamedEntityExtractionLabel;
    
    /** 
     * Translated from class com.dataiku.dip.labeling.Label$ObjectDetectionLabel
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ObjectDetectionLabel extends _Label<BoundingBox> {
        type: "OBJECT_DETECTION";
    }
    
    export type ObjectDetectionLabel = _ObjectDetectionLabel;

    export namespace ImageClassificationLabel {
        /** Type of class com.dataiku.dip.labeling.Label$ImageClassificationLabel in PolyJSON mappings */
        export const type = "IMAGE_CLASSIFICATION";
    }

    export namespace NamedEntityExtractionLabel {
        /** Type of class com.dataiku.dip.labeling.Label$NamedEntityExtractionLabel in PolyJSON mappings */
        export const type = "NAMED_ENTITY_EXTRACTION";
    }

    export namespace ObjectDetectionLabel {
        /** Type of class com.dataiku.dip.labeling.Label$ObjectDetectionLabel in PolyJSON mappings */
        export const type = "OBJECT_DETECTION";
    }
}