<div class="editable-list__input-group">
    <mat-form-field>
        <mat-label></mat-label>
        <input 
            data-qa-dku-list-input
            matInput
            [matAutocomplete]="auto"
            [formControl]="inputControl"
            [type]="type" 
            [name]="name"
            class="editable-list__input"
            placeholder="{{placeholder}}" 
            autocomplete="{{autocomplete}}"
            (focus)="handleFocus($event)" 
            (blur)="handleBlur($event)" 
            [required]="required"
        >

        <mat-autocomplete #auto="matAutocomplete" [disableRipple]="true">
            <mat-option *ngFor="let suggestion of (suggestions || [])" [value]="suggestion" (onSelectionChange)="onSelectionChange($event)" (click)="onSelectionClick($event)">
                {{ suggestion }}
            </mat-option>
        </mat-autocomplete>

    </mat-form-field>
    <div [hidden]="!warnValueIsTrimmable()">
        <div class="editable-list__input-warning-message">Input starts or ends with one or more spaces.</div>
    </div>

    <div [hidden]="!(inputControl.invalid && (inputControl.dirty || inputControl.touched))">
        <div class="editable-list__input-error-message" [hidden]="!(inputControl.errors && inputControl.errors.required)">Please fill this field.</div>
    </div>
</div>
