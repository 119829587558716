import type { AvailableResult } from './../available-result';
import type { TimeSeriesComputation } from './time-series-computation';
import type { _AvailableResult } from './../available-result';
import type { _TimeSeriesComputation } from './time-series-computation';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.DurbinWatson
 * Via: UIModel annotation in the class hierarchy
 */
export interface _DurbinWatson extends _TimeSeriesComputation {
    type: "durbin_watson";
}

export type DurbinWatson = _DurbinWatson;

export namespace DurbinWatson {
    /** Type of class com.dataiku.dip.eda.compute.computations.timeseries.DurbinWatson in PolyJSON mappings */
    export const type = "durbin_watson";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.DurbinWatson$DurbinWatsonResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _DurbinWatsonResult extends _AvailableResult {
        type: "durbin_watson";
        statistic: number;
    }
    
    export type DurbinWatsonResult = _DurbinWatsonResult;

    export namespace DurbinWatsonResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.timeseries.DurbinWatson$DurbinWatsonResult in PolyJSON mappings */
        export const type = "durbin_watson";
    }
}