import type { Abstract2SampTestCard } from './common/abstract2-samp-test-card';
import type { _Abstract2SampTestCard } from './common/abstract2-samp-test-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.MoodTest2SampCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _MoodTest2SampCard extends _Abstract2SampTestCard {
    type: "mood_test_2samp";
}

export type MoodTest2SampCard = _MoodTest2SampCard;

export namespace MoodTest2SampCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.MoodTest2SampCard in PolyJSON mappings */
    export const type = "mood_test_2samp";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.MoodTest2SampCard$MoodTest2SampCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _MoodTest2SampCardResult extends Abstract2SampTestCard._Abstract2SampTestCardResult {
        type: "mood_test_2samp";
        pvalue: number;
        statistic: number;
    }
    
    export type MoodTest2SampCardResult = _MoodTest2SampCardResult;

    export namespace MoodTest2SampCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.MoodTest2SampCard$MoodTest2SampCardResult in PolyJSON mappings */
        export const type = "mood_test_2samp";
    }
}