import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Aggregation, CatalogFacetMap } from '@shared/models';
import { debounceTime } from 'rxjs';
import { DataSourcesTab } from '../shared/models';

@UntilDestroy()
@Component({
    selector: 'data-sources-top-bar',
    templateUrl: './data-sources-top-bar.component.html',
    styleUrls: ['./data-sources-top-bar.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSourcesTopBarComponent implements OnInit, OnChanges {
    @Input() readonly itemCount!: number;
    @Input() readonly facets!: CatalogFacetMap;
    @Input() readonly aggregations! : Record<string, Aggregation>;
    @Input() readonly searchQuery!: string;
    @Input() readonly selectedTab!: DataSourcesTab;

    @Output() searchQueryChange = new EventEmitter<string>();
    @Output() selectedTabChange = new EventEmitter<DataSourcesTab>();
    @Output() facetsChange = new EventEmitter<CatalogFacetMap>();
    @Output() resetFilters = new EventEmitter<void>();

    DataSourcesTab = DataSourcesTab;
    query = new UntypedFormControl(this.searchQuery);
    hasAnyFilter = false;

    constructor() { }

    ngOnInit(): void {
        this.query.valueChanges.pipe(
            debounceTime(200),
            untilDestroyed(this),
        ).subscribe(query => {
            this.searchQueryChange.next(query);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.searchQuery) {
            this.query.setValue(changes.searchQuery.currentValue, {emitEvent: false});
        }
        this.hasAnyFilter = Object.keys(this.facets).some(key => !['scope', '_type'].includes(key) && this.facets[key].length);
    }
}
