import type { Distribution } from './distribution';
import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _Distribution } from './distribution';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';

/** 
 * Translated from class com.dataiku.dip.eda.compute.distributions.NormalMixture
 * Via: UIModel annotation in the class hierarchy
 */
export interface _NormalMixture extends _Distribution {
    type: "normal_mixture";
    nbComponents: number;
}

export type NormalMixture = _NormalMixture;

export namespace NormalMixture {
    /** Type of class com.dataiku.dip.eda.compute.distributions.NormalMixture in PolyJSON mappings */
    export const type = "normal_mixture";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.distributions.NormalMixture$FittedNormalMixture
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FittedNormalMixture extends Distribution._FittedDistribution {
        type: "normal_mixture";
        components: NormalMixture.FittedNormalMixture.Component[];
    }
    
    export type FittedNormalMixture = _FittedNormalMixture;

    export namespace FittedNormalMixture {
        /** Type of class com.dataiku.dip.eda.compute.distributions.NormalMixture$FittedNormalMixture in PolyJSON mappings */
        export const type = "normal_mixture";
        
        /** 
         * Translated from class com.dataiku.dip.eda.compute.distributions.NormalMixture$FittedNormalMixture$Component
         * Via: com.dataiku.dip.eda.compute.distributions.NormalMixture$FittedNormalMixture
         */
        export interface _Component extends _ImmutableValueObject {
            mean: number;
            std: number;
            weight: number;
        }
        
        export type Component = _Component;
    }
}