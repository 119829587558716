import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OneSampleShapiroTestStat, SchemaColumn } from 'generated-sources';
import { StatsRecipeTestSettingsEditorComponent } from '../stats-recipe-test-settings-editor.component';
import { observeInput } from "dku-frontend-core";
import { Observable } from "rxjs";

/**
 * Local data types and helpers
 */

type OneSampleShapiroTestControl = FormGroup<{
    column: FormControl<string | null>;
}>;


/**
 * The main component
 */

@Component({
    selector: 'shapiro-normality-test-recipe-settings',
    templateUrl: './shapiro-normality-test-recipe-settings.component.html',
    styleUrls: ['./shapiro-normality-test-recipe-settings.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShapiroNormalityTestRecipeSettingsComponent extends StatsRecipeTestSettingsEditorComponent<OneSampleShapiroTestStat, OneSampleShapiroTestControl> {

    @Input() columns: SchemaColumn[];
    columns$: Observable<SchemaColumn[]> = observeInput(this, "columns");

    controlFactory(): OneSampleShapiroTestControl {
        return this.formBuilder.group({
            column: this.formBuilder.control<string | null>(null, [Validators.required])
        });
    };
}
