import type { AxisDef } from './../axis-def';
import type { PivotTableRequest } from './../pivot-table-request';
import type { Unaggregated } from './../unaggregated';
import type { _AxisDef } from './../axis-def';
import type { _PivotTableRequest } from './../pivot-table-request';
import type { _Unaggregated } from './../unaggregated';

/** 
 * Translated from class com.dataiku.dip.pivot.backend.model.boxplots.PTBoxplotsRequest
 * Via: UIModel annotation in the class hierarchy
 */
export interface _PTBoxplotsRequest extends _PivotTableRequest {
    column: Unaggregated;
    whiskersMode: PTBoxplotsRequest.WhiskersMode;
    whiskersPercentile: number;
    xAxis: AxisDef;
}

export type PTBoxplotsRequest = _PTBoxplotsRequest;

export namespace PTBoxplotsRequest {
    /** 
     * Translated from enum com.dataiku.dip.pivot.backend.model.boxplots.PTBoxplotsRequest$WhiskersMode
     * Via: com.dataiku.dip.pivot.backend.model.boxplots.PTBoxplotsRequest
     */
    export enum WhiskersMode {
        IQR = "IQR",
        PERCENTILE = "PERCENTILE",
    }
}