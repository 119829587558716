import { AbstractControl } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { observeFormControl } from './form-control-observer';

/*
    Allows for a FormControl element (control) to be disabled/enabled based on the specified value
    of another FormControl (conditionControl).

    The enableValue callback is called if defined when the control is enabled. Angular remove the
    value of the control when it is disabled.
*/
export function toggleFormControl(control: AbstractControl, conditionControl: AbstractControl, conditionValue: any, enableValue?: Function) {
    conditionControl.valueChanges
        .subscribe((controlValue) => {
            // if conditionValue is a function, call it with the controlValue as the parameter
            const condition = typeof conditionValue === 'function' ? conditionValue(controlValue) : controlValue === conditionValue;

            if (condition) {
                control.enable();
                if (enableValue) {
                    enableValue(controlValue);
                }
            } else {
                control.disable();
            }
        });

    return control;
}


type FormStructure = {
    condition: AbstractControl;
    value: unknown | ((str: unknown) => boolean);
    control: AbstractControl;
    children?: FormStructure[];
}

/*
    Problem: toggleFormControl() cannot always be used in a complex form with nested form controls because AbstractControl.enable() and AbstractControl.disable() impacts all the children recurively
    Unfortunately, this behavior is not modifiable and there is no way to tell Angular "enable() this control but do not enable all children".

    However, what we can do is to activate/desactive the controls recurvively from roots to leaves. This is what this function does, based on the form structure.
*/
export function toggleFormControls(globalForm: FormStructure[]) {
    function updateRecursive(form: FormStructure) {
        const condition = typeof form.value === 'function' ? form.value(form.condition.value) : form.condition.value === form.value;
        if (condition) {
            form.control.disabled && form.control.enable();
        } else {
            form.control.enabled && form.control.disable();
        }
        if (form.control.enabled) {
            form.children?.forEach((innerForm) => updateRecursive(innerForm));
        }
    }

    function gatherAllConditions(form: FormStructure[]): AbstractControl[] {
        let conditionControls: AbstractControl[] = [];
        for (const innerForm of form) {
            conditionControls.push(innerForm.condition);
            if (innerForm.children) {
                conditionControls = conditionControls.concat(gatherAllConditions(innerForm.children));
            }
        }
        return conditionControls;
    }

    combineLatest(gatherAllConditions(globalForm).map(control => observeFormControl(control)))
        .subscribe(() => {
            for (const innerForm of globalForm) {
                updateRecursive(innerForm);
            }
        });
}
