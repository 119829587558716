export namespace PreTrainClusteringModelingParams {
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.clustering.PreTrainClusteringModelingParams$Algorithm
     * Via: com.dataiku.dip.analysis.model.clustering.ClusteringModelSnippetData
     */
    export enum Algorithm {
        KMEANS = "KMEANS",
        MiniBatchKMeans = "MiniBatchKMeans",
        SPECTRAL = "SPECTRAL",
        WARD = "WARD",
        DBSCAN = "DBSCAN",
        GMM = "GMM",
        SCIKIT_MODEL = "SCIKIT_MODEL",
        PY_TWO_STEP = "PY_TWO_STEP",
        PY_ISOLATION_FOREST = "PY_ISOLATION_FOREST",
        MLLIB_KMEANS = "MLLIB_KMEANS",
        MLLIB_GAUSSIAN_MIXTURE = "MLLIB_GAUSSIAN_MIXTURE",
        MLLIB_CUSTOM = "MLLIB_CUSTOM",
        MLLIB_TWO_STEP = "MLLIB_TWO_STEP",
        MLLIB_ISOLATION_FOREST = "MLLIB_ISOLATION_FOREST",
        VERTICA_KMEANS = "VERTICA_KMEANS",
        SPARKLING_KMEANS = "SPARKLING_KMEANS",
    }
}