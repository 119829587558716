import type { CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { TimeSeriesCard } from './time-series-card';
import type { UnitRootTestADFCard } from './unit-root-test-adfcard';
import type { UnitRootTestKPSSCard } from './unit-root-test-kpsscard';
import type { UnitRootTestZACard } from './unit-root-test-zacard';
import type { _CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { _TimeSeriesCard } from './time-series-card';
import type { _UnitRootTestADFCard } from './unit-root-test-adfcard';
import type { _UnitRootTestKPSSCard } from './unit-root-test-kpsscard';
import type { _UnitRootTestZACard } from './unit-root-test-zacard';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.UnitRootTestCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _UnitRootTestCard extends _TimeSeriesCard, _CardWithConfidenceLevel {
    confidenceLevel: number;
    nLags?: number | null;
}

/** List of all concrete subclasses of com.dataiku.dip.eda.worksheets.cards.UnitRootTestCard */
export type UnitRootTestCard =
    | UnitRootTestADFCard
    | UnitRootTestKPSSCard
    | UnitRootTestZACard;

export namespace UnitRootTestCard {
    /** Types of class com.dataiku.dip.eda.worksheets.cards.UnitRootTestCard in PolyJSON mappings */
    export const type = ["unit_root_test_adf", "unit_root_test_kpss", "unit_root_test_za", ] as const;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.UnitRootTestCard$UnitRootTestCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _UnitRootTestCardResult extends TimeSeriesCard._TimeSeriesCardResult {
        pValue: number;
        statistic: number;
        usedLag: number;
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.eda.worksheets.cards.UnitRootTestCard$UnitRootTestCardResult */
    export type UnitRootTestCardResult =
        | UnitRootTestADFCard.UnitRootTestADFCardResult
        | UnitRootTestKPSSCard.UnitRootTestKPSSCardResult
        | UnitRootTestZACard.UnitRootTestZACardResult;

    export namespace UnitRootTestCardResult {
        /** Types of class com.dataiku.dip.eda.worksheets.cards.UnitRootTestCard$UnitRootTestCardResult in PolyJSON mappings */
        export const type = ["unit_root_test_adf", "unit_root_test_kpss", "unit_root_test_za", ] as const;
    }
}