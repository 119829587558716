export function setupDebugUtils() {
    // debug shorthand. Don't use anywhere in the code! Just in console
    const w = window as any;
    w.$component = () => {
        // looks for an angular component associated with the last inspected element, going up in the DOM
        function getComponentRec(elt: HTMLElement, currentComponentInstance?: any): HTMLElement {
            const c = w.ng.probe(elt);
            if (c && (!currentComponentInstance || c.componentInstance !== currentComponentInstance)) {
                const instance = c.componentInstance;
                instance.$$HTMLElement = elt;
                if (elt.parentElement) {
                    try {
                        instance.$$parentComponent = getComponentRec(elt.parentElement, instance);
                    } catch (e) {
                        // fine
                    }
                }
                return instance;
            }
            if (elt.parentElement) {
                return getComponentRec(elt.parentElement, currentComponentInstance);
            }
            throw new Error('No Angular component here!');
        }

        return getComponentRec(w.$0);
    };
}
