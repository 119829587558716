import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { CardWizardVariable } from "@features/eda/card-models";
import { CardWizardService } from "@features/eda/worksheet/card-wizard/card-wizard.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { ScatterPlot3DCard } from "src/generated-sources";

@UntilDestroy()
@Component({
    selector: 'scatter-plot-3d-card-config',
    templateUrl: './scatter-plot-3d-card-config.component.html',
    styleUrls: [
        '../../../../shared-styles/card-wizard.less',
        './scatter-plot-3d-card-config.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScatterPlot3DCardConfigComponent implements OnInit, OnChanges, OnDestroy {
    @Input() params: ScatterPlot3DCard;
    @Input() variables: CardWizardVariable[];
    @Output() paramsChange = new EventEmitter<ScatterPlot3DCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    allVariables$: Observable<CardWizardVariable[]>;

    configForm = this.formBuilder.group({
        xColumn: this.formBuilder.control(null, Validators.required),
        yColumn: this.formBuilder.control(null, Validators.required),
        zColumn: this.formBuilder.control(null, Validators.required),
        symbolSize: this.formBuilder.control(null, [
            Validators.min(1),
            Validators.max(20),
            Validators.required
        ]),
        maxNumberOfPoints: this.formBuilder.control(null, [
            Validators.min(1),
            Validators.required,
        ]),
    });

    constructor(
        private formBuilder: UntypedFormBuilder,
        private cardWizardService: CardWizardService
    ) {
        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((formValue) => {
                const {
                    xColumn,
                    yColumn,
                    zColumn,
                    symbolSize,
                    maxNumberOfPoints,
                } = formValue;

                const updatedCard = {
                    ...this.params,
                    xColumn,
                    yColumn,
                    zColumn,
                    symbolSize,
                    maxNumberOfPoints,
                };

                this.paramsChange.emit(updatedCard);
            });

        this.configForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const { valid } = this.configForm;
                this.validityChange.emit(valid);
            });
    }

    ngOnInit() {
        const { type } = this.params;
        this.allVariables$ = this.cardWizardService.availableVariables(type);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            const {
                xColumn,
                yColumn,
                zColumn,
                symbolSize,
                maxNumberOfPoints
            } = this.params;

            this.configForm.patchValue({
                xColumn,
                yColumn,
                zColumn,
                symbolSize,
                maxNumberOfPoints,
            });
        }
    }

    ngOnDestroy() {
        // left empty but required by `untilDestroyed`.
    }
}
