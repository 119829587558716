import type { AuditTrailTargetSettings } from './audit-trail-target-settings';
import type { _AuditTrailTargetSettings } from './audit-trail-target-settings';

/** 
 * Translated from class com.dataiku.dip.security.audit.model.EventServerTargetSettings
 * Via: UIModel annotation in the class hierarchy
 */
export interface _EventServerTargetSettings extends _AuditTrailTargetSettings {
    type: "EVENT_SERVER";
    authKey: string;
    autoconfigureFromNodesDirectory: boolean;
    routingKey: string;
    routingKeyMode: EventServerTargetSettings.RoutingKeyMode;
    trustAllSSLCertificates: boolean;
    url: string;
}

export type EventServerTargetSettings = _EventServerTargetSettings;

export namespace EventServerTargetSettings {
    /** Type of class com.dataiku.dip.security.audit.model.EventServerTargetSettings in PolyJSON mappings */
    export const type = "EVENT_SERVER";
    
    /** 
     * Translated from enum com.dataiku.dip.security.audit.model.EventServerTargetSettings$RoutingKeyMode
     * Via: com.dataiku.dip.security.audit.model.EventServerTargetSettings
     */
    export enum RoutingKeyMode {
        FROM_MESSAGE = "FROM_MESSAGE",
        STATIC = "STATIC",
        STATIC_IF_MISSING = "STATIC_IF_MISSING",
    }
}