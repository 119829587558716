import type { CodeRecipeParams } from './../../code-recipe-params';
import type { SimpleKeyValue } from 'dku-frontend-core/generated-sources/dku-core/src/main/java/com/dataiku/dip/coremodel/simple-key-value';
import type { _CodeRecipeParams } from './../../code-recipe-params';
import type { _SimpleKeyValue } from 'dku-frontend-core/generated-sources/dku-core/src/main/java/com/dataiku/dip/coremodel/simple-key-value';



export namespace HiveRecipeMeta {
    /** 
     * Translated from enum com.dataiku.dip.recipes.code.hive.HiveRecipeMeta$HiveExecutionEngine
     * Via: com.dataiku.dip.recipes.code.hive.HiveRecipeMeta$HiveRecipeParams
     */
    export enum HiveExecutionEngine {
        HIVECLI_LOCAL = "HIVECLI_LOCAL",
        HIVECLI_GLOBAL = "HIVECLI_GLOBAL",
        HIVESERVER2 = "HIVESERVER2",
    }
    /** 
     * Translated from class com.dataiku.dip.recipes.code.hive.HiveRecipeMeta$HiveRecipeParams
     * Via: com.dataiku.dip.recipes.visualsql.VisualSQLRecipeEngineParams
     */
    export interface _HiveRecipeParams extends _CodeRecipeParams {
        addDkuUdf: boolean;
        executionEngine: HiveRecipeMeta.HiveExecutionEngine;
        hiveconf: SimpleKeyValue[];
        inheritConf: string;
        preQueries: string;
    }
    
    export type HiveRecipeParams = _HiveRecipeParams;
}