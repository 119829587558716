import type { BinaryClassificationModelPerf } from './binary-classification-model-perf';
import type { MulticlassModelPerf } from './multiclass-model-perf';

/** 
 * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf
 * Via: UIModel annotation in the class hierarchy
 */
export interface _DeepHubPredictionModelPerf {
}

/** List of all concrete subclasses of com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf */
export type DeepHubPredictionModelPerf =
    | DeepHubPredictionModelPerf.DeepHubImageClassificationPredictionModelPerf
    | DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf;

export namespace DeepHubPredictionModelPerf {
    /** Types of class com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf in PolyJSON mappings */
    export const type = ["DEEP_HUB_IMAGE_CLASSIFICATION", "DEEP_HUB_IMAGE_OBJECT_DETECTION", ] as const;
    
    export function isDeepHubPredictionModelPerf(obj: DeepHubPredictionModelPerf): obj is DeepHubPredictionModelPerf { return ["DEEP_HUB_IMAGE_CLASSIFICATION", "DEEP_HUB_IMAGE_OBJECT_DETECTION", ].includes(obj["type"]); }
    export function isDeepHubImageClassificationPredictionModelPerf(obj: DeepHubPredictionModelPerf): obj is DeepHubPredictionModelPerf.DeepHubImageClassificationPredictionModelPerf { return obj["type"] == "DEEP_HUB_IMAGE_CLASSIFICATION"; }
    export function isDeepHubObjectDetectionPredictionModelPerf(obj: DeepHubPredictionModelPerf): obj is DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf { return obj["type"] == "DEEP_HUB_IMAGE_OBJECT_DETECTION"; }
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubImageClassificationPredictionModelPerf
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _DeepHubImageClassificationPredictionModelPerf extends _DeepHubPredictionModelPerf {
        type: "DEEP_HUB_IMAGE_CLASSIFICATION";
        confusion_matrix: MulticlassModelPerf.MulticlassConfusion;
        densityData: { [key in string]: BinaryClassificationModelPerf.PerClassPredictedProbabilityDensityData };
        metrics: DeepHubPredictionModelPerf.DeepHubImageClassificationPredictionModelPerf.ImageClassificationMetrics;
        oneVsAllAveragePrecision: { [key in string]: number };
        oneVsAllCalibrationCurves: { [key in string]: BinaryClassificationModelPerf.CalibrationBin[] };
        oneVsAllCalibrationLoss: { [key in string]: number };
        oneVsAllPrCurves: { [key in string]: BinaryClassificationModelPerf.PrVizData };
        oneVsAllRocAUC: { [key in string]: number };
        oneVsAllRocCurves: { [key in string]: BinaryClassificationModelPerf.RocVizBin[] };
    }
    
    export type DeepHubImageClassificationPredictionModelPerf = _DeepHubImageClassificationPredictionModelPerf;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _DeepHubObjectDetectionPredictionModelPerf extends _DeepHubPredictionModelPerf {
        type: "DEEP_HUB_IMAGE_OBJECT_DETECTION";
        IOUThreshold: number[];
        confidenceScoreThresholds: number[];
        confusion_matrix: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.ConfusionMatrix;
        metrics: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.ObjectDetectionMetrics;
        optimalConfidenceScoreThreshold: number;
        perCategoryMetrics: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.ObjectDetectionMetrics[];
        perIOU: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf[];
    }
    
    export type DeepHubObjectDetectionPredictionModelPerf = _DeepHubObjectDetectionPredictionModelPerf;

    export namespace DeepHubImageClassificationPredictionModelPerf {
        /** Type of class com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubImageClassificationPredictionModelPerf in PolyJSON mappings */
        export const type = "DEEP_HUB_IMAGE_CLASSIFICATION";
        
        /** 
         * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubImageClassificationPredictionModelPerf$ImageClassificationMetrics
         * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubImageClassificationPredictionModelPerf
         */
        export interface _ImageClassificationMetrics {
            accuracy: number;
            averagePrecision: number;
            f1: number;
            hammingLoss: number;
            logLoss: number;
            mcalibrationLoss: number;
            mrocAUC: number;
            precision: number;
            recall: number;
        }
        
        export type ImageClassificationMetrics = _ImageClassificationMetrics;
    }

    export namespace DeepHubObjectDetectionPredictionModelPerf {
        /** Type of class com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf in PolyJSON mappings */
        export const type = "DEEP_HUB_IMAGE_OBJECT_DETECTION";
        
        /** 
         * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$ConfusionMatrix
         * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf
         */
        export interface _ConfusionMatrix {
            confusionMatrices: number[][][][];
            groundTruthsCounts: number[];
            perConfidenceScoreDetectionsCount: number[][];
        }
        
        export type ConfusionMatrix = _ConfusionMatrix;
        
        /** 
         * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$ObjectDetectionMetrics
         * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf
         */
        export interface _ObjectDetectionMetrics {
            averagePrecisionAllIOU: number;
            averagePrecisionIOU50: number;
            averagePrecisionIOU75: number;
        }
        
        export type ObjectDetectionMetrics = _ObjectDetectionMetrics;
        
        /** 
         * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf
         * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf
         */
        export interface _PerIOUPerf {
            global: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf.PerIOUMetrics;
            iou: number;
            optimalConfidenceScoreThreshold: number;
            perCategory: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf.PerIOUMetrics[];
            precisionRecallCurve: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf.PrecisionRecallCurve;
        }
        
        export type PerIOUPerf = _PerIOUPerf;

        export namespace PerIOUPerf {
            /** 
             * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf$PerIOUMetrics
             * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf
             */
            export interface _PerIOUMetrics {
                averagePrecision: number;
            }
            
            export type PerIOUMetrics = _PerIOUMetrics;
            
            /** 
             * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf$PrecisionRecallCurve
             * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf
             */
            export interface _PrecisionRecallCurve {
                confidenceOfBest: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf.PrecisionRecallCurve.BestConfidence;
                curve: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf.PrecisionRecallCurve.Curve;
            }
            
            export type PrecisionRecallCurve = _PrecisionRecallCurve;

            export namespace PrecisionRecallCurve {
                /** 
                 * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf$PrecisionRecallCurve$BestConfidence
                 * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf$PrecisionRecallCurve
                 */
                export interface _BestConfidence {
                    f1: number;
                    precision: number;
                    recall: number;
                }
                
                export type BestConfidence = _BestConfidence;
                
                /** 
                 * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf$PrecisionRecallCurve$Curve
                 * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf$PrecisionRecallCurve
                 */
                export interface _Curve {
                    confidence: number[];
                    f1: number[];
                    precision: number[];
                    recall: number[];
                }
                
                export type Curve = _Curve;
            }
        }
    }
}