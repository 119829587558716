import { Injectable } from '@angular/core';
import { format, formatLocale, formatDefaultLocale, FormatLocaleDefinition } from 'd3-format';
import { cloneDeep, isInteger } from 'lodash';
import { UnitSymbol } from '../../enums';
import { FrontendNumberFormattingOptions, MultiplierInfo } from '../../interfaces';
import { ChartStaticDataService } from '../chart-static-data.service';
import { MathUtilsService } from './math-utils.service';
import { GroupingSymbol } from '@features/simple-report/enums/symbols/grouping-symbol.enum';
import { DigitGrouping } from '@features/simple-report/interfaces/digit-grouping.interface';
import _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
/**
 * Context-agnostic (supposingly) set of utils to format numbers
 * (!) This service previously was in:
 * - static/dataiku/js/simple_report/services/formatting/number-formatter.service.js
 * - static/dataiku/js/simple_report/chart_view_common.js
 */
export class NumberFormatterService {
    private formatPrefixes = ['y', 'z', 'a', 'f', 'p', 'n', 'µ', 'm', '',
        UnitSymbol.K, UnitSymbol.M, UnitSymbol.G, UnitSymbol.T, UnitSymbol.P, UnitSymbol.E, UnitSymbol.Z, UnitSymbol.Y]
        .map((prefix, index) => this.formatPrefix(prefix, index));

    private DEFAULT_FORMAT_LOCALE_DEFINITION: FormatLocaleDefinition = {
        minus: '-',
        thousands: ',',
        grouping: [3],
        currency: ['$', ''],
        decimal: '.'
    }

    constructor(
        private chartStaticDataService: ChartStaticDataService,
        private mathUtilsService: MathUtilsService
    ) {
        //  This is mandatory as d3-format uses a different minus character: "−" (U+2212) instead of "-"
        formatDefaultLocale(this.DEFAULT_FORMAT_LOCALE_DEFINITION);
    }

    private formatPrecision(x: number, p: number) {
        return p - (x ? Math.ceil(Math.log(x) / Math.LN10) : 1);
    }

    private formatPrefix(d: string, i: number) {
        const k = Math.pow(10, Math.abs(8 - i) * 3);
        return {
            scale: i > 8 ? function (d: number) {
                return d / k;
            } : function (d: number) {
                return d * k;
            },
            symbol: d
        };
    }

    private round(x: number, n: number) {
        return n ? Math.round(x * (n = Math.pow(10, n))) / n : Math.round(x);
    }

    private computeD3LocalizedFormatter(digitGroupingOptions: DigitGrouping) {
        const formatLocaleDefinition = {
            ...this.DEFAULT_FORMAT_LOCALE_DEFINITION,
            decimal: digitGroupingOptions.decimalSymbol,
            thousands: digitGroupingOptions.groupingSymbol
        };

        return formatLocale(formatLocaleDefinition).format;
    }

    private computeD3LocalizedFormatterWithComma() {
        const formatLocaleDefinition = {
            ...this.DEFAULT_FORMAT_LOCALE_DEFINITION,
            thousands: ","
        };

        return formatLocale(formatLocaleDefinition).format;
    }

    private getBasicSpecifierPrecisionPart(decimalPlaces: number, type = 'f', stripZeros = false) {
        return `.${ decimalPlaces }${ stripZeros ? '~' : '' }${ type }`;
    }

    // Compute the most appropriate decimal places specifier depending on given formatting options
    private getSpecifierPrecisionPart(formattingOptions: FrontendNumberFormattingOptions) {

        // Apply custom precision if initially computed
        if (formattingOptions.customPrecision) {
            return formattingOptions.customPrecision;

            // Fallback in priority on the the decimal places manually set by the user if some
        } else if (_.isNumber(formattingOptions.decimalPlaces)) {
            return this.getBasicSpecifierPrecisionPart(formattingOptions.decimalPlaces, 'f', formattingOptions.stripZeros);

            // Or the given minimum decimals that could be context-specific
        } else if (_.isNumber(formattingOptions.minDecimals) && formattingOptions.minDecimals !== null) {
            return this.getBasicSpecifierPrecisionPart(Math.max(0, formattingOptions.minDecimals), 'f', formattingOptions.stripZeros);
        }

        // If none of the above, default to no decimal places
        return '.0f';
    }


    private autocompleteFormattingOptions(formattingOptions?: FrontendNumberFormattingOptions): FrontendNumberFormattingOptions {

        const finalFormattingOptions = _.isObject(formattingOptions) ? _.cloneDeep(formattingOptions) : {};

        // Detect if should format in percentage if unspecified
        if (finalFormattingOptions.shouldFormatInPercentage === undefined) {
            finalFormattingOptions.shouldFormatInPercentage = this.shouldFormatInPercentage(finalFormattingOptions);
        }

        // Retrieve digit grouping options from its identifier (if specified)
        const digitGroupingOptions = finalFormattingOptions.digitGrouping && this.chartStaticDataService.availableDigitGrouping[finalFormattingOptions.digitGrouping];

        if (digitGroupingOptions) {
            finalFormattingOptions.d3LocalizedFormatter = this.computeD3LocalizedFormatter(digitGroupingOptions);
        } else if (finalFormattingOptions.comma) { 
            finalFormattingOptions.d3LocalizedFormatter = this.computeD3LocalizedFormatterWithComma();
        }

        // Deduce a coma option if unspecified
        // For obscure reasons, d3 expects "comma" to be "," if you want to group digits (no matter if it's a comma or not)...
        if (!finalFormattingOptions.comma) {
            if(digitGroupingOptions) {
                finalFormattingOptions.comma = (digitGroupingOptions && digitGroupingOptions.groupingSymbol !== GroupingSymbol.NONE) ? ',' : '';
            } else {
                finalFormattingOptions.comma = '';
            }
        }

        // If the user has manually set a number of decimal places, compute right away the corresponding part of d3's specifier
        if (finalFormattingOptions.decimalPlaces !== undefined && finalFormattingOptions.decimalPlaces !== null) {
            finalFormattingOptions.customPrecision = this.getBasicSpecifierPrecisionPart(finalFormattingOptions.decimalPlaces);
        }

        // Sometimes "multiplier" can be the multiplier label, then we need to retrieve the proper multiplier object
        if (finalFormattingOptions.multiplier && !(finalFormattingOptions.multiplier?.label)) {
            finalFormattingOptions.multiplier = this.getMultiplierFromLabel(finalFormattingOptions.multiplier);
        }

        // If user hasn't chosen a particular precision, we'll force the stripping of zeros.
        if (finalFormattingOptions.stripZeros === undefined && !finalFormattingOptions.customPrecision) {
            finalFormattingOptions.stripZeros = true;
        }

        return finalFormattingOptions;
    }


    private getNumberOfDigitsBeforeDecimalPoint(x: number) {
        const absX = Math.abs(x);
        const logX = this.mathUtilsService.log10(absX);

        if (logX < 0) {
            return 0;
        } else {
            return 1 + (logX | 0); // | 0 is quick way to floor
        }
    }

    private getSmartPrecision(x: number, maxDigits = 5) {

        const nbDigitsBeforeDecimalPoint = this.getNumberOfDigitsBeforeDecimalPoint(x);
        let nbDecimals = maxDigits - nbDigitsBeforeDecimalPoint; // Ideally we do not want numbers that exceed 9 digits in total

        nbDecimals = Math.max(2, nbDecimals); // Yet we want a minimum accuracy of 2 decimals (meaning that in some cases, we can go up to 11 digits)

        // Avoid getting remaining trailing zeros after rounding
        const roundedX = Math.round(x * Math.pow(10, nbDecimals)) / Math.pow(10, nbDecimals);

        /*
         * To find the last significant number in x, multiply x by decreasing powers of 10 and check if it's still an integer
         * The number of loops is minimised by starting the search with the max number of decimals that can be displayed
         */
        let i;

        for (i = nbDecimals - 1; i > 0; i--) {
            if (!isInteger(roundedX * Math.pow(10, i))) {
                break;
            }
        }
        return i + 1;
    };

    /*
     * This method and its dependencies are parts of the code retrieved in previous versions of d3
     * It is useful to get the precision and the symbol and precision of a given value
     */
    getPrefix(value: number, precision?: number) {
        let i = 0;
        if (value) {
            if (value < 0) {
                value *= -1;
            }
            if (precision) {
                value = this.round(value, this.formatPrecision(value, precision));
            }
            i = 1 + Math.floor(1e-12 + Math.log(value) / Math.LN10);
            i = Math.max(-24, Math.min(24, Math.floor((i - 1) / 3) * 3));
        }
        return this.formatPrefixes[8 + i / 3];
    }

    isPercentScale(measures: Array<any>): boolean {
        return measures.every((measure) => this.chartStaticDataService.MEASURES_PERCENT_MODES.includes(measure.computeMode));
    }

    shouldFormatInPercentage(measureOptions: any): boolean {
        return measureOptions && this.isPercentScale([measureOptions]);
    }

    appendPrefixAndSuffix(value: string, prefix = '', suffix = '', isPercentage = false): string {
        return prefix + value + (isPercentage ? '%' : '') + suffix;
    }

    getMinDecimals(minValue: number, maxValue: number, numValues: number): number | null {

        // Suppose the values are evenly spaced, the minimum display precision we need is:
        const minPrecision = (maxValue - minValue) / numValues;

        // That means we need to have that many decimals: (can be negative: -1 means we don't even need the units number, -2 the hundreds, etc)
        return minPrecision > 0 ? Math.ceil(-this.mathUtilsService.log10(minPrecision)) : null;
    }

    getMultiplierFromLabel(multiplierLabel: any): MultiplierInfo {
        const multiplier = Object.values(this.chartStaticDataService.availableMultipliers).find(multiplier => multiplier.label === multiplierLabel);
        return multiplier ? multiplier : this.chartStaticDataService.highestMultiplier;
    }

    /**
     * Manually applies the asked formatting options to the given value, except from prefix, suffix and percentage symbol
     *
     * @param   {number}  value                                         - Number to format.
     * @param   {object}  formattingOptions                             - Object containing user preferences for formatting.
     * @param   {boolean} [formattingOptions.shouldFormatInPercentage]  - True to append a %.
     * @returns {string} value, formatted as per the provided options.
     */
    getManuallyFormattedValue(value: any, formattingOptions: FrontendNumberFormattingOptions): string {
        let unitPrefixSymbol = '';
        let powerOfTen = 0;
        let formattedValue = value;
        let minDecimals = formattingOptions.minDecimals;
        const finalFormattingOptions = _.cloneDeep(formattingOptions);

        if (value !== 0) {
            // A number displayed in percentages cannot have a multiplier.
            if (!finalFormattingOptions.shouldFormatInPercentage && finalFormattingOptions.multiplier) {
                unitPrefixSymbol = finalFormattingOptions.multiplier.symbol || '';
                powerOfTen = finalFormattingOptions.multiplier.powerOfTen as number;
            }

            if (powerOfTen > 0) {
                formattedValue = value / Math.pow(10, powerOfTen);
                if (_.isNumber(formattingOptions.minDecimals)) {
                    minDecimals = formattingOptions.minDecimals + powerOfTen;
                }
            } else if (finalFormattingOptions && finalFormattingOptions?.multiplier?.label === this.chartStaticDataService.noneMultiplier.label) {
                minDecimals = this.getSmartPrecision(value);
            }
        }

        // If digit grouping is set, we expect to format from the given localized formatter
        if (finalFormattingOptions.d3LocalizedFormatter) {
            let specifier = finalFormattingOptions.comma;

            if (_.isNumber(formattingOptions.decimalPlaces) || (_.isNumber(minDecimals) && minDecimals !== null)) {
                specifier += this.getSpecifierPrecisionPart({ ...finalFormattingOptions, minDecimals: minDecimals });
            } else { // No constraint on the number of decimals, compute the smartest precision
                specifier += this.getBasicSpecifierPrecisionPart(this.getSmartPrecision(formattedValue), 'f', formattingOptions.stripZeros);
            }
            
            formattedValue = finalFormattingOptions.d3LocalizedFormatter(specifier)(formattedValue);
        } else { // Else there's no digit grouping, formatting using toFixed
            if (_.isNumber(finalFormattingOptions.decimalPlaces)) {
                formattedValue = formattedValue.toFixed(finalFormattingOptions.decimalPlaces);
            } else if (_.isNumber(minDecimals) && minDecimals !== null) {
                formattedValue = formattedValue.toFixed(Math.max(0, minDecimals));
            }
        }

        return `${ formattedValue }${ unitPrefixSymbol }`;
    }

    /**
     * Manually formats the given value using the given formatting options.
     *
     * To use when a multiplier has been selected (ie is not Auto), because we cannot force a specific unit prefix while formatting with D3 formatting helpers.
     *
     * @param   {number}  value                                         - Number to format.
     * @param   {object}  formattingOptions                             - Object containing user preferences for formatting.
     * @param   {number}  formattingOptions.minDecimals                 - Default number of decimals to keep. (can be negative: -1 means we don't even need the units number, -2 the hundreds, etc)
     * @param   {number}  [formattingOptions.decimalPlaces]       - Number of digits to keep after the decimal point.
     * @param   {object}  formattingOptions.multiplier                  - A multiplier from ChartsStaticData.allMultipliers or ChartsStaticData.Multipliers
     * @param   {boolean} [formattingOptions.shouldFormatInPercentage]  - True to append a %.
     * @returns {string} value, formatted as per the provided options.
     */
    formatValueManually(value: any, formattingOptions: any): string {
        return this.appendPrefixAndSuffix(
            this.getManuallyFormattedValue(value, formattingOptions),
            formattingOptions.prefix,
            formattingOptions.suffix,
            formattingOptions.shouldFormatInPercentage
        );
    }

    /**
     * Get the label matching the given prefix symbol according to the supported multipliers list.
     * @param   {string}    unitPrefixSymbol            - A symbol of a SI-prefix (k, M, G...).
     * @returns A multiplier object from ChartsStaticData.allMultipliers
     */
    getMultiplierFromUnitPrefixSymbol(unitPrefixSymbol: UnitSymbol): MultiplierInfo {
        // In DSS we chose to display billions as 'B' and not 'G' like in d3.
        unitPrefixSymbol = unitPrefixSymbol === UnitSymbol.G ? UnitSymbol.B : unitPrefixSymbol;
        const multiplier = Object.values(this.chartStaticDataService.allMultipliers).find(multiplier => multiplier.symbol === unitPrefixSymbol);
        return multiplier ? multiplier : this.chartStaticDataService.highestMultiplier;
    }

    /**
     * Format the given value knowing the unit prefix symbol to use.
     *
     * @param   {number}    value                                           - Number to format.
     * @param   {object}    formattingOptions                               - Object containing user preferences for formatting.
     * @param   {string}    formattingOptions.unitPrefixSymbol              - A symbol of a SI-prefix (k, M, G...).
     * @param   {number}    formattingOptions.minDecimals                   - Default number of decimals to keep. (can be negative: -1 means we don't even need the units number, -2 the hundreds, etc)
     * @param   {number}    [formattingOptions.decimalPlaces]         - Number of digits to keep after the decimal point.
     * @param   {string}    [formattingOptions.prefix]                      - Optional string to add before the formatted number
     * @param   {string}    [formattingOptions.suffix]                      - Optional string to add after the formatted number
     * @param   {boolean}   [formattingOptions.shouldFormatInPercentage]    - True to append a %.
     * @returns {string} value, formatted as per the provided options.
     */
    formatFromPrefix(value: any, formattingOptions: any): string {
        const formattingFromPrefixOptions = cloneDeep(formattingOptions);

        // A number displayed in percentages cannot have a multiplier.
        if (!formattingFromPrefixOptions.shouldFormatInPercentage) {
            formattingFromPrefixOptions.multiplier = this.getMultiplierFromUnitPrefixSymbol(formattingFromPrefixOptions.unitPrefixSymbol);
        }

        return this.formatValueManually(value, formattingFromPrefixOptions);
    }

    formatWithD3(value: any, formatter = format, specifier: any, prefix: string, suffix: string, isPercentage: boolean): string {
        // Default d3 suffix 'G' for billions must be replaced by 'B'
        return this.appendPrefixAndSuffix(formatter(specifier)(value).replace(/G/, 'B'), prefix, suffix, isPercentage);
    }

    /**
     * Format the given value in the more human-readable manner taking into account given formatting options:
     * 
     * - Get the best default multiplier
     * 
     * - Apply the best precision depending on the context:
     *  - The user-defined one in priority, 
     *  - Else the minDecimals one (deduced from a range of values, for instance for formatting axes)
     *  - Or a smart one, depending on the value only
     * 
     * - Use scientific notation for small number
     *
     * @param   {number}    value                                         - Number to format.
     * @param   {object}    formattingOptions                             - Object containing user preferences for formatting.
     * @param   {number}    formattingOptions.minDecimals                 - Number of decimals to keep. (can be negative: -1 means we don't even need the unit prefix, -2 the hundreds, etc)
     * @param   {string}    [formattingOptions.comma]                     - Comma separator character.
     * @param   {boolean}   [formattingOptions.stripZeros]                - True to remove trailing zeros after the decimal point.
     * @param   {object}    [formattingOptions.measureOptions]            - Measure object containing user preferences for formatting.
     * @param   {boolean}   [formattingOptions.shouldFormatInPercentage]  - True to append a %.
     * @param   {number}    [formattingOptions.decimalPlaces]             - Number of digits to keep after the decimal point.
     * @param   {string}    [formattingOptions.customPrecision]           - Part of a specifier dedicated to precision to be used with d3.format().
     * @returns {string} value, formatted as per the provided options.
     */
    formatValueAutomatically(value: any, formattingOptions: any) {
        const abs = Math.abs(value);
        const formatter = formattingOptions.d3LocalizedFormatter ? formattingOptions.d3LocalizedFormatter : format;

        // If the number is too low, we don't apply any unit prefix and convert it in scientific notation unless if a custom precision is given.
        if (abs < 0.00001 && !formattingOptions.customPrecision) {
            const scientificNotation = value.toExponential(Math.max(0, Math.max(formattingOptions.minDecimals, 0) - Math.floor(-this.mathUtilsService.log10(value)) - 1));
            return this.appendPrefixAndSuffix(scientificNotation, formattingOptions.prefix, formattingOptions.suffix, formattingOptions.shouldFormatInPercentage);
        
        // Else if we need decimals (and user hasn't specified a custom precision) we format with the required decimals and strip zeros if asked.
        } else if (formattingOptions.minDecimals > 0 && !formattingOptions.customPrecision) {
            const valueWithPrecision = formatter(`${ formattingOptions.comma }${ this.getSpecifierPrecisionPart(formattingOptions) }`)(value);
            return this.appendPrefixAndSuffix(valueWithPrecision, formattingOptions.prefix, formattingOptions.suffix, formattingOptions.shouldFormatInPercentage);

        // Else if we don't need decimals but we still need to display a unit prefix (at least '10k')
        } else if (abs >= 10000) {
            // User-defined precision first 
            if (formattingOptions.customPrecision) {
                /*
                 * d3.format can append the unit suffix thanks to "s" specifier but when doing so, it also rounds the value.
                 * In order to automatically get the unit prefix + apply custom places, we must go manual.
                 */
                const d3UnitPrefix = this.getPrefix(value);
                formattingOptions.unitPrefixSymbol = d3UnitPrefix.symbol;
                return this.formatFromPrefix(value, formattingOptions);
            
            // Else the computed one if some
            } else if (_.isNumber(formattingOptions.minDecimals)) {
                /*
                 * We trim the number based on minDecimals (<0): this will round and replace the last digits by zero
                 * (e.g. minDecimals = -4, x = 123456, => trimmedX = 120000)
                 */
                const trimmedValue = Math.round(value * Math.pow(10, formattingOptions.minDecimals)) * Math.pow(10, -formattingOptions.minDecimals);
                // Then we ask d3 to write the trimmed number with a unit prefix
                const d3UnitPrefix = this.getPrefix(trimmedValue);
                const prefixed = d3UnitPrefix.scale(trimmedValue) + d3UnitPrefix.symbol;

                /*
                 * Because it's been trimmed, prefixed can be 120k if the value was 123456
                 * In this case, we want to return 123k (as concise + more precise),
                 * so we just use the length of prefixed as reference and let d3 do the rest
                 */
                return this.formatWithD3(value, formatter, '.' + (prefixed.replace(/\D/g, '').length) + 's', formattingOptions.prefix, formattingOptions.suffix, formattingOptions.shouldFormatInPercentage);
            } else { // No constraint on the number of decimals, go for a decimal notation with an SI prefix, rounded to significant digits.
                return this.formatWithD3(value, formatter, '~s', formattingOptions.prefix, formattingOptions.suffix, formattingOptions.shouldFormatInPercentage);
            }
        }

        // Else we'll apply requested precision. If there's no particular instruction for precision, we'll compute a smart one (and strip zeros)
        const precision = formattingOptions.customPrecision 
            ? formattingOptions.customPrecision 
            : _.isNumber(formattingOptions.minDecimals) 
            ? this.getBasicSpecifierPrecisionPart(Math.max(0, formattingOptions.minDecimals), 'f', true)
            : this.getBasicSpecifierPrecisionPart(this.getSmartPrecision(value), 'f', true);

        let specifier = `${ formattingOptions.comma }${ precision }`;
        
        return this.formatWithD3(value, formatter, specifier, formattingOptions.prefix, formattingOptions.suffix, formattingOptions.shouldFormatInPercentage);
    }

    get(
        formattingOptions?: FrontendNumberFormattingOptions
    ): (value: any) => string {

        const finalFormattingOptions = this.autocompleteFormattingOptions(formattingOptions);

        return (value: any) => {

            if (value === '___dku_no_value___') {
                return 'No value';
            }

            if (typeof value !== 'number') {
                return 'NA';
            }

            if (finalFormattingOptions.shouldFormatInPercentage) {
                value = value * 100;
            }
            const arbitraryPrecisionZero = value > -1e-8 && value < 1e-8;

            if (value === 0 || arbitraryPrecisionZero) {
                return this.appendPrefixAndSuffix('0', finalFormattingOptions.prefix, finalFormattingOptions.suffix, finalFormattingOptions.shouldFormatInPercentage);
            } else if (!finalFormattingOptions.shouldFormatInPercentage && (finalFormattingOptions && finalFormattingOptions.multiplier
                && finalFormattingOptions.multiplier.label !== this.chartStaticDataService.autoMultiplier.label)) {
                return this.formatValueManually(value, finalFormattingOptions);
            } else {
                return this.formatValueAutomatically(value, finalFormattingOptions);
            }
        };
    }

    // Wrapper of get() that first computes the minimum decimal places from a given range and adapt the options to build a formattingOptions object.
    // (!) If you need to pass something from the measure options to the formatting options, it's need to be done manually
    getForRange(
        minValue: number,
        maxValue: number,
        numValues: number,
        commaSeparator?: boolean,
        stripZeros?: boolean,
        measureOptions?: any,
        shouldFormatInPercentage?: boolean,
    ): (value: any) => string {

        shouldFormatInPercentage = shouldFormatInPercentage != undefined ? shouldFormatInPercentage : this.shouldFormatInPercentage(measureOptions);

        if (shouldFormatInPercentage) {
            minValue = minValue * 100;
            maxValue = maxValue * 100;
        }
       
        return (value): string => {
            const digitGroupingOptions = measureOptions && measureOptions.digitGrouping && this.chartStaticDataService.availableDigitGrouping[measureOptions.digitGrouping];
            const formattingOptions: FrontendNumberFormattingOptions = {
                multiplier: measureOptions && measureOptions.multiplier,
                prefix: measureOptions && measureOptions.prefix,
                suffix: measureOptions && measureOptions.suffix,
                decimalPlaces: measureOptions && measureOptions.decimalPlaces,
                digitGrouping: measureOptions && measureOptions.digitGrouping,
                shouldFormatInPercentage,
                minDecimals: this.getMinDecimals(minValue, maxValue, numValues),
                // For obscure reasons, d3 expects "comma" to be "," if you want to group digits (no matter if it's a comma or not)...
                comma: (digitGroupingOptions && digitGroupingOptions.groupingSymbol !== GroupingSymbol.NONE) ? ',' : (commaSeparator ? ',' : ''),
                stripZeros: stripZeros
            }
            return this.get(formattingOptions)(value);
        }
    }

    /**
     * This method belongs originally to number-formatting.filters.js and has been retrieved here
     * because we can't upgrade filters in Angular, so it is defined here and then downgraded to be used
     * as a filter in number-formatting.filters.js (longReadableNumber).
     */
    longReadableNumberFilter() {
        const digitFormatters: any[] = [];

        // All these keys of the locale need to be defined to avoid formatLocale to crash
        const modifiedLocale: FormatLocaleDefinition = {
            decimal: '.',
            thousands: '\xa0',
            grouping: [3],
            currency: ['', '']
        };

        for (let i = 0; i <= 9; i++) {
            digitFormatters.push(formatLocale(modifiedLocale).format(',' + this.getBasicSpecifierPrecisionPart(i))); //,.Xf uses a comma for a thousands separator and will keep X decimals
        }

        // We're dealing with a number here, but the method should be able to work with other things than a number.
        return (x: any) => {

            if (isNaN(x as number)) {
                if (typeof x === 'string') {
                    return x;
                } else if (typeof x.toString === 'function') {
                    return x.toString();
                } else {
                    return x;
                }
            }

            const abs_x = Math.abs(x as number);

            if (isInteger(abs_x)) {
                return formatLocale(modifiedLocale).format(',')(x as number);
            }

            if (x === 0) {
                return '0';
            }

            const nbDecimals = this.getSmartPrecision(x as number, 9);

            return digitFormatters[nbDecimals](x);
        };
    }

    /**
     * This method belongs originally to number-formatting.filters.js and has been retrieved here
     * because we can't upgrade filters in Angular, so it is defined here and then downgraded to be used
     * as a filter in number-formatting.filters.js (longSmartNumber).
     * 
     * Good looking long numbers: 
     * - Contains coma thousands separator by default (returns 123,456.78 instead of 123456.78)
     * - Computes the most suitable number of decimal places
     * 
     * Will consider formatting options in priority. (Could return 123.456,78 or 123 456,78 for instance)
     * 
     */
    longSmartNumberFilter() {

        const digitSpecifiers: any[] = [];

        for (let i = 0; i < 6; i++) {
            digitSpecifiers.push(',' + this.getBasicSpecifierPrecisionPart(i));
        }

        return (x: any, formattingOptions: FrontendNumberFormattingOptions) => {
            if (typeof x != 'number') {
                return 'NA';
            }

            let finalFormattingOptions;
            let formatter = format;
            
            if (_.isObject(formattingOptions)) {
                finalFormattingOptions = this.autocompleteFormattingOptions(formattingOptions);
                if (_.isFunction(finalFormattingOptions.d3LocalizedFormatter)) {
                    formatter = finalFormattingOptions.d3LocalizedFormatter;
                }
            }

            // Take into account user's choice for decimal places in priority, else compute the most suited one
            if (finalFormattingOptions && _.isNumber(finalFormattingOptions.decimalPlaces)) {
                return formatter(this.getBasicSpecifierPrecisionPart(finalFormattingOptions.decimalPlaces))(x);
            }

            const abs_x = Math.abs(x);

            if (this.mathUtilsService.isInteger(abs_x)) {
                return formatter(',')(x);
            }

            if (this.mathUtilsService.isInteger(abs_x * 100)) {
                return formatter(',' + this.getBasicSpecifierPrecisionPart(2))(x);
            }

            if (x === 0) {
                return '0';
            }

            const heavyWeight = 1 - (this.mathUtilsService.log10(abs_x) | 0);

            const nbDecimals = Math.max(2, -heavyWeight + 2);

            if (nbDecimals < 6) {
                return formatter(digitSpecifiers[nbDecimals])(x);
            } else {
                const finalValue = x.toPrecision(4);
                return finalFormattingOptions?.d3LocalizedFormatter
                    ? finalFormattingOptions.d3LocalizedFormatter(finalValue)
                    : finalValue;
            }
        };
    }

    /**
     * This method belongs originally to number-formatting.filters.js and has been retrieved here
     * because we can't upgrade filters in Angular, so it is defined here and then downgraded to be used
     * as a filter in number-formatting.filters.js (smartNumber).
     */
    smartNumberFilter() {
        // Short representation of number.
        const expFormatter = (formatter: Function, value: number) => {
            return formatter(this.getBasicSpecifierPrecisionPart(2, 'e'))(value);
        }

        const siFormatter = (formatter: Function, value: number, decimalPlaces?: number,) => {
            if (_.isNumber(decimalPlaces)) {
                /*
                * d3.format() can append the unit prefix thanks to "s" specifier but when doing so, it also rounds the value.
                * In order to automatically get the unit prefix + apply custom places, we must go manual.
                */
                const d3UnitPrefix = this.getPrefix(value);

                switch (d3UnitPrefix.symbol) {
                    case UnitSymbol.K: value = value / 1000; break;
                    case UnitSymbol.M: value = value / 1000000; break;
                    case UnitSymbol.G: value = value / 1000000000; break;
                }

                const fixedValue: string = value.toFixed(decimalPlaces);

                return fixedValue + d3UnitPrefix.symbol;
            } else {
                return formatter(this.getBasicSpecifierPrecisionPart(2, 's'))(value);
            }
        };

        const digitSpecifiers: string[] = [];

        for (let i = 0; i < 6; i++) {
            digitSpecifiers.push(this.getBasicSpecifierPrecisionPart(i));
        }

        const formatWithDecimalPlaces = (formatter: Function, value: any, defaultDecimalPlaces: any, customDecimalPlaces?: number) => {
            return _.isNumber(customDecimalPlaces)
                ? formatter(this.getBasicSpecifierPrecisionPart(customDecimalPlaces))(value)
                : formatter(digitSpecifiers[defaultDecimalPlaces])(value);
        };


        return (d: any, formattingOptions: FrontendNumberFormattingOptions) => {
            if (typeof d != 'number') {
                return 'NA';
            }

            let finalFormattingOptions;
            let formatter = format;
            
            if (_.isObject(formattingOptions)) {
                finalFormattingOptions = this.autocompleteFormattingOptions(formattingOptions);
                if (_.isFunction(finalFormattingOptions.d3LocalizedFormatter)) {
                    formatter = finalFormattingOptions.d3LocalizedFormatter;
                }
            }

            const abs = Math.abs(d);

            let decimalPlaces;

            // Take into account user's choice for decimal places in priority, else compute the most suited one
            if (finalFormattingOptions && _.isNumber(finalFormattingOptions.decimalPlaces)) {
                decimalPlaces = finalFormattingOptions.decimalPlaces;
            }

            if (abs >= 1e12) {
                return expFormatter(formatter, d);
            } else if (abs >= 100000) {
                return siFormatter(formatter, d, decimalPlaces);
            } else if (abs >= 100) {
                return formatWithDecimalPlaces(formatter, d, 0, decimalPlaces);
            } else if (abs >= 1) {
                if (abs % 1 === 0) {
                    return formatWithDecimalPlaces(formatter, d, 0, decimalPlaces);
                }
                return formatWithDecimalPlaces(formatter, d, 2, decimalPlaces);
            } else if (abs === 0) {
                return formatWithDecimalPlaces(formatter, d, 0, decimalPlaces);
            } else if (abs < 0.00001) {
                const finalValue = d.toPrecision(3);
                return finalFormattingOptions?.d3LocalizedFormatter
                    ? finalFormattingOptions.d3LocalizedFormatter(finalValue)
                    : finalValue;
            } else {
                if (_.isNumber(decimalPlaces)) {
                    return formatter(this.getBasicSpecifierPrecisionPart(decimalPlaces))(d);
                } else {
                    const x = Math.min(5, 2 - (this.mathUtilsService.log10(abs) | 0));
                    return formatter(digitSpecifiers[x])(d);
                }
            }
        };
    }

    /**
     * This method belongs originally to number-formatting.filters.js and has been retrieved here
     * because we can't upgrade filters in Angular, so it is defined here and then downgraded to be used
     * as a filter in number-formatting.filters.js (percentageNumber).
     */
    percentageNumberFilter() {
        return (value: number, formattingOptions: any) => {
            let finalFormattingOptions;
            let formatter = format;
            
            if (_.isObject(formattingOptions)) {
                finalFormattingOptions = this.autocompleteFormattingOptions(formattingOptions);
                if (_.isFunction(finalFormattingOptions.d3LocalizedFormatter)) {
                    formatter = finalFormattingOptions.d3LocalizedFormatter;
                }
            }

            const specifierPrecisionPart = finalFormattingOptions
                ? this.getSpecifierPrecisionPart(finalFormattingOptions)
                : '.0';

            return formatter(specifierPrecisionPart + '%')(value);
        };
    }
}
