import type { DatasetSelection } from './dataset-selection';
import type { DatasetSelectionToMemTable } from './dataset-selection-to-mem-table';
import type { StreamableDatasetSelection } from './streamable-dataset-selection';
import type { _DatasetSelection } from './dataset-selection';
import type { _DatasetSelectionToMemTable } from './dataset-selection-to-mem-table';
import type { _StreamableDatasetSelection } from './streamable-dataset-selection';

/** 
 * Translated from class com.dataiku.dip.datasets.SamplingParam
 * Via: UIModel annotation in the class hierarchy
 */
export interface _SamplingParam {
    ascending: boolean;
    column?: string | null;
    maxReadUncompressedBytes: number;
    maxRecords: number;
    samplingMethod: SamplingParam.SamplingMethod;
    seed?: number | null;
    targetRatio: number;
    withinFirstN: number;
}

/** List of all concrete subclasses of com.dataiku.dip.datasets.SamplingParam */
export type SamplingParam =
    | DatasetSelection
    | DatasetSelectionToMemTable
    | _SamplingParam
    | StreamableDatasetSelection;

export namespace SamplingParam {
    /** 
     * Translated from enum com.dataiku.dip.datasets.SamplingParam$SamplingMethod
     * Via: com.dataiku.dip.shaker.server.SampleMetadata
     */
    export enum SamplingMethod {
        FULL = "FULL",
        HEAD_SEQUENTIAL = "HEAD_SEQUENTIAL",
        TAIL_SEQUENTIAL = "TAIL_SEQUENTIAL",
        RANDOM_FIXED_NB = "RANDOM_FIXED_NB",
        RANDOM_FIXED_RATIO = "RANDOM_FIXED_RATIO",
        COLUMN_BASED = "COLUMN_BASED",
        STRATIFIED_TARGET_NB_EXACT = "STRATIFIED_TARGET_NB_EXACT",
        STRATIFIED_TARGET_RATIO_EXACT = "STRATIFIED_TARGET_RATIO_EXACT",
        CLASS_REBALANCE_TARGET_NB_APPROX = "CLASS_REBALANCE_TARGET_NB_APPROX",
        CLASS_REBALANCE_TARGET_RATIO_APPROX = "CLASS_REBALANCE_TARGET_RATIO_APPROX",
        RANDOM_FIXED_NB_EXACT = "RANDOM_FIXED_NB_EXACT",
        RANDOM_FIXED_RATIO_EXACT = "RANDOM_FIXED_RATIO_EXACT",
        COLUMN_ORDERED = "COLUMN_ORDERED",
    }
}