import type { PredictionModelSnippetData } from './prediction-model-snippet-data';
import type { _PredictionModelSnippetData } from './prediction-model-snippet-data';

/** 
 * Translated from class com.dataiku.dip.analysis.model.prediction.PartitionedModelExtract
 * Via: com.dataiku.dip.analysis.model.prediction.PredictionModelSnippetData
 */
export interface _PartitionedModelExtract {
    states: { [key in PartitionedModelExtract.PartitionState]: number };
    summaries: { [key in string]: PartitionedModelExtract.PartitionedModelSummary };
    versions: { [key in string]: string };
}

export type PartitionedModelExtract = _PartitionedModelExtract;

export namespace PartitionedModelExtract {
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.prediction.PartitionedModelExtract$PartitionState
     * Via: com.dataiku.dip.analysis.model.prediction.PartitionedModelExtract
     */
    export enum PartitionState {
        REUSED_PENDING = "REUSED_PENDING",
        REUSED_RUNNING = "REUSED_RUNNING",
        REUSED_DONE = "REUSED_DONE",
        REUSED_FAILED = "REUSED_FAILED",
        REUSED_ABORTED = "REUSED_ABORTED",
        PENDING = "PENDING",
        RUNNING = "RUNNING",
        DONE = "DONE",
        FAILED = "FAILED",
        ABORTED = "ABORTED",
    }
    /** 
     * Translated from class com.dataiku.dip.analysis.model.prediction.PartitionedModelExtract$PartitionedModelSummary
     * Via: com.dataiku.dip.analysis.model.prediction.PartitionedModelExtract
     */
    export interface _PartitionedModelSummary {
        snippet: PredictionModelSnippetData;
        state: PartitionedModelExtract.PartitionState;
    }
    
    export type PartitionedModelSummary = _PartitionedModelSummary;
}