import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { QuantilesTableCard } from 'src/generated-sources';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { FormArrayRepeat } from '@utils/form-array-repeat';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import _ from 'lodash';

const defaultCustomFreqs = [0, 0.25, 0.5, 0.75, 1];

@UntilDestroy()
@Component({
    selector: 'quantiles-table-card-config',
    templateUrl: './quantiles-table-card-config.component.html',
    styleUrls: [
        '../../../../shared-styles/forms.less',
        './quantiles-table-card-config.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuantilesTableCardConfigComponent implements OnChanges, OnDestroy {
    @Input() params: QuantilesTableCard;
    @Output() paramsChange = new EventEmitter<QuantilesTableCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    customFreqs = new FormArrayRepeat(() => new UntypedFormControl(0.5, [
        Validators.min(0),
        Validators.max(1),
        Validators.required
    ]));

    customFreqsEnabled = new UntypedFormControl(false);

    configForm = new UntypedFormGroup({
        customFreqs: this.customFreqs,
        customFreqsEnabled: this.customFreqsEnabled
    });

    constructor() {
        this.configForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(formValue => this.paramsChange.emit({
                ...this.params,
                customFreqs: formValue.customFreqsEnabled ? _.sortBy(formValue.customFreqs) : null
            }));

        this.configForm.statusChanges.pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.configForm.setValue({
                customFreqsEnabled: !!this.params.customFreqs,
                customFreqs: this.params.customFreqs || defaultCustomFreqs
            });
        }
    }

    ngOnDestroy() {
    }

    addQuantile() {
        this.customFreqs.add();
    }

    dropQuantile(index: number) {
        this.customFreqs.removeAt(index);
        if (this.customFreqs.controls.length === 0) {
            this.customFreqsEnabled.setValue(false);
        }
    }
}
