export namespace PromptStudio {
    /** 
     * Translated from enum com.dataiku.dip.llm.promptstudio.PromptStudio$LLMType
     * Via: com.dataiku.dip.dao.SavedModel
     */
    export enum LLMType {
        OPENAI = "OPENAI",
        AZURE_OPENAI = "AZURE_OPENAI",
        VERTEX = "VERTEX",
        COHERE = "COHERE",
        ANTHROPIC = "ANTHROPIC",
        HUGGINGFACE_TRANSFORMER_LOCAL = "HUGGINGFACE_TRANSFORMER_LOCAL",
        HUGGINGFACE_API = "HUGGINGFACE_API",
        SAVED_MODEL_LANGCHAIN = "SAVED_MODEL_LANGCHAIN",
        SAVED_MODEL_FINETUNED_OPENAI = "SAVED_MODEL_FINETUNED_OPENAI",
        SAVED_MODEL_FINETUNED_AZURE_OPENAI = "SAVED_MODEL_FINETUNED_AZURE_OPENAI",
        SAVED_MODEL_FINETUNED_VERTEX = "SAVED_MODEL_FINETUNED_VERTEX",
        SAVED_MODEL_FINETUNED_HUGGINGFACE_TRANSFORMER = "SAVED_MODEL_FINETUNED_HUGGINGFACE_TRANSFORMER",
        TEMPORARY_RETRIEVAL_AUGMENTED = "TEMPORARY_RETRIEVAL_AUGMENTED",
    }
}