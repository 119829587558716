import type { AllFilter } from './all-filter';
import type { AndFilter } from './and-filter';
import type { AnumFilter } from './anum-filter';
import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { IntervalFilter } from './interval-filter';
import type { MissingValueFilter } from './missing-value-filter';
import type { NotFilter } from './not-filter';
import type { _AllFilter } from './all-filter';
import type { _AndFilter } from './and-filter';
import type { _AnumFilter } from './anum-filter';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _IntervalFilter } from './interval-filter';
import type { _MissingValueFilter } from './missing-value-filter';
import type { _NotFilter } from './not-filter';

/** 
 * Translated from class com.dataiku.dip.eda.compute.filtering.Filter
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Filter extends _ImmutableValueObject {
    name?: string | null;
}

/** List of all concrete subclasses of com.dataiku.dip.eda.compute.filtering.Filter */
export type Filter =
    | AllFilter
    | AndFilter
    | AnumFilter
    | IntervalFilter
    | MissingValueFilter
    | NotFilter;

export namespace Filter {
    /** Types of class com.dataiku.dip.eda.compute.filtering.Filter in PolyJSON mappings */
    export const type = ["all", "and", "anum", "interval", "missing", "not", ] as const;
    
    export function isAllFilter(obj: Filter): obj is AllFilter { return obj["type"] == "all"; }
    export function isAndFilter(obj: Filter): obj is AndFilter { return obj["type"] == "and"; }
    export function isAnumFilter(obj: Filter): obj is AnumFilter { return obj["type"] == "anum"; }
    export function isFilter(obj: Filter): obj is Filter { return ["all", "and", "anum", "interval", "missing", "not", ].includes(obj["type"]); }
    export function isIntervalFilter(obj: Filter): obj is IntervalFilter { return obj["type"] == "interval"; }
    export function isMissingValueFilter(obj: Filter): obj is MissingValueFilter { return obj["type"] == "missing"; }
    export function isNotFilter(obj: Filter): obj is NotFilter { return obj["type"] == "not"; }
}