import type { BivariateHeaderCard } from './../bivariate_header/bivariate-header-card';
import type { Card } from './../card';
import type { CardResult } from './../card-result';
import type { ColumnCard } from './column-card';
import type { UnivariateHeaderCard } from './../univariate_header/univariate-header-card';
import type { WorksheetRootCard } from './../worksheet-root-card';
import type { _BivariateHeaderCard } from './../bivariate_header/bivariate-header-card';
import type { _Card } from './../card';
import type { _CardResult } from './../card-result';
import type { _ColumnCard } from './column-card';
import type { _UnivariateHeaderCard } from './../univariate_header/univariate-header-card';
import type { _WorksheetRootCard } from './../worksheet-root-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.common.AbstractCardContainer
 * Via: UIModel annotation in the class hierarchy
 */
export interface _AbstractCardContainer extends _Card {
    cards: Card[];
}

/** List of all concrete subclasses of com.dataiku.dip.eda.worksheets.cards.common.AbstractCardContainer */
export type AbstractCardContainer =
    | WorksheetRootCard
    | BivariateHeaderCard
    | ColumnCard
    | UnivariateHeaderCard;

export namespace AbstractCardContainer {
    /** Types of class com.dataiku.dip.eda.worksheets.cards.common.AbstractCardContainer in PolyJSON mappings */
    export const type = ["worksheet_root", "bivariate_header", "column_card", "univariate_header", ] as const;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.common.AbstractCardContainer$AbstractCardContainerResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AbstractCardContainerResult extends _CardResult {
        results: CardResult[];
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.eda.worksheets.cards.common.AbstractCardContainer$AbstractCardContainerResult */
    export type AbstractCardContainerResult =
        | WorksheetRootCard.WorksheetRootCardResult
        | BivariateHeaderCard.BivariateHeaderCardResult
        | ColumnCard.ColumnCardResult
        | UnivariateHeaderCard.UnivariateHeaderCardResult;

    export namespace AbstractCardContainerResult {
        /** Types of class com.dataiku.dip.eda.worksheets.cards.common.AbstractCardContainer$AbstractCardContainerResult in PolyJSON mappings */
        export const type = ["worksheet_root", "bivariate_header", "column_card", "univariate_header", ] as const;
    }
}