import type { BoxPlotFragment } from './fragments/box-plot-fragment';
import type { CardResult } from './card-result';
import type { CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { NumericalHistogramFragment } from './fragments/numerical-histogram-fragment';
import type { TestDistribution } from './../../compute/computations/univariate/test-distribution';
import type { UnivariateCard } from './univariate-card';
import type { _BoxPlotFragment } from './fragments/box-plot-fragment';
import type { _CardResult } from './card-result';
import type { _CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { _NumericalHistogramFragment } from './fragments/numerical-histogram-fragment';
import type { _UnivariateCard } from './univariate-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.ShapiroNormalityTestCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ShapiroNormalityTestCard extends _UnivariateCard, _CardWithConfidenceLevel {
    type: "shapiro";
    confidenceLevel: number;
}

export type ShapiroNormalityTestCard = _ShapiroNormalityTestCard;

export namespace ShapiroNormalityTestCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.ShapiroNormalityTestCard in PolyJSON mappings */
    export const type = "shapiro";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.ShapiroNormalityTestCard$ShapiroNormalityTestCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ShapiroNormalityTestCardResult extends _CardResult {
        type: "shapiro";
        boxPlot: BoxPlotFragment;
        histogram: NumericalHistogramFragment;
        normalPdf: TestDistribution.ProbabilityDensityFunction;
        pvalue: number;
        statistic: number;
    }
    
    export type ShapiroNormalityTestCardResult = _ShapiroNormalityTestCardResult;

    export namespace ShapiroNormalityTestCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.ShapiroNormalityTestCard$ShapiroNormalityTestCardResult in PolyJSON mappings */
        export const type = "shapiro";
    }
}