import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nicePrecision'
})
export class NicePrecisionPipe implements PipeTransform {

    transform(val: number | undefined, p: number): string | undefined {
        if (val == undefined) {
            return undefined;
        }
        if (val < Math.pow(10, p)) {
            if (Math.round(val) == val) {
                /* Don't add stuff to integers */
                return val.toFixed(0);
            } else {
                return val.toPrecision(p);
            }
        } else {
            return val.toFixed(0);
        }

    }

}
