<button mat-button (click)="select.open()" class="btn btn--text btn--primary btn--dku-icon">
    <ng-content></ng-content>
</button>

<!--
    FIXME: this is a temporary solution to be able to remove dependency on <ngx-mat-select-search>
-->
<basic-select #select
    class="select-button__input"
    [multiple]="true"
    [items]="options"
    [searchable]="true"
    [bindLabel]="labelKey"
    [bindValue]="valueKey"
    [searchPlaceholderText]="searchPlaceholder"
    [noEntryFoundText]="searchNoEntry"
    (ngModelChange)="selectionChange.emit($event)"
    [ngModel]="selection"
    [optionTemplateRef]="optionTemplateRef">
</basic-select>
