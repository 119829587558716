import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { APIError, catchAPIError, ErrorContext, isAPIError } from '@core/dataiku-api/api-error';
import { MatLegacyRow as MatRow, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { WT1Service } from 'dku-frontend-core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ColorsService } from '@shared/graphics/colors.service';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, skip, switchMap, debounceTime, startWith, first, catchError } from 'rxjs/operators';
import { Run } from 'src/generated-sources';
import { ExperimentTrackingService, MetricInfo, RunViewModel } from './experiment-tracking.service';
import { WaitingService } from '@core/overlays/waiting.service';
import { MatSort, Sort } from '@angular/material/sort';
import { now } from '@shared/pipes/date-pipes/date-pipes-common';
import _ from 'lodash';
import { compareNumbers, compareStrings, fairAny } from 'dku-frontend-core';
import { copyToClipboard } from '@utils/clipboard';
import { ETBreadcrumbItem } from './experiment-tracking-header/experiment-tracking-header.component';
import { YYYYMMDDHHmmssDateTimePipe } from '@shared/pipes/date-pipes/yyyymmddhhmmss-date-time.pipe';
import { HttpStatusCode } from '@angular/common/http';

enum RunGroupColumns {
    RUN_INFORMATION = 'group-run-information',
    METRICS = 'group-metrics',
    PARAMS = 'group-parameters',
    TAGS = 'group-tags',
    DSS_SYSTEM_TAGS = 'group-dss-system-tags',
    MLFLOW_SYSTEM_TAGS = 'group-mlflow-system-tags',
    ARTIFACTS = 'group-artifacts'
}

interface ColumnDefinition {
    columnDef: string;
    groupColumn: RunGroupColumns;
    groupColumnHeader: string;
    valueKey: string;
    header: string;
    isNumerical: boolean;
}

@UntilDestroy()
@Component({
    selector: 'experiment-tracking-runs-list',
    templateUrl: './experiment-tracking-runs-list.component.html',
    styleUrls: ['./experiment-tracking-runs-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentTrackingRunsListComponent implements OnInit, AfterViewInit, ErrorContext {
    readonly RUN_INFORMATION_GROUP_COLUMN_HEADER: string = "Run Information";
    readonly METRICS_GROUP_COLUMN_HEADER: string = "Metrics";
    readonly PARAMS_GROUP_COLUMN_HEADER: string = "Parameters";
    readonly TAGS_GROUP_COLUMN_HEADER: string = "Tags";
    readonly DSS_SYSTEM_TAGS_GROUP_COLUMN_HEADER: string = "DSS System Tags";
    readonly MLFLOW_SYSTEM_TAGS_GROUP_COLUMN_HEADER: string = "MLflow System Tags";
    readonly ARTIFACTS_GROUP_COLUMN_HEADER: string = "Artifacts";

    readonly groupHeadersMap: Map<RunGroupColumns, string> = new Map([
        [RunGroupColumns.RUN_INFORMATION, this.RUN_INFORMATION_GROUP_COLUMN_HEADER],
        [RunGroupColumns.METRICS, this.METRICS_GROUP_COLUMN_HEADER],
        [RunGroupColumns.PARAMS, this.PARAMS_GROUP_COLUMN_HEADER],
        [RunGroupColumns.TAGS, this.TAGS_GROUP_COLUMN_HEADER],
        [RunGroupColumns.DSS_SYSTEM_TAGS, this.DSS_SYSTEM_TAGS_GROUP_COLUMN_HEADER],
        [RunGroupColumns.MLFLOW_SYSTEM_TAGS, this.MLFLOW_SYSTEM_TAGS_GROUP_COLUMN_HEADER],
        [RunGroupColumns.ARTIFACTS, this.ARTIFACTS_GROUP_COLUMN_HEADER]
    ]);

    /** PREFIXES SHOULD HAVE FIXED LENGHT OF 2 CHARS */
    readonly RUN_INFORMATION_COLUMN_KEY_PREFIX: string = "ri";
    readonly METRICS_COLUMN_KEY_PREFIX: string = "me";
    readonly PARAMS_COLUMN_KEY_PREFIX: string = "pa";
    readonly TAGS_COLUMN_KEY_PREFIX: string = "ta";
    readonly MLFLOW_SYSTEM_TAGS_COLUMN_KEY_PREFIX: string = "st";
    readonly ARTIFACTS_COLUMN_KEY_PREFIX: string = "ar";

    readonly prefixesGroupMap: Map<string, RunGroupColumns> = new Map([
        [this.RUN_INFORMATION_COLUMN_KEY_PREFIX, RunGroupColumns.RUN_INFORMATION],
        [this.METRICS_COLUMN_KEY_PREFIX, RunGroupColumns.METRICS],
        [this.PARAMS_COLUMN_KEY_PREFIX, RunGroupColumns.PARAMS],
        [this.TAGS_COLUMN_KEY_PREFIX, RunGroupColumns.TAGS],
        [this.MLFLOW_SYSTEM_TAGS_COLUMN_KEY_PREFIX, RunGroupColumns.MLFLOW_SYSTEM_TAGS],
        [this.ARTIFACTS_COLUMN_KEY_PREFIX, RunGroupColumns.ARTIFACTS]
    ]);

    readonly groupPrefixesMap: Map<RunGroupColumns, string> = new Map(Array.from(this.prefixesGroupMap.entries()).map(entry => [entry[1], entry[0]]));

    readonly QUERY_SEARCH_SEPARATOR: string = ";";

    readonly runInformationColumnKeys: string[] = ['experiment', 'run', 'startTime', 'totalTime', 'origin', 'status', 'dssUser', 'dssGitCommit', 'dssGitMessage', 'dssGitBranch'];
    readonly artifactColumnsKeys: string[] = ['models', 'artifact'];
    readonly defaultDisplayedColumns: string[] = ['highlight', 'select', 'display-chart', 'deleted', 'runColor'];
    readonly defaultDisplayedGroupColumns: string[] = ['group-highlight', 'group-select', 'group-display-chart', 'group-deleted', 'group-run-color'];
    readonly groupColumns: RunGroupColumns[] = [RunGroupColumns.RUN_INFORMATION, RunGroupColumns.METRICS, RunGroupColumns.PARAMS, RunGroupColumns.TAGS, RunGroupColumns.DSS_SYSTEM_TAGS, RunGroupColumns.MLFLOW_SYSTEM_TAGS, RunGroupColumns.ARTIFACTS];

    RunGroupColumnsEnum: typeof RunGroupColumns = RunGroupColumns

    @ViewChildren(MatRow, { read: ElementRef }) tableRows: QueryList<ElementRef>;
    @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    projectKey: string;
    experimentIds: string[];
    intermediateLinks: Observable<ETBreadcrumbItem>[] = [];

    dataSource: MatTableDataSource<RunViewModel> = new MatTableDataSource(new Array<RunViewModel>());
    querySort$: Subject<Sort>;
    defaultSort: Sort = <Sort>{ active: "ri-startTime", direction: "desc" };
    querySearch$: Subject<string>;

    selectedRunIds = new Set<string>();
    selectedRuns: RunViewModel[];

    selectedToDisplayRunIds = new Set<string>();
    selectedToDisplayRuns: RunViewModel[];

    runs$: Observable<RunViewModel[]>;
    materializedRuns: RunViewModel[] = [];
    refresh$: Subject<void> = new ReplaySubject(1);

    displayedColumns: string[] = [];
    displayedGroupColumns: string[] = [];
    displayedColumnsMap: Map<RunGroupColumns, string[]> = new Map();

    availableColumns: ColumnDefinition[] = []
    columnsByColDefMap: Map<string, ColumnDefinition> = new Map();
    columnsByGroupMap: Map<RunGroupColumns, ColumnDefinition[]> = new Map();

    highlightedRunId: string = "";

    error?: APIError;
    experimentIdsOnError: string[] = []

    form = this.fb.group({
        selectedColumns: new UntypedFormControl(undefined, []),
        querySearch: new UntypedFormControl(undefined, []),
        viewAll: new UntypedFormControl(false, [])
    })

    readonly viewAllControl = this.form.controls['viewAll'];

    runInformationFrozen: boolean = false;

    constructor(
        private DataikuAPI: DataikuAPIService,
        private fb: UntypedFormBuilder,
        private cd: ChangeDetectorRef,
        private colorsService: ColorsService,
        private waitingService: WaitingService,
        private experimentTrackingService: ExperimentTrackingService,
        private wt1Service: WT1Service,
        private YYYYMMDDHHmmssDateTimePipe: YYYYMMDDHHmmssDateTimePipe
    ) {
    }

    ngOnInit(): void {
        this.projectKey = this.experimentTrackingService.projectKey;
        this.experimentIds = this.experimentTrackingService.experimentIds;
        this.subscribeToSelectedColumnChange();
        this.subscribeToSearchChange();
        this.querySort$ = new BehaviorSubject(this.defaultSort);
        this.querySearch$ = new BehaviorSubject<string>("");
        this.createBreadcrumb();
        this.initViewAllParam();

        this.runs$ =
            combineLatest([this.refresh$, this.viewAllControl.valueChanges.pipe(startWith(this.viewAllControl.value))]).pipe(
                untilDestroyed(this),
                switchMap(([_refresh, viewAll]) => {
                    return this.DataikuAPI.experiments.searchRuns(this.projectKey, this.experimentIds, viewAll ? "ALL" : "ACTIVE_ONLY").pipe(
                        this.waitingService.bindSpinner(),
                        map((runs: Run[]): RunViewModel[] => {
                            return this.experimentTrackingService.mapRuns(runs);
                        }),
                        catchAPIError(this, false, this.cd));
                }),
                shareReplay()
            );

        this.runs$
            .pipe(untilDestroyed(this))
            .subscribe(runs => {
                this.setUpColumns(runs);
                this.checkExperiments(runs);
            });

        combineLatest([this.runs$, this.querySort$, this.querySearch$])
            .pipe(untilDestroyed(this))
            .subscribe(([runs, sort, search]: [RunViewModel[], Sort, string]) => {
                const oldRuns = this.materializedRuns.slice();
                this.materializedRuns = runs;
                this.dataSource.data = runs;
                this.dataSource.filter = search.trim().toLowerCase();

                if (sort) {
                    this.sortData(<Sort>{ active: sort.active, direction: sort.direction });
                }

                this.setUpSelection(oldRuns);
            });

        this.viewAllControl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((viewAll) => this.experimentTrackingService.goToRunList(this.experimentIds, this.experimentTrackingService.viewAllExperiments, viewAll, false, 'replace'));
    }

    ngAfterViewInit() {
        const filterPredicate = (data: RunViewModel, filter: string): boolean => {
            return this.filterData(data, filter);
        };

        this.dataSource.filterPredicate = filterPredicate;
        this.refresh();
    }

    createBreadcrumb() {
        this.intermediateLinks = [this.experimentTrackingService.getRunsListBreadcrumb()];
    }

    checkExperiments(runs: RunViewModel[]) {
        this.experimentIdsOnError = [];
        const experimentIdsInRuns:Set<string> = new Set(runs.map((run:RunViewModel) => run.info.experimentId));

        this.experimentIds.forEach((experimentId) => {
            if(!experimentIdsInRuns.has(experimentId)){
                this.DataikuAPI.experiments.getExperiment(this.projectKey, experimentId)
                .pipe(first())
                .subscribe({
                    error: err => {
                        if (isAPIError(err) && (err.httpCode === HttpStatusCode["NotFound"])) {
                            this.experimentIdsOnError.push(experimentId);
                            this.cd.markForCheck();
                            return;
                        }
                        this.pushError(err);
                    }
                });
            }
        });
    }

    initViewAllParam() {
        this.form.patchValue({
            viewAll: this.experimentTrackingService.viewAllRuns
        });
    }

    setUpColumns(runs: RunViewModel[]) {
        const oldDefaultColumns = this.getDefaultColumns();
        this.computeAvailableColumns(runs);

        let selectedColumns: string[] = this.getSelectedColumns();

        if(!selectedColumns.length) {
            selectedColumns = this.getDefaultColumns();
        } else {
            const defaultColumns = this.getDefaultColumns();
            // Get new columns that are visible by default
            const newColumns: string[] = defaultColumns.filter(column => !oldDefaultColumns.includes(column));
            // Remove columns that are not available anymore and concat the new ones
            const availableColumns = this.availableColumns.map(column => column.columnDef);
            selectedColumns = selectedColumns
                .filter(column => availableColumns.includes(column))
                .concat(newColumns);
        }

        this.setSelectedColumns(selectedColumns);
    }

    setUpSelection(oldRuns: RunViewModel[]) {
        if (oldRuns.length == 0) {
            this.selectedToDisplayRunIds.clear();
            this.masterToDisplayToggle();
        } else {
            const oldIds: Set<string> = new Set(oldRuns.map((run) => run.info.runId));
            const newRunIds: string[] = this.materializedRuns.filter((run) => !oldIds.has(run.info.runId)).map((run) => run.info.runId);
            // New arrived runs are selected by default
            const selectedToDisplayRunIds = new Set(newRunIds);

            // As some runs already selected could have been deleted, remove them from selection
            this.materializedRuns.forEach((run: RunViewModel) => {
                if (this.selectedToDisplayRunIds.has(run.info.runId)) {
                    selectedToDisplayRunIds.add(run.info.runId);
                }
            });

            this.selectedToDisplayRunIds = selectedToDisplayRunIds;
            this.updateToDisplaySelectedRuns();
        }
    }

    trackExperimentBy(_index: number, item: RunViewModel) {
        return item.info.runId;
    }

    subscribeToSelectedColumnChange(): void {
        const selectedColumns$ = this.form.controls['selectedColumns'].valueChanges
            .pipe(
                untilDestroyed(this),
                distinctUntilChanged()
            );
        selectedColumns$.subscribe((columnsToDisplay: string[]) => {
            this.toggleColumns(columnsToDisplay || []);
        });
        this.publishWT1ToggleColumnsEventExceptOnInit(selectedColumns$);
    }

    subscribeToSearchChange(): void {
        const searchValue$ = this.form.controls['querySearch'].valueChanges
            .pipe(
                untilDestroyed(this),
                distinctUntilChanged(),
                debounceTime(300)
            );
        searchValue$.subscribe((search: string) => {
            this.querySearch$.next(search);
        });
        this.publishWT1QuerySearchEvent(searchValue$);
    }

    publishWT1ToggleColumnsEventExceptOnInit(selectedColumns$: Observable<string[]>) {
        // Don't publish a WT1 event on first emission, since this one is for init and is not a real user event.
        selectedColumns$.pipe(
            skip(1),
            untilDestroyed(this)
        ).subscribe(
            (columnsToDisplay: string[]) => {
                this.wt1Service.event('experiment-tracking-toggle-runs-column',
                    {
                        columnsCount: columnsToDisplay?.length || 0
                    });
            }
        );
    }

    publishWT1QuerySearchEvent(querySearch$: Observable<string>) {
        querySearch$.pipe(
            untilDestroyed(this)
        ).subscribe(
            (search: string) => {
                this.wt1Service.event('experiment-tracking-toggle-query-search',
                    {
                        querySearch: search
                    });
            }
        );
    }

    getSelectedColumns(): string[] {
        return this.form.controls['selectedColumns'].value || [];
    }

    setSelectedColumns(columns: string[]): void {
        this.form.controls['selectedColumns'].patchValue(columns);
    }

    getDefaultColumns(): string[] {
        return this.availableColumns
            .filter(columnDef => {
                if (RunGroupColumns.MLFLOW_SYSTEM_TAGS === columnDef.groupColumn) {
                    return false;
                }
                if (columnDef.columnDef.startsWith('ri-dssGit')) {
                    return false;
                }
                return true;
            })
            .map(columnDef => columnDef.columnDef);
    }

    toggleColumns(columnsToDisplay: string[]): void {
        const columnsKeysPerGroupMap: Map<RunGroupColumns, string[]> = new Map();

        columnsToDisplay.forEach((columnKey: string) => {
            const group: RunGroupColumns | undefined = this.getGroupFromPrefixedColumn(columnKey);

            if (group) {
                if (columnsKeysPerGroupMap.has(group)) {
                    columnsKeysPerGroupMap.get(group)?.push(columnKey)
                } else {
                    columnsKeysPerGroupMap.set(group, [columnKey]);
                }
            }
        });

        this.updateDisplayedColumns(columnsKeysPerGroupMap);
    }

    updateDisplayedColumns(columnsKeysPerGroupMap: Map<RunGroupColumns, string[]>): void {
        this.displayedGroupColumns = this.defaultDisplayedGroupColumns.slice();
        this.displayedColumns = this.defaultDisplayedColumns.slice();

        this.groupColumns.forEach((groupColumnType: RunGroupColumns) => {
            const columnKeys: string[] = columnsKeysPerGroupMap.get(groupColumnType) || [];

            this.displayedColumnsMap.set(groupColumnType, columnKeys);

            if (columnKeys.length) {
                this.displayedColumns = this.displayedColumns.concat(columnKeys);
                this.displayedGroupColumns.push(groupColumnType);
            }
        });

        this.cd.markForCheck();
    }

    setOrUpdateColumnDef(columnDefMap: Map<string, ColumnDefinition>, runGroupColumn: RunGroupColumns, key: string, isNumerical: boolean) {
        const columnDef = this.getGroupColumnsPrefix(runGroupColumn) + "-" + key;

        if (columnDefMap.has(columnDef)) {
            columnDefMap.get(columnDef)!.isNumerical = (columnDefMap.get(columnDef)!.isNumerical && isNumerical);
        } else {
            columnDefMap.set(columnDef, {
                columnDef: columnDef,
                groupColumn: runGroupColumn,
                groupColumnHeader: this.getGroupColumnsHeader(runGroupColumn),
                valueKey: key,
                header: key,
                isNumerical: isNumerical
            });
        }
    }

    computeAvailableColumns(runs: RunViewModel[]) {
        const experimentColumnDefMap: Map<string, ColumnDefinition> = new Map();
        const metricsColumnDefMap: Map<string, ColumnDefinition> = new Map();
        const paramsColumnDefMap: Map<string, ColumnDefinition> = new Map();
        const tagsColumnDefMap: Map<string, ColumnDefinition> = new Map();
        const mlflowSystemTagsColumnDefMap: Map<string, ColumnDefinition> = new Map();
        const artifactsColumnDefMap: Map<string, ColumnDefinition> = new Map();

        runs.forEach((run: RunViewModel) => {
            run.metrics.forEach((value: MetricInfo, key: string) => {
                this.setOrUpdateColumnDef(metricsColumnDefMap, RunGroupColumns.METRICS, key, true);
            });

            run.params.forEach((value: fairAny, key: string) => {
                this.setOrUpdateColumnDef(paramsColumnDefMap, RunGroupColumns.PARAMS, key, isFinite(value));
            });

            run.tags.forEach((value: fairAny, key: string) => {
                this.setOrUpdateColumnDef(tagsColumnDefMap, RunGroupColumns.TAGS, key, isFinite(value));
            });

            run.systemTags.forEach((value: fairAny, key: string) => {
                this.setOrUpdateColumnDef(mlflowSystemTagsColumnDefMap, RunGroupColumns.MLFLOW_SYSTEM_TAGS, key, isFinite(value));
            });
        });

        this.runInformationColumnKeys.forEach((key: string) => {
            this.setOrUpdateColumnDef(experimentColumnDefMap, RunGroupColumns.RUN_INFORMATION, key, false);
        });

        this.artifactColumnsKeys.forEach((key: string) => {
            this.setOrUpdateColumnDef(artifactsColumnDefMap, RunGroupColumns.ARTIFACTS, key, false);
        });

        const compareCols = function (a: ColumnDefinition, b: ColumnDefinition) {
            return compareStrings(a.valueKey, b.valueKey, true);
        };

        this.columnsByGroupMap.set(RunGroupColumns.RUN_INFORMATION, Array.from(experimentColumnDefMap.values()));
        this.columnsByGroupMap.set(RunGroupColumns.METRICS, Array.from(metricsColumnDefMap.values()).sort(compareCols));
        this.columnsByGroupMap.set(RunGroupColumns.PARAMS, Array.from(paramsColumnDefMap.values()).sort(compareCols));
        this.columnsByGroupMap.set(RunGroupColumns.TAGS, Array.from(tagsColumnDefMap.values()).sort(compareCols));
        this.columnsByGroupMap.set(RunGroupColumns.MLFLOW_SYSTEM_TAGS, Array.from(mlflowSystemTagsColumnDefMap.values()).sort(compareCols));
        this.columnsByGroupMap.set(RunGroupColumns.ARTIFACTS, Array.from(artifactsColumnDefMap.values()));

        this.availableColumns = _.flatMap(Array.from(this.columnsByGroupMap.values()))

        this.columnsByColDefMap = new Map();
        this.availableColumns.forEach(colDef => this.columnsByColDefMap.set(colDef.columnDef, colDef));
    }

    getGroupColumnsPrefix(groupColumnType: RunGroupColumns): string {
        return this.groupPrefixesMap.get(groupColumnType) || "";
    }

    getGroupFromPrefixedColumn(columnKey: string): (RunGroupColumns | undefined) {
        return this.prefixesGroupMap.get(columnKey.slice(0, 2));
    }

    getGroupColumnsHeader(groupColumnType: RunGroupColumns): string {
        return this.groupHeadersMap.get(groupColumnType) || "";
    }

    refresh(): void {
        this.refresh$.next();
    }

    pushError(error: APIError): void {
        this.error = error;
    }

    getRunColor(runId: string) {
        return this.colorsService.getColorForVariable(runId);
    }

    setHighlightedRunId(runId: string) {
        this.highlightedRunId = runId;
    }

    scrollToRunId(runId: string) {
        this.wt1Service.event('experiment-tracking-scroll-to-run-id', {});
        const runIdx = this.materializedRuns.findIndex(r => r.info.runId === runId);
        if (-1 != runIdx && runIdx < this.tableRows.length) {
            this.tableRows.toArray()[runIdx].nativeElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }

    isAllToDisplaySelected() {
        return this.selectedToDisplayRunIds.size == this.materializedRuns.length;
    }

    masterToDisplayToggle() {
        if (this.isAllToDisplaySelected()) {
            this.selectedToDisplayRunIds.clear();
        } else {
            this.materializedRuns.forEach(run => this.selectedToDisplayRunIds.add(run.info.runId));
        }
        this.updateToDisplaySelectedRuns();
    }

    isRunToDisplaySelected(run: RunViewModel) {
        return this.selectedToDisplayRunIds.has(run.info.runId)
    }

    toggleRunToDisplaySelection(run: RunViewModel) {
        if (this.selectedToDisplayRunIds.has(run.info.runId)) {
            this.selectedToDisplayRunIds.delete(run.info.runId);
        } else {
            this.selectedToDisplayRunIds.add(run.info.runId);
        }
        this.updateToDisplaySelectedRuns();
    }

    updateToDisplaySelectedRuns() {
        this.selectedToDisplayRuns = this.materializedRuns.filter(run => this.selectedToDisplayRunIds.has(run.info.runId));
    }

    hasToDisplaySelectedRuns(): boolean {
        return this.selectedToDisplayRunIds.size != 0;
    }

    checkedToDisplayAll(): string {
        if (this.hasToDisplaySelectedRuns() && this.isAllToDisplaySelected()) {
            return "true";
        }
        return "";
    }

    isAllSelected(): boolean {
        const anyUnselectedVisibleRun = this.materializedRuns.find(run => !this.selectedRunIds.has(run.info.runId));
        return !anyUnselectedVisibleRun;
    }

    masterToggle() {
        if (this.isAllSelected()) {
            this.selectedRunIds.clear();
        } else {
            this.materializedRuns.forEach(run => this.selectedRunIds.add(run.info.runId));
        }
        this.updateSelectedRuns();
    }

    isRunSelected(run: RunViewModel) {
        return this.selectedRunIds.has(run.info.runId)
    }

    toggleRunSelection(run: RunViewModel) {
        if (this.selectedRunIds.has(run.info.runId)) {
            this.selectedRunIds.delete(run.info.runId);
        } else {
            this.selectedRunIds.add(run.info.runId);
        }
        this.updateSelectedRuns();
    }

    updateSelectedRuns() {
        this.selectedRuns = this.materializedRuns.filter(run => this.selectedRunIds.has(run.info.runId));
    }

    hasSelectedRuns(): boolean {
        return this.selectedRunIds.size != 0;
    }

    checkedAll(): string {
        if (this.hasSelectedRuns() && this.isAllSelected()) {
            return "true";
        }
        return "";
    }

    clearSelected() {
        this.selectedRunIds.clear();
        this.selectedRuns = [];
    }

    getExperimentNameOrId(run: RunViewModel) {
        return run.experimentName ? run.experimentName : run.info.experimentId;
    }

    getDuration(run: RunViewModel): number {
        const start = run.info.startTime;
        let end = run.info.endTime
        end = (end == undefined || end == 0) ? now : end;
        return (end - start);
    }

    getMetricValue(run: RunViewModel, metricKey: string): number {
        return run.metrics.get(metricKey)?.lastValue || NaN;
    }

    getParamValue(run: RunViewModel, tagKey: string): string {
        return run.params.get(tagKey) || '-';
    }

    getTagValue(run: RunViewModel, tagKey: string): string {
        return run.tags.get(tagKey) || '-';
    }

    getMlflowSystemTagValue(run: RunViewModel, tagKey: string): string {
        return run.systemTags.get(tagKey) || '-';
    }

    getValue(run: RunViewModel, group: RunGroupColumns, tagKey: string): (string | number) {
        let value: string | number = '';

        switch (group) {
            case RunGroupColumns.METRICS:
                value = this.getMetricValue(run, tagKey);
                break;
            case RunGroupColumns.PARAMS:
                value = this.getParamValue(run, tagKey);
                break;
            case RunGroupColumns.TAGS:
                value = this.getTagValue(run, tagKey);
                break;
            case RunGroupColumns.MLFLOW_SYSTEM_TAGS:
                value = this.getMlflowSystemTagValue(run, tagKey);
                break;
        }

        return value;
    }

    getArtifactValue(run: RunViewModel) {
        let value = run.info.artifactUri;

        if (run.artifactInfo.managedFolderName) {
            value = run.artifactInfo.managedFolderName + "/" + run.artifactInfo.subfolder;
        }

        return value;
    }

    sortData(sort: Sort) {
        const data = this.materializedRuns.slice();
        if (!sort.active || sort.direction === '') {
            this.dataSource.data = data;
            return;
        }

        this.dataSource.data = data.sort((a: RunViewModel, b: RunViewModel) => {
            const isAsc = sort.direction === 'asc';
            let compareValue = 0;

            if (sort.active === 'ri-experiment') {
                compareValue = compareStrings(this.getExperimentNameOrId(a), this.getExperimentNameOrId(b), isAsc);
            } else if (sort.active === 'ri-run') {
                compareValue = compareStrings(a.runNameId, b.runNameId, isAsc);
            } else if (sort.active === 'ri-startTime') {
                compareValue = compareNumbers(a.info.startTime, b.info.startTime, isAsc);
            } else if (sort.active === 'ri-totalTime') {
                compareValue = compareNumbers(this.getDuration(a), this.getDuration(b), isAsc);
            } else if (sort.active === 'ri-status') {
                compareValue = compareStrings(a.info.status, b.info.status, isAsc);
            } else if (sort.active === 'ri-dssUser') {
                compareValue = compareStrings(a.dssUser, b.dssUser, isAsc);
            } else if (sort.active === 'ri-dssGitCommit') {
                compareValue = compareStrings(a.dssGitCommit, b.dssGitCommit, isAsc);
            } else if (sort.active === 'ri-dssGitBranch') {
                compareValue = compareStrings(a.dssGitBranch, b.dssGitBranch, isAsc);
            } else if (sort.active === 'ri-dssGitMessage') {
                compareValue = compareStrings(a.dssGitMessage, b.dssGitMessage, isAsc);
            } else if (sort.active === 'ar-models') {
                compareValue = compareNumbers(a.data?.models?.length || 0, b.data?.models?.length || 0, isAsc);
            } else if (sort.active === 'ar-artifact') {
                compareValue = compareStrings(this.getArtifactValue(a), this.getArtifactValue(b), isAsc);
            } else {
                const group = this.getGroupFromPrefixedColumn(sort.active);

                if (group) {
                    const key = sort.active.slice(3);
                    const isNumeric = this.columnsByColDefMap.get(sort.active)!.isNumerical;
                    const compareFunc = isNumeric ? compareNumbers : compareStrings;
                    compareValue = compareFunc(this.getValue(a, group, key), this.getValue(b, group, key), isAsc);
                }
            }

            return compareValue;
        });
    }

    onSortChange(sort: Sort) {
        this.wt1Service.event('experiment-tracking-sort-runs-table', sort);
        this.querySort$.next(sort);
    }

    formatDate(date: number): string {
        return this.YYYYMMDDHHmmssDateTimePipe.transform(date);
    }

    filterData(data: RunViewModel, filter: string): boolean {
        let dataStr = "";

        this.displayedColumns.forEach((column: string) => {
            if (column === 'ri-experiment') {
                dataStr += this.getExperimentNameOrId(data);
            } else if (column === 'ri-run') {
                dataStr += data.runNameId;
            } else if (column === 'ri-startTime') {
                dataStr += this.formatDate(data.info.startTime);
            } else if (column === 'ri-totalTime') {
                dataStr += this.getDuration(data);
            } else if (column === 'ri-status') {
                dataStr += data.info.status;
            } else if (column === 'ri-dssUser') {
                dataStr += data.dssUser;
            } else if (column === 'ri-dssGitCommit') {
                dataStr += data.dssGitCommit;
            } else if (column === 'ri-dssGitBranch') {
                dataStr += data.dssGitBranch;
            } else if (column === 'ri-dssGitMessage') {
                dataStr += data.dssGitMessage;
            } else if (column === 'ar-models') {
                dataStr += data.data?.models?.length || 0;
            } else if (column === 'ar-artifact') {
                dataStr += this.getArtifactValue(data);
            } else {
                const group = this.getGroupFromPrefixedColumn(column);

                if (group) {
                    const key = column.slice(3);
                    const isNumeric = this.columnsByColDefMap.get(column)!.isNumerical;

                    if (isNumeric) {
                        dataStr += Number(this.getValue(data, group, key))?.toFixed(4);
                    } else {
                        dataStr += this.getValue(data, group, key);
                    }
                }
            }

            dataStr += this.QUERY_SEARCH_SEPARATOR;
        });

        return dataStr.toLowerCase().indexOf(filter) != -1;
    }

    getSelectedDeletedRuns(runs: Run[]) {
        if (!this.selectedRunIds) {
            return [];
        }
        return runs.filter(r => this.selectedRunIds.has(r.info.runId)
            && r.info.lifecycleStage === "deleted");
    }

    getSelectedActiveRuns(runs: Run[]) {
        if (!this.selectedRunIds) {
            return [];
        }
        return runs.filter(r => this.selectedRunIds.has(r.info.runId)
            && r.info.lifecycleStage === "active");
    }

    hasSelectedDeletedRuns(runs: Run[]): boolean {
        return this.getSelectedDeletedRuns(runs).length !== 0;
    }

    hasSelectedActiveRuns(runs: Run[]): boolean {
        return this.getSelectedActiveRuns(runs).length !== 0;
    }

    restoreSelected(runs: Run[]) {
        return this.DataikuAPI.experiments.restoreRuns(this.projectKey, this.getSelectedDeletedRuns(runs).map(r => r.info.runId))
            .pipe(
                this.waitingService.bindSpinner(),
                catchAPIError(this, false, this.cd))
            .subscribe(() => {
                this.clearSelected();
                this.refresh();
            });
    }

    deleteSelected(runs: Run[]) {
        return this.DataikuAPI.experiments.deleteRuns(this.projectKey, this.getSelectedActiveRuns(runs).map(r => r.info.runId))
            .pipe(
                this.waitingService.bindSpinner(),
                catchAPIError(this, false, this.cd))
            .subscribe(() => {
                this.clearSelected();
                this.refresh();
            });
    }

    copyToClipboard(text: string) {
        copyToClipboard(text);
    }

    showSelected(runs: RunViewModel[]) {
        runs.filter(r => this.selectedRunIds.has(r.info.runId)).forEach((run: RunViewModel) => {
            if (!this.selectedToDisplayRunIds.has(run.info.runId)) {
                this.selectedToDisplayRunIds.add(run.info.runId);
            }
        });
        this.updateToDisplaySelectedRuns();
    }

    hideSelected(runs: RunViewModel[]) {
        runs.filter(r => this.selectedRunIds.has(r.info.runId)).forEach((run: RunViewModel) => {
            if (this.selectedToDisplayRunIds.has(run.info.runId)) {
                this.selectedToDisplayRunIds.delete(run.info.runId);
            }
        });
        this.updateToDisplaySelectedRuns();
    }

    toggleRunInformationFreezeMode() {
        this.runInformationFrozen = !this.runInformationFrozen;
    }
}
