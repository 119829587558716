import type { Distribution } from './distribution';
import type { _Distribution } from './distribution';

/** 
 * Translated from class com.dataiku.dip.eda.compute.distributions.Normal
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Normal extends _Distribution {
    type: "normal";
}

export type Normal = _Normal;

export namespace Normal {
    /** Type of class com.dataiku.dip.eda.compute.distributions.Normal in PolyJSON mappings */
    export const type = "normal";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.distributions.Normal$FittedNormal
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FittedNormal extends Distribution._FittedDistribution {
        type: "normal";
        mean: number;
        std: number;
    }
    
    export type FittedNormal = _FittedNormal;

    export namespace FittedNormal {
        /** Type of class com.dataiku.dip.eda.compute.distributions.Normal$FittedNormal in PolyJSON mappings */
        export const type = "normal";
    }
}