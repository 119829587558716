import { NgModule } from '@angular/core';
import { EditableListInputComponent } from './editable-list-input.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        EditableListInputComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatAutocompleteModule,
        MatInputModule,
        MatFormFieldModule,
        MatOptionModule
    ],
    exports: [
        EditableListInputComponent
    ]
})
export class EditableListInputModule { }
