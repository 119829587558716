import type { BivariateCard } from './../bivariate-card';
import type { BivariateHistogramCard } from './../bivariate_header/bivariate-histogram-card';
import type { BoxPlotFragment } from './../fragments/box-plot-fragment';
import type { CardResult } from './../card-result';
import type { CardWithConfidenceLevel } from './card-with-confidence-level';
import type { Filter } from './../../../compute/filtering/filter';
import type { ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { MoodTestNSampCard } from './../mood-test-nsamp-card';
import type { OneWayANOVACard } from './../one-way-anovacard';
import type { PairwiseMoodTestCard } from './../pairwise-mood-test-card';
import type { PairwiseTTestCard } from './../pairwise-ttest-card';
import type { _BivariateCard } from './../bivariate-card';
import type { _BoxPlotFragment } from './../fragments/box-plot-fragment';
import type { _CardResult } from './../card-result';
import type { _CardWithConfidenceLevel } from './card-with-confidence-level';
import type { _Filter } from './../../../compute/filtering/filter';
import type { _ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _MoodTestNSampCard } from './../mood-test-nsamp-card';
import type { _OneWayANOVACard } from './../one-way-anovacard';
import type { _PairwiseMoodTestCard } from './../pairwise-mood-test-card';
import type { _PairwiseTTestCard } from './../pairwise-ttest-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _AbstractNSampTestCard extends _BivariateCard, _CardWithConfidenceLevel {
    confidenceLevel: number;
    maxGroups: number;
    yValues?: string[] | null;
}

/** List of all concrete subclasses of com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard */
export type AbstractNSampTestCard =
    | MoodTestNSampCard
    | OneWayANOVACard
    | PairwiseMoodTestCard
    | PairwiseTTestCard;

export namespace AbstractNSampTestCard {
    /** Types of class com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard in PolyJSON mappings */
    export const type = ["mood_nsamp", "oneway_anova", "pairwise_mood", "pairwise_ttest", ] as const;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard$AbstractNSampleTestCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AbstractNSampleTestCardResult extends _CardResult {
        allGroups: AbstractNSampTestCard.GroupInfo;
        groups: AbstractNSampTestCard.GroupInfo[];
        histogramData: BivariateHistogramCard.BivariateHistogramData;
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard$AbstractNSampleTestCardResult */
    export type AbstractNSampleTestCardResult =
        | MoodTestNSampCard.MoodTestNSampCardResult
        | OneWayANOVACard.OneWayANOVACardResult
        | PairwiseMoodTestCard.PairwiseMoodTestCardResult
        | PairwiseTTestCard.PairwiseTTestCardResult;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard$GroupInfo
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _GroupInfo extends _ImmutableValueObject {
        boxPlot: BoxPlotFragment;
        filter: Filter;
    }
    
    export type GroupInfo = _GroupInfo;

    export namespace AbstractNSampleTestCardResult {
        /** Types of class com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard$AbstractNSampleTestCardResult in PolyJSON mappings */
        export const type = ["mood_nsamp", "oneway_anova", "pairwise_mood", "pairwise_ttest", ] as const;
    }
}