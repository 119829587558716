import type { Filter } from './../filtering/filter';
import type { Grouping } from './grouping';
import type { GroupingResult } from './grouping-result';
import type { _Filter } from './../filtering/filter';
import type { _Grouping } from './grouping';
import type { _GroupingResult } from './grouping-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.grouping.SubsetGrouping
 * Via: UIModel annotation in the class hierarchy
 */
export interface _SubsetGrouping extends _Grouping {
    type: "subset";
    filter: Filter;
}

export type SubsetGrouping = _SubsetGrouping;

export namespace SubsetGrouping {
    /** Type of class com.dataiku.dip.eda.compute.grouping.SubsetGrouping in PolyJSON mappings */
    export const type = "subset";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.grouping.SubsetGrouping$SubsetGroupingResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _SubsetGroupingResult extends _GroupingResult {
        type: "subset";
        filter: Filter;
    }
    
    export type SubsetGroupingResult = _SubsetGroupingResult;

    export namespace SubsetGroupingResult {
        /** Type of class com.dataiku.dip.eda.compute.grouping.SubsetGrouping$SubsetGroupingResult in PolyJSON mappings */
        export const type = "subset";
    }
}