import type { TaggableObjectsService } from './../../server/services/taggable-objects-service';

/** 
 * Translated from class com.dataiku.dip.llm.retrieval.RetrievableKnowledge
 * Via: com.dataiku.dip.server.UsabilityComputer$UsableComputable
 */
export interface _RetrievableKnowledge extends TaggableObjectsService._TaggableObject {
    connection: string;
    embeddingLLMId: string;
    id: string;
    llmsExposedWith: RetrievableKnowledge.RAGLLMSettings[];
    name: string;
    pineconeIndexName: string;
    projectKey: string;
    retrieverType: RetrievableKnowledge.RetrieverType;
    vectorStoreType: RetrievableKnowledge.VectorStoreType;
}

export type RetrievableKnowledge = _RetrievableKnowledge;

export namespace RetrievableKnowledge {
    /** 
     * Translated from class com.dataiku.dip.llm.retrieval.RetrievableKnowledge$RAGLLMSettings
     * Via: com.dataiku.dip.llm.retrieval.RetrievableKnowledge
     */
    export interface _RAGLLMSettings {
        llmId: string;
        maxDocuments: number;
        mmrDiversity: number;
        mmrK: number;
        outputFormat: RetrievableKnowledge.RAGOutputFormat;
        printSources: boolean;
        useMMR: boolean;
    }
    
    export type RAGLLMSettings = _RAGLLMSettings;
    
    /** 
     * Translated from enum com.dataiku.dip.llm.retrieval.RetrievableKnowledge$RAGOutputFormat
     * Via: com.dataiku.dip.llm.retrieval.RetrievableKnowledge$RAGLLMSettings
     */
    export enum RAGOutputFormat {
        TEXT = "TEXT",
        JSON = "JSON",
    }
    /** 
     * Translated from enum com.dataiku.dip.llm.retrieval.RetrievableKnowledge$RetrieverType
     * Via: com.dataiku.dip.llm.retrieval.RetrievableKnowledge
     */
    export enum RetrieverType {
        VECTOR_STORE_BACKED = "VECTOR_STORE_BACKED",
    }
    /** 
     * Translated from enum com.dataiku.dip.llm.retrieval.RetrievableKnowledge$VectorStoreType
     * Via: com.dataiku.dip.llm.retrieval.RetrievableKnowledge
     */
    export enum VectorStoreType {
        FAISS = "FAISS",
        CHROMA = "CHROMA",
        PINECONE = "PINECONE",
    }
}