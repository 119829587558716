import { Injectable } from '@angular/core';
import { Experiment } from '@model-main/experimenttracking/experiment';
import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Run } from 'src/generated-sources';

@Injectable({ providedIn: 'root' })
export class PrivateExperimentTrackingAPI {
    constructor(
        private dkuHttp: DkuHttpService
    ) { }

    searchRuns(projectKey: string, experimentIds: string[], viewType: string): Observable<Run[]> {
        return this.dkuHttp.request('GET', '/experiment-tracking/runs/search', { projectKey, experimentIds, viewType }, false);
    }

    getRun(projectKey: string, runId: string): Observable<Run> {
        return this.dkuHttp.request('GET', '/experiment-tracking/runs/get', { projectKey, runId }, false);
    }

    getExperiment(projectKey: string, experimentId: string): Observable<Experiment> {
        return this.dkuHttp.request('GET', '/experiment-tracking/experiments/get', { projectKey, experimentId }, false);
    }

    listExperiments(projectKey: string, viewType: string): Observable<Experiment[]> {
        return this.dkuHttp.request('GET', '/experiment-tracking/experiments/list', { projectKey, viewType }, false);
    }

    deleteExperiments(projectKey: string, experimentIds: string[]): Observable<void> {
        return this.dkuHttp.request('POST', '/experiment-tracking/delete-experiments', {
            projectKey,
            experimentIds: JSON.stringify(experimentIds)
        }, false);
    }

    restoreExperiments(projectKey: string, experimentIds: string[]): Observable<void> {
        return this.dkuHttp.request('POST', '/experiment-tracking/restore-experiments', {
            projectKey,
            experimentIds: JSON.stringify(experimentIds)
        }, false);
    }

    deleteRuns(projectKey: string, runIds: string[]): Observable<void> {
        return this.dkuHttp.request('POST', '/experiment-tracking/delete-runs', {
            projectKey,
            runIds: JSON.stringify(runIds)
        }, false);
    }

    restoreRuns(projectKey: string, runIds: string[]): Observable<void> {
        return this.dkuHttp.request('POST', '/experiment-tracking/restore-runs', {
            projectKey,
            runIds: JSON.stringify(runIds)
        }, false);
    }
}
