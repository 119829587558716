import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnChanges, Output } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { FormArrayRepeat } from "@utils/form-array-repeat";
import { fairAny } from "dku-frontend-core";
import _ from "lodash";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { AggregationResults, ALL_FACET_KEYS, Facet, FacetKey, FacetMap, FacetValue } from "../feature-store-models";

@UntilDestroy()
@Component({
  selector: "facet-list",
  templateUrl: "./facet-list.component.html",
  styleUrls: ["./facet-list.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacetListComponent implements OnChanges {
  @Input() aggregationResults!: AggregationResults;
  @Input() users!: Record<string, string>;
  @Input() projectNames!: Record<string, string>;
  @Output() newFacetSelection: EventEmitter<FacetMap> = new EventEmitter();

  form: UntypedFormGroup;

  facetsCollapse: Record<string, boolean> = {};
  showInput: Record<string, boolean> = {};
  facetsShowAll: Record<string, boolean> = {};

  facetFieldsDisplayNames: Record<FacetKey, string> = {
    "projectKey.raw": "Projects",
    user: "Contributors",
    "tag.raw": "Tags",
    isQuicklyShareable: "Quickly shareable",
    partitioned: "Partitioned",
    meaning: "Meaning"
  };

  facetDataQaSmid: Record<FacetKey, string> = {
    "projectKey.raw": "projects",
    user: "contributors",
    "tag.raw": "tags",
    isQuicklyShareable: "quickly-shareable",
    partitioned: "partitioned",
    meaning: "meaning"
  };

  constructor(@Inject("$rootScope") public $rootScope: fairAny, private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      facets: new FormArrayRepeat(() => {
        return this.fb.group({
          key: "" as FacetKey,
          facetSearch: "",
          values: new FormArrayRepeat(() => {
            return this.fb.group({
              key: "",
              value: "",
              count: 0,
              selected: false
            });
          })
        });
      })
    });
    this.form.valueChanges
      .pipe(
        map((formValue) => this.getSelectedFacets(formValue)),
        debounceTime(200),
        distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
        untilDestroyed(this)
      )
      .subscribe((facetMap) => {
        this.newFacetSelection.emit(facetMap);
      });
  }

  ngOnChanges() {
    const facets: Facet[] = [];
    ALL_FACET_KEYS.forEach((key) => {
      if (key === "isQuicklyShareable" && !this.$rootScope.appConfig.quickSharingElementsEnabled) return;
      const results = this.aggregationResults[key];
      if (results) {
        const buckets = results.agg.buckets;
        if (buckets.length > 0) {
          const facetValuesControls: FacetValue[] = [];
          const existingFacetValues: UntypedFormGroup[] = this.getFacetValues(key);
          const bucketKeys: (string | number)[] = [];
          buckets.forEach((bucket) => {
            const selected = this.getSelectedState(existingFacetValues, bucket.key);
            facetValuesControls.push({
              key: bucket.key,
              value: this.formatFacetValue(bucket.key, key),
              count: bucket.doc_count,
              selected: selected
            });
            bucketKeys.push(bucket.key);
          });
          existingFacetValues.forEach((facet) => {
            const facetKey: string = facet.get("key")?.value;
            if (!bucketKeys.includes(facetKey) && this.getSelectedState(existingFacetValues, facetKey)) {
              facetValuesControls.push({
                key: facetKey,
                value: this.formatFacetValue(facetKey, key),
                count: 0,
                selected: true
              });
            }
          });
          facets.push({
            key: key,
            facetSearch: this.getExistingFacetSearch(key),
            values: facetValuesControls
          });
        }
      }
    });
    this.form.setValue({ facets: facets });
  }

  resetForm() {
    (this.form.get("facets") as UntypedFormArray).reset({});
  }

  getFacetForms() {
    return (this.form.get("facets") as UntypedFormArray).controls as UntypedFormGroup[];
  }

  numberOfValues(facetForm: UntypedFormGroup) {
    return (facetForm.get("values") as UntypedFormArray).length || 0;
  }

  getFacetForm(facetKey: string) {
    return this.getFacetForms().find((facetControl) => {
      return facetControl.controls.key.value === facetKey;
    });
  }

  getFacetValues(facetKey: string) {
    const facetForm = this.getFacetForm(facetKey);
    if (!facetForm) return [];
    else {
      return (facetForm.controls.values as UntypedFormArray).controls as UntypedFormGroup[];
    }
  }

  getSelectedState(facetValues: UntypedFormGroup[], value: string | number) {
    return (
      (facetValues.find((facetValue) => {
        return facetValue.controls.key.value === value;
      })?.controls.selected.value as boolean) || false
    );
  }

  getExistingFacetSearch(facetKey: string) {
    const facetForm = this.getFacetForm(facetKey);
    if (!facetForm) return "";
    else {
      return ((facetForm.controls.facetSearch as UntypedFormControl).value as string) || "";
    }
  }

  getSelectedFacets(facets: { [key: string]: Facet[] }) {
    return facets["facets"].reduce((facetMap: FacetMap, facet: Facet) => {
      const key = facet.key;
      const facetValues = facet.values;
      const selectedValues = facetValues
        .filter((facetValue) => {
          return facetValue.selected;
        })
        .map((facetValue) => `${facetValue.key}`);
      if (selectedValues.length > 0) facetMap[key] = selectedValues;
      return facetMap;
    }, {});
  }

  formatFacetValue(facetValue: string | number | boolean, facet: string) {
    const value = String(facetValue);
    switch (facet) {
      case "projectKey.raw":
        return this.projectNames[value] || value;
      case "user":
        return this.users[value] || value;
      case "partitioned":
        return value === "0" ? "No" : "Yes";
      case "isQuicklyShareable":
        return value === "0" ? "No" : "Yes";
      case "meaning":
        return value || "";
    }
    return value;
  }

  facetKey(facetForm: UntypedFormGroup) {
    return this.facetKeyForm(facetForm).value as FacetKey;
  }

  facetKeyForm(facetForm: UntypedFormGroup) {
    return facetForm.get("key") as UntypedFormControl;
  }

  facetValuesForm(facetForm: UntypedFormGroup) {
    return facetForm.get("values") as UntypedFormArray;
  }

  facetValues(facetForm: UntypedFormGroup) {
    return this.facetValuesForm(facetForm).controls as UntypedFormGroup[];
  }

  formatFacetName(field: FacetKey) {
    return this.facetFieldsDisplayNames[field];
  }

  resetFacetSearch(key: string, facetForm: UntypedFormGroup) {
    this.showInput[key] = !this.showInput[key];
    (facetForm.controls.facetSearch as UntypedFormControl).setValue("", { emitEvent: false });
  }

  onFacetSearchKeyDown(key: string, facetForm: UntypedFormGroup, e: KeyboardEvent) {
    if (e.key === "Esc" || e.key === "Escape") {
      // Esc for IE/Edge
      (e.target as HTMLInputElement).blur();
      this.showInput[key] = false;
      (facetForm.controls.facetSearch as UntypedFormControl).setValue("", { emitEvent: false });
    }
  }

  selectAllFacetValues(facetForm: UntypedFormGroup) {
    this.facetValues(facetForm).forEach((facetValueControl) => {
      (facetValueControl.get("selected") as UntypedFormControl).setValue(false);
    });
  }

  noFacetSelected(facetForm: UntypedFormGroup) {
    return !this.facetValues(facetForm).find((facetValueControl) => {
      return (facetValueControl.get("selected") as UntypedFormControl).value as boolean;
    });
  }

  filterFacetsValues(facetForm: UntypedFormGroup) {
    const search = (facetForm.controls.facetSearch.value || "").toLowerCase();
    if (!search || !search.length) return this.facetValues(facetForm);
    return this.facetValues(facetForm).filter((control) => {
      return String(control.get("value")?.value.toLowerCase()).includes(search);
    });
  }

  hasMoreItems(key: string, facetForm: UntypedFormGroup, facetValues: UntypedFormGroup[]) {
    return facetValues.length > 4 && !this.facetsShowAll[key] && facetForm.controls.facetSearch.value === "";
  }

  facetSelected(facet: UntypedFormGroup) {
    return (facet.controls.selected.value as boolean) || false;
  }
}
