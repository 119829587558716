import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DKURootScope } from '@shared/models/dku-root-scope.model';
import { SmartNumberPipe } from '@shared/pipes/number-pipes/smart-number.pipe';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'number-of-records-form',
    templateUrl: './number-of-records-form.component.html',
    styleUrls: ['../forms.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberOfRecordsFormComponent implements OnInit {
    public max$ = new BehaviorSubject<number>(1000000);
    public error$ = new BehaviorSubject<string | null>(null);

    private minValidator = Validators.min(1);
    private maxValidator = Validators.max(this.max$.value);

    public defaultNumberOfRecords = 100000;
    public numberOfRecordsControl = new UntypedFormControl(this.defaultNumberOfRecords, [this.minValidator, this.maxValidator, Validators.required]);

    @Input()
    set numberOfRecords(value: number) {
        this.numberOfRecordsControl.patchValue(value);
    }

    @Output() numberOfRecordsChange = new EventEmitter<number>();

    constructor(private smartNumberPipe: SmartNumberPipe, @Inject('$rootScope') $rootScope: DKURootScope) {
        this.max$.next($rootScope.appConfig.scatterSettings?.maxNbOfRecords || 1000000);
    }

    ngOnInit(): void {
        this.max$.subscribe(max => {
            this.numberOfRecordsControl.removeValidators(this.maxValidator);
            this.maxValidator = Validators.max(max);
            this.numberOfRecordsControl.addValidators(this.maxValidator);
            this.numberOfRecordsControl.updateValueAndValidity();
        });

        this.numberOfRecordsControl.valueChanges
            .subscribe(() => {
                if (this.numberOfRecordsControl.valid) {
                    this.error$.next(null);
                } else if (this.numberOfRecordsControl.errors) {
                    if (this.numberOfRecordsControl.errors.required) {
                        this.error$.next('You must set a value (default 100k)');
                    }

                    if (this.numberOfRecordsControl.errors.min) {
                        this.error$.next('Min nb cannot be lower than 1.');
                    }

                    if (this.numberOfRecordsControl.errors.max) {
                        this.error$.next(`Max nb cannot exceed ${this.smartNumberPipe.transform(this.max$.value, true)}.`);
                    }
                }
            });

        //  Update to check if initial nb of records doesn't exceed allowed max nb of records
        this.numberOfRecordsControl.updateValueAndValidity();
    }

    setNumberOfRecords() {
        if (this.numberOfRecordsControl.valid) {
            this.numberOfRecordsChange.emit(this.numberOfRecordsControl.value ?? undefined);
        }
    }
}
