import { Component, Output, Input, EventEmitter, OnChanges, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { Card } from 'src/generated-sources';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { CardAction, CardActionType } from '../cards/events';
import { DkuPopoverComponent } from '@app/widgets/dropdowns/dku-popover/dku-popover.component';
import { hasConfidenceLevel } from '@features/eda/utils';

@Component({
    selector: 'edit-confidence-level-settings',
    templateUrl: './edit-confidence-level-settings.component.html',
    styleUrls: [
        '../../shared-styles/forms.less',
        '../../shared-styles/header-button.less',
        './edit-confidence-level-settings.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditConfidenceLevelSettingsComponent implements OnChanges {
    @Input() params: Card;
    @Input() canSave = true;
    @Output() action = new EventEmitter<CardAction>();
    @ViewChild('popover') popover: DkuPopoverComponent;

    form = new UntypedFormGroup({
        confidenceLevel: new UntypedFormControl(undefined, [
            Validators.required,
            Validators.min(0.5),
            Validators.max(0.99999)
        ]),
        showConfidenceInterval: new UntypedFormControl(undefined, [Validators.required])
    });

    ngOnChanges() {
        if (this.params && hasConfidenceLevel(this.params)) {
            this.form.patchValue({
                confidenceLevel: this.params.confidenceLevel,
                showConfidenceInterval: this.params.showConfidenceInterval,
            });
        }
    }

    save() {
        if (this.canSave && this.form.valid) {
            this.action.emit({
                type: CardActionType.UPDATE,
                newParams: { ...this.params, ...this.form.value }
            });

            this.popover.hide();
        }
    }

    get confidenceLevel(): number {
        return (this.params as any).confidenceLevel;
    }
}
