<form class="dkuform-chart-options" [formGroup]="displayOptionsForm">

    <div class="control-group" *ngIf="showOverlapSettings$ | async">
        <label class="control-label">Overlapping strategy</label>
        <div class="controls">
            <ng-select
                [items]="overlappingStrategies"
                bindLabel="name" 
                bindValue="id"
                formControlName="overlappingStrategy"
                [clearable]="false"
                [searchable]="false"
                appendTo="body">   
            </ng-select>
        </div>
    </div>

    <dss-reactive-input [inputTemplate]="textFormattingForm" formControlName="textFormatting">
        <ng-template #textFormattingForm let-value="value" let-onValueChange="onValueChange">
            <text-formatting-form [textFormatting]="value" (textFormattingChange)="onValueChange($event)"
                [showBackgroundSettings]="showBackgroundSettings" [showFontColorSettings]="showFontColorSettings"
                defaultFontColor="AUTO" [enableReset]="true" [resetTooltip]="resetTooltip$ | async">
            </text-formatting-form>
        </ng-template>
    </dss-reactive-input>
</form>

