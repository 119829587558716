import { Injectable } from '@angular/core';
import { get, set } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class ChartFormattingPaneService {
    folds: Record<string, boolean> = {};

    open(fold: string, scrollIntoView?: boolean) {
        set(this.folds, fold, true);

        if (scrollIntoView) {
            //  Scroll to opened fold if exists.
            setTimeout(() => document.querySelector(`#${fold}`)?.scrollIntoView());
        }
    }

    close(fold: string) {
        set(this.folds, fold, false);
    }

    toggle(fold: string) {
        //  Retrieves fold state, if undefined, return false (meaning closed).
        const foldState = get(this.folds, fold, false);
        set(this.folds, fold, !foldState);
    }

    set(fold: string, value: boolean) {
        set(this.folds, fold, value);
    }
}
