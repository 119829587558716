<modal-header [title]="modalTitle" [hasBorder]="true" icon="dku-icon-stacked-24"></modal-header>
<div class="modal-body">
    <form class="dkuform-modal-wrapper dkuform-modal-horizontal" [formGroup]="fullForm">
        <api-error-alert [apiError]="errorContext.error$ | async" (resetError)="errorContext.resetError()"
            [closable]="true"></api-error-alert>
        <div class="control-group mleft16 mright16">
            <label class="control-label">Project</label>
            <div class="controls data-collection-add-objects__select">
                <dss-project-selector class="data-collection-selector" [projects]="projects$ | async"
                    formControlName="projectKey" id="projectKey" required>
                </dss-project-selector>
            </div>
        </div>

        <div class="control-group mleft16 mright16">
            <label class="control-label" for="objectPicker">Datasets</label>
            <div class="controls data-collection-add-objects__select" *ngIf="(selectedType$ | async) as type">
                <dss-accessible-objects-selector [objects]="(selectableObjects$ | async) ?? []" [type]="type"
                    formControlName="selectedObjects" [multi]="true">
                </dss-accessible-objects-selector>
            </div>
        </div>

        <div  *ngIf="(selectedObjects$ | async)?.length" class="control-group mleft16 mright16">
            <table formArrayName="objectOptions"
                class="table"
            >
                <tr>
                    <th>Dataset</th>
                    <th class="data-collection-add-objects__header-authorization">
                        <label class="horizontal-flex aic data-collection-add-objects__header-label">
                            <span>Object authorization</span>
                            <i class="dku-icon-question-circle-fill-16 cursor-pointer" 
                                data-placement="bottom"
                                [dkuMdPopover]="authPopoverMessage"
                                dkuMdTitle="More info about object authorization">
                            </i>
                        </label>
                    </th>
                    <th class="data-collection-add-objects__header-quick-sharing">
                        <label class="horizontal-flex aic data-collection-add-objects__header-label">
                            <span>Enable Quick sharing</span>
                            <i class="dku-icon-question-circle-fill-16 cursor-pointer" 
                                dkuMdPopover="<p>Quick sharing is currently not enabled on this dataset.</p><p>Enabling it will allow users who have read access to the dataset<br/>to use it in other projects without requiring a request.</p>" 
                                dkuMdTitle="More info about Quick sharing"
                                data-placement="bottom">
                            </i>
                        </label>
                    </th>
                </tr>
                <tr *ngFor="let obj of (selectedObjects$ | async); let i = index" [formGroupName]="i">
                    <td class="data-collection-add-objects__table-td">
                        <span class="data-collection-add-objects__dataset-name">
                            <i class="icon universe-color dataset {{obj.subtype | datasetTypeToIcon:16 | toModernIcon:16}}"></i>
                            <span class="mx-textellipsis" [matTooltip]="obj.label" isEllipsisActive>{{obj.label}}</span>
                        </span>
                    </td>
                    <td>
                        <dku-bs-select-form-control formControlName="requestedReaderAuthorizations"
                            [ngOptions]="'authorization.value as authorization.label disable when authorization.disabled for authorization in list'"
                            [list]="obj.authorizationOptions" [layout]="'list'"
                            [optionsDescriptions]="objectAuthorizationDescriptions">
                        </dku-bs-select-form-control>
                    </td>
                    <td class="data-collection-add-objects__table-td data-collection-add-objects__quick-sharing">
                        <span [matTooltip]="obj.quickSharingTooltip">
                            <input type="checkbox" formControlName="enableQuickSharing"/>
                        </span>
                    </td>
                </tr>
            </table>
        </div>
    </form>
</div>
<div class="modal-footer modal-footer-std-buttons">
    <div class="pull-right">
        <button type="button" class="btn btn--text btn--secondary" (click)="cancel()">Cancel</button>
        <button type="submit" class="btn btn--text btn--primary" (click)="confirm()" [disabled]="fullForm.valid === false || addActionPending"
            autofocus>
            Add
        </button>
    </div>
</div>