import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { ComputationResult } from './../computation-result';
import type { Grouping } from './../../grouping/grouping';
import type { GroupingResult } from './../../grouping/grouping-result';
import type { _AvailableResult } from './../available-result';
import type { _Computation } from './../computation';
import type { _ComputationResult } from './../computation-result';
import type { _Grouping } from './../../grouping/grouping';
import type { _GroupingResult } from './../../grouping/grouping-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.common.GroupedComputation
 * Via: UIModel annotation in the class hierarchy
 */
export interface _GroupedComputation extends _Computation {
    type: "grouped";
    computation: Computation;
    grouping: Grouping;
}

export type GroupedComputation = _GroupedComputation;

export namespace GroupedComputation {
    /** Type of class com.dataiku.dip.eda.compute.computations.common.GroupedComputation in PolyJSON mappings */
    export const type = "grouped";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.common.GroupedComputation$GroupedComputationResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _GroupedComputationResult extends _AvailableResult {
        type: "grouped";
        groups: GroupingResult;
        results: ComputationResult[];
    }
    
    export type GroupedComputationResult = _GroupedComputationResult;

    export namespace GroupedComputationResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.common.GroupedComputation$GroupedComputationResult in PolyJSON mappings */
        export const type = "grouped";
    }
}