<labeling-error></labeling-error>
<ng-container *ngIf="labelingService.labelingTaskInfo$ | async as task">
    <ng-container *ngIf="{unusableTaskWarning: unusableTaskWarning$ | async} as ctx">
        <labeling-unusable-warning *ngIf="ctx.unusableTaskWarning" [warning]="ctx.unusableTaskWarning" [privileges]="privilegesOnTask"></labeling-unusable-warning>
        <ng-container *ngIf="!ctx.unusableTaskWarning">
            <div class="labeling-overview">
                <div class="labeling-overview-insight" *ngIf="globalPerRecordStatusCount$ | async as globalProgress">
                    <div class="global-progress__header">
                        <div class="dku-grand-title">Global Progress</div>
                        <a *ngIf="privilegesOnTask?.canReview && globalProgress['READY_FOR_REVIEW'] > 0" (click)="goToReviewTab()"
                            class="btn btn--contained btn--primary">Review {{itemName(task.type)}}s</a>
                    </div>
                    <div class="global-progress__legend" *ngIf="minNbAnnotatorsPerRecord$ | async as minNbAnnotatorsPerRecord">
                        <span><span class="labeling-color-dot labeling-color-dot--reviewed"></span> {{ globalProgress['REVIEWED']}} {{ itemName(task.type) | plurify: globalProgress['REVIEWED'] }} reviewed
                        </span>
                        <span><span class="labeling-color-dot labeling-color-dot--to-review"></span>{{ globalProgress['READY_FOR_REVIEW'] }} to be
                            reviewed</span>
                        <span *ngIf="minNbAnnotatorsPerRecord > 1"><span class="labeling-color-dot labeling-color-dot--partially-labeled"></span>{{ globalProgress['PARTIALLY_LABELED'] }}
                            partially labeled  <i class="labeling-overview-tooltip icon-question-sign" matTooltip="Labeled by at least one annotator, but not all"></i></span>
                        <span><span class="labeling-color-dot labeling-color-dot--to-label"></span>{{ globalProgress['TO_LABEL']}} to label</span>
                        <span *ngIf="globalProgress['INVALID']"><span class="labeling-color-dot labeling-color-dot--invalid"></span>{{ globalProgress['INVALID'] }}
                            invalid {{ itemName(task.type) | plurify: globalProgress['INVALID'] }} <i class="labeling-overview-tooltip icon-question-sign" matTooltip="Rows with missing {{ idName(task.type) + (textTask(task.type) ? ' or text' : '') }}"></i></span>
                    </div>
                    <div class="progress-bar-container" *ngIf="totalNumRecords$ | async as totalNumRecords">
                        <div class="global-progress-bar progress-bar--reviewed"
                            [ngStyle]="{ 'width': 100 * globalProgress['REVIEWED'] / totalNumRecords + '%' }"></div>
                        <div class="global-progress-bar progress-bar--to-review"
                            [ngStyle]="{ 'width': 100 * globalProgress['READY_FOR_REVIEW'] / totalNumRecords + '%' }"></div>
                        <div class="global-progress-bar progress-bar--partially-labeled"
                            [ngStyle]="{ 'width': 100 * globalProgress['PARTIALLY_LABELED'] / totalNumRecords + '%' }"></div>
                        <div class="global-progress-bar progress-bar--to-label"
                            [ngStyle]="{ 'width': 100 * globalProgress['TO_LABEL'] / totalNumRecords + '%' }"></div>
                        <div class="global-progress-bar progress-bar--invalid"
                             [ngStyle]="{ 'width': 100 * globalProgress['INVALID'] / totalNumRecords + '%' }"></div>
                    </div>
                </div>
                <ng-container *ngIf="{perAnnotatorStats: perAnnotatorStats$ | async} as ctx">
                    <ng-container *ngIf="ctx.perAnnotatorStats !== null">
                        <image-classification-per-labeler-stats class="labeling-overview-insight" *ngIf="task.type === LabelingTaskType.IMAGE_CLASSIFICATION" [perAnnotatorStats]="ctx.perAnnotatorStats"></image-classification-per-labeler-stats>
                        <classical-per-labeler-stats class="labeling-overview-insight" *ngIf="task.type === LabelingTaskType.OBJECT_DETECTION || task.type === LabelingTaskType.NAMED_ENTITY_EXTRACTION" [perAnnotatorStats]="ctx.perAnnotatorStats"></classical-per-labeler-stats>
                    </ng-container>
                </ng-container>

                <div class="labeling-overview-insight" *ngIf="globalPerClassPercentage$ | async as perClassPercentage">
                    <div class="dku-grand-title">Label popularity</div>
                    <table class="table table-hover labeling-overview-insight__table ">
                        <thead>
                            <tr>
                                <th width="50%">Label</th>
                                <th width="50%" class="labeling-overview-insight__figure-col">Usage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="objectKeysFun(perClassPercentage).length === 0">
                                <td colspan="2" class="labeling-overview-insight__empty-state-table">
                                    No classes have been defined yet
                                </td>
                            </tr>
                            <tr *ngFor="let classPercentage of perClassPercentage | keyvalue: sortByDescValue">
                                <td class="labeling-overview-insight__name-col">
                                    <span class="labeling-color-dot"
                                        [ngStyle]="{'background-color': getColorForClass(classPercentage.key)}"></span>
                                    <span [ngClass]="{ 'disabled': !classPercentage.value[1] }" title="{{ classPercentage.key }}">{{ classPercentage.key }}</span>
                                </td>
                                <td class="labeling-overview-insight__figure-col" [ngClass]="{ 'disabled': !classPercentage.value[1] }">{{ classPercentage.value[0] | percent: '1.0-1'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
