import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { HTMLTooltipDirective } from './html-tooltip.directive';

@NgModule({
    declarations: [
        HTMLTooltipDirective
    ],
    imports: [
        CommonModule,
        OverlayModule
    ],
    exports: [
        HTMLTooltipDirective
    ]
})
export class HTMLTooltipModule { }
