<modal-header title="Create story"></modal-header>
<div class="modal-body">
    <api-error-alert
        [apiError]="data.error.error$ | async"
        [closable]="true"
        (resetError)="data.error.resetError()">
    </api-error-alert>
    <p>Datasets will be automatically synchronized with Dataiku Story</p>
    <workspace-datastory [workspace]="data.workspace" (datastoryChanged)="updateDatastory($event)">
    </workspace-datastory>
</div>
<div class="modal-footer modal-footer-std-buttons">
    <div class="pull-right">
        <button type="button" class="btn btn--secondary btn--text" (click)="cancel()">Cancel</button>
        <button type="submit" class="btn btn--primary btn--text" (click)="confirm()" [disabled]="!datastory" autofocus>
           Create
        </button>
    </div>
</div>
