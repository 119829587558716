import { AbstractControl, isFormArray, isFormGroup } from '@angular/forms';

// Recursively mark as dirty the passed control and all its children
export function markAllAsDirty(control: AbstractControl): void {
    if (isFormArray(control)) {
        control.controls.forEach((c) => markAllAsDirty(c));
    } else if (isFormGroup(control)) {
        Object.keys(control.controls).forEach((key) => {
            const c = control.controls[key];
            c && markAllAsDirty(c);
        });
    }
    control.markAsDirty();
}
