export namespace UsageDataReporting {
    /** 
     * Translated from enum com.dataiku.dip.server.services.UsageDataReporting$UsageDataReportingMode
     * Via: UIModel annotation in the class hierarchy
     */
    export enum UsageDataReportingMode {
        NO = "NO",
        OPEN_ONLY = "OPEN_ONLY",
        DEFAULT = "DEFAULT",
    }
    /** 
     * Translated from enum com.dataiku.dip.server.services.UsageDataReporting$UsageDataReportingPrivacyMode
     * Via: UIModel annotation in the class hierarchy
     */
    export enum UsageDataReportingPrivacyMode {
        ANONYMOUS = "ANONYMOUS",
        LINK = "LINK",
        DEFAULT = "DEFAULT",
    }
}