import { Injectable, Inject } from '@angular/core';
import { fairAny } from 'dku-frontend-core';

@Injectable({
  providedIn: 'root'
})
export class NewInVersionService {

  constructor(@Inject('localStorageService') private localStorageService: fairAny) { }

  getLocalStoragePattern(popoverId: string, version: string): string {
    return `dismissedPopover_${popoverId}_v${version}`;
  }

  storeDismissedPopoverInLocalStorage(popoverId: string, version: string): void {
    this.localStorageService.set(this.getLocalStoragePattern(popoverId, version), true);
  }

  getDismissedPopoverFromLocalStorage(popoverId: string, version: string): boolean {
    return this.localStorageService.get(this.getLocalStoragePattern(popoverId, version));
  }

  extractMajorVersion(dssVersion: string | undefined) {
    if (!dssVersion) return undefined;
    const match = dssVersion.match(/^(\d+)\..*/);
    return match ? match[1] : undefined;
  }

}
