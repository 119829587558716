import { Inject, Injectable } from '@angular/core';
import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { EChartsOption, RadarSeriesOption } from 'echarts';
import { ChartAxisTypes } from '../../../enums';
import { ChartCoordinates, FrontendChartDef } from '../../../interfaces';
import { ChartRadarDataWrapper } from '../../../models';
import { ChartDimensionService, ChartFormattingService, ChartLabelsService, ColorUtilsService } from '../../../services';
import { EChartSeriesContext, EChartLegendContext, EChartDrawContext, PrepareDataService, NonCartesian2DEChartDef, EChartPrepareDataContext, EChartOptionsContext, EChartMatrixPoint } from '../../echarts';

@Injectable({
    providedIn: 'root'
})
export class RadarEChartDefService extends NonCartesian2DEChartDef {
    chartData: ChartRadarDataWrapper;
    chartDef: FrontendChartDef;
    truncateAxisNames: (name: string, formattingOptions: {
        fontFamily: string; fontSize: number;
    }) => string; // Temporary solution while `overflow: 'truncate'` for axisName is not working, https://github.com/apache/echarts/issues/13551

    constructor(
        @Inject('StringUtils') protected stringUtils: any,
        @Inject('ChartStoreFactory') chartStoreFactory: any,
        @Inject('CHART_FORMATTING_OPTIONS') chartFormattingOptions: any,
        private colorUtils: ColorUtilsService,
        private chartLabelsService: ChartLabelsService,
        chartFormattingService: ChartFormattingService,
        prepareDataService: PrepareDataService,
        private chartLabels: ChartLabelsService,
        private chartDimension: ChartDimensionService
    ) {
        super(stringUtils, chartStoreFactory, chartFormattingOptions, chartFormattingService, prepareDataService, colorUtils);
    }

    wrapData(data: PivotTableTensorResponse, axesDef?: Record<string, number>): ChartRadarDataWrapper {
        return new ChartRadarDataWrapper(data, axesDef, this.chartDef);
    }

    onInit(chartDef: FrontendChartDef, data: PivotTableTensorResponse, axesDef: Record<string, number>) {
        // TODO: remove once we know how to deal with it in ChartRadarDataWrapper.
        this.chartDef = chartDef;
        super.onInit(chartDef, data, axesDef);
    }

    onLegendHover = (legendContext: EChartLegendContext) => {
        const actionType = legendContext.mouseEnter ? 'highlight' : 'downplay';
        legendContext.echartInstance.dispatchAction({
            type: actionType,
            dataIndex: legendContext.item.index
        });
    };

    getColorSpec(chartDef: FrontendChartDef) {
        return {
            type: ChartAxisTypes.DIMENSION,
            name: 'color',
            dimension: chartDef.genericDimension0[0]
        };
    }

    getThumbnailOptions(options: EChartsOption) {
        return {
            radar: {
                indicator: (options.radar as any).indicator.map((indicator: any) => ({ ...indicator, name: '' }))
            },
            ...super.getThumbnailOptions(options)
        };
    }

    draw(drawContext: EChartDrawContext): { options: EChartsOption, allCoords: Array<Array<ChartCoordinates>> } {

        const { chartDef, chartBase } = drawContext;
        const chartData = drawContext.chartData as ChartRadarDataWrapper;

        this.chartData = chartData;
        this.chartDef = chartDef;

        const gridOptions = drawContext.chartBase.margins;
        const chartWidth = drawContext.chartBase.width;
        const chartHeight = drawContext.chartBase.height;

        const ratioOfRadarRadius = 0.8; // approximation of percentage of frame occupied by radar

        this.truncateAxisNames = (label: string, formattingOptions: {fontFamily: string, fontSize: number}) => {
            // Same truncate logic as in stories
            const padding = 6;
            const maxLabelSpacing = (
                (chartWidth - Math.min(chartWidth, chartHeight) * ratioOfRadarRadius)/2 -
                (gridOptions.left || 0) -
                (gridOptions.right || 0)
            ) - padding;

            const labelWidth = this.stringUtils.getTextWidth(label, `${formattingOptions.fontSize}px ${formattingOptions.fontFamily}`);

            const truncatedLabelLength = (label.length * maxLabelSpacing) / labelWidth;

            if (labelWidth <= maxLabelSpacing) {
                return label;
            }

            return `${label.slice(0, truncatedLabelLength)}...`;
        };

        const prepareDataContext: EChartPrepareDataContext = {
            chartDef: chartDef,
            chartData: chartData,
            legends: drawContext.legends,
            colorScale: chartBase.colorScale,
            mapper: value => this.mapValue(value)
        };

        this.chartPoints = this.prepareData(prepareDataContext);

        // group values by dimension
        if(!chartData.shouldDrawPolygonsFromMeasures()) {
            const groupChartPoints = (chartPoints: Record<string, any>[], key: string) => Object.values(
                chartPoints.reduce((res: any[], item) => {
                    const index = res.findIndex((element) => element[key] === item[key]);
                    if(index === -1) {
                        res.push({ ...item, value: item.value, coord: { ...item.coord, color: 0, measure: item.coord.color } });
                    }
                    return res;
                }, [])
            );
            this.chartPoints = groupChartPoints(this.chartPoints, 'colorLabel');
        }

        return super.draw(drawContext);
    }

    protected getSeriesId(coord: ChartCoordinates): string {
        return '';
    }

    protected getSeries({
        chartDef,
        chartData,
        colorScale,
        chartPoints
    }: EChartSeriesContext): Array<RadarSeriesOption> {
        if (!chartDef || !chartPoints) {
            throw new Error('Missing properties to build series for radar echart');
        }

        const min = chartData.getMinValue();
        const max = chartData.getMaxValue();

        const data = (chartPoints as Array<EChartMatrixPoint>).map((point: EChartMatrixPoint, index: number) => {
            const color = colorScale && this.colorUtils.toRgba(colorScale(index), chartDef.colorOptions.transparency);
            const formatValue = (axisIndex: number) => this.chartFormattingService.getForRadialChart(
                min,
                max,
                // For measures as polygons, get formatting by polygon, for measures as axes, get formatting by axis
                chartData.shouldDrawPolygonsFromMeasures() ?
                    chartData.getAxisLabels('polygon')[index] :
                    chartData.getAxisLabels('axis')[axisIndex]
            );

            const seriesData: any = {
                value: point.value as any,
                name: chartData.getAxisLabels('polygon')[index],
                itemStyle: {
                    color: color
                },
                lineStyle: {
                    color: color,
                    width: chartDef.radarOptions.lineStyle.width,
                    type: chartDef.radarOptions.lineStyle.type?.toLowerCase() as any
                },
                areaStyle: {
                    color: chartDef.radarOptions.filled ? color : 'transparent'
                },
                label: {
                    formatter: (params: any) => formatValue(params.dimensionIndex)(params.value)
                }
            };

            const labelColor = this.getLabelColor(chartDef, color);

            if (labelColor) {
                seriesData.label.color = labelColor;
            }

            return seriesData;
        });

        const labelOptions = this.getLabelFormatting(chartDef);

        const serie: RadarSeriesOption = {
            type: 'radar',
            data,
            labelLayout: labelOptions['labelLayout'],
            label: labelOptions['label'],
            emphasis: {
                focus: 'self',
                areaStyle: {
                    shadowBlur: 0,
                    opacity: 0.8
                }
            },
            blur: {
                label: {
                    show: false
                },
                areaStyle: {
                    opacity: 0.2
                },
                itemStyle: {
                    opacity: 0.5
                }
            },
            animation: false
        };

        return [serie];
    }

    protected getOptions({ series, gridOptions }: EChartOptionsContext): EChartsOption {
        const maxValue = this.chartData.getMaxValue();
        const minValue = this.chartData.getMinValue();

        const indicatorFormatter = (axis: any) => {
            const formattedValue = axis.isA === 'measure' ?
                this.chartLabelsService.getLongMeasureLabel(axis)
                :
                this.chartLabels.getFormattedLabel(
                    axis,
                    this.chartDimension.getNumberFormattingOptions(this.chartDef.genericDimension0[0]),
                    axis.min,
                    axis.max
                );

            return this.truncateAxisNames(formattedValue, {
                ...this.AXIS_LABELS_DEFAULT_FORMATTING_OPTIONS,
                ...this.chartDef.radialAxisFormatting.axisTitleFormatting
            });
        };

        const options = {
            grid: gridOptions,
            radar: {
                shape: 'circle',
                indicator: this.chartData.getAxisLabels('axis')?.map((axis) => ({
                    name: indicatorFormatter(axis), // Format axisLabels for when measures are axes
                    max: maxValue,
                    min: Math.min(minValue, 0) // set min for series with negative numbers
                })),
                axisName: {
                    // overflow: 'truncate',  // https://github.com/apache/echarts/issues/16391
                    ...this.AXIS_LABELS_DEFAULT_FORMATTING_OPTIONS,
                    padding: [2, 3, 0.5, 3],
                    ...this.chartDef.radialAxisFormatting.axisTitleFormatting,
                    color: this.chartDef.radialAxisFormatting.axisTitleFormatting?.fontColor ?? '',
                    backgroundColor: this.chartDef.radialAxisFormatting.axisTitleFormatting?.hasBackground ?
                        this.chartDef.radialAxisFormatting.axisTitleFormatting.backgroundColor :
                        'transparent'
                },
                axisLabel: {
                    hideOverlap: true
                }
            },
            series
        } as EChartsOption;

        return options;
    }
}
