import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, FormArray } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { FitDistributionCard, Variable } from 'src/generated-sources';
import { FormArrayRepeat } from '@utils/form-array-repeat';
import { toggleFormControl } from '@utils/toggle-form-control';
import { Observable } from 'rxjs';
import { CardWizardVariable } from '@features/eda/card-models';
import { CardWizardService } from '../../../card-wizard/card-wizard.service';
import { isIntegerValidator } from '@utils/is-integer-validator';

@UntilDestroy()
@Component({
    selector: 'fit-distribution-card-config',
    templateUrl: './fit-distribution-card-config.component.html',
    styleUrls: [
        '../../../../shared-styles/card-wizard.less',
        './fit-distribution-card-config.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FitDistributionCardConfigComponent implements OnInit, OnChanges, OnDestroy {
    @Input() params: FitDistributionCard;
    @Input() variables: Variable[];
    @Output() paramsChange = new EventEmitter<FitDistributionCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    allVariables$: Observable<CardWizardVariable[]>;

    fitDistributions = [{
        name: 'Beta',
        value: 'beta'
    }, {
        name: 'Exponential',
        value: 'exponential'
    }, {
        name: 'Laplace',
        value: 'laplace'
    }, {
        name: 'LogNormal',
        value: 'lognormal'
    }, {
        name: 'Normal',
        value: 'normal'
    }, {
        name: 'Normal mixture',
        value: 'normal_mixture'
    }, {
        name: 'Pareto',
        value: 'pareto'
    }, {
        name: 'Triangular',
        value: 'triangular'
    }, {
        name: 'Weibull',
        value: 'weibull'
    }];

    distributions = new FormArrayRepeat(() => {
        const type = this.fb.control('beta', [Validators.required]);

        const group = this.fb.group({
            distribution: this.fb.group({
                type,
                nbComponents: toggleFormControl(this.fb.control({ value: 2, disabled: true }, [Validators.required, Validators.min(1), isIntegerValidator()]), type, 'normal_mixture')
            })
        });

        return group;
    });

    configForm = this.fb.group({
        column: this.fb.control(null, [Validators.required]),
        distributions: this.distributions
    });

    constructor(
        private fb: UntypedFormBuilder,
        private cardWizardService: CardWizardService
    ) {
        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((formValue) => this.paramsChange.emit({
                ...this.params,
                column: formValue.column,
                distributions: formValue.distributions
            }));

        this.configForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
    }

    ngOnInit() {
        this.allVariables$ = this.cardWizardService.availableVariables(this.params.type);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.configForm.patchValue({
                column: this.params.column,
                distributions: this.params.distributions
            });
        }
    }

    ngOnDestroy() {
    }
}
