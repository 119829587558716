import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { InsightSummary } from '@shared/models';

@Injectable({providedIn: 'root'})
export class PrivateWikisAPI {
    constructor(private dkuHttp: DkuHttpService) {
    }

    getArticleSummary(projectKey: string, articleIdOrName: string): Observable<InsightSummary> {
        return this.dkuHttp.request('GET', '/projects/wikis/get-article-summary', { projectKey, articleIdOrName }, false);
    }
}
