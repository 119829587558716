import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { JointNormal } from './joint-normal';
import type { KDE2D } from './kde2-d';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _JointNormal } from './joint-normal';
import type { _KDE2D } from './kde2-d';

/** 
 * Translated from class com.dataiku.dip.eda.compute.distributions.Distribution2D
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Distribution2D extends _ImmutableValueObject {
}

/** List of all concrete subclasses of com.dataiku.dip.eda.compute.distributions.Distribution2D */
export type Distribution2D =
    | JointNormal
    | KDE2D;

export namespace Distribution2D {
    /** Types of class com.dataiku.dip.eda.compute.distributions.Distribution2D in PolyJSON mappings */
    export const type = ["joint_normal", "kde_2d", ] as const;
    
    export function isDistribution2D(obj: Distribution2D): obj is Distribution2D { return ["joint_normal", "kde_2d", ].includes(obj["type"]); }
    export function isJointNormal(obj: Distribution2D): obj is JointNormal { return obj["type"] == "joint_normal"; }
    export function isKDE2D(obj: Distribution2D): obj is KDE2D { return obj["type"] == "kde_2d"; }
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.distributions.Distribution2D$FittedDistribution2D
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FittedDistribution2D extends _ImmutableValueObject {
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.eda.compute.distributions.Distribution2D$FittedDistribution2D */
    export type FittedDistribution2D =
        | JointNormal.FittedJointNormal
        | KDE2D.FittedKDE2D;

    export namespace FittedDistribution2D {
        /** Types of class com.dataiku.dip.eda.compute.distributions.Distribution2D$FittedDistribution2D in PolyJSON mappings */
        export const type = ["joint_normal", "kde_2d", ] as const;
        
        export function isFittedDistribution2D(obj: Distribution2D.FittedDistribution2D): obj is Distribution2D.FittedDistribution2D { return ["joint_normal", "kde_2d", ].includes(obj["type"]); }
        export function isFittedJointNormal(obj: Distribution2D.FittedDistribution2D): obj is JointNormal.FittedJointNormal { return obj["type"] == "joint_normal"; }
        export function isFittedKDE2D(obj: Distribution2D.FittedDistribution2D): obj is KDE2D.FittedKDE2D { return obj["type"] == "kde_2d"; }
    }
}