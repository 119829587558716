import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.KendallTau
 * Via: UIModel annotation in the class hierarchy
 */
export interface _KendallTau extends Computation._BivariateComputation {
    type: "kendall_tau";
}

export type KendallTau = _KendallTau;

export namespace KendallTau {
    /** Type of class com.dataiku.dip.eda.compute.computations.bivariate.KendallTau in PolyJSON mappings */
    export const type = "kendall_tau";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.KendallTau$KendallTauResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _KendallTauResult extends _AvailableResult {
        type: "kendall_tau";
        correlation: number;
        pvalue: number;
    }
    
    export type KendallTauResult = _KendallTauResult;

    export namespace KendallTauResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.bivariate.KendallTau$KendallTauResult in PolyJSON mappings */
        export const type = "kendall_tau";
    }
}