import type { DeepHubMetricParams } from './deep-hub-metric-params';
import type { PredictionMLTask } from './prediction-mltask';
import type { PredictionModelingParams } from './prediction-modeling-params';
import type { _DeepHubMetricParams } from './deep-hub-metric-params';

/** 
 * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams
 * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelDetails
 */
export interface _DeepHubPreTrainModelingParams {
    augmentationParams: DeepHubPreTrainModelingParams.ImageAugmentationParams;
    dummy: boolean;
    earlyStopping: DeepHubPreTrainModelingParams.EarlyStopping;
    enableParallelDataLoading: boolean;
    epochs: number;
    gpuParams: PredictionModelingParams.GpuCodeParams;
    learningRate: number;
    lrScheduler: DeepHubPreTrainModelingParams.LrScheduler;
    metrics: DeepHubMetricParams;
    modelOptimizationSplitParams: DeepHubPreTrainModelingParams.DeepHubModelOptimizationSplitParams;
    nbFinetunedLayers: number;
    numWorkers: number;
    optimizer: DeepHubPreTrainModelingParams.Optimizer;
    perDeviceBatchSize: number;
    processCountPerNode: number;
    type: PredictionMLTask.PredictionType;
    weightDecay: number;
}

/**
    Translate from class com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams
    @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
*/
export type DeepHubPreTrainModelingParams = _DeepHubPreTrainModelingParams;

export namespace DeepHubPreTrainModelingParams {
    /** 
     * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$AffineParams
     * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ImageAugmentationParams
     */
    export interface _AffineParams {
        horizontalFlip: DeepHubPreTrainModelingParams.ImageAugmentationParam;
        rotate: DeepHubPreTrainModelingParams.RotateParams;
        verticalFlip: DeepHubPreTrainModelingParams.ImageAugmentationParam;
    }
    
    export type AffineParams = _AffineParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ColorJitterParams
     * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ImageAugmentationParams
     */
    export interface _ColorJitterParams extends DeepHubPreTrainModelingParams._ImageAugmentationParam {
        brightness: number;
        contrast: number;
        hue: number;
    }
    
    export type ColorJitterParams = _ColorJitterParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$DeepHubModelOptimizationSplitParams
     * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams
     */
    export interface _DeepHubModelOptimizationSplitParams {
        seed: number;
        trainSplitRatio: number;
    }
    
    export type DeepHubModelOptimizationSplitParams = _DeepHubModelOptimizationSplitParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$EarlyStopping
     * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams
     */
    export interface _EarlyStopping {
        enabled: boolean;
        minDelta: number;
        patience: number;
    }
    
    export type EarlyStopping = _EarlyStopping;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ImageAugmentationParam
     * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$AffineParams
     */
    export interface _ImageAugmentationParam {
        enabled: boolean;
        probability: number;
    }
    
    /**
        Translate from class com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ImageAugmentationParam
        @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
    */
    export type ImageAugmentationParam = _ImageAugmentationParam;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ImageAugmentationParams
     * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams
     */
    export interface _ImageAugmentationParams {
        affine: DeepHubPreTrainModelingParams.AffineParams;
        colorJitter: DeepHubPreTrainModelingParams.ColorJitterParams;
        crop: DeepHubPreTrainModelingParams.RandomCropParams;
    }
    
    export type ImageAugmentationParams = _ImageAugmentationParams;
    
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$LrScheduler
     * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams
     */
    export enum LrScheduler {
        PLATEAU = "PLATEAU",
        STEP = "STEP",
        EXPONENTIAL = "EXPONENTIAL",
    }
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$Optimizer
     * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams
     */
    export enum Optimizer {
        ADAM = "ADAM",
        SGD = "SGD",
        RMSPROP = "RMSPROP",
        ADAMAX = "ADAMAX",
        ADAGRAD = "ADAGRAD",
        ADADELTA = "ADADELTA",
    }
    /** 
     * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$RandomCropParams
     * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ImageAugmentationParams
     */
    export interface _RandomCropParams extends DeepHubPreTrainModelingParams._ImageAugmentationParam {
        minKeptRatio: number;
        preserveAspectRatio: boolean;
    }
    
    export type RandomCropParams = _RandomCropParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$RotateParams
     * Via: com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$AffineParams
     */
    export interface _RotateParams extends DeepHubPreTrainModelingParams._ImageAugmentationParam {
        maxRotation: number;
    }
    
    export type RotateParams = _RotateParams;
}