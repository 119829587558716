import { Component, ChangeDetectionStrategy, EventEmitter, Output, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'lines-zoom-options-form',
    templateUrl: './lines-zoom-options-form.component.html',
    styleUrls: ['../forms.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinesZoomOptionsFormComponent implements OnInit {
    @Output() zoomOptionsChange = new EventEmitter<boolean>();

    public zoomOptionsForm: UntypedFormGroup = new UntypedFormGroup({
        brush: new FormControl<boolean>(true),
    });

    public hasBrush$ = new BehaviorSubject(false);

    constructor() {}

    ngOnInit(): void {
        this.zoomOptionsForm.valueChanges
            .pipe(untilDestroyed(this), debounceTime(10))
            .subscribe((nextValue: boolean) => {
                this.zoomOptionsChange.emit(nextValue);
            });
    }
}
