<modal-header title="Edit object" icon="dku-icon-edit-16"></modal-header>
<form #editObjectNameForm="ngForm" class="dkuform-modal-horizontal dkuform-modal-wrapper">
    <div class="modal-body">
        <api-error-alert
            [apiError]="errors$ | async"
            (resetError)="resetError()"
            [closable]="true">
        </api-error-alert>
        <div class="control-group">
            <label for="customName" class="control-label">Name</label>
            <div class="controls">
                <input *ngIf="!updatedWorkspaceObject.story" type="text" [placeholder]="updatedWorkspaceObject.defaultName" id="customName" name="customName" [(ngModel)]="updatedWorkspaceObject.customName">
                <input *ngIf="updatedWorkspaceObject.story" type="text" placeholder="Story title" id="storyTitle" name="storyTitle" [(ngModel)]="updatedWorkspaceObject.story.title" required>
            </div>
        </div>
    </div>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right">
            <button type="button" class="btn btn--secondary btn--text" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn--primary btn--text" (click)="confirm()" [disabled]="editObjectNameForm.form.invalid || editObjectNameForm.form.pristine">
                Confirm
            </button>
        </div>
    </div>
</form>