import type { AxisDef } from './../../pivot/backend/model/axis-def';
import type { ChartFilter } from './../../pivot/frontend/model/chart-filter';

/** 
 * Translated from class com.dataiku.dip.shaker.filter.FilterRequest
 * Via: UIModel annotation in the class hierarchy
 */
export interface _FilterRequest {
    elements: FilterRequest.FilterElement[];
}

export type FilterRequest = _FilterRequest;

export namespace FilterRequest {
    /** 
     * Translated from class com.dataiku.dip.shaker.filter.FilterRequest$FilterElement
     * Via: com.dataiku.dip.shaker.filter.FilterRequest
     */
    export interface _FilterElement {
        active: boolean;
        column: string;
        columnType: AxisDef.Type;
        dateFilterOption: ChartFilter.DateRelativeOption;
        dateFilterPart: ChartFilter.DateFilterPart;
        dateFilterType: ChartFilter.DateFilterType;
        excludedValues: string[];
        isAGlobalFilter: boolean;
        maxValue: number;
        minValue: number;
        params: { [key in string]: string };
        selectedValues: string[];
        timezone: string;
        type: FilterRequest.FilterType;
    }
    
    export type FilterElement = _FilterElement;
    
    /** 
     * Translated from enum com.dataiku.dip.shaker.filter.FilterRequest$FilterType
     * Via: com.dataiku.dip.shaker.filter.FilterRequest$FilterElement
     */
    export enum FilterType {
        ALPHANUM = "ALPHANUM",
        ALPHANUM_FACET = "ALPHANUM_FACET",
        NUMERICAL_FACET = "NUMERICAL_FACET",
        BOUNDING_BOX_FACET = "BOUNDING_BOX_FACET",
        DATE_FACET = "DATE_FACET",
        VALIDITY = "VALIDITY",
        GLOBAL_SEARCH = "GLOBAL_SEARCH",
    }
}