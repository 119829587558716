import type { UnitRootTestCard } from './unit-root-test-card';
import type { UnitRootTestZA } from './../../compute/computations/timeseries/unit-root-test-za';
import type { _UnitRootTestCard } from './unit-root-test-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.UnitRootTestZACard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _UnitRootTestZACard extends _UnitRootTestCard {
    type: "unit_root_test_za";
    regressionMode: UnitRootTestZA.RegressionMode;
}

export type UnitRootTestZACard = _UnitRootTestZACard;

export namespace UnitRootTestZACard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.UnitRootTestZACard in PolyJSON mappings */
    export const type = "unit_root_test_za";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.UnitRootTestZACard$UnitRootTestZACardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _UnitRootTestZACardResult extends UnitRootTestCard._UnitRootTestCardResult {
        type: "unit_root_test_za";
    }
    
    export type UnitRootTestZACardResult = _UnitRootTestZACardResult;

    export namespace UnitRootTestZACardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.UnitRootTestZACard$UnitRootTestZACardResult in PolyJSON mappings */
        export const type = "unit_root_test_za";
    }
}