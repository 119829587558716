export namespace IProjectCommitModeService {
    /** 
     * Translated from enum com.dataiku.dip.git.IProjectCommitModeService$ProjectCommitMode
     * Via: com.dataiku.dip.server.services.ProjectsService$UIProject
     */
    export enum ProjectCommitMode {
        AUTO = "AUTO",
        RECIPES_EXPLICIT = "RECIPES_EXPLICIT",
        ALL_EXPLICIT = "ALL_EXPLICIT",
    }
}