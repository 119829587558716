import type { TileParams } from './tile-params';
import type { _TileParams } from './tile-params';

/** 
 * Translated from class com.dataiku.dip.dashboards.model.Tile
 * Via: com.dataiku.dip.dashboards.model.TilesGrid
 */
export interface _Tile {
    autoLoad: boolean;
    borderColor?: string | null;
    box: Tile.Box;
    clickAction: Tile.TileClickAction;
    displayMode?: Tile.TileDisplayMode | null;
    imageId?: string | null;
    insightId?: string | null;
    insightType?: string | null;
    resizeImageMode?: Tile.TileResizeImageMode | null;
    showTitle: Tile.DisplayTitleMode;
    targetInsightId?: string | null;
    tileParams: TileParams;
    tileType: Tile.TileType;
    title?: string | null;
}

export type Tile = _Tile;

export namespace Tile {
    /** 
     * Translated from class com.dataiku.dip.dashboards.model.Tile$Box
     * Via: com.dataiku.dip.dashboards.model.Tile
     */
    export interface _Box {
        height: number;
        left: number;
        top: number;
        width: number;
    }
    
    export type Box = _Box;
    
    /** 
     * Translated from enum com.dataiku.dip.dashboards.model.Tile$DisplayTitleMode
     * Via: com.dataiku.dip.dashboards.model.Tile
     */
    export enum DisplayTitleMode {
        YES = "YES",
        NO = "NO",
        MOUSEOVER = "MOUSEOVER",
    }
    /** 
     * Translated from enum com.dataiku.dip.dashboards.model.Tile$TileClickAction
     * Via: com.dataiku.dip.dashboards.model.Tile
     */
    export enum TileClickAction {
        DO_NOTHING = "DO_NOTHING",
        OPEN_INSIGHT = "OPEN_INSIGHT",
        OPEN_OTHER_INSIGHT = "OPEN_OTHER_INSIGHT",
    }
    /** 
     * Translated from enum com.dataiku.dip.dashboards.model.Tile$TileDisplayMode
     * Via: com.dataiku.dip.dashboards.model.Tile
     */
    export enum TileDisplayMode {
        INSIGHT = "INSIGHT",
        INSIGHT_DESC = "INSIGHT_DESC",
        IMAGE_AND_INSIGHT_DESC = "IMAGE_AND_INSIGHT_DESC",
        IMAGE = "IMAGE",
    }
    /** 
     * Translated from enum com.dataiku.dip.dashboards.model.Tile$TileResizeImageMode
     * Via: com.dataiku.dip.dashboards.model.Tile
     */
    export enum TileResizeImageMode {
        ORIG_SIZE = "ORIG_SIZE",
        FIT_SIZE = "FIT_SIZE",
        STRETCH_SIZE = "STRETCH_SIZE",
        FIT_CROP_SIZE = "FIT_CROP_SIZE",
    }
    /** 
     * Translated from enum com.dataiku.dip.dashboards.model.Tile$TileType
     * Via: com.dataiku.dip.dashboards.model.Tile
     */
    export enum TileType {
        INSIGHT = "INSIGHT",
        TEXT = "TEXT",
        IMAGE = "IMAGE",
        IFRAME = "IFRAME",
    }
}