import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Abstract2SampTestCard, Variable } from 'src/generated-sources';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { CardWizardService } from '../../../card-wizard/card-wizard.service';
import { CardWizardVariable } from '@features/eda/card-models';
import { Observable, EMPTY } from 'rxjs';
import { SampleContextService } from '@features/eda/sample-context.service';
import { switchMap, map, shareReplay, distinctUntilChanged } from 'rxjs/operators';
import { observeFormControl } from '@utils/form-control-observer';
import { ErrorContext, APIError, catchAPIError } from '@core/dataiku-api/api-error';
import { toggleFormControl } from '@utils/toggle-form-control';

@UntilDestroy()
@Component({
    selector: 'abstract-2-samp-card-config',
    templateUrl: './abstract-2-samp-card-config.component.html',
    styleUrls: [
        '../../../../shared-styles/card-wizard.less',
        './abstract-2-samp-card-config.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class Abstract2SampCardConfigComponent implements OnInit, OnChanges, OnDestroy, ErrorContext {
    @Input() params: Abstract2SampTestCard;
    @Output() paramsChange = new EventEmitter<Abstract2SampTestCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    testVariables$: Observable<CardWizardVariable[]>;
    groupVariables$: Observable<CardWizardVariable[]>;
    suggestedYValues$: Observable<string[]>;
    error?: APIError | null;

    private groupingMethod = this.fb.control(null, [Validators.required]);

    configForm = this.fb.group({
        xColumn: this.fb.control(null, [Validators.required]),
        yColumn: this.fb.control(null, [Validators.required]),
        groupingMethod: this.groupingMethod,
        value1: toggleFormControl(
            this.fb.control(
                { value: null, disabled: true },
                [Validators.required, Validators.minLength(1)],
            ),
            this.groupingMethod,
            'manual',
        ),
        value2: toggleFormControl(
            this.fb.control(
                { value: null, disabled: true },
                [Validators.required, Validators.minLength(1)],
            ),
            this.groupingMethod,
            'manual',
        ),
    });

    constructor(
        private fb: UntypedFormBuilder,
        private cardWizardService: CardWizardService,
        private sampleContextService: SampleContextService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.suggestedYValues$ = observeFormControl<Variable | null>(this.configForm.controls.yColumn).pipe(
            map(yColumn => yColumn?.name),
            distinctUntilChanged(),
            switchMap(yColumnName => {
                if (yColumnName) {
                    return this.sampleContextService.runInteractiveQuery({
                        type: 'list_most_frequent_values',
                        column: yColumnName,
                        maxValues: 100
                    }).pipe(
                        catchAPIError(this),
                        map(queryResult => queryResult.values || [])
                    )
                }
                return EMPTY;
            }),
            shareReplay(1)
        );

        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((formValue) => {
                let params = {
                    ...this.params,
                    xColumn: formValue.xColumn,
                    yColumn: formValue.yColumn,
                    value1: null,
                    value2: null,
                };

                if (formValue.groupingMethod === 'manual') {
                    params = {
                        ...params,
                        value1: formValue.value1,
                        value2: formValue.value2,
                    };
                }

                this.paramsChange.emit(params);
            });

        this.configForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.validityChange.emit(this.configForm.valid));
    }

    pushError(error: APIError | null) {
        this.error = error;
        this.changeDetectorRef.markForCheck();
    }

    ngOnInit() {
        this.testVariables$ = this.cardWizardService.availableVariables(this.params.type, { isTestVariable: true });
        this.groupVariables$ = this.cardWizardService.availableVariables(this.params.type);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            const isAuto = this.params.value1 == null
                && this.params.value2 == null;

            this.configForm.patchValue({
                xColumn: this.params.xColumn,
                yColumn: this.params.yColumn,
                groupingMethod: isAuto ? 'auto' : 'manual',
                value1: isAuto ? null : this.params.value1,
                value2: isAuto ? null : this.params.value2,
            });
        }
    }

    ngOnDestroy() {

    }
}
