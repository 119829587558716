import type { CardResult } from './card-result';
import type { Filter } from './../../compute/filtering/filter';
import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { MultivariateCard } from './multivariate-card';
import type { Series } from './common/series';
import type { SplitBySpec } from './../models/split-by-spec';
import type { _CardResult } from './card-result';
import type { _Filter } from './../../compute/filtering/filter';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _MultivariateCard } from './multivariate-card';
import type { _Series } from './common/series';
import type { _SplitBySpec } from './../models/split-by-spec';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.ParallelCoordinatesPlotCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ParallelCoordinatesPlotCard extends _MultivariateCard {
    type: "parallel_coordinates_plot";
    colorBy?: SplitBySpec | null;
    highlightFilter?: Filter | null;
    maxNumberOfPoints: number;
}

export type ParallelCoordinatesPlotCard = _ParallelCoordinatesPlotCard;

export namespace ParallelCoordinatesPlotCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.ParallelCoordinatesPlotCard in PolyJSON mappings */
    export const type = "parallel_coordinates_plot";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.ParallelCoordinatesPlotCard$ParallelCoordinates
     * Via: com.dataiku.dip.eda.worksheets.cards.ParallelCoordinatesPlotCard$ParallelCoordinatesPlotCardResult
     */
    export interface _ParallelCoordinates extends _ImmutableValueObject {
        columnSeries: Series[];
        groupIndex: number;
        isHighlighted: boolean;
    }
    
    export type ParallelCoordinates = _ParallelCoordinates;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.ParallelCoordinatesPlotCard$ParallelCoordinatesPlotCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ParallelCoordinatesPlotCardResult extends _CardResult {
        type: "parallel_coordinates_plot";
        groups: Filter[];
        parallelCoordinates: ParallelCoordinatesPlotCard.ParallelCoordinates[];
    }
    
    export type ParallelCoordinatesPlotCardResult = _ParallelCoordinatesPlotCardResult;

    export namespace ParallelCoordinatesPlotCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.ParallelCoordinatesPlotCard$ParallelCoordinatesPlotCardResult in PolyJSON mappings */
        export const type = "parallel_coordinates_plot";
    }
}