<div class="recipe-settings-section1 eda-recipe-settings-section">
    <h1 class="recipe-settings-section1-title">
        Principal Component Analysis settings
    </h1>

    <div class="recipe-settings-section2">
        <form class="dkuform-horizontal"
            [formGroup]="configForm"
        >
            <div *ngIf="selectedColumns.length < 1" class="alert alert-error">
                <i class="icon-dku-error icon-error-color mright4"></i>
                Principal component analysis requires at least one selected column.
                Running this recipe will fail.
            </div>

            <div class="control-group">
                <div class="control-label">
                    Input columns
                </div>

                <div class="controls">
                    <basic-select *ngIf="columns$ | async as inputColumns"
                        formControlName="columns"
                        [items]="inputColumns"
                        summarizedSelectionItemsType="columns"
                        bindLabel="name"
                        bindValue="name"
                        bindAnnotation="type"
                        [multiple]="true"
                        [searchable]="true"
                        [actionsBox]="true"
                        [invalidateOnGhosts]="true"
                        ghostSectionTitle="Invalid columns"
                        ghostItemsTooltip="This column does not exist in the input dataset."
                    ></basic-select>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="recipe-settings-section1 eda-recipe-settings-section">
    <h1 class="recipe-settings-section1-title">
        Output
    </h1>

    <div class="recipe-settings-section2">
        <form class="dkuform-horizontal"
            [formGroup]="configForm"
        >
            <div *ngIf="forwardedColumns.length > 0 && (projectionsOutput$ | async) === undefined" class="alert alert-info">
                <i class="icon-info-sign icon-info-color mright4"></i>
                You must set a projections output dataset in order to forward input columns.
            </div>

            <div *ngIf="(projectionsOutput$ | async) !== undefined && conflictingForwardedColumns.join(', ') as conflictLabel"
                class="alert alert-info"
            >
                <i class="icon-info-sign icon-info-color mright4"></i>
                The following forwarded columns have conflicting names with the recipe projections output:

                <b *ngIf="conflictLabel.length > MAX_CONFLICTING_COLUMNS_LABEL_LENGTH"
                    [matTooltip]="conflictLabel"
                    matTooltipPosition="right"
                >
                    {{ conflictLabel.slice(0, MAX_CONFLICTING_COLUMNS_LABEL_LENGTH) }}…
                </b>

                <span *ngIf="conflictLabel.length <= MAX_CONFLICTING_COLUMNS_LABEL_LENGTH">
                    {{ conflictLabel }}.
                </span>

                <br>
                The name of these columns will be prefixed in the projections dataset to remove ambiguity.
            </div>

            <div class="control-group">
                <div class="control-label">
                    Input columns to forward
                </div>

                <div class="controls">
                    <basic-select *ngIf="columns$ | async as inputColumns"
                        formControlName="forwardedColumns"
                        [items]="inputColumns"
                        summarizedSelectionItemsType="columns"
                        bindLabel="name"
                        bindValue="name"
                        bindAnnotation="type"
                        [multiple]="true"
                        [searchable]="true"
                        [actionsBox]="true"
                        [invalidateOnGhosts]="true"
                        ghostSectionTitle="Invalid columns"
                        ghostItemsTooltip="This column does not exist in the input dataset."
                    ></basic-select>
                </div>
            </div>

            <div class="control-group">
                <div class="control-label">
                    Add computation timestamp
                </div>

                <div class="controls">
                    <input type="checkbox"
                        formControlName="addComputationTimestamp"
                    />

                    <div class="help-inline">
                        Add a timestamp of when the recipe was executed
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
