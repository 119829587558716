import type { AvailableResult } from './../available-result';
import type { TimeSeriesComputation } from './time-series-computation';
import type { UnitRootTestADF } from './unit-root-test-adf';
import type { UnitRootTestKPSS } from './unit-root-test-kpss';
import type { UnitRootTestZA } from './unit-root-test-za';
import type { _AvailableResult } from './../available-result';
import type { _TimeSeriesComputation } from './time-series-computation';
import type { _UnitRootTestADF } from './unit-root-test-adf';
import type { _UnitRootTestKPSS } from './unit-root-test-kpss';
import type { _UnitRootTestZA } from './unit-root-test-za';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTest
 * Via: UIModel annotation in the class hierarchy
 */
export interface _UnitRootTest extends _TimeSeriesComputation {
}

/** List of all concrete subclasses of com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTest */
export type UnitRootTest =
    | UnitRootTestADF
    | UnitRootTestKPSS
    | UnitRootTestZA;

export namespace UnitRootTest {
    /** Types of class com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTest in PolyJSON mappings */
    export const type = ["unit_root_adf", "unit_root_kpss", "unit_root_za", ] as const;
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTest$UnitRootTestResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _UnitRootTestResult extends _AvailableResult {
        criticalValues: { [key in string]: number };
        nObservations: number;
        pValue: number;
        statistic: number;
        usedLag: number;
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTest$UnitRootTestResult */
    export type UnitRootTestResult =
        | UnitRootTestADF.UnitRootTestADFResult
        | UnitRootTestKPSS.UnitRootTestKPSSResult
        | UnitRootTestZA.UnitRootTestZAResult;

    export namespace UnitRootTestResult {
        /** Types of class com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTest$UnitRootTestResult in PolyJSON mappings */
        export const type = ["unit_root_adf", "unit_root_kpss", "unit_root_za", ] as const;
    }
}