import type { Grouping } from './grouping';
import type { GroupingResult } from './grouping-result';
import type { _Grouping } from './grouping';
import type { _GroupingResult } from './grouping-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.grouping.AnumGrouping
 * Via: UIModel annotation in the class hierarchy
 */
export interface _AnumGrouping extends _Grouping {
    type: "anum";
    column: string;
    groupOthers: boolean;
    maxValues?: number | null;
}

export type AnumGrouping = _AnumGrouping;

export namespace AnumGrouping {
    /** Type of class com.dataiku.dip.eda.compute.grouping.AnumGrouping in PolyJSON mappings */
    export const type = "anum";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.grouping.AnumGrouping$AnumGroupingResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AnumGroupingResult extends _GroupingResult {
        type: "anum";
        column: string;
        hasAllValues: boolean;
        hasOthers: boolean;
        values: string[];
    }
    
    export type AnumGroupingResult = _AnumGroupingResult;

    export namespace AnumGroupingResult {
        /** Type of class com.dataiku.dip.eda.compute.grouping.AnumGrouping$AnumGroupingResult in PolyJSON mappings */
        export const type = "anum";
    }
}