import type { ACF } from './acf';
import type { AvailableResult } from './../available-result';
import type { PACF } from './pacf';
import type { TimeSeriesComputation } from './time-series-computation';
import type { _ACF } from './acf';
import type { _AvailableResult } from './../available-result';
import type { _PACF } from './pacf';
import type { _TimeSeriesComputation } from './time-series-computation';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.CorrelationFunction
 * Via: UIModel annotation in the class hierarchy
 */
export interface _CorrelationFunction extends _TimeSeriesComputation {
}

/** List of all concrete subclasses of com.dataiku.dip.eda.compute.computations.timeseries.CorrelationFunction */
export type CorrelationFunction =
    | ACF
    | PACF;

export namespace CorrelationFunction {
    /** Types of class com.dataiku.dip.eda.compute.computations.timeseries.CorrelationFunction in PolyJSON mappings */
    export const type = ["acf", "pacf", ] as const;
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.CorrelationFunction$CorrelationFunctionResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _CorrelationFunctionResult extends _AvailableResult {
        coefficients: number[];
        confidenceIntervals: number[][];
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.eda.compute.computations.timeseries.CorrelationFunction$CorrelationFunctionResult */
    export type CorrelationFunctionResult =
        | ACF.ACFResult
        | PACF.PACFResult;

    export namespace CorrelationFunctionResult {
        /** Types of class com.dataiku.dip.eda.compute.computations.timeseries.CorrelationFunction$CorrelationFunctionResult in PolyJSON mappings */
        export const type = ["acf", "pacf", ] as const;
    }
}