import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual, isNil } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { FrontendReferenceLine, ReferenceLineAvailableAxisInfo } from '../interfaces';
import { ReferenceLineAxisOptionsType } from '../reference-line-axis-options-type.enum';
import { ReferenceLinesService } from '../reference-lines.service';
import { ReferenceLineOptionsService } from './reference-line-options.service';

@UntilDestroy()
@Component({
    selector: 'reference-line-form',
    templateUrl: './reference-line-form.component.html',
    styleUrls: ['../../forms.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferenceLineFormComponent {
    private $id: string;
    private _availableAxisOptions: Array<ReferenceLineAvailableAxisInfo> | null;

    public styleOptions;
    public labelPositionOptions;
    public multiplierOptions;
    public colors: Array<string>;

    public axisOptions$ = new BehaviorSubject<Array<{id: any, name: string}>>([]);
    public hasOnlyOneAxis$ = new BehaviorSubject<boolean>(false);
    public isAxisAvailable$ = new BehaviorSubject(true);
    public isAxisAvailableTooltip$ = new BehaviorSubject<string | null>(null);
    public isPercent$ = new BehaviorSubject(false);

    public referenceLineForm: UntypedFormGroup = new UntypedFormGroup({
        value: new UntypedFormControl(null),
        displayValue: new UntypedFormControl(null),
        lineType: new UntypedFormControl(null),
        lineColor: new UntypedFormControl('#000'),
        lineSize: new UntypedFormControl(1),
        axis: new UntypedFormControl(null),
        prefix: new UntypedFormControl(null),
        suffix: new UntypedFormControl(null),
        labelPosition: new UntypedFormControl(ChartDef.ReferenceLinePosition.INSIDE_END_TOP),
        multiplier: new UntypedFormControl(ChartDef.ReferenceLineMultiplier.Inherit),
        valueFormatting: new UntypedFormControl(null),
    });

    @Input()
    set referenceLine(value: FrontendReferenceLine) {
        this.$id = value.$id;
        if (!this.equals(value, this.referenceLineForm.getRawValue())) {
            this.referenceLineForm.patchValue(value);
        }
    }

    @Input()
    set axisOptionsType(value: ReferenceLineAxisOptionsType | null | undefined) {
        this.hasOnlyOneAxis$.next(!!value && value.startsWith('ONLY'));
        this.axisOptions$.next(this.referenceLineOptions.getAxisOptions(value || ReferenceLineAxisOptionsType.ALL));
    }

    @Output() referenceLineChange = new EventEmitter<FrontendReferenceLine>();

    constructor(private referenceLineOptions: ReferenceLineOptionsService, private referenceLineService: ReferenceLinesService) {
        this.styleOptions = referenceLineOptions.styleOptions;
        this.labelPositionOptions = referenceLineOptions.labelPositionOptions;
        this.colors = referenceLineOptions.colors;
        this.multiplierOptions = referenceLineOptions.multiplierOptions;

        this.referenceLineService.availableAxisOptions$
            .pipe(untilDestroyed(this))
            .subscribe(availableAxisOptions => {
                this._availableAxisOptions = availableAxisOptions;
                this.checkAxis(this.referenceLineForm.getRawValue().axis, availableAxisOptions);
            });

        this.referenceLineForm.valueChanges
            .subscribe((value: Partial<FrontendReferenceLine>) => {
                this.checkAxis(value.axis, this._availableAxisOptions);
                this.referenceLineChange.emit({ $id: this.$id, ...value });
            });
    }

    private equals(referenceLine: FrontendReferenceLine, referenceLineForm: Partial<FrontendReferenceLine>) {
        return referenceLine.value === referenceLineForm.value &&
        referenceLine.displayValue === referenceLineForm.displayValue &&
        referenceLine.lineType === referenceLineForm.lineType &&
        referenceLine.lineColor === referenceLineForm.lineColor &&
        referenceLine.lineSize === referenceLineForm.lineSize &&
        referenceLine.axis === referenceLineForm.axis &&
        referenceLine.prefix === referenceLineForm.prefix &&
        referenceLine.suffix === referenceLineForm.suffix &&
        referenceLine.labelPosition === referenceLineForm.labelPosition &&
        referenceLine.multiplier === referenceLineForm.multiplier &&
        isEqual(referenceLine.valueFormatting, referenceLineForm.valueFormatting);
    }

    private getUnavailableAxisMessage(currentAxis?: ChartDef.ReferenceLineAxis): string | null {
        switch (currentAxis) {
            case ChartDef.ReferenceLineAxis.LEFT_Y_AXIS:
                return 'There is no left axis. Select a displayed axis to visualise the line.';
            case ChartDef.ReferenceLineAxis.RIGHT_Y_AXIS:
                return 'There is no right axis. Select a displayed axis to visualise the line.';
        }

        return null;
    }

    private getNonNumericalAxisMessage(currentAxis?: ChartDef.ReferenceLineAxis): string {
        switch (currentAxis) {
            case ChartDef.ReferenceLineAxis.LEFT_Y_AXIS:
            case ChartDef.ReferenceLineAxis.RIGHT_Y_AXIS:
            case ChartDef.ReferenceLineAxis.X_AXIS:
                return 'Reference lines cannot be displayed on a non-numerical axis. Select a numerical axis to visualise the line.';
            default:
                return 'There is no axis at the moment. Please add a measure.';
        }
    }

    private checkAxis(currentAxis?: ChartDef.ReferenceLineAxis, availableAxisOptions: Array<ReferenceLineAvailableAxisInfo> | null = []) {
        if (currentAxis && availableAxisOptions?.length) {
            const availableAxisOption = availableAxisOptions.find(availableAxisOption => {
                return availableAxisOption.axis === currentAxis;
            });

            const isAxisUnavailable = isNil(availableAxisOption) || !availableAxisOption.isDisplayed;

            if (isAxisUnavailable) {
                this.isAxisAvailableTooltip$.next(this.getUnavailableAxisMessage(currentAxis));
            } else if (!availableAxisOption.isNumerical) {
                this.isAxisAvailableTooltip$.next(this.getNonNumericalAxisMessage(currentAxis));
            }

            this.isAxisAvailable$.next(!isAxisUnavailable && availableAxisOption.isNumerical);

            //  Checks if current axis is also displayed with percents
            this.isPercent$.next(!isAxisUnavailable && !!availableAxisOption?.isPercentScale);
        } else {
            this.isAxisAvailable$.next(true);
        }
    }
}
