import type { BaseLabelingAnswer } from './base-labeling-answer';
import type { VerifiedLabel } from './verified-label';
import type { _BaseLabelingAnswer } from './base-labeling-answer';
import type { _VerifiedLabel } from './verified-label';

/** 
 * Translated from class com.dataiku.dip.labeling.LabelingAnswer
 * Via: UIModel annotation in the class hierarchy
 */
export interface _LabelingAnswer extends _BaseLabelingAnswer {
    annotatorId: string;
    reviewTime: number;
    status: LabelingAnswer.AnswerStatus;
    verifiedLabel?: VerifiedLabel | null;
}

export type LabelingAnswer = _LabelingAnswer;

export namespace LabelingAnswer {
    /** 
     * Translated from enum com.dataiku.dip.labeling.LabelingAnswer$AnswerStatus
     * Via: com.dataiku.dip.labeling.LabelingAnswer
     */
    export enum AnswerStatus {
        PENDING_REVIEW = "PENDING_REVIEW",
        REVIEWED = "REVIEWED",
    }
}