import type { Distribution2D } from './distribution2-d';
import type { _Distribution2D } from './distribution2-d';

/** 
 * Translated from class com.dataiku.dip.eda.compute.distributions.KDE2D
 * Via: UIModel annotation in the class hierarchy
 */
export interface _KDE2D extends _Distribution2D {
    type: "kde_2d";
    x_relative_bandwidth: number;
    y_relative_bandwidth: number;
}

export type KDE2D = _KDE2D;

export namespace KDE2D {
    /** Type of class com.dataiku.dip.eda.compute.distributions.KDE2D in PolyJSON mappings */
    export const type = "kde_2d";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.distributions.KDE2D$FittedKDE2D
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FittedKDE2D extends Distribution2D._FittedDistribution2D {
        type: "kde_2d";
    }
    
    export type FittedKDE2D = _FittedKDE2D;

    export namespace FittedKDE2D {
        /** Type of class com.dataiku.dip.eda.compute.distributions.KDE2D$FittedKDE2D in PolyJSON mappings */
        export const type = "kde_2d";
    }
}