import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { assertNever } from "dku-frontend-core";
import { Card, UnitRootTestADF, UnitRootTestCard, UnitRootTestKPSS, UnitRootTestZA } from "src/generated-sources";

@Component({
    selector: 'unit-root-test-card-body',
    templateUrl: './unit-root-test-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/test-conclusion.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/card-layout.less',
        './unit-root-test-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitRootTestCardBodyComponent {
    @Input() params: UnitRootTestCard;
    @Input() results: UnitRootTestCard.UnitRootTestCardResult;

    get isRejected() {
        return this.results.pValue <= (1 - this.params.confidenceLevel);
    }

    get isADF(): boolean {
        return Card.isUnitRootTestADFCard(this.params);
    }

    get isZA(): boolean {
        return Card.isUnitRootTestZACard(this.params);
    }

    get isKPSS(): boolean {
        return Card.isUnitRootTestKPSSCard(this.params);
    }

    get regressionLabel(): string {
        const card = this.params;

        if (Card.isUnitRootTestADFCard(card)) {
            switch (card.regressionMode) {
                case UnitRootTestADF.RegressionMode.CONSTANT_ONLY:
                    return 'Constant only';
                case UnitRootTestADF.RegressionMode.CONSTANT_WITH_LINEAR_TREND:
                    return 'Constant, linear trend';
                case UnitRootTestADF.RegressionMode.CONSTANT_WITH_LINEAR_QUADRATIC_TREND:
                    return 'Constant, linear and quadratic trend';
                case UnitRootTestADF.RegressionMode.NO_CONSTANT_NO_TREND:
                    return 'No constant, no trend';
            }
        }

        if (Card.isUnitRootTestZACard(card)) {
            switch (card.regressionMode) {
                case UnitRootTestZA.RegressionMode.CONSTANT_ONLY:
                    return 'Constant only';
                case UnitRootTestZA.RegressionMode.TREND_ONLY:
                    return 'Trend only';
                case UnitRootTestZA.RegressionMode.CONSTANT_WITH_TREND:
                    return 'Constant with trend';
            }
        }

        if (Card.isUnitRootTestKPSSCard(card)) {
            switch (card.regressionMode) {
                case UnitRootTestKPSS.RegressionMode.CONSTANT:
                    return "Constant only";
                case UnitRootTestKPSS.RegressionMode.CONSTANT_WITH_TREND:
                    return "Constant with trend";
            }
        }

        assertNever(card);
    }

    get stationarityLabel(): string {
        const card = this.params;

        if (Card.isUnitRootTestADFCard(card)) {
            switch (card.regressionMode) {
                case UnitRootTestADF.RegressionMode.NO_CONSTANT_NO_TREND:
                case UnitRootTestADF.RegressionMode.CONSTANT_ONLY:
                    return "stationary";

                case UnitRootTestADF.RegressionMode.CONSTANT_WITH_LINEAR_TREND:
                case UnitRootTestADF.RegressionMode.CONSTANT_WITH_LINEAR_QUADRATIC_TREND:
                    return "trend stationary";
            }
        }

        if (Card.isUnitRootTestZACard(card)) {
            switch (card.regressionMode) {
                case UnitRootTestZA.RegressionMode.CONSTANT_ONLY:
                    return "stationary";

                case UnitRootTestZA.RegressionMode.TREND_ONLY:
                case UnitRootTestZA.RegressionMode.CONSTANT_WITH_TREND:
                    return "trend stationary";
            }
        }

        if (Card.isUnitRootTestKPSSCard(card)) {
            switch (card.regressionMode) {
                case UnitRootTestKPSS.RegressionMode.CONSTANT:
                    return "stationary";
                case UnitRootTestKPSS.RegressionMode.CONSTANT_WITH_TREND:
                    return "trend stationary";
            }
        }

        assertNever(card);
    }

    get usedLagLabel(): string {
        if (this.params.nLags == null) {
            return `${this.results.usedLag} (automatically computed)`;
        }

        return `${this.results.usedLag} (manually set)`;
    }
}
