import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep, isNil, uniqueId } from 'lodash';
import { BehaviorSubject, combineLatest, debounce, distinctUntilChanged, map, pairwise, timer } from 'rxjs';
import { FrontendReferenceLine } from './interfaces';
import { ReferenceLineAxisOptionsType } from './reference-line-axis-options-type.enum';

@UntilDestroy()
@Component({
    selector: 'reference-lines-form',
    templateUrl: './reference-lines-form.component.html',
    styleUrls: ['./reference-lines-form.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferenceLinesFormComponent implements OnInit, AfterViewInit {
    @Output() referenceLinesChange = new EventEmitter<Array<FrontendReferenceLine>>();

    public hasReferenceLines$ = new BehaviorSubject<boolean>(false);
    public referenceLines$ = new BehaviorSubject<Array<FrontendReferenceLine>>([]);

    public axisOptionsType$ = new BehaviorSubject<ReferenceLineAxisOptionsType | undefined>(ReferenceLineAxisOptionsType.ALL);

    private _referenceLines$ = new BehaviorSubject<Array<FrontendReferenceLine>>([]);
    private debouncedReferenceLinesChange = new BehaviorSubject<Array<FrontendReferenceLine>>([]);

    get referenceLines(): Array<FrontendReferenceLine> {
        return this.referenceLines$.getValue();
    }

    @Input()
    set referenceLines(values: Array<Partial<FrontendReferenceLine>>) {
        const frontendReferenceLines: Array<FrontendReferenceLine> = (values || []).map(value => ({ $id: value.$id || uniqueId(), ...value }));
        this._referenceLines$.next(frontendReferenceLines);
    }

    @Input()
    set axisOptionsType(value: ReferenceLineAxisOptionsType | undefined) {
        this.axisOptionsType$.next(value);
    }

    ngOnInit() {
        combineLatest([this._referenceLines$, this.axisOptionsType$])
            .pipe(untilDestroyed(this))
            .subscribe(([referenceLines, axisOptionsType]) => {
                const filteredReferenceLines = referenceLines.filter(referenceLine => {
                    switch(axisOptionsType) {
                        case ReferenceLineAxisOptionsType.ONLY_X:
                            return referenceLine.axis === ChartDef.ReferenceLineAxis.X_AXIS;
                        case ReferenceLineAxisOptionsType.ONLY_LEFT_Y:
                            return referenceLine.axis === ChartDef.ReferenceLineAxis.LEFT_Y_AXIS;
                        case ReferenceLineAxisOptionsType.X_AND_LEFT_Y:
                            return referenceLine.axis === ChartDef.ReferenceLineAxis.X_AXIS || referenceLine.axis === ChartDef.ReferenceLineAxis.LEFT_Y_AXIS;
                        case ReferenceLineAxisOptionsType.ALL_Y:
                            return referenceLine.axis !== ChartDef.ReferenceLineAxis.X_AXIS;
                        case ReferenceLineAxisOptionsType.ALL:
                        default:
                            return true;
                    }
                });

                this.referenceLines$.next(filteredReferenceLines);
                this.hasReferenceLines$.next(!!filteredReferenceLines.length);
            });
    }

    ngAfterViewInit(): void {
        this.debouncedReferenceLinesChange.pipe(
            untilDestroyed(this),
            map(referenceLines => cloneDeep(referenceLines)),
            distinctUntilChanged(),
            pairwise(),
            debounce(([prevReferenceLines, nextReferenceLines]: [Array<FrontendReferenceLine>, Array<FrontendReferenceLine>]) => {
                //  Convert array to map for reference lines
                const referenceLinesToMapReducer = (acc: Record<string, FrontendReferenceLine>, referenceLine: FrontendReferenceLine) => {
                    acc[referenceLine.$id] = referenceLine;
                    return acc;
                };

                const prevReferenceLinesMap = prevReferenceLines.reduce(referenceLinesToMapReducer, {});
                /*
                 *  If the diff concerns a text or number input, we need to delay it.
                 *  Else, we only apply a quick debounce to avoid issues with the angularjs digest cycle.
                 *  This method is used in place of the "frontImportantDelayedProperties" mechanism
                 *  located in the "chart-definition-change-handler.service.js" file.
                 */
                const delayChanges = nextReferenceLines.some((nextReferenceLine) => {
                    const prevReferenceLine = prevReferenceLinesMap[nextReferenceLine.$id];
                    if (!isNil(prevReferenceLine)) {
                        return nextReferenceLine.value !== prevReferenceLine.value || nextReferenceLine.prefix !== prevReferenceLine.prefix || nextReferenceLine.suffix !== prevReferenceLine.suffix;
                    }
                    return false;
                });

                return timer(delayChanges ? 600 : 10);
            })
        ).subscribe(([_, nextReferenceLines]) => {
            this.referenceLinesChange.emit(nextReferenceLines);
        });
    }

    trackByFn(_: number, value: FrontendReferenceLine): string {
        return value.$id;
    }

    onReferenceLineChanged(referenceLine: FrontendReferenceLine, index: number) {
        this.referenceLines[index] = referenceLine;
        this.debouncedReferenceLinesChange.next(this.referenceLines);
    }

    addReferenceLine() {
        const axisOptionsType = this.axisOptionsType$.getValue();

        const defaultReferenceLine: FrontendReferenceLine = {
            $id: uniqueId(),
            lineColor: '#000',
            lineType: ChartDef.ChartLineType.DASHED,
            axis: axisOptionsType === ReferenceLineAxisOptionsType.ONLY_X ? ChartDef.ReferenceLineAxis.X_AXIS : ChartDef.ReferenceLineAxis.LEFT_Y_AXIS
        };

        this.referenceLines.push(defaultReferenceLine);
        this.debouncedReferenceLinesChange.next(this.referenceLines);
    }

    deleteReferenceLine(index: number) {
        this.referenceLines.splice(index, 1);
        this.debouncedReferenceLinesChange.next(this.referenceLines);
    }
}
