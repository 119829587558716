/** 
 * Translated from class com.dataiku.dip.dataflow.exec.computedcolumn.ComputedColumn
 * Via: com.dataiku.dip.dataflow.exec.grouping.GroupingRecipePayloadParams
 */
export interface _ComputedColumn {
    expr: string;
    mode: ComputedColumn.Mode;
    name: string;
    type: string;
}

export type ComputedColumn = _ComputedColumn;

export namespace ComputedColumn {
    /** 
     * Translated from enum com.dataiku.dip.dataflow.exec.computedcolumn.ComputedColumn$Mode
     * Via: com.dataiku.dip.dataflow.exec.computedcolumn.ComputedColumn
     */
    export enum Mode {
        GREL = "GREL",
        SQL = "SQL",
    }
}