import { Input, Output, EventEmitter, Directive, inject, DestroyRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { FormArrayRepeat } from '@utils/form-array-repeat';
import { markAllAsDirty } from '@utils/form-controls';
import { AbstractTestStat } from 'generated-sources';
import { observeInput } from "dku-frontend-core";
import { Observable, debounceTime } from "rxjs";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


@Directive({})
export abstract class StatsRecipeTestSettingsEditorComponent<TestStat extends AbstractTestStat, TestControl extends AbstractControl> {
    @Input() params: TestStat[];

    @Output() paramsChange = new EventEmitter<TestStat[]>();
    @Output() validityChange = new EventEmitter<boolean>();

    destroyRef = inject(DestroyRef);

    configForm: FormGroup;
    statsForm: FormArrayRepeat<TestControl>;

    private params$: Observable<TestStat[]> = observeInput(this, "params");

    constructor(
        public formBuilder: FormBuilder,
    ) {
        this.configForm = this.formBuilder.group({ statsForm: this.statsForm });

        this.statsForm = new FormArrayRepeat(() => this.controlFactory());

        this.statsForm.valueChanges.pipe(
            // multiple events can be fired rapidly (when patching, enabling controls...)
            // -> we only care about the last one
            debounceTime(100),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe((statsFormValue: TestStat[]) => this.handleFormChanges(statsFormValue));

        this.params$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((params: TestStat[]) => this.patchConfigForm(params));
    }

    addTest() {
        this.statsForm.add();

        // initialize the new control with the values from the previous one
        if (this.statsForm.length > 1) {
            const newControl = this.statsForm.controls[this.statsForm.controls.length - 1];
            const previousControl = this.statsForm.controls[this.statsForm.controls.length - 2];

            newControl.patchValue(previousControl.value);
        }

        markAllAsDirty(this.statsForm.controls[this.statsForm.controls.length - 1]);
    }

    deleteTest(index: number) {
        this.statsForm.removeAt(index);
    }

    private handleFormChanges(statsFormValue: TestStat[]) {
        this.paramsChange.emit(statsFormValue);
        this.validityChange.emit(this.statsForm.valid);
    }

    private patchConfigForm(params: TestStat[]) {
        this.statsForm.patchValue(params);
        markAllAsDirty(this.statsForm);
    }

    abstract controlFactory(): TestControl;
}
