import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Dashboard } from '@model-main/dashboards/model/dashboard';
import { DashboardToCreate } from '../../../features/dashboards/models/dashboard-to-create.model';

@Injectable({providedIn: 'root'})
export class PrivateDashboardsAPI {
    constructor(private dkuHttp: DkuHttpService) {
    }

    save(dashboardData: DashboardToCreate | Dashboard, commitMessage?: string): Observable<Dashboard> {
        return this.dkuHttp.request<Dashboard>('POST', '/dashboards/save', { dashboardData, commitMessage }, false);
    }

    get(projectKey: string, dashboardId: string): Observable<Dashboard> {
        return this.dkuHttp.request<Dashboard>('GET', '/dashboards/get', { projectKey, dashboardId }, false);
    }

    getInsightWithPayload(projectKey: string, insightId: string): Observable<any> {
        return this.dkuHttp.request<any>('GET', '/dashboards/insights/get-with-payload', { projectKey, insightId }, false);
    }
}
