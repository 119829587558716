import { Injectable } from '@angular/core';
import { ChartZoomControlInstance } from './chart-zoom-control-instance.model';

class LayoutRectangle {
    x: number;
    y: number;
    width: number;
    height: number;
}

@Injectable({
    providedIn: 'root'
})
export class EChartZoomControlInstance extends ChartZoomControlInstance {
    private ZOOM_IN_SCALE = 1.1;
    private ZOOM_OUT_SCALE = 1 / 1.1;
    private echartInstance: any;

    /**
     * Source: https://github.com/ecomfe/zrender/ (including documentation)
     */
    private matrix = {

        /**
         * Create an identity matrix
         */
        create(): Float32Array | number[] {
            return [1, 0, 0, 1, 0, 0];
        },

        /**
         * Translate the matrix input along the vector v and assign the result to output.
         * @param output    Output matrix, zrender.matrix.createcreated
         * @param input     The matrix to be modified will not be changed in the modification method.
         * @param v         A translation vector of length representing the horizontal and vertical offsets
         * @returns         Modified output
         */
        translate(output: Float32Array | number[], input: Float32Array | number[], v: Float32Array | number[]): Float32Array | number[] {
            output[0] = input[0];
            output[1] = input[1];
            output[2] = input[2];
            output[3] = input[3];
            output[4] = input[4] + v[0];
            output[5] = input[5] + v[1];
            return output;
        },

        /**
         * Scale the matrix input along the vector v and assign the result to output.
         * @param output    Output matrix, zrender.matrix.createcreated
         * @param input     The matrix to be modified will not be changed in the modification method.
         * @param v         A scaling vector of length representing the amount of horizontal and vertical scaling.
         * @returns         Modified output
         */
        scale(output: Float32Array | number[], input: Float32Array | number[], v: Float32Array | number[]): Float32Array | number[] {
            const vx = v[0];
            const vy = v[1];
            output[0] = input[0] * vx;
            output[1] = input[1] * vy;
            output[2] = input[2] * vx;
            output[3] = input[3] * vy;
            output[4] = input[4] * vx;
            output[5] = input[5] * vy;
            return output;
        }
    };

    constructor() {
        super();
        this.canZoomIn$.next(true);
        this.canZoomOut$.next(true);
        this.canResetZoom$.next(true);
    }

    init(echartInstance: any) {
        this.echartInstance = echartInstance;
    }

    zoomIn() {
        this.zoom(this.ZOOM_IN_SCALE);
    }

    zoomOut() {
        this.zoom(this.ZOOM_OUT_SCALE);
    }

    resetZoom = () => {
        this.echartInstance.resize({ silent: true });
    };

    /**
     * Source: https://github.com/ecomfe/zrender/ (including documentation)
     * Matrix m Left- multiply a vector v and assign it to a vector out.
     * @param output        output vector
     * @param v             vector
     * @param matrix        matrix
     * @returns             Output vector
     */
    applyTransform(output: LayoutRectangle, v: LayoutRectangle, matrix: Float32Array | number[]): void {
        if (matrix[1] < 1e-5 && matrix[1] > -1e-5 && matrix[2] < 1e-5 && matrix[2] > -1e-5) {
            const scaleX = matrix[0];
            const scaleY = matrix[3];
            const transformX = matrix[4];
            const transformY = matrix[5];
            output.x = v.x * scaleX + transformX;
            output.y = v.y * scaleY + transformY;
            output.width = v.width * scaleX;
            output.height = v.height * scaleY;
            if (output.width < 0) {
                output.x += output.width;
                output.width = -output.width;
            }
            if (output.height < 0) {
                output.y += output.height;
                output.height = -output.height;
            }
        }
    }

    /**
     * Zooms in or out (depending on the scale) the root rectangle from the echart instance
     * This function is adapted from _onZoom function of echarts' TreemapView.ts (https://github.com/apache/echarts/blob/master/src/chart/treemap/TreemapView.ts)
     * @param scale a number defining whether zoom in or out is performed
     */
    private zoom(scale: number) {
        if (this.echartInstance) {
            const treemapView = this.echartInstance._chartsViews[0];
            if (treemapView && treemapView._state !== 'animating') {

                const root = treemapView.seriesModel.getData().tree.root;
                if (!root) {
                    return;
                }

                const rootLayout = root.getLayout();
                if (!rootLayout) {
                    return;
                }

                const rect = {
                    x: rootLayout.x,
                    y: rootLayout.y,
                    width: rootLayout.width,
                    height: rootLayout.height
                };

                const layoutInfo = treemapView.seriesModel.layoutInfo;
                let centerX = rect.x + (rect.width/2);
                let centerY = rect.y + (rect.height/2);

                centerX -= layoutInfo.x;
                centerY -= layoutInfo.y;

                const m = this.matrix.create();
                this.matrix.translate(m, m, [-centerX, -centerY]);
                this.matrix.scale(m, m, [scale, scale]);
                this.matrix.translate(m, m, [centerX, centerY]);
                this.applyTransform(rect, rect, m);
                treemapView.api.dispatchAction({
                    type: 'treemapRender',
                    from: treemapView.uid,
                    seriesId: treemapView.seriesModel.id,
                    rootRect: {
                        x: rect.x,
                        y: rect.y,
                        width: rect.width,
                        height: rect.height
                    }
                });
            }
        }
    }
}
