import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { ChartVariant } from '@model-main/pivot/frontend/model/chart-variant';
import { DisplayAxis } from '@model-main/pivot/frontend/model/display-axis';
import { DisplayType } from '@model-main/pivot/frontend/model/display-type';
import { LabelPosition } from '@model-main/pivot/frontend/model/label-position';
import { Multiplier } from '@model-main/pivot/frontend/model/multiplier';
import { Aggregation } from '@model-main/pivot/backend/model/aggregation';
import { FrontendChartDef, FrontendDimensionDef, FrontendMeasureDef } from '../../interfaces';
import { DigitGrouping } from '@model-main/pivot/frontend/model/digit-grouping';

const DEFAULT_FONT_FORMATTING = {
    fontSize: 12,
    fontColor: '#333'
};

export const partialChartDef: Partial<FrontendChartDef> = {
    variant: ChartVariant.normal,
    name: 'Avg of total by gender',
    userEditedName: false,
    displayWithECharts: true,
    genericDimension0: [
      {
          multiplier: 'Auto',
          decimalPlaces: null,
          dateParams: undefined as any,
          showDimensionValuesInChart: undefined as any,
          prefix: '',
          suffix: '',
          digitGrouping: DigitGrouping.DEFAULT,
          column: 'gender',
          type: 'ALPHANUM',
          numParams: {
              emptyBinsMode: 'ZEROS'
          },
          maxValues: 20,
          generateOthersCategory: true,
          forceLastPositionOthers: true,
          oneTickPerBin: false,
          filters: [],
          isA: 'dimension',
          possibleSorts: [
              {
                  type: 'NATURAL',
                  label: 'Natural ordering',
                  sortAscending: true
              },
              {
                  type: 'AGGREGATION',
                  measureIdx: 0,
                  label: 'Average of total, descending'
              },
              {
                  type: 'AGGREGATION',
                  measureIdx: 0,
                  label: 'Average of total, ascending',
                  sortAscending: true
              }
          ],
          sort: {
              type: 'AGGREGATION',
              measureIdx: 0,
              label: 'Average of total, descending'
          },
          chartDefAttributeName: 'genericDimension0'
      } as FrontendDimensionDef
    ],
    genericDimension1: [],
    facetDimension: [],
    animationDimension: [],
    genericMeasures: [
      {
          multiplier: Multiplier.Auto,
          decimalPlaces: null,
          prefix: '',
          suffix: '',
          digitGrouping: DigitGrouping.DEFAULT,
          column: 'total',
          function: Aggregation.Function.AVG,
          type: 'NUMERICAL',
          displayed: true,
          isA: 'measure',
          displayAxis: DisplayAxis.axis1,
          displayType: DisplayType.column,
          computeMode: Aggregation.ComputeMode.NORMAL,
          computeModeDim: 0,
          labelPosition: LabelPosition.BOTTOM,
          chartDefAttributeName: 'genericMeasures'
      } as FrontendMeasureDef
    ],
    xDimension: [],
    yDimension: [],
    uaXDimension: [],
    uaYDimension: [],
    uaSize: [],
    uaShape: [],
    uaColor: [],
    uaTooltip: [],
    groupDimension: [],
    xMeasure: [],
    yMeasure: [],
    colorMeasure: [],
    sizeMeasure: [],
    geometry: [],
    geoLayers: [
        {
            geometry: [],
            colorOptions: {
                singleColor: '#2678B1',
                transparency: 0.75,
                colorPalette: 'default',
                ccScaleMode: ChartDef.ColorOptions.ContinuousColorScaleMode.NORMAL,
                customPalette: {
                    id: '__dku_custom__',
                    name: 'Custom Palette',
                    colors: [],
                    values: [],
                    fixedValues: false
                },
                paletteType: ChartDef.ColorOptions.PaletteType.CONTINUOUS,
                quantizationMode: ChartDef.ColorOptions.QuantizationMode.NONE,
                numQuantizeSteps: 5,
                paletteMiddleValue: 0,
                customColors: {},
                heatDensityMapIntensity: 0.5,
                heatDensityMapRadius: 0.5
            },
            uaColor: []
        }
    ],
    tooltipMeasures: [],
    boxplotBreakdownDim: [],
    boxplotValue: [],
    filters: [],
    axis1LogScale: false,
    axis2LogScale: false,
    includeZero: true,
    smoothing: true,
    showLegend: true,
    showXAxis: false,
    showInChartValues: false,
    showInChartLabels: true,
    strokeWidth: 1,
    fillOpacity: 0.6,
    xAxisFormatting: {
        displayAxis: true,
        showAxisTitle: true,
        axisValuesFormatting: {
            numberFormatting: {
                multiplier: Multiplier.Auto,
                prefix: '',
                suffix: '',
                digitGrouping: DigitGrouping.DEFAULT
            },
            axisTicksFormatting: {
                fontSize: 12,
                fontColor: '#333',
                hasBackground: false
            }
        }
    },
    yAxisFormatting: {
        displayAxis: true,
        showAxisTitle: true,
        axisValuesFormatting: {
            numberFormatting: {
                multiplier: Multiplier.Auto,
                prefix: '',
                suffix: '',
                digitGrouping: DigitGrouping.DEFAULT
            },
            axisTicksFormatting: {
                fontSize: 12,
                fontColor: '#333',
                hasBackground: false
            }
        }
    },
    hexbinRadius: 20,
    hexbinNumber: 20,
    hexbinRadiusMode: ChartDef.HexbinRadiusMode.NUM_HEXAGONS,
    chartHeight: 200,
    singleXAxis: true,
    multiTooltips: false,
    animationFrameDuration: 3000,
    animationRepeat: true,
    colorOptions: {
        singleColor: '#2678B1',
        transparency: 0.75,
        colorPalette: 'default',
        ccScaleMode: ChartDef.ColorOptions.ContinuousColorScaleMode.NORMAL,
        customPalette: {
            id: '__dku_custom__',
            name: 'Custom Palette',
            colors: [],
            values: [],
            fixedValues: false
        },
        paletteType: ChartDef.ColorOptions.PaletteType.CONTINUOUS,
        quantizationMode: ChartDef.ColorOptions.QuantizationMode.NONE,
        numQuantizeSteps: 5,
        paletteMiddleValue: 0,
        customColors: {},
        heatDensityMapIntensity: 0.5,
        heatDensityMapRadius: 0.5
    },
    bubblesOptions: {
        defaultRadius: 5,
        singleShape: ChartDef.BubbleShape.FILLED_CIRCLE
    },
    computeMode: ChartDef.ComputeMode.NORMAL,
    xAxisMode: ChartDef.XAxisMode.NORMAL,
    yAxisMode: ChartDef.YAxisMode.NORMAL,
    pieOptions: {
        donutHoleSize: 54
    },
    scatterOptions: {
        equalScales: false,
        identityLine: false,
        regression: {
            type: ChartDef.RegressionType.LINEAR,
            show: false,
            displayFormula: true,
            lineSize: 1,
            lineColor: '#fff',
            labelPosition: ChartDef.RegressionLinePosition.INSIDE_END,
            textFormatting: {
                fontSize: 11,
                fontColor: '#333',
                hasBackground: false
            }
        },
        numberOfRecords: 100000
    },
    zoomOptions: {
        scale: 1,
        translate: [0, 0],
        xRange: [0, 1200],
        yRange: [1200, 0],
        enabled: true,
        persisted: true
    },
    mapOptions: {
        tilesLayer: 'cartodb-positron',
        lockSquareGrid: false
    },
    mapGridOptions: {
        gridLonDeg: 0.6,
        gridLatDeg: 0.6
    },
    radarOptions: {
        filled: false,
        polygonsSource: ChartDef.PolygonSources.MEASURES,
        lineStyle: {
            width: 2,
            type: ChartDef.LineStyleTypes.SOLID
        }
    },
    useLiveProcessingIfAvailable: true,
    legendPlacement: ChartDef.LegendPlacement.OUTER_RIGHT,
    pivotTableOptions: {
        measureDisplayMode: ChartDef.PivotTableMeasureDisplayMode.ROWS,
        displayTotals: {
            subTotals: {
                rows: true,
                columns: true
            },
            grandTotal: {
                row: true,
                column: true
            }
        },
        tableFormatting: {
            rowHeaders: { ...DEFAULT_FONT_FORMATTING },
            columnHeaders: { ...DEFAULT_FONT_FORMATTING },
            values: { ...DEFAULT_FONT_FORMATTING }
        }
    },
    hasEchart: true,
    brush: true,
    colorPaletteType: 'LINEAR',
    $loadedDesc: null,
    $pluginDesc: null,
    $pluginChartDesc: null
};
