/** 
 * Translated from class com.dataiku.dip.security.auth.AuthSyncSettings
 * Via: UIModel annotation in the class hierarchy
 */
export interface _AuthSyncSettings {
    missingUserAction: AuthSyncSettings.MissingUserAction;
    notInAuthorizedGroupsAction: AuthSyncSettings.NotInAuthorizedGroupsAction;
    syncUserDisplayName: boolean;
    syncUserEmail: boolean;
    syncUserGroups: boolean;
    syncUserProfile: boolean;
}

export type AuthSyncSettings = _AuthSyncSettings;

export namespace AuthSyncSettings {
    /** 
     * Translated from enum com.dataiku.dip.security.auth.AuthSyncSettings$MissingUserAction
     * Via: com.dataiku.dip.security.auth.AuthSyncSettings
     */
    export enum MissingUserAction {
        WARN = "WARN",
        DISABLE_USER = "DISABLE_USER",
    }
    /** 
     * Translated from enum com.dataiku.dip.security.auth.AuthSyncSettings$NotInAuthorizedGroupsAction
     * Via: com.dataiku.dip.security.auth.AuthSyncSettings
     */
    export enum NotInAuthorizedGroupsAction {
        WARN = "WARN",
        DISABLE_USER = "DISABLE_USER",
    }
}