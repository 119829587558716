export namespace TaggableObjectChangedEvent {
    /** 
     * Translated from enum com.dataiku.dip.server.notifications.backend.TaggableObjectChangedEvent$ActionType
     * Via: com.dataiku.dip.timelines.TimelineItem
     */
    export enum ActionType {
        PROJECT_CREATE = "PROJECT_CREATE",
        PROJECT_EDIT = "PROJECT_EDIT",
        PROJECT_GIT_PULL = "PROJECT_GIT_PULL",
        PROJECT_GIT_CHECKOUT = "PROJECT_GIT_CHECKOUT",
        PROJECT_DELETE = "PROJECT_DELETE",
        PROJECT_DUPLICATE = "PROJECT_DUPLICATE",
        DATASET_CREATE = "DATASET_CREATE",
        DATASET_EDIT = "DATASET_EDIT",
        DATASET_DELETE = "DATASET_DELETE",
        DATASET_RENAME = "DATASET_RENAME",
        DATASET_UPDATE_METRICS = "DATASET_UPDATE_METRICS",
        DATASET_SHARED_TO_WORKSPACE = "DATASET_SHARED_TO_WORKSPACE",
        SAVED_MODEL_CREATE = "SAVED_MODEL_CREATE",
        SAVED_MODEL_EDIT = "SAVED_MODEL_EDIT",
        SAVED_MODEL_DELETE = "SAVED_MODEL_DELETE",
        SAVED_MODEL_RENAME = "SAVED_MODEL_RENAME",
        SAVED_MODEL_UPDATE_METRICS = "SAVED_MODEL_UPDATE_METRICS",
        SAVED_MODEL_CHANGE_ACTIVE_VERSION = "SAVED_MODEL_CHANGE_ACTIVE_VERSION",
        MODEL_EVALUATION_STORE_CREATE = "MODEL_EVALUATION_STORE_CREATE",
        MODEL_EVALUATION_STORE_EDIT = "MODEL_EVALUATION_STORE_EDIT",
        MODEL_EVALUATION_STORE_DELETE = "MODEL_EVALUATION_STORE_DELETE",
        MODEL_EVALUATION_STORE_RENAME = "MODEL_EVALUATION_STORE_RENAME",
        MODEL_COMPARISON_CREATE = "MODEL_COMPARISON_CREATE",
        MODEL_COMPARISON_EDIT = "MODEL_COMPARISON_EDIT",
        MODEL_COMPARISON_DELETE = "MODEL_COMPARISON_DELETE",
        MODEL_COMPARISON_RENAME = "MODEL_COMPARISON_RENAME",
        PROMPT_STUDIO_CREATE = "PROMPT_STUDIO_CREATE",
        PROMPT_STUDIO_EDIT = "PROMPT_STUDIO_EDIT",
        PROMPT_STUDIO_DELETE = "PROMPT_STUDIO_DELETE",
        PROMPT_STUDIO_RENAME = "PROMPT_STUDIO_RENAME",
        RETRIEVABLE_KNOWLEDGE_CREATE = "RETRIEVABLE_KNOWLEDGE_CREATE",
        RETRIEVABLE_KNOWLEDGE_EDIT = "RETRIEVABLE_KNOWLEDGE_EDIT",
        RETRIEVABLE_KNOWLEDGE_DELETE = "RETRIEVABLE_KNOWLEDGE_DELETE",
        RETRIEVABLE_KNOWLEDGE_RENAME = "RETRIEVABLE_KNOWLEDGE_RENAME",
        LABELING_TASK_CREATE = "LABELING_TASK_CREATE",
        LABELING_TASK_EDIT = "LABELING_TASK_EDIT",
        LABELING_TASK_DELETE = "LABELING_TASK_DELETE",
        LABELING_TASK_RENAME = "LABELING_TASK_RENAME",
        MANAGED_FOLDER_CREATE = "MANAGED_FOLDER_CREATE",
        MANAGED_FOLDER_EDIT = "MANAGED_FOLDER_EDIT",
        MANAGED_FOLDER_DELETE = "MANAGED_FOLDER_DELETE",
        MANAGED_FOLDER_RENAME = "MANAGED_FOLDER_RENAME",
        MANAGED_FOLDER_UPDATE_METRICS = "MANAGED_FOLDER_UPDATE_METRICS",
        STREAMING_ENDPOINT_CREATE = "STREAMING_ENDPOINT_CREATE",
        STREAMING_ENDPOINT_EDIT = "STREAMING_ENDPOINT_EDIT",
        STREAMING_ENDPOINT_DELETE = "STREAMING_ENDPOINT_DELETE",
        RECIPE_CREATE = "RECIPE_CREATE",
        RECIPE_EDIT = "RECIPE_EDIT",
        RECIPE_DELETE = "RECIPE_DELETE",
        RECIPE_RENAME = "RECIPE_RENAME",
        ANALYSIS_CREATE = "ANALYSIS_CREATE",
        ANALYSIS_EDIT = "ANALYSIS_EDIT",
        ANALYSIS_DELETE = "ANALYSIS_DELETE",
        ANALYSIS_RENAME = "ANALYSIS_RENAME",
        DASHBOARD_CREATE = "DASHBOARD_CREATE",
        DASHBOARD_DELETE = "DASHBOARD_DELETE",
        DASHBOARD_EDIT = "DASHBOARD_EDIT",
        DASHBOARD_RENAME = "DASHBOARD_RENAME",
        DASHBOARD_LISTED = "DASHBOARD_LISTED",
        DASHBOARD_UNLISTED = "DASHBOARD_UNLISTED",
        DASHBOARD_SHARED_TO_WORKSPACE = "DASHBOARD_SHARED_TO_WORKSPACE",
        WEB_APP_CREATE = "WEB_APP_CREATE",
        WEB_APP_DELETE = "WEB_APP_DELETE",
        WEB_APP_EDIT = "WEB_APP_EDIT",
        WEB_APP_RENAME = "WEB_APP_RENAME",
        WEB_APP_SHARED_TO_WORKSPACE = "WEB_APP_SHARED_TO_WORKSPACE",
        CODE_STUDIO_CREATE = "CODE_STUDIO_CREATE",
        CODE_STUDIO_DELETE = "CODE_STUDIO_DELETE",
        CODE_STUDIO_EDIT = "CODE_STUDIO_EDIT",
        CODE_STUDIO_RENAME = "CODE_STUDIO_RENAME",
        REPORT_CREATE = "REPORT_CREATE",
        REPORT_DELETE = "REPORT_DELETE",
        REPORT_EDIT = "REPORT_EDIT",
        REPORT_RENAME = "REPORT_RENAME",
        SQL_NOTEBOOK_CREATE = "SQL_NOTEBOOK_CREATE",
        SQL_NOTEBOOK_EDIT = "SQL_NOTEBOOK_EDIT",
        SQL_NOTEBOOK_DELETE = "SQL_NOTEBOOK_DELETE",
        SQL_NOTEBOOK_RENAME = "SQL_NOTEBOOK_RENAME",
        SEARCH_NOTEBOOK_CREATE = "SEARCH_NOTEBOOK_CREATE",
        SEARCH_NOTEBOOK_EDIT = "SEARCH_NOTEBOOK_EDIT",
        SEARCH_NOTEBOOK_DELETE = "SEARCH_NOTEBOOK_DELETE",
        SEARCH_NOTEBOOK_RENAME = "SEARCH_NOTEBOOK_RENAME",
        JUPYTER_NOTEBOOK_CREATE = "JUPYTER_NOTEBOOK_CREATE",
        JUPYTER_NOTEBOOK_EDIT = "JUPYTER_NOTEBOOK_EDIT",
        JUPYTER_NOTEBOOK_DELETE = "JUPYTER_NOTEBOOK_DELETE",
        JUPYTER_NOTEBOOK_RENAME = "JUPYTER_NOTEBOOK_RENAME",
        INSIGHT_CREATE = "INSIGHT_CREATE",
        INSIGHT_DELETE = "INSIGHT_DELETE",
        INSIGHT_EDIT = "INSIGHT_EDIT",
        INSIGHT_RENAME = "INSIGHT_RENAME",
        INSIGHT_LISTED = "INSIGHT_LISTED",
        INSIGHT_UNLISTED = "INSIGHT_UNLISTED",
        INSIGHT_SHARED_TO_WORKSPACE = "INSIGHT_SHARED_TO_WORKSPACE",
        LAMBDA_SERVICE_CREATE = "LAMBDA_SERVICE_CREATE",
        LAMBDA_SERVICE_EDIT = "LAMBDA_SERVICE_EDIT",
        LAMBDA_SERVICE_DELETE = "LAMBDA_SERVICE_DELETE",
        LAMBDA_SERVICE_RENAME = "LAMBDA_SERVICE_RENAME",
        LAMBDA_SERVICE_PACKAGE = "LAMBDA_SERVICE_PACKAGE",
        SCENARIO_CREATE = "SCENARIO_CREATE",
        SCENARIO_EDIT = "SCENARIO_EDIT",
        SCENARIO_DELETE = "SCENARIO_DELETE",
        SCENARIO_RENAME = "SCENARIO_RENAME",
        ARTICLE_CREATE = "ARTICLE_CREATE",
        ARTICLE_EDIT = "ARTICLE_EDIT",
        ARTICLE_DELETE = "ARTICLE_DELETE",
        ARTICLE_RENAME = "ARTICLE_RENAME",
        ARTICLE_SHARED_TO_WORKSPACE = "ARTICLE_SHARED_TO_WORKSPACE",
        STATISTICS_WORKSHEET_CREATE = "STATISTICS_WORKSHEET_CREATE",
        STATISTICS_WORKSHEET_EDIT = "STATISTICS_WORKSHEET_EDIT",
        STATISTICS_WORKSHEET_DELETE = "STATISTICS_WORKSHEET_DELETE",
        STATISTICS_WORKSHEET_RENAME = "STATISTICS_WORKSHEET_RENAME",
        FLOW_ZONE_CREATE = "FLOW_ZONE_CREATE",
        FLOW_ZONE_EDIT = "FLOW_ZONE_EDIT",
        FLOW_ZONE_DELETE = "FLOW_ZONE_DELETE",
        EDIT_COLLABORATIVE_METADATA = "EDIT_COLLABORATIVE_METADATA",
        DATA_COLLECTION_CREATE = "DATA_COLLECTION_CREATE",
        DATA_COLLECTION_EDIT = "DATA_COLLECTION_EDIT",
        DATA_COLLECTION_DELETE = "DATA_COLLECTION_DELETE",
        COMMENT = "COMMENT",
        UNKNOWN = "UNKNOWN",
    }
}