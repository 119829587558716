import { AxisDef } from "@model-main/pivot/backend/model/axis-def";
import { AxisSortPrune } from "@model-main/pivot/backend/model/axis-sort-prune";
import { NumericalAxisParams } from "@model-main/pivot/backend/model/numerical-axis-params";
import { PivotTableResponse } from "@model-main/pivot/backend/model/pivot-table-response";
import { PivotTableTensorResponse } from "@model-main/pivot/backend/model/pivot-table-tensor-response";
import { SamplingParam } from "@model-main/datasets/sampling-param";

export const pivotTableTensorResponseDNMN: PivotTableTensorResponse = {
    axisLabels: [
        [
            {
                sortValue: 17.25,
                min: 14,
                max: 20.5,
                label: "14-21"
            },
            {
                sortValue: 23.75,
                min: 20.5,
                max: 27,
                label: "21-27"
            },
            {
                sortValue: 30.25,
                min: 27,
                max: 33.5,
                label: "27-34"
            },
            {
                sortValue: 36.75,
                min: 33.5,
                max: 40,
                label: "34-40"
            },
            {
                sortValue: 43.25,
                min: 40,
                max: 46.5,
                label: "40-47"
            },
            {
                sortValue: 49.75,
                min: 46.5,
                max: 53,
                label: "47-53"
            },
            {
                sortValue: 56.25,
                min: 53,
                max: 59.5,
                label: "53-60"
            },
            {
                sortValue: 62.75,
                min: 59.5,
                max: 66,
                label: "60-66"
            },
            {
                sortValue: 69.25,
                min: 66,
                max: 72.5,
                label: "66-73"
            },
            {
                sortValue: 75.75,
                min: 72.5,
                max: 79,
                label: "73-79"
            }
        ]
    ] as Array<any>,
    axisDefs: [
        {
            column: "age",
            type: AxisDef.Type.NUMERICAL,
            sortPrune: {
                sortType: AxisSortPrune.SortType.NATURAL,
                sortAscending: true,
                aggregationSortId: -1,
                generateOthersCategory: false,
                filters: [],
                maxValues: 0
            },
            numParams: {
                mode: NumericalAxisParams.BinningMode.FIXED_NB,
                emptyBinsMode: NumericalAxisParams.EmptyBinsMode.ZEROS,
                nbBins: 10,
                binSize: 100,
                niceBounds: false
            }
        }
    ] as Array<any>,
    sequenceId: 0,
    aggregations: [
        {
            numAxes: 1,
            axisLengths: [
                10
            ],
            tensor: [
                21.77836879432624,
                23.83160621761658,
                32.1468200270636,
                41.650918635170605,
                46.688127090301,
                55.39253486464315,
                66.15446608462055,
                65.26706349206349,
                71.12185185185186,
                59.15819861431871
            ],
            axes: [
                [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            ],
            multipliers: [
                1
            ]
        }
    ] as Array<any>,
    counts: {
        numAxes: 1,
        axisLengths: [
            10
        ],
        tensor: [
            282,
            386,
            739,
            762,
            1196,
            1219,
            1489,
            1260,
            1350,
            433
        ],
        axes: [
            [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ]
        ],
        multipliers: [
            1
        ]
    },
    filterFacets: [],
    beforeFilterRecords: 10000,
    afterFilterRecords: 10000,
    engine: PivotTableResponse.PivotEngine.LINO,
    sampleMetadata: {
        samplingMethod: SamplingParam.SamplingMethod.HEAD_SEQUENTIAL,
        maxRecords: 10000,
        targetRatio: 0.02,
        hasFilter: false,
        partitionCount: 0,
        sampleIsWholeDataset: false,
        sampleRecordCount: -1,
        datasetRecordCount: -1,
        recordCountIsApproximate: false,
        recordCountIsObsolete: false,
        memoryLimitInMB: 150,
        memoryLimitReached: false
    },
    columnsSummary: undefined as any
};
