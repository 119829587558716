import type { Abstract2SampTestCard } from './common/abstract2-samp-test-card';
import type { _Abstract2SampTestCard } from './common/abstract2-samp-test-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.TTest2SampCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _TTest2SampCard extends _Abstract2SampTestCard {
    type: "ttest_2samp";
}

export type TTest2SampCard = _TTest2SampCard;

export namespace TTest2SampCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.TTest2SampCard in PolyJSON mappings */
    export const type = "ttest_2samp";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.TTest2SampCard$TTest2SampCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _TTest2SampCardResult extends Abstract2SampTestCard._Abstract2SampTestCardResult {
        type: "ttest_2samp";
        pvalue: number;
        statistic: number;
    }
    
    export type TTest2SampCardResult = _TTest2SampCardResult;

    export namespace TTest2SampCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.TTest2SampCard$TTest2SampCardResult in PolyJSON mappings */
        export const type = "ttest_2samp";
    }
}