import type { AzureMLProxyConfiguration } from './azure-mlproxy-configuration';
import type { DSSProxyModelConfiguration } from './dssproxy-model-configuration';
import type { KServeProxyModelConfiguration } from './kserve-proxy-model-configuration';
import type { SagemakerProxyConfiguration } from './sagemaker-proxy-configuration';
import type { VertexAIProxyConfiguration } from './vertex-aiproxy-configuration';
import type { _AzureMLProxyConfiguration } from './azure-mlproxy-configuration';
import type { _DSSProxyModelConfiguration } from './dssproxy-model-configuration';
import type { _KServeProxyModelConfiguration } from './kserve-proxy-model-configuration';
import type { _SagemakerProxyConfiguration } from './sagemaker-proxy-configuration';
import type { _VertexAIProxyConfiguration } from './vertex-aiproxy-configuration';

/** 
 * Translated from class com.dataiku.dip.savedmodels.proxymodels.ProxyModelConfiguration
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ProxyModelConfiguration {
    connection?: string | null;
    region?: string | null;
}

/** List of all concrete subclasses of com.dataiku.dip.savedmodels.proxymodels.ProxyModelConfiguration */
export type ProxyModelConfiguration =
    | AzureMLProxyConfiguration
    | DSSProxyModelConfiguration
    | KServeProxyModelConfiguration
    | SagemakerProxyConfiguration
    | VertexAIProxyConfiguration;

export namespace ProxyModelConfiguration {
    /** Types of class com.dataiku.dip.savedmodels.proxymodels.ProxyModelConfiguration in PolyJSON mappings */
    export const protocol = ["azure-ml", "dss-api-node", "kserve", "sagemaker", "vertex-ai", ] as const;
    
    export function isAzureMLProxyConfiguration(obj: ProxyModelConfiguration): obj is AzureMLProxyConfiguration { return obj["protocol"] == "azure-ml"; }
    export function isDSSProxyModelConfiguration(obj: ProxyModelConfiguration): obj is DSSProxyModelConfiguration { return obj["protocol"] == "dss-api-node"; }
    export function isKServeProxyModelConfiguration(obj: ProxyModelConfiguration): obj is KServeProxyModelConfiguration { return obj["protocol"] == "kserve"; }
    export function isProxyModelConfiguration(obj: ProxyModelConfiguration): obj is ProxyModelConfiguration { return ["azure-ml", "dss-api-node", "kserve", "sagemaker", "vertex-ai", ].includes(obj["protocol"]); }
    export function isSagemakerProxyConfiguration(obj: ProxyModelConfiguration): obj is SagemakerProxyConfiguration { return obj["protocol"] == "sagemaker"; }
    export function isVertexAIProxyConfiguration(obj: ProxyModelConfiguration): obj is VertexAIProxyConfiguration { return obj["protocol"] == "vertex-ai"; }
}