import type { AbstractSQLConnection } from './../connections/abstract-sqlconnection';
import type { ChecksSet } from './../metrics/checks-set';
import type { DatasetOrdering } from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/input/dataset-ordering';
import type { FormatParams } from './format-params';
import type { ImageViewSettings } from './../labeling/image-view-settings';
import type { ObjectCustomMetadata } from './object-custom-metadata';
import type { OverrideTable } from './../variables/override-table';
import type { PartitioningScheme } from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/partitioning/partitioning-scheme';
import type { ProbesSet } from './../metrics/probes-set';
import type { Schema } from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/coremodel/schema';
import type { TaggableObjectsService } from './../server/services/taggable-objects-service';
import type { _ChecksSet } from './../metrics/checks-set';
import type { _DatasetOrdering } from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/input/dataset-ordering';
import type { _FormatParams } from './format-params';
import type { _ImageViewSettings } from './../labeling/image-view-settings';
import type { _ObjectCustomMetadata } from './object-custom-metadata';
import type { _OverrideTable } from './../variables/override-table';
import type { _PartitioningScheme } from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/partitioning/partitioning-scheme';
import type { _ProbesSet } from './../metrics/probes-set';
import type { _Schema } from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/coremodel/schema';

/** 
 * Translated from class com.dataiku.dip.coremodel.SerializedDataset
 * Via: com.dataiku.dip.server.UsabilityComputer$UsableComputable
 */
export interface _SerializedDataset extends TaggableObjectsService._TaggableObject {
    autodetectedFormatId: string;
    checks: SerializedDataset.SerializedCheck[];
    customMeta: ObjectCustomMetadata;
    dataSteward: string;
    dkuProperties: AbstractSQLConnection.CustomDatabaseProperty[];
    featureGroup: boolean;
    flowOptions: SerializedDataset.FlowOptions;
    formatParams: FormatParams;
    formatType: string;
    imageViewSettings: ImageViewSettings;
    managed: boolean;
    metrics: ProbesSet;
    metricsChecks: ChecksSet;
    metricsChecksRaw: any/* com.google.gson.JsonElement */;
    metricsRaw: any/* com.google.gson.JsonElement */;
    name: string;
    overrideTable: OverrideTable;
    params: any/* com.dataiku.dip.datasets.DatasetHandler$DatasetParams (overriden) */;
    partitioning: PartitioningScheme;
    projectKey: string;
    readWriteOptions: SerializedDataset.ReadWriteOptions;
    remoteFiles: SerializedDataset.RemoteFiles;
    schema: Schema;
    smartName: string;
    type: string;
}

export type SerializedDataset = _SerializedDataset;

export namespace SerializedDataset {
    /** 
     * Translated from enum com.dataiku.dip.coremodel.SerializedDataset$CrossProjectBuildBehaviour
     * Via: com.dataiku.dip.coremodel.SerializedDataset$FlowOptions
     */
    export enum CrossProjectBuildBehaviour {
        DEFAULT = "DEFAULT",
        STOP = "STOP",
        TRAVERSE = "TRAVERSE",
    }
    /** 
     * Translated from class com.dataiku.dip.coremodel.SerializedDataset$FlowOptions
     * Via: com.dataiku.dip.dao.SavedModel
     */
    export interface _FlowOptions {
        crossProjectBuildBehavior: SerializedDataset.CrossProjectBuildBehaviour;
        dateBlacklist: string;
        flowEpoch: string;
        rebuildBehavior: SerializedDataset.RebuildBehavior;
        virtualizable: boolean;
    }
    
    export type FlowOptions = _FlowOptions;
    
    /** 
     * Translated from class com.dataiku.dip.coremodel.SerializedDataset$ReadWriteOptions
     * Via: com.dataiku.dip.coremodel.SerializedDataset
     */
    export interface _ReadWriteOptions {
        defaultReadOrdering: DatasetOrdering;
        forceSingleOutputFile: boolean;
        preserveOrder: boolean;
        singleOutputFileBaseName: string;
        writeBuckets: number;
    }
    
    export type ReadWriteOptions = _ReadWriteOptions;
    
    /** 
     * Translated from enum com.dataiku.dip.coremodel.SerializedDataset$RebuildBehavior
     * Via: com.dataiku.dip.dao.SavedModel
     */
    export enum RebuildBehavior {
        NORMAL = "NORMAL",
        EXPLICIT = "EXPLICIT",
        WRITE_PROTECT = "WRITE_PROTECT",
    }
    /** 
     * Translated from class com.dataiku.dip.coremodel.SerializedDataset$RemoteFiles
     * Via: com.dataiku.dip.coremodel.SerializedDataset
     */
    export interface _RemoteFiles {
        alwaysCheckSync: boolean;
        sources: SerializedDataset.RemoteFilesSource[];
    }
    
    export type RemoteFiles = _RemoteFiles;
    
    /** 
     * Translated from class com.dataiku.dip.coremodel.SerializedDataset$RemoteFilesSource
     * Via: com.dataiku.dip.coremodel.SerializedDataset$RemoteFiles
     */
    export interface _RemoteFilesSource {
        connection: string;
        path: string;
        protocol: string;
        url: string;
        useConnection: boolean;
        useGlobalProxy: boolean;
    }
    
    export type RemoteFilesSource = _RemoteFilesSource;
    
    /** 
     * Translated from class com.dataiku.dip.coremodel.SerializedDataset$SerializedCheck
     * Via: com.dataiku.dip.coremodel.SerializedDataset
     */
    export interface _SerializedCheck {
        params: { [key in string]: string };
        type: string;
    }
    
    export type SerializedCheck = _SerializedCheck;
}