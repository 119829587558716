import type { AvailableResult } from './../available-result';
import type { TimeSeriesComputation } from './time-series-computation';
import type { _AvailableResult } from './../available-result';
import type { _TimeSeriesComputation } from './time-series-computation';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.MannKendallTest
 * Via: UIModel annotation in the class hierarchy
 */
export interface _MannKendallTest extends _TimeSeriesComputation {
    type: "mann_kendall";
    alpha: number;
}

export type MannKendallTest = _MannKendallTest;

export namespace MannKendallTest {
    /** Type of class com.dataiku.dip.eda.compute.computations.timeseries.MannKendallTest in PolyJSON mappings */
    export const type = "mann_kendall";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.MannKendallTest$MannKendallTestResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _MannKendallTestResult extends _AvailableResult {
        type: "mann_kendall";
        intercept?: number | null;
        pValue: number;
        score: number;
        slope?: number | null;
        statistic: number;
        tau: number;
        trend: MannKendallTest.Trend;
        variance: number;
    }
    
    export type MannKendallTestResult = _MannKendallTestResult;
    
    /** 
     * Translated from enum com.dataiku.dip.eda.compute.computations.timeseries.MannKendallTest$Trend
     * Via: com.dataiku.dip.eda.worksheets.cards.MannKendallTestCard$MannKendallTestCardResult
     */
    export enum Trend {
        DECREASING = "DECREASING",
        INCREASING = "INCREASING",
        NO_TREND = "NO_TREND",
    }

    export namespace MannKendallTestResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.timeseries.MannKendallTest$MannKendallTestResult in PolyJSON mappings */
        export const type = "mann_kendall";
    }
}