import type { PT2DDensityRequest } from './density/pt2-ddensity-request';
import type { PTBoxplotsRequest } from './boxplots/ptboxplots-request';
import type { PTMapAggrAdminRequest } from './maps/ptmap-aggr-admin-request';
import type { PTMapAggrGridRequest } from './maps/ptmap-aggr-grid-request';
import type { PTMapRawGeometryRequest } from './maps/ptmap-raw-geometry-request';
import type { PTMapScatterRequest } from './maps/ptmap-scatter-request';
import type { PTScatterRequest } from './scatter/ptscatter-request';
import type { PivotTableFiltersRequest } from './maps/pivot-table-filters-request';
import type { PivotTableTensorRequest } from './pivot-table-tensor-request';
import type { PivotTableWebappRequest } from './maps/pivot-table-webapp-request';
import type { RowFilter } from './row-filter';
import type { SimpleAggregatedRequest } from './simple-aggregated-request';
import type { _PT2DDensityRequest } from './density/pt2-ddensity-request';
import type { _PTBoxplotsRequest } from './boxplots/ptboxplots-request';
import type { _PTMapAggrAdminRequest } from './maps/ptmap-aggr-admin-request';
import type { _PTMapAggrGridRequest } from './maps/ptmap-aggr-grid-request';
import type { _PTMapRawGeometryRequest } from './maps/ptmap-raw-geometry-request';
import type { _PTMapScatterRequest } from './maps/ptmap-scatter-request';
import type { _PTScatterRequest } from './scatter/ptscatter-request';
import type { _PivotTableFiltersRequest } from './maps/pivot-table-filters-request';
import type { _PivotTableTensorRequest } from './pivot-table-tensor-request';
import type { _PivotTableWebappRequest } from './maps/pivot-table-webapp-request';
import type { _RowFilter } from './row-filter';
import type { _SimpleAggregatedRequest } from './simple-aggregated-request';

/** 
 * Translated from class com.dataiku.dip.pivot.backend.model.PivotTableRequest
 * Via: UIModel annotation in the class hierarchy
 */
export interface _PivotTableRequest {
    computeUsableColumns: boolean;
    filters: RowFilter[];
    type: PivotTableRequest.RequestType;
}

/** List of all concrete subclasses of com.dataiku.dip.pivot.backend.model.PivotTableRequest */
export type PivotTableRequest =
    | PivotTableTensorRequest
    | SimpleAggregatedRequest
    | PTBoxplotsRequest
    | PT2DDensityRequest
    | PTMapAggrAdminRequest
    | PTMapAggrGridRequest
    | PTMapRawGeometryRequest
    | PTMapScatterRequest
    | PivotTableFiltersRequest
    | PivotTableWebappRequest
    | PTScatterRequest;

export namespace PivotTableRequest {
    /** 
     * Translated from enum com.dataiku.dip.pivot.backend.model.PivotTableRequest$RequestType
     * Via: com.dataiku.dip.pivot.backend.model.PivotTableRequest
     */
    export enum RequestType {
        AGGREGATED_ND = "AGGREGATED_ND",
        AGGREGATED_GEO_ADMIN = "AGGREGATED_GEO_ADMIN",
        AGGREGATED_GEO_GRID = "AGGREGATED_GEO_GRID",
        BOXPLOTS = "BOXPLOTS",
        SCATTER_NON_AGGREGATED = "SCATTER_NON_AGGREGATED",
        MAP_SCATTER_NON_AGGREGATED = "MAP_SCATTER_NON_AGGREGATED",
        DENSITY_2D = "DENSITY_2D",
        RAW_GEOMETRY = "RAW_GEOMETRY",
        NO_PIVOT_AGGREGATED = "NO_PIVOT_AGGREGATED",
        WEBAPP = "WEBAPP",
        FILTERS = "FILTERS",
    }
}