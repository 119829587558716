import { Component, ChangeDetectionStrategy, ViewChild, Inject, ChangeDetectorRef, HostListener } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { catchAPIError } from '@core/dataiku-api/api-error';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { PopularDatasetsService } from '@features/popular-datasets/services/popular-datasets.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DatasetAndTablePreview } from '@shared/services/dataset-and-table-preview/dataset-and-table-preview.service';
import { ErrorContextService } from '@shared/services/error-context.service';
import { Shareability, ShareAndPublishService } from '@shared/services/share-and-publish.service';
import { fairAny } from 'dku-frontend-core';
import { ITaggingService, ProjectsService, UIPopularDataset } from 'generated-sources';
import { finalize, firstValueFrom, map, shareReplay } from 'rxjs';

function popularDatasetToTaggableRefWithName(dataset: UIPopularDataset.AbstractPopularDataset) {
    return {
        type: ITaggingService.TaggableType.DATASET,
        projectKey: dataset.projectKey,
        id: dataset.name,
        displayName: dataset.name
    };
}

@UntilDestroy()
@Component({
    selector: 'popular-datasets-table',
    templateUrl: './popular-datasets-table.component.html',
    styleUrls: ['./popular-datasets-table.component.less'],
    providers: [ErrorContextService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopularDatasetsTableComponent {

    @ViewChild(MatSort) sort: MatSort;

    displayedColumns = ['icon', 'name', "projectName", 'projects', 'dataCollections', 'lastBuild', 'description', 'actions'];

    dataSource = new MatTableDataSource<UIPopularDataset.AbstractPopularDataset>();
    isLoading: boolean = false;
    hasLastResult: boolean = false;
    lastRunTimestamp: number = -1;
    golden: boolean = false;

    readonly popularDatasetsHelpMessage: string =
        "Popular datasets are commonly used and shared datasets across your instance, that you may want to publish to a data collection, workspace or feature store, or import directly into a project for immediate use.\n\n" +
        "Popular datasets are defined by a few key parameters, including:\n" +
        "* Number of shares\n" +
        "* Time since last rebuild\n" +
        "* \"Trending datasets\" (meaning they are getting more popular over time)\n\n" +
        `<a target="_blank" href="${this.$rootScope.versionDocRoot as string}data-catalog/popular-datasets.html">More details in the documentation</a>`;

    private keyword: string;

    constructor(
        private popularDatasetsService: PopularDatasetsService,
        private errorContext: ErrorContextService,
        private cd: ChangeDetectorRef,
        @Inject("$rootScope") public $rootScope: fairAny,
        @Inject("FutureProgressModal") private FutureProgressModal: fairAny,
        @Inject("ActivityIndicator") private ActivityIndicator: fairAny,
        private shareAndPublishService: ShareAndPublishService,
        private datasetAndTablePreview: DatasetAndTablePreview,
        private currentRouteService: CurrentRouteService
    ) {
        void this.refreshPopularDatasetList();
    }

    private get contextProject() {
        // the type is string, but it actually can be undefined in some contexts!
        const projectKey = this.currentRouteService.projectKey as string | undefined;
        if(projectKey) {
            return this.$rootScope.projectSummary as ProjectsService.UIProject;
        } else {
            return undefined;
        }
    }

    async refreshOrCompute() {
        await this.refreshPopularDatasetList();
        if (!this.hasLastResult) {
            this.compute();
        }
    }

    compute() {
        this.isLoading = true;
        this.popularDatasetsService.compute().pipe(
            catchAPIError(this.errorContext),
            shareReplay(1),
            finalize(() => {
                this.isLoading = false;
                this.cd.detectChanges();
            })
        ).subscribe(resp => {
            this.FutureProgressModal.show(this.$rootScope, resp, "Compute popular datasets").then(() => {
                void this.refreshPopularDatasetList();
                this.ActivityIndicator.success("Popular datasets computation done", 5000);
            }).catch(() => {
                this.ActivityIndicator.error("Popular datasets computation failed", 5000);
            }).finally(() => {
                this.cd.detectChanges();
            });
        });
    }

    popularDatasetsListIsEmpty(): boolean {
        return this.dataSource.data.length <= 0;
    }

    // magic trick to force type checking inside of mat-table
    isPopularDataset(item: unknown): item is UIPopularDataset.AbstractPopularDataset {
        return true;
    }

    isDatasetReadable(dataset: UIPopularDataset.AbstractPopularDataset): dataset is UIPopularDataset.PopularDataset {
        return UIPopularDataset.AbstractPopularDataset.isPopularDataset(dataset);
    }

    isDatasetDiscoverable(dataset: UIPopularDataset.AbstractPopularDataset): dataset is UIPopularDataset.DiscoverablePopularDataset {
        return UIPopularDataset.AbstractPopularDataset.isDiscoverablePopularDataset(dataset);
    }

    getShareability(item: UIPopularDataset.AbstractPopularDataset) {
        return this.shareAndPublishService.getShareability(
            popularDatasetToTaggableRefWithName(item),
            item.objectAuthorizations,
            this.contextProject
        );
    }

    getPublishability(item: UIPopularDataset.AbstractPopularDataset) {
        return this.shareAndPublishService.getPublishability(
            popularDatasetToTaggableRefWithName(item),
            item.objectAuthorizations,
            this.contextProject,
            this.$rootScope.appConfig.globalPermissions,
            this.isDatasetReadable(item) ? item.isFeatureGroup : false
        );
    }

    shareButtonAction(item: UIPopularDataset.AbstractPopularDataset, shareability: Shareability['shareability']): Promise<boolean> {
        const objectRef = popularDatasetToTaggableRefWithName(item);

        if (shareability === "share" && this.contextProject?.projectKey) {
            return this.shareAndPublishService.doShare(
                objectRef,
                this.contextProject.projectKey,
                item.objectAuthorizations.isQuicklyShareable,
                this.getWT1AdditionalInfo(item)
            );
        } else if (shareability === "share") {
            return this.shareAndPublishService.openShareModal(
                objectRef,
                item.objectAuthorizations,
                this.getWT1AdditionalInfo(item)
            );
        } else if (shareability === "request") {
            return this.shareAndPublishService.requestShare(
                objectRef,
                this.contextProject?.projectKey,
                this.getWT1AdditionalInfo(item)
            );
        } else {
            // somehow user clicked a disabled button, let's pretend nothing happened
            return Promise.resolve(false);
        }
    }

    shareToDashboard(item: UIPopularDataset.AbstractPopularDataset) {
        if(this.contextProject === undefined) return; // should never happen as button is not displayed
        return this.shareAndPublishService.shareDatasetToDashboard(item.projectKey, item.name, this.contextProject.projectKey);
    }

    shareToWorkspace(item: UIPopularDataset.AbstractPopularDataset) {
        return this.shareAndPublishService.shareToWorkspace(popularDatasetToTaggableRefWithName(item));
    }

    addToFeatureStore(item: UIPopularDataset.AbstractPopularDataset) {
        return this.shareAndPublishService.addToFeatureStore(item.projectKey, item.name, item.objectAuthorizations);
    }

    addToDataCollection(item: UIPopularDataset.AbstractPopularDataset) {
        return this.shareAndPublishService.addToDataCollection(popularDatasetToTaggableRefWithName(item), this.getWT1AdditionalInfo(item));
    }

    preview(item: UIPopularDataset.AbstractPopularDataset) {
        return this.datasetAndTablePreview.openDatasetPreviewModal(item.projectKey, item.name);
    }

    @HostListener('window:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        this.keyword += event.key.toLowerCase();
        if (this.keyword.includes('gold')) {
            this.golden = true;
            this.keyword = '';
        }
    }

    private async refreshPopularDatasetList() {
        this.isLoading = true;
        return firstValueFrom(
            this.popularDatasetsService.list().pipe(
                map(list => {
                    list.datasets.sort((a, b) => b.score - a.score);
                    return list;
                }),
                catchAPIError(this.errorContext),
            )
        ).then((popularDatasets) => {
            if (popularDatasets.status.lastRunTimestamp !== -1) {
                this.hasLastResult = true;
                this.lastRunTimestamp = popularDatasets.status.lastRunTimestamp;
                this.updateTable(popularDatasets.datasets);
            }
        }).finally(() => {
            this.isLoading = false;
            this.cd.detectChanges();
        });

    }

    private updateTable(popularDatasets: UIPopularDataset.AbstractPopularDataset[]) {
            this.dataSource.data = popularDatasets;
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (item: UIPopularDataset.AbstractPopularDataset, property: string) => {
                switch (property) {
                    case 'name': return item.name;
                    case 'projectName': return item.projectName;
                    case 'projects': return this.isDatasetReadable(item) ? item.numberOfProjects : 0;
                    case 'dataCollections': return this.isDatasetReadable(item) ? item.numberOfDataCollections : 0;
                    case 'lastBuild': return this.isDatasetReadable(item) ? item.lastBuiltOn : 0;
                    default: return 0;
                }
            };
    }

    private getWT1AdditionalInfo(item: UIPopularDataset.AbstractPopularDataset) {
        // be uber-safe about the type
        const source = Array.isArray(this.dataSource?.data) ? this.dataSource.data : [];
        return {
            from: 'popular-datasets',
            score: item.score,
            rank: source.indexOf(item)+1,
            accessiblePopularDatasetCount: source.length,
        };
    }
}

export interface PermissionInfo {
    show: boolean;
    enabled: boolean;
    tooltip: string;
}
