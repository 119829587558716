<ng-container *ngIf="tooltipOptions$ | async as tooltipOptions">
    <span *ngIf="tooltipOptions.message" class="badge badge--{{status$ | async}} horizontal-flex aic" [htmlTooltip]="tooltipOptions.message" [htmlTooltipPosition]="tooltipOptions.position">
        <i *ngIf="iconOptions$ | async as iconOptions" class="dku-icon-{{iconOptions.label}}-{{iconOptions.style}}-16 icon-{{iconOptions.status}}-color" [ngClass]="iconOptions.size"></i>
        <span *ngIf="label$ | async as label">{{ label }}</span>
    </span>

    <span *ngIf="!tooltipOptions.message" class="badge badge--{{status$ | async}} horizontal-flex aic" >
        <i *ngIf="iconOptions$ | async as iconOptions" class="dku-icon-{{iconOptions.label}}-{{iconOptions.style}}-16 icon-{{iconOptions.status}}-color" [ngClass]="iconOptions.size"></i>
        <span *ngIf="label$ | async as label">{{ label }}</span>
    </span>
</ng-container>
