import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { Card, PairwiseTTestCard, TTest2SampCard } from 'src/generated-sources';
import { createShapiroCard, createVarianceComparisonCard } from '../card-creation-templates';
import { ModalsService, ModalShape } from '@shared/modals/modals.service';
import { EditCardModalComponent } from '@features/eda/worksheet/card-wizard/edit-card-modal/edit-card-modal.component';
import { CardActionType, CardAction } from '../../events';

type SupportedCards = PairwiseTTestCard | TTest2SampCard;

@Component({
    selector: 'generic-nsamp-ttest-card-help',
    templateUrl: './generic-nsamp-ttest-card-help.component.html',
    styleUrls: ['./generic-nsamp-ttest-card-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericNSampTTestCardHelpComponent {
    @Input() params: SupportedCards;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
    @Output() action = new EventEmitter<CardAction>();

    constructor(private modalsService: ModalsService, private viewContainerRef: ViewContainerRef) { }

    get canAddCard() {
        return !this.readOnly && this.extendedActions;
    }

    addGroupedShapiro() {
        if (!this.canAddCard) {
            return;
        }

        const proposedCard = createShapiroCard(this.params.xColumn, this.params.yColumn);
        this.modalsService.open(EditCardModalComponent, { params: proposedCard }, ModalShape.NONE,
            this.viewContainerRef)
            .then(({ card }) => {
                this.action.emit({
                    type: CardActionType.ADD,
                    card
                });
            }, () => { });
    }

    get hasTwoSamples() {
        return Card.isAbstract2SampTestCard(this.params);
    }

    get isPairwise() {
        return Card.isAbstractPairwiseTestCard(this.params);
    }

    addGroupedSummary() {
        if (!this.canAddCard) {
            return;
        }

        const proposedCard = createVarianceComparisonCard(this.params.xColumn, this.params.yColumn);
        this.modalsService.open(EditCardModalComponent, { params: proposedCard }, ModalShape.NONE,
            this.viewContainerRef)
            .then(({ card }) => {
                this.action.emit({
                    type: CardActionType.ADD,
                    card
                });
            }, () => { });
    }
}
