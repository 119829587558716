export namespace SparkConstants {
    /** 
     * Translated from enum com.dataiku.dip.analysis.ml.SparkConstants$Checkpoint
     * Via: com.dataiku.dip.analysis.model.MLTask
     */
    export enum Checkpoint {
        NONE = "NONE",
        RESILIENT = "RESILIENT",
        LOCAL = "LOCAL",
    }
}