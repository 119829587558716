import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NumberSelectComponent } from '@shared/modules/number-select';
import { isNil } from 'lodash';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ChartColorPickerComponent } from '../../chart-color-picker';


@UntilDestroy()
@Component({
    selector: 'text-formatting-form',
    templateUrl: './text-formatting-form.component.html'
})

export class TextFormattingFormComponent implements AfterViewInit {
    @Input() enableReset: boolean = false;
    @Input() defaultFontSize: number = 11;
    @Input() defaultFontColor: string = '#333';

    @Output() textFormattingChange = new EventEmitter<ChartDef.TextFormattingOptions>();

    @ViewChild(NumberSelectComponent) fontSizeInput: NumberSelectComponent;
    @ViewChild('fontColorInput') fontColorInput: ChartColorPickerComponent;

    public colors: Array<string>;
    public showBackgroundSettings$ = new BehaviorSubject(true);
    public showFontColorSettings$ = new BehaviorSubject(true);
    public showFontLabel$ = new BehaviorSubject(true);
    public resetTooltip$ = new BehaviorSubject('Reset');

    public textFormattingForm = new UntypedFormGroup({
        fontSize: new FormControl<number>(this.defaultFontSize),
        fontColor: new FormControl<string>(this.defaultFontColor),
        hasBackground: new FormControl<boolean>(false),
        backgroundColor: new FormControl<string>('#D9D9D9BF')
    });

    constructor() {
        this.colors = (window as any).dkuColorPalettes.discrete[1].colors;
    }

    @Input()
    set textFormatting(value: ChartDef.TextFormattingOptions) {
        if (!value) {
            this.textFormattingForm.updateValueAndValidity();
        } else if (!this.equals(value, this.textFormattingForm.getRawValue())) {
            this.textFormattingForm.patchValue(value);
        }
    }

    @Input()
    set showFontLabel(value: boolean) {
        this.showFontLabel$.next(value);
    }

    @Input()
    set showBackgroundSettings(value: boolean) {
        this.showBackgroundSettings$.next(value);
        if (value === false) {
            this.textFormattingForm.removeControl('hasBackground');
            this.textFormattingForm.removeControl('backgroundColor');
        }
    }

    @Input()
    set showFontColorSettings(value: boolean) {
        this.showFontColorSettings$.next(value);
        if (value) {
            this.textFormattingForm.addControl('fontColor', new UntypedFormControl('#333'));
        } else {
            this.textFormattingForm.removeControl('fontColor');
        }
    }

    @Input()
    set resetTooltip(value: string | null | undefined) {
        if (value) {
            this.resetTooltip$.next(value);
        }
    }
    
    ngAfterViewInit(): void {
        this.textFormattingForm.valueChanges
            .pipe(
                untilDestroyed(this),
                debounceTime(10),
                distinctUntilChanged(this.equals)
            )
            .subscribe((nextValue: ChartDef.TextFormattingOptions) => {
                this.textFormattingChange.emit({ ...nextValue });
            });
    }

    equals(textFormatting: ChartDef.TextFormattingOptions, textFormattingForm: ChartDef.TextFormattingOptions): boolean {
        return textFormatting && textFormatting.fontSize === textFormattingForm.fontSize && textFormatting.fontColor === textFormattingForm.fontColor &&
        ((isNil(textFormattingForm.hasBackground) && isNil(textFormattingForm.backgroundColor)) || (textFormatting.hasBackground === textFormattingForm.hasBackground && textFormatting.backgroundColor === textFormattingForm.backgroundColor));
    }

    public resetFont() {
        this.textFormattingForm.controls.fontSize.patchValue(this.defaultFontSize);
        //otherwise the input is not updated
        this.fontSizeInput.value = this.textFormattingForm.getRawValue().fontSize;

        if (this.showFontColorSettings$.value) {
            this.textFormattingForm.controls.fontColor.patchValue(this.defaultFontColor);
            this.fontColorInput.color = this.textFormattingForm.getRawValue().fontColor;
        }
    }
}
