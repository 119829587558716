import { Injectable } from '@angular/core';
import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { PredictionSMMgmtService } from 'src/generated-sources';

@Injectable({ providedIn: 'root' })
export class PrivateSavedModelsPredictionAPI {
    constructor(
        private dkuHttp: DkuHttpService
    ) { }

    getStatus(projectKey: string, savedModelId: string): Observable<PredictionSMMgmtService.PredictionSMStatus> {
        return this.dkuHttp.request('GET', '/savedmodels/prediction/get-status', {
            projectKey, savedModelId
        }, false);
    }
}
