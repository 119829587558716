import { AxisDef } from '@model-main/pivot/backend/model/axis-def';
import { AxisSortPrune } from '@model-main/pivot/backend/model/axis-sort-prune';
import { PivotTableResponse } from '@model-main/pivot/backend/model/pivot-table-response';
import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { SamplingParam } from '@model-main/datasets/sampling-param';

export const pivotTableTensorResponseWithTwoMeasures: PivotTableTensorResponse = {
    axisLabels: [
        [
            {
                label: '___dku_no_value___'
            },
            {
                label: 'M'
            },
            {
                label: 'F'
            }
        ]
    ] as Array<any>,
    axisDefs: [
        {
            column: 'gender',
            type: AxisDef.Type.ALPHANUM,
            sortPrune: {
                sortType: AxisSortPrune.SortType.AGGREGATION,
                sortAscending: false,
                aggregationSortId: 0,
                generateOthersCategory: true,
                forceLastPositionOthers: true,
                filters: [],
                maxValues: 20
            }
        }
    ] as Array<any>,
    sequenceId: 0,
    aggregations: [
        {
            numAxes: 1,
            axisLengths: [4],
            tensor: [
                58.773549000951476,
                56.43458781362007,
                51.54113712374582,
                0
            ],
            axes: [[0, 0, 0, 0]],
            multipliers: [1]
        },
        {
            numAxes: 1,
            axisLengths: [4],
            tensor: [
                10.730732635585158,
                10.896505376344086,
                10.883835005574136,
                0
            ],
            axes: [[0, 0, 0, 0]],
            multipliers: [1]
        }
    ] as Array<any>,
    counts: {
        numAxes: 1,
        axisLengths: [4],
        tensor: [
            1051,
            4464,
            4485,
            0
        ],
        axes: [[0, 0, 0, 0]],
        multipliers: [1]
    },
    filterFacets: [],
    beforeFilterRecords: 10000,
    afterFilterRecords: 10000,
    engine: PivotTableResponse.PivotEngine.LINO,
    sampleMetadata: {
        samplingMethod: SamplingParam.SamplingMethod.HEAD_SEQUENTIAL,
        maxRecords: 10000,
        targetRatio: 0.02,
        sampleIsWholeDataset: false,
        datasetRecordCount: -1,
        recordCountIsApproximate: false
    } as any,
    columnsSummary: undefined as any
};
