import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatTooltipCaretDirective } from './mat-tooltip-caret.directive';
import { IsEllipsisActiveDirective } from './is-ellipsis-active.directive';



@NgModule({
  declarations: [
    MatTooltipCaretDirective,
    IsEllipsisActiveDirective
  ],
  imports: [
    CommonModule,
    MatTooltipModule
  ],
  exports: [
    MatTooltipModule,
    MatTooltipCaretDirective,
    IsEllipsisActiveDirective
  ]
})
export class DkuMatTooltipModule { }
