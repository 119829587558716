<div *ngIf="nav$ | async as nav" class="object-nav horizontal-flex">
    <div class="flex oh">
        <div class="object-breadcrumb">
            <a class="item insight">
                <i class="icon" [ngClass]="[objectColor$ | async, objectType$ | async | toModernIcon:24]"></i>
                <span class="title ellipsis" textOverflowTooltip [textTooltip]="objectName$ | async" tooltipDirection="tooltip-bottom"></span>
            </a>
        </div>
    </div>
    <div class="object-nav__links">
        <a *ngIf="nav.back && !nav.back.disabled"
            class="object-nav__link"
            [attr.href]="nav.back.href">
            <i class="dku-icon-arrow-left-16"></i>
            {{nav.back.label}}
        </a>
        <a *ngIf="nav.source && !nav.source.disabled"
            class="object-nav__link"
            [attr.href]="nav.source.href">
            <i [attr.class]="objectType$ | async | toModernIcon:16"></i>
            {{nav.source.label}}
        </a>
    </div>
</div>
