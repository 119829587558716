import { AxisDef } from "@model-main/pivot/backend/model/axis-def";
import { AxisSortPrune } from "@model-main/pivot/backend/model/axis-sort-prune";
import { PivotTableResponse } from "@model-main/pivot/backend/model/pivot-table-response";
import { PivotTableTensorResponse } from "@model-main/pivot/backend/model/pivot-table-tensor-response";
import { SamplingParam } from "@model-main/datasets/sampling-param";
import { DateAxisParams } from "@model-main/pivot/backend/model/date-axis-params";

export const pivotTableTensorResponseDCMT: PivotTableTensorResponse = {
    axisLabels: [
        [
            {
                tsValue: 1364774400000,
                sortValue: 8053,
                min: 1364774400000,
                max: 1372636799999,
                label: "2013-Q2"
            },
            {
                tsValue: 1372636800000,
                sortValue: 8054,
                min: 1372636800000,
                max: 1380585599999,
                label: "2013-Q3"
            },
            {
                tsValue: 1380585600000,
                sortValue: 8055,
                min: 1380585600000,
                max: 1388534399999,
                label: "2013-Q4"
            },
            {
                tsValue: 1388534400000,
                sortValue: 8056,
                min: 1388534400000,
                max: 1396310399999,
                label: "2014-Q1"
            },
            {
                tsValue: 1396310400000,
                sortValue: 8057,
                min: 1396310400000,
                max: 1404172799999,
                label: "2014-Q2"
            },
            {
                tsValue: 1404172800000,
                sortValue: 8058,
                min: 1404172800000,
                max: 1412121599999,
                label: "2014-Q3"
            },
            {
                tsValue: 1412121600000,
                sortValue: 8059,
                min: 1412121600000,
                max: 1420070399999,
                label: "2014-Q4"
            },
            {
                tsValue: 1420070400000,
                sortValue: 8060,
                min: 1420070400000,
                max: 1427846399999,
                label: "2015-Q1"
            },
            {
                tsValue: 1427846400000,
                sortValue: 8061,
                min: 1427846400000,
                max: 1435708799999,
                label: "2015-Q2"
            },
            {
                tsValue: 1435708800000,
                sortValue: 8062,
                min: 1435708800000,
                max: 1443657599999,
                label: "2015-Q3"
            },
            {
                tsValue: 1443657600000,
                sortValue: 8063,
                min: 1443657600000,
                max: 1451606399999,
                label: "2015-Q4"
            },
            {
                tsValue: 1451606400000,
                sortValue: 8064,
                min: 1451606400000,
                max: 1459468799999,
                label: "2016-Q1"
            },
            {
                tsValue: 1459468800000,
                sortValue: 8065,
                min: 1459468800000,
                max: 1467331199999,
                label: "2016-Q2"
            },
            {
                tsValue: 1467331200000,
                sortValue: 8066,
                min: 1467331200000,
                max: 1475279999999,
                label: "2016-Q3"
            },
            {
                tsValue: 1475280000000,
                sortValue: 8067,
                min: 1475280000000,
                max: 1483228799999,
                label: "2016-Q4"
            },
            {
                tsValue: 1483228800000,
                sortValue: 8068,
                min: 1483228800000,
                max: 1491004799999,
                label: "2017-Q1"
            },
            {
                tsValue: 1491004800000,
                sortValue: 8069,
                min: 1491004800000,
                max: 1498867199999,
                label: "2017-Q2"
            }
        ]
    ] as Array<any>,
    axisDefs: [
        {
            column: "order_date",
            type: AxisDef.Type.DATE,
            sortPrune: {
                sortType: AxisSortPrune.SortType.NATURAL,
                sortAscending: true,
                aggregationSortId: -1,
                generateOthersCategory: true,
                forceLastPositionOthers: true,
                filters: [],
                maxValues: 0
            },
            dateParams: {
                mode: DateAxisParams.BinningMode.QUARTER,
                maxBinNumberForAutomaticMode: 30
            }
        }
    ] as Array<any>,
    sequenceId: 0,
    aggregations: [
        {
            numAxes: 1,
            axisLengths: [
                18
            ],
            tensor: [
                42.58571428571429,
                46.121212121212125,
                50.45100502512563,
                60.1171875,
                49.579787234042556,
                47.406451612903226,
                58.52613941018767,
                59.38779956427015,
                49.101073345259394,
                48.66790254237288,
                54.938693467336684,
                58.74105960264901,
                57.10686015831135,
                52.33858695652174,
                55.016582914572865,
                63.45560488346282,
                58.328828828828826,
                0
            ],
            axes: [
                [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            ],
            multipliers: [
                1
            ]
        }
    ] as Array<any>,
    counts: {
        numAxes: 1,
        axisLengths: [
            18
        ],
        tensor: [
            35,
            330,
            398,
            192,
            282,
            620,
            746,
            459,
            559,
            944,
            995,
            755,
            758,
            920,
            995,
            901,
            111,
            0
        ],
        axes: [
            [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ]
        ],
        multipliers: [
            1
        ]
    },
    filterFacets: [],
    beforeFilterRecords: 10000,
    afterFilterRecords: 10000,
    engine: PivotTableResponse.PivotEngine.LINO,
    sampleMetadata: {
        samplingMethod: SamplingParam.SamplingMethod.HEAD_SEQUENTIAL,
        maxRecords: 10000,
        targetRatio: 0.02,
        hasFilter: false,
        partitionCount: 0,
        sampleIsWholeDataset: false,
        sampleRecordCount: -1,
        datasetRecordCount: -1,
        recordCountIsApproximate: false,
        recordCountIsObsolete: false,
        memoryLimitInMB: 150,
        memoryLimitReached: false
    },
    columnsSummary: undefined as any
};
