import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { ComputationResult } from './../computation-result';
import type { _AvailableResult } from './../available-result';
import type { _Computation } from './../computation';
import type { _ComputationResult } from './../computation-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.common.MultiComputation
 * Via: UIModel annotation in the class hierarchy
 */
export interface _MultiComputation extends _Computation {
    type: "multi";
    computations: Computation[];
}

export type MultiComputation = _MultiComputation;

export namespace MultiComputation {
    /** Type of class com.dataiku.dip.eda.compute.computations.common.MultiComputation in PolyJSON mappings */
    export const type = "multi";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.common.MultiComputation$MultiComputationResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _MultiComputationResult extends _AvailableResult {
        type: "multi";
        results: ComputationResult[];
    }
    
    export type MultiComputationResult = _MultiComputationResult;

    export namespace MultiComputationResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.common.MultiComputation$MultiComputationResult in PolyJSON mappings */
        export const type = "multi";
    }
}