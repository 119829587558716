<div>
    <experiment-tracking-header [intermediateLinks]="intermediateLinks">
        <ng-container *ngIf="run$ | async as run">
            <span matTooltip="No model logged in the current run." [matTooltipDisabled]="run.origin == 'analysis' || run.data.models.length != 0">
                <button class="btn btn--secondary right-header-deploy"
                        (click)="openDeployModal(run)"
                        [disabled]="!canDeploy(run)"
                        [ngClass]="{disabled: run.origin != 'analysis' && run.data.models.length == 0}"
                        [qa]="'experiments-run-details-deploy-model'">
                   <i class="icon-play"></i>
                   {{ run.data.models.length < 2 ? 'Deploy the model' : 'Deploy a model (' + run.data.models.length + ')'}}
                </button>
            </span>
        </ng-container>
    </experiment-tracking-header>
    <div class="dss-page row-fluid offset0">
        <div class="span2 nav-list-sidebar sidebar-experiment-tracking oa">
            <ul>
                <li class="l1" [class.active]="activeTab === 'details'">
                    <a [href]="detailsHref" [wt1Click]="'experiment-tracking-run-go-to-details'" [qa]="'experiments-run-details-to-details'">Run Details</a>
                </li>
                <ng-container *ngIf="run$ | async as run">
                    <li class="l1" *ngIf="hasArtifact(run)" [class.active]="activeTab === 'artifacts'">
                        <a [href]="artifactsHref" [wt1Click]="'experiment-tracking-run-go-to-artifacts'" [qa]="'experiments-run-details-to-artifacts'">Run Artifacts</a>
                    </li>
                </ng-container>
            </ul>
        </div>
        <div class="span10 h100 offset0 boxed-next-to-sidebar oa">
            <div *ngIf="error$ | async as error" class="error-details">
                <api-error-alert [apiError]="error" (resetError)="resetError()" [closable]="true"></api-error-alert>
            </div>
            <ng-container *ngIf="run$ | async as run">
                <div experiment-tracking-run-details *ngIf="activeTab === 'details'" [run]="run"
                     (refreshRun)="refresh()" (openDeployModal)="openDeployModal(run)"
                     class="oa experiment-tracking-run-tab-content">
                </div>
                <div experiment-tracking-run-artifacts *ngIf="activeTab === 'artifacts'" [run]="run" class="oa experiment-tracking-run-tab-content">
                </div>
            </ng-container>
        </div>
    </div>
</div>
