import type { Distribution } from './distribution';
import type { _Distribution } from './distribution';

/** 
 * Translated from class com.dataiku.dip.eda.compute.distributions.Exponential
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Exponential extends _Distribution {
    type: "exponential";
}

export type Exponential = _Exponential;

export namespace Exponential {
    /** Type of class com.dataiku.dip.eda.compute.distributions.Exponential in PolyJSON mappings */
    export const type = "exponential";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.distributions.Exponential$FittedExponential
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FittedExponential extends Distribution._FittedDistribution {
        type: "exponential";
        rate: number;
    }
    
    export type FittedExponential = _FittedExponential;

    export namespace FittedExponential {
        /** Type of class com.dataiku.dip.eda.compute.distributions.Exponential$FittedExponential in PolyJSON mappings */
        export const type = "exponential";
    }
}