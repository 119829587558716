import type { ProxyModelVersionConfiguration } from './../../savedmodels/proxymodelversions/proxy-model-version-configuration';



export namespace VertexAIUtils {
    /** 
     * Translated from class com.dataiku.dip.apideployer.deployments.VertexAIUtils$DSSVertexAIConsolidatedEndpointInfo
     * Via: com.dataiku.dip.savedmodels.proxymodelversions.ProxyModelVersionConfiguration$ConsolidatedEndpointInfo
     */
    export interface _DSSVertexAIConsolidatedEndpointInfo extends ProxyModelVersionConfiguration._ConsolidatedEndpointInfo {
        type: "DSSVertexAIConsolidatedEndpointInfo";
        description: string;
        displayName: string;
        models: VertexAIUtils.DSSVertexAIModelInfo[];
        name: string;
        trafficSplit: { [key in string]: number };
    }
    
    export type DSSVertexAIConsolidatedEndpointInfo = _DSSVertexAIConsolidatedEndpointInfo;
    
    /** 
     * Translated from class com.dataiku.dip.apideployer.deployments.VertexAIUtils$DSSVertexAIModelInfo
     * Via: com.dataiku.dip.apideployer.deployments.VertexAIUtils$DSSVertexAIConsolidatedEndpointInfo
     */
    export interface _DSSVertexAIModelInfo {
        displayName: string;
        id: string;
        modelId: string;
        modelVersionId: string;
        resourceName: string;
        versionId: string;
    }
    
    export type DSSVertexAIModelInfo = _DSSVertexAIModelInfo;

    export namespace DSSVertexAIConsolidatedEndpointInfo {
        /** Type of class com.dataiku.dip.apideployer.deployments.VertexAIUtils$DSSVertexAIConsolidatedEndpointInfo in PolyJSON mappings */
        export const type = "DSSVertexAIConsolidatedEndpointInfo";
    }
}