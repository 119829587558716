import type { DeepHubPreTrainModelingParams } from './deep-hub-pre-train-modeling-params';
import type { MLTask } from './../mltask';
import type { PredictionPreprocessingParams } from './../preprocessing/prediction-preprocessing-params';
import type { SplitParams } from './../split-params';
import type { _DeepHubPreTrainModelingParams } from './deep-hub-pre-train-modeling-params';
import type { _MLTask } from './../mltask';
import type { _PredictionPreprocessingParams } from './../preprocessing/prediction-preprocessing-params';
import type { _SplitParams } from './../split-params';

/** 
 * Translated from class com.dataiku.dip.analysis.model.prediction.PredictionMLTask
 * Via: com.dataiku.dip.analysis.ml.prediction.flow.PredictionSMMgmtService$PredictionSMStatus
 */
export interface _PredictionMLTask extends _MLTask {
    predictionType: PredictionMLTask.PredictionType;
    splitParams: SplitParams;
    targetVariable: string;
}

/**
    Translate from class com.dataiku.dip.analysis.model.prediction.PredictionMLTask
    @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
*/
export type PredictionMLTask = _PredictionMLTask;

export namespace PredictionMLTask {
    /** 
     * Translated from class com.dataiku.dip.analysis.model.prediction.PredictionMLTask$DeepHubPredictionMLTask
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _DeepHubPredictionMLTask extends _PredictionMLTask {
        managedFolderSmartId: string;
        modeling: DeepHubPreTrainModelingParams;
        pathColumn: string;
        preprocessing: PredictionPreprocessingParams;
    }
    
    export type DeepHubPredictionMLTask = _DeepHubPredictionMLTask;
    
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.prediction.PredictionMLTask$PredictionType
     * Via: UIModel annotation in the class hierarchy
     */
    export enum PredictionType {
        BINARY_CLASSIFICATION = "BINARY_CLASSIFICATION",
        REGRESSION = "REGRESSION",
        MULTICLASS = "MULTICLASS",
        DEEP_HUB_IMAGE_OBJECT_DETECTION = "DEEP_HUB_IMAGE_OBJECT_DETECTION",
        DEEP_HUB_IMAGE_CLASSIFICATION = "DEEP_HUB_IMAGE_CLASSIFICATION",
        TIMESERIES_FORECAST = "TIMESERIES_FORECAST",
        CAUSAL_BINARY_CLASSIFICATION = "CAUSAL_BINARY_CLASSIFICATION",
        CAUSAL_REGRESSION = "CAUSAL_REGRESSION",
    }

    export namespace TimeseriesForecastingMLTask {
        /** 
         * Translated from class com.dataiku.dip.analysis.model.prediction.PredictionMLTask$TimeseriesForecastingMLTask$TimeseriesEvaluationParams
         * Via: com.dataiku.dip.analysis.model.prediction.PredictionModelSnippetData
         */
        export interface _TimeseriesEvaluationParams {
            gapSize: number;
            testSize: number;
        }
        
        export type TimeseriesEvaluationParams = _TimeseriesEvaluationParams;
    }
}