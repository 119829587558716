import type { ACFPlotCard } from './acfplot-card';
import type { Abstract2DPivotCard } from './bivariate_header/abstract2-dpivot-card';
import type { Abstract2SampTestCard } from './common/abstract2-samp-test-card';
import type { AbstractCardContainer } from './common/abstract-card-container';
import type { AbstractHeaderCard } from './common/abstract-header-card';
import type { AbstractNSampTestCard } from './common/abstract-nsamp-test-card';
import type { AbstractPairwiseTestCard } from './common/abstract-pairwise-test-card';
import type { BivariateBoxPlotCard } from './bivariate_header/bivariate-box-plot-card';
import type { BivariateCard } from './bivariate-card';
import type { BivariateFrequencyTableCard } from './bivariate_header/bivariate-frequency-table-card';
import type { BivariateHeaderCard } from './bivariate_header/bivariate-header-card';
import type { BivariateHistogramCard } from './bivariate_header/bivariate-histogram-card';
import type { BivariateSummaryStatsCard } from './bivariate_header/bivariate-summary-stats-card';
import type { CDFPlotCard } from './cdfplot-card';
import type { CardWithConfidenceInterval } from './common/card-with-confidence-interval';
import type { CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { CategoricalHistogramCard } from './univariate_header/categorical-histogram-card';
import type { Chi2IndTestCard } from './chi2-ind-test-card';
import type { ColumnCard } from './common/column-card';
import type { CorrelationMatrixCard } from './correlation-matrix-card';
import type { DurbinWatsonCard } from './durbin-watson-card';
import type { Filter } from './../../compute/filtering/filter';
import type { Fit2DDistributionCard } from './fit2-ddistribution-card';
import type { FitCurveCard } from './fit-curve-card';
import type { FitDistributionCard } from './fit-distribution-card';
import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { KsTest2SampCard } from './ks-test2-samp-card';
import type { MannKendallTestCard } from './mann-kendall-test-card';
import type { MoodTest2SampCard } from './mood-test2-samp-card';
import type { MoodTestNSampCard } from './mood-test-nsamp-card';
import type { MosaicPlotCard } from './bivariate_header/mosaic-plot-card';
import type { MultivariateCard } from './multivariate-card';
import type { NumericalHistogramCard } from './univariate_header/numerical-histogram-card';
import type { OneWayANOVACard } from './one-way-anovacard';
import type { PCACard } from './pcacard';
import type { PairwiseMoodTestCard } from './pairwise-mood-test-card';
import type { PairwiseTTestCard } from './pairwise-ttest-card';
import type { ParallelCoordinatesPlotCard } from './parallel-coordinates-plot-card';
import type { PlaygroundCard } from './playground-card';
import type { QuantilesTableCard } from './univariate_header/quantiles-table-card';
import type { STLDecompositionCard } from './stldecomposition-card';
import type { ScatterPlot3DCard } from './scatter-plot3-dcard';
import type { ScatterPlotCard } from './bivariate_header/scatter-plot-card';
import type { ShapiroNormalityTestCard } from './shapiro-normality-test-card';
import type { SignTest1SampCard } from './sign-test1-samp-card';
import type { SplitBySpec } from './../models/split-by-spec';
import type { TTest2SampCard } from './ttest2-samp-card';
import type { TZTest1SampCard } from './tztest1-samp-card';
import type { TimeSeriesCard } from './time-series-card';
import type { TrivariateCard } from './trivariate-card';
import type { UnitRootTestADFCard } from './unit-root-test-adfcard';
import type { UnitRootTestCard } from './unit-root-test-card';
import type { UnitRootTestKPSSCard } from './unit-root-test-kpsscard';
import type { UnitRootTestZACard } from './unit-root-test-zacard';
import type { UnivariateCard } from './univariate-card';
import type { UnivariateFrequencyTableCard } from './univariate_header/univariate-frequency-table-card';
import type { UnivariateHeaderCard } from './univariate_header/univariate-header-card';
import type { UnivariateSummaryStatsCard } from './univariate_header/univariate-summary-stats-card';
import type { WorksheetRootCard } from './worksheet-root-card';
import type { _ACFPlotCard } from './acfplot-card';
import type { _Abstract2DPivotCard } from './bivariate_header/abstract2-dpivot-card';
import type { _Abstract2SampTestCard } from './common/abstract2-samp-test-card';
import type { _AbstractCardContainer } from './common/abstract-card-container';
import type { _AbstractHeaderCard } from './common/abstract-header-card';
import type { _AbstractNSampTestCard } from './common/abstract-nsamp-test-card';
import type { _AbstractPairwiseTestCard } from './common/abstract-pairwise-test-card';
import type { _BivariateBoxPlotCard } from './bivariate_header/bivariate-box-plot-card';
import type { _BivariateCard } from './bivariate-card';
import type { _BivariateFrequencyTableCard } from './bivariate_header/bivariate-frequency-table-card';
import type { _BivariateHeaderCard } from './bivariate_header/bivariate-header-card';
import type { _BivariateHistogramCard } from './bivariate_header/bivariate-histogram-card';
import type { _BivariateSummaryStatsCard } from './bivariate_header/bivariate-summary-stats-card';
import type { _CDFPlotCard } from './cdfplot-card';
import type { _CardWithConfidenceInterval } from './common/card-with-confidence-interval';
import type { _CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { _CategoricalHistogramCard } from './univariate_header/categorical-histogram-card';
import type { _Chi2IndTestCard } from './chi2-ind-test-card';
import type { _ColumnCard } from './common/column-card';
import type { _CorrelationMatrixCard } from './correlation-matrix-card';
import type { _DurbinWatsonCard } from './durbin-watson-card';
import type { _Filter } from './../../compute/filtering/filter';
import type { _Fit2DDistributionCard } from './fit2-ddistribution-card';
import type { _FitCurveCard } from './fit-curve-card';
import type { _FitDistributionCard } from './fit-distribution-card';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _KsTest2SampCard } from './ks-test2-samp-card';
import type { _MannKendallTestCard } from './mann-kendall-test-card';
import type { _MoodTest2SampCard } from './mood-test2-samp-card';
import type { _MoodTestNSampCard } from './mood-test-nsamp-card';
import type { _MosaicPlotCard } from './bivariate_header/mosaic-plot-card';
import type { _MultivariateCard } from './multivariate-card';
import type { _NumericalHistogramCard } from './univariate_header/numerical-histogram-card';
import type { _OneWayANOVACard } from './one-way-anovacard';
import type { _PCACard } from './pcacard';
import type { _PairwiseMoodTestCard } from './pairwise-mood-test-card';
import type { _PairwiseTTestCard } from './pairwise-ttest-card';
import type { _ParallelCoordinatesPlotCard } from './parallel-coordinates-plot-card';
import type { _PlaygroundCard } from './playground-card';
import type { _QuantilesTableCard } from './univariate_header/quantiles-table-card';
import type { _STLDecompositionCard } from './stldecomposition-card';
import type { _ScatterPlot3DCard } from './scatter-plot3-dcard';
import type { _ScatterPlotCard } from './bivariate_header/scatter-plot-card';
import type { _ShapiroNormalityTestCard } from './shapiro-normality-test-card';
import type { _SignTest1SampCard } from './sign-test1-samp-card';
import type { _SplitBySpec } from './../models/split-by-spec';
import type { _TTest2SampCard } from './ttest2-samp-card';
import type { _TZTest1SampCard } from './tztest1-samp-card';
import type { _TimeSeriesCard } from './time-series-card';
import type { _TrivariateCard } from './trivariate-card';
import type { _UnitRootTestADFCard } from './unit-root-test-adfcard';
import type { _UnitRootTestCard } from './unit-root-test-card';
import type { _UnitRootTestKPSSCard } from './unit-root-test-kpsscard';
import type { _UnitRootTestZACard } from './unit-root-test-zacard';
import type { _UnivariateCard } from './univariate-card';
import type { _UnivariateFrequencyTableCard } from './univariate_header/univariate-frequency-table-card';
import type { _UnivariateHeaderCard } from './univariate_header/univariate-header-card';
import type { _UnivariateSummaryStatsCard } from './univariate_header/univariate-summary-stats-card';
import type { _WorksheetRootCard } from './worksheet-root-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.Card
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Card extends _ImmutableValueObject {
    filter?: Filter | null;
    id: string;
    splitBy?: SplitBySpec | null;
}

/** List of all concrete subclasses of com.dataiku.dip.eda.worksheets.cards.Card */
export type Card =
    | ACFPlotCard
    | CDFPlotCard
    | Chi2IndTestCard
    | CorrelationMatrixCard
    | DurbinWatsonCard
    | Fit2DDistributionCard
    | FitCurveCard
    | FitDistributionCard
    | KsTest2SampCard
    | MannKendallTestCard
    | MoodTest2SampCard
    | MoodTestNSampCard
    | OneWayANOVACard
    | PCACard
    | PairwiseMoodTestCard
    | PairwiseTTestCard
    | ParallelCoordinatesPlotCard
    | PlaygroundCard
    | STLDecompositionCard
    | ScatterPlot3DCard
    | ShapiroNormalityTestCard
    | SignTest1SampCard
    | TTest2SampCard
    | TZTest1SampCard
    | UnitRootTestADFCard
    | UnitRootTestKPSSCard
    | UnitRootTestZACard
    | WorksheetRootCard
    | BivariateBoxPlotCard
    | BivariateFrequencyTableCard
    | BivariateHeaderCard
    | BivariateHistogramCard
    | BivariateSummaryStatsCard
    | MosaicPlotCard
    | ScatterPlotCard
    | ColumnCard
    | CategoricalHistogramCard
    | NumericalHistogramCard
    | QuantilesTableCard
    | UnivariateFrequencyTableCard
    | UnivariateHeaderCard
    | UnivariateSummaryStatsCard;

export namespace Card {
    /** Types of class com.dataiku.dip.eda.worksheets.cards.Card in PolyJSON mappings */
    export const type = ["acf_plot", "cdf_plot", "chi2_independence_test", "correlation_matrix", "durbin_watson", "fit_2d_distribution", "fit_curve", "fit_distribution", "ks_test_2samp", "mann_kendall_test", "mood_test_2samp", "mood_nsamp", "oneway_anova", "pca", "pairwise_mood", "pairwise_ttest", "parallel_coordinates_plot", "playground", "stl_decomposition", "scatter_plot_3d", "shapiro", "sign_test_1samp", "ttest_2samp", "ttest_ztest_1samp", "unit_root_test_adf", "unit_root_test_kpss", "unit_root_test_za", "worksheet_root", "bivariate_box_plot", "bivariate_frequency_table", "bivariate_header", "bivariate_histogram", "bivariate_summary", "mosaic_plot", "scatter_plot", "column_card", "categorical_histogram", "numerical_histogram", "quantile_table", "univariate_frequency_table", "univariate_header", "univariate_summary", ] as const;
    
    export function isACFPlotCard(obj: Card): obj is ACFPlotCard { return obj["type"] == "acf_plot"; }
    export function isBivariateCard(obj: Card): obj is BivariateCard { return ["chi2_independence_test", "fit_2d_distribution", "fit_curve", "ks_test_2samp", "mood_test_2samp", "mood_nsamp", "oneway_anova", "pairwise_mood", "pairwise_ttest", "ttest_2samp", "bivariate_box_plot", "bivariate_frequency_table", "bivariate_histogram", "bivariate_summary", "mosaic_plot", "scatter_plot", ].includes(obj["type"]); }
    export function isCDFPlotCard(obj: Card): obj is CDFPlotCard { return obj["type"] == "cdf_plot"; }
    export function isCard(obj: Card): obj is Card { return ["acf_plot", "cdf_plot", "chi2_independence_test", "correlation_matrix", "durbin_watson", "fit_2d_distribution", "fit_curve", "fit_distribution", "ks_test_2samp", "mann_kendall_test", "mood_test_2samp", "mood_nsamp", "oneway_anova", "pca", "pairwise_mood", "pairwise_ttest", "parallel_coordinates_plot", "playground", "stl_decomposition", "scatter_plot_3d", "shapiro", "sign_test_1samp", "ttest_2samp", "ttest_ztest_1samp", "unit_root_test_adf", "unit_root_test_kpss", "unit_root_test_za", "worksheet_root", "bivariate_box_plot", "bivariate_frequency_table", "bivariate_header", "bivariate_histogram", "bivariate_summary", "mosaic_plot", "scatter_plot", "column_card", "categorical_histogram", "numerical_histogram", "quantile_table", "univariate_frequency_table", "univariate_header", "univariate_summary", ].includes(obj["type"]); }
    export function isChi2IndTestCard(obj: Card): obj is Chi2IndTestCard { return obj["type"] == "chi2_independence_test"; }
    export function isCorrelationMatrixCard(obj: Card): obj is CorrelationMatrixCard { return obj["type"] == "correlation_matrix"; }
    export function isDurbinWatsonCard(obj: Card): obj is DurbinWatsonCard { return obj["type"] == "durbin_watson"; }
    export function isFit2DDistributionCard(obj: Card): obj is Fit2DDistributionCard { return obj["type"] == "fit_2d_distribution"; }
    export function isFitCurveCard(obj: Card): obj is FitCurveCard { return obj["type"] == "fit_curve"; }
    export function isFitDistributionCard(obj: Card): obj is FitDistributionCard { return obj["type"] == "fit_distribution"; }
    export function isKsTest2SampCard(obj: Card): obj is KsTest2SampCard { return obj["type"] == "ks_test_2samp"; }
    export function isMannKendallTestCard(obj: Card): obj is MannKendallTestCard { return obj["type"] == "mann_kendall_test"; }
    export function isMoodTest2SampCard(obj: Card): obj is MoodTest2SampCard { return obj["type"] == "mood_test_2samp"; }
    export function isMoodTestNSampCard(obj: Card): obj is MoodTestNSampCard { return obj["type"] == "mood_nsamp"; }
    export function isMultivariateCard(obj: Card): obj is MultivariateCard { return ["correlation_matrix", "pca", "parallel_coordinates_plot", ].includes(obj["type"]); }
    export function isOneWayANOVACard(obj: Card): obj is OneWayANOVACard { return obj["type"] == "oneway_anova"; }
    export function isPCACard(obj: Card): obj is PCACard { return obj["type"] == "pca"; }
    export function isPairwiseMoodTestCard(obj: Card): obj is PairwiseMoodTestCard { return obj["type"] == "pairwise_mood"; }
    export function isPairwiseTTestCard(obj: Card): obj is PairwiseTTestCard { return obj["type"] == "pairwise_ttest"; }
    export function isParallelCoordinatesPlotCard(obj: Card): obj is ParallelCoordinatesPlotCard { return obj["type"] == "parallel_coordinates_plot"; }
    export function isPlaygroundCard(obj: Card): obj is PlaygroundCard { return obj["type"] == "playground"; }
    export function isSTLDecompositionCard(obj: Card): obj is STLDecompositionCard { return obj["type"] == "stl_decomposition"; }
    export function isScatterPlot3DCard(obj: Card): obj is ScatterPlot3DCard { return obj["type"] == "scatter_plot_3d"; }
    export function isShapiroNormalityTestCard(obj: Card): obj is ShapiroNormalityTestCard { return obj["type"] == "shapiro"; }
    export function isSignTest1SampCard(obj: Card): obj is SignTest1SampCard { return obj["type"] == "sign_test_1samp"; }
    export function isTTest2SampCard(obj: Card): obj is TTest2SampCard { return obj["type"] == "ttest_2samp"; }
    export function isTZTest1SampCard(obj: Card): obj is TZTest1SampCard { return obj["type"] == "ttest_ztest_1samp"; }
    export function isTimeSeriesCard(obj: Card): obj is TimeSeriesCard { return ["acf_plot", "durbin_watson", "mann_kendall_test", "stl_decomposition", "unit_root_test_adf", "unit_root_test_kpss", "unit_root_test_za", ].includes(obj["type"]); }
    export function isTrivariateCard(obj: Card): obj is TrivariateCard { return ["scatter_plot_3d", ].includes(obj["type"]); }
    export function isUnitRootTestADFCard(obj: Card): obj is UnitRootTestADFCard { return obj["type"] == "unit_root_test_adf"; }
    export function isUnitRootTestCard(obj: Card): obj is UnitRootTestCard { return ["unit_root_test_adf", "unit_root_test_kpss", "unit_root_test_za", ].includes(obj["type"]); }
    export function isUnitRootTestKPSSCard(obj: Card): obj is UnitRootTestKPSSCard { return obj["type"] == "unit_root_test_kpss"; }
    export function isUnitRootTestZACard(obj: Card): obj is UnitRootTestZACard { return obj["type"] == "unit_root_test_za"; }
    export function isUnivariateCard(obj: Card): obj is UnivariateCard { return ["cdf_plot", "fit_distribution", "shapiro", "sign_test_1samp", "ttest_ztest_1samp", "categorical_histogram", "numerical_histogram", "quantile_table", "univariate_frequency_table", "univariate_summary", ].includes(obj["type"]); }
    export function isWorksheetRootCard(obj: Card): obj is WorksheetRootCard { return obj["type"] == "worksheet_root"; }
    export function isAbstract2DPivotCard(obj: Card): obj is Abstract2DPivotCard { return ["bivariate_frequency_table", "bivariate_histogram", "mosaic_plot", ].includes(obj["type"]); }
    export function isBivariateBoxPlotCard(obj: Card): obj is BivariateBoxPlotCard { return obj["type"] == "bivariate_box_plot"; }
    export function isBivariateFrequencyTableCard(obj: Card): obj is BivariateFrequencyTableCard { return obj["type"] == "bivariate_frequency_table"; }
    export function isBivariateHeaderCard(obj: Card): obj is BivariateHeaderCard { return obj["type"] == "bivariate_header"; }
    export function isBivariateHistogramCard(obj: Card): obj is BivariateHistogramCard { return obj["type"] == "bivariate_histogram"; }
    export function isBivariateSummaryStatsCard(obj: Card): obj is BivariateSummaryStatsCard { return obj["type"] == "bivariate_summary"; }
    export function isMosaicPlotCard(obj: Card): obj is MosaicPlotCard { return obj["type"] == "mosaic_plot"; }
    export function isScatterPlotCard(obj: Card): obj is ScatterPlotCard { return obj["type"] == "scatter_plot"; }
    export function isAbstract2SampTestCard(obj: Card): obj is Abstract2SampTestCard { return ["ks_test_2samp", "mood_test_2samp", "ttest_2samp", ].includes(obj["type"]); }
    export function isAbstractCardContainer(obj: Card): obj is AbstractCardContainer { return ["worksheet_root", "bivariate_header", "column_card", "univariate_header", ].includes(obj["type"]); }
    export function isAbstractHeaderCard(obj: Card): obj is AbstractHeaderCard { return ["bivariate_header", "univariate_header", ].includes(obj["type"]); }
    export function isAbstractNSampTestCard(obj: Card): obj is AbstractNSampTestCard { return ["mood_nsamp", "oneway_anova", "pairwise_mood", "pairwise_ttest", ].includes(obj["type"]); }
    export function isAbstractPairwiseTestCard(obj: Card): obj is AbstractPairwiseTestCard { return ["pairwise_mood", "pairwise_ttest", ].includes(obj["type"]); }
    export function isColumnCard(obj: Card): obj is ColumnCard { return obj["type"] == "column_card"; }
    export function isCategoricalHistogramCard(obj: Card): obj is CategoricalHistogramCard { return obj["type"] == "categorical_histogram"; }
    export function isNumericalHistogramCard(obj: Card): obj is NumericalHistogramCard { return obj["type"] == "numerical_histogram"; }
    export function isQuantilesTableCard(obj: Card): obj is QuantilesTableCard { return obj["type"] == "quantile_table"; }
    export function isUnivariateFrequencyTableCard(obj: Card): obj is UnivariateFrequencyTableCard { return obj["type"] == "univariate_frequency_table"; }
    export function isUnivariateHeaderCard(obj: Card): obj is UnivariateHeaderCard { return obj["type"] == "univariate_header"; }
    export function isUnivariateSummaryStatsCard(obj: Card): obj is UnivariateSummaryStatsCard { return obj["type"] == "univariate_summary"; }
    export function isCardWithConfidenceInterval(obj: Card): obj is CardWithConfidenceInterval & Card { return ["worksheet_root", "bivariate_summary", "quantile_table", "univariate_summary", ].includes(obj["type"]); }
    export function isCardWithConfidenceLevel(obj: Card): obj is CardWithConfidenceLevel & Card { return ["acf_plot", "chi2_independence_test", "fit_distribution", "mann_kendall_test", "shapiro", "sign_test_1samp", "ttest_ztest_1samp", "worksheet_root", "bivariate_summary", "quantile_table", "univariate_summary", ].includes(obj["type"]); }
}