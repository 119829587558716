import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.StdDev
 * Via: UIModel annotation in the class hierarchy
 */
export interface _StdDev extends Computation._UnivariateComputation {
    type: "std_dev";
}

export type StdDev = _StdDev;

export namespace StdDev {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.StdDev in PolyJSON mappings */
    export const type = "std_dev";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.StdDev$StdDevResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _StdDevResult extends _AvailableResult {
        type: "std_dev";
        value: number;
    }
    
    export type StdDevResult = _StdDevResult;

    export namespace StdDevResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.StdDev$StdDevResult in PolyJSON mappings */
        export const type = "std_dev";
    }
}