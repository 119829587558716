import { AxisDef } from '@model-main/pivot/backend/model/axis-def';
import { AxisSortPrune } from '@model-main/pivot/backend/model/axis-sort-prune';
import { PivotTableResponse } from '@model-main/pivot/backend/model/pivot-table-response';
import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { SamplingParam } from '@model-main/datasets/sampling-param';

export const pivotTableTensorResponseBars100WithColors: PivotTableTensorResponse = {
    axisLabels: [
        [
            {
                label: 'White T-Shirt F'
            },
            {
                label: 'White T-Shirt M'
            },
            {
                label: 'Hoodie'
            },
            {
                label: 'Black T-Shirt M'
            },
            {
                label: 'Black T-Shirt F'
            },
            {
                label: 'Tennis Shirt'
            }
        ],
        [
            {
                label: 'M'
            },
            {
                label: 'F'
            },
            {
                label: '___dku_no_value___'
            }
        ]
    ] as Array<any>,
    axisDefs: [
        {
            column: 'tshirt_category',
            type: AxisDef.Type.ALPHANUM,
            sortPrune: {
                sortType: AxisSortPrune.SortType.AGGREGATION,
                sortAscending: false,
                aggregationSortId: 0,
                generateOthersCategory: true,
                forceLastPositionOthers: true,
                filters: [],
                maxValues: 20
            }
        },
        {
            column: 'gender',
            type: AxisDef.Type.ALPHANUM,
            sortPrune: {
                sortType: AxisSortPrune.SortType.AGGREGATION,
                sortAscending: false,
                aggregationSortId: 0,
                generateOthersCategory: true,
                forceLastPositionOthers: true,
                filters: [],
                maxValues: 20
            }
        }
    ] as Array<any>,
    sequenceId: 0,
    aggregations: [
        {
            numAxes: 2,
            axisLengths: [
                7,
                4
            ],
            tensor: [
                10.713286713286713,
                10.942196531791907,
                10.924376508447304,
                10.886971527178602,
                10.9296875,
                10.737100737100738,
                0,
                11.048582995951417,
                10.86201550387597,
                10.890840652446675,
                10.71531100478469,
                10.790652385589095,
                10.804407713498623,
                0,
                10.74496644295302,
                11.146153846153846,
                10.676595744680851,
                10.552238805970148,
                10.525,
                10.30232558139535,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            axes: [
                [
                    10.987557301899148,
                    10.942769768457842,
                    10.887032967032967,
                    10.808773903262093,
                    10.779607843137255,
                    10.72196261682243,
                    0
                ],
                [
                    10.896505376344086,
                    10.883835005574136,
                    10.730732635585158,
                    0
                ]
            ],
            multipliers: [
                1,
                7
            ]
        },
        {
            numAxes: 2,
            axisLengths: [
                7,
                4
            ],
            tensor: [
                43,
                53.395953757225435,
                60.46178600160901,
                50.32786885245902,
                45.1171875,
                80.13759213759214,
                0,
                45.26720647773279,
                53.42635658914729,
                58.626097867001256,
                46,
                48.18889970788705,
                69.84573002754821,
                0,
                44.31543624161074,
                63.53846153846154,
                66.62553191489361,
                53.124378109452735,
                45.208333333333336,
                80.09302325581395,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            axes: [
                [
                    44.962017026850035,
                    54.5565749235474,
                    60.45538461538462,
                    49.62654668166479,
                    47.6,
                    75.76869158878505,
                    0
                ],
                [
                    56.43458781362007,
                    51.54113712374582,
                    58.773549000951476,
                    0
                ]
            ],
            multipliers: [
                1,
                7
            ]
        }
    ] as Array<any>,
    counts: {
        numAxes: 2,
        axisLengths: [
            7,
            4
        ],
        tensor: [
            143,
            1384,
            1243,
            1159,
            128,
            407,
            0,
            1235,
            645,
            797,
            418,
            1027,
            363,
            0,
            149,
            260,
            235,
            201,
            120,
            86,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        axes: [
            [
                1527,
                2289,
                2275,
                1778,
                1275,
                856,
                0
            ],
            [
                4464,
                4485,
                1051,
                0
            ]
        ],
        multipliers: [
            1,
            7
        ]
    },
    filterFacets: [],
    beforeFilterRecords: 10000,
    afterFilterRecords: 10000,
    engine: PivotTableResponse.PivotEngine.LINO,
    sampleMetadata: {
        samplingMethod: SamplingParam.SamplingMethod.HEAD_SEQUENTIAL,
        maxRecords: 10000,
        targetRatio: 0.02,
        hasFilter: false,
        partitionCount: 0,
        sampleIsWholeDataset: false,
        sampleRecordCount: -1,
        datasetRecordCount: -1,
        recordCountIsApproximate: false,
        recordCountIsObsolete: false,
        memoryLimitInMB: 150,
        memoryLimitReached: false
    },
    columnsSummary: undefined as any
};
