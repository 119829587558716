import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNumber } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    tap,
} from 'rxjs/operators';
import { ColorUtilsService } from '../../../services';

@UntilDestroy()
@Component({
    selector: 'chart-color-picker-alpha',
    templateUrl: './chart-color-picker-alpha.component.html',
    styleUrls: ['./chart-color-picker-alpha.component.less']
})
export class ChartColorPickerAlphaComponent implements AfterViewInit {
    color$ = new BehaviorSubject<string | null>(null);
    sliderColor$ = new BehaviorSubject<string | null>(null);
    markerColor$ = new BehaviorSubject<string | null>(null);
    position$ = new BehaviorSubject('0px');
    alpha = new UntypedFormControl(100);

    @Input()
    set color(value: string | null) {
        if (value) {
            this.color$.next(this.getColor(value));
        }
    }

    @Output() colorChange = new EventEmitter<string>();

    @ViewChild('slider') slider: ElementRef;

    constructor(private colorUtilsService: ColorUtilsService) {
        this.color$
            .pipe(
                untilDestroyed(this),
                filter((color): color is string => !!color),
                distinctUntilChanged()
            ).subscribe((color: string) => {
                const rgba = this.colorUtilsService.strToRGBA(color);

                const alpha = rgba.a;

                if (alpha < 1) {
                    this.alpha.patchValue(alpha * 100);
                } else {
                    rgba.a = this.alpha.value / 100;
                }

                this.sliderColor$.next(this.colorUtilsService.RGBAToHex(rgba, false).toUpperCase());

                const alphaHex = this.colorUtilsService.RGBAToHex(rgba, true).toUpperCase();
                this.markerColor$.next(alphaHex);
                this.colorChange.next(alphaHex)
            });
    }

    ngAfterViewInit() {
        this.alpha.valueChanges
            .pipe(
                untilDestroyed(this),
                distinctUntilChanged(),
                map((alpha: number) => {
                    const currentColor = this.color$.getValue();
                    return { 
                        alpha,
                        currentColor: currentColor ? this.getColor(currentColor, alpha) : null
                    }
                }),
                tap(({ alpha }) => this.position$.next(this.getPosition(this.slider.nativeElement, alpha))),
                debounceTime(500),
                tap(({ currentColor }) => currentColor && this.color$.next(currentColor))
            )
            .subscribe();

        setTimeout(() => {
            this.alpha.updateValueAndValidity();
        });
    }

    getPosition(slider: HTMLElement, value: number): string {
        return `${slider.clientWidth * (value / 100)}px`;
    }

    getColor(hex: string, alpha?: number): string {
        const rgba = this.colorUtilsService.strToRGBA(hex);

        if (isNumber(alpha)) {
            rgba.a = alpha / 100;
        }

        return this.colorUtilsService.RGBAToHex(rgba, true).toUpperCase();
    }
}

