import { Component, ChangeDetectionStrategy, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { isNil } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ChartZoomControlTypes } from '../../enums';
import { ChartZoomControlInstance } from '../../models';
import { ChartZoomControlAdapterService } from '../../services';

@Component({
    selector: 'chart-zoom-control-panel',
    templateUrl: './chart-zoom-control-panel.component.html',
    styleUrls: ['./chart-zoom-control-panel.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartZoomControlPanelComponent implements OnInit, OnDestroy {
    public buttons: Array<any> = [];

    private subscriptions: Array<Subscription> = [];
    private zoomControlInstance: ChartZoomControlInstance | null = null;

    private canZoomIn$ = new BehaviorSubject<boolean>(true);
    private canZoomOut$ = new BehaviorSubject<boolean>(true);
    private canResetZoom$ = new BehaviorSubject<boolean>(true);
    private hasResetButton$ = new BehaviorSubject<boolean>(true);

    constructor(
        private chartZoomControlService: ChartZoomControlAdapterService,
        private cd: ChangeDetectorRef
    ) {
    }

    @Input()
    set zoomControlInstanceId(value: string) {
        if (isNil(value)) {
            this.zoomControlInstance = null;
        } else {
            this.zoomControlInstance = this.chartZoomControlService.get(value);

            if (!isNil(this.zoomControlInstance)) {
                this.unsubscribeAll();

                this.subscriptions.push(this.zoomControlInstance.canZoomIn$?.subscribe(canZoomIn => {
                    this.canZoomIn$.next(canZoomIn);
                    this.cd.detectChanges();
                }));

                this.subscriptions.push(this.zoomControlInstance.canZoomOut$?.subscribe(canZoomOut => {
                    this.canZoomOut$.next(canZoomOut);
                    this.cd.detectChanges();
                }));

                this.subscriptions.push(this.zoomControlInstance.canResetZoom$?.subscribe(canResetZoom => {
                    this.canResetZoom$.next(canResetZoom);
                    this.cd.detectChanges();
                }));

                this.subscriptions.push(this.zoomControlInstance.hasResetButton$?.subscribe(hasResetButton => {
                    this.hasResetButton$.next(hasResetButton);
                    this.cd.detectChanges();
                }));
            }
        }
    }

    ngOnInit(): void {
        this.buttons = [
            { title: 'Zoom out', action: this.zoomOutAction, icon: 'dku-icon-zoom-out-16', class: 'btn btn--secondary btn--icon', enabled$: this.canZoomOut$ },
            { title: 'Zoom in', action: this.zoomInAction, icon: 'dku-icon-zoom-in-16', class: 'btn btn--secondary btn--icon', enabled$: this.canZoomIn$ },
            { title: 'Reset view', action: this.resetZoomAction, icon: 'medium dku-icon-arrow-undo-16', class: 'btn btn--secondary btn--icon mtop8', enabled$: this.canResetZoom$, show$: this.hasResetButton$ }
        ];
    }

    ngOnDestroy(): void {
        this.unsubscribeAll();
    }

    zoomIn() {
        this.zoomControlInstance?.zoomIn();
    }

    zoomOut() {
        this.zoomControlInstance?.zoomOut();
    }

    resetZoom() {
        this.zoomControlInstance?.resetZoom();
    }

    blur(event: Event) {
        (event.target as HTMLButtonElement).blur();
    }

    private unsubscribeAll() {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(sub => sub.unsubscribe());
            this.subscriptions = [];
        }
    }

    private zoomInAction = (event: MouseEvent) => {
        event.stopPropagation();
        this.zoomIn();
    };

    private zoomOutAction = (event: MouseEvent) => {
        event.stopPropagation();
        this.zoomOut();
    };

    private resetZoomAction = (event: MouseEvent) => {
        event.stopPropagation();
        this.resetZoom();
    };
}
