/** 
 * Translated from class com.dataiku.dip.containers.exec.ContainerExecSelection
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ContainerExecSelection {
    containerConf?: string | null;
    containerMode: ContainerExecSelection.ContainerExecMode;
}

export type ContainerExecSelection = _ContainerExecSelection;

export namespace ContainerExecSelection {
    /** 
     * Translated from enum com.dataiku.dip.containers.exec.ContainerExecSelection$ContainerExecMode
     * Via: com.dataiku.dip.containers.exec.ContainerExecSelection
     */
    export enum ContainerExecMode {
        INHERIT = "INHERIT",
        EXPLICIT_CONTAINER = "EXPLICIT_CONTAINER",
        NONE = "NONE",
    }
}