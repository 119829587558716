import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { Grouping } from './../../grouping/grouping';
import type { _AvailableResult } from './../available-result';
import type { _Computation } from './../computation';
import type { _Grouping } from './../../grouping/grouping';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.Chi2IndTest
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Chi2IndTest extends _Computation {
    type: "chi2_ind_test";
    xGrouping: Grouping;
    yGrouping: Grouping;
}

export type Chi2IndTest = _Chi2IndTest;

export namespace Chi2IndTest {
    /** Type of class com.dataiku.dip.eda.compute.computations.bivariate.Chi2IndTest in PolyJSON mappings */
    export const type = "chi2_ind_test";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.Chi2IndTest$Chi2IndTestResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _Chi2IndTestResult extends _AvailableResult {
        type: "chi2_ind_test";
        dof: number;
        pvalue: number;
        statistic: number;
    }
    
    export type Chi2IndTestResult = _Chi2IndTestResult;

    export namespace Chi2IndTestResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.bivariate.Chi2IndTest$Chi2IndTestResult in PolyJSON mappings */
        export const type = "chi2_ind_test";
    }
}