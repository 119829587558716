import type { GroupProfile } from './../auth/group-profile';
import type { UserRemappingRule } from './../auth/user-remapping-rule';
import type { _GroupProfile } from './../auth/group-profile';
import type { _UserRemappingRule } from './../auth/user-remapping-rule';

/** 
 * Translated from class com.dataiku.dip.security.azure.AzureADSettings
 * Via: UIModel annotation in the class hierarchy
 */
export interface _AzureADSettings {
    allowOnDemandUsersProvisioning: boolean;
    allowOnDemandUsersSync: boolean;
    authorizedGroups: string;
    autoProvisionUsersAtLoginTime: boolean;
    autoSyncUsersAtLoginTime: boolean;
    credentialMethod: AzureADSettings.CredentialMethod;
    credentialsCertificatePassword: string;
    credentialsCertificatePath: string;
    credentialsClientId: string;
    credentialsClientSecret: string;
    credentialsTenantId: string;
    defaultUserProfile: string;
    enabled: boolean;
    groupProfiles: GroupProfile[];
    loginAttributeRef: AzureADSettings.LoginAttribute;
    order?: number | null;
    remappingRules: UserRemappingRule[];
    trustAllCerts: boolean;
    useGlobalProxy: boolean;
    userQueryFilter: string;
}

export type AzureADSettings = _AzureADSettings;

export namespace AzureADSettings {
    /** 
     * Translated from enum com.dataiku.dip.security.azure.AzureADSettings$CredentialMethod
     * Via: com.dataiku.dip.security.azure.AzureADSettings
     */
    export enum CredentialMethod {
        OAUTH2_SECRET = "OAUTH2_SECRET",
        OAUTH2_CERT = "OAUTH2_CERT",
    }
    /** 
     * Translated from enum com.dataiku.dip.security.azure.AzureADSettings$LoginAttribute
     * Via: com.dataiku.dip.security.azure.AzureADSettings
     */
    export enum LoginAttribute {
        EMAIL = "EMAIL",
        USER_PRINCIPAL_NAME = "USER_PRINCIPAL_NAME",
        DISPLAY_NAME = "DISPLAY_NAME",
    }
}