import { Inject, Injectable } from '@angular/core';
import { ChartType } from '@model-main/pivot/frontend/model/chart-type';
import { ChartTensorDataWrapper } from '../../models';
import { ChartCoordinates, FrontendChartDef } from '../../interfaces';

@Injectable({
    providedIn: 'root'
})
export class EChartsTooltipsService {
    constructor(
        @Inject('Logger') private logger: any,
        @Inject('ChartTooltips') private chartTooltipsService: any
    ) { }


    getTooltipOptions() {
        // Handled globally with legacy tooltips for now
        return {
            show: false
        };
    }

    hideTooltip(tooltips: any) {
        tooltips.resetColors();
        tooltips.unfix();
    }

    hideTooltipIfNotFixed(tooltips: any) {
        if (!tooltips.fixed) {
            this.hideTooltip(tooltips);
        }
    }

    setupTooltips(echartInstance: any, tooltips: any, coordinates: Array<Array<ChartCoordinates>>, legend: any, tooltipIgnoredSeries: string[]) {
        echartInstance.on('mousemove', (params: any) => {
            const chartType = params.componentSubType;
            /*
             * fix for a bug from echarts: the background of tree node also triggers a mouseover event and the params have dataIndex 0
             * https://github.com/apache/echarts/issues/11377
             */
            if (tooltipIgnoredSeries.includes(params.seriesId) ||
                (chartType === ChartType.treemap && params.dataIndex === 0)) {
                return;
            }

            const animationContext = tooltips.getAnimationContext();
            const coordIndex = (coordinates.length / animationContext.numberOfFrames) * animationContext.animation + params.seriesIndex;
            const coords = chartType === ChartType.treemap ? params.data.coord : { ...coordinates[coordIndex][params.dataIndex] };
            let color;
            if (legend && legend.type === 'COLOR_DISCRETE') {
                const c = coords.color + coords.measure;
                color = legend.items[c].rgbaColor || legend.items[c].color;
            } else {
                color = coords.color;
            }

            tooltips.showForCoords(coords.measure, coords, params.event.event, color);
        });

        echartInstance.on('mouseout', () => {
            if (!tooltips.fixed) {
                tooltips.hide();
            }
        });

        try {
            echartInstance.getZr().on('click', (event: any) => {
                if (!event.target) {
                    this.hideTooltip(tooltips);
                } else {
                    tooltips.fix();
                }
            });
        } catch (e) {
            this.logger({ serviceName: 'EChartsTooltips', objectName: 'Service' }).error('on click', e);
        }

        echartInstance.on('globalout', () => this.hideTooltipIfNotFixed(tooltips));
    }

    createTooltips($container: any, chartHandler: any, chartData: ChartTensorDataWrapper, chartDef: FrontendChartDef, measureFormatters: Array<any>, colorScale: (color: any) => string) {
        const tooltips = this.chartTooltipsService.create($container, chartHandler, chartData, chartDef, measureFormatters, colorScale);
        return tooltips;
    }
}
