import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { fairAny } from 'dku-frontend-core';
import { cloneDeep, isEqual, uniqueId } from 'lodash';
import { ChartColumnTypeUtilsService, ChartFilterUtilsService, NumberFormatterService } from '.';
import { AxisDef } from '@model-main/pivot/backend/model/axis-def';
import { ChartFilter } from '@model-main/pivot/frontend/model/chart-filter';
import { ColumnSummary } from '@model-main/pivot/backend/model/column-summary';
import { FilterFacet } from '@model-main/pivot/backend/model/filter-facet';
import { PivotTableResponse } from '@model-main/pivot/backend/model/pivot-table-response';
import { ChartsOnDatasetDataSpec } from '@model-main/shaker/model/charts-on-dataset-data-spec';
import { DateUtilsService } from '@shared/services';
import { FacetUiState, FilterTmpData, AlphanumericalFilterTmpDataValue, FrontendChartFilter, FrontendChartFilterTypeProperties, DateRangeFilterTmpDataValues, NumericalFilterTmpDataValues, AlphanumericalFilterTmpDataValues, DatePartFilterTmpDataValues, FilterTmpDataValues, RelativeDateFilterTmpDataValues } from '../models';

type PivotRequestErrorCallback = (data: Record<string, unknown> | null, status: number, headers: unknown, config?: unknown, statusText?: string) => void;
export interface GetPivotRequestOptions {
    projectKey: string;
    dataSpec: ChartsOnDatasetDataSpec;
    requestedSampleId: string;
    onError: PivotRequestErrorCallback;
    visualAnalysisFullModelId?: string;
}

type AlphanumFilterSelectionType = ChartFilter.FilterSelectionType.MULTI_SELECT | ChartFilter.FilterSelectionType.SINGLE_SELECT;

@Injectable({
    providedIn: 'root'
})
/**
 * Everything about mapping 'ChartFilter.java' to 'filter-row.html' and vice-versa.
 * (!) This service previously was in static/dataiku/js/simple_report/services/chart-filters.service.js
 */
export class ChartFiltersService {
    private readonly AVAILABLE_RELATIVE_DATE_FILTER_DATE_PARTS = new Set(this.chartFilterUtilsService.getDateRelativeFilterParts().map(([value]) => value));
    private readonly logger: { error: (msg: string) => void };

    constructor(
        private readonly chartFilterUtilsService: ChartFilterUtilsService,
        private readonly chartColumnTypeUtilsService: ChartColumnTypeUtilsService,
        private readonly numberFormatterService: NumberFormatterService,
        private readonly dateUtilsService: DateUtilsService,
        @Inject(LOCALE_ID) private readonly locale: string,
        @Inject('ChartDataUtils') private readonly chartDataUtilsService: fairAny,
        @Inject('$location') private readonly $location: fairAny,
        @Inject('ChartRequestComputer') private readonly chartRequestComputer: fairAny,
        @Inject('FilterFacetsService') private readonly filterFacetsService: fairAny,
        @Inject('Logger') private loggerFactory: fairAny
    ) {
        this.logger = this.loggerFactory({ serviceName: 'ChartFilters', objectName: 'Service' });
    }

    canClearFilter(filterTmpData: FilterTmpData): boolean {
        return this.hasChangedFacetSelectionType(filterTmpData) || this.hasChangedExcludeOtherValuesOption(filterTmpData) || this.hasChangedFacetValues(filterTmpData);
    }

    getAllFiltersSummary(filters: FrontendChartFilter[]): string {
        const filtersCount = filters.length;
        let deactivatedFiltersCount = 0;

        filters && filters.forEach(filter => {
            if (!filter.active) {
                deactivatedFiltersCount++;
            }
        });

        if (deactivatedFiltersCount === 0) {
            return this.getDefaultSummary(filtersCount);
        }

        return this.getSummaryForSomeDeactivatedFilters(filtersCount, deactivatedFiltersCount);
    }

    clearFacet(filterTmpData: FilterTmpData): FilterTmpData {
        const newFilterTmpData = cloneDeep(filterTmpData);
        if (this.chartColumnTypeUtilsService.isNumericalColumnType(newFilterTmpData.columnType) || this.chartColumnTypeUtilsService.isDateColumnType(newFilterTmpData.columnType)) {
            this.switchFilterSelectionTypeToRange(newFilterTmpData);
            if (newFilterTmpData.minValue !== undefined) {
                newFilterTmpData.minValue = null;
            }
            if (newFilterTmpData.maxValue !== undefined) {
                newFilterTmpData.maxValue = null;
            }
            if (newFilterTmpData.timezone !== undefined) {
                newFilterTmpData.timezone = 'UTC';
            }
            newFilterTmpData.excludeOtherValues = this.getDefaultExcludeOtherValues(newFilterTmpData.isAGlobalFilter, newFilterTmpData?.values);
        } else if (this.chartColumnTypeUtilsService.isAlphanumColumnType(newFilterTmpData.columnType)) {
            const defaultExcludeOtherValues = this.getDefaultExcludeOtherValues(newFilterTmpData.isAGlobalFilter, newFilterTmpData?.values);
            this.switchFilterSelectionTypeToAlphanum(newFilterTmpData, defaultExcludeOtherValues, ChartFilter.FilterSelectionType.MULTI_SELECT);
            newFilterTmpData.values = newFilterTmpData.values?.map(value => ({ ...value, included: true }));
        } else {
            this.logger.error('Unsupported filter encountered while clearing facet');
        }

        return newFilterTmpData;
    }

    clearAllFacets(filtersTmpData: FilterTmpData[]): FilterTmpData[] {
        return filtersTmpData.map((filterTmpData) => this.clearFacet(filterTmpData));
    }
    /**
     * Initializes filterTmpData (the displayed filter) from pivot-response and current filters data.
     * @param responseData            - Filters available from pivot-response for a given source dataset (computed data)
     * @param filter                  - Filter (data to store)
     * @param productShortName
     * @param engine
     * @param overrideWithResponse    - Override filterTmpData min and max values from the response (optional)
     *
     * @return filter display data (temporary data used only in view)
     */
    getFilterTmpData(responseFacet: FilterFacet, filter: FrontendChartFilter, overrideWithResponse = false): FilterTmpData {
        delete filter.$isFromUrlQuery;

        let filterTmpData: FilterTmpData = {
            column: filter.column,
            active: filter.active === undefined ? true : filter.active,
            excludeOtherValues: this.computeExcludeOtherValues(filter, responseFacet),
            allValuesInSample: filter.allValuesInSample === undefined ? false : filter.allValuesInSample,
            isAGlobalFilter: filter.isAGlobalFilter,
            filterType: filter.filterType,
            columnType: filter.columnType,
            response: responseFacet,
            $isFromUrlQuery: filter.$isFromUrlQuery,
            id: filter.id != null ? filter.id : this.computeFilterId(),
            filterSelectionType: filter.filterSelectionType
        };

        if (this.chartFilterUtilsService.isExplicitFilter(filter)) {
            filterTmpData.explicitConditions = filter.explicitConditions;
            filterTmpData.explicitExclude = filter.explicitExclude;
        } else {
            filterTmpData = {
                ...filterTmpData,
                ...this.getFilterTmpDataValues(responseFacet, filter, overrideWithResponse)
            };
        }

        return filterTmpData;
    }

    getWarningMessage(responseFacet: FilterFacet, productShortName: string, engine: PivotTableResponse.PivotEngine): string | null {
        return responseFacet.isTruncated ? `Facet values are incomplete (${this.getFriendlyNameForEngine(engine, productShortName)} engine limit reached)` : null;
    }

    convertFilterTmpDataToPivotFilter(filterTmpData: FilterTmpData): FrontendChartFilter {
        const pivotFilter: FrontendChartFilter = {
            active: filterTmpData.active,
            isA: 'filter',
            column: filterTmpData.column,
            columnType: filterTmpData.columnType,
            filterType: filterTmpData.filterType,
            filterSelectionType: filterTmpData.filterSelectionType,
            isAGlobalFilter: filterTmpData.isAGlobalFilter,
            allValuesInSample: filterTmpData.allValuesInSample,
            excludeOtherValues: filterTmpData.excludeOtherValues,
            id: filterTmpData.id != null ? filterTmpData.id : this.computeFilterId()
        };

        if (this.chartFilterUtilsService.isExplicitFilter(pivotFilter)) {
            pivotFilter.explicitConditions = filterTmpData.explicitConditions;
            pivotFilter.explicitExclude = filterTmpData.explicitExclude;
        }
        if (this.chartColumnTypeUtilsService.isDateColumnType(pivotFilter.columnType)) {
            pivotFilter.dateFilterType = filterTmpData.dateFilterType;
            pivotFilter.dateFilterPart = filterTmpData.dateFilterPart;

            switch (pivotFilter.dateFilterType) {
                case ChartFilter.DateFilterType.RELATIVE:
                    pivotFilter.dateFilterOption = filterTmpData.dateFilterOption;
                    pivotFilter.minValue = filterTmpData.minValue;
                    pivotFilter.maxValue = filterTmpData.maxValue;
                    break;
                case ChartFilter.DateFilterType.RANGE:
                    pivotFilter.timezone = filterTmpData.timezone;
                    break;
            }
        }
        if (this.chartFilterUtilsService.isAlphanumericalFilter(pivotFilter)) {
            pivotFilter.excludeOtherValues = filterTmpData.excludeOtherValues;
            delete pivotFilter.minValue;
            delete pivotFilter.maxValue;

            if (filterTmpData.excludeOtherValues) {
                pivotFilter.selectedValues = this.getFacetValues(filterTmpData, true);
                delete pivotFilter.excludedValues;
            } else {
                pivotFilter.excludedValues = this.getFacetValues(filterTmpData, false);
                delete pivotFilter.selectedValues;
            }
            pivotFilter.$totalValuesCount = filterTmpData.values ? filterTmpData.values.length : 0;
        } else if (this.chartFilterUtilsService.isNumericalFilter(pivotFilter)) {
            delete pivotFilter.selectedValues;
            delete pivotFilter.excludedValues;
            pivotFilter.minValue = filterTmpData.minValue !== filterTmpData.response.minValue ? filterTmpData.minValue : null;
            pivotFilter.maxValue = filterTmpData.maxValue !== filterTmpData.response.maxValue ? filterTmpData.maxValue : null;
            pivotFilter.$globalMinValue = filterTmpData.response.globalMinValue;
            pivotFilter.$globalMaxValue = filterTmpData.response.globalMaxValue;
        }

        return pivotFilter;
    }

    /**
     * Updates filterTmpData according to the new selection type.
     * @param newSelectionType          - The new selection type be applied.
     * @param filterTmpData             - The filter on which to apply the new type.
     * @param filters                   - The list of all filters.
     * @param filterIndex               - The index of the filter concerned by the selection type change.
     * @param getPivotResponseOptions   - The options to make the get-pivot-response request.
     */
    switchFilterSelectionType(newSelectionType: ChartFilter.FilterSelectionType, filterTmpData: FilterTmpData, filters: FrontendChartFilter[], filterIndex: number, getPivotResponseOptions: GetPivotRequestOptions): Promise<void> {
        // set the default include/exclude behavior depending on the selection type
        switch (newSelectionType) {
            case ChartFilter.FilterSelectionType.MULTI_SELECT:
                return this.switchFilterSelectionTypeToMultiSelect(filterTmpData, filters, filterIndex, getPivotResponseOptions);
            case ChartFilter.FilterSelectionType.SINGLE_SELECT:
                return this.switchFilterSelectionTypeToSingleSelect(filterTmpData, filters, filterIndex, getPivotResponseOptions);
            case ChartFilter.FilterSelectionType.RANGE_OF_VALUES:
                this.switchFilterSelectionTypeToRange(filterTmpData);
                return Promise.resolve();

        }
    }

    /**
     * Initializes a filter.
     */
    autocompleteFilter(filter: Partial<ChartFilter>, usableColumns: ColumnSummary.UsableColumn[] = [], isAGlobalFilter = false): void {
        const column = usableColumns.find(col => col.column === filter.column);
        let type: AxisDef.Type | undefined = undefined;
        if (column && column.type !== filter.columnType) {
            type = column.type as AxisDef.Type;
            filter.columnType = column.type as AxisDef.Type;
            filter.filterType = filter.filterType || `${column.type}_FACET` as ChartFilter.FilterType;
            if (filter.columnType === 'DATE') {
                filter.dateFilterType = ChartFilter.DateFilterType.RANGE;
            }
        }
        if (filter.id == null) {
            filter.id = this.computeFilterId();
        }
        if (filter.isA !== 'filter') {
            type = filter.columnType || type;
            filter.columnType = type;
            if (type) {
                filter.filterType = filter.filterType || `${type}_FACET` as ChartFilter.FilterType;
            }
            filter.isA = 'filter';
            if (filter.columnType === 'DATE') {
                filter.dateFilterType = ChartFilter.DateFilterType.RANGE;
            }
        }
        if (filter.active === undefined) {
            filter.active = true;
        }
        if (filter.allValuesInSample === undefined) {
            filter.allValuesInSample = false;
        }
        if (filter.isAGlobalFilter === undefined) {
            filter.isAGlobalFilter = isAGlobalFilter;
        }
        if (this.chartFilterUtilsService.isAlphanumericalFilter(filter as FrontendChartFilterTypeProperties)) {
            /*
             * For dashboards, the excludeOtherValues value is dynamic and computed from the facet values.
             * As we don't have the values here, we don't initialise it.
             */
            if (!isAGlobalFilter) {
                filter.excludeOtherValues = filter.excludeOtherValues === undefined ? false : filter.excludeOtherValues;
                if (filter.excludeOtherValues) {
                    filter.selectedValues = filter.selectedValues || null;
                } else {
                    filter.excludedValues = filter.excludedValues || {};
                }
            }
            filter.filterSelectionType = filter.filterSelectionType || ChartFilter.FilterSelectionType.MULTI_SELECT;
        } else if (this.chartFilterUtilsService.isNumericalFilter(filter as FrontendChartFilterTypeProperties)) {
            filter.filterSelectionType = filter.filterSelectionType || ChartFilter.FilterSelectionType.RANGE_OF_VALUES;
            if (filter.minValue === undefined) {
                filter.minValue = null;
            }
            if (filter.maxValue === undefined) {
                filter.maxValue = null;
            }
            if (this.chartColumnTypeUtilsService.isDateColumnType(filter.columnType)) {
                filter.timezone = filter.timezone || 'UTC';
            }
        }
        if (this.chartColumnTypeUtilsService.isDateColumnType(filter.columnType)) {
            filter.dateFilterType = filter.dateFilterType || ChartFilter.DateFilterType.RANGE;
            filter.timezone = filter.timezone || 'UTC';
        }
    }

    /**
     * Builds the request object for a get-pivot-response API call of type filters.
     * @param filters
     * @param requestParams
     * @returns
     */
    buildFiltersRequest(filters: FrontendChartFilter[], requestParams?: Record<string, unknown>): Record<string, unknown> {
        const requestDefinition = {
            type: 'filters',
            filters
        };
        const request = this.chartRequestComputer.compute(requestDefinition);
        for (const [key, value] of Object.entries(requestParams || {})) {
            request[key] = value;
        }

        return request;
    }

    /**
     * Updates filterTmpData according to the new date filter part.
     */
    switchDateFilterPart(dateFilterPart: ChartFilter.DateFilterPart, filterTmpData: FilterTmpData): void {
        // Set the values to null so that the backend returns all the available values for the newly selected date part.
        filterTmpData.values = null;
        filterTmpData.dateFilterPart = dateFilterPart;
    }

    /**
     * Updates filterTmpData according to the new date filter type.
     */
    switchDateFilterType(dateFilterType: ChartFilter.DateFilterType, filterTmpData: FilterTmpData, filters: FrontendChartFilter[], filterIndex: number, getPivotResponseOptions: GetPivotRequestOptions): Promise<void> {
        switch (dateFilterType) {
            case ChartFilter.DateFilterType.RANGE:
                this.switchDateFilterTypeToRange(filterTmpData);
                break;
            case ChartFilter.DateFilterType.RELATIVE:
                this.switchDateFilterTypeToRelativeRange(filterTmpData);
                break;
            case ChartFilter.DateFilterType.PART:
                return this.switchDateFilterTypeToDatePart(filterTmpData, filters, filterIndex, getPivotResponseOptions);
        }
        return Promise.resolve();
    }

    /**
     * Computes the facet UI state from filterTmpData.
     * @param filtersTmpData
     * @returns
     */
    getFacetUiState(filterTmpData: FilterTmpData): FacetUiState {
        const facetUiState: FacetUiState = {};
        const isResponseAlphanumerical = !!filterTmpData.response && !!filterTmpData.response.values.length;

        if (filterTmpData.filterSelectionType === ChartFilter.FilterSelectionType.RANGE_OF_VALUES) {
            const lb = !isResponseAlphanumerical ? filterTmpData.response.minValue : undefined;
            const ub = !isResponseAlphanumerical ? filterTmpData.response.maxValue : undefined;

            facetUiState.sliderLowerBound = lb !== undefined ? lb : facetUiState.sliderLowerBound;
            facetUiState.sliderUpperBound = ub !== undefined ? ub : facetUiState.sliderUpperBound;
            facetUiState.sliderModelMin = filterTmpData.minValue;
            facetUiState.sliderModelMax = filterTmpData.maxValue;

            if (this.chartColumnTypeUtilsService.isNumericalColumnType(filterTmpData.columnType) && facetUiState.sliderModelMax != null && facetUiState.sliderModelMin != null) {
                // 10000 ticks
                let sliderStep = Math.round(10000 * (facetUiState.sliderModelMax - facetUiState.sliderModelMin)) / 100000000;
                // Handle min=max
                sliderStep = sliderStep === 0 ? 1 : sliderStep;

                const sliderDecimals = Math.max(String(sliderStep - Math.floor(sliderStep)).length - 2, 0);

                facetUiState.sliderStep = sliderStep;
                facetUiState.sliderDecimals = sliderDecimals;
            }
        }
        if (filterTmpData.filterType === ChartFilter.FilterType.DATE_FACET) {
            facetUiState.dateFilterType = filterTmpData.dateFilterType;
            if (filterTmpData.dateFilterType === ChartFilter.DateFilterType.RANGE) {
                const response = filterTmpData.response || {};
                const minValue = filterTmpData.minValue != undefined ? filterTmpData.minValue : (!isResponseAlphanumerical ? response.minValue : null);
                const maxValue = filterTmpData.maxValue != undefined ? filterTmpData.maxValue : (!isResponseAlphanumerical ? response.maxValue : null);

                facetUiState.timezoneDateRangeModel = filterTmpData.timezone || 'UTC';
                facetUiState.fromDateRangeModel = minValue !== null ? this.dateUtilsService.convertDateToTimezone(new Date(minValue), facetUiState.timezoneDateRangeModel) : undefined;
                facetUiState.toDateRangeModel = maxValue !== null ? this.dateUtilsService.convertDateToTimezone(new Date(maxValue), facetUiState.timezoneDateRangeModel) : undefined;
            } else {
                facetUiState.dateFilterPart = filterTmpData.dateFilterPart;
            }
        }

        return facetUiState;
    }

    onDateRangeChange(facetUiState: FacetUiState, filterTmpData: FilterTmpData): void {
        if (!filterTmpData) {
            return;
        }

        const from = facetUiState.fromDateRangeModel;
        const to = facetUiState.toDateRangeModel;
        // If a boundary is undefined it means that this boundary is invalid.
        if (from === undefined || to === undefined) {
            return;
        }
        const tz = facetUiState.timezoneDateRangeModel;

        if (tz) {
            filterTmpData.timezone = tz;
            filterTmpData.minValue = from != null ? this.dateUtilsService.convertDateFromTimezone(from, tz).getTime() : null;
            filterTmpData.maxValue = to != null ? this.dateUtilsService.convertDateFromTimezone(to, tz).getTime() : null;
        }
    }

    areFiltersEqual(filter1: FrontendChartFilter, filter2: FrontendChartFilter): boolean {
        if (!filter1 && !filter2) {
            return true;
        }
        if (filter1 != null && !filter2 || filter2 != null && !filter1) {
            return false;
        }
        if (filter1.column !== filter2.column || filter1.filterType !== filter2.filterType || filter1.filterSelectionType !== filter2.filterSelectionType || filter1.allValuesInSample !== filter2.allValuesInSample || filter1.excludeOtherValues !== filter2.excludeOtherValues || filter1.active !== filter2.active) {
            return false;
        }
        switch (filter1.filterType) {
            case ChartFilter.FilterType.DATE_FACET:
                return this.areDatePivotFiltersEqual(filter1, filter2);
            case ChartFilter.FilterType.NUMERICAL_FACET:
                return this.areNumericalPivotFiltersEqual(filter1, filter2);
            case ChartFilter.FilterType.ALPHANUM_FACET:
                return this.areAlphanumericalPivotFiltersEqual(filter1, filter2);
            default:
                this.logger.error(`Unsupported filter encountered while comparing filters: filter type ${filter1.filterType} doesn't exist`);
                return false;
        }
    }

    getFacetSummary(filterTmpData: FilterTmpData) {
        if (this.chartFilterUtilsService.isAlphanumericalFilter(filterTmpData)) {
            if (!filterTmpData.values || filterTmpData.values.length === 0) {
                return 'No value available';
            }
            const valuesCount = Object.values(filterTmpData.values).filter(({ included }) => included).length;
            if (valuesCount === 0) {
                return 'None selected';
            }

            return `${valuesCount} selected`;
        } else if (this.chartFilterUtilsService.isNumericalFilter(filterTmpData)) {
            if (filterTmpData.response.minValue === 0 && filterTmpData.response.maxValue === 0) {
                return 'No value available';
            }

            /*
             * Stored filter values can be out of range compared to new facets values received from response
             * so we always display the minimal range from both
             */
            const areMinAndMaxWithinRange = this.hasWithinRangeMinAndMax(filterTmpData, filterTmpData.response);
            let summaryValues;
            if (areMinAndMaxWithinRange) {
                summaryValues = {
                    minValue: filterTmpData.minValue != null ? filterTmpData.minValue : filterTmpData.response.minValue,
                    maxValue: filterTmpData.maxValue != null ? filterTmpData.maxValue : filterTmpData.response.maxValue
                };
            } else {
                summaryValues = filterTmpData.response;
            }

            let formatter: (value: number) => string;
            if (filterTmpData.columnType === AxisDef.Type.DATE) {
                const dateDisplayUnit = this.chartDataUtilsService.computeDateDisplayUnit(summaryValues.minValue, summaryValues.maxValue);
                formatter = (value: number) => formatDate(value, dateDisplayUnit.dateFilterOption, this.locale, dateDisplayUnit.dateFilterOptionTimezone);
            } else {
                formatter = this.numberFormatterService.getForRange(summaryValues.minValue, summaryValues.maxValue, 2);
            }
            return `${formatter(summaryValues.minValue)} to ${formatter(summaryValues.maxValue)}`;
        } else if (this.chartFilterUtilsService.isRelativeDateFilter(filterTmpData) && filterTmpData.dateFilterOption && filterTmpData.dateFilterPart) {
            return this.chartFilterUtilsService.computeRelativeDateLabel(filterTmpData.dateFilterOption, filterTmpData.dateFilterPart, filterTmpData.minValue != null ? filterTmpData.minValue : null, filterTmpData.maxValue != null ? filterTmpData.maxValue : null);
        }

        this.logger.error('Unsupported filter encountered while computing facet summary');
        return '';
    }


    public computeFilterId() {
        return uniqueId(`${Date.now().toString()}_`);
    }

    private areAlphanumericalPivotFiltersEqual(filter1: FrontendChartFilter, filter2: FrontendChartFilter): boolean {
        return filter1.excludeOtherValues === filter2.excludeOtherValues && (filter1.excludeOtherValues ? isEqual(filter1.selectedValues, filter2.selectedValues) : isEqual(filter1.excludedValues, filter2.excludedValues));
    }

    private areNumericalPivotFiltersEqual(filter1: FrontendChartFilter, filter2: FrontendChartFilter): boolean {
        return filter1.minValue === filter2.minValue && filter1.maxValue === filter2.maxValue && filter1.$globalMinValue === filter2.$globalMinValue && filter1.$globalMaxValue === filter2.$globalMaxValue;
    }

    private areDatePivotFiltersEqual(filter1: FrontendChartFilter, filter2: FrontendChartFilter): boolean {
        if (filter1.dateFilterType !== filter2.dateFilterType) {
            return false;
        }
        if (filter1.dateFilterType === ChartFilter.DateFilterType.RANGE) {
            return this.areNumericalPivotFiltersEqual(filter1, filter2);
        }
        if (filter1.dateFilterType === ChartFilter.DateFilterType.RELATIVE) {
            if (filter1.dateFilterOption !== filter2.dateFilterOption || filter1.dateFilterPart !== filter2.dateFilterPart) {
                return false;
            }
            if (filter1.dateFilterOption === ChartFilter.DateRelativeOption.LAST) {
                return filter1.minValue === filter2.minValue;
            }
            if (filter1.dateFilterOption === ChartFilter.DateRelativeOption.NEXT) {
                return filter1.maxValue === filter2.maxValue;
            }
        }
        if (filter1.dateFilterType === ChartFilter.DateFilterType.PART) {
            if (filter1.dateFilterPart !== filter2.dateFilterPart) {
                return false;
            }
            return this.areAlphanumericalPivotFiltersEqual(filter1, filter2);
        }
        return true;
    }

    private getFriendlyNameForEngine(engineName: string, productShortName: string): string {
        if (engineName === 'LINO') {
            return productShortName;
        }
        if (engineName === 'SQL') {
            return 'In-database';
        }
        return engineName;
    }

    private isNumberWithinRange(value: number | null | undefined, responseFacet: FilterFacet) {
        return (typeof value === 'number' && value >= responseFacet.minValue && value <= responseFacet.maxValue) || value === null;
    }

    private hasWithinRangeMinAndMax(filter: ChartFilter | FilterTmpData, responseFacet: FilterFacet) {
        return filter && this.isNumberWithinRange(filter.minValue, responseFacet) && this.isNumberWithinRange(filter.maxValue, responseFacet);
    }

    private hasChangedAlphanumericalValue(filterTmpData: FilterTmpData): boolean {
        if (!filterTmpData || !this.chartFilterUtilsService.isAlphanumericalFilter(filterTmpData)) {
            return false;
        }
        // For multi select filters, a filter has changed if all values aren't selected.
        if (filterTmpData.filterSelectionType === ChartFilter.FilterSelectionType.MULTI_SELECT) {
            return !!filterTmpData.values?.some(({ included }) => !included);
        }
        // For single select filters, a filter has changed if the selected value isn't the first one.
        return !!(filterTmpData.values && filterTmpData.values.length && !filterTmpData.values[0].included);
    }

    private hasChangedNumericalValue(filterTmpData: FilterTmpData) {
        if (!this.chartFilterUtilsService.isNumericalFilter(filterTmpData)) {
            return false;
        }
        if (filterTmpData.isAGlobalFilter) {
            // On dashboard filters, if range has been modified it is necessarily within the current facet available range
            const hasChangedMinOrMax = (filterTmpData.minValue !== filterTmpData.response.minValue && filterTmpData.minValue !== null) || (filterTmpData.maxValue !== filterTmpData.response.maxValue && filterTmpData.maxValue !== null);
            return hasChangedMinOrMax && this.hasWithinRangeMinAndMax(filterTmpData, filterTmpData.response);
        } else {
            // On charts filters (explore and insights), no change means values have not been set
            return typeof filterTmpData.minValue !== 'number' && typeof filterTmpData.maxValue !== 'number';
        }
    }

    private hasChangedTimezone(filterTmpData: FilterTmpData): boolean {
        return filterTmpData.timezone !== 'UTC';
    }

    private hasChangedRelativeDateValue(filterTmpData: FilterTmpData): boolean {
        return filterTmpData.dateFilterOption !== ChartFilter.DateRelativeOption.THIS;
    }

    private hasChangedFacetValues(filterTmpData: FilterTmpData): boolean {
        if (this.chartFilterUtilsService.isAlphanumericalFilter(filterTmpData)) {
            return this.hasChangedAlphanumericalValue(filterTmpData);
        } else if (this.chartFilterUtilsService.isDateRangeFilter(filterTmpData)) {
            return this.hasChangedNumericalValue(filterTmpData) || this.hasChangedTimezone(filterTmpData);
        } else if (this.chartFilterUtilsService.isNumericalFilter(filterTmpData)) {
            return this.hasChangedNumericalValue(filterTmpData);
        } else if (this.chartFilterUtilsService.isRelativeDateFilter(filterTmpData)) {
            return this.hasChangedRelativeDateValue(filterTmpData);
        }
        this.logger.error('Unsupported filter encountered while determining if filter changed');
        return false;
    }

    private hasChangedExcludeOtherValuesOption(filterTmpData: FilterTmpData): boolean {
        return this.getDefaultExcludeOtherValues(filterTmpData.isAGlobalFilter, filterTmpData?.response?.values) !== filterTmpData.excludeOtherValues;
    }

    private hasChangedFacetSelectionType(filterTmpData: FilterTmpData): boolean {
        if (this.chartColumnTypeUtilsService.isNumericalColumnType(filterTmpData.columnType)) {
            return this.hasChangedNumericalColumnSelectionType(filterTmpData);
        } else if (this.chartColumnTypeUtilsService.isDateColumnType(filterTmpData.columnType)) {
            return this.hasChangedDateColumnSelectionType(filterTmpData);
        } else if (this.chartColumnTypeUtilsService.isAlphanumColumnType(filterTmpData.columnType)) {
            return this.hasChangedAlphanumColumnSelectionType(filterTmpData);
        }
        this.logger.error('Unsupported filter encountered while determining if filter selection type changed');
        return false;
    }

    private hasChangedNumericalColumnSelectionType(filterTmpData: FilterTmpData): boolean {
        return filterTmpData.filterType !== ChartFilter.FilterType.NUMERICAL_FACET
            || filterTmpData.filterSelectionType !== ChartFilter.FilterSelectionType.RANGE_OF_VALUES;
    }

    private hasChangedDateColumnSelectionType(filterTmpData: FilterTmpData): boolean {
        return filterTmpData.dateFilterType !== ChartFilter.DateFilterType.RANGE
            || filterTmpData.filterSelectionType !== ChartFilter.FilterSelectionType.RANGE_OF_VALUES;
    }

    private hasChangedAlphanumColumnSelectionType(filterTmpData: FilterTmpData): boolean {
        return filterTmpData.filterType !== ChartFilter.FilterType.ALPHANUM_FACET
            || filterTmpData.filterSelectionType !== ChartFilter.FilterSelectionType.MULTI_SELECT;
    }

    private getFiltersCountLabel(filtersCount: number) {
        return `${filtersCount} filter${filtersCount > 1 ? 's' : ''}`;
    }

    private getDeactivatedFiltersCountLabel(deactivatedFiltersCount: number) {
        return `${deactivatedFiltersCount} disabled`;
    }

    private getDefaultSummary(filtersCount: number) {
        return `${this.getFiltersCountLabel(filtersCount)}`;
    }

    private getSummaryForSomeDeactivatedFilters(filtersCount: number, deactivatedFiltersCount: number) {

        if (deactivatedFiltersCount === filtersCount) {
            return `${this.getFiltersCountLabel(filtersCount)} (all disabled)`;
        }

        return `${this.getFiltersCountLabel(filtersCount)} (${this.getDeactivatedFiltersCountLabel(deactivatedFiltersCount)})`;

    }


    /**
     * For an alphanumerical filter, returns all filter values that need to be tracked.
     * If the response contains only the relevant values, previously edited irrelevant values need to be tracked.
     */
    private getAllTrackedValues(responseFacet: FilterFacet, filter: FrontendChartFilter, hasSamplingChanged: boolean, selectedValues: string[], excludedValues: string[]): Omit<AlphanumericalFilterTmpDataValue, 'included'>[] {
        /*
         * If all values in sample are in the response facet, we know there cannot be additional values to track.
         * If the sampling changed, we also want to clear tracked values so as not to track values that may not be in the sample anymore.
         */
        if (filter.allValuesInSample || hasSamplingChanged) {
            return responseFacet.values as Omit<AlphanumericalFilterTmpDataValue, 'included'>[];
        }
        // If only relevant values are in the response facet, the filter may contain irrelevant values that needs tracking.
        const responseFacetValueIds = new Set(responseFacet.values.map(({ id }) => id));
        const irrelevantValues = (filter.excludeOtherValues ? selectedValues : excludedValues)
            .filter(filterValue => !responseFacetValueIds.has(filterValue))
            .map(filterValue => ({ id: filterValue, label: filterValue, count: 0 }));

        return [
            ...responseFacet.values,
            ...irrelevantValues
        ];
    }

    /**
     * Determines whether if a filter value should be selected or not.
     */
    private shouldFilterValueBeSelected(value: Omit<AlphanumericalFilterTmpDataValue, 'included'>, filter: FrontendChartFilter, selectedValuesSet: Set<string>, excludedValuesSet: Set<string>, hasAValueAlreadyBeenIncluded: boolean): boolean {
        if (filter.filterSelectionType === ChartFilter.FilterSelectionType.SINGLE_SELECT && hasAValueAlreadyBeenIncluded === true) {
            return false;
        }

        const isNewFilter = !filter.selectedValues && !filter.excludedValues;
        if (isNewFilter) {
            return true;
        }

        const isInSelectedValues = selectedValuesSet.has(value.id);
        if (isInSelectedValues) {
            return true;
        }

        const isInExcludedValues = excludedValuesSet.has(value.id);
        if (isInExcludedValues) {
            return false;
        }

        return !filter.excludeOtherValues;
    }

    /**
     * Returns the filter facet state in `filterTmpData` for the filter UI.
     */
    private getFilterTmpDataValues(responseFacet: FilterFacet, filter: FrontendChartFilter, hasSamplingChanged: boolean): FilterTmpDataValues {
        if (this.chartFilterUtilsService.isAlphanumericalFilter(filter)) {
            return this.getAlphanumericalFilterValues(responseFacet, filter, hasSamplingChanged);
        } else if (this.chartFilterUtilsService.isDateRangeFilter(filter)) {
            return this.getDateRangeFilterValues(responseFacet, filter, hasSamplingChanged);
        } else if (this.chartFilterUtilsService.isNumericalRangeFilter(filter)) {
            return this.getNumericalFilterValues(responseFacet, filter, hasSamplingChanged);
        } else if (this.chartFilterUtilsService.isRelativeDateFilter(filter)) {
            return this.getRelativeDateFilterValues(filter);
        }

        throw 'Unsupported filter encountered while computing filter values';
    }

    private getAlphanumericalFilterValues(responseFacet: FilterFacet, filter: FrontendChartFilter, hasSamplingChanged: boolean): AlphanumericalFilterTmpDataValues | DatePartFilterTmpDataValues {
        const selectedValues = Object.keys(filter.selectedValues || {});
        const excludedValues = Object.keys(filter.excludedValues || {});
        const selectedValuesSet = new Set(selectedValues);
        const excludedValuesSet = new Set(excludedValues);
        if (filter.$isFromUrlQuery) {
            /*
             * Find common facet values between response and url query
             * if none, apply initial state (depending on all selected facet values)
             */
            const responseValues = new Set(responseFacet.values.map(({ id }) => id));
            const urlValues = (filter.excludeOtherValues ? selectedValues : excludedValues);
            const invalidValue = urlValues.find(value => !responseValues.has(value));

            if (urlValues.length && invalidValue) {
                filter.$warningMessage = `${filter.column} has no matching facet value '${invalidValue}'`;
            }
        }
        let hasAValueAlreadyBeenIncluded = false;
        const values = this.getAllTrackedValues(responseFacet, filter, hasSamplingChanged, selectedValues, excludedValues)
            .map(value => {
                const included = this.shouldFilterValueBeSelected(value, filter, selectedValuesSet, excludedValuesSet, hasAValueAlreadyBeenIncluded);
                if (!hasAValueAlreadyBeenIncluded) {
                    hasAValueAlreadyBeenIncluded = included;
                }
                return {
                    ...value,
                    included
                };
            });
        const isDatePartFilter = this.chartFilterUtilsService.isDatePartFilter(filter);
        return {
            dateFilterType: isDatePartFilter ? filter.dateFilterType : undefined,
            dateFilterPart: isDatePartFilter ? filter.dateFilterPart : undefined,
            values
        };
    }

    private getDateRangeFilterValues(responseFacet: FilterFacet, filter: FrontendChartFilter, hasSamplingChanged: boolean): DateRangeFilterTmpDataValues {
        return {
            dateFilterType: filter.dateFilterType,
            timezone: filter.timezone,
            ...this.getNumericalFilterValues(responseFacet, filter, hasSamplingChanged)
        };
    }

    private getNumericalFilterValues(responseFacet: FilterFacet, filter: FrontendChartFilter, hasSamplingChanged: boolean): NumericalFilterTmpDataValues {
        const definedMinValue = filter.minValue != null ? filter.minValue : responseFacet.minValue;
        const definedMaxValue = filter.maxValue != null ? filter.maxValue : responseFacet.maxValue;
        const isRangeInvalid = definedMinValue > definedMaxValue;
        const isMinInvalid = definedMinValue > responseFacet.maxValue;
        const isMinOutOfRange = definedMinValue < responseFacet.minValue;
        const isMaxInvalid = definedMaxValue < responseFacet.minValue;
        const isMaxOutOfRange = definedMaxValue > responseFacet.maxValue;
        // Filters from url query with out of range values should trigger a warning message.
        if (filter.$isFromUrlQuery) {
            if (isRangeInvalid) {
                filter.$warningMessage = `${filter.column} has invalid range: min is greater than max`;
            } else if (isMinInvalid || isMinOutOfRange) {
                filter.$warningMessage = `${filter.column} has invalid range: min is out of range`;
            } else if (isMaxInvalid || isMaxOutOfRange) {
                filter.$warningMessage = `${filter.column} has invalid range: max is out of range`;
            }
        }
        let minValue: number;
        let maxValue: number;
        // If the sampling changed we override the filter min and max with the response min and max.
        if (typeof filter.minValue === 'number' && !hasSamplingChanged && !isRangeInvalid && !isMinInvalid && !(isMinOutOfRange && filter.$isFromUrlQuery)) {
            minValue = filter.minValue;
        } else {
            minValue = responseFacet.minValue;
        }
        if (typeof filter.maxValue === 'number' && !hasSamplingChanged && !isRangeInvalid && !isMaxInvalid && !(isMaxOutOfRange && filter.$isFromUrlQuery)) {
            maxValue = filter.maxValue;
        } else {
            maxValue = responseFacet.maxValue;
        }
        return { minValue, maxValue };
    }

    private getRelativeDateFilterValues(filter: FrontendChartFilter): RelativeDateFilterTmpDataValues {
        return {
            dateFilterPart: filter.dateFilterPart,
            dateFilterOption: filter.dateFilterOption,
            minValue: filter.minValue,
            maxValue: filter.maxValue,
            dateFilterType: filter.dateFilterType
        };
    }

    /**
     * Returns the default exclude other values option value.
     */
    private getDefaultExcludeOtherValues(isAGlobalFilter?: boolean, facetValues?: FilterFacet.Val[] | AlphanumericalFilterTmpDataValue[] | null | undefined): boolean {
        // Charts are in include mode by default.
        if (!isAGlobalFilter) {
            return false;
        }
        // Dashboards are in exclude mode if the facet contains less than 200 values or in include mode otherwise.
        return (facetValues || []).length < 200;
    }

    /**
     * Computes the exclude other values option value.
     */
    private computeExcludeOtherValues(filter: FrontendChartFilter, responseFacet: FilterFacet): boolean {
        // excludeOtherValues can be already set if we're not dealing with a freshly created filter.
        if (filter.excludeOtherValues !== undefined) {
            return filter.excludeOtherValues;
        } else {
            return this.getDefaultExcludeOtherValues(filter.isAGlobalFilter, responseFacet.values);
        }
    }

    private getFacetValues(filterTmpData: FilterTmpData, included = true) {
        /*
         * filterTmpData.values can be null if we come from a range filter (eg a numerical filter converted as alphanum).
         * In this case, we should default to all values selected.
         */
        if (!filterTmpData.values) {
            return included ? null : {};
        }
        const values: Record<string, boolean> = {};
        filterTmpData.values.forEach(facetValue => {
            if (included ? facetValue.included : !facetValue.included) {
                values[facetValue.id] = filterTmpData.allValuesInSample || (!!facetValue.count && facetValue.count > 0);
            }
        });
        return values;
    }

    private switchFilterSelectionTypeToMultiSelect(filterTmpData: FilterTmpData, filters: FrontendChartFilter[], filterIndex: number, getPivotResponseOptions: GetPivotRequestOptions): Promise<void> {
        if (this.chartFilterUtilsService.isDateRangeFilter(filterTmpData) || this.chartFilterUtilsService.isNumericalRangeFilter(filterTmpData)) {
            // if we come from a range we want to include all values in the range.
            return this.convertRangeFilterToAlphanumFilter(ChartFilter.FilterSelectionType.MULTI_SELECT, filterTmpData, filters, filterIndex, getPivotResponseOptions);
        }
        const defaultExcludeOtherValues = this.getDefaultExcludeOtherValues(filterTmpData.isAGlobalFilter, filterTmpData?.response?.values);
        this.switchFilterSelectionTypeToAlphanum(filterTmpData, defaultExcludeOtherValues, ChartFilter.FilterSelectionType.MULTI_SELECT);
        return Promise.resolve();
    }

    private switchFilterSelectionTypeToSingleSelect(filterTmpData: FilterTmpData, filters: FrontendChartFilter[], filterIndex: number, getPivotResponseOptions: GetPivotRequestOptions): Promise<void> {
        // keeping the first found value as included and de-selecting any other value
        if (filterTmpData.values && filterTmpData.values.length > 0) {
            // If there are values in filterTmpData.values, that means we come from a ALPHANUM_FACET, hence we can manually select the first value.
            let isAValueIncluded = false;
            for (const value of filterTmpData.values) {
                value.included = value.included && !isAValueIncluded;
                if (value.included) {
                    isAValueIncluded = true;
                }
            }
            // If we haven't included a value yet, include the first one.
            if (!isAValueIncluded) {
                filterTmpData.values[0].included = true;
            }
            this.switchFilterSelectionTypeToAlphanum(filterTmpData, true, ChartFilter.FilterSelectionType.SINGLE_SELECT);
            return Promise.resolve();
        }
        // if there is no value in filterTmpData.values, that means we come from a NUMERICAL_FACET/DATE_FACET, we need to do a request to know the available values.
        return this.convertRangeFilterToAlphanumFilter(ChartFilter.FilterSelectionType.SINGLE_SELECT, filterTmpData, filters, filterIndex, getPivotResponseOptions);
    }

    private convertRangeFilterToAlphanumFilter(newSelectionType: AlphanumFilterSelectionType, filterTmpData: FilterTmpData, filters: FrontendChartFilter[], filterIndex: number, getPivotResponseOptions: GetPivotRequestOptions): Promise<void> {
        if (!this.chartFilterUtilsService.isNumericalRangeFilter(filterTmpData) && !this.chartFilterUtilsService.isDateRangeFilter(filterTmpData)) {
            this.logger.error('Unsupported filter: date range or numerical range filter expected');
            return Promise.reject();
        }
        let minValue = filterTmpData.minValue ?? -Infinity;
        let maxValue = filterTmpData.maxValue ?? +Infinity;
        const filtersCopy = cloneDeep(filters);
        if (this.chartColumnTypeUtilsService.isDateColumnType(filterTmpData.columnType)) {
            // If we are dealing with a date then we have to match the precision of the default date part, which is year.
            if (minValue != null) {
                minValue = this.dateUtilsService.convertDateToTimezone(new Date(minValue), filterTmpData.timezone || 'UTC').getUTCFullYear();
            }
            if (maxValue != null) {
                maxValue = this.dateUtilsService.convertDateToTimezone(new Date(maxValue), filterTmpData.timezone || 'UTC').getUTCFullYear();
            }
            filtersCopy[filterIndex].dateFilterType = ChartFilter.DateFilterType.PART;
            filtersCopy[filterIndex].dateFilterPart = ChartFilter.DateFilterPart.YEAR;
        } else {
            filtersCopy[filterIndex].filterType = ChartFilter.FilterType.ALPHANUM_FACET;
        }
        filtersCopy[filterIndex].filterSelectionType = filterTmpData.filterSelectionType;
        filtersCopy[filterIndex].selectedValues = null;
        const onSuccess = (filterFacet: FilterFacet) => {
            let hasAValueAlreadyBeenIncluded = false;
            const numericalValues = filterFacet.values.map(value => Number(value.id)).sort((a, b) => a - b);
            delete filterTmpData.minValue;
            delete filterTmpData.maxValue;
            filterTmpData.values = numericalValues.map((value) => {
                // A null minValue or maxValue means the boundary is flexible and as such the compared value can be included.
                const isGreaterThanMin = value >= minValue;
                const isLessThanMax = value <= maxValue;
                const included = isGreaterThanMin && isLessThanMax && (newSelectionType === ChartFilter.FilterSelectionType.MULTI_SELECT || !hasAValueAlreadyBeenIncluded);
                if (included) {
                    hasAValueAlreadyBeenIncluded = true;
                }
                return { id: String(value), label: String(value), included };
            });
            this.switchFilterSelectionTypeToAlphanum(filterTmpData, true, newSelectionType);
        };
        return this.getFilteredFilterFacet(filtersCopy, filterIndex, onSuccess, getPivotResponseOptions);
    }

    /**
     * Returns the filter facet of all available values given the state of the other filters.
     * @param filters                   - the list of all filters, including the one for which we want to  retrieve the facet.
     * @param filterIndex               - the index of the filters for which we want to retrieve the  facet in the filters list.
     * @param onSuccess                 - a callback to call when the facet has been successfully fetched from the backend.
     * @param getPivotResponseOptions   - options allowing to make the call to the backend.
     */
    private getFilteredFilterFacet(filters: FrontendChartFilter[], filterIndex: number, onSuccess: (facet: FilterFacet) => void, { projectKey, dataSpec, requestedSampleId, onError, visualAnalysisFullModelId }: GetPivotRequestOptions) {
        const filter: FrontendChartFilter = { ...filters[filterIndex] };
        if (this.chartFilterUtilsService.isAlphanumericalFilter(filter)) {
            if (filter.excludeOtherValues) {
                filter.selectedValues = null;
            } else {
                filter.excludedValues = {};
            }
        } else if (this.chartFilterUtilsService.isNumericalFilter(filter)) {
            filter.minValue = null;
            filter.maxValue = null;
        }
        const filtersCopy = [...filters];
        filtersCopy[filterIndex] = filter;
        const request = this.buildFiltersRequest(filtersCopy);
        return this.filterFacetsService.getFilterFacets(projectKey, dataSpec, request, requestedSampleId, undefined, visualAnalysisFullModelId)
            .success((data: { result: { pivotResponse: PivotTableResponse } }) => {
                const currentFilterFacet = data.result.pivotResponse.filterFacets[filterIndex];
                onSuccess(currentFilterFacet);
            })
            .error((data: fairAny, status: number, headers: Record<string, unknown>, config: Record<string, unknown>, statusText: string) => {
                if (data && data.hasResult && data.aborted) {
                    // Manually aborted => do not report as error
                } else if (onError) {
                    onError(data, status, headers, config, statusText);
                }
            });
    }

    private switchFilterSelectionTypeToAlphanum(filterTmpData: FilterTmpData, excludeOtherValues: boolean, filterSelectionType: AlphanumFilterSelectionType): void {
        filterTmpData.filterSelectionType = filterSelectionType;
        filterTmpData.excludeOtherValues = excludeOtherValues;
        if (this.chartFilterUtilsService.isDateRangeFilter(filterTmpData) || this.chartFilterUtilsService.isRelativeDateFilter(filterTmpData)) {
            filterTmpData.dateFilterType = ChartFilter.DateFilterType.PART;
            filterTmpData.dateFilterPart = ChartFilter.DateFilterPart.YEAR;
        }
        if (filterTmpData.columnType !== AxisDef.Type.DATE) {
            filterTmpData.filterType = ChartFilter.FilterType.ALPHANUM_FACET;
        }
        delete filterTmpData.minValue;
        delete filterTmpData.maxValue;
    }

    private switchFilterSelectionTypeToRange(filterTmpData: FilterTmpData): void {
        if (this.chartColumnTypeUtilsService.isDateColumnType(filterTmpData.columnType)) {
            this.switchDateFilterTypeToRange(filterTmpData);
        } else {
            filterTmpData.filterSelectionType = ChartFilter.FilterSelectionType.RANGE_OF_VALUES;
            filterTmpData.filterType = ChartFilter.FilterType.NUMERICAL_FACET;
            delete filterTmpData.values;
        }
    }

    private switchDateFilterTypeToRange(filterTmpData: FilterTmpData): void {
        filterTmpData.filterSelectionType = ChartFilter.FilterSelectionType.RANGE_OF_VALUES;
        filterTmpData.dateFilterType = ChartFilter.DateFilterType.RANGE;
        filterTmpData.minValue = null;
        filterTmpData.maxValue = null;
        filterTmpData.timezone = 'UTC';
        delete filterTmpData.dateFilterOption;
        delete filterTmpData.dateFilterPart;
        delete filterTmpData.values;
    }

    private switchDateFilterTypeToRelativeRange(filterTmpData: FilterTmpData): void {
        filterTmpData.filterSelectionType = ChartFilter.FilterSelectionType.RANGE_OF_VALUES;
        const dateFilterPart = (filterTmpData.dateFilterPart != null && this.AVAILABLE_RELATIVE_DATE_FILTER_DATE_PARTS.has(filterTmpData.dateFilterPart)) ? filterTmpData.dateFilterPart : ChartFilter.DateFilterPart.YEAR;
        filterTmpData.dateFilterPart = dateFilterPart;
        filterTmpData.dateFilterOption = ChartFilter.DateRelativeOption.THIS;
        filterTmpData.minValue = 1;
        filterTmpData.maxValue = 1;
        filterTmpData.dateFilterType = ChartFilter.DateFilterType.RELATIVE;
        delete filterTmpData.values;
    }

    private switchDateFilterTypeToDatePart(filterTmpData: FilterTmpData, filters: FrontendChartFilter[], filterIndex: number, getPivotResponseOptions: GetPivotRequestOptions): Promise<void> {
        // In the case of a relative date filter the min and max values cannot be reused for a date part filter, so we need to get rid of them or values may be missing.
        if (filterTmpData.dateFilterType === ChartFilter.DateFilterType.RELATIVE) {
            delete filterTmpData.minValue;
            delete filterTmpData.maxValue;
        }
        return this.switchFilterSelectionType(ChartFilter.FilterSelectionType.MULTI_SELECT, filterTmpData, filters, filterIndex, getPivotResponseOptions);
    }
}
