import { Injectable } from '@angular/core';
import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { ChartType } from '@model-main/pivot/frontend/model/chart-type';
import { DimensionDef } from '@model-main/pivot/frontend/model/dimension-def';
import { MeasureDef } from '@model-main/pivot/frontend/model/measure-def';
import { DateAxisParams } from '@model-main/pivot/backend/model/date-axis-params';
import { FrontendChartDef } from '../interfaces';
import { ChartStaticDataService } from './chart-static-data.service';

@Injectable({
    providedIn: 'root'
})

/**
 * Set of helpers for chart dimension manipulation.
 * (!) This service previously was in:
 * - static/dataiku/js/simple_report/core/chart-dimension.service.js
 * - static/dataiku/js/simple_report/charts_core.js
 */
export class ChartDimensionService {
    constructor(private chartStaticDataService: ChartStaticDataService) {}


    /**
     * Finds the bin number definition for the chart type.
     */
    private findBinNumberOrDefault(chartType: ChartType, binNumbers: Array<any>) {
        return binNumbers.find(automaticMaxBin => automaticMaxBin.chartType === chartType) || this.chartStaticDataService.BIN_NUMBER_DEFAULT;
    }

    /**
     * Compute the bin number.
     */
    private getBinNumber(chartType: ChartType, isMainDimension: boolean, binNumbers: Array<any>) {
        const binNumber = this.findBinNumberOrDefault(chartType, binNumbers);
        if (isMainDimension) {
            return binNumber.valueForMainDimension;
        }
        return binNumber.valueForOtherDimension;
    }

    isTimelineable(dimension: DimensionDef) {
        if (dimension && dimension.type === 'DATE') {
            if (!dimension.dateParams) {
                return false;
            }
            return this.chartStaticDataService.TIMELINE_AND_AUTOMATIC_DATE_MODES.map((dateMode: any) => dateMode.value).includes(dimension.dateParams.mode);
        }
        return false;
    }

    /**
     * Return True if the dimension is a Date dimension but with an ordinal scale (i.e. when it's configured
     * to display one tick per bin.
     */
    isOrdinalDateScale(dimension: DimensionDef) {
        return dimension && dimension.type === 'DATE' && dimension.oneTickPerBin;
    }

    /**
     * Return True if the dimension is unaggregated
     */
    isUnaggregated(dimension: DimensionDef) {
        return dimension && dimension.isA === 'ua';
    }

    /**
     * Return True if an automatic date dimension.
     */
    isAutomatic(dimension: DimensionDef) {
        if (!this.isTimelineable(dimension)) {
            return false;
        }
        return dimension.dateParams.mode === this.chartStaticDataService.AUTOMATIC_DATE_MODE.value;
    }

    /**
     * Returns the max number of bins for automatic dimensions.
     */
    private getMaxBinNumberForAutomaticMode(chartType: ChartType, isMainDimension: boolean) {
        return this.getBinNumber(chartType, isMainDimension, this.chartStaticDataService.AUTOMATIC_MAX_BIN_NUMBERS);
    }

    /**
     * Returns true if the chart contains a main automatic date axis.
     */
    private isMainDateAxisAutomatic(chartDef: FrontendChartDef) {
        return chartDef.genericDimension0.length > 0 && this.isAutomatic(chartDef.genericDimension0[0]);
    }

    /**
     * Returns the main date axis binning mode from the response.
     */
    private getMainDateAxisBinningMode(response: PivotTableTensorResponse) {
        return response.axisDefs[0].dateParams.mode;
    }

    hasFixedNumberOfBins(dimension: DimensionDef) {
        return dimension.numParams.mode === 'FIXED_NB';
    }

    hasFixedBinSize(dimension: DimensionDef) {
        return dimension.numParams.mode == 'FIXED_SIZE';
    }

    isNumerical(dimension: DimensionDef) {
        return dimension && dimension.type == 'NUMERICAL';
    }

    isTrueNumerical(dimension: DimensionDef) {
        return this.isNumerical(dimension) && ((dimension.numParams && dimension.numParams.mode != 'TREAT_AS_ALPHANUM') || dimension.numParams == undefined);
    }

    getNumberFormattingOptions(dimension: DimensionDef) {
        if (!dimension || !this.isTrueNumerical(dimension)) {
            return null;
        }
        return {
            multiplier: dimension.multiplier || this.chartStaticDataService.autoMultiplier.label,
            decimalPlaces: dimension.decimalPlaces,
            prefix: dimension.prefix,
            suffix: dimension.suffix,
            digitGrouping: dimension.digitGrouping || this.chartStaticDataService.DEFAULT_DIGIT_GROUPING
        };
    }

    isTimeline(dimension: DimensionDef) {
        return !this.isOrdinalDateScale(dimension) && this.isTimelineable(dimension);
    }

    /**
     * Returns True if the dimension is an automatic date dimension not using an ordinal scale.
     */
    isCandidateForInteractivity(dimension: DimensionDef) {
        return this.isAutomatic(dimension) && !this.isOrdinalDateScale(dimension);
    }

    /**
     * Return True if the first X-axis dimension is an non-ordinal automatic date dimension as it's the
     * only one that can be interactive.
     */
    private containsInteractiveDimensionCandidate(chartDef: FrontendChartDef) {
        if (chartDef.genericDimension0.length === 0) {
            return false;
        }
        return this.isCandidateForInteractivity(chartDef.genericDimension0[0]);
    }

    /**
     * Return True if the chart is configured to be interactive and is not prevented to be.
     */
    isInteractiveChart(chartDef: FrontendChartDef, disableChartInteractivityGlobally: boolean) {
        if (disableChartInteractivityGlobally) {
            return false;
        }
        if (chartDef.type !== 'lines') {
            return false;
        }
        return this.containsInteractiveDimensionCandidate(chartDef);
    }

    /**
     * Checks if all measures in the given array are in percent mode
     * @param {Array} measures
     * @returns {Boolean} True if if all measures are in percent mode, False otherwise
     */
    isPercentScale(measures: Array<MeasureDef>): boolean {
        return measures.every((measure) => this.chartStaticDataService.MEASURES_PERCENT_MODES.includes(measure.computeMode));
    }

    getDateModeDescription(mode: string) {
        const result = this.chartStaticDataService.DATE_MODES_WITH_BACKEND_ONLY.filter(dateMode => dateMode.value === mode);
        if (result.length === 1) {
            return result[0].label;
        }
        return 'Unknown';
    }

    getComputedMainAutomaticBinningModeLabel(response: PivotTableTensorResponse, chartDef: FrontendChartDef, disableChartInteractivityGlobally: boolean) {
        if (!this.isInteractiveChart(chartDef, disableChartInteractivityGlobally)) {
            return undefined;
        }
        if (this.isMainDateAxisAutomatic(chartDef)) {
            return `(${this.getDateModeDescription(this.getMainDateAxisBinningMode(response))})`;
        } else {
            return undefined;
        }
    }

    /**
     * Returns the number of bins for numerical dimensions.
     */
    getNumericalBinNumber(chartType: ChartType, isMainDimension: boolean) {
        return this.getBinNumber(chartType, isMainDimension, this.chartStaticDataService.NUMERICAL_BIN_NUMBERS);
    }

    /**
     * Build the dataParams for the request date axis
     */
    buildDateParamsForAxis(dimension: DimensionDef, chartType: ChartType, isMainInteractiveDateAxis: boolean): DateAxisParams {
        const dateParams = Object.assign({}, dimension.dateParams);
        if (this.isAutomatic(dimension)) {
            dateParams.maxBinNumberForAutomaticMode = this.getMaxBinNumberForAutomaticMode(chartType, isMainInteractiveDateAxis);
        }
        return dateParams as DateAxisParams;
    }

    /**
     * Builds the runtime filter corresponding to the zoom settings on the interactive dimension.
     */
    buildZoomRuntimeFilter(interactiveDimension: any, zoomUtils: any) {
        return {
            column: interactiveDimension.column,
            columnType: 'DATE',
            filterType: 'INTERACTIVE_DATE_FACET',
            dateFilterType: 'RANGE',
            minValue: Math.round(zoomUtils.displayInterval[0]),
            maxValue: Math.round(zoomUtils.displayInterval[1])
        };
    }

    isAlphanumLike(dimension: DimensionDef) {
        if (!dimension) {
            return;
        }
        return dimension.type == 'ALPHANUM' || (dimension.type == 'NUMERICAL' && dimension.numParams && dimension.numParams.mode == 'TREAT_AS_ALPHANUM');
    }

    isBinnedNumerical(dimension: DimensionDef) {
        if (!dimension) {
            return;
        }
        return this.isTrueNumerical(dimension) && ((dimension.numParams && dimension.numParams.mode != 'NONE') || dimension.numParams == undefined);
    }

    isUnbinnedNumerical(dimension: DimensionDef) {
        if (!dimension) {
            return;
        }
        return this.isTrueNumerical(dimension) && !this.isBinnedNumerical(dimension);
    }


    isAdjustableForNicerBounds(dimension: DimensionDef, chartType: ChartType) {
        return dimension.type == 'NUMERICAL' && dimension.numParams.mode == 'FIXED_NB' && chartType !== ChartType.radar;
    }

    hasOneTickPerBin(dimension: DimensionDef): boolean | undefined {
        if (!dimension) {
            return;
        }
        return dimension.oneTickPerBin === true;
    }
}
