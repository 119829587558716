import type { OneSampleShapiroTestStat } from './one-sample-shapiro-test-stat';
import type { StatsRecipePayloadParams } from './../stats-recipe-payload-params';
import type { _OneSampleShapiroTestStat } from './one-sample-shapiro-test-stat';
import type { _StatsRecipePayloadParams } from './../stats-recipe-payload-params';

/** 
 * Translated from class com.dataiku.dip.recipes.eda.stats.OneSampleShapiroTestRecipePayloadParams
 * Via: UIModel annotation in the class hierarchy
 */
export interface _OneSampleShapiroTestRecipePayloadParams extends _StatsRecipePayloadParams<OneSampleShapiroTestStat> {
    type: "recipe_one-sample-shapiro-test";
}

export type OneSampleShapiroTestRecipePayloadParams = _OneSampleShapiroTestRecipePayloadParams;

export namespace OneSampleShapiroTestRecipePayloadParams {
    /** Type of class com.dataiku.dip.recipes.eda.stats.OneSampleShapiroTestRecipePayloadParams in PolyJSON mappings */
    export const type = "recipe_one-sample-shapiro-test";
}