import { Injectable } from '@angular/core';
import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { NADimensionDef } from '@model-main/pivot/frontend/model/nadimension-def';
import { extent } from 'd3-array';
import { isNumber } from 'lodash';
import { ChartUADimensionService } from '../../services';

@Injectable({
    providedIn: 'root'
})
export class MockChartDataUtilsService {
    constructor(private chartUADimension: ChartUADimensionService) { }

    /**
     * Returns the min, max, and list of values on the given axis
     * @param {NADimensionDef.java} dimension
     * @param {ScatterAxis.java} axisData
     * @param {Number} afterFilterRecords
     * @param {[Number, Number]} extent
     * @return {Object} extent as {values: [], min: min, max: max}
     */
    getUnaggregatedAxisExtent(dimension: NADimensionDef, axisData: any, afterFilterRecords?: number, extent?: [number, number]): {values: Array<any>, min?: number, max?: number} {
        if (this.chartUADimension.isAlphanumLike(dimension) || this.chartUADimension.isDiscreteDate(dimension)) {
            const sortedValues = [...axisData.str.sortedMapping].sort((a, b) => a.sortOrder - b.sortOrder);
            return { values: sortedValues.map((value: any) => value.label) };
        } else if (this.chartUADimension.isTrueNumerical(dimension)) {
            return {
                values: axisData.num.data.filter((_: any, i: number) => isNumber(afterFilterRecords) && i < afterFilterRecords),
                min: extent && isFinite(extent[0]) ? extent[0] : axisData.num.min,
                max: extent && isFinite(extent[1]) ? extent[1] : axisData.num.max
            };
        } else if (this.chartUADimension.isDateRange(dimension)) {
            return {
                values: axisData.ts.data.filter((_: any, i: number) => isNumber(afterFilterRecords) && i < afterFilterRecords),
                min: extent && isFinite(extent[0]) ? extent[0] : axisData.ts.min,
                max: extent && isFinite(extent[1]) ? extent[1] : axisData.ts.max
            };
        } else {
            throw new Error('Unhandled dimension type: ' + dimension.type);
        }
    }

    /**
     * Returns the min & max values across all dimensions for the given measure
     * @param {PivotTableTensorResponse.java} data
     * @param {Number} mIdx - measure index
     * @param {Boolean} ignoreEmptyBins - whether or not to ignore empty bins
     * @param {Set<int> | null} binsToInclude - subtotal tensor indexes to include, the other will be excluded
     * @return {Array} extent as [min, max]
     */
    getMeasureExtent(data: any, mIdx: number, ignoreEmptyBins: boolean, binsToInclude: Set<number> | null = null) {

        if (!data.aggregations[mIdx]) {
            return null;
        }

        let accessor: any = (a: any) => a;
        if (ignoreEmptyBins) {
            accessor = function(d: any, i: number) {
                if (data.aggregations[mIdx].nonNullCounts) {
                    return data.aggregations[mIdx].nonNullCounts[i] > 0 ? (!binsToInclude || binsToInclude.has(i) ? d : null) : null;
                } else {
                    return (data.counts.tensor[i] > 0) ? (!binsToInclude || binsToInclude.has(i) ? d : null) : null;
                }
            };
        }

        return extent(data.aggregations[mIdx].tensor, accessor);
    }

    /**
     * Returns an aggregation tensor where empty & all-null bins are filtered out
     * @param {PivotTableTensorResponse.java} data
     * @param {number} mIdx     index  of the measure whose values we're getting
     * @return {Array}          list of values for non-empty and non-null bins
     */
    getMeasureValues(data: PivotTableTensorResponse, mIdx: number, binsToInclude: Set<number>) {
        if (!data.aggregations[mIdx]) {
            return null;
        }

        return data.aggregations[mIdx].tensor.filter(function(d, i) {
            if (data.aggregations[mIdx].nonNullCounts) {
                return data.aggregations[mIdx].nonNullCounts[i] > 0;
            } else {
                return (data.counts.tensor[i] > 0) ? (!binsToInclude || binsToInclude.has(i) ? true : false) : false;
            }
        });
    }
}
