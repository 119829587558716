// Generic type definition helpers

// native syntax is ugly, Dict<Dict<T>> looks better than {[name1: string]: {[name2: string]: T}}
// in practice keys are always strings anyway...
export interface Dict<T> {
    [name: string]: T;
}

// We want to avoid using "any" as a type
// But sometimes we really want any (if we defined `isFunction(obj)` obj can obviously be anything)
// Then we should use realAny so that we can immediately make the difference between quick possibly dirty coding and generic code.
export type realAny = any;

// Sometimes typing is a bit overkill and we don't want to be extremists. It's not a realAny so let's define a fairAny :)
// Examples:
// - upgraded provider that has no type yet
// - accessing attributes by name `(<fairAny>obj)[attName]` (because obj[attName] is illegal...)
export type fairAny = any;


// Utility to modify replace some properties of an existing type
export type Modify<T, R> = Omit<T, keyof R> & R;

// Remove types from T that are assignable to U
export type Diff<T, U> = T extends U ? never : T;

// Remove null and undefined from T
export type NonNullable<T> = Diff<T, null | undefined>;

// syntactic sugar (convenient for stuff coming from J2TS @UINullable field, but not limited to it).
export type Nullable<T> = T | undefined | null;

// Remove types from T that are not assignable to U
export type Filter<T, U> = T extends U ? T : never;

// Retrieve all possible keys of T
export type AllKeys<T> = T extends any ? keyof T : never;

// Narrow a union type & only keep types declaring a specific property
export type WithKey<T, K extends AllKeys<T>> = T extends { [k in K]?: T[K] } ? T & { [k in K]: T[K] } : never;

