import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetsModule } from "@app/widgets/widgets.module";
import { HeatmapComponent } from './heatmap/heatmap.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalsModule } from '@shared/modals/modals.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommaSeparatedViewDirective } from '@shared/directives/comma-separated-view/comma-separated-view.directive';
import { ForceIntegerDirective } from '@shared/directives/force-integer/force-integer.directive';
import { NgxEchartsModule } from 'ngx-echarts';
import { DataikuAPIModule } from '@core/dataiku-api/dataiku-api.module';
import { ClickStopDirective } from '@shared/directives/click-stop/click-stop.directive';
import { SimpleNumberListComponent } from './simple-number-list/simple-number-list.component';
import { ContainerSelectionFormComponent } from './container-selection-form/container-selection-form.component';
import { DoclinkWrapperComponent } from './doclink-wrapper/doclink-wrapper.component';
import { DatasetErrorCtaComponent } from './dataset-error-cta/dataset-error-cta.component';
import { SidebarNavComponent } from './sidebar-nav/sidebar-nav.component';
import { SidebarNavItemComponent } from './sidebar-nav/sidebar-nav-item/sidebar-nav-item.component';
import { ImageFeedComponent } from './item-feed/image-feed/image-feed.component';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { ConfusionMatrixComponent } from './confusion-matrix/confusion-matrix.component';
import { ImageMetadataComponent } from './feed-image-modal/image-metadata/image-metadata.component';
import { UserPictureComponent } from './user-picture/user-picture.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { PipesModule } from '../pipes/pipes.module';
import { ProjectSelectorComponent } from './project-selector/project-selector.component';
import { AccessibleObjectsSelectorComponent } from './accessible-objects-selector/accessible-objects-selector.component';
import { FeedImageModalComponent } from '@shared/components/feed-image-modal/feed-image-modal.component';
import { FeedImageModalSectionComponent } from './feed-image-modal-section/feed-image-modal-section.component';
import { FeedImageModalCanvasComponent } from './feed-image-modal-canvas/feed-image-modal-canvas.component';
import { CarouselComponent } from './carousel/carousel.component';
import { ImagePlaceholderComponent } from './image-placeholder/image-placeholder.component';
import { IndeterminateDirective } from '@shared/directives/indeterminate/indeterminate.directive';
import { ForceResizeDirective } from '@shared/directives/force-resize/force-resize.directive';
import { DynamicVirtualScrollViewportDirective } from '@shared/directives/dynamic-virtual-scroll-viewport.directive';
import { ImageContainerComponent } from './image-container/image-container.component';
import { InViewportModule } from 'ng-in-viewport';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelectPanelSimulatorComponent, NgSelectMonkeyPatchDirective } from '@shared/directives/ng-select-monkey-patch.directive';
import { BasicSelectComponent } from './basic-select/basic-select.component';
import { DowngradedAccessibleObjectsSelectorComponent } from './accessible-objects-selector/downgraded/downgraded-accessible-objects-selector.component';
import { SortOrderButtonComponent } from './sort-order-button';
import { ImageWithArtifactsComponent } from './image-with-artifacts/image-with-artifacts.component';
import { RightPanelSchemaComponent } from './right-panel-summary/right-panel-schema/right-panel-schema.component';
import { DkuFrontendCoreModule } from 'dku-frontend-core';
import { NgSelectSearchInputComponent } from './ng-select-search-input/ng-select-search-input.component';
import { NgSelectActionsBoxComponent } from './ng-select-actions-box/ng-select-actions-box.component';
import { ImageFeedModalComponent } from './image-feed-modal/image-feed-modal.component';
import { ImageFeedModalAnnotationSectionComponent } from './image-feed-modal-annotation-section/image-feed-modal-annotation-section.component';
import { NgSelectPseudoCheckboxComponent } from './ng-select-pseudo-checkbox/ng-select-pseudo-checkbox.component';
import { RightPanelLayoutComponent } from './collapsible-right-panel-layout/collapsible-right-panel-layout.component';
import { DkuMatTooltipModule } from '@shared/components/dku-mat-tooltip/dku-mat-tooltip.module';
import { TextOverflowTooltipModule } from '../../migration/modules/text-overflow-tooltip';
import { TagsSelectorComponent } from './tags-selector/tags-selector.component';
import { RightPanelAccordionSectionComponent } from './right-panel-summary/right-panel-accordion-section/right-panel-accordion-section.component';
import { DragVirtualScrollComponent } from './drag-virtual-scroll/drag-virtual-scroll.component';
import { RightPanelActionComponent } from './right-panel-summary/right-panel-action/right-panel-action.component';
import { RightPanelActionsContainerComponent } from './right-panel-summary/right-panel-actions-container/right-panel-actions-container.component';
import { RightPanelActionStarBtnComponent } from './right-panel-summary/right-panel-action/right-panel-action-star-btn/right-panel-action-star-btn.component';
import { RightPanelActionWatchBtnComponent } from './right-panel-summary/right-panel-action/right-panel-action-watch-btn/right-panel-action-watch-btn.component';
import { RightPanelActionPublishBtnComponent } from './right-panel-summary/right-panel-action/right-panel-action-publish-btn/right-panel-action-publish-btn.component';
import { RightPanelHeaderComponent } from './right-panel-summary/right-panel-header/right-panel-header.component';
import { RightPanelDetailsProjectComponent } from './right-panel-summary/right-panel-details-project/right-panel-details-project.component';
import { UIRouterModule } from '@migration/modules';
import { RightPanelDetailsDescriptionComponent } from './right-panel-summary/right-panel-details-description/right-panel-details-description.component';
import { RightPanelDetailsJoinRecipeComponent } from './right-panel-summary/right-panel-details-join-recipe/right-panel-details-join-recipe.component';
import { RightPanelDetailsTagsComponent } from './right-panel-summary/right-panel-details-tags/right-panel-details-tags.component';
import { MigrationModule } from '@migration/migration.module';
import { RightPanelDetailsTextInfoComponent } from './right-panel-summary/right-panel-details-text-info/right-panel-details-text-info.component';
import { RightPanelDetailsDataStewardComponent } from './right-panel-summary/right-panel-details-data-steward/right-panel-details-data-steward.component';
import { RightPanelStatusMetricComponent } from './right-panel-summary/right-panel-dataset-status/right-panel-status-metric/right-panel-status-metric.component';
import { RightPanelDatasetStatusComponent } from './right-panel-summary/right-panel-dataset-status/right-panel-dataset-status.component';
import { BasePermissionsEditionComponent } from './base-permissions-edition/base-permissions-edition.component';
import { SelectButtonComponent } from './select-button/select-button.component';
import { RightPanelDetailsRelatedElementsComponent } from './right-panel-summary/right-panel-details-related-elements/right-panel-details-related-elements.component';
import { RightPanelDetailsUsagesComponent } from './right-panel-summary/right-panel-details-usages/right-panel-details-usages.component';
import { ClearableSearchbarComponent } from './clearable-searchbar';
import { LeftPaneComponent } from './left-pane';
import { ItemFeedComponent } from './item-feed/item-feed.component';
import { DocumentFeedComponent } from './item-feed/document-feed/document-feed.component';
import { RightPanelDetailsPartitioningComponent } from './right-panel-summary/right-panel-details-partitioning/right-panel-details-partitioning.component';
import { MassActionMenuComponent } from './mass-action-menu/mass-action-menu.component';
import { TopDrawerComponent } from './top-drawer/top-drawer.component';
import { ObjectNavComponent } from './object-nav/object-nav.component';
import { ObjectNavTabComponent } from './object-nav/object-nav-tab/object-nav-tab.component';
import { RightPanelDetailsStackRecipeComponent } from './right-panel-summary/right-panel-details-stack-recipe/right-panel-details-stack-recipe.component';
import { CardVirtualScrollComponent } from './card-virtual-scroll/card-virtual-scroll.component';
import { RightPanelDetailsGroupingRecipeComponent } from './right-panel-summary/right-panel-details-grouping-recipe/right-panel-details-grouping-recipe.component';
import { SelectAllDirective } from './select-all/select-all.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalsModule,
        ScrollingModule,
        NgxEchartsModule,
        DataikuAPIModule,
        DkuFrontendCoreModule,
        MatFormFieldModule,
        MatSelectModule,
        DkuMatTooltipModule,
        MatMenuModule,
        PipesModule,
        InViewportModule,
        WidgetsModule,
        NgSelectModule,
        TextOverflowTooltipModule,
        UIRouterModule,
        MigrationModule
    ],
    declarations: [
        HeatmapComponent,
        ImageFeedComponent,
        ImageFeedModalComponent,
        ImageFeedModalAnnotationSectionComponent,
        CommaSeparatedViewDirective,
        ClickStopDirective,
        SelectButtonComponent,
        ForceIntegerDirective,
        IndeterminateDirective,
        ForceResizeDirective,
        DynamicVirtualScrollViewportDirective,
        SimpleNumberListComponent,
        ContainerSelectionFormComponent,
        DoclinkWrapperComponent,
        DatasetErrorCtaComponent,
        SidebarNavComponent,
        SidebarNavItemComponent,
        InfiniteScrollComponent,
        ImageWithArtifactsComponent,
        FeedImageModalComponent,
        FeedImageModalSectionComponent,
        ConfusionMatrixComponent,
        UserPictureComponent,
        ProjectSelectorComponent,
        AccessibleObjectsSelectorComponent,
        FeedImageModalCanvasComponent,
        CarouselComponent,
        ImageMetadataComponent,
        ImagePlaceholderComponent,
        ImageContainerComponent,
        NgSelectMonkeyPatchDirective,
        NgSelectPanelSimulatorComponent,
        BasicSelectComponent,
        DowngradedAccessibleObjectsSelectorComponent,
        NgSelectSearchInputComponent,
        NgSelectActionsBoxComponent,
        SortOrderButtonComponent,
        NgSelectPseudoCheckboxComponent,
        RightPanelLayoutComponent,
        RightPanelSchemaComponent,
        TagsSelectorComponent,
        RightPanelAccordionSectionComponent,
        DragVirtualScrollComponent,
        RightPanelActionComponent,
        RightPanelActionsContainerComponent,
        RightPanelActionStarBtnComponent,
        RightPanelActionWatchBtnComponent,
        RightPanelActionPublishBtnComponent,
        RightPanelHeaderComponent,
        RightPanelDetailsProjectComponent,
        RightPanelDetailsDescriptionComponent,
        RightPanelDetailsTagsComponent,
        RightPanelDetailsTextInfoComponent,
        RightPanelDetailsDataStewardComponent,
        RightPanelStatusMetricComponent,
        RightPanelDatasetStatusComponent,
        BasePermissionsEditionComponent,
        RightPanelDetailsRelatedElementsComponent,
        RightPanelDetailsUsagesComponent,
        ClearableSearchbarComponent,
        LeftPaneComponent,
        ItemFeedComponent,
        DocumentFeedComponent,
        RightPanelDetailsPartitioningComponent,
        TopDrawerComponent,
        RightPanelDetailsJoinRecipeComponent,
        MassActionMenuComponent,
        ObjectNavComponent,
        ObjectNavTabComponent,
        RightPanelDetailsStackRecipeComponent,
        CardVirtualScrollComponent,
        RightPanelDetailsGroupingRecipeComponent,
        SelectAllDirective
    ],
    exports: [
        DocumentFeedComponent,
        HeatmapComponent,
        BasePermissionsEditionComponent,
        ConfusionMatrixComponent,
        ImageFeedComponent,
        CommaSeparatedViewDirective,
        ClickStopDirective,
        ForceIntegerDirective,
        IndeterminateDirective,
        ForceResizeDirective,
        SelectButtonComponent,
        DynamicVirtualScrollViewportDirective,
        SimpleNumberListComponent,
        ContainerSelectionFormComponent,
        DoclinkWrapperComponent,
        DatasetErrorCtaComponent,
        SidebarNavComponent,
        SidebarNavItemComponent,
        InfiniteScrollComponent,
        ImageWithArtifactsComponent,
        FeedImageModalComponent,
        FeedImageModalSectionComponent,
        FeedImageModalCanvasComponent,
        ImageMetadataComponent,
        UserPictureComponent,
        ImagePlaceholderComponent,
        ProjectSelectorComponent,
        AccessibleObjectsSelectorComponent,
        ImageContainerComponent,
        NgSelectMonkeyPatchDirective,
        NgSelectModule,
        BasicSelectComponent,
        NgSelectSearchInputComponent,
        NgSelectActionsBoxComponent,
        ImageContainerComponent,
        SortOrderButtonComponent,
        NgSelectPseudoCheckboxComponent,
        RightPanelLayoutComponent,
        RightPanelSchemaComponent,
        TagsSelectorComponent,
        RightPanelAccordionSectionComponent,
        DragVirtualScrollComponent,
        RightPanelActionComponent,
        RightPanelActionsContainerComponent,
        RightPanelActionStarBtnComponent,
        RightPanelActionWatchBtnComponent,
        RightPanelActionPublishBtnComponent,
        RightPanelHeaderComponent,
        RightPanelDetailsProjectComponent,
        RightPanelDetailsDescriptionComponent,
        RightPanelDetailsTagsComponent,
        RightPanelDetailsTextInfoComponent,
        RightPanelDetailsDataStewardComponent,
        RightPanelStatusMetricComponent,
        RightPanelDatasetStatusComponent,
        RightPanelDetailsRelatedElementsComponent,
        RightPanelDetailsUsagesComponent,
        ClearableSearchbarComponent,
        LeftPaneComponent,
        RightPanelDetailsPartitioningComponent,
        TopDrawerComponent,
        RightPanelDetailsJoinRecipeComponent,
        MassActionMenuComponent,
        ObjectNavComponent,
        ObjectNavTabComponent,
        CardVirtualScrollComponent,
        SelectAllDirective
    ],
})
export class ComponentsModule {
}
