import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.Max
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Max extends Computation._UnivariateComputation {
    type: "max";
}

export type Max = _Max;

export namespace Max {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.Max in PolyJSON mappings */
    export const type = "max";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.Max$MaxResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _MaxResult extends _AvailableResult {
        type: "max";
        value: number;
    }
    
    export type MaxResult = _MaxResult;

    export namespace MaxResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.Max$MaxResult in PolyJSON mappings */
        export const type = "max";
    }
}