import type { CardResult } from './card-result';
import type { Filter } from './../../compute/filtering/filter';
import type { HeatmapParams } from './common/heatmap-params';
import type { MultivariateCard } from './multivariate-card';
import type { SplitBySpec } from './../models/split-by-spec';
import type { _CardResult } from './card-result';
import type { _Filter } from './../../compute/filtering/filter';
import type { _HeatmapParams } from './common/heatmap-params';
import type { _MultivariateCard } from './multivariate-card';
import type { _SplitBySpec } from './../models/split-by-spec';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.PCACard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _PCACard extends _MultivariateCard {
    type: "pca";
    colorBy?: SplitBySpec | null;
    heatmapParams: HeatmapParams;
    showComponents: boolean;
    showLoadingPlot: boolean;
    showScatterPlot: boolean;
    showScreePlot: boolean;
}

export type PCACard = _PCACard;

export namespace PCACard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.PCACard in PolyJSON mappings */
    export const type = "pca";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.PCACard$PCACardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _PCACardResult extends _CardResult {
        type: "pca";
        components: number[][];
        explainedVariance: number[];
        explainedVarianceRatio: number[];
        groups: Filter[];
        projections: number[][][];
    }
    
    export type PCACardResult = _PCACardResult;

    export namespace PCACardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.PCACard$PCACardResult in PolyJSON mappings */
        export const type = "pca";
    }
}