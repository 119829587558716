import type { FilterDesc } from './../../../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type { InputDescBase } from './../joinlike/input-desc-base';
import type { VirtualInputBasedRecipePayloadParams } from './../../common/virtual-input-based-recipe-payload-params';
import type { _FilterDesc } from './../../../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type { _InputDescBase } from './../joinlike/input-desc-base';
import type { _VirtualInputBasedRecipePayloadParams } from './../../common/virtual-input-based-recipe-payload-params';

/** 
 * Translated from class com.dataiku.dip.dataflow.exec.vstack.VStackRecipePayloadParams
 * Via: UIModel annotation in the class hierarchy
 */
export interface _VStackRecipePayloadParams extends _VirtualInputBasedRecipePayloadParams<VStackRecipePayloadParams.InputDesc> {
    addOriginColumn: boolean;
    copySchemaFromDatasetWithName?: string | null;
    mode: VStackRecipePayloadParams.SchemaMergeMode;
    originColumnName: string;
    postFilter: FilterDesc;
    selectedColumns?: string[] | null;
    selectedColumnsIndexes?: number[] | null;
}

export type VStackRecipePayloadParams = _VStackRecipePayloadParams;

export namespace VStackRecipePayloadParams {
    /** 
     * Translated from class com.dataiku.dip.dataflow.exec.vstack.VStackRecipePayloadParams$InputDesc
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _InputDesc extends _InputDescBase {
        columnsMatch: string[];
        preFilter: FilterDesc;
    }
    
    export type InputDesc = _InputDesc;
    
    /** 
     * Translated from enum com.dataiku.dip.dataflow.exec.vstack.VStackRecipePayloadParams$SchemaMergeMode
     * Via: com.dataiku.dip.dataflow.exec.vstack.VStackRecipePayloadParams
     */
    export enum SchemaMergeMode {
        UNION = "UNION",
        INTERSECT = "INTERSECT",
        FROM_DATASET = "FROM_DATASET",
        FROM_INDEX = "FROM_INDEX",
        REMAP = "REMAP",
        CUSTOM = "CUSTOM",
    }

    export namespace SchemaMergeMode {
        export const uiDescriptions: { [key in VStackRecipePayloadParams.SchemaMergeMode]: string } = {
          "CUSTOM": "Custom defined schema",
          "FROM_DATASET": "Use schema from dataset",
          "FROM_INDEX": "Using column order",
          "INTERSECT": "Intersection of input schemas",
          "REMAP": "Manually remapping",
          "UNION": "Union of input schemas"
        };
    }
}