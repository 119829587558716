/** 
 * Translated from class com.dataiku.dip.labeling.text.TokenizationParam
 * Via: UIModel annotation in the class hierarchy
 */
export interface _TokenizationParam {
    engine: TokenizationParam.TokenizationEngine;
    splitMechanism: TokenizationParam.TokenizationSplitMechanism;
}

export type TokenizationParam = _TokenizationParam;

export namespace TokenizationParam {
    /** 
     * Translated from enum com.dataiku.dip.labeling.text.TokenizationParam$TokenizationEngine
     * Via: com.dataiku.dip.labeling.text.TokenizationParam
     */
    export enum TokenizationEngine {
        SIMPLE = "SIMPLE",
    }
    /** 
     * Translated from enum com.dataiku.dip.labeling.text.TokenizationParam$TokenizationSplitMechanism
     * Via: com.dataiku.dip.labeling.text.TokenizationParam
     */
    export enum TokenizationSplitMechanism {
        WHITESPACE = "WHITESPACE",
        CHARACTER = "CHARACTER",
    }
}