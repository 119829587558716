<table mat-table [dataSource]="content$">
    <!-- Multi-select check-box -->
    <ng-container matColumnDef="mass-select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item"><ng-container *ngIf="isDataSourceDataset(item) || isDataSourceExternalTable(item)">
            <input type="checkbox" #massSelectedCheckbox
                [ngModel]="isMultiSelected(item)" (ngModelChange)="multiSelect(item, massSelectedCheckbox.checked)"/>
        </ng-container></td>
    </ng-container>

    <!-- Icon -->
    <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item"><ng-container *ngIf="isDataSourceDataset(item) || isDataSourceExternalTable(item)">
                <i class="icon universe-color dataset {{isDataSourceDataset(item) ? (item._source.type_raw | typeToIcon:24 | toModernIcon:24) : 'dku-icon-data-table-24'}}"></i>
        </ng-container></td>
    </ng-container>

    <!-- Item name -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let item"><ng-container *ngIf="isDataSourceDataset(item) || isDataSourceExternalTable(item)">
            <div class="highlight"
                [matTooltip]="item._source.name" isEllipsisActive
                [innerHTML]="formatItemName(item.highlight, item._source.name) | escapeHighlightedHtml"
            ></div>
        </ng-container></td>
    </ng-container>

    <!-- Project name -->
    <ng-container matColumnDef="sourceProjectName">
        <th mat-header-cell *matHeaderCellDef> Project </th>
        <td mat-cell *matCellDef="let item"><ng-container *ngIf="isDataSourceDataset(item)">
            <div class="highlight"
                [matTooltip]="item._source.projectName || item._source.projectKey" isEllipsisActive
                [innerHTML]="formatProjectNameOrKey(item.highlight, item._source.projectName || item._source.projectKey) | escapeHighlightedHtml"
            ></div>
        </ng-container></td>
    </ng-container>

    <!-- Connection name -->
    <ng-container matColumnDef="connection">
        <th mat-header-cell *matHeaderCellDef> Connection </th>
        <td mat-cell *matCellDef="let item"><ng-container *ngIf="isDataSourceExternalTable(item)">
            <div [matTooltip]="item._source.connection" isEllipsisActive>
                {{ item._source.connection | connectionNameFormatter }}
            </div>
        </ng-container></td>
    </ng-container>

    <!-- Catalog name -->
    <ng-container matColumnDef="catalog">
        <th mat-header-cell *matHeaderCellDef> Database catalog </th>
        <td mat-cell *matCellDef="let item"><ng-container *ngIf="isDataSourceExternalTable(item)">
            <div [matTooltip]="item._source.catalog ?? ''" isEllipsisActive>
                {{ item._source.catalog }}
            </div>
        </ng-container></td>
    </ng-container>

    <!-- Schema name -->
    <ng-container matColumnDef="schema">
        <th mat-header-cell *matHeaderCellDef> Schema </th>
        <td mat-cell *matCellDef="let item"><ng-container *ngIf="isDataSourceExternalTable(item)">
            <div [matTooltip]="item._source.schema" isEllipsisActive>
                {{ item._source.schema }}
            </div>
        </ng-container></td>
    </ng-container>

    <!-- Project count-->
    <ng-container matColumnDef="usedInProjectCount">
        <th mat-header-cell *matHeaderCellDef>
            <span matTooltip="The total number of projects this dataset is used in"
                matTooltipPosition="above"
            >
                # Projects
            </span>
        </th>
        <td mat-cell *matCellDef="let item">
            <ng-container *ngIf="isDataSourceDataset(item)">
                {{ item._source.usedIn.length + 1 }}  <!-- +1 to include the source project -->
            </ng-container>
        </td>
    </ng-container>

    <!-- Columns count-->
    <ng-container matColumnDef="columnCount">
        <th mat-header-cell *matHeaderCellDef>
            <span matTooltip="Column count"
                matTooltipPosition="above"
            >
                # Columns
            </span>
        </th>
        <td mat-cell *matCellDef="let item">
            <ng-container *ngIf="isDataSourceDataset(item) || isDataSourceExternalTable(item)">
                {{ item._source.numColumns }}
            </ng-container>
        </td>
    </ng-container>

    <!-- Description-->
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let item">
            <ng-container *ngIf="isDataSourceDataset(item) || isDataSourceExternalTable(item)">
                <div [matTooltip]="getDescription(item)" isEllipsisActive>
                    {{ getDescription(item) }}
                </div>
            </ng-container>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let item; columns: displayedColumns;" (click)="selectedItemChange.emit(item)"
        [class.selected]="selectedItem === item"></tr>

</table>
