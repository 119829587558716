import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { Grouping } from './../../grouping/grouping';
import type { PValueAdjustmentMethod } from './../../stats/pvalue-adjustment-method';
import type { _AvailableResult } from './../available-result';
import type { _Grouping } from './../../grouping/grouping';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.PairwiseMoodTest
 * Via: UIModel annotation in the class hierarchy
 */
export interface _PairwiseMoodTest extends Computation._UnivariateComputation {
    type: "pairwise_mood_test";
    adjustmentMethod: PValueAdjustmentMethod;
    grouping: Grouping;
}

export type PairwiseMoodTest = _PairwiseMoodTest;

export namespace PairwiseMoodTest {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.PairwiseMoodTest in PolyJSON mappings */
    export const type = "pairwise_mood_test";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.PairwiseMoodTest$PairwiseMoodTestResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _PairwiseMoodTestResult extends _AvailableResult {
        type: "pairwise_mood_test";
        pvalues: number[];
        statistics: number[];
    }
    
    export type PairwiseMoodTestResult = _PairwiseMoodTestResult;

    export namespace PairwiseMoodTestResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.PairwiseMoodTest$PairwiseMoodTestResult in PolyJSON mappings */
        export const type = "pairwise_mood_test";
    }
}