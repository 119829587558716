/** 
 * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartVariant
 * Via: UIModel annotation in the class hierarchy
 */
export enum ChartVariant {
    normal = "normal",
    stacked_100 = "stacked_100",
    binned_xy_rect = "binned_xy_rect",
    binned_xy_hex = "binned_xy_hex",
    filled_map = "filled_map",
    donut = "donut",
    colored = "colored",
}