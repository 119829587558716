import type { CardResult } from './../card-result';
import type { CardWithConfidenceInterval } from './../common/card-with-confidence-interval';
import type { CardWithConfidenceLevel } from './../common/card-with-confidence-level';
import type { SummaryCardBuilder } from './../common/summary-card-builder';
import type { UnivariateCard } from './../univariate-card';
import type { _CardResult } from './../card-result';
import type { _CardWithConfidenceInterval } from './../common/card-with-confidence-interval';
import type { _CardWithConfidenceLevel } from './../common/card-with-confidence-level';
import type { _UnivariateCard } from './../univariate-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateSummaryStatsCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _UnivariateSummaryStatsCard extends _UnivariateCard, _CardWithConfidenceInterval, _CardWithConfidenceLevel {
    type: "univariate_summary";
    compute: UnivariateSummaryStatsCard.StatsToCompute;
    confidenceLevel: number;
    showConfidenceInterval: boolean;
}

export type UnivariateSummaryStatsCard = _UnivariateSummaryStatsCard;

export namespace UnivariateSummaryStatsCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateSummaryStatsCard in PolyJSON mappings */
    export const type = "univariate_summary";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateSummaryStatsCard$StatsToCompute
     * Via: com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateSummaryStatsCard
     */
    export interface _StatsToCompute {
        completeCount: boolean;
        distinctCount: boolean;
        iqr: boolean;
        kurtosis: boolean;
        max: boolean;
        mean: boolean;
        median: boolean;
        min: boolean;
        mode: boolean;
        nbEmpty: boolean;
        nbNonEmpty: boolean;
        nonZeroRatio: boolean;
        range: boolean;
        sem: boolean;
        skewness: boolean;
        std: boolean;
        sum: boolean;
        totalCount: boolean;
        variance: boolean;
        zeroCount: boolean;
        zeroRatio: boolean;
    }
    
    export type StatsToCompute = _StatsToCompute;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateSummaryStatsCard$UnivariateSummaryStatsCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _UnivariateSummaryStatsCardResult extends _CardResult {
        type: "univariate_summary";
        stats: SummaryCardBuilder.ComputedStat[];
    }
    
    export type UnivariateSummaryStatsCardResult = _UnivariateSummaryStatsCardResult;

    export namespace UnivariateSummaryStatsCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateSummaryStatsCard$UnivariateSummaryStatsCardResult in PolyJSON mappings */
        export const type = "univariate_summary";
    }
}