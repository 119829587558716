import { Injectable } from '@angular/core';
import { EChartZoomControlInstance } from '../../models';
import { ChartZoomControlStoreService } from './chart-zoom-control-store.service';
import { ChartZoomControl } from './chart-zoom-control.model';


@Injectable({
    providedIn: 'root'
})
export class EChartsZoomControlService extends ChartZoomControl {
    constructor(chartZoomControlStore: ChartZoomControlStoreService) {
        super(chartZoomControlStore);
    }

    create(echartInstance: any): string {
        const zoomControlInstance = new EChartZoomControlInstance();
        const zoomControlInstanceId = zoomControlInstance.id;
        this.chartZoomControlStore.set(zoomControlInstanceId, zoomControlInstance);
        this.init(zoomControlInstanceId, echartInstance);
        return zoomControlInstanceId;
    }
}
