import type { AzureMLModel } from './datamodel/azure-mlmodel';
import type { AzureMLOnlineDeployment } from './datamodel/azure-mlonline-deployment';
import type { AzureMLOnlineEndpoint } from './datamodel/azure-mlonline-endpoint';
import type { ProxyModelVersionConfiguration } from './../../savedmodels/proxymodelversions/proxy-model-version-configuration';
import type { _AzureMLModel } from './datamodel/azure-mlmodel';
import type { _AzureMLOnlineDeployment } from './datamodel/azure-mlonline-deployment';
import type { _AzureMLOnlineEndpoint } from './datamodel/azure-mlonline-endpoint';



export namespace AzureMLUtils {
    /** 
     * Translated from class com.dataiku.dip.externalinfras.azureml.AzureMLUtils$DSSAzureMLConsolidatedEndpointInfo
     * Via: com.dataiku.dip.savedmodels.proxymodelversions.ProxyModelVersionConfiguration$ConsolidatedEndpointInfo
     */
    export interface _DSSAzureMLConsolidatedEndpointInfo extends ProxyModelVersionConfiguration._ConsolidatedEndpointInfo {
        type: "DSSAzureMLConsolidatedEndpointInfo";
        deployments: AzureMLOnlineDeployment[];
        endpointInfo: AzureMLOnlineEndpoint;
        input: { [key in string]: any/* com.google.gson.JsonElement */ };
        modelByDeployment: { [key in string]: AzureMLModel };
        openAPI: string;
        retrievedByTenantId: string;
    }
    
    export type DSSAzureMLConsolidatedEndpointInfo = _DSSAzureMLConsolidatedEndpointInfo;

    export namespace DSSAzureMLConsolidatedEndpointInfo {
        /** Type of class com.dataiku.dip.externalinfras.azureml.AzureMLUtils$DSSAzureMLConsolidatedEndpointInfo in PolyJSON mappings */
        export const type = "DSSAzureMLConsolidatedEndpointInfo";
    }
}