import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { IndividualExplanationParams } from './../prediction/flow/individual-explanation-params';
import type { ValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/value-object';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _ValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/value-object';



export namespace InteractiveModelParams {
    /** 
     * Translated from class com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ComputationParams
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ComputationParams extends _ValueObject {
        applyPreparationScript: boolean;
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ComputationParams */
    export type ComputationParams =
        | InteractiveModelParams.CounterfactualsParams
        | InteractiveModelParams.ExplanationsParams
        | InteractiveModelParams.OutcomeOptimizationParams
        | InteractiveModelParams.ScoringParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$CounterfactualsParams
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _CounterfactualsParams extends InteractiveModelParams._ExplorationParams {
        type: "COUNTERFACTUALS";
    }
    
    export type CounterfactualsParams = _CounterfactualsParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ExplanationsParams
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ExplanationsParams extends InteractiveModelParams._ComputationParams {
        type: "EXPLANATIONS";
        explanationMethod?: IndividualExplanationParams.ExplanationsMethod | null;
        nExplanations: number;
    }
    
    export type ExplanationsParams = _ExplanationsParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ExplorationParams
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ExplorationParams extends InteractiveModelParams._ComputationParams {
        featureDomains: InteractiveModelParams.ExplorationParams.FeatureDomain[];
        target: any/* com.google.gson.JsonPrimitive */;
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ExplorationParams */
    export type ExplorationParams =
        | InteractiveModelParams.CounterfactualsParams
        | InteractiveModelParams.OutcomeOptimizationParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$OutcomeOptimizationParams
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _OutcomeOptimizationParams extends InteractiveModelParams._ExplorationParams {
        type: "OUTCOME_OPTIMIZATION";
    }
    
    export type OutcomeOptimizationParams = _OutcomeOptimizationParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ScoringParams
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ScoringParams extends InteractiveModelParams._ComputationParams {
        type: "SCORING";
    }
    
    export type ScoringParams = _ScoringParams;

    export namespace ComputationParams {
        /** Types of class com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ComputationParams in PolyJSON mappings */
        export const type = ["COUNTERFACTUALS", "EXPLANATIONS", "OUTCOME_OPTIMIZATION", "SCORING", ] as const;
        
        export function isComputationParams(obj: InteractiveModelParams.ComputationParams): obj is InteractiveModelParams.ComputationParams { return ["COUNTERFACTUALS", "EXPLANATIONS", "OUTCOME_OPTIMIZATION", "SCORING", ].includes(obj["type"]); }
        export function isCounterfactualsParams(obj: InteractiveModelParams.ComputationParams): obj is InteractiveModelParams.CounterfactualsParams { return obj["type"] == "COUNTERFACTUALS"; }
        export function isExplanationsParams(obj: InteractiveModelParams.ComputationParams): obj is InteractiveModelParams.ExplanationsParams { return obj["type"] == "EXPLANATIONS"; }
        export function isExplorationParams(obj: InteractiveModelParams.ComputationParams): obj is InteractiveModelParams.ExplorationParams { return ["COUNTERFACTUALS", "OUTCOME_OPTIMIZATION", ].includes(obj["type"]); }
        export function isOutcomeOptimizationParams(obj: InteractiveModelParams.ComputationParams): obj is InteractiveModelParams.OutcomeOptimizationParams { return obj["type"] == "OUTCOME_OPTIMIZATION"; }
        export function isScoringParams(obj: InteractiveModelParams.ComputationParams): obj is InteractiveModelParams.ScoringParams { return obj["type"] == "SCORING"; }
    }

    export namespace CounterfactualsParams {
        /** Type of class com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$CounterfactualsParams in PolyJSON mappings */
        export const type = "COUNTERFACTUALS";
    }

    export namespace ExplanationsParams {
        /** Type of class com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ExplanationsParams in PolyJSON mappings */
        export const type = "EXPLANATIONS";
    }

    export namespace ExplorationParams {
        /** Types of class com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ExplorationParams in PolyJSON mappings */
        export const type = ["COUNTERFACTUALS", "OUTCOME_OPTIMIZATION", ] as const;
        
        /** 
         * Translated from enum com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ExplorationParams$EmuType
         * Via: com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ExplorationParams$FeatureDomain
         */
        export enum EmuType {
            FROZEN = "FROZEN",
            NUMERICAL = "NUMERICAL",
            CATEGORICAL = "CATEGORICAL",
        }
        /** 
         * Translated from class com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ExplorationParams$FeatureDomain
         * Via: com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ExplorationParams
         */
        export interface _FeatureDomain extends _ImmutableValueObject {
            categories: string[];
            maxValue: number;
            minValue: number;
            name: string;
            type: InteractiveModelParams.ExplorationParams.EmuType;
        }
        
        export type FeatureDomain = _FeatureDomain;
    }

    export namespace OutcomeOptimizationParams {
        /** Type of class com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$OutcomeOptimizationParams in PolyJSON mappings */
        export const type = "OUTCOME_OPTIMIZATION";
    }

    export namespace ScoringParams {
        /** Type of class com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelParams$ScoringParams in PolyJSON mappings */
        export const type = "SCORING";
    }
}