import type { AbstractNSampTestCard } from './abstract-nsamp-test-card';
import type { BivariateCard } from './../bivariate-card';
import type { CardResult } from './../card-result';
import type { CardWithConfidenceLevel } from './card-with-confidence-level';
import type { KsTest2SampCard } from './../ks-test2-samp-card';
import type { MoodTest2SampCard } from './../mood-test2-samp-card';
import type { TTest2SampCard } from './../ttest2-samp-card';
import type { _BivariateCard } from './../bivariate-card';
import type { _CardResult } from './../card-result';
import type { _CardWithConfidenceLevel } from './card-with-confidence-level';
import type { _KsTest2SampCard } from './../ks-test2-samp-card';
import type { _MoodTest2SampCard } from './../mood-test2-samp-card';
import type { _TTest2SampCard } from './../ttest2-samp-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.common.Abstract2SampTestCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Abstract2SampTestCard extends _BivariateCard, _CardWithConfidenceLevel {
    confidenceLevel: number;
    value1?: string[] | null;
    value2?: string[] | null;
}

/** List of all concrete subclasses of com.dataiku.dip.eda.worksheets.cards.common.Abstract2SampTestCard */
export type Abstract2SampTestCard =
    | KsTest2SampCard
    | MoodTest2SampCard
    | TTest2SampCard;

export namespace Abstract2SampTestCard {
    /** Types of class com.dataiku.dip.eda.worksheets.cards.common.Abstract2SampTestCard in PolyJSON mappings */
    export const type = ["ks_test_2samp", "mood_test_2samp", "ttest_2samp", ] as const;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.common.Abstract2SampTestCard$Abstract2SampTestCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _Abstract2SampTestCardResult extends _CardResult {
        allGroups: AbstractNSampTestCard.GroupInfo;
        groups: AbstractNSampTestCard.GroupInfo[];
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.eda.worksheets.cards.common.Abstract2SampTestCard$Abstract2SampTestCardResult */
    export type Abstract2SampTestCardResult =
        | KsTest2SampCard.KsTest2SampCardResult
        | MoodTest2SampCard.MoodTest2SampCardResult
        | TTest2SampCard.TTest2SampCardResult;

    export namespace Abstract2SampTestCardResult {
        /** Types of class com.dataiku.dip.eda.worksheets.cards.common.Abstract2SampTestCard$Abstract2SampTestCardResult in PolyJSON mappings */
        export const type = ["ks_test_2samp", "mood_test_2samp", "ttest_2samp", ] as const;
    }
}