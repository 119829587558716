import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA  } from '@angular/material/legacy-dialog';
import { realAny } from 'dku-frontend-core';
import { Workspace } from '@model-main/workspaces/workspace';
import { Observable, Subject, merge } from 'rxjs';
import { WorkspacesError } from '../../models';
import { APIError } from '@core/dataiku-api/api-error';
import _ from 'lodash';

@Component({
    selector: 'edit-object-modal',
    templateUrl: './edit-object-modal.component.html'
})

export class EditObjectModalComponent {
    updatedWorkspaceObject: Workspace.WorkspaceObject;
    errors$: Observable<APIError | undefined>;
    underlyingErrors$ = new Subject<APIError | undefined>();

    constructor(
        public dialogRef: MatDialogRef<realAny, boolean>,
        @Inject(MAT_DIALOG_DATA) public data: {
            object: Workspace.WorkspaceObject,
            submit: (object: Workspace.WorkspaceObject) => Observable<Workspace>;
            error: WorkspacesError;
        }
    ) {
        if (data.object) {
            this.updatedWorkspaceObject = _.cloneDeep(this.data.object);
        }
        this.errors$ = merge(data.error.error$, this.underlyingErrors$);

        // Clean up error on close
        this.dialogRef.beforeClosed().subscribe(() => this.data.error.resetError());
     }

    confirm() {
        this.data.submit(this.updatedWorkspaceObject)
            .subscribe(() => this.dialogRef.close(true));
        
    }

    cancel() {
        this.dialogRef.close(false);
    }

    onError(error: APIError): void {
        this.underlyingErrors$.next(error);
    }

    resetError(): void {
        this.underlyingErrors$.next(undefined);
        this.data.error.resetError();
    }

}
