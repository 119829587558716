import type { AbstractNSampTestCard } from './common/abstract-nsamp-test-card';
import type { AbstractPairwiseTestCard } from './common/abstract-pairwise-test-card';
import type { _AbstractPairwiseTestCard } from './common/abstract-pairwise-test-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.PairwiseTTestCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _PairwiseTTestCard extends _AbstractPairwiseTestCard {
    type: "pairwise_ttest";
}

export type PairwiseTTestCard = _PairwiseTTestCard;

export namespace PairwiseTTestCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.PairwiseTTestCard in PolyJSON mappings */
    export const type = "pairwise_ttest";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.PairwiseTTestCard$PairwiseTTestCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _PairwiseTTestCardResult extends AbstractNSampTestCard._AbstractNSampleTestCardResult {
        type: "pairwise_ttest";
        pvalues: number[];
        statistics: number[];
    }
    
    export type PairwiseTTestCardResult = _PairwiseTTestCardResult;

    export namespace PairwiseTTestCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.PairwiseTTestCard$PairwiseTTestCardResult in PolyJSON mappings */
        export const type = "pairwise_ttest";
    }
}