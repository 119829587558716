import type { CardResult } from './../card-result';
import type { Filter } from './../../../compute/filtering/filter';
import type { _CardResult } from './../card-result';
import type { _Filter } from './../../../compute/filtering/filter';



export namespace GroupedCompiledCardWrapper {
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.common.GroupedCompiledCardWrapper$GroupedCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _GroupedCardResult extends _CardResult {
        type: "groups";
        groups: Filter[];
        results: CardResult[];
    }
    
    export type GroupedCardResult = _GroupedCardResult;

    export namespace GroupedCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.common.GroupedCompiledCardWrapper$GroupedCardResult in PolyJSON mappings */
        export const type = "groups";
    }
}