import type { Distribution } from './distribution';
import type { _Distribution } from './distribution';

/** 
 * Translated from class com.dataiku.dip.eda.compute.distributions.Weibull
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Weibull extends _Distribution {
    type: "weibull";
}

export type Weibull = _Weibull;

export namespace Weibull {
    /** Type of class com.dataiku.dip.eda.compute.distributions.Weibull in PolyJSON mappings */
    export const type = "weibull";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.distributions.Weibull$FittedWeibull
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FittedWeibull extends Distribution._FittedDistribution {
        type: "weibull";
        scale: number;
        shape: number;
    }
    
    export type FittedWeibull = _FittedWeibull;

    export namespace FittedWeibull {
        /** Type of class com.dataiku.dip.eda.compute.distributions.Weibull$FittedWeibull in PolyJSON mappings */
        export const type = "weibull";
    }
}