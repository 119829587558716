import type { AxisSortPrune } from './axis-sort-prune';
import type { DateAxisParams } from './date-axis-params';
import type { NumericalAxisParams } from './numerical-axis-params';
import type { Unaggregated } from './unaggregated';
import type { _AxisSortPrune } from './axis-sort-prune';
import type { _DateAxisParams } from './date-axis-params';
import type { _NumericalAxisParams } from './numerical-axis-params';

/** 
 * Translated from class com.dataiku.dip.pivot.backend.model.AxisDef
 * Via: UIModel annotation in the class hierarchy
 */
export interface _AxisDef {
    column: string;
    dateMode: Unaggregated.DateMode;
    dateParams: DateAxisParams;
    numParams: NumericalAxisParams;
    sortPrune: AxisSortPrune;
    type: AxisDef.Type;
}

export type AxisDef = _AxisDef;

export namespace AxisDef {
    /** 
     * Translated from enum com.dataiku.dip.pivot.backend.model.AxisDef$Type
     * Via: com.dataiku.dip.pivot.backend.model.Aggregation
     */
    export enum Type {
        ALPHANUM = "ALPHANUM",
        NUMERICAL = "NUMERICAL",
        DATE = "DATE",
        GEOPOINT = "GEOPOINT",
        GEOMETRY = "GEOMETRY",
        CUSTOM = "CUSTOM",
    }
}