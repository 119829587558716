import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LeafletMap } from '../interfaces';
import { ChartZoomControlInstance } from './chart-zoom-control-instance.model';

@Injectable({
    providedIn: 'root'
})
export class LeafletZoomControlInstance extends ChartZoomControlInstance {
    private map: LeafletMap;
    id: string;

    public canZoomIn$ = new BehaviorSubject<boolean>(true);
    public canZoomOut$ = new BehaviorSubject<boolean>(true);
    public canResetZoom$ = new BehaviorSubject<boolean>(true);
    public hasResetButton$ = new BehaviorSubject<boolean>(false);

    constructor() {
        super();
    }

    init(map: LeafletMap, saveMap: () => void) {
        this.map = map;
        map.on('zoomend', () => {
            this.canZoomIn$.next(this.canZoomIn());
            this.canZoomOut$.next(this.canZoomOut());
            saveMap();
        });
        map.on('moveend', () => {
            this.canZoomIn$.next(this.canZoomIn());
            this.canZoomOut$.next(this.canZoomOut());
            saveMap();
        });
    }

    zoomIn() {
        this.map.zoomIn();
    }

    zoomOut() {
        this.map.zoomOut(); 
    }

    canZoomIn() {
        return this.map.getZoom() < 18;
    }

    canZoomOut() {
        return this.map.getZoom() > 0;
    }

    resetZoom = () => {
        return;
    }

}
