import type { Filter } from './../../../compute/filtering/filter';
import type { ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _Filter } from './../../../compute/filtering/filter';
import type { _ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';



export namespace SummaryCardBuilder {
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.common.SummaryCardBuilder$ComputedStat
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ComputedStat extends _ImmutableValueObject {
        failureMessage?: string | null;
        filterValues?: Filter[] | null;
        flagName: string;
        floatValue?: number | null;
        fullLabel: string;
        intValue?: number | null;
        lower?: number | null;
        pvalue?: number | null;
        shortLabel: string;
        type: SummaryCardBuilder.ComputedStatType;
        upper?: number | null;
        warning?: string | null;
    }
    
    export type ComputedStat = _ComputedStat;
    
    /** 
     * Translated from enum com.dataiku.dip.eda.worksheets.cards.common.SummaryCardBuilder$ComputedStatType
     * Via: com.dataiku.dip.eda.worksheets.cards.common.SummaryCardBuilder$ComputedStat
     */
    export enum ComputedStatType {
        FILTERS = "FILTERS",
        FLOAT = "FLOAT",
        INT = "INT",
        FAILURE = "FAILURE",
        NOT_COMPUTED = "NOT_COMPUTED",
    }
}