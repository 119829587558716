<ng-container *ngIf="!params.params.showSummary">
    <ng-container *ngTemplateOutlet="stlDecompositionPlot"></ng-container>
</ng-container>

<div class="card-layout" *ngIf="params.params.showSummary">
    <div class="card-layout__section card-layout__section--vertical">

        <div class="card-layout__section">
            <ng-container *ngTemplateOutlet="stlDecompositionPlot"></ng-container>
        </div>

        <div class="card-layout__section">
            <div class="card-layout__title">STL parameters</div>
            <div class="card-layout__content">
                <table class="stats-table">
                    <tbody class="stats-table__body">
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Seasonal length
                                <span *ngIf="!params.params.period">(guessed)</span>
                            </th>
                            <td class="stats-table__cell"> {{results.guessedParams.period}} </td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Seasonal smoother
                            </th>
                            <td class="stats-table__cell"> {{params.params.seasonal}} </td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Trend smoother
                                <span *ngIf="!params.params.trend">(guessed)</span>
                            </th>
                            <td class="stats-table__cell">{{results.guessedParams.trendSmoother}}</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Low pass smoother
                                <span *ngIf="!params.params.trend">(guessed)</span>
                            </th>
                            <td class="stats-table__cell">{{results.guessedParams.lowPassSmoother}} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <time-series-summary class="card-layout__section" [params]="params" [results]="results"></time-series-summary>
    </div>
</div>

<ng-template #stlDecompositionPlot>
    <div class="horizontal-flex aic mbot8" *ngIf="!readOnly">
        <div class="horizontal-flex aic mleft8">
            <div class="auto-toggle-name">Expanded</div>
            <label class="dku-toggle dku-toggle-mini mright8 mleft8 dku-toggle-no-content">
                <input type="checkbox" class="control" [ngModel]="params.showCompactChart" (change)="toggleCompactChart()">
                <span></span>
            </label>
            <div class="auto-toggle-name">Compact</div>
        </div>
        <div *ngIf="params.showCompactChart" class="horizontal-flex mleft8 mtop4">
            <ng-container *ngFor="let kind of SERIES_KIND">
                <div class="horizontal-flex jcc aic mleft4 mright4 cursor-pointer" [ngStyle]="{'opacity': params.selectedSeries.includes(kind) ? 1 : 0.5}" (click)="displaySeries(kind)">
                    <span class="chart-tooltip-circle" [ngStyle]="{'background-color': getColor(kind)}"></span> {{ formatSeriesKind(kind) }}
                </div>
            </ng-container>
        </div>
    </div>
    <lazy-echart
        class="chart"
        [ngClass]="{
            'padtop16': readOnly,
            'chart--fixed-height': hasFixedHeight,
            'h100': !hasFixedHeight
        }"
        [options]="plotOptions"
        (chartDataZoom)="dataZoomClicked($event)"
        (click.stop)="false"
    ></lazy-echart>
</ng-template>
