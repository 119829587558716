import type { AzureMLResource } from './azure-mlresource';
import type { _AzureMLResource } from './azure-mlresource';

/** 
 * Translated from class com.dataiku.dip.externalinfras.azureml.datamodel.AzureMLOnlineEndpoint
 * Via: com.dataiku.dip.externalinfras.azureml.AzureMLUtils$DSSAzureMLConsolidatedEndpointInfo
 */
export interface _AzureMLOnlineEndpoint extends _AzureMLResource {
    authMode: string;
    kind: string;
    location: string;
    openapiURI: string;
    provisioningState: AzureMLOnlineEndpoint.EndpointProvisioningState;
    publicNetworkAccess: string;
    scoringURI: string;
    traffic: { [key in string]: number };
}

export type AzureMLOnlineEndpoint = _AzureMLOnlineEndpoint;

export namespace AzureMLOnlineEndpoint {
    /** 
     * Translated from enum com.dataiku.dip.externalinfras.azureml.datamodel.AzureMLOnlineEndpoint$EndpointProvisioningState
     * Via: com.dataiku.dip.externalinfras.azureml.datamodel.AzureMLOnlineEndpoint
     */
    export enum EndpointProvisioningState {
        CREATING = "CREATING",
        DELETING = "DELETING",
        UPDATING = "UPDATING",
        SUCCEEDED = "SUCCEEDED",
        FAILED = "FAILED",
        CANCELED = "CANCELED",
    }
}