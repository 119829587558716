import type { Privileges } from './../security/privileges';

/** 
 * Translated from class com.dataiku.dip.autoconfig.ParamDesc
 * Via: com.dataiku.dip.coremodel.AppHomepageTile$ProjectVariablesTile
 */
export interface _ParamDesc {
    allowDuplicates: boolean;
    allowedColumnTypes: string[];
    allowedConnectionTypes: string[];
    apiServiceParamName: string;
    canCreateDataset: boolean;
    canSelectForeign: boolean;
    clusterPermissions: Privileges.ClusterLevelPrivilegeType[];
    codeMirrorDialect: string;
    columnRole: string;
    credentialRequestSettings: ParamDesc.CredentialRequestSettings;
    datasetParamName: string;
    defaultValue: any/* java.lang.Object */;
    description: string;
    disableAutoReload: boolean;
    getChoicesFromPython: boolean;
    iconInDatasetPreview: string;
    label: string;
    languages: string;
    mandatory: boolean;
    markCreatedAsBuilt: boolean;
    maxD: number;
    maxI: number;
    minD: number;
    minI: number;
    name: string;
    parameterSetId: string;
    regexpFilter: string;
    selectChoices: ParamDesc.SelectChoice[];
    subParams: ParamDesc[];
    triggerParameters: string[];
    type: ParamDesc.Type;
    visibilityCondition: string;
    visualAnalysisParamName: string;
    wt1Loggable: boolean;
}

/**
    Translate from class com.dataiku.dip.autoconfig.ParamDesc
    @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
*/
export type ParamDesc = _ParamDesc;

export namespace ParamDesc {
    /** 
     * Translated from class com.dataiku.dip.autoconfig.ParamDesc$CredentialRequestSettings
     * Via: com.dataiku.dip.autoconfig.ParamDesc
     */
    export interface _CredentialRequestSettings {
        authorizationEndpoint: string;
        resources: string[];
        scope: string;
        tokenEndpoint: string;
        type: ParamDesc.CredentialRequestType;
    }
    
    export type CredentialRequestSettings = _CredentialRequestSettings;
    
    /** 
     * Translated from enum com.dataiku.dip.autoconfig.ParamDesc$CredentialRequestType
     * Via: com.dataiku.dip.autoconfig.ParamDesc$CredentialRequestSettings
     */
    export enum CredentialRequestType {
        SINGLE_FIELD = "SINGLE_FIELD",
        BASIC = "BASIC",
        OAUTH2 = "OAUTH2",
    }
    /** 
     * Translated from class com.dataiku.dip.autoconfig.ParamDesc$SelectChoice
     * Via: com.dataiku.dip.autoconfig.ParamDesc
     */
    export interface _SelectChoice {
        color: string;
        icon: string;
        label: string;
        selected: boolean;
        showInColumnPreview: boolean;
        value: string;
    }
    
    export type SelectChoice = _SelectChoice;
    
    /** 
     * Translated from enum com.dataiku.dip.autoconfig.ParamDesc$Type
     * Via: com.dataiku.dip.autoconfig.ParamDesc
     */
    export enum Type {
        STRING = "STRING",
        STRINGS = "STRINGS",
        INT = "INT",
        DOUBLE = "DOUBLE",
        DOUBLES = "DOUBLES",
        BOOLEAN = "BOOLEAN",
        SELECT = "SELECT",
        MULTISELECT = "MULTISELECT",
        MAP = "MAP",
        KEY_VALUE_LIST = "KEY_VALUE_LIST",
        TEXTAREA = "TEXTAREA",
        PASSWORD = "PASSWORD",
        ARRAY = "ARRAY",
        OBJECT_LIST = "OBJECT_LIST",
        COLUMN = "COLUMN",
        COLUMNS = "COLUMNS",
        PROJECT = "PROJECT",
        DATASET = "DATASET",
        DATASETS = "DATASETS",
        DATASET_COLUMN = "DATASET_COLUMN",
        DATASET_COLUMNS = "DATASET_COLUMNS",
        CONNECTION = "CONNECTION",
        CONNECTIONS = "CONNECTIONS",
        MANAGED_FOLDER = "MANAGED_FOLDER",
        FOLDER = "FOLDER",
        SAVED_MODEL = "SAVED_MODEL",
        MODEL = "MODEL",
        SCENARIO = "SCENARIO",
        API_SERVICE = "API_SERVICE",
        API_SERVICE_VERSION = "API_SERVICE_VERSION",
        BUNDLE = "BUNDLE",
        VISUAL_ANALYSIS = "VISUAL_ANALYSIS",
        ML_TASK = "ML_TASK",
        CODE_ENV = "CODE_ENV",
        CLUSTER = "CLUSTER",
        PLUGIN = "PLUGIN",
        PRESET = "PRESET",
        PRESETS = "PRESETS",
        SEPARATOR = "SEPARATOR",
        DATE = "DATE",
        CREDENTIAL_REQUEST = "CREDENTIAL_REQUEST",
        MODEL_EVALUATION_STORE = "MODEL_EVALUATION_STORE",
    }
}