<data-catalog-nav></data-catalog-nav>
<div class="catalog-home__body">
    <api-error-alert-from-context></api-error-alert-from-context>
    <div class="catalog-home__header">
        <span>Browse all of your organization's data in one place</span>
    </div>
    <div class="catalog-home__links-container">
        <div class="cluster-spacing-3x aic">
            <ul class="catalog-home__links">
                <data-catalog-home-link
                    route="^.datacollections.home"
                    src="/static/dataiku/images/data-catalog/data-collections.svg"
                    title="Data Collections"
                    description="Explore, and reuse datasets from curated lists">
                </data-catalog-home-link>
                <data-catalog-home-link
                    route="^.datasources({selectedTab: 'all'})"
                    src="/static/dataiku/images/data-catalog/datasets-and-indexed-tables.svg"
                    [title]="'Datasets' + (hasIndexedConnections ? ' & Indexed&nbsp;Tables' : '')"
                    [description]="hasIndexedConnections ? 'Search existing DSS datasets and tables from indexed connections': 'Search through datasets used in your DSS projects'">
                </data-catalog-home-link>
                <data-catalog-home-link
                    route="^.connectionexplorer"
                    src="/static/dataiku/images/data-catalog/connections-explorer.svg"
                    title="Connection Explorer"
                    description="Browse all tables in your DSS connections (SQL and metastore)">
                </data-catalog-home-link>
            </ul>
        </div>
    </div>
    <div class="catalog-home__data-collections">
        <div class="data-collections__section">
            <div class="data-collections__section__left">
                <div class="dku-grand-title">Data Collections</div>
                <a class="data-collections__link" ui-sref route="^.datacollections.home">
                    See all
                    <i class="dku-icon-chevron-right-12"></i>
                </a>
            </div>
            <button *ngIf="canCreateDataCollections"
                class="btn btn--primary btn--dku-icon"
                (click)="createDataCollection()"
            >
                <i class="dku-icon-plus-16"></i>
                New collection
            </button>
        </div>
        <div *ngIf="(items$ | async) as items; else emptyCta">
            <drag-virtual-scroll *ngIf="items.length; else emptyCta" [items]="items" [itemTemplate]="itemTemplate"
                [itemWidth]="340" [itemHeight]="180">
                <ng-template #itemTemplate let-item="item">
                    <data-collection-card [dataCollection]="item" (click)="openCollectionPage(item.id)">
                    </data-collection-card>
                </ng-template>
            </drag-virtual-scroll>
        </div>
        <ng-template #emptyCta>
            <h2 class="catalog-home__empty-data-collection-list dku-medium-title">No data collections yet</h2>
        </ng-template>
        <div class="catalog-home__popular-datasets" *ngIf="$rootScope.appConfig.enablePopularDatasets">
            <popular-datasets-table></popular-datasets-table>
        </div>
    </div>
</div>
