import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.MinTime
 * Via: UIModel annotation in the class hierarchy
 */
export interface _MinTime extends Computation._UnivariateComputation {
    type: "min_time";
}

export type MinTime = _MinTime;

export namespace MinTime {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.MinTime in PolyJSON mappings */
    export const type = "min_time";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.MinTime$MinTimeResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _MinTimeResult extends _AvailableResult {
        type: "min_time";
        timestamp: string;
    }
    
    export type MinTimeResult = _MinTimeResult;

    export namespace MinTimeResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.MinTime$MinTimeResult in PolyJSON mappings */
        export const type = "min_time";
    }
}