export namespace Privileges {
    /** 
     * Translated from enum com.dataiku.dip.security.Privileges$ClusterLevelPrivilegeType
     * Via: com.dataiku.dip.autoconfig.ParamDesc
     */
    export enum ClusterLevelPrivilegeType {
        USE = "USE",
        UPDATE = "UPDATE",
        MANAGE_USERS = "MANAGE_USERS",
    }
    /** 
     * Translated from enum com.dataiku.dip.security.Privileges$DataCollectionLevelPrivilegeType
     * Via: UIModel annotation in the class hierarchy
     */
    export enum DataCollectionLevelPrivilegeType {
        READ = "READ",
        WRITE = "WRITE",
        ADMIN = "ADMIN",
    }
    /** 
     * Translated from enum com.dataiku.dip.security.Privileges$DatasetLevelPrivilegeType
     * Via: com.dataiku.dip.security.model.GlobalScopePublicAPIKey$UnscopedDatasetPrivilege
     */
    export enum DatasetLevelPrivilegeType {
        READ_DATA = "READ_DATA",
        WRITE_DATA = "WRITE_DATA",
        READ_METADATA = "READ_METADATA",
        WRITE_METADATA = "WRITE_METADATA",
        READ_SCHEMA = "READ_SCHEMA",
        WRITE_SCHEMA = "WRITE_SCHEMA",
    }
    /** 
     * Translated from enum com.dataiku.dip.security.Privileges$WorkspaceLevelPrivilegeType
     * Via: UIModel annotation in the class hierarchy
     */
    export enum WorkspaceLevelPrivilegeType {
        READ = "READ",
        WRITE = "WRITE",
        ADMIN = "ADMIN",
    }
}