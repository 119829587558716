import type { FilterDesc } from './../../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type { StreamableDatasetSelection } from './../../datasets/streamable-dataset-selection';
import type { _FilterDesc } from './../../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type { _StreamableDatasetSelection } from './../../datasets/streamable-dataset-selection';

/** 
 * Translated from class com.dataiku.dip.analysis.model.SplitParams
 * Via: com.dataiku.dip.analysis.model.prediction.PredictionMLTask
 */
export interface _SplitParams {
    efsdDatasetSmartName: string;
    efsdTest: SplitParams.EFSDSplit;
    efsdTrain: SplitParams.EFSDSplit;
    eftdTest: SplitParams.EFTDSplit;
    eftdTrain: SplitParams.EFTDSplit;
    instanceIdRefresher: number;
    kfold: boolean;
    nFolds: number;
    splitBeforePrepare: boolean;
    ssdColumn: string;
    ssdDatasetSmartName: string;
    ssdGroupColumnName: string;
    ssdGrouped: boolean;
    ssdSeed: number;
    ssdSelection: StreamableDatasetSelection;
    ssdSplitMode: SplitParams.SplitMode;
    ssdStratified: boolean;
    ssdTrainingRatio: number;
    streamAll: boolean;
    subSamplingSeed: number;
    testOnLargerValues: boolean;
    ttPolicy: SplitParams.TrainTestPolicy;
}

export type SplitParams = _SplitParams;

export namespace SplitParams {
    /** 
     * Translated from class com.dataiku.dip.analysis.model.SplitParams$EFDSplit
     * Via: com.dataiku.dip.analysis.model.SplitParams$EFSDSplit
     */
    export interface _EFDSplit {
        filter: FilterDesc;
        selection: StreamableDatasetSelection;
    }
    
    /**
        Translate from class com.dataiku.dip.analysis.model.SplitParams$EFDSplit
        @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
    */
    export type EFDSplit = _EFDSplit;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.SplitParams$EFSDSplit
     * Via: com.dataiku.dip.analysis.model.SplitParams
     */
    export interface _EFSDSplit extends SplitParams._EFDSplit {
    }
    
    export type EFSDSplit = _EFSDSplit;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.SplitParams$EFTDSplit
     * Via: com.dataiku.dip.analysis.model.SplitParams
     */
    export interface _EFTDSplit extends SplitParams._EFDSplit {
        datasetSmartName: string;
    }
    
    export type EFTDSplit = _EFTDSplit;
    
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.SplitParams$SplitMode
     * Via: com.dataiku.dip.analysis.model.SplitParams
     */
    export enum SplitMode {
        RANDOM = "RANDOM",
        SORTED = "SORTED",
    }
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.SplitParams$TrainTestPolicy
     * Via: com.dataiku.dip.analysis.model.SplitParams
     */
    export enum TrainTestPolicy {
        SPLIT_SINGLE_DATASET = "SPLIT_SINGLE_DATASET",
        EXPLICIT_FILTERING_SINGLE_DATASET = "EXPLICIT_FILTERING_SINGLE_DATASET",
        EXPLICIT_FILTERING_TWO_DATASETS = "EXPLICIT_FILTERING_TWO_DATASETS",
    }
}