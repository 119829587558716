import type { UnitRootTestCard } from './unit-root-test-card';
import type { UnitRootTestKPSS } from './../../compute/computations/timeseries/unit-root-test-kpss';
import type { _UnitRootTestCard } from './unit-root-test-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.UnitRootTestKPSSCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _UnitRootTestKPSSCard extends _UnitRootTestCard {
    type: "unit_root_test_kpss";
    regressionMode: UnitRootTestKPSS.RegressionMode;
}

export type UnitRootTestKPSSCard = _UnitRootTestKPSSCard;

export namespace UnitRootTestKPSSCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.UnitRootTestKPSSCard in PolyJSON mappings */
    export const type = "unit_root_test_kpss";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.UnitRootTestKPSSCard$UnitRootTestKPSSCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _UnitRootTestKPSSCardResult extends UnitRootTestCard._UnitRootTestCardResult {
        type: "unit_root_test_kpss";
    }
    
    export type UnitRootTestKPSSCardResult = _UnitRootTestKPSSCardResult;

    export namespace UnitRootTestKPSSCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.UnitRootTestKPSSCard$UnitRootTestKPSSCardResult in PolyJSON mappings */
        export const type = "unit_root_test_kpss";
    }
}