import type { UnitRootTestADF } from './../../compute/computations/timeseries/unit-root-test-adf';
import type { UnitRootTestCard } from './unit-root-test-card';
import type { _UnitRootTestCard } from './unit-root-test-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.UnitRootTestADFCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _UnitRootTestADFCard extends _UnitRootTestCard {
    type: "unit_root_test_adf";
    regressionMode: UnitRootTestADF.RegressionMode;
}

export type UnitRootTestADFCard = _UnitRootTestADFCard;

export namespace UnitRootTestADFCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.UnitRootTestADFCard in PolyJSON mappings */
    export const type = "unit_root_test_adf";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.UnitRootTestADFCard$UnitRootTestADFCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _UnitRootTestADFCardResult extends UnitRootTestCard._UnitRootTestCardResult {
        type: "unit_root_test_adf";
    }
    
    export type UnitRootTestADFCardResult = _UnitRootTestADFCardResult;

    export namespace UnitRootTestADFCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.UnitRootTestADFCard$UnitRootTestADFCardResult in PolyJSON mappings */
        export const type = "unit_root_test_adf";
    }
}