export namespace CodeEnvModel {
    /** 
     * Translated from enum com.dataiku.dip.code.CodeEnvModel$EnvLang
     * Via: com.dataiku.dip.security.model.GlobalScopePublicAPIKey
     */
    export enum EnvLang {
        PYTHON = "PYTHON",
        R = "R",
        JULIA = "JULIA",
    }
    /** 
     * Translated from class com.dataiku.dip.code.CodeEnvModel$PermissionItem
     * Via: com.dataiku.dip.security.model.GlobalScopePublicAPIKey
     */
    export interface _PermissionItem {
        group: string;
        manageUsers: boolean;
        update: boolean;
        use: boolean;
    }
    
    export type PermissionItem = _PermissionItem;
    
    /** 
     * Translated from class com.dataiku.dip.code.CodeEnvModel$UsedCodeEnvRef
     * Via: com.dataiku.dip.coremodel.AppManifest
     */
    export interface _UsedCodeEnvRef {
        envLang: CodeEnvModel.EnvLang;
        envName: string;
    }
    
    export type UsedCodeEnvRef = _UsedCodeEnvRef;
}