import { Component, forwardRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import _ from 'lodash';
import { DkuValidators } from '@utils/dku-validators';
import { produce } from 'immer';


@Component({
    selector: 'simple-number-list',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SimpleNumberListComponent),
            multi: true
        }
    ],
    templateUrl: './simple-number-list.component.html',
    styleUrls: ['./simple-number-list.component.less']
})
export class SimpleNumberListComponent implements ControlValueAccessor {
    editedItems: number[];

    addForm: UntypedFormGroup;

    onChange: Function;
    onTouched: Function;

    constructor(
        private fb: UntypedFormBuilder
    ) {
        this.addForm = this.fb.group({
            value: this.fb.control(null, [Validators.required, DkuValidators.float()])
        });
    }

    add(event: Event) {
        const newItem: number = parseFloat(this.addForm.controls.value.value);
        if (!this.editedItems) { this.editedItems = []; }
        this.editedItems = [...this.editedItems, newItem];
        this.editedItems = _.uniq(this.editedItems.sort((x, y) => x - y));
        if (this.onChange) { this.onChange(this.editedItems); }
        this.addForm.reset({ value: null });
        event.preventDefault();
    }

    remove(index: number) {
        this.editedItems = produce(this.editedItems, editedItems => { editedItems.splice(index, 1); });
        if (this.onChange) { this.onChange(this.editedItems); }
    }

    writeValue(obj: any): void {
        this.editedItems = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.addForm.disable() : this.addForm.enable();
    }
}
