<div class="labeling-data-tab">
    <labeling-error></labeling-error>
    <div class="section">
        <div class="paned-page-section__heading mbot16">Data</div>
        <form [formGroup]="form" *ngIf="metadataColumns" class="form-inline labeling-data-tab__form">
            <div class="control-group">
                <label class="control-label">
                    Text column
                </label>
                <div class="controls">
                    <ng-select
                        formControlName="dataColumn"
                        [clearable]="false"
                        [searchable]="true"
                        notFoundText="No columns found"
                        appendTo="body"
                        placeholder="Select a text column">
                        <ng-option [disabled]="!canBeTextColumn(dataColumn)" *ngFor="let dataColumn of metadataColumns" [value]="dataColumn.name">{{dataColumn.name}}</ng-option>
                    </ng-select>
                    <span class="help-inline">Column containing the text to annotate (must be a string column)</span>
                </div>
                <div *ngIf="form.controls.dataColumn.invalid" class="alert alert-warning mtop8 faic">
                    <i class="dku-icon-warning-filled-16 mright4"></i>
                    The text column is required.
                </div>
            </div>
            <div class="control-group">
                <label for="specifyIdColumnCheckBox" class="control-label">Specify an ID column</label>
                <div class="controls">
                    <input type="checkbox"
                        #specifyIdColumnCheckBox
                        id="specifyIdColumnCheckBox"
                        [checked]="specifyIdColumn"
                        (change)="toggleSpecifyIdColumn(specifyIdColumnCheckBox.checked)"/>
                    <div class="help-inline">Whether a column from the dataset should be used as the ID</div>
                </div>
            </div>

            <div class="control-group" *ngIf="specifyIdColumnCheckBox.checked">
                <label class="control-label">
                    ID column
                </label>
                <div class="controls">
                    <ng-select
                        formControlName="idColumn"
                        [clearable]="false"
                        [searchable]="true"
                        notFoundText="No columns found"
                        appendTo="body"
                        placeholder="Select an ID column">
                        <ng-option [disabled]="!canBeIDColumn(idColumn)" *ngFor="let idColumn of metadataColumns" [value]="idColumn.name">{{idColumn.name}}</ng-option>
                    </ng-select>
                    <span class="help-inline">(Optional) Column containing a unique identifier (must be a numeric or string column)</span>
                </div>
                <div  *ngIf="(hasAnswers$ | async) && (idColumnChanged$ | async)" class="alert alert-warning mtop8 faic">
                    <i class="dku-icon-warning-filled-16 mright4"></i>
                    ID column has been changed. Existing annotations will be lost if you save these new settings.
                </div>
            </div>
            <div class="control-group">
                <label class="control-label">
                    Context columns
                </label>
                <div class="controls">
                    <basic-select
                        formControlName="extraColumns"
                        [items]="metadataColumns"
                        [searchable]="true"
                        [multiple]="true"
                        [bindLabel]="'name'"
                        [bindValue]="'name'"
                     ></basic-select>
                    <span class="help-inline">(Optional) Columns shown alongside the text in Annotation and Review</span>
                </div>
            </div>
        </form>
    </div>
</div>
