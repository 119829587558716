/** 
 * Translated from class com.dataiku.dip.code.CodeEnvSelection
 * Via: com.dataiku.dip.analysis.model.MLTask
 */
export interface _CodeEnvSelection {
    envMode: CodeEnvSelection.EnvMode;
    envName: string;
}

export type CodeEnvSelection = _CodeEnvSelection;

export namespace CodeEnvSelection {
    /** 
     * Translated from enum com.dataiku.dip.code.CodeEnvSelection$EnvMode
     * Via: com.dataiku.dip.code.CodeEnvSelection
     */
    export enum EnvMode {
        USE_BUILTIN_MODE = "USE_BUILTIN_MODE",
        INHERIT = "INHERIT",
        EXPLICIT_ENV = "EXPLICIT_ENV",
    }
}