import { Injectable } from '@angular/core';
import { SerializedProject } from '@model-main/coremodel/serialized-project';
import { ProjectsCRUDController } from '@model-main/server/controllers/projects-crudcontroller';
import { ProjectsService } from '@model-main/server/services/projects-service';
import { ProjectSummary } from '@shared/models';
import { DkuHttpService, InfoMessage } from 'dku-frontend-core';
import { ExposedObjectsService } from 'generated-sources';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PrivateProjectsAPI {
    constructor(private dkuHttp: DkuHttpService) { }

    getSummary(projectKey: string): Observable<ProjectSummary> {
        return this.dkuHttp.request('GET', '/projects/get-summary', { projectKey }, false);
    }

    listHeads(requiredPrivilege?: string | null): Observable<ProjectsCRUDController.ProjectHead[]> {
        return this.dkuHttp.request('GET', '/projects/list-heads', { requiredPrivilege }, false);
    }

    getDashboardAuthorizations(projectKey: string, withLicensingCheck = true): Observable<SerializedProject.ProjectDashboardAuthorizations> {
        return this.dkuHttp.request('GET', '/projects/get-dashboard-authorizations', { projectKey, withLicensingCheck }, false);
    }

    list(): Observable<ProjectsService.UIProject[]> {
        return this.dkuHttp.request('GET', '/projects/list', undefined, false);
    }

    addExposedObject(projectKey: string, type: string, objectId: string, targetProjectKey: string, targetZoneId?: string): Observable<InfoMessage> {
        return this.dkuHttp.request('POST', '/projects/add-exposed-object', {
            projectKey,
            type,
            objectId,
            targetProjectKey,
            targetZoneId
        }, false);
    }

    getObjectAuthorizations(projectKey: string, objectType: string, objectId: string): Observable<ExposedObjectsService.ObjectAuthorizations> {
        return this.dkuHttp.request('GET', '/projects/get-object-authorizations', {
            projectKey,
            objectType,
            objectId,
        }, false);
    }
}
