import type { Grouping } from './grouping';
import type { GroupingResult } from './grouping-result';
import type { _Grouping } from './grouping';
import type { _GroupingResult } from './grouping-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.grouping.SubsampledGrouping
 * Via: UIModel annotation in the class hierarchy
 */
export interface _SubsampledGrouping extends _Grouping {
    type: "subsampled";
    maxRows: number;
    seed: number;
}

export type SubsampledGrouping = _SubsampledGrouping;

export namespace SubsampledGrouping {
    /** Type of class com.dataiku.dip.eda.compute.grouping.SubsampledGrouping in PolyJSON mappings */
    export const type = "subsampled";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.grouping.SubsampledGrouping$SubsampledGroupingResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _SubsampledGroupingResult extends _GroupingResult {
        type: "subsampled";
    }
    
    export type SubsampledGroupingResult = _SubsampledGroupingResult;

    export namespace SubsampledGroupingResult {
        /** Type of class com.dataiku.dip.eda.compute.grouping.SubsampledGrouping$SubsampledGroupingResult in PolyJSON mappings */
        export const type = "subsampled";
    }
}