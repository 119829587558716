import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MoodTestNSampCard, OneWayANOVACard, MoodTest2SampCard, TTest2SampCard, Card } from 'src/generated-sources';
import { CardAction } from '@features/eda/worksheet/cards/events';

type SupportedCard =
    MoodTestNSampCard
    | OneWayANOVACard
    | MoodTest2SampCard
    | TTest2SampCard;

type SupportedCardResult =
    MoodTestNSampCard.MoodTestNSampCardResult
    | OneWayANOVACard.OneWayANOVACardResult
    | MoodTest2SampCard.MoodTest2SampCardResult
    | TTest2SampCard.TTest2SampCardResult;

@Component({
    selector: 'generic-nsamp-test-card-body',
    templateUrl: './generic-nsamp-test-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/test-conclusion.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/card-layout.less',
        './generic-nsamp-test-card-body.component.less'
    ]
})
export class GenericNSampTestCardBodyComponent {
    @Input() results: SupportedCardResult;
    @Input() params: SupportedCard;
    @Output() action = new EventEmitter<CardAction>();

    get isRejected() {
        return this.results.pvalue <= (1 - this.params.confidenceLevel);
    }

    get isMedianRelevant() {
        return this.locationParameterName === 'median';
    }

    get isMeanRelevant() {
        return this.locationParameterName === 'mean';
    }

    get locationParameterName() {
        switch (this.params.type) {
            case 'oneway_anova':
            case 'ttest_2samp':
                return 'mean';
            case 'mood_nsamp':
            case 'mood_test_2samp':
                return 'median';
        }
    }

    get hasTwoSamples() {
        return Card.isAbstract2SampTestCard(this.params);
    }

    get testStatisticName() {
        switch (this.params.type) {
            case 'oneway_anova':
                return 'F-value';
            case 'mood_nsamp':
            case 'mood_test_2samp':
                return 'Pearson’s chi-square statistic';
            case 'ttest_2samp':
                return '𝘵-statistic';
        }
    }

    get boxPlotsMin(): number {
        return this.results.allGroups.boxPlot.min ?? 0;
    }

    get boxPlotsMax(): number {
        return this.results.allGroups.boxPlot.max ?? 0;
    }
}
