import { Component, OnInit, ChangeDetectionStrategy, Input, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import _ from 'lodash';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { ProjectsService } from '../../../../generated-sources';

type ProjectKey = ProjectsService.UIProject['projectKey'];

@Component({
    selector: 'dss-project-selector',
    templateUrl: './project-selector.component.html',
    styleUrls: ['./project-selector.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: ProjectSelectorComponent,
        multi: true
    }]
})
export class ProjectSelectorComponent implements OnInit, ControlValueAccessor {
    @Input() id: string;
    @Input() set projects(projects: ProjectsService.UIProject[] | null) {
        if (projects === null) return;

        this.projects$.next(projects);
    }

    touched = false;
    destroyRef = inject(DestroyRef);

    readonly projectControl = this.fb.control(null);
    readonly searchControl = this.fb.control('');
    readonly projects$ = new ReplaySubject<ProjectsService.UIProject[]>(1);

    readonly filteredProjects$ = combineLatest([this.projects$, this.searchControl.valueChanges.pipe(startWith(this.searchControl.value)) as Observable<string>])
        .pipe(
            map(([projects, filter]) => this.filterProjects(projects, filter)),
        );

    private onChange?: (projectKey: ProjectKey) => void;
    private onTouched?: () => void;

    constructor(private readonly fb: UntypedFormBuilder) { }

    ngOnInit(): void {
        this.handleProjectSelection();
    }

    writeValue(projectKey: ProjectKey): void {
        this.projectControl.setValue(projectKey, { emitEvent: false });
    }

    registerOnChange(onChange: (projectKey: ProjectKey) => void): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    private markAsTouched(): void {
        if (!this.touched) {
            this.onTouched?.();
            this.touched = true;
        }
    }

    searchFn(filter: string, project: ProjectsService.UIProject): boolean {
        const lowerCaseFilter = filter.toLowerCase();
        const nameFilter = project.name.toLowerCase().includes(lowerCaseFilter);
        const projectKeyFilter = project.projectKey.toLowerCase().includes(lowerCaseFilter);
        return nameFilter || projectKeyFilter;
    }

    private filterProjects(projects: ProjectsService.UIProject[], filter: string): ProjectsService.UIProject[] {
        const lowerCaseFilter = filter.toLowerCase();

        return projects.filter(project => {
            const nameFilter = project.name.toLowerCase().includes(lowerCaseFilter);
            const projectKeyFilter = project.projectKey.toLowerCase().includes(lowerCaseFilter);

            return nameFilter || projectKeyFilter;
        });
    }

    private handleProjectSelection(): void {
        this.projectControl.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((projectKey: ProjectKey) => {
                this.onChange?.(projectKey);
                this.markAsTouched();
            }
            )).subscribe();
    }
}
