import type { PreprocessingParams } from './preprocessing-params';
import type { _PreprocessingParams } from './preprocessing-params';

/** 
 * Translated from class com.dataiku.dip.analysis.model.preprocessing.ClusteringPreprocessingParams
 * Via: com.dataiku.dip.analysis.model.clustering.ClusteringMLTask
 */
export interface _ClusteringPreprocessingParams extends _PreprocessingParams {
    outliers: ClusteringPreprocessingParams.OutliersParams;
    reduce: ClusteringPreprocessingParams.ReductionParams;
}

export type ClusteringPreprocessingParams = _ClusteringPreprocessingParams;

export namespace ClusteringPreprocessingParams {
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.preprocessing.ClusteringPreprocessingParams$OutliersHandlingMethod
     * Via: com.dataiku.dip.analysis.model.preprocessing.ClusteringPreprocessingParams$OutliersParams
     */
    export enum OutliersHandlingMethod {
        NONE = "NONE",
        DROP = "DROP",
        CLUSTER = "CLUSTER",
    }
    /** 
     * Translated from class com.dataiku.dip.analysis.model.preprocessing.ClusteringPreprocessingParams$OutliersParams
     * Via: com.dataiku.dip.analysis.model.preprocessing.ClusteringPreprocessingParams
     */
    export interface _OutliersParams {
        method: ClusteringPreprocessingParams.OutliersHandlingMethod;
        min_cum_ratio: number;
        min_n: number;
    }
    
    export type OutliersParams = _OutliersParams;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.preprocessing.ClusteringPreprocessingParams$ReductionParams
     * Via: com.dataiku.dip.analysis.model.preprocessing.ClusteringPreprocessingParams
     */
    export interface _ReductionParams {
        disable: boolean;
        enable: boolean;
        kept_variance: number;
    }
    
    export type ReductionParams = _ReductionParams;
}