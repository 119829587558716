/** 
 * Translated from class com.dataiku.dip.pivot.backend.model.NumericalAxisParams
 * Via: UIModel annotation in the class hierarchy
 */
export interface _NumericalAxisParams {
    binSize: number;
    emptyBinsMode: NumericalAxisParams.EmptyBinsMode;
    mode: NumericalAxisParams.BinningMode;
    nbBins: number;
    niceBounds: boolean;
}

export type NumericalAxisParams = _NumericalAxisParams;

export namespace NumericalAxisParams {
    /** 
     * Translated from enum com.dataiku.dip.pivot.backend.model.NumericalAxisParams$BinningMode
     * Via: com.dataiku.dip.pivot.backend.model.NumericalAxisParams
     */
    export enum BinningMode {
        NONE = "NONE",
        FIXED_NB = "FIXED_NB",
        FIXED_SIZE = "FIXED_SIZE",
    }
    /** 
     * Translated from enum com.dataiku.dip.pivot.backend.model.NumericalAxisParams$EmptyBinsMode
     * Via: com.dataiku.dip.pivot.backend.model.NumericalAxisParams
     */
    export enum EmptyBinsMode {
        ZEROS = "ZEROS",
        DASHED = "DASHED",
        AVERAGE = "AVERAGE",
    }
}