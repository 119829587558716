import { resolveSmartName } from "@utils/loc";
import { Component, Input, Inject, OnInit, EventEmitter, Output, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { fairAny, isNumeric } from 'dku-frontend-core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { DatasetSelection } from 'src/generated-sources';

@Component({
    selector: 'partition-selection',
    templateUrl: './partition-selection.component.html',
    styleUrls: ['./partition-selection.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartitionSelectionComponent implements OnInit, OnChanges {
    @Input() partitionSelectionMethod: DatasetSelection.PartitionSelectionMethod | null;
    @Input() selectedPartitions: string[] | null;
    @Input() latestPartitionsN: number | null;
    @Input() projectKey: string;
    @Input() datasetSmartName: string;
    @Output() partitionSelectionMethodChange = new EventEmitter<DatasetSelection.PartitionSelectionMethod | null>();
    @Output() selectedPartitionsChange = new EventEmitter<string[] | null>();
    @Output() latestPartitionsNChange = new EventEmitter<number | null>();

    form = this.formBuilder.group({
        partitionSelectionMethod: new UntypedFormControl(undefined, [Validators.required]),
        selectedPartitions: new UntypedFormControl(undefined, [Validators.required]),
        latestPartitionsN: new UntypedFormControl(undefined, [Validators.required])
    });


    partitionsList: any[] | null = null;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private dataikuAPIService: DataikuAPIService,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject('SamplingData') public SamplingData: fairAny) {
    }

    ngOnInit(): void {
        this.form.get('partitionSelectionMethod')!.valueChanges.subscribe(
            value => this.partitionSelectionMethodChange.emit(value)
        );

        this.form.get('selectedPartitions')!.valueChanges.subscribe(
            value => {
                this.selectedPartitionsChange.emit((typeof value === "string") ? [value] : value);
            }
        );

        this.form.get('latestPartitionsN')!.valueChanges.subscribe(
            value => this.latestPartitionsNChange.emit(value)
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.form.patchValue({
            partitionSelectionMethod: this.partitionSelectionMethod,
            selectedPartitions: this.selectedPartitions,
            latestPartitionsN: this.latestPartitionsN
        });
    }

    loadPartitionsList(): void {
        const nameInfo = resolveSmartName(this.projectKey, this.datasetSmartName);
        this.dataikuAPIService.datasets.listPartitionsWithName(
            nameInfo.projectKey,
            nameInfo.id)
            .subscribe(
                (response: any) => {
                    this.partitionsList = response.sort((a: any, b: any) => {
                        if (isNumeric(a) && isNumeric(b)) {
                            return (b as number) - (a as number);
                        } else {
                            return a === b ? 0 : (a < b ? 1 : -1);
                        }
                    });
                    this.changeDetectorRef.detectChanges();
                });
    }

    get preparedPartitionSelectionMethods(): any[] {
        const ret: any[] = [];
        for (const x of this.SamplingData.partitionSelectionMethods) {
            ret.push({
                key: x[0],
                value: x[1]
            });
        }
        return ret;
    }
}
