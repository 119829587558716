<div class="control-group">
    <div class="df" [formGroup]="ticksConfigForm">
        <div class="mright8 number-of-ticks-selector">
            <ng-select 
                formControlName="mode"
                class="no-global-contextual-menu-close"
                bindLabel="label" 
                bindValue="value"
                [clearable]="false"
                [searchable]="false"
                appendTo="body" 
                [items]="chartFeaturesService.getAxisTicksModes()">
            </ng-select>
        </div>
        <label [ngClass]="{'disabled': isDisabled}" class="number-of-ticks-label control-label">
            of ticks
        </label>
        <div [ngClass]="{'add-icon-warning': !(ticksAreOverlapping || isDisabled), 'number-of-ticks-input--disabled': isDisabled }" 
            class="number-of-ticks-input controls">
            <input type="number" formControlName="number" placeholder="Auto" 
                min="0" 
                max="{{ticksConfigForm.get('mode')?.value === 'NUMBER' ? 500 : null}}" 
                step="{{ticksConfigForm.get('mode')?.value === 'NUMBER' ? 1 : 'any'}}" />
        </div>
        <dss-warning-icon *ngIf="ticksAreOverlapping && !isDisabled" [tooltip]="tooltip"></dss-warning-icon>
        <ng-container *ngIf="disabledTooltip$ | async as disabledTooltip">
            <div *ngIf="isDisabled" class="number-of-ticks-info-icon df aic" [matTooltip]="disabledTooltip" matTooltipPosition="above" container="body">
                <i class="dku-icon-question-circle-fill-16"></i>
            </div>
        </ng-container>
    </div>
</div>
