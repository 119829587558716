import { Injectable } from '@angular/core';
import { isNil } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class ChartZoomLoaderService {
    displayLoader(show = true, id?: string): void {
        const selector = isNil(id) ? '.dku-loader-in-chart' : `.dku-loader-in-chart_${id}`;
        const zoomingLoader = document.querySelector(selector);

        if (show === true) {
            zoomingLoader?.classList.add('dku-loader-in-chart--active');
        } else {
            zoomingLoader?.classList.remove('dku-loader-in-chart--active');
        }
    }
}
