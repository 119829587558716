import type { Curve } from './curve';
import type { _Curve } from './curve';

/** 
 * Translated from class com.dataiku.dip.eda.compute.curves.PolynomialCurve
 * Via: UIModel annotation in the class hierarchy
 */
export interface _PolynomialCurve extends _Curve {
    type: "polynomial";
    degree: number;
}

export type PolynomialCurve = _PolynomialCurve;

export namespace PolynomialCurve {
    /** Type of class com.dataiku.dip.eda.compute.curves.PolynomialCurve in PolyJSON mappings */
    export const type = "polynomial";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.curves.PolynomialCurve$ParametrizedPolynomialCurve
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ParametrizedPolynomialCurve extends Curve._ParametrizedCurve {
        type: "polynomial";
        coefs: number[];
    }
    
    export type ParametrizedPolynomialCurve = _ParametrizedPolynomialCurve;

    export namespace ParametrizedPolynomialCurve {
        /** Type of class com.dataiku.dip.eda.compute.curves.PolynomialCurve$ParametrizedPolynomialCurve in PolyJSON mappings */
        export const type = "polynomial";
    }
}