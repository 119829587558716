import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DkuMatTooltipModule } from '@shared/components/dku-mat-tooltip/dku-mat-tooltip.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { AutocompletableTextareaModule, UIRouterModule } from '@migration/modules';
import { MigrationModule } from '@migration/migration.module';
import { ComponentsModule } from '@shared/components/components.module';
import { ModalsModule } from '@shared/modals/modals.module';
import { InitialsIconModule } from '@shared/modules/initials-icon';
import { PipesModule } from '@shared/pipes/pipes.module';
import { DkuFrontendCoreModule } from 'dku-frontend-core';
import { DataikuAPIModule } from '@core/dataiku-api/dataiku-api.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
    WorkspacesLeftMenuComponent,
    EditWorkspaceModalComponent,
    WorkspaceAddObjectsComponent,
    AddObjectsModalComponent,
    EditObjectModalComponent,
    WorkspaceGeneralEditionModule,
} from './shared';
import { WorkspacesComponent } from './workspaces.component';
import { WorkspaceDatastoryComponent } from "./";
import { WorkspaceDatastoryCreationModalComponent } from "./";
import { TextOverflowTooltipModule } from '@migration/modules';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        UIRouterModule,
        MigrationModule,
        WidgetsModule,
        MatMenuModule,
        MatDialogModule,
        MatSelectModule,
        MatTabsModule,
        DkuMatTooltipModule,
        ComponentsModule,
        PipesModule,
        ModalsModule,
        DataikuAPIModule,
        DkuFrontendCoreModule,
        InitialsIconModule,
        WorkspaceGeneralEditionModule,
        ScrollingModule,
        TextOverflowTooltipModule,
        AutocompletableTextareaModule
    ],
    declarations: [
        WorkspacesComponent,
        WorkspacesLeftMenuComponent,
        EditWorkspaceModalComponent,
        AddObjectsModalComponent,
        EditObjectModalComponent,
        WorkspaceAddObjectsComponent,
        WorkspaceDatastoryComponent,
        WorkspaceDatastoryCreationModalComponent
    ]
})
export class WorkspacesModule {}
