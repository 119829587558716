import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';

/** 
 * Translated from class com.dataiku.dip.eda.compute.resampling.ResamplerSettings
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ResamplerSettings extends _ImmutableValueObject {
    duplicateTimestampsHandlingMethod: ResamplerSettings.DuplicateTimestampsHandlingMethod;
    extrapolationConstantValue?: number | null;
    extrapolationMethod: ResamplerSettings.ExtrapolationMethod;
    interpolationConstantValue?: number | null;
    interpolationMethod: ResamplerSettings.InterpolationMethod;
    nUnits: number;
    timeUnit: ResamplerSettings.TimeUnit;
    timeUnitEndOfWeek?: ResamplerSettings.DayOfWeek | null;
}

export type ResamplerSettings = _ResamplerSettings;

export namespace ResamplerSettings {
    /** 
     * Translated from enum com.dataiku.dip.eda.compute.resampling.ResamplerSettings$DayOfWeek
     * Via: com.dataiku.dip.eda.compute.resampling.ResamplerSettings
     */
    export enum DayOfWeek {
        MONDAY = "MONDAY",
        TUESDAY = "TUESDAY",
        WEDNESDAY = "WEDNESDAY",
        THURSDAY = "THURSDAY",
        FRIDAY = "FRIDAY",
        SATURDAY = "SATURDAY",
        SUNDAY = "SUNDAY",
    }
    /** 
     * Translated from enum com.dataiku.dip.eda.compute.resampling.ResamplerSettings$DuplicateTimestampsHandlingMethod
     * Via: com.dataiku.dip.eda.compute.resampling.ResamplerSettings
     */
    export enum DuplicateTimestampsHandlingMethod {
        FAIL_IF_CONFLICTING = "FAIL_IF_CONFLICTING",
        DROP_IF_CONFLICTING = "DROP_IF_CONFLICTING",
        MEAN_MODE = "MEAN_MODE",
    }
    /** 
     * Translated from enum com.dataiku.dip.eda.compute.resampling.ResamplerSettings$ExtrapolationMethod
     * Via: com.dataiku.dip.eda.compute.resampling.ResamplerSettings
     */
    export enum ExtrapolationMethod {
        PREVIOUS_NEXT = "PREVIOUS_NEXT",
        NO_EXTRAPOLATION = "NO_EXTRAPOLATION",
        CONSTANT = "CONSTANT",
        LINEAR = "LINEAR",
        QUADRATIC = "QUADRATIC",
        CUBIC = "CUBIC",
    }
    /** 
     * Translated from enum com.dataiku.dip.eda.compute.resampling.ResamplerSettings$InterpolationMethod
     * Via: com.dataiku.dip.eda.compute.resampling.ResamplerSettings
     */
    export enum InterpolationMethod {
        NEAREST = "NEAREST",
        PREVIOUS = "PREVIOUS",
        NEXT = "NEXT",
        LINEAR = "LINEAR",
        QUADRATIC = "QUADRATIC",
        CUBIC = "CUBIC",
        CONSTANT = "CONSTANT",
    }
    /** 
     * Translated from enum com.dataiku.dip.eda.compute.resampling.ResamplerSettings$TimeUnit
     * Via: com.dataiku.dip.eda.compute.resampling.ResamplerSettings
     */
    export enum TimeUnit {
        MILLISECOND = "MILLISECOND",
        SECOND = "SECOND",
        MINUTE = "MINUTE",
        HOUR = "HOUR",
        BUSINESS_DAY = "BUSINESS_DAY",
        DAY = "DAY",
        WEEK = "WEEK",
        MONTH = "MONTH",
        QUARTER = "QUARTER",
        HALF_YEAR = "HALF_YEAR",
        YEAR = "YEAR",
    }
}