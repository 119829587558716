import type { EdaErrorCodes } from './../../eda-error-codes';
import type { InteractiveQuery } from './interactive-query';
import type { _InteractiveQuery } from './interactive-query';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.queries.CheckTimeStepRegularity
 * Via: UIModel annotation in the class hierarchy
 */
export interface _CheckTimeStepRegularity extends _InteractiveQuery {
    type: "check_time_step_regularity";
    column: string;
}

export type CheckTimeStepRegularity = _CheckTimeStepRegularity;

export namespace CheckTimeStepRegularity {
    /** Type of class com.dataiku.dip.eda.worksheets.queries.CheckTimeStepRegularity in PolyJSON mappings */
    export const type = "check_time_step_regularity";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.queries.CheckTimeStepRegularity$CheckTimeStepRegularityResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _CheckTimeStepRegularityResult extends InteractiveQuery._InteractiveQueryResult {
        type: "check_time_step_regularity";
        error_code?: EdaErrorCodes | null;
        error_message?: string | null;
        warnings: string[];
    }
    
    export type CheckTimeStepRegularityResult = _CheckTimeStepRegularityResult;

    export namespace CheckTimeStepRegularityResult {
        /** Type of class com.dataiku.dip.eda.worksheets.queries.CheckTimeStepRegularity$CheckTimeStepRegularityResult in PolyJSON mappings */
        export const type = "check_time_step_regularity";
    }
}