import type { Distribution } from './distribution';
import type { _Distribution } from './distribution';

/** 
 * Translated from class com.dataiku.dip.eda.compute.distributions.Binomial
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Binomial extends _Distribution {
    type: "binomial";
    n: number;
}

export type Binomial = _Binomial;

export namespace Binomial {
    /** Type of class com.dataiku.dip.eda.compute.distributions.Binomial in PolyJSON mappings */
    export const type = "binomial";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.distributions.Binomial$FittedBinomial
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FittedBinomial extends Distribution._FittedDistribution {
        type: "binomial";
        n: number;
        p: number;
    }
    
    export type FittedBinomial = _FittedBinomial;

    export namespace FittedBinomial {
        /** Type of class com.dataiku.dip.eda.compute.distributions.Binomial$FittedBinomial in PolyJSON mappings */
        export const type = "binomial";
    }
}