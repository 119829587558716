<div [formGroup]="miscForm">
    <form class="dkuform-chart-options" *ngIf="displayCompatibleAxisForm$ | async">
        <div class="controls">
            <label>
                <input type="checkbox" formControlName="equalScales" />
                Force axes to use the same scale
            </label>

            <label>
                <input type="checkbox" formControlName="identityLine" />
                Show <em>y=x</em> line
            </label>
        </div>
    </form>
    <dss-reactive-input [inputTemplate]="numberOfRecordsForm" formControlName="numberOfRecords">
        <ng-template #numberOfRecordsForm let-value="value" let-onValueChange="onValueChange">
            <number-of-records-form *ngIf="displayNumberOfRecordsForm$ | async" id="qa_charts_number-of-records-form" class="mtop8"
                [numberOfRecords]="value"
                (numberOfRecordsChange)="onValueChange($event)">
            </number-of-records-form>
        </ng-template>
    </dss-reactive-input>
</div>
