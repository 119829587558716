import type { DatasetMetricsStatusService } from './../server/datasets/dataset-metrics-status-service';
import type { ExposedObjectsService } from './../server/services/exposed-objects-service';
import type { ITaggingService } from './../server/services/itagging-service';
import type { InterestsInternalDB } from './../dao/interests-internal-db';
import type { PublicUser } from './../security/model/public-user';
import type { Schema } from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/coremodel/schema';
import type { TaggableObjectsService } from './../server/services/taggable-objects-service';
import type { Usages } from './../featurestore/usages';
import type { _PublicUser } from './../security/model/public-user';
import type { _Schema } from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/coremodel/schema';
import type { _Usages } from './../featurestore/usages';



export namespace UIDataCatalog {
    /** 
     * Translated from class com.dataiku.dip.datacatalog.UIDataCatalog$AbstractDataCatalogItemDetails
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AbstractDataCatalogItemDetails {
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.datacatalog.UIDataCatalog$AbstractDataCatalogItemDetails */
    export type AbstractDataCatalogItemDetails =
        | UIDataCatalog.DatasetDetails
        | UIDataCatalog.DiscoverableDatasetDetails;
    
    /** 
     * Translated from class com.dataiku.dip.datacatalog.UIDataCatalog$AbstractDatasetDetails
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AbstractDatasetDetails extends UIDataCatalog._AbstractDataCatalogItemDetails {
        dataSteward?: PublicUser | null;
        datasetType: string;
        description: string;
        name: string;
        objectAuthorizations: ExposedObjectsService.ObjectAuthorizations;
        shortDesc: string;
        sourceProjectKey: string;
        sourceProjectName: string;
        tags: string[];
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.datacatalog.UIDataCatalog$AbstractDatasetDetails */
    export type AbstractDatasetDetails =
        | UIDataCatalog.DatasetDetails
        | UIDataCatalog.DiscoverableDatasetDetails;
    
    /** 
     * Translated from class com.dataiku.dip.datacatalog.UIDataCatalog$DatasetDetails
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _DatasetDetails extends UIDataCatalog._AbstractDatasetDetails {
        type: "DATASET";
        canExportData: boolean;
        createdBy: PublicUser;
        creationDate: number;
        interest: InterestsInternalDB.Interest;
        isFeatureGroup: boolean;
        lastBuiltOn: number;
        lastModificationDate: number;
        lastModifiedBy: PublicUser;
        partitioningDimensions: UIDataCatalog.DetailsDimension[];
        schema: Schema;
        splitInputsByType: { [key in ITaggingService.TaggableType]: TaggableObjectsService.TaggableObjectRefWithName[] };
        splitOutputsByType: { [key in ITaggingService.TaggableType]: TaggableObjectsService.TaggableObjectRefWithName[] };
        status: DatasetMetricsStatusService.DatasetSummaryStatus;
        usages: Usages;
    }
    
    export type DatasetDetails = _DatasetDetails;
    
    /** 
     * Translated from class com.dataiku.dip.datacatalog.UIDataCatalog$DetailsDimension
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _DetailsDimension {
        name: string;
        type: string;
    }
    
    export type DetailsDimension = _DetailsDimension;
    
    /** 
     * Translated from class com.dataiku.dip.datacatalog.UIDataCatalog$DiscoverableDatasetDetails
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _DiscoverableDatasetDetails extends UIDataCatalog._AbstractDatasetDetails {
        type: "DISCOVERABLE-DATASET";
    }
    
    export type DiscoverableDatasetDetails = _DiscoverableDatasetDetails;

    export namespace AbstractDataCatalogItemDetails {
        /** Types of class com.dataiku.dip.datacatalog.UIDataCatalog$AbstractDataCatalogItemDetails in PolyJSON mappings */
        export const type = ["DATASET", "DISCOVERABLE-DATASET", ] as const;
        
        export function isAbstractDataCatalogItemDetails(obj: UIDataCatalog.AbstractDataCatalogItemDetails): obj is UIDataCatalog.AbstractDataCatalogItemDetails { return ["DATASET", "DISCOVERABLE-DATASET", ].includes(obj["type"]); }
        export function isAbstractDatasetDetails(obj: UIDataCatalog.AbstractDataCatalogItemDetails): obj is UIDataCatalog.AbstractDatasetDetails { return ["DATASET", "DISCOVERABLE-DATASET", ].includes(obj["type"]); }
        export function isDatasetDetails(obj: UIDataCatalog.AbstractDataCatalogItemDetails): obj is UIDataCatalog.DatasetDetails { return obj["type"] == "DATASET"; }
        export function isDiscoverableDatasetDetails(obj: UIDataCatalog.AbstractDataCatalogItemDetails): obj is UIDataCatalog.DiscoverableDatasetDetails { return obj["type"] == "DISCOVERABLE-DATASET"; }
    }

    export namespace AbstractDatasetDetails {
        /** Types of class com.dataiku.dip.datacatalog.UIDataCatalog$AbstractDatasetDetails in PolyJSON mappings */
        export const type = ["DATASET", "DISCOVERABLE-DATASET", ] as const;
    }

    export namespace DatasetDetails {
        /** Type of class com.dataiku.dip.datacatalog.UIDataCatalog$DatasetDetails in PolyJSON mappings */
        export const type = "DATASET";
    }

    export namespace DiscoverableDatasetDetails {
        /** Type of class com.dataiku.dip.datacatalog.UIDataCatalog$DiscoverableDatasetDetails in PolyJSON mappings */
        export const type = "DISCOVERABLE-DATASET";
    }
}