import type { GroupProfile } from './auth/group-profile';
import type { UserRemappingRule } from './auth/user-remapping-rule';
import type { _GroupProfile } from './auth/group-profile';
import type { _UserRemappingRule } from './auth/user-remapping-rule';

/** 
 * Translated from class com.dataiku.dip.security.SSOSettings
 * Via: UIModel annotation in the class hierarchy
 */
export interface _SSOSettings {
    authorizedGroups: string;
    autoProvisionUsersAtLoginTime: boolean;
    autoSyncUsersAtLoginTime: boolean;
    defaultUserProfile: string;
    enabled: boolean;
    groupProfiles: GroupProfile[];
    openIDParams: SSOSettings.OpenIDParams;
    order?: number | null;
    protocol: SSOSettings.SSOProtocol;
    remappingRules: UserRemappingRule[];
    samlIDPMetadata: string;
    samlLoginAttribute: string;
    samlSPParams: SSOSettings.SAMLSPParams;
    spnegoKrb5File: string;
    spnegoLoginConfFile: string;
    spnegoMode: SSOSettings.SPNEGOMode;
    spnegoPreauthKeytab: string;
    spnegoPreauthLogin: string;
    spnegoPreauthPassword: string;
    spnegoPreauthPrincipal: string;
    spnegoStripRealm: boolean;
}

export type SSOSettings = _SSOSettings;

export namespace SSOSettings {
    /** 
     * Translated from class com.dataiku.dip.security.SSOSettings$OpenIDParams
     * Via: com.dataiku.dip.security.SSOSettings
     */
    export interface _OpenIDParams {
        authorizationEndpoint: string;
        claimKeyDisplayName: string;
        claimKeyEmail: string;
        claimKeyGroups: string;
        claimKeyIdentifier: string;
        clientId: string;
        clientSecret: string;
        enableGroups: boolean;
        issuer: string;
        jwksUri: string;
        prompt: string;
        scope: string;
        tokenEndpoint: string;
        tokenEndpointAuthMethod: SSOSettings.OpenIDTokenEndpointAuthMethod;
        useGlobalProxy: boolean;
        usePKCE: boolean;
    }
    
    export type OpenIDParams = _OpenIDParams;
    
    /** 
     * Translated from enum com.dataiku.dip.security.SSOSettings$OpenIDTokenEndpointAuthMethod
     * Via: com.dataiku.dip.security.SSOSettings$OpenIDParams
     */
    export enum OpenIDTokenEndpointAuthMethod {
        CLIENT_SECRET_BASIC = "CLIENT_SECRET_BASIC",
        CLIENT_SECRET_POST = "CLIENT_SECRET_POST",
    }
    /** 
     * Translated from class com.dataiku.dip.security.SSOSettings$SAMLSPParams
     * Via: com.dataiku.dip.security.SSOSettings
     */
    export interface _SAMLSPParams {
        acsURL: string;
        displayNameAttribute: string;
        emailAttribute: string;
        enableGroups: boolean;
        entityId: string;
        groupsAttribute: string;
        keyAlias: string;
        keystoreFile: string;
        keystorePassword: string;
        signRequests: boolean;
    }
    
    export type SAMLSPParams = _SAMLSPParams;
    
    /** 
     * Translated from enum com.dataiku.dip.security.SSOSettings$SPNEGOMode
     * Via: com.dataiku.dip.security.SSOSettings
     */
    export enum SPNEGOMode {
        PREAUTH_KEYTAB = "PREAUTH_KEYTAB",
        CUSTOM_LOGIN_CONF = "CUSTOM_LOGIN_CONF",
    }
    /** 
     * Translated from enum com.dataiku.dip.security.SSOSettings$SSOProtocol
     * Via: com.dataiku.dip.security.SSOSettings
     */
    export enum SSOProtocol {
        SAML = "SAML",
        SPNEGO = "SPNEGO",
        OPENID = "OPENID",
    }
}