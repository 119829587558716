import type { BoxPlotFragment } from './fragments/box-plot-fragment';
import type { CardResult } from './card-result';
import type { CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { UnivariateCard } from './univariate-card';
import type { _BoxPlotFragment } from './fragments/box-plot-fragment';
import type { _CardResult } from './card-result';
import type { _CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { _UnivariateCard } from './univariate-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.TZTest1SampCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _TZTest1SampCard extends _UnivariateCard, _CardWithConfidenceLevel {
    type: "ttest_ztest_1samp";
    confidenceLevel: number;
    hypothesizedMean: number;
    knownStdDev?: number | null;
}

export type TZTest1SampCard = _TZTest1SampCard;

export namespace TZTest1SampCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.TZTest1SampCard in PolyJSON mappings */
    export const type = "ttest_ztest_1samp";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.TZTest1SampCard$TZTest1SampCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _TZTest1SampCardResult extends _CardResult {
        type: "ttest_ztest_1samp";
        boxPlot: BoxPlotFragment;
        dof?: number | null;
        pvalue: number;
        statistic: number;
    }
    
    export type TZTest1SampCardResult = _TZTest1SampCardResult;

    export namespace TZTest1SampCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.TZTest1SampCard$TZTest1SampCardResult in PolyJSON mappings */
        export const type = "ttest_ztest_1samp";
    }
}