import type { Grouping } from './grouping';
import type { GroupingResult } from './grouping-result';
import type { _Grouping } from './grouping';
import type { _GroupingResult } from './grouping-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.grouping.BinnedGrouping
 * Via: UIModel annotation in the class hierarchy
 */
export interface _BinnedGrouping extends _Grouping {
    type: "binned";
    binSize?: number | null;
    column: string;
    keepNA: boolean;
    mode: BinnedGrouping.BinningMode;
    nbBins?: number | null;
}

export type BinnedGrouping = _BinnedGrouping;

export namespace BinnedGrouping {
    /** Type of class com.dataiku.dip.eda.compute.grouping.BinnedGrouping in PolyJSON mappings */
    export const type = "binned";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.grouping.BinnedGrouping$BinnedGroupingResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _BinnedGroupingResult extends _GroupingResult {
        type: "binned";
        column: string;
        edges: number[];
        hasNa: boolean;
    }
    
    export type BinnedGroupingResult = _BinnedGroupingResult;
    
    /** 
     * Translated from enum com.dataiku.dip.eda.compute.grouping.BinnedGrouping$BinningMode
     * Via: com.dataiku.dip.eda.compute.grouping.BinnedGrouping
     */
    export enum BinningMode {
        AUTO = "AUTO",
        FIXED_NB = "FIXED_NB",
        FIXED_SIZE = "FIXED_SIZE",
    }

    export namespace BinnedGroupingResult {
        /** Type of class com.dataiku.dip.eda.compute.grouping.BinnedGrouping$BinnedGroupingResult in PolyJSON mappings */
        export const type = "binned";
    }
}