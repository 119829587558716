<div class="dku-chart-option-box">
    <form class="dkuform-chart-options mtop8" (ngSubmit)="setNumberOfRecords()">
        <div class="control-group">
            <label class="control-label">Number of points (Max {{(max$ | async) | smartNumber:true }})</label>
            <div class="controls horizontal-flex">
                <input type="number" [placeholder]="defaultNumberOfRecords" [formControl]="numberOfRecordsControl" selectAll />
                <button class="horizontal-flex aic btn btn--secondary btn--dku-icon" [disabled]="error$ | async" onfocus="this.blur()"> <i class="dku-icon-arrow-sync-16"></i></button>
            </div>
            <div class="smaller-font error-text-color" *ngIf="error$ | async as error">
                {{error}}
            </div>
        </div>
    </form>
    <div class="horizontal-flex aic dku-text db text-weak mbot8">
        <div><i class="dku-icon-question-circle-fill-16"></i></div>
        <div>Chart display may slow down your browser over 100k points.</div>
    </div>
</div>
