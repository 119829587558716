import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { Grouping } from './../../grouping/grouping';
import type { _AvailableResult } from './../available-result';
import type { _Grouping } from './../../grouping/grouping';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.KsTest2Samp
 * Via: UIModel annotation in the class hierarchy
 */
export interface _KsTest2Samp extends Computation._UnivariateComputation {
    type: "ks_test_2samp";
    grouping: Grouping;
}

export type KsTest2Samp = _KsTest2Samp;

export namespace KsTest2Samp {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.KsTest2Samp in PolyJSON mappings */
    export const type = "ks_test_2samp";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.KsTest2Samp$KsTest2SampResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _KsTest2SampResult extends _AvailableResult {
        type: "ks_test_2samp";
        pvalue: number;
        statistic: number;
    }
    
    export type KsTest2SampResult = _KsTest2SampResult;

    export namespace KsTest2SampResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.KsTest2Samp$KsTest2SampResult in PolyJSON mappings */
        export const type = "ks_test_2samp";
    }
}