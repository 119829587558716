<div *ngIf="hasReferenceLines$ | async; then referenceLines else emptyState"></div>
<ng-template #emptyState>
    <span class="dku-text db mleftright8 text-prompt">Use reference lines to compare static values against the measures displayed in the chart.</span>
</ng-template>
<ng-template #referenceLines>
    <div class="dku-chart-option-box" *ngFor="let referenceLine of referenceLines$ | async; let index = index; trackBy: trackByFn;">
        <span class="dku-text-b mtop8 mbot4 db">Line {{index + 1}}</span>
        <button class="btn btn-trash btn--text btn--icon btn--secondary btn--dku-icon" (click)="deleteReferenceLine(index)" data-qa-charts-reference-line-trash-button>
            <i class="dku-icon-trash-12"></i>
        </button>
        <reference-line-form [axisOptionsType]="axisOptionsType$ | async" [referenceLine]="referenceLine"
            (referenceLineChange)="onReferenceLineChanged($event, index)">
        </reference-line-form>
    </div>
</ng-template>
<button class="btn btn--text btn--primary mtop8 btn--dku-icon" (click)="addReferenceLine()" data-qa-charts-add-reference-line-button>
    <i class="dku-icon-plus-16"></i>
    <span>Add a reference line</span>
</button>
