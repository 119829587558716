import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { FlexibleConnectedPositionStrategy, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { HTMLTooltipPosition } from './html-tooltip-position.enum';
import { HTMLTooltipHostComponent } from './html-tooltip-host.component';

enum PositionX {
    START = 'start',
    CENTER = 'center',
    END = 'end'
}

enum PositionY {
    TOP = 'top',
    CENTER = 'center',
    BOTTOM = 'bottom'
}

@Directive({
    selector: '[htmlTooltip]'
})
export class HTMLTooltipDirective implements OnInit, OnDestroy {
    @Input() htmlTooltip: string;
    @Input() htmlTooltipPosition: HTMLTooltipPosition = HTMLTooltipPosition.ABOVE;

    private DEFAULT_OFFSET = 5;
    private overlayRef: OverlayRef;

    constructor(
        private elementRef: ElementRef,
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private viewContainerRef: ViewContainerRef,
    ) { }

    ngOnInit(): void {
        const positionStrategy = this.getPositionStrategy();

        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.close()
        });
    }

    ngOnDestroy(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }

    private getPositionStrategy(): FlexibleConnectedPositionStrategy {
        const originPosition = this.getOriginPosition();
        const overlayPosition = this.getOverlayPosition();
        const defaultXOffset = this.getXOffset();
        const defaultYOffset = this.getYOffset();

        return this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions([
                { originX: originPosition.x, originY: originPosition.y, overlayX: overlayPosition.x, overlayY: overlayPosition.y }
            ])
            .withDefaultOffsetX(defaultXOffset)
            .withDefaultOffsetY(defaultYOffset)
            .withFlexibleDimensions(false);
    }

    private getYOffset(): number {
        switch (this.htmlTooltipPosition) {
            case HTMLTooltipPosition.LEFT:
            case HTMLTooltipPosition.RIGHT:
                return 0;
            case HTMLTooltipPosition.BELOW:
                return this.DEFAULT_OFFSET;
            default:
                return -this.DEFAULT_OFFSET;
        }
    }

    private getXOffset(): number {
        switch (this.htmlTooltipPosition) {
            case HTMLTooltipPosition.LEFT:
                return -this.DEFAULT_OFFSET;
            case HTMLTooltipPosition.RIGHT:
                return this.DEFAULT_OFFSET;
            case HTMLTooltipPosition.BELOW:
            default:
                return 0;
        }
    }

    private getOriginPosition(): { x: PositionX, y: PositionY } {
        switch (this.htmlTooltipPosition) {
            case HTMLTooltipPosition.BELOW:
                return { x: PositionX.CENTER, y: PositionY.BOTTOM };
            case HTMLTooltipPosition.LEFT:
                return { x: PositionX.START, y: PositionY.CENTER };
            case HTMLTooltipPosition.RIGHT:
                return { x: PositionX.END, y: PositionY.CENTER };
            default:
                return { x: PositionX.CENTER, y: PositionY.TOP };
        }
    }

    private getOverlayPosition(): { x: PositionX, y: PositionY } {
        switch (this.htmlTooltipPosition) {
            case HTMLTooltipPosition.BELOW:
                return { x: PositionX.CENTER, y: PositionY.TOP };
            case HTMLTooltipPosition.LEFT:
                return { x: PositionX.END, y: PositionY.CENTER };
            case HTMLTooltipPosition.RIGHT:
                return { x: PositionX.START, y: PositionY.CENTER };
            default:
                return { x: PositionX.CENTER, y: PositionY.BOTTOM };
        }
    }

    @HostListener('mouseenter')
    showTooltip(): void {
        const overlayRef = this.overlay.create({ positionStrategy: this.getPositionStrategy() });
        const tooltipPortal = new ComponentPortal(HTMLTooltipHostComponent, this.viewContainerRef);
        const componentRef = overlayRef.attach(tooltipPortal);
        componentRef.instance.content = this.htmlTooltip;
        componentRef.instance.tooltipPosition = this.htmlTooltipPosition;
        this.overlayRef = overlayRef;
    }

    @HostListener('mouseleave')
    hideTooltip(): void {
        if (this.overlayRef && this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
    }
}
