import type { BivariateCard } from './bivariate-card';
import type { CardResult } from './card-result';
import type { Curve } from './../../compute/curves/curve';
import type { FitCurve } from './../../compute/computations/bivariate/fit-curve';
import type { _BivariateCard } from './bivariate-card';
import type { _CardResult } from './card-result';
import type { _Curve } from './../../compute/curves/curve';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.FitCurveCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _FitCurveCard extends _BivariateCard {
    type: "fit_curve";
    curves: Curve[];
}

export type FitCurveCard = _FitCurveCard;

export namespace FitCurveCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.FitCurveCard in PolyJSON mappings */
    export const type = "fit_curve";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.FitCurveCard$FitCurveCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FitCurveCardResult extends _CardResult {
        type: "fit_curve";
        parametrizedCurves: FitCurve.FitCurveResult[];
        xSeries: number[];
        ySeries: number[];
    }
    
    export type FitCurveCardResult = _FitCurveCardResult;

    export namespace FitCurveCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.FitCurveCard$FitCurveCardResult in PolyJSON mappings */
        export const type = "fit_curve";
    }
}