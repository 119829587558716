import type { DatasetOrdering } from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/input/dataset-ordering';
import type { DatasetSelectionToMemTable } from './dataset-selection-to-mem-table';
import type { FilterDesc } from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type { SamplingParam } from './sampling-param';
import type { StreamableDatasetSelection } from './streamable-dataset-selection';
import type { _DatasetOrdering } from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/input/dataset-ordering';
import type { _DatasetSelectionToMemTable } from './dataset-selection-to-mem-table';
import type { _FilterDesc } from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type { _SamplingParam } from './sampling-param';
import type { _StreamableDatasetSelection } from './streamable-dataset-selection';

/** 
 * Translated from class com.dataiku.dip.datasets.DatasetSelection
 * Via: UIModel annotation in the class hierarchy
 */
export interface _DatasetSelection extends _SamplingParam {
    filter: FilterDesc;
    latestPartitionsN: number;
    ordering: DatasetOrdering;
    partitionSelectionMethod: DatasetSelection.PartitionSelectionMethod;
    selectedPartitions?: string[] | null;
}

/** List of all concrete subclasses of com.dataiku.dip.datasets.DatasetSelection */
export type DatasetSelection =
    | _DatasetSelection
    | DatasetSelectionToMemTable
    | StreamableDatasetSelection;

export namespace DatasetSelection {
    /** 
     * Translated from enum com.dataiku.dip.datasets.DatasetSelection$PartitionSelectionMethod
     * Via: com.dataiku.dip.managedfolder.ManagedFolderSelection
     */
    export enum PartitionSelectionMethod {
        ALL = "ALL",
        SELECTED = "SELECTED",
        LATEST_N = "LATEST_N",
    }
}