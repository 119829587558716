import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.Mean
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Mean extends Computation._UnivariateComputation {
    type: "mean";
    confidence?: number | null;
}

export type Mean = _Mean;

export namespace Mean {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.Mean in PolyJSON mappings */
    export const type = "mean";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.Mean$MeanResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _MeanResult extends _AvailableResult {
        type: "mean";
        lower?: number | null;
        upper?: number | null;
        value: number;
    }
    
    export type MeanResult = _MeanResult;

    export namespace MeanResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.Mean$MeanResult in PolyJSON mappings */
        export const type = "mean";
    }
}