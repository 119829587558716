import type { Aggregation } from './../../backend/model/aggregation';
import type { ChartDef } from './chart-def';
import type { DigitGrouping } from './digit-grouping';
import type { DisplayAxis } from './display-axis';
import type { DisplayType } from './display-type';
import type { LabelPosition } from './label-position';
import type { Multiplier } from './multiplier';

/** 
 * Translated from class com.dataiku.dip.pivot.frontend.model.MeasureDef
 * Via: UIModel annotation in the class hierarchy
 */
export interface _MeasureDef {
    colorRules: MeasureDef.ColorRule[];
    column: string;
    computeMode: Aggregation.ComputeMode;
    computeModeDim: number;
    customFunction?: string | null;
    decimalPlaces?: number | null;
    digitGrouping: DigitGrouping;
    displayAxis: DisplayAxis;
    displayLabel?: string | null;
    displayType: DisplayType;
    displayed: boolean;
    function: Aggregation.Function;
    isA: string;
    labelPosition?: LabelPosition | null;
    multiplier: Multiplier;
    prefix: string;
    showDisplayLabel?: boolean | null;
    suffix: string;
    type: string;
}

export type MeasureDef = _MeasureDef;

export namespace MeasureDef {
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.MeasureDef$ColorRule
     * Via: com.dataiku.dip.pivot.frontend.model.MeasureDef
     */
    export interface _ColorRule {
        colorOptions: ChartDef.ColorOptions;
        mode: MeasureDef.ColorRule.Mode;
        styleClass: string;
        value: number;
    }
    
    export type ColorRule = _ColorRule;

    export namespace ColorRule {
        /** 
         * Translated from enum com.dataiku.dip.pivot.frontend.model.MeasureDef$ColorRule$Mode
         * Via: com.dataiku.dip.pivot.frontend.model.MeasureDef$ColorRule
         */
        export enum Mode {
            LOWER_THAN = "LOWER_THAN",
            LOWER_OR_EQUAL_THAN = "LOWER_OR_EQUAL_THAN",
            GREATER_THAN = "GREATER_THAN",
            GREATER_OR_EQUAL_THAN = "GREATER_OR_EQUAL_THAN",
        }
    }
}