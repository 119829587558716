import type { ACF } from './timeseries/acf';
import type { Chi2IndTest } from './bivariate/chi2-ind-test';
import type { CorrelationFunction } from './timeseries/correlation-function';
import type { Count } from './common/count';
import type { CountDistinct } from './univariate/count-distinct';
import type { Covariance } from './bivariate/covariance';
import type { DummyComputation } from './common/dummy-computation';
import type { DurbinWatson } from './timeseries/durbin-watson';
import type { EmpiricalCDF } from './univariate/empirical-cdf';
import type { Entropy } from './univariate/entropy';
import type { FetchCSV } from './multivariate/fetch-csv';
import type { FetchValues } from './multivariate/fetch-values';
import type { Fit2DDistribution } from './bivariate/fit2-ddistribution';
import type { FitCurve } from './bivariate/fit-curve';
import type { FitDistribution } from './univariate/fit-distribution';
import type { GroupedComputation } from './common/grouped-computation';
import type { GuessTimeStep } from './univariate/guess-time-step';
import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { KendallTau } from './bivariate/kendall-tau';
import type { KsTest2Samp } from './univariate/ks-test2-samp';
import type { Kurtosis } from './univariate/kurtosis';
import type { MannKendallTest } from './timeseries/mann-kendall-test';
import type { MatchTimeStep } from './univariate/match-time-step';
import type { Max } from './univariate/max';
import type { MaxTime } from './univariate/max-time';
import type { Mean } from './univariate/mean';
import type { Min } from './univariate/min';
import type { MinTime } from './univariate/min-time';
import type { MoodTestNSamp } from './univariate/mood-test-nsamp';
import type { MultiComputation } from './common/multi-computation';
import type { MutualInformation } from './bivariate/mutual-information';
import type { OneWayAnova } from './univariate/one-way-anova';
import type { PACF } from './timeseries/pacf';
import type { PCA } from './multivariate/pca';
import type { PairwiseMoodTest } from './univariate/pairwise-mood-test';
import type { PairwiseTTest } from './univariate/pairwise-ttest';
import type { Pearson } from './bivariate/pearson';
import type { Quantiles } from './univariate/quantiles';
import type { ResampledComputation } from './common/resampled-computation';
import type { STLDecomposition } from './timeseries/stldecomposition';
import type { Sem } from './univariate/sem';
import type { Shapiro } from './univariate/shapiro';
import type { SignTest1Samp } from './univariate/sign-test1-samp';
import type { Skewness } from './univariate/skewness';
import type { Spearman } from './bivariate/spearman';
import type { StdDev } from './univariate/std-dev';
import type { Sum } from './univariate/sum';
import type { TTest1Samp } from './univariate/ttest1-samp';
import type { TestDistribution } from './univariate/test-distribution';
import type { TimeSeriesComputation } from './timeseries/time-series-computation';
import type { UnitRootTest } from './timeseries/unit-root-test';
import type { UnitRootTestADF } from './timeseries/unit-root-test-adf';
import type { UnitRootTestKPSS } from './timeseries/unit-root-test-kpss';
import type { UnitRootTestZA } from './timeseries/unit-root-test-za';
import type { Variance } from './univariate/variance';
import type { ZTest1Samp } from './univariate/ztest1-samp';
import type { _ACF } from './timeseries/acf';
import type { _Chi2IndTest } from './bivariate/chi2-ind-test';
import type { _CorrelationFunction } from './timeseries/correlation-function';
import type { _Count } from './common/count';
import type { _CountDistinct } from './univariate/count-distinct';
import type { _Covariance } from './bivariate/covariance';
import type { _DummyComputation } from './common/dummy-computation';
import type { _DurbinWatson } from './timeseries/durbin-watson';
import type { _EmpiricalCDF } from './univariate/empirical-cdf';
import type { _Entropy } from './univariate/entropy';
import type { _FetchCSV } from './multivariate/fetch-csv';
import type { _FetchValues } from './multivariate/fetch-values';
import type { _Fit2DDistribution } from './bivariate/fit2-ddistribution';
import type { _FitCurve } from './bivariate/fit-curve';
import type { _FitDistribution } from './univariate/fit-distribution';
import type { _GroupedComputation } from './common/grouped-computation';
import type { _GuessTimeStep } from './univariate/guess-time-step';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _KendallTau } from './bivariate/kendall-tau';
import type { _KsTest2Samp } from './univariate/ks-test2-samp';
import type { _Kurtosis } from './univariate/kurtosis';
import type { _MannKendallTest } from './timeseries/mann-kendall-test';
import type { _MatchTimeStep } from './univariate/match-time-step';
import type { _Max } from './univariate/max';
import type { _MaxTime } from './univariate/max-time';
import type { _Mean } from './univariate/mean';
import type { _Min } from './univariate/min';
import type { _MinTime } from './univariate/min-time';
import type { _MoodTestNSamp } from './univariate/mood-test-nsamp';
import type { _MultiComputation } from './common/multi-computation';
import type { _MutualInformation } from './bivariate/mutual-information';
import type { _OneWayAnova } from './univariate/one-way-anova';
import type { _PACF } from './timeseries/pacf';
import type { _PCA } from './multivariate/pca';
import type { _PairwiseMoodTest } from './univariate/pairwise-mood-test';
import type { _PairwiseTTest } from './univariate/pairwise-ttest';
import type { _Pearson } from './bivariate/pearson';
import type { _Quantiles } from './univariate/quantiles';
import type { _ResampledComputation } from './common/resampled-computation';
import type { _STLDecomposition } from './timeseries/stldecomposition';
import type { _Sem } from './univariate/sem';
import type { _Shapiro } from './univariate/shapiro';
import type { _SignTest1Samp } from './univariate/sign-test1-samp';
import type { _Skewness } from './univariate/skewness';
import type { _Spearman } from './bivariate/spearman';
import type { _StdDev } from './univariate/std-dev';
import type { _Sum } from './univariate/sum';
import type { _TTest1Samp } from './univariate/ttest1-samp';
import type { _TestDistribution } from './univariate/test-distribution';
import type { _TimeSeriesComputation } from './timeseries/time-series-computation';
import type { _UnitRootTest } from './timeseries/unit-root-test';
import type { _UnitRootTestADF } from './timeseries/unit-root-test-adf';
import type { _UnitRootTestKPSS } from './timeseries/unit-root-test-kpss';
import type { _UnitRootTestZA } from './timeseries/unit-root-test-za';
import type { _Variance } from './univariate/variance';
import type { _ZTest1Samp } from './univariate/ztest1-samp';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.Computation
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Computation extends _ImmutableValueObject {
}

/** List of all concrete subclasses of com.dataiku.dip.eda.compute.computations.Computation */
export type Computation =
    | Chi2IndTest
    | Covariance
    | Fit2DDistribution
    | FitCurve
    | KendallTau
    | MutualInformation
    | Pearson
    | Spearman
    | Count
    | DummyComputation
    | GroupedComputation
    | MultiComputation
    | ResampledComputation
    | FetchCSV
    | FetchValues
    | PCA
    | ACF
    | DurbinWatson
    | MannKendallTest
    | PACF
    | STLDecomposition
    | UnitRootTestADF
    | UnitRootTestKPSS
    | UnitRootTestZA
    | CountDistinct
    | EmpiricalCDF
    | Entropy
    | FitDistribution
    | GuessTimeStep
    | KsTest2Samp
    | Kurtosis
    | MatchTimeStep
    | Max
    | MaxTime
    | Mean
    | Min
    | MinTime
    | MoodTestNSamp
    | OneWayAnova
    | PairwiseMoodTest
    | PairwiseTTest
    | Quantiles
    | Sem
    | Shapiro
    | SignTest1Samp
    | Skewness
    | StdDev
    | Sum
    | TTest1Samp
    | TestDistribution
    | Variance
    | ZTest1Samp;

export namespace Computation {
    /** Types of class com.dataiku.dip.eda.compute.computations.Computation in PolyJSON mappings */
    export const type = ["chi2_ind_test", "covariance", "fit_2d_distribution", "fit_curve", "kendall_tau", "mutual_information", "pearson", "spearman", "count", "dummy", "grouped", "multi", "resampled", "fetch_csv", "fetch_values", "pca", "acf", "durbin_watson", "mann_kendall", "pacf", "stl_decomposition", "unit_root_adf", "unit_root_kpss", "unit_root_za", "count_distinct", "empirical_cdf", "entropy", "fit_distribution", "guess_time_step", "ks_test_2samp", "kurtosis", "match_time_step", "max", "max_time", "mean", "min", "min_time", "mood_test_nsamp", "one_way_anova", "pairwise_mood_test", "pairwise_ttest", "quantiles", "sem", "shapiro", "sign_test_1samp", "skewness", "std_dev", "sum", "ttest_1samp", "test_distribution", "variance", "ztest_1samp", ] as const;
    
    export function isComputation(obj: Computation): obj is Computation { return ["chi2_ind_test", "covariance", "fit_2d_distribution", "fit_curve", "kendall_tau", "mutual_information", "pearson", "spearman", "count", "dummy", "grouped", "multi", "resampled", "fetch_csv", "fetch_values", "pca", "acf", "durbin_watson", "mann_kendall", "pacf", "stl_decomposition", "unit_root_adf", "unit_root_kpss", "unit_root_za", "count_distinct", "empirical_cdf", "entropy", "fit_distribution", "guess_time_step", "ks_test_2samp", "kurtosis", "match_time_step", "max", "max_time", "mean", "min", "min_time", "mood_test_nsamp", "one_way_anova", "pairwise_mood_test", "pairwise_ttest", "quantiles", "sem", "shapiro", "sign_test_1samp", "skewness", "std_dev", "sum", "ttest_1samp", "test_distribution", "variance", "ztest_1samp", ].includes(obj["type"]); }
    export function isBivariateComputation(obj: Computation): obj is Computation.BivariateComputation { return ["covariance", "fit_2d_distribution", "fit_curve", "kendall_tau", "mutual_information", "pearson", "spearman", ].includes(obj["type"]); }
    export function isMultivariateComputation(obj: Computation): obj is Computation.MultivariateComputation { return ["fetch_csv", "fetch_values", "pca", ].includes(obj["type"]); }
    export function isUnivariateComputation(obj: Computation): obj is Computation.UnivariateComputation { return ["count_distinct", "empirical_cdf", "entropy", "fit_distribution", "guess_time_step", "ks_test_2samp", "kurtosis", "match_time_step", "max", "max_time", "mean", "min", "min_time", "mood_test_nsamp", "one_way_anova", "pairwise_mood_test", "pairwise_ttest", "quantiles", "sem", "shapiro", "sign_test_1samp", "skewness", "std_dev", "sum", "ttest_1samp", "test_distribution", "variance", "ztest_1samp", ].includes(obj["type"]); }
    export function isChi2IndTest(obj: Computation): obj is Chi2IndTest { return obj["type"] == "chi2_ind_test"; }
    export function isCovariance(obj: Computation): obj is Covariance { return obj["type"] == "covariance"; }
    export function isFit2DDistribution(obj: Computation): obj is Fit2DDistribution { return obj["type"] == "fit_2d_distribution"; }
    export function isFitCurve(obj: Computation): obj is FitCurve { return obj["type"] == "fit_curve"; }
    export function isKendallTau(obj: Computation): obj is KendallTau { return obj["type"] == "kendall_tau"; }
    export function isMutualInformation(obj: Computation): obj is MutualInformation { return obj["type"] == "mutual_information"; }
    export function isPearson(obj: Computation): obj is Pearson { return obj["type"] == "pearson"; }
    export function isSpearman(obj: Computation): obj is Spearman { return obj["type"] == "spearman"; }
    export function isCount(obj: Computation): obj is Count { return obj["type"] == "count"; }
    export function isDummyComputation(obj: Computation): obj is DummyComputation { return obj["type"] == "dummy"; }
    export function isGroupedComputation(obj: Computation): obj is GroupedComputation { return obj["type"] == "grouped"; }
    export function isMultiComputation(obj: Computation): obj is MultiComputation { return obj["type"] == "multi"; }
    export function isResampledComputation(obj: Computation): obj is ResampledComputation { return obj["type"] == "resampled"; }
    export function isFetchCSV(obj: Computation): obj is FetchCSV { return obj["type"] == "fetch_csv"; }
    export function isFetchValues(obj: Computation): obj is FetchValues { return obj["type"] == "fetch_values"; }
    export function isPCA(obj: Computation): obj is PCA { return obj["type"] == "pca"; }
    export function isACF(obj: Computation): obj is ACF { return obj["type"] == "acf"; }
    export function isCorrelationFunction(obj: Computation): obj is CorrelationFunction { return ["acf", "pacf", ].includes(obj["type"]); }
    export function isDurbinWatson(obj: Computation): obj is DurbinWatson { return obj["type"] == "durbin_watson"; }
    export function isMannKendallTest(obj: Computation): obj is MannKendallTest { return obj["type"] == "mann_kendall"; }
    export function isPACF(obj: Computation): obj is PACF { return obj["type"] == "pacf"; }
    export function isSTLDecomposition(obj: Computation): obj is STLDecomposition { return obj["type"] == "stl_decomposition"; }
    export function isTimeSeriesComputation(obj: Computation): obj is TimeSeriesComputation { return ["acf", "durbin_watson", "mann_kendall", "pacf", "stl_decomposition", "unit_root_adf", "unit_root_kpss", "unit_root_za", ].includes(obj["type"]); }
    export function isUnitRootTest(obj: Computation): obj is UnitRootTest { return ["unit_root_adf", "unit_root_kpss", "unit_root_za", ].includes(obj["type"]); }
    export function isUnitRootTestADF(obj: Computation): obj is UnitRootTestADF { return obj["type"] == "unit_root_adf"; }
    export function isUnitRootTestKPSS(obj: Computation): obj is UnitRootTestKPSS { return obj["type"] == "unit_root_kpss"; }
    export function isUnitRootTestZA(obj: Computation): obj is UnitRootTestZA { return obj["type"] == "unit_root_za"; }
    export function isCountDistinct(obj: Computation): obj is CountDistinct { return obj["type"] == "count_distinct"; }
    export function isEmpiricalCDF(obj: Computation): obj is EmpiricalCDF { return obj["type"] == "empirical_cdf"; }
    export function isEntropy(obj: Computation): obj is Entropy { return obj["type"] == "entropy"; }
    export function isFitDistribution(obj: Computation): obj is FitDistribution { return obj["type"] == "fit_distribution"; }
    export function isGuessTimeStep(obj: Computation): obj is GuessTimeStep { return obj["type"] == "guess_time_step"; }
    export function isKsTest2Samp(obj: Computation): obj is KsTest2Samp { return obj["type"] == "ks_test_2samp"; }
    export function isKurtosis(obj: Computation): obj is Kurtosis { return obj["type"] == "kurtosis"; }
    export function isMatchTimeStep(obj: Computation): obj is MatchTimeStep { return obj["type"] == "match_time_step"; }
    export function isMax(obj: Computation): obj is Max { return obj["type"] == "max"; }
    export function isMaxTime(obj: Computation): obj is MaxTime { return obj["type"] == "max_time"; }
    export function isMean(obj: Computation): obj is Mean { return obj["type"] == "mean"; }
    export function isMin(obj: Computation): obj is Min { return obj["type"] == "min"; }
    export function isMinTime(obj: Computation): obj is MinTime { return obj["type"] == "min_time"; }
    export function isMoodTestNSamp(obj: Computation): obj is MoodTestNSamp { return obj["type"] == "mood_test_nsamp"; }
    export function isOneWayAnova(obj: Computation): obj is OneWayAnova { return obj["type"] == "one_way_anova"; }
    export function isPairwiseMoodTest(obj: Computation): obj is PairwiseMoodTest { return obj["type"] == "pairwise_mood_test"; }
    export function isPairwiseTTest(obj: Computation): obj is PairwiseTTest { return obj["type"] == "pairwise_ttest"; }
    export function isQuantiles(obj: Computation): obj is Quantiles { return obj["type"] == "quantiles"; }
    export function isSem(obj: Computation): obj is Sem { return obj["type"] == "sem"; }
    export function isShapiro(obj: Computation): obj is Shapiro { return obj["type"] == "shapiro"; }
    export function isSignTest1Samp(obj: Computation): obj is SignTest1Samp { return obj["type"] == "sign_test_1samp"; }
    export function isSkewness(obj: Computation): obj is Skewness { return obj["type"] == "skewness"; }
    export function isStdDev(obj: Computation): obj is StdDev { return obj["type"] == "std_dev"; }
    export function isSum(obj: Computation): obj is Sum { return obj["type"] == "sum"; }
    export function isTTest1Samp(obj: Computation): obj is TTest1Samp { return obj["type"] == "ttest_1samp"; }
    export function isTestDistribution(obj: Computation): obj is TestDistribution { return obj["type"] == "test_distribution"; }
    export function isVariance(obj: Computation): obj is Variance { return obj["type"] == "variance"; }
    export function isZTest1Samp(obj: Computation): obj is ZTest1Samp { return obj["type"] == "ztest_1samp"; }
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.Computation$BivariateComputation
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _BivariateComputation extends _Computation {
        xColumn: string;
        yColumn: string;
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.eda.compute.computations.Computation$BivariateComputation */
    export type BivariateComputation =
        | Covariance
        | Fit2DDistribution
        | FitCurve
        | KendallTau
        | MutualInformation
        | Pearson
        | Spearman;
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.Computation$MultivariateComputation
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _MultivariateComputation extends _Computation {
        columns: string[];
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.eda.compute.computations.Computation$MultivariateComputation */
    export type MultivariateComputation =
        | FetchCSV
        | FetchValues
        | PCA;
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.Computation$UnivariateComputation
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _UnivariateComputation extends _Computation {
        column: string;
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.eda.compute.computations.Computation$UnivariateComputation */
    export type UnivariateComputation =
        | CountDistinct
        | EmpiricalCDF
        | Entropy
        | FitDistribution
        | GuessTimeStep
        | KsTest2Samp
        | Kurtosis
        | MatchTimeStep
        | Max
        | MaxTime
        | Mean
        | Min
        | MinTime
        | MoodTestNSamp
        | OneWayAnova
        | PairwiseMoodTest
        | PairwiseTTest
        | Quantiles
        | Sem
        | Shapiro
        | SignTest1Samp
        | Skewness
        | StdDev
        | Sum
        | TTest1Samp
        | TestDistribution
        | Variance
        | ZTest1Samp;

    export namespace BivariateComputation {
        /** Types of class com.dataiku.dip.eda.compute.computations.Computation$BivariateComputation in PolyJSON mappings */
        export const type = ["covariance", "fit_2d_distribution", "fit_curve", "kendall_tau", "mutual_information", "pearson", "spearman", ] as const;
    }

    export namespace MultivariateComputation {
        /** Types of class com.dataiku.dip.eda.compute.computations.Computation$MultivariateComputation in PolyJSON mappings */
        export const type = ["fetch_csv", "fetch_values", "pca", ] as const;
    }

    export namespace UnivariateComputation {
        /** Types of class com.dataiku.dip.eda.compute.computations.Computation$UnivariateComputation in PolyJSON mappings */
        export const type = ["count_distinct", "empirical_cdf", "entropy", "fit_distribution", "guess_time_step", "ks_test_2samp", "kurtosis", "match_time_step", "max", "max_time", "mean", "min", "min_time", "mood_test_nsamp", "one_way_anova", "pairwise_mood_test", "pairwise_ttest", "quantiles", "sem", "shapiro", "sign_test_1samp", "skewness", "std_dev", "sum", "ttest_1samp", "test_distribution", "variance", "ztest_1samp", ] as const;
    }
}