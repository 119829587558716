export namespace DataAugmentationService {
    /** 
     * Translated from enum com.dataiku.dip.analysis.coreservices.DataAugmentationService$AugmentationType
     * Via: UIModel annotation in the class hierarchy
     */
    export enum AugmentationType {
        AFFINE = "AFFINE",
        COLOR = "COLOR",
        CROP = "CROP",
    }
    /** 
     * Translated from class com.dataiku.dip.analysis.coreservices.DataAugmentationService$AugmentedImage
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AugmentedImage {
        failed: boolean;
        imgDataAsBase64: string;
    }
    
    export type AugmentedImage = _AugmentedImage;
}