import type { AbstractCardContainer } from './abstract-card-container';
import type { BivariateHeaderCard } from './../bivariate_header/bivariate-header-card';
import type { UnivariateHeaderCard } from './../univariate_header/univariate-header-card';
import type { Variable } from './../../models/variable';
import type { _AbstractCardContainer } from './abstract-card-container';
import type { _BivariateHeaderCard } from './../bivariate_header/bivariate-header-card';
import type { _UnivariateHeaderCard } from './../univariate_header/univariate-header-card';
import type { _Variable } from './../../models/variable';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.common.AbstractHeaderCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _AbstractHeaderCard extends _AbstractCardContainer {
    xColumns: Variable[];
}

/** List of all concrete subclasses of com.dataiku.dip.eda.worksheets.cards.common.AbstractHeaderCard */
export type AbstractHeaderCard =
    | BivariateHeaderCard
    | UnivariateHeaderCard;

export namespace AbstractHeaderCard {
    /** Types of class com.dataiku.dip.eda.worksheets.cards.common.AbstractHeaderCard in PolyJSON mappings */
    export const type = ["bivariate_header", "univariate_header", ] as const;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.common.AbstractHeaderCard$AbstractHeaderCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AbstractHeaderCardResult extends AbstractCardContainer._AbstractCardContainerResult {
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.eda.worksheets.cards.common.AbstractHeaderCard$AbstractHeaderCardResult */
    export type AbstractHeaderCardResult =
        | BivariateHeaderCard.BivariateHeaderCardResult
        | UnivariateHeaderCard.UnivariateHeaderCardResult;

    export namespace AbstractHeaderCardResult {
        /** Types of class com.dataiku.dip.eda.worksheets.cards.common.AbstractHeaderCard$AbstractHeaderCardResult in PolyJSON mappings */
        export const type = ["bivariate_header", "univariate_header", ] as const;
    }
}