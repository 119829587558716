<ng-container *ngIf="(errorMessage$ | async) === null else workspaceError">
    <ng-container *ngIf="(workspace$ | async) as workspace">
        <div class="main-panel workspace-page">
            <workspace-header [workspace]="workspace"></workspace-header>
            <api-error-alert
                [apiError]="errors$ | async"
                (resetError)="resetError()"
                [closable]="true">
            </api-error-alert>
            <main class="workspace-page__content">
                <section *ngIf="workspace.workspaceObjects.length > 0" class="workspace-page__toolbar">
                    <div class="workspace-page__toolbar-btns">
                        <workspace-object-filters
                            [objects]="workspace.workspaceObjects"
                            [filters]="objectFilters"
                            [sort]="objectSort"
                            (filtersChange)="onFiltersChanged(workspace, $event)"
                            (sortChange)="onSortChanged(workspace, $event)">
                        </workspace-object-filters>

                        <div class="btn-group workspace-page__view-btns">
                            <button class="btn btn--secondary btn--text btn--icon btn--dku-icon"
                                [class.btn--active]="displayType === WorkspaceListType.TILE"
                                (click)="switchListType(WorkspaceListType.TILE)"
                                matTooltip="Tile view"
                                matTooltipPosition="below">
                                <i class="dku-icon-table-16"></i>
                            </button>
                            <button class="btn btn--secondary btn--text btn--icon btn--dku-icon"
                                [class.btn--active]="displayType === WorkspaceListType.LIST"
                                (click)="switchListType(WorkspaceListType.LIST)"
                                matTooltip="List view"
                                matTooltipPosition="below">
                                <i class="dku-icon-list-bulleted-16"></i>
                            </button>
                        </div>
                    </div>
                </section>
                <section *ngIf="workspace.workspaceObjects.length > 0 && (filteredObjects$ | async) as filteredObjects" class="workspace-page__objects">
                    <workspace-object-list
                        [workspaceKey]="workspace.workspaceKey"
                        [objects]="filteredObjects"
                        [editable]="workspace.currentUserPermissions.write"
                        [displayType]="displayType"
                        (duplicateObject)="duplicateObject(workspace, $event)"
                        (editObject)="openEditObjectModal(workspace, $event)"
                        (removeObject)="openRemoveObjectModal(workspace, $event)"
                        (toggleStar)="toggleStar(workspace, $event.object, $event.status)">
                    </workspace-object-list>
                    <span *ngIf="filteredObjects.length === 0 && workspace.workspaceObjects.length > 0" class="workspace-page__no-results">
                        No object match your search.
                    </span>
                </section>
                <section *ngIf="workspace.workspaceObjects.length === 0" class="workspace-page__empty-state">
                    <div style="max-width: 760px;">
                        <div *ngIf="canAddContent">
                            <img src="/static/dataiku/images/workspaces/empty-workspace.svg" height="222" />
                            <h2 class="dku-grand-title-sb">This workspace is empty</h2>
                            <h3 class="dku-medium-title text-weak">Invite users to join and publish relevant objects from a variety of projects for everyone to collaborate. <span  *ngIf="canCreateDatastory">You can also add datasets to the workspace and start creating compelling Stories with your data.</span></h3>
                            <button *ngIf="workspace.currentUserPermissions.admin" class="mright8 btn btn--secondary btn--dku-icon" (click)="openMembersModal(workspace)" onfocus="this.blur()"><i class="dku-icon-plus-16"></i> Add users</button>
                            <button class="btn btn--secondary btn--dku-icon" (click)="openAddObjectModal(workspace)" onfocus="this.blur()" data-qa-workspace-page__add-content-btn><i class="dku-icon-plus-16"></i> Add content</button>
                        </div>  
                        <div *ngIf="!canAddContent">
                            <img src="/static/dataiku/images/workspaces/no-workspace.svg" width="250" />
                            <h2 class="dku-grand-title-sb">This workspace is empty</h2>
                            <h3 class="dku-medium-title text-weak">The workspace isn't ready yet; nothing to see for now...</h3>
                            <p class="dku-small-title mtop24">Come back when your colleagues have added relevant content. In the meantime, you can visit the <academy-link>Dataiku Academy</academy-link> to ramp up on Dataiku DSS</p>
                        </div>    
                    </div>
                </section>
            </main>
        </div>
        <workspace-right-panel [workspace]="workspace" [timeline]="timeline$ | async"></workspace-right-panel>
    </ng-container>
</ng-container>
<ng-template #workspaceError>
    <workspace-error-message [message]="errorMessage$ | async"></workspace-error-message>
</ng-template>
