import { directive } from '@migration/downgraded-components';
import { service, constant } from '@migration/downgraded-providers';

import { RegressionTypes, AxisTicksConfigMode, ValuesInChartOverlappingStrategy, PolygonSources, LineStyleTypes, CHART_ZOOM_CONTROL_TYPES } from './constants';
import { ChartTensorDataWrapper } from './models';
import { EChartsManagerService, EChartsInstanceManagerService, TreemapEChartDefService, PieEChartDefService, ChartCustomColorsService, ReferenceLinesService, ReferenceLinesFormComponent, ChartCustomColorsComponent, RegressionLineFormComponent, ValuesDisplayFormComponent, NumberOfRecordsFormComponent, BadgeComponent, ChartZoomControlPanelComponent, ZoomOptionsFormComponent, LinesZoomOptionsFormComponent, RegressionLineService, TableFormattingFormComponent, ChartOptionsMenuComponent, RadarEChartDefService, TextFormattingFormComponent, AxisTicksConfigurationFormComponent, MiscFormComponent, FoldableSectionComponent } from './modules';
import { ChartLabelsService, ChartDataWrapperFactoryService, ChartDimensionService, ChartFormattingService, ChartStaticDataService, ChartUADimensionService, NumberFormatterService, ChartDefinitionChangeHandlerService, ChartFiltersService, ChartFilterUtilsService, ChartColumnTypeUtilsService, ChartRegressionService, ColorUtilsService, ChartCustomMeasuresService, ChartConfigurationCopyPasteService, ChartZoomLoaderService, ChartFormattingPaneService, AxisTicksConfigurationService, ChartZoomControlAdapterService } from './services';

export function downgradeSimpleReport() {
    //  Services
    service('EChartsManager', EChartsManagerService),
    service('EChartsInstanceManager', EChartsInstanceManagerService),
    service('TreemapEChartDef', TreemapEChartDefService),
    service('PieEChartDef', PieEChartDefService),
    service('RadarEChartDef', RadarEChartDefService),
    service('ChartDimension', ChartDimensionService),
    service('ChartUADimension', ChartUADimensionService),
    service('ChartsStaticData', ChartStaticDataService),
    service('NumberFormatter', NumberFormatterService),
    service('ChartFormatting', ChartFormattingService),
    service('ChartLabels', ChartLabelsService),
    service('ChartDataWrapperFactory', ChartDataWrapperFactoryService),
    service('ChartConfigurationCopyPaste', ChartConfigurationCopyPasteService),
    service('ChartCustomColors', ChartCustomColorsService),
    service('ChartDefinitionChangeHandler', ChartDefinitionChangeHandlerService),
    service('ChartRegression', ChartRegressionService),
    service('ReferenceLines', ReferenceLinesService),
    service('RegressionLine', RegressionLineService),
    service('ChartFilters', ChartFiltersService),
    service('AxisTicksConfiguration', AxisTicksConfigurationService),
    service('ChartFilterUtils', ChartFilterUtilsService),
    service('ChartColumnTypeUtils', ChartColumnTypeUtilsService),
    service('ColorUtils', ColorUtilsService),
    service('ChartZoomControl', ChartZoomControlAdapterService),
    service('ChartZoomLoader', ChartZoomLoaderService),
    service('ChartCustomMeasure', ChartCustomMeasuresService),
    service('ChartFormattingPane', ChartFormattingPaneService),

    //  Constants
    constant('ChartTensorDataWrapper', ChartTensorDataWrapper);
    constant('ValuesInChartOverlappingStrategy', ValuesInChartOverlappingStrategy);
    constant('RegressionTypes', RegressionTypes);
    constant('AxisTicksConfigMode', AxisTicksConfigMode);
    constant('PolygonSources', PolygonSources);
    constant('LineStyleTypes', LineStyleTypes);
    constant('CHART_ZOOM_CONTROL_TYPES', CHART_ZOOM_CONTROL_TYPES);

    //  Components
    directive('ng2RegressionLineForm', RegressionLineFormComponent);
    directive('ng2ReferenceLinesForm', ReferenceLinesFormComponent);
    directive('ng2ChartCustomColors', ChartCustomColorsComponent);
    directive('ng2ValuesDisplayForm', ValuesDisplayFormComponent);
    directive('ng2ChartZoomControlPanel', ChartZoomControlPanelComponent);
    directive('ng2TextFormattingForm', TextFormattingFormComponent);
    directive('ng2NumberOfRecordsForm', NumberOfRecordsFormComponent);
    directive('ng2ZoomOptionsForm', ZoomOptionsFormComponent);
    directive('ng2LinesZoomOptionsForm', LinesZoomOptionsFormComponent);
    directive('ng2TableFormattingForm', TableFormattingFormComponent),
    directive('ng2AxisTicksConfigurationForm', AxisTicksConfigurationFormComponent);
    directive('ng2ChartOptionsMenu', ChartOptionsMenuComponent);
    directive('ng2Badge', BadgeComponent);
    directive('ng2TextFormattingForm', TextFormattingFormComponent);
    directive('ng2MiscForm', MiscFormComponent);
    directive('ng2FoldableSection', FoldableSectionComponent);
}
