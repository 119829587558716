import { Injectable } from "@angular/core";
import { DeepHubColumnFormat } from "src/generated-sources";
import { CellDataWithAnnotation, ColumnMetadataWithIndex, ImportantColumnsMetadataWithAnnotation } from "../sample-data-fetcher.service";
import { SampleDataFormatterService } from "../sample-data-formatter.service";

export class ObjectDetectionDesignCellData implements CellDataWithAnnotation {
    constructor(
        public itemId: string,
        public itemIndex: number,
        public target?: DeepHubColumnFormat.ObjectDetectionTargetItem[],
        public invalid?: boolean,
        public invalidReason?: string
    ) {}

    listCategories(): string[] {
        return (this.invalid || !this.target)  ? [] : this.target?.map(t => t.category);
    }
}

function isValidObjectDetectionTarget(obj: unknown): obj is DeepHubColumnFormat.ObjectDetectionTargetItem[] {
    if (!Array.isArray(obj)) {
        return false;
    }
    return obj.every(o => {
        const hasBbox = (o.bbox && Array.isArray(o.bbox) && o.bbox.length === 4);
        const hasCategory = o.category && typeof o.category === "string";
        return hasBbox && hasCategory;
    });
}


@Injectable()
export class ObjectDetectionDataFormatterService extends SampleDataFormatterService {

    rowToCellData(rowContent: string[], columnsMetadata: ImportantColumnsMetadataWithAnnotation<ColumnMetadataWithIndex>, i: number): ObjectDetectionDesignCellData {

        const targetStr = rowContent[columnsMetadata.single.annotation.index];
        const itemId = rowContent[columnsMetadata.single.itemPath.index];

        if (!targetStr) {
            return new ObjectDetectionDesignCellData(itemId, i);
        }
        try {
            const target: unknown = JSON.parse(targetStr);
            if (isValidObjectDetectionTarget(target)) {
                return new ObjectDetectionDesignCellData(itemId, i, target)
            }
        } catch(error) {}// Not logging the error, because would flood the console

        // Something bad happened or bad format
        return new ObjectDetectionDesignCellData(
            itemId,
            i,
            undefined,
            true,
            "Invalid Object detection format: '" + targetStr + "'");
        }
}