import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import _ from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SearchOptions, ViewBy } from '../feature-store-models';

@UntilDestroy()
@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarComponent implements OnInit, OnChanges {

  @ViewChild('search') searchElement!: ElementRef;
  @Input() searchQuery!: string;
  @Output() newQueryEvent: EventEmitter<SearchOptions> = new EventEmitter();

  sortOptions = [
    {
      label: 'Relevance',
      value: 'score'
    },
    {
      label: 'Creation',
      value: 'createdOn'
    },
    {
      label: 'Last modification',
      value: 'lastModifiedOn'
    }
  ];

  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
  ) {
    this.form = this.fb.group({
      query: this.fb.control(""),
      sortBy: this.fb.control("score"),
      reverse: this.fb.control(true),
      viewBy: this.fb.control(ViewBy.FEATURE_GROUP)
    });
  }

  ngOnInit() {
    this.form.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
        untilDestroyed(this),
      )
      .subscribe((searchOptions) => {
        this.newQueryEvent.emit(searchOptions);
      });
  }

  ngOnChanges() {
    this.form.patchValue({
      query: this.searchQuery
    });
  }

  hasSearch() {
    return this.form.controls['query'].value !== "";
  }

  resetSearch() {
    this.form.controls['query'].setValue("");
  }

  selectInput() {
    this.searchElement.nativeElement.focus();
  }

  disableSortButton() {
    return this.form.controls['sortBy'].value === 'score';
  }

  reverseOrder() {
    return this.form.controls['reverse'].value as boolean;
  }

  isViewByFeatureGroup() {
    return this.form.controls['viewBy'].value === ViewBy.FEATURE_GROUP;
  }

  isViewByFeatureName() {
    return this.form.controls['viewBy'].value === ViewBy.FEATURE_NAME;
  }

  viewByFeatureGroup() {
    this.form.controls['viewBy'].setValue(ViewBy.FEATURE_GROUP);
  }

  viewByFeatureName() {
    this.form.controls['viewBy'].setValue(ViewBy.FEATURE_NAME);
  }
}
