<ng-select
    [ngModel]="currentSelection"
    (ngModelChange)="changeCurrentSelection($event)"
    class="auto-grow"
    [multiple]="multi"
    [items]="selectableItems"
    [virtualScroll]="true"
    [closeOnSelect]="!multi"
    [searchFn]="searchFn"
    [groupBy]="groupByFn"
    [searchable]="false"
    [clearable]="false"
    [appendTo]="'body'"
    [placeholder]="placeholder"
    notFoundText="No {{ type | niceConst | lowercase }} found"
    class="ng-select--no-wrap-selected-items ng-select--with-ng-option-separator"
    [ngClass]="selectClass"
    [disabled]="isDisabled"
    >
    <ng-template ng-optgroup-tmp let-groupItem="item">
        <div class="group">{{ groupItem.label }}</div>
    </ng-template>

    <ng-template ng-header-tmp>
        <ng-select-search-input data-qa-accessible-objects-selector__search-input></ng-select-search-input>
    </ng-template>

    <ng-template ng-label-tmp let-item="item">
        <!-- Template for the menu trigger in single mode -->
        <div class="select-trigger-content">
            <ng-container *ngTemplateOutlet="objectTypeIcon; context: { type: item.object.type, subtype: item.object.subtype  }">
            </ng-container>
            <div class="select-trigger-text">{{ item.label }}</div>
            <div *ngIf="showObjectLink && item">
                <small>
                    &nbsp;– <a target="_blank" href="{{ stateUtils.href.dssObject(item.object.type || type, item.object.id, item.object.projectKey || currentRouteService.projectKey) }}">view</a>
                </small>
            </div>
        </div>
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <!-- Template for the menu trigger in multiple mode -->
        <div class="select-trigger-content">
            <ng-container *ngTemplateOutlet="objectTypeIcon; context: { type: items[0].object.type, subtype: items[0].object.subtype  }">
            </ng-container>
            <ng-container *ngIf="items.length > 1">
                {{ items.length }} {{ items[0].object.type | niceConst |
                plurify:items.length | lowercase }} selected
            </ng-container>
            <ng-container *ngIf="items.length === 1">
                {{ items[0].label }}
            </ng-container>
        </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
        <!-- Template for each menu option -->
        <ng-select-pseudo-checkbox></ng-select-pseudo-checkbox>
        <ng-container *ngTemplateOutlet="optionItem; context: { item: item }"></ng-container>
    </ng-template>
</ng-select>

<ng-template #objectTypeIcon
             let-type="type"
             let-subtype="subtype">
    <ng-container *ngIf="!subtype">
        <i [attr.class]="'object-type-icon icon ' +  (type | typeToIcon) + ' ' + (type | typeToColor)"></i>
    </ng-container>
    <ng-container *ngIf="!!subtype">
        <i
           [attr.class]="'object-type-icon icon ' +  (subtype | typeToIcon) + ' ' + (subtype | subtypeToColor: type)"></i>
    </ng-container>
</ng-template>

<ng-template #optionItem let-item="item">
    <div class="object-option" [title]="item.object.label" data-qa-object-option>
        <div class="object-option__content">
            <ng-container *ngTemplateOutlet="objectTypeIcon; context: { type: item.object.type, subtype: item.object.subtype  }">
            </ng-container>

            <div class="object-option__text">
                <div class="object-option__text-top">{{ item.object.label }}</div>
                <div class="object-option__text-bottom"
                        *ngIf="item.object.subtype || (item.object.usable === false)">
                    <ng-container *ngIf="item.object.subtype">{{ item.object.subtype }}</ng-container>
                    <ng-container *ngIf="item.object.subtype && (item.object.usable === false)">&nbsp;</ng-container>
                    <ng-container *ngIf="item.object.usable === false">{{ item.object.usableReason }}</ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-template>