import { Injectable, Inject } from '@angular/core';
import { CapitalizePipe } from '../../../shared/pipes/capitalize.pipe';
import { PlurifyPipe } from '../../../shared/pipes/text-pipes/plurify.pipe';
import { ChartFilter, AxisDef } from '../../../../generated-sources';
import { FrontendChartFilterTypeProperties } from '..';
import { fairAny } from 'dku-frontend-core';
import { ChartColumnTypeUtilsService, ChartStaticDataService } from '.';

const capitalize = (input: string) => new CapitalizePipe().transform(input);
const plurify = (singular: string, num: number) => new PlurifyPipe().transform(singular, num);

@Injectable({
    providedIn: 'root'
})
/**
 * Utility functions easing chart filters manipulation.
 */
export class ChartFilterUtilsService {
    private readonly logger: { error: (msg: string) => void };

    constructor(
        private readonly chartStaticDataService: ChartStaticDataService,
        private readonly chartColumnTypeUtilsService: ChartColumnTypeUtilsService,
        @Inject('Logger') private loggerFactory: fairAny
    ) {
        this.logger = this.loggerFactory({ serviceName: 'ChartFilters', objectName: 'Service' });
    }

    computeRelativeDateLabel(dateFilterOption: ChartFilter.DateRelativeOption, dateFilterPart: ChartFilter.DateFilterPart, dateFilterRelativeLast: number | null, dateFilterRelativeNext: number | null): string {
        const item = { THIS: 'this', LAST: 'last', NEXT: 'next', TO: 'to date' }[dateFilterOption];
        if (dateFilterPart === ChartFilter.DateFilterPart.INDIVIDUAL || dateFilterPart === ChartFilter.DateFilterPart.WEEK_OF_YEAR || dateFilterPart === ChartFilter.DateFilterPart.DAY_OF_WEEK) {
            this.logger.error('Unsupported date part encountered while computing relative date filter label');
            return '';
        }
        const unit = { YEAR: 'year', QUARTER_OF_YEAR: 'quarter', MONTH_OF_YEAR: 'month', DAY_OF_MONTH: 'day', HOUR_OF_DAY: 'hour' }[dateFilterPart];

        if (dateFilterOption === ChartFilter.DateRelativeOption.TO) {
            return capitalize(`${unit} ${item}`);
        } if (dateFilterOption === ChartFilter.DateRelativeOption.LAST && dateFilterRelativeLast != null && dateFilterRelativeLast > 1) {
            return capitalize(`${item} ${dateFilterRelativeLast} ${plurify(unit, dateFilterRelativeLast)}`);
        } else if (dateFilterOption === ChartFilter.DateRelativeOption.NEXT && dateFilterRelativeNext != null && dateFilterRelativeNext > 1) {
            return capitalize(`${item} ${dateFilterRelativeNext} ${plurify(unit, dateFilterRelativeNext)}`);
        } else {
            return capitalize(`${item} ${unit}`);
        }
    }

    /**
     * Checks if the given filter is alphanumerical.
     * It can be a filter based on an alphanumerical column, a numerical column as alphanum or a date part.
     * @param filter the filter to check.
     * @returns
     */
    isAlphanumericalFilter(filter: FrontendChartFilterTypeProperties): boolean {
        return filter.filterType === ChartFilter.FilterType.ALPHANUM_FACET
            || this.chartColumnTypeUtilsService.isAlphanumColumnType(filter.columnType)
            || this.isDatePartFilter(filter);
    }

    /**
     * Checks if the given filter is numerical.
     * It can be a numerical range filter or a date range filter.
     */
    isNumericalFilter(filter: FrontendChartFilterTypeProperties): boolean {
        return this.isNumericalRangeFilter(filter)
            || this.isDateRangeFilter(filter);
    }

    isNumericalRangeFilter(filter: FrontendChartFilterTypeProperties): boolean {
        return (filter.filterType === ChartFilter.FilterType.NUMERICAL_FACET
            && filter.columnType === AxisDef.Type.NUMERICAL);
    }

    isDateRangeFilter(filter: FrontendChartFilterTypeProperties): boolean {
        return filter && filter.columnType === AxisDef.Type.DATE && filter.dateFilterType === ChartFilter.DateFilterType.RANGE;
    }

    isRelativeDateFilter(filter: FrontendChartFilterTypeProperties) {
        return filter && filter.columnType === AxisDef.Type.DATE && filter.dateFilterType === ChartFilter.DateFilterType.RELATIVE;
    }

    isDatePartFilter(filter: FrontendChartFilterTypeProperties) {
        return filter && filter.columnType === AxisDef.Type.DATE && filter.dateFilterType === ChartFilter.DateFilterType.PART;
    }

    isExplicitFilter(filter: FrontendChartFilterTypeProperties) {
        return filter && filter.filterType === ChartFilter.FilterType.EXPLICIT;
    }

    getDateFilterTypes() {
        return [
            this.buildDateFreeRangeFilterType(),
            this.buildDateRelativeFilterType(),
            this.buildDatePartFilterType()
        ];
    }

    getDateChartRangeFilterTypes() {
        return [
            this.buildDateFreeRangeFilterType(),
            this.buildDateRelativeFilterType()
        ];
    }

    getDateChartFilterParts() {
        return [
            this.toDateFilterType(this.chartStaticDataService.YEAR),
            this.toDateFilterType(this.chartStaticDataService.QUARTER_OF_YEAR),
            this.toDateFilterType(this.chartStaticDataService.MONTH_OF_YEAR),
            this.toDateFilterType(this.chartStaticDataService.WEEK_OF_YEAR),
            this.toDateFilterType(this.chartStaticDataService.DAY_OF_MONTH),
            this.toDateFilterType(this.chartStaticDataService.DAY_OF_WEEK),
            this.toDateFilterType(this.chartStaticDataService.HOUR_OF_DAY)
        ];
    }

    getDateFilterParts() {
        return [
            this.toDateFilterType(this.chartStaticDataService.YEAR),
            this.toDateFilterType(this.chartStaticDataService.QUARTER_OF_YEAR),
            this.toDateFilterType(this.chartStaticDataService.MONTH_OF_YEAR),
            this.toDateFilterType(this.chartStaticDataService.WEEK_OF_YEAR),
            this.toDateFilterType(this.chartStaticDataService.DAY_OF_MONTH),
            this.toDateFilterType(this.chartStaticDataService.DAY_OF_WEEK),
            this.toDateFilterType(this.chartStaticDataService.HOUR_OF_DAY),
            this.toDateFilterType(this.chartStaticDataService.INDIVIDUAL)
        ];
    }

    getDateRelativeFilterParts() {
        return [
            this.toDateFilterType(this.chartStaticDataService.RELATIVE_YEAR),
            this.toDateFilterType(this.chartStaticDataService.RELATIVE_QUARTER),
            this.toDateFilterType(this.chartStaticDataService.RELATIVE_MONTH),
            this.toDateFilterType(this.chartStaticDataService.RELATIVE_DAY),
            this.toDateFilterType(this.chartStaticDataService.RELATIVE_HOUR)
        ];
    }

    private toDateFilterType(dateMode: { label: string, value: string, suffix?: string }) {
        let label = dateMode.label;
        if (dateMode.suffix) {
            label += ` (${dateMode.suffix})`;
        }
        return [dateMode.value, label];
    }

    /**
     * Builds the date free range filter type.
     */
    private buildDateFreeRangeFilterType(suffix?: string) {
        return this.toDateFilterType({ ...this.chartStaticDataService.DEFAULT_DATE_RANGE_FILTER_TYPE, suffix });
    }

    private buildDateRelativeFilterType(suffix?: string) {
        return this.toDateFilterType({ ...this.chartStaticDataService.DEFAULT_DATE_RELATIVE_FILTER_TYPE, suffix });
    }

    private buildDatePartFilterType(suffix?: string) {
        return this.toDateFilterType({ ...this.chartStaticDataService.DEFAULT_DATE_PART_FILTER_TYPE, suffix });
    }

}
