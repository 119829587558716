/** 
 * Translated from class com.dataiku.dip.analysis.model.core.SavedModelOriginInfo
 * Via: com.dataiku.dip.analysis.ml.llm.LLMSMMgmtService$LLMSMVersionHeader
 */
export interface _SavedModelOriginInfo {
    fullModelId: string;
    jobId: string;
    jobIdUpdate: number;
    origin: SavedModelOriginInfo.Origin;
}

export type SavedModelOriginInfo = _SavedModelOriginInfo;

export namespace SavedModelOriginInfo {
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.core.SavedModelOriginInfo$Origin
     * Via: com.dataiku.dip.analysis.model.core.SavedModelOriginInfo
     */
    export enum Origin {
        EXPORTED_FROM_ANALYSIS = "EXPORTED_FROM_ANALYSIS",
        TRAINED_FROM_RECIPE = "TRAINED_FROM_RECIPE",
    }
}