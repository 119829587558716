import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Workspace } from '@model-main/workspaces/workspace';
import { UntypedFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { observeFormControl } from '@utils/form-control-observer';
import { ITaggingService } from '@model-main/server/services/itagging-service';


@UntilDestroy()
@Component({
    selector: 'workspace-datastory',
    templateUrl: './workspace-datastory.component.html',
    styleUrls: ['./workspace-datastory.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceDatastoryComponent implements OnInit {
    @Input() workspace: Workspace;
    @Input() datastory: Workspace.Story;
    @Output() datastoryChanged = new EventEmitter<Workspace.Story | null>();

    query = '';
    datastoryForm: UntypedFormGroup;
    datasetObjects: Workspace.WorkspaceObject[];

    constructor(private fb: UntypedFormBuilder) {
    }

    get references(): UntypedFormGroup {
        return (this.datastoryForm.get('references') as UntypedFormGroup);
    }

    ngOnInit(): void {
        this.datasetObjects = this.workspace.workspaceObjects.filter(wo => wo.reference?.type === ITaggingService.TaggableType.DATASET).sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.datastoryForm = this.fb.group({
            title: [this.datastory?.title, Validators.required],
            description: this.datastory?.description,
            references: this.fb.group(Object.fromEntries(this.datasetObjects.map(ref => [ref.id, true])), {validators: this.atLeastOne})
        });
        observeFormControl(this.datastoryForm).pipe(untilDestroyed(this)).subscribe(() => this.notifyChange());
    }

    private atLeastOne(control: AbstractControl): ValidationErrors {
        const valid = Object.values((control as UntypedFormGroup).controls).some(c => c.value)
        if (!valid) {
            return {'atLeastOneError': true};
        }
        return {};
    }

    public searchChanged(searchTerm: string): void {
        this.query = searchTerm;
    }

    private notifyChange(): void {
        if (this.datastoryForm.valid) {
            const modifiedDatastory : Workspace.Story = {
                ...this.datastory,
                ...this.datastoryForm.value,
                references: this.datasetObjects.filter(datasetObject => this.datastoryForm.value.references[datasetObject.id]).map(obj => obj.id)
            };
            this.datastoryChanged.emit(modifiedDatastory);
        } else {
            this.datastoryChanged.emit(null);
        }
    }
}
