import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { ChartVariant } from '@model-main/pivot/frontend/model/chart-variant';
import { DisplayAxis } from '@model-main/pivot/frontend/model/display-axis';
import { DisplayType } from '@model-main/pivot/frontend/model/display-type';
import { LabelPosition } from '@model-main/pivot/frontend/model/label-position';
import { Multiplier } from '@model-main/pivot/frontend/model/multiplier';
import { Aggregation } from '@model-main/pivot/backend/model/aggregation';
import { FrontendChartDef } from '../../../interfaces';
import { DigitGrouping } from '@model-main/pivot/frontend/model/digit-grouping';

export const partialPieChartDef: Partial<FrontendChartDef> = {
    variant: ChartVariant.normal,
    name: 'Revenue per country',
    displayWithECharts: false,
    genericDimension0: [
        {
            column: 'ip_country',
            type: 'ALPHANUM',
            numParams: {
                emptyBinsMode: 'ZEROS'
            },
            maxValues: 10,
            generateOthersCategory: true,
            forceLastPositionOthers: true,
            filters: [],
            isA: 'dimension',
            dateParams: undefined as any,
            showDimensionValuesInChart: undefined as any,
            oneTickPerBin: undefined as any,
            possibleSorts: [
                {
                    type: 'NATURAL',
                    label: 'Natural ordering',
                    sortAscending: true
                },
                {
                    type: 'AGGREGATION',
                    measureIdx: 0,
                    label: 'Average of revenue, descending'
                },
                {
                    type: 'AGGREGATION',
                    measureIdx: 0,
                    label: 'Average of revenue, ascending',
                    sortAscending: true
                }
            ],
            sort: {
                type: 'AGGREGATION',
                measureIdx: 0,
                label: 'Average of revenue, descending'
            },
            multiplier: Multiplier.Auto,
            prefix: '',
            suffix: '',
            digitGrouping: DigitGrouping.DEFAULT
        }
    ],
    facetDimension: [],
    animationDimension: [],
    genericMeasures: [
        {
            column: 'revenue',
            function: Aggregation.Function.AVG,
            type: 'NUMERICAL',
            displayed: true,
            displayAxis: DisplayAxis.axis1,
            displayType: DisplayType.column,
            isA: 'measure',
            computeMode: Aggregation.ComputeMode.NORMAL,
            computeModeDim: 0,
            labelPosition:  LabelPosition.BOTTOM,
            showDisplayLabel: true,
            multiplier: Multiplier.Auto,
            prefix: '',
            suffix: '',
            digitGrouping: DigitGrouping.DEFAULT,
            colorRules: []
        }
    ],
    tooltipMeasures: [],
    filters: [],
    showInChartValues: false,
    showInChartLabels: false,
    multiTooltips: false,
    animationFrameDuration: 500,
    animationRepeat: true,
    colorOptions: {
        singleColor: '#659a88',
        transparency: 1,
        colorPalette: 'default',
        ccScaleMode: ChartDef.ColorOptions.ContinuousColorScaleMode.NORMAL,
        customPalette: {
            id: '__dku_custom__',
            name: 'Custom palette',
            colors: [],
            values: [],
            fixedValues: false
        },
        paletteType: ChartDef.ColorOptions.PaletteType.CONTINUOUS,
        quantizationMode: ChartDef.ColorOptions.QuantizationMode.NONE,
        numQuantizeSteps: 5,
        paletteMiddleValue: 0,
        customColors: {},
        heatDensityMapIntensity: 0.5,
        heatDensityMapRadius: 0.5
    },
    useLiveProcessingIfAvailable: true,
    legendPlacement: ChartDef.LegendPlacement.OUTER_RIGHT,
    colorPaletteType: 'LINEAR',
    valuesInChartDisplayOptions: {
        textFormatting: {
            fontSize: 11
        }
    }
};
