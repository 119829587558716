import type { DataService } from './../server/data-service';
import type { DatasetSelectionToMemTable } from './../../datasets/dataset-selection-to-mem-table';
import type { MetricsEngineDesc } from './../../metrics/metrics-engine-desc';
import type { ScriptStep } from './script-step';
import type { StreamableDatasetSelection } from './../../datasets/streamable-dataset-selection';
import type { _DatasetSelectionToMemTable } from './../../datasets/dataset-selection-to-mem-table';
import type { _ScriptStep } from './script-step';
import type { _StreamableDatasetSelection } from './../../datasets/streamable-dataset-selection';

/** 
 * Translated from class com.dataiku.dip.shaker.model.SerializedShakerScript
 * Via: com.dataiku.dip.shaker.model.ChartsOnDatasetDataSpec
 */
export interface _SerializedShakerScript {
    analysisColumnData: { [key in string]: SerializedShakerScript.AnalysisColumnData };
    coloring: SerializedShakerScript.TableColoring;
    columnUseScientificNotationByName: { [key in string]: boolean };
    columnWidthsByName: { [key in string]: number };
    columnsSelection: SerializedShakerScript.DisplayedColumnsSelection;
    contextProjectKey?: string | null;
    explorationFilters: any/* com.google.gson.JsonElement */[];
    explorationSampling: SerializedShakerScript.ShakerExplorationSampleSettings;
    exploreUIParams: { [key in string]: any/* com.google.gson.JsonElement */ };
    fullSampleStatistics?: SerializedShakerScript.FullSampleStatisticsConfig | null;
    globalSearchQuery: string;
    maxProcessedMemTableBytes: number;
    origin: SerializedShakerScript.ShakerOrigin;
    previewMode: SerializedShakerScript.PreviewMode;
    recipeSchema?: DataService.ShakerRecipeSchema | null;
    sorting: SerializedShakerScript.TableSorting[];
    steps: ScriptStep[];
    vizSampling: SerializedShakerScript.RefreshableStreamableSelection;
}

export type SerializedShakerScript = _SerializedShakerScript;

export namespace SerializedShakerScript {
    /** 
     * Translated from class com.dataiku.dip.shaker.model.SerializedShakerScript$AnalysisColumnData
     * Via: com.dataiku.dip.shaker.model.SerializedShakerScript
     */
    export interface _AnalysisColumnData {
        comment: string;
        customFields: { [key in string]: any/* com.google.gson.JsonElement */ };
        meaning: string;
    }
    
    export type AnalysisColumnData = _AnalysisColumnData;
    
    /** 
     * Translated from enum com.dataiku.dip.shaker.model.SerializedShakerScript$ChartsEngine
     * Via: com.dataiku.dip.shaker.model.ChartsOnDatasetDataSpec
     */
    export enum ChartsEngine {
        LINO = "LINO",
        SQL = "SQL",
        SPARKSQL = "SPARKSQL",
    }
    /** 
     * Translated from class com.dataiku.dip.shaker.model.SerializedShakerScript$DisplayedColumn
     * Via: com.dataiku.dip.shaker.model.SerializedShakerScript$DisplayedColumnsSelection
     */
    export interface _DisplayedColumn {
        d: boolean;
        name: string;
    }
    
    export type DisplayedColumn = _DisplayedColumn;
    
    /** 
     * Translated from enum com.dataiku.dip.shaker.model.SerializedShakerScript$DisplayedColumnsMode
     * Via: com.dataiku.dip.shaker.model.SerializedShakerScript$DisplayedColumnsSelection
     */
    export enum DisplayedColumnsMode {
        ALL = "ALL",
        SELECT = "SELECT",
        SELECT_AND_REORDER = "SELECT_AND_REORDER",
    }
    /** 
     * Translated from class com.dataiku.dip.shaker.model.SerializedShakerScript$DisplayedColumnsSelection
     * Via: com.dataiku.dip.shaker.server.SerializedMemTableV2
     */
    export interface _DisplayedColumnsSelection {
        list?: SerializedShakerScript.DisplayedColumn[] | null;
        mode: SerializedShakerScript.DisplayedColumnsMode;
    }
    
    export type DisplayedColumnsSelection = _DisplayedColumnsSelection;
    
    /** 
     * Translated from class com.dataiku.dip.shaker.model.SerializedShakerScript$FullSampleStatisticsConfig
     * Via: com.dataiku.dip.shaker.model.SerializedShakerScript
     */
    export interface _FullSampleStatisticsConfig {
        advanced: boolean;
        basic: boolean;
        computeCostFreeMetrics: boolean;
        countDistinct: boolean;
        engineConfig: MetricsEngineDesc.MetricsEngineConfig;
        numberTopValues: number;
        percentile: boolean;
        updateOnAnalyzeBoxOpen: boolean;
        validity: boolean;
    }
    
    export type FullSampleStatisticsConfig = _FullSampleStatisticsConfig;
    
    /** 
     * Translated from enum com.dataiku.dip.shaker.model.SerializedShakerScript$PreviewMode
     * Via: com.dataiku.dip.shaker.model.SerializedShakerScript
     */
    export enum PreviewMode {
        IMPACTED_ONLY = "IMPACTED_ONLY",
        ALL_ROWS = "ALL_ROWS",
    }
    /** 
     * Translated from class com.dataiku.dip.shaker.model.SerializedShakerScript$RefreshableStreamableSelection
     * Via: com.dataiku.dip.shaker.model.ChartsOnDatasetDataSpec
     */
    export interface _RefreshableStreamableSelection {
        _refreshTrigger: number;
        autoRefreshSample: boolean;
        selection: StreamableDatasetSelection;
    }
    
    export type RefreshableStreamableSelection = _RefreshableStreamableSelection;
    
    /** 
     * Translated from class com.dataiku.dip.shaker.model.SerializedShakerScript$ShakerExplorationSampleSettings
     * Via: com.dataiku.dip.shaker.model.SerializedShakerScript
     */
    export interface _ShakerExplorationSampleSettings {
        _refreshTrigger: number;
        autoRefreshSample: boolean;
        selection: DatasetSelectionToMemTable;
    }
    
    export type ShakerExplorationSampleSettings = _ShakerExplorationSampleSettings;
    
    /** 
     * Translated from enum com.dataiku.dip.shaker.model.SerializedShakerScript$ShakerOrigin
     * Via: com.dataiku.dip.shaker.model.SerializedShakerScript
     */
    export enum ShakerOrigin {
        DATASET_EXPLORE = "DATASET_EXPLORE",
        ANALYSIS = "ANALYSIS",
        PREPARE_RECIPE = "PREPARE_RECIPE",
        FLOW_PREVIEW = "FLOW_PREVIEW",
    }
    /** 
     * Translated from class com.dataiku.dip.shaker.model.SerializedShakerScript$TableColoring
     * Via: com.dataiku.dip.shaker.model.SerializedShakerScript
     */
    export interface _TableColoring {
        individualColumns: string[];
        scheme: SerializedShakerScript.TableColoringScheme;
        singleColumn?: string | null;
        singleValue?: string | null;
        valueColoringMode: SerializedShakerScript.ValueColoringMode;
    }
    
    export type TableColoring = _TableColoring;
    
    /** 
     * Translated from enum com.dataiku.dip.shaker.model.SerializedShakerScript$TableColoringScheme
     * Via: com.dataiku.dip.shaker.server.SerializedMemTableV2
     */
    export enum TableColoringScheme {
        MEANING_AND_STATUS = "MEANING_AND_STATUS",
        FILL_ONLY = "FILL_ONLY",
        INDIVIDUAL_COLUMNS_VALUES = "INDIVIDUAL_COLUMNS_VALUES",
        ALL_COLUMNS_VALUES = "ALL_COLUMNS_VALUES",
        SINGLE_COLUMN_VALUES = "SINGLE_COLUMN_VALUES",
        SINGLE_VALUE_HIGHLIGHT = "SINGLE_VALUE_HIGHLIGHT",
    }
    /** 
     * Translated from class com.dataiku.dip.shaker.model.SerializedShakerScript$TableSorting
     * Via: com.dataiku.dip.shaker.model.SerializedShakerScript
     */
    export interface _TableSorting {
        ascending: boolean;
        column: string;
    }
    
    export type TableSorting = _TableSorting;
    
    /** 
     * Translated from enum com.dataiku.dip.shaker.model.SerializedShakerScript$ValueColoringMode
     * Via: com.dataiku.dip.shaker.model.SerializedShakerScript$TableColoring
     */
    export enum ValueColoringMode {
        HASH = "HASH",
        RANK = "RANK",
    }
}