export namespace RecipeVariablesHelper {
    /** 
     * Translated from class com.dataiku.dip.server.recipes.RecipeVariablesHelper$AvailableVariable
     * Via: com.dataiku.dip.server.recipes.GenericRecipesValidationService$RecipeValidationResult
     */
    export interface _AvailableVariable {
        type: RecipeVariablesHelper.VariableType;
        value: string;
    }
    
    export type AvailableVariable = _AvailableVariable;
    
    /** 
     * Translated from enum com.dataiku.dip.server.recipes.RecipeVariablesHelper$VariableType
     * Via: com.dataiku.dip.server.recipes.RecipeVariablesHelper$AvailableVariable
     */
    export enum VariableType {
        FLOW = "FLOW",
        USER = "USER",
    }
}