import type { Filter } from './filter';
import type { _Filter } from './filter';

/** 
 * Translated from class com.dataiku.dip.eda.compute.filtering.AnumFilter
 * Via: UIModel annotation in the class hierarchy
 */
export interface _AnumFilter extends _Filter {
    type: "anum";
    column: string;
    values: string[];
}

export type AnumFilter = _AnumFilter;

export namespace AnumFilter {
    /** Type of class com.dataiku.dip.eda.compute.filtering.AnumFilter in PolyJSON mappings */
    export const type = "anum";
}