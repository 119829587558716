import type { ProxyModelVersionConfiguration } from './../../savedmodels/proxymodelversions/proxy-model-version-configuration';



export namespace SageMakerUtils {
    /** 
     * Translated from class com.dataiku.dip.apideployer.deployments.SageMakerUtils$DSSSageMakerConsolidatedEndpointInfo
     * Via: com.dataiku.dip.savedmodels.proxymodelversions.ProxyModelVersionConfiguration$ConsolidatedEndpointInfo
     */
    export interface _DSSSageMakerConsolidatedEndpointInfo extends ProxyModelVersionConfiguration._ConsolidatedEndpointInfo {
        type: "DSSSageMakerConsolidatedEndpointInfo";
        arn: string;
        configArn: string;
        configName: string;
        endpointName: string;
        variants: SageMakerUtils.DSSSageMakerProductionVariant[];
    }
    
    export type DSSSageMakerConsolidatedEndpointInfo = _DSSSageMakerConsolidatedEndpointInfo;
    
    /** 
     * Translated from class com.dataiku.dip.apideployer.deployments.SageMakerUtils$DSSSageMakerProductionVariant
     * Via: com.dataiku.dip.apideployer.deployments.SageMakerUtils$DSSSageMakerConsolidatedEndpointInfo
     */
    export interface _DSSSageMakerProductionVariant {
        modelName: string;
        name: string;
        weight: number;
    }
    
    export type DSSSageMakerProductionVariant = _DSSSageMakerProductionVariant;

    export namespace DSSSageMakerConsolidatedEndpointInfo {
        /** Type of class com.dataiku.dip.apideployer.deployments.SageMakerUtils$DSSSageMakerConsolidatedEndpointInfo in PolyJSON mappings */
        export const type = "DSSSageMakerConsolidatedEndpointInfo";
    }
}