import type { FailedResult } from './failed-result';
import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { NotComputedResult } from './not-computed-result';
import type { UnavailableResult } from './unavailable-result';
import type { _FailedResult } from './failed-result';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _NotComputedResult } from './not-computed-result';
import type { _UnavailableResult } from './unavailable-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.ComputationResult
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ComputationResult extends _ImmutableValueObject {
}

/** List of all concrete subclasses of com.dataiku.dip.eda.compute.computations.ComputationResult */
export type ComputationResult =
    | FailedResult
    | NotComputedResult;

export namespace ComputationResult {
    /** Types of class com.dataiku.dip.eda.compute.computations.ComputationResult in PolyJSON mappings */
    export const type = ["failed", "not_computed", "chi2_ind_test", "covariance", "fit_2d_distribution", "fit_curve", "kendall_tau", "mutual_information", "pearson", "spearman", "count", "dummy", "grouped", "multi", "resampled", "fetch_csv", "fetch_values", "pca", "acf", "durbin_watson", "mann_kendall", "pacf", "stl_decomposition", "unit_root_adf", "unit_root_kpss", "unit_root_za", "count_distinct", "empirical_cdf", "entropy", "fit_distribution", "guess_time_step", "ks_test_2samp", "kurtosis", "match_time_step", "max", "max_time", "mean", "min", "min_time", "mood_test_nsamp", "one_way_anova", "pairwise_mood_test", "pairwise_ttest", "quantiles", "sem", "shapiro", "sign_test_1samp", "skewness", "std_dev", "sum", "ttest_1samp", "test_distribution", "variance", "ztest_1samp", ] as const;
    
    export function isComputationResult(obj: ComputationResult): obj is ComputationResult { return ["failed", "not_computed", "chi2_ind_test", "covariance", "fit_2d_distribution", "fit_curve", "kendall_tau", "mutual_information", "pearson", "spearman", "count", "dummy", "grouped", "multi", "resampled", "fetch_csv", "fetch_values", "pca", "acf", "durbin_watson", "mann_kendall", "pacf", "stl_decomposition", "unit_root_adf", "unit_root_kpss", "unit_root_za", "count_distinct", "empirical_cdf", "entropy", "fit_distribution", "guess_time_step", "ks_test_2samp", "kurtosis", "match_time_step", "max", "max_time", "mean", "min", "min_time", "mood_test_nsamp", "one_way_anova", "pairwise_mood_test", "pairwise_ttest", "quantiles", "sem", "shapiro", "sign_test_1samp", "skewness", "std_dev", "sum", "ttest_1samp", "test_distribution", "variance", "ztest_1samp", ].includes(obj["type"]); }
    export function isFailedResult(obj: ComputationResult): obj is FailedResult { return obj["type"] == "failed"; }
    export function isNotComputedResult(obj: ComputationResult): obj is NotComputedResult { return obj["type"] == "not_computed"; }
    export function isUnavailableResult(obj: ComputationResult): obj is UnavailableResult { return ["failed", "not_computed", ].includes(obj["type"]); }
}