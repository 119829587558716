import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HTMLTooltipPosition } from '../html-tooltip';

type BadgeStatus = 'default' | 'primary' | 'warning' | 'success';
type BadgeIconStatus = 'default' | 'primary' | 'warning' | 'success';
type BadgeIconLabel = 'info' | 'primary' | 'warning' | 'success';
type BadgeIconStyle = 'outline' | 'filled';
type BadgeIconSize = 'default' | 'lg';

class BadgeTooltipOptions {
    message?: string;
    position: HTMLTooltipPosition = HTMLTooltipPosition.ABOVE;
    class: string = 'mat-tooltip--above';
}

class BadgeIconOptions {
    size: BadgeIconSize;
    style: BadgeIconStyle;
    status: BadgeIconStatus;
    label: BadgeIconLabel;

    constructor(iconOptions?: Partial<BadgeIconOptions>) {
        this.label = iconOptions?.label || 'info';
        this.size = iconOptions?.size || 'default';
        this.style = iconOptions?.style || 'filled';
        this.status = iconOptions?.status || 'default';
    }
}

@Component({
    selector: 'dss-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeComponent {
    private tooltipOptions = new BadgeTooltipOptions();

    public label$ = new BehaviorSubject<string | null | undefined>(null);
    public status$ = new BehaviorSubject<BadgeStatus>('default');
    public iconOptions$ = new BehaviorSubject<BadgeIconOptions | null | undefined>(null);
    public tooltipOptions$ = new BehaviorSubject<BadgeTooltipOptions>(this.tooltipOptions);

    @Input() set label(value: string | undefined) {
        this.label$.next(value);
    }

    @Input() set status(value: BadgeStatus | undefined) {
        this.status$.next(this.getOrDefaultStatus(value));
    }

    @Input() set iconOptions(value: BadgeIconOptions | undefined) {
        this.iconOptions$.next(new BadgeIconOptions(value));
    }

    @Input() set tooltip(value: string) {
        this.tooltipOptions.message = value;
        this.tooltipOptions$.next(this.tooltipOptions);
    }

    @Input() set tooltipPosition(value: HTMLTooltipPosition) {
        this.tooltipOptions.position = value;
        this.tooltipOptions$.next(this.tooltipOptions);
    }

    private getOrDefaultStatus(value?: BadgeStatus): BadgeStatus {
        return value || 'default';
    }
}
