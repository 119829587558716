import { Component, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MigrationStoreService } from '@migration/migration-store.service';
import { ReplaySubject} from 'rxjs';

interface DatasetPreviewOptions {
    id: string;
    projectKey: string;
}

@Component({
    selector: 'dss-dataset-preview-modal',
    templateUrl: './dataset-preview-modal.component.html',
    styleUrls: ['./dataset-preview-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetPreviewModalComponent implements OnDestroy {
    modalTitle: string;
    refresh$ = new ReplaySubject<void>(1);
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DatasetPreviewOptions,
        private dialogRef: MatDialogRef<DatasetPreviewModalComponent>,
        private migrationStoreService: MigrationStoreService,
    ) {
        migrationStoreService.register('refreshDatasetPreview', this.refresh$);
        this.modalTitle = `Preview of "${this.data.projectKey}.${this.data.id}"`;
    }

    reload() {
        this.refresh$.next();
    }
    close() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.migrationStoreService.unregister('refreshDatasetPreview');
    }

}
