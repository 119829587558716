import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { realAny } from 'dku-frontend-core';

@Component({
  selector: 'select-modal',
  templateUrl: './select-modal.component.html',
  styleUrls: ['./select-modal.component.less']
})
export class SelectModalComponent implements OnInit {
    selectedItem: any;

    constructor(
        public dialogRef: MatDialogRef<realAny, string>,
        @Inject(MAT_DIALOG_DATA) public data: {
            title: string,
            message: string,
            icon?: string,
            items: any[],
            defaultItem: any,
        }
    ) {
        this.selectedItem = this.data.defaultItem;
    }

    ngOnInit(): void {
    }

    selectItem(item: any) {
        this.selectedItem = item;
    }

    confirm() {
        this.dialogRef.close(this.selectedItem);
    }

    cancel() {
        this.dialogRef.close();
    }
}
