import type { Beta } from './beta';
import type { Binomial } from './binomial';
import type { Exponential } from './exponential';
import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { Laplace } from './laplace';
import type { LogNormal } from './log-normal';
import type { Normal } from './normal';
import type { NormalMixture } from './normal-mixture';
import type { Pareto } from './pareto';
import type { Poisson } from './poisson';
import type { Triangular } from './triangular';
import type { Weibull } from './weibull';
import type { _Beta } from './beta';
import type { _Binomial } from './binomial';
import type { _Exponential } from './exponential';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _Laplace } from './laplace';
import type { _LogNormal } from './log-normal';
import type { _Normal } from './normal';
import type { _NormalMixture } from './normal-mixture';
import type { _Pareto } from './pareto';
import type { _Poisson } from './poisson';
import type { _Triangular } from './triangular';
import type { _Weibull } from './weibull';

/** 
 * Translated from class com.dataiku.dip.eda.compute.distributions.Distribution
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Distribution extends _ImmutableValueObject {
}

/** List of all concrete subclasses of com.dataiku.dip.eda.compute.distributions.Distribution */
export type Distribution =
    | Beta
    | Binomial
    | Exponential
    | Laplace
    | LogNormal
    | Normal
    | NormalMixture
    | Pareto
    | Poisson
    | Triangular
    | Weibull;

export namespace Distribution {
    /** Types of class com.dataiku.dip.eda.compute.distributions.Distribution in PolyJSON mappings */
    export const type = ["beta", "binomial", "exponential", "laplace", "lognormal", "normal", "normal_mixture", "pareto", "poisson", "triangular", "weibull", ] as const;
    
    export function isBeta(obj: Distribution): obj is Beta { return obj["type"] == "beta"; }
    export function isBinomial(obj: Distribution): obj is Binomial { return obj["type"] == "binomial"; }
    export function isDistribution(obj: Distribution): obj is Distribution { return ["beta", "binomial", "exponential", "laplace", "lognormal", "normal", "normal_mixture", "pareto", "poisson", "triangular", "weibull", ].includes(obj["type"]); }
    export function isExponential(obj: Distribution): obj is Exponential { return obj["type"] == "exponential"; }
    export function isLaplace(obj: Distribution): obj is Laplace { return obj["type"] == "laplace"; }
    export function isLogNormal(obj: Distribution): obj is LogNormal { return obj["type"] == "lognormal"; }
    export function isNormal(obj: Distribution): obj is Normal { return obj["type"] == "normal"; }
    export function isNormalMixture(obj: Distribution): obj is NormalMixture { return obj["type"] == "normal_mixture"; }
    export function isPareto(obj: Distribution): obj is Pareto { return obj["type"] == "pareto"; }
    export function isPoisson(obj: Distribution): obj is Poisson { return obj["type"] == "poisson"; }
    export function isTriangular(obj: Distribution): obj is Triangular { return obj["type"] == "triangular"; }
    export function isWeibull(obj: Distribution): obj is Weibull { return obj["type"] == "weibull"; }
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.distributions.Distribution$FittedDistribution
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FittedDistribution extends _ImmutableValueObject {
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.eda.compute.distributions.Distribution$FittedDistribution */
    export type FittedDistribution =
        | Beta.FittedBeta
        | Binomial.FittedBinomial
        | Exponential.FittedExponential
        | Laplace.FittedLaplace
        | LogNormal.FittedLogNormal
        | Normal.FittedNormal
        | NormalMixture.FittedNormalMixture
        | Pareto.FittedPareto
        | Poisson.FittedPoisson
        | Triangular.FittedTriangular
        | Weibull.FittedWeibull;

    export namespace FittedDistribution {
        /** Types of class com.dataiku.dip.eda.compute.distributions.Distribution$FittedDistribution in PolyJSON mappings */
        export const type = ["beta", "binomial", "exponential", "laplace", "lognormal", "normal", "normal_mixture", "pareto", "poisson", "triangular", "weibull", ] as const;
        
        export function isFittedBeta(obj: Distribution.FittedDistribution): obj is Beta.FittedBeta { return obj["type"] == "beta"; }
        export function isFittedBinomial(obj: Distribution.FittedDistribution): obj is Binomial.FittedBinomial { return obj["type"] == "binomial"; }
        export function isFittedDistribution(obj: Distribution.FittedDistribution): obj is Distribution.FittedDistribution { return ["beta", "binomial", "exponential", "laplace", "lognormal", "normal", "normal_mixture", "pareto", "poisson", "triangular", "weibull", ].includes(obj["type"]); }
        export function isFittedExponential(obj: Distribution.FittedDistribution): obj is Exponential.FittedExponential { return obj["type"] == "exponential"; }
        export function isFittedLaplace(obj: Distribution.FittedDistribution): obj is Laplace.FittedLaplace { return obj["type"] == "laplace"; }
        export function isFittedLogNormal(obj: Distribution.FittedDistribution): obj is LogNormal.FittedLogNormal { return obj["type"] == "lognormal"; }
        export function isFittedNormal(obj: Distribution.FittedDistribution): obj is Normal.FittedNormal { return obj["type"] == "normal"; }
        export function isFittedNormalMixture(obj: Distribution.FittedDistribution): obj is NormalMixture.FittedNormalMixture { return obj["type"] == "normal_mixture"; }
        export function isFittedPareto(obj: Distribution.FittedDistribution): obj is Pareto.FittedPareto { return obj["type"] == "pareto"; }
        export function isFittedPoisson(obj: Distribution.FittedDistribution): obj is Poisson.FittedPoisson { return obj["type"] == "poisson"; }
        export function isFittedTriangular(obj: Distribution.FittedDistribution): obj is Triangular.FittedTriangular { return obj["type"] == "triangular"; }
        export function isFittedWeibull(obj: Distribution.FittedDistribution): obj is Weibull.FittedWeibull { return obj["type"] == "weibull"; }
    }
}