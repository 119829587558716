import { Inject, Injectable } from '@angular/core';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { RegressionTypes } from '../../../constants';
import { ChartIAE } from '../../../models';
import { D3V3 } from '../../../interfaces';
import { ChartRegressionService } from '../../../services';

@Injectable({
    providedIn: 'root'
})
export class RegressionLineService {
    //object that will hold chart points from which regression will be drawn
    private regressionInstance: {points?: Array<[number, number]>, filled?: boolean} = {};


    constructor(
        @Inject('ChartAxesUtils') private chartAxesUtils: any,
        @Inject('StringUtils') private stringUtils: any,
        private chartRegressionService: ChartRegressionService
    ) {
    }

    /**
     * Initializes the regression instance
     */
    public initRegressionInstance() {
        this.regressionInstance = { points: [], filled: false };
    }

    /**
     * Adds scatterplot chart values to the regression instance
     */
    public addRegressionPoints(x: number, y: number) {
        if (Array.isArray(this.regressionInstance.points)) {
            this.regressionInstance.points.push([x, y]);
        } else {
            throw new ChartIAE('Regression instance not initialized.');
        }
    }

    /**
     * Checks if regressionInstance has points assigned
     */
    public isRegressionInstanceFilled(): boolean {
        return !!this.regressionInstance.filled;
    }

    /**
     * Draws regression line
     */
    public drawRegression(context: CanvasRenderingContext2D, regressionOptions: ChartDef.Regression, xCustomExtent: [number, number], yCustomExtent: [number, number], xPositionScale: D3V3["scale"], yPositionScale: D3V3["scale"]) {
        if (this.regressionInstance.points?.length) {
            const regression = this.chartRegressionService.regression(regressionOptions, this.regressionInstance.points, {
                dimensions: [0, 1],
                xCustomMin: this.chartAxesUtils.getManualExtentMin(xCustomExtent),
                yCustomMin: this.chartAxesUtils.getManualExtentMin(yCustomExtent)
            });

            if (regressionOptions.type === RegressionTypes.LINEAR) {
                regression.points = [regression.points[0], regression.points[regression.points.length - 1]];
            }

            let last: [number, number] | null = null;

            context.strokeStyle = context.fillStyle = regressionOptions.lineColor;
            const thicknessIncrease = 1.5;
            context.lineWidth = regressionOptions.lineSize * thicknessIncrease;

            regression.points.forEach(d => {
                const x = xPositionScale(d[0]);
                const y = yPositionScale(d[1]);
                if (last) {
                    context.beginPath();
                    context.moveTo(x, y);

                    const xMid = x + (last[0] - x) / 2;
                    const yMid = y + (last[1] - y) / 2;

                    context.quadraticCurveTo(xMid, yMid, last[0], last[1]);
                    context.stroke();
                }

                last = [x, y];
            });

            if (regressionOptions.displayFormula) {
                const pxlr = 2; // pixel ratio

                const { hasBackground, backgroundColor, fontSize, fontColor } = regressionOptions.textFormatting || {};

                const position = regressionOptions.labelPosition;
                const start = position.includes('START');
                const expressionWidth = this.stringUtils.getTextWidth(regression.expression, `${fontSize * pxlr}px SourceSansPro`);
                const xOffset = start ? 0 : expressionWidth * -1;
                const yOffset = -30;
                const point = start ? [xPositionScale(regression.points[0][0]), yPositionScale(regression.points[0][1])] : last;

                if (point) {
                    if (hasBackground) {
                        const xBgPadding = 4;
                        const yBgPadding = 8;
                        const yBgOffset = 6;
                        if (backgroundColor) {
                            context.fillStyle = backgroundColor;
                        }

                        context.fillRect(point[0] + xOffset - xBgPadding,
                            point[1] + yOffset - fontSize - yBgPadding - yBgOffset,
                            expressionWidth + 2 * xBgPadding,
                            fontSize * pxlr + 2 * yBgPadding);
                    }

                    if (fontColor) {
                        context.fillStyle = fontColor;
                    }

                    context.font = `${fontSize * pxlr}px SourceSansPro`;
                    context.fillText(regression.expression, point[0] + xOffset, point[1] + yOffset);
                    context.stroke();
                }
            }
        }

    }
}
