export namespace DeephubImagesDataService {
    /** 
     * Translated from class com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$Category
     * Via: com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ObjectDetectionPredictedFilter
     */
    export interface _Category {
        type: DeephubImagesDataService.CategoryType;
        value: string;
    }
    
    export type Category = _Category;
    
    /** 
     * Translated from enum com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$CategoryType
     * Via: com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$Category
     */
    export enum CategoryType {
        ANY = "ANY",
        NONE = "NONE",
        ONE = "ONE",
    }
    /** 
     * Translated from class com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ComputerVisionPredictedFilter
     * Via: com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ImageClassificationPredictedFilter
     */
    export interface _ComputerVisionPredictedFilter {
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ComputerVisionPredictedFilter */
    export type ComputerVisionPredictedFilter =
        | DeephubImagesDataService.ImageClassificationPredictedFilter
        | DeephubImagesDataService.ObjectDetectionPredictedFilter;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$DeepHubDesignFilterRequest
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _DeepHubDesignFilterRequest {
        targetCategories: string[];
    }
    
    export type DeepHubDesignFilterRequest = _DeepHubDesignFilterRequest;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ImageClassificationPredictedFilter
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ImageClassificationPredictedFilter extends DeephubImagesDataService._ComputerVisionPredictedFilter {
        type: "DEEP_HUB_IMAGE_CLASSIFICATION";
        ascending: boolean;
        detection?: string | null;
        groundTruth?: string | null;
    }
    
    export type ImageClassificationPredictedFilter = _ImageClassificationPredictedFilter;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ObjectDetectionPredictedFilter
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ObjectDetectionPredictedFilter extends DeephubImagesDataService._ComputerVisionPredictedFilter {
        type: "DEEP_HUB_IMAGE_OBJECT_DETECTION";
        detection?: DeephubImagesDataService.Category | null;
        groundTruth?: DeephubImagesDataService.Category | null;
        minConfidence: number;
        minIOU: number;
        sorting: DeephubImagesDataService.Sorting;
    }
    
    export type ObjectDetectionPredictedFilter = _ObjectDetectionPredictedFilter;
    
    /** 
     * Translated from enum com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$SortBy
     * Via: com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$Sorting
     */
    export enum SortBy {
        IOU = "IOU",
        CONFIDENCE = "CONFIDENCE",
    }
    /** 
     * Translated from class com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$Sorting
     * Via: com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ObjectDetectionPredictedFilter
     */
    export interface _Sorting {
        ascending: boolean;
        sortBy: DeephubImagesDataService.SortBy;
    }
    
    export type Sorting = _Sorting;

    export namespace ComputerVisionPredictedFilter {
        /** Types of class com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ComputerVisionPredictedFilter in PolyJSON mappings */
        export const type = ["DEEP_HUB_IMAGE_CLASSIFICATION", "DEEP_HUB_IMAGE_OBJECT_DETECTION", ] as const;
        
        export function isComputerVisionPredictedFilter(obj: DeephubImagesDataService.ComputerVisionPredictedFilter): obj is DeephubImagesDataService.ComputerVisionPredictedFilter { return ["DEEP_HUB_IMAGE_CLASSIFICATION", "DEEP_HUB_IMAGE_OBJECT_DETECTION", ].includes(obj["type"]); }
        export function isImageClassificationPredictedFilter(obj: DeephubImagesDataService.ComputerVisionPredictedFilter): obj is DeephubImagesDataService.ImageClassificationPredictedFilter { return obj["type"] == "DEEP_HUB_IMAGE_CLASSIFICATION"; }
        export function isObjectDetectionPredictedFilter(obj: DeephubImagesDataService.ComputerVisionPredictedFilter): obj is DeephubImagesDataService.ObjectDetectionPredictedFilter { return obj["type"] == "DEEP_HUB_IMAGE_OBJECT_DETECTION"; }
    }

    export namespace ImageClassificationPredictedFilter {
        /** Type of class com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ImageClassificationPredictedFilter in PolyJSON mappings */
        export const type = "DEEP_HUB_IMAGE_CLASSIFICATION";
    }

    export namespace ObjectDetectionPredictedFilter {
        /** Type of class com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ObjectDetectionPredictedFilter in PolyJSON mappings */
        export const type = "DEEP_HUB_IMAGE_OBJECT_DETECTION";
    }
}