import type { Grouping } from './grouping';
import type { GroupingResult } from './grouping-result';
import type { _Grouping } from './grouping';
import type { _GroupingResult } from './grouping-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.grouping.UnionGrouping
 * Via: UIModel annotation in the class hierarchy
 */
export interface _UnionGrouping extends _Grouping {
    type: "union";
    groupings: Grouping[];
}

export type UnionGrouping = _UnionGrouping;

export namespace UnionGrouping {
    /** Type of class com.dataiku.dip.eda.compute.grouping.UnionGrouping in PolyJSON mappings */
    export const type = "union";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.grouping.UnionGrouping$UnionGroupingResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _UnionGroupingResult extends _GroupingResult {
        type: "union";
        groupings: GroupingResult[];
    }
    
    export type UnionGroupingResult = _UnionGroupingResult;

    export namespace UnionGroupingResult {
        /** Type of class com.dataiku.dip.eda.compute.grouping.UnionGrouping$UnionGroupingResult in PolyJSON mappings */
        export const type = "union";
    }
}