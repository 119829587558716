export namespace PythonCodeEnvPackagesUtils {
    /** 
     * Translated from class com.dataiku.dip.code.PythonCodeEnvPackagesUtils$CodeEnvExperimentTrackingCompat
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _CodeEnvExperimentTrackingCompat {
        compatibilityInfo: PythonCodeEnvPackagesUtils.ExperimentTrackingCompatibilityInfo;
        envName: string;
    }
    
    export type CodeEnvExperimentTrackingCompat = _CodeEnvExperimentTrackingCompat;
    
    /** 
     * Translated from class com.dataiku.dip.code.PythonCodeEnvPackagesUtils$ExperimentTrackingCompatibilityInfo
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ExperimentTrackingCompatibilityInfo {
        compatibilityStatus: PythonCodeEnvPackagesUtils.ExperimentTrackingCompatibilityInfo.CompatibilityStatus;
        reasons: string[];
    }
    
    export type ExperimentTrackingCompatibilityInfo = _ExperimentTrackingCompatibilityInfo;

    export namespace ExperimentTrackingCompatibilityInfo {
        /** 
         * Translated from enum com.dataiku.dip.code.PythonCodeEnvPackagesUtils$ExperimentTrackingCompatibilityInfo$CompatibilityStatus
         * Via: com.dataiku.dip.code.PythonCodeEnvPackagesUtils$ExperimentTrackingCompatibilityInfo
         */
        export enum CompatibilityStatus {
            INCOMPATIBLE = "INCOMPATIBLE",
            MAYBE_COMPATIBLE = "MAYBE_COMPATIBLE",
            TESTED_COMPATIBLE = "TESTED_COMPATIBLE",
        }
    }
}