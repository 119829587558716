import { AxisDef } from '@model-main/pivot/backend/model/axis-def';
import { AxisSortPrune } from '@model-main/pivot/backend/model/axis-sort-prune';
import { PivotTableResponse } from '@model-main/pivot/backend/model/pivot-table-response';
import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { SamplingParam } from '@model-main/datasets/sampling-param';

export const pieNumericalByAlphanumericalPivotResponse: PivotTableTensorResponse = {
    axisLabels: [[
        {
            label: 'New Zealand'
        },
        {
            label: 'Norway'
        },
        {
            label: 'Israel'
        },
        {
            label: 'Singapore'
        },
        {
            label: 'Austria'
        },
        {
            label: 'Australia'
        },
        {
            label: 'Switzerland'
        },
        {
            label: 'United States'
        },
        {
            label: 'South Korea'
        },
        {
            label: 'Netherlands'
        },
        {
            label: 'Others'
        }
    ]] as Array<any>,
    axisDefs: [
        {
            column: 'ip_country',
            type: AxisDef.Type.ALPHANUM,
            sortPrune: {
                sortType: AxisSortPrune.SortType.AGGREGATION,
                sortAscending: false,
                aggregationSortId: 0,
                generateOthersCategory: true,
                forceLastPositionOthers: true,
                filters: [],
                maxValues: 10
            }
        }
    ] as Array<any>,
    sequenceId: 0,
    aggregations: [
        {
            numAxes: 1,
            axisLengths: [
                11
            ],
            tensor: [
                261.3333333333333,
                240.25,
                233.5,
                225.0,
                224.0,
                222.4,
                207.14285714285714,
                206.65777777777777,
                201.95,
                201.0,
                145.76388888888889
            ],
            axes: [
                [
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0
                ]
            ],
            multipliers: [
                1,
            ]
        }
    ] as Array<any>,
    counts: {
        numAxes: 1,
        axisLengths: [
            11
        ],
        tensor: [
            3,
            4,
            2,
            1,
            1,
            5,
            7,
            225,
            20,
            9,
            288
        ],
        axes: [
            [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
        ],
        multipliers: [
            1
        ]
    },
    filterFacets: [],
    beforeFilterRecords: 565,
    afterFilterRecords: 565,
    engine: PivotTableResponse.PivotEngine.LINO,
    sampleMetadata: {
        samplingMethod: SamplingParam.SamplingMethod.RANDOM_FIXED_RATIO,
        maxRecords: 30000,
        targetRatio: 0.02,
        hasFilter: false,
        partitionCount: 0,
        sampleIsWholeDataset: false,
        sampleRecordCount: -1,
        datasetRecordCount: 29799,
        recordCountIsApproximate: false,
        recordCountIsObsolete: false,
        memoryLimitInMB: 150,
        memoryLimitReached: false
    },
    columnsSummary: undefined as any
};

export const pieAlphanumericalByAlphanumericalPivotResponse: PivotTableTensorResponse = {
    axisLabels: [[
        {
            label: 'False'
        },
        {
            label: 'True'
        }
    ]] as Array<any>,
    axisDefs: [
        {
            column: 'campain',
            type: AxisDef.Type.ALPHANUM,
            sortPrune: {
                sortType: AxisSortPrune.SortType.AGGREGATION,
                sortAscending: false,
                aggregationSortId: 0,
                generateOthersCategory: true,
                forceLastPositionOthers: true,
                filters: [],
                maxValues: 20
            }
        }
    ] as Array<any>,
    sequenceId: 0,
    aggregations: [
        {
            numAxes: 1,
            axisLengths: [
                3
            ],
            tensor: [
                0.625,
                0.375,
                0.0
            ],
            axes: [
                [
                    0.0,
                    0.0,
                    0.0
                ]
            ],
            multipliers: [
                1
            ]

        }
    ] as Array<any>,
    counts: {
        numAxes: 1,
        axisLengths: [
            3
        ],
        tensor: [
            423,
            142,
            0
        ],
        axes: [
            [
                0,
                0,
                0
            ]
        ],
        multipliers: [
            1
        ]
    },
    filterFacets: [],
    beforeFilterRecords: 565,
    afterFilterRecords: 565,
    engine: PivotTableResponse.PivotEngine.LINO,
    sampleMetadata: {
        samplingMethod: SamplingParam.SamplingMethod.RANDOM_FIXED_RATIO,
        maxRecords: 30000,
        targetRatio: 0.02,
        hasFilter: false,
        partitionCount: 0,
        sampleIsWholeDataset: false,
        sampleRecordCount: -1,
        datasetRecordCount: 29799,
        recordCountIsApproximate: false,
        recordCountIsObsolete: false,
        memoryLimitInMB: 150,
        memoryLimitReached: false
    },
    columnsSummary: undefined as any
};

export const pieNumericalByDatePivotResponse: PivotTableTensorResponse = {
    axisLabels: [[
        {
            tsValue: 1325376000000,
            sortValue: 24144.0,
            min: 1.325376E12,
            max: 1.328054399999E12,
            label: '01/2012'
        },
        {
            tsValue: 1328054400000,
            sortValue: 24145.0,
            min: 1.3280544E12,
            max: 1.330559999999E12,
            label: '02/2012'
        },
        {
            tsValue: 1335830400000,
            sortValue: 24148.0,
            min: 1.3358304E12,
            max: 1.338508799999E12,
            label: '05/2012'
        },
        {
            tsValue: 1330560000000,
            sortValue: 24146.0,
            min: 1.33056E12,
            max: 1.333238399999E12,
            label: '03/2012'
        },
        {
            tsValue: 1333238400000,
            sortValue: 24147.0,
            min: 1.3332384E12,
            max: 1.335830399999E12,
            label: '04/2012'
        },
        {
            tsValue: 1341100800000,
            sortValue: 24150.0,
            min: 1.3411008E12,
            max: 1.343779199999E12,
            label: '07/2012'
        },
        {
            tsValue: 1338508800000,
            sortValue: 24149.0,
            min: 1.3385088E12,
            max: 1.341100799999E12,
            label: '06/2012'
        },
        {
            tsValue: 1343779200000,
            sortValue: 24151.0,
            min: 1.3437792E12,
            max: 1.346457599999E12,
            label: '08/2012'
        },
        {
            tsValue: 1346457600000,
            sortValue: 24152.0,
            min: 1.3464576E12,
            max: 1.349049599999E12,
            label: '09/2012'
        },
        {
            tsValue: 1349049600000,
            sortValue: 24153.0,
            min: 1.3490496E12,
            max: 1.351727999999E12,
            label: '10/2012'
        },
        {
            tsValue: 1351728000000,
            sortValue: 24154.0,
            min: 1.351728E12,
            max: 1.354319999999E12,
            label: '11/2012'
        }
    ]] as Array<any>,
    axisDefs: [
        {
            column: 'day',
            type: 'DATE',
            sortPrune: {
                sortType: 'AGGREGATION',
                sortAscending: false,
                aggregationSortId: 0,
                generateOthersCategory: true,
                forceLastPositionOthers: true,
                filters: [],
                maxValues: 0
            },
            dateParams: {
                mode: 'MONTH',
                maxBinNumberForAutomaticMode: 30
            }
        }
    ] as Array<any>,
    sequenceId: 0,
    aggregations: [
        {
            numAxes: 1,
            axisLengths: [
                12
            ],
            tensor: [
                1361.7010902031063,
                1304.1956417624522,
                1282.7393966547193,
                1248.1039330043368,
                1247.0121141975308,
                1105.782784237726,
                1053.4611111111112,
                68.9899193548387,
                61.36886574074074,
                57.17265325670498,
                28.80812757201646,
                0.0
            ],
            axes: [
                [
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0
                ]
            ],
            multipliers: [
                1
            ]
        }
    ] as Array<any>,
    counts: {
        numAxes: 1,
        axisLengths: [
            12
        ],
        tensor: [
            13392,
            12528,
            13392,
            13374,
            12960,
            12384,
            12960,
            8928,
            8640,
            8352,
            3888,
            0
        ],
        axes: [
            [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ]
        ],
        multipliers: [
            1
        ]
    },
    filterFacets: [],
    beforeFilterRecords: 565,
    afterFilterRecords: 565,
    engine: PivotTableResponse.PivotEngine.LINO,
    sampleMetadata: {
        samplingMethod: SamplingParam.SamplingMethod.FULL,
        maxRecords: 100,
        targetRatio: 0.02,
        hasFilter: false,
        partitionCount: 0,
        sampleIsWholeDataset: true,
        sampleRecordCount: -1,
        datasetRecordCount: 29799,
        recordCountIsApproximate: false,
        recordCountIsObsolete: false,
        memoryLimitInMB: 150,
        memoryLimitReached: false
    },
    columnsSummary: undefined as any
};

export const pieAlphanumericalByDatePivotResponse: PivotTableTensorResponse = {
    axisLabels: [[
        {
            tsValue: 0,
            sortValue: 0.0,
            min: 0.0,
            max: 0.0,
            label: '1'
        },
        {
            tsValue: 0,
            sortValue: 1.0,
            min: 0.0,
            max: 0.0,
            label: '2'
        },
        {
            tsValue: 0,
            sortValue: 2.0,
            min: 0.0,
            max: 0.0,
            label: '3'
        },
        {
            tsValue: 0,
            sortValue: 3.0,
            min: 0.0,
            max: 0.0,
            label: '4'
        }
    ]] as Array<any>,
    axisDefs: [
        {
            column: 'day',
            type: 'DATE',
            sortPrune: {
                sortType: 'AGGREGATION',
                sortAscending: false,
                aggregationSortId: 0,
                generateOthersCategory: true,
                forceLastPositionOthers: true,
                filters: [],
                maxValues: 0
            },
            dateParams: {
                mode: 'MONTH',
                maxBinNumberForAutomaticMode: 30
            }
        }
    ] as Array<any>,
    sequenceId: 0,
    aggregations: [
        {
            numAxes: 1,
            axisLengths: [
                5
            ],
            tensor: [
                37.0,
                12.0,
                22.0,
                32.0,
                0.0
            ],
            axes: [
                [
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0
                ]
            ],
            multipliers: [
                1
            ]
        }
    ] as Array<any>,
    counts: {
        numAxes: 1,
        axisLengths: [
            5
        ],
        tensor: [
            38,
            12,
            23,
            36,
            0
        ],
        axes: [
            [
                0,
                0,
                0,
                0,
                0
            ]
        ],
        multipliers: [
            1
        ]
    },
    filterFacets: [],
    beforeFilterRecords: 565,
    afterFilterRecords: 565,
    engine: PivotTableResponse.PivotEngine.LINO,
    sampleMetadata: {
        samplingMethod: SamplingParam.SamplingMethod.RANDOM_FIXED_NB,
        maxRecords: 100,
        targetRatio: 0.02,
        hasFilter: false,
        partitionCount: 0,
        sampleIsWholeDataset: true,
        sampleRecordCount: -1,
        datasetRecordCount: 29799,
        recordCountIsApproximate: false,
        recordCountIsObsolete: false,
        memoryLimitInMB: 150,
        memoryLimitReached: false
    },
    columnsSummary: undefined as any
};

export const pieNumericalByNumericalPivotResponse: PivotTableTensorResponse = {
    axisLabels: [[
        {
            sortValue: 222.875,
            min: 9.0,
            max: 436.75,
            label: '9-437'
        },
        {
            sortValue: 650.625,
            min: 436.75,
            max: 864.5,
            label: '437-865'
        },
        {
            sortValue: 1078.375,
            min: 864.5,
            max: 1292.25,
            label: '865-1292'
        },
        {
            sortValue: 1506.125,
            min: 1292.25,
            max: 1720.0,
            label: '1292-1720'
        }
    ]] as Array<any>,
    axisDefs: [
        {
            column: 'days_active',
            type: 'NUMERICAL',
            sortPrune: {
                sortType: 'NATURAL',
                sortAscending: true,
                aggregationSortId: -1,
                generateOthersCategory: false,
                filters: [],
                maxValues: 0
            },
            numParams: {
                mode: 'FIXED_NB',
                emptyBinsMode: 'ZEROS',
                nbBins: 4,
                binSize: 100.0,
                niceBounds: false
            }
        }
    ] as Array<any>,
    sequenceId: 0,
    aggregations: [
        {
            numAxes: 1,
            axisLengths: [
                4
            ],
            tensor: [
                250.05963855421678,
                235.20300000000003,
                197.92600000000002,
                61.46
            ],
            axes: [
                [
                    0.0,
                    0.0,
                    0.0,
                    0.0
                ]
            ],
            multipliers: [
                1
            ]
        }
    ] as Array<any>,
    counts: {
        numAxes: 1,
        axisLengths: [
            4
        ],
        tensor: [
            83,
            20,
            5,
            1
        ],
        axes: [
            [
                0,
                0,
                0,
                0
            ]
        ],
        multipliers: [
            1
        ]
    },
    filterFacets: [],
    beforeFilterRecords: 565,
    afterFilterRecords: 565,
    engine: PivotTableResponse.PivotEngine.LINO,
    sampleMetadata: {
        samplingMethod: SamplingParam.SamplingMethod.RANDOM_FIXED_NB,
        maxRecords: 30000,
        targetRatio: 0.02,
        hasFilter: false,
        partitionCount: 0,
        sampleIsWholeDataset: false,
        sampleRecordCount: -1,
        datasetRecordCount: 29799,
        recordCountIsApproximate: false,
        recordCountIsObsolete: false,
        memoryLimitInMB: 150,
        memoryLimitReached: false
    },
    columnsSummary: undefined as any
};

export const pieAlphanumericalByNumericalPivotResponse: PivotTableTensorResponse = {
    axisLabels: [[
        {
            sortValue: 222.875,
            min: 9.0,
            max: 436.75,
            label: '9-437'
        },
        {
            sortValue: 650.625,
            min: 436.75,
            max: 864.5,
            label: '437-865'
        },
        {
            sortValue: 1078.375,
            min: 864.5,
            max: 1292.25,
            label: '865-1292'
        },
        {
            sortValue: 1506.125,
            min: 1292.25,
            max: 1720.0,
            label: '1292-1720'
        }
    ]] as Array<any>,
    axisDefs: [
        {
            column: 'days_active',
            type: 'NUMERICAL',
            sortPrune: {
                sortType: 'NATURAL',
                sortAscending: true,
                aggregationSortId: -1,
                generateOthersCategory: false,
                filters: [],
                maxValues: 0
            },
            numParams: {
                mode: 'FIXED_NB',
                emptyBinsMode: 'ZEROS',
                nbBins: 4,
                binSize: 100.0,
                niceBounds: false
            }
        }
    ] as Array<any>,
    sequenceId: 0,
    aggregations: [
        {
            numAxes: 1,
            axisLengths: [
                4
            ],
            tensor: [
                76.0,
                20.0,
                5.0,
                1.0
            ],
            axes: [
                [
                    0.0,
                    0.0,
                    0.0,
                    0.0
                ]
            ],
            multipliers: [
                1
            ]
        }
    ] as Array<any>,
    counts: {
        numAxes: 1,
        axisLengths: [
            4
        ],
        tensor: [
            83,
            20,
            5,
            1
        ],
        axes: [
            [
                0,
                0,
                0,
                0
            ]
        ],
        multipliers: [
            1
        ]
    },
    filterFacets: [],
    beforeFilterRecords: 565,
    afterFilterRecords: 565,
    engine: PivotTableResponse.PivotEngine.LINO,
    sampleMetadata: {
        samplingMethod: SamplingParam.SamplingMethod.RANDOM_FIXED_NB,
        maxRecords: 30000,
        targetRatio: 0.02,
        hasFilter: false,
        partitionCount: 0,
        sampleIsWholeDataset: false,
        sampleRecordCount: -1,
        datasetRecordCount: 29799,
        recordCountIsApproximate: false,
        recordCountIsObsolete: false,
        memoryLimitInMB: 150,
        memoryLimitReached: false
    },
    columnsSummary: undefined as any
};

export const pieNumericalByAlphanumericalWithFacetsPivotResponse: PivotTableTensorResponse = {
    axisLabels: [
        [
            {
                label: 'liquor'
            },
            {
                label: 'insurance'
            },
            {
                label: 'internet'
            },
            {
                label: 'gas'
            },
            {
                label: 'luxury goods'
            }
        ],
        [
            {
               sortValue: 0.25,
               min: 0.0,
               max: 0.5,
               label: '0-0.5'
            },
            {
                sortValue: 0.75,
                min: 0.5,
                max: 1.0,
                label: '0.5-1.0'
            }
        ]
    ] as Array<any>,
    axisDefs: [
        {
            column: 'merchant_subsector_description',
            type: 'ALPHANUM',
            sortPrune: {
                sortType: 'AGGREGATION',
                sortAscending: false,
                aggregationSortId: 0,
                generateOthersCategory: true,
                forceLastPositionOthers: true,
                filters: [],
                maxValues: 20
            }
        },
        {
            column: 'authorized_flag',
            type: 'NUMERICAL',
            sortPrune: {
                sortType: 'NATURAL',
                sortAscending: true,
                aggregationSortId: -1,
                generateOthersCategory: false,
                filters: [],
                maxValues: 0
            },
            numParams: {
                mode: 'FIXED_NB',
                emptyBinsMode: 'ZEROS',
                nbBins: 2,
                binSize: 100.0,
                niceBounds: false
            }
        }
    ] as Array<any>,
    sequenceId: 0,
    aggregations: [
        {
            numAxes: 2,
            axisLengths: [
                6,
                2
            ],
            tensor: [
                0.0,
                753.55,
                0.0,
                146.74,
                0.0,
                0.0,
                1770.83,
                37.45,
                277.31,
                0.0,
                50.980000000000004,
                0.0
            ],
            axes: [
                [
                    1770.83,
                    791.0,
                    277.31,
                    146.74,
                    50.980000000000004,
                    0.0
                ],
                [
                    900.29,
                    2136.57
                ]
            ],
            multipliers: [
                1,
                6
            ]
        }
    ] as Array<any>,
    counts: {
        numAxes: 2,
        axisLengths: [
            6,
            2
        ],
        tensor: [
            0,
            1,
            0,
            1,
            0,
            0,
            1,
            1,
            2,
            0,
            2,
            0
        ],
        axes: [
            [
                1,
                2,
                2,
                1,
                2,
                0
            ],
            [
                2,
                6
            ]
        ],
        multipliers: [
            1,
            6
        ]
    },
    filterFacets: [],
    beforeFilterRecords: 8,
    afterFilterRecords: 8,
    engine: PivotTableResponse.PivotEngine.LINO,
    sampleMetadata: {
        samplingMethod: SamplingParam.SamplingMethod.RANDOM_FIXED_NB,
        maxRecords: 8,
        targetRatio: 0.02,
        hasFilter: false,
        partitionCount: 0,
        sampleIsWholeDataset: false,
        sampleRecordCount: -1,
        datasetRecordCount: 326808,
        recordCountIsApproximate: false,
        recordCountIsObsolete: false,
        memoryLimitReached: false,
        memoryLimitInMB: 0
    },
    columnsSummary: undefined as any
}
