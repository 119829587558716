import type { ParamDesc } from './../autoconfig/param-desc';
import type { _ParamDesc } from './../autoconfig/param-desc';

/** 
 * Translated from class com.dataiku.dip.coremodel.AppHomepageTile
 * Via: com.dataiku.dip.coremodel.AppHomepageTile$RunScenarioTile
 */
export interface _AppHomepageTile {
    help: string;
    helpTitle: string;
    prompt: string;
    type: AppHomepageTile.AppHomepageTileType;
    visibilityCondition: string;
}

/**
    Translate from class com.dataiku.dip.coremodel.AppHomepageTile
    @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
*/
export type AppHomepageTile = _AppHomepageTile;

export namespace AppHomepageTile {
    /** 
     * Translated from enum com.dataiku.dip.coremodel.AppHomepageTile$AppHomepageTileType
     * Via: com.dataiku.dip.coremodel.AppHomepageTile
     */
    export enum AppHomepageTileType {
        UPLOAD_DATASET_SET_FILE = "UPLOAD_DATASET_SET_FILE",
        INLINE_DATASET_EDIT = "INLINE_DATASET_EDIT",
        MANAGED_FOLDER_ADD_FILE = "MANAGED_FOLDER_ADD_FILE",
        DATASET_EDIT_SETTINGS = "DATASET_EDIT_SETTINGS",
        STREAMING_ENDPOINT_EDIT_SETTINGS = "STREAMING_ENDPOINT_EDIT_SETTINGS",
        FILES_BASED_DATASET_BROWSE_AND_PREVIEW = "FILES_BASED_DATASET_BROWSE_AND_PREVIEW",
        MANAGED_FOLDER_BROWSE = "MANAGED_FOLDER_BROWSE",
        CONNECTION_EXPLORER_TO_REPLACE_THE_SETTINGS_OF_A_DATASET_WITH_A_NEW_TABLE_REFERENCE = "CONNECTION_EXPLORER_TO_REPLACE_THE_SETTINGS_OF_A_DATASET_WITH_A_NEW_TABLE_REFERENCE",
        PROJECT_VARIABLES_EDIT = "PROJECT_VARIABLES_EDIT",
        PERFORM_SCHEMA_PROPAGATION = "PERFORM_SCHEMA_PROPAGATION",
        GUESS_TRAIN_DEPLOY = "GUESS_TRAIN_DEPLOY",
        SCENARIO_RUN = "SCENARIO_RUN",
        INLINE_PYTHON_RUN = "INLINE_PYTHON_RUN",
        DASHBOARD_LINK = "DASHBOARD_LINK",
        DOWNLOAD_DATASET = "DOWNLOAD_DATASET",
        DOWNLOAD_RMARKDOWN = "DOWNLOAD_RMARKDOWN",
        MANAGED_FOLDER_LINK = "MANAGED_FOLDER_LINK",
        DOWNLOAD_MANAGED_FOLDER_FILE = "DOWNLOAD_MANAGED_FOLDER_FILE",
        DOWNLOAD_DASHBOARD_EXPORT = "DOWNLOAD_DASHBOARD_EXPORT",
        VARIABLE_DISPLAY = "VARIABLE_DISPLAY",
    }
    /** 
     * Translated from class com.dataiku.dip.coremodel.AppHomepageTile$ProjectVariablesTile
     * Via: com.dataiku.dip.coremodel.AppManifest$AppUseAsRecipeSettings
     */
    export interface _ProjectVariablesTile extends _AppHomepageTile {
        behavior: AppHomepageTile.ProjectVariablesTileBehavior;
        buttonText: string;
        html: string;
        js: string;
        module: string;
        params: ParamDesc[];
        python: string;
    }
    
    export type ProjectVariablesTile = _ProjectVariablesTile;
    
    /** 
     * Translated from enum com.dataiku.dip.coremodel.AppHomepageTile$ProjectVariablesTileBehavior
     * Via: com.dataiku.dip.coremodel.AppHomepageTile$ProjectVariablesTile
     */
    export enum ProjectVariablesTileBehavior {
        INLINE_AUTO_SAVE = "INLINE_AUTO_SAVE",
        INLINE_EXPLICIT_SAVE = "INLINE_EXPLICIT_SAVE",
        MODAL = "MODAL",
    }
    /** 
     * Translated from class com.dataiku.dip.coremodel.AppHomepageTile$RunScenarioTile
     * Via: com.dataiku.dip.coremodel.AppManifest$AppUseAsRecipeSettings
     */
    export interface _RunScenarioTile extends _AppHomepageTile {
        buttonText: string;
        scenarioId: string;
    }
    
    export type RunScenarioTile = _RunScenarioTile;
}