import type { AbstractCardContainer } from './abstract-card-container';
import type { Variable } from './../../models/variable';
import type { _AbstractCardContainer } from './abstract-card-container';
import type { _Variable } from './../../models/variable';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.common.ColumnCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ColumnCard extends _AbstractCardContainer {
    type: "column_card";
    column: Variable;
}

export type ColumnCard = _ColumnCard;

export namespace ColumnCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.common.ColumnCard in PolyJSON mappings */
    export const type = "column_card";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.common.ColumnCard$ColumnCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ColumnCardResult extends AbstractCardContainer._AbstractCardContainerResult {
        type: "column_card";
        column: Variable;
    }
    
    export type ColumnCardResult = _ColumnCardResult;

    export namespace ColumnCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.common.ColumnCard$ColumnCardResult in PolyJSON mappings */
        export const type = "column_card";
    }
}