import type { CardResult } from './card-result';
import type { Filter } from './../../compute/filtering/filter';
import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { SplitBySpec } from './../models/split-by-spec';
import type { TrivariateCard } from './trivariate-card';
import type { _CardResult } from './card-result';
import type { _Filter } from './../../compute/filtering/filter';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _SplitBySpec } from './../models/split-by-spec';
import type { _TrivariateCard } from './trivariate-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.ScatterPlot3DCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ScatterPlot3DCard extends _TrivariateCard {
    type: "scatter_plot_3d";
    colorBy?: SplitBySpec | null;
    highlightFilter?: Filter | null;
    maxNumberOfPoints: number;
    symbolSize: number;
}

export type ScatterPlot3DCard = _ScatterPlot3DCard;

export namespace ScatterPlot3DCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.ScatterPlot3DCard in PolyJSON mappings */
    export const type = "scatter_plot_3d";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.ScatterPlot3DCard$ScatterPlot3DCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ScatterPlot3DCardResult extends _CardResult {
        type: "scatter_plot_3d";
        groups: Filter[];
        series3d: ScatterPlot3DCard.ScatterPlot3DSeries[];
    }
    
    export type ScatterPlot3DCardResult = _ScatterPlot3DCardResult;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.ScatterPlot3DCard$ScatterPlot3DSeries
     * Via: com.dataiku.dip.eda.worksheets.cards.ScatterPlot3DCard$ScatterPlot3DCardResult
     */
    export interface _ScatterPlot3DSeries extends _ImmutableValueObject {
        groupIndex: number;
        isHighlighted: boolean;
        xSeries: number[];
        ySeries: number[];
        zSeries: number[];
    }
    
    export type ScatterPlot3DSeries = _ScatterPlot3DSeries;

    export namespace ScatterPlot3DCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.ScatterPlot3DCard$ScatterPlot3DCardResult in PolyJSON mappings */
        export const type = "scatter_plot_3d";
    }
}