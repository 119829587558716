import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { UnivariateSummaryStatsCard, BivariateSummaryStatsCard, Variable } from 'src/generated-sources';
import { produce } from 'immer';


// Create types derived from J2TS-generated models in order to get some type safety
// - Prevent typos on property names
// - Cannot detect missing items
type SupportedCards = UnivariateSummaryStatsCard | BivariateSummaryStatsCard;

type StatItem = (
    {
        cardType: UnivariateSummaryStatsCard['type'],
        property: keyof UnivariateSummaryStatsCard.StatsToCompute,
        variableType?: Variable.Type
    }
    | {
        xVariableType?: Variable.Type,
        yVariableType?: Variable.Type,
        cardType: BivariateSummaryStatsCard['type'],
        property: keyof BivariateSummaryStatsCard.StatsToCompute
    }
) & { label: string };

export type StatKey = string & Partial<StatItem>['property'];

const allStatItems: StatItem[] = [
    {
        property: 'totalCount',
        cardType: 'univariate_summary',
        label: 'Total count'
    },
    {
        property: 'mean',
        variableType: Variable.Type.CONTINUOUS,
        cardType: 'univariate_summary',
        label: 'Mean'
    },
    {
        property: 'sum',
        variableType: Variable.Type.CONTINUOUS,
        cardType: 'univariate_summary',
        label: 'Sum'
    },
    {
        property: 'median',
        variableType: Variable.Type.CONTINUOUS,
        cardType: 'univariate_summary',
        label: 'Median'
    },
    {
        property: 'std',
        variableType: Variable.Type.CONTINUOUS,
        cardType: 'univariate_summary',
        label: 'Std Dev'
    },
    {
        property: 'variance',
        variableType: Variable.Type.CONTINUOUS,
        cardType: 'univariate_summary',
        label: 'Variance'
    },
    {
        property: 'skewness',
        variableType: Variable.Type.CONTINUOUS,
        cardType: 'univariate_summary',
        label: 'Skewness'
    },
    {
        property: 'kurtosis',
        variableType: Variable.Type.CONTINUOUS,
        cardType: 'univariate_summary',
        label: 'Kurtosis'
    },
    {
        property: 'sem',
        variableType: Variable.Type.CONTINUOUS,
        cardType: 'univariate_summary',
        label: 'Standard Error of the Mean'
    },
    {
        property: 'completeCount',
        variableType: Variable.Type.CONTINUOUS,
        cardType: 'univariate_summary',
        label: 'N finite'
    },
    {
        property: 'nbEmpty',
        variableType: Variable.Type.CATEGORICAL,
        cardType: 'univariate_summary',
        label: 'N empty'
    },
    {
        property: 'zeroCount',
        variableType: Variable.Type.CONTINUOUS,
        cardType: 'univariate_summary',
        label: 'Zero count'
    },
    {
        property: 'zeroRatio',
        variableType: Variable.Type.CONTINUOUS,
        cardType: 'univariate_summary',
        label: 'Zero ratio'
    },
    {
        property: 'nonZeroRatio',
        variableType: Variable.Type.CONTINUOUS,
        cardType: 'univariate_summary',
        label: 'Non zero ratio'
    },
    {
        property: 'iqr',
        variableType: Variable.Type.CONTINUOUS,
        cardType: 'univariate_summary',
        label: 'IQR'
    },
    {
        property: 'distinctCount',
        cardType: 'univariate_summary',
        label: 'N distinct'
    },
    {
        property: 'nbNonEmpty',
        cardType: 'univariate_summary',
        variableType: Variable.Type.CATEGORICAL,
        label: 'N non empty'
    },
    {
        property: 'min',
        variableType: Variable.Type.CONTINUOUS,
        cardType: 'univariate_summary',
        label: 'Min'
    },
    {
        property: 'max',
        variableType: Variable.Type.CONTINUOUS,
        cardType: 'univariate_summary',
        label: 'Max'
    },
    {
        property: 'range',
        variableType: Variable.Type.CONTINUOUS,
        cardType: 'univariate_summary',
        label: 'Range'
    },
    {
        property: 'mode',
        variableType: Variable.Type.CATEGORICAL,
        cardType: 'univariate_summary',
        label: 'Mode'
    },
    {
        property: 'spearman',
        cardType: 'bivariate_summary',
        xVariableType: Variable.Type.CONTINUOUS,
        yVariableType: Variable.Type.CONTINUOUS,
        label: 'Spearman'
    },
    {
        property: 'pearson',
        cardType: 'bivariate_summary',
        xVariableType: Variable.Type.CONTINUOUS,
        yVariableType: Variable.Type.CONTINUOUS,
        label: 'Pearson'
    },
    {
        property: 'totalCount',
        cardType: 'bivariate_summary',
        label: 'Count'
    },
    {
        property: 'covariance',
        cardType: 'bivariate_summary',
        xVariableType: Variable.Type.CONTINUOUS,
        yVariableType: Variable.Type.CONTINUOUS,
        label: 'Covariance'
    },
    {
        property: 'kendallTau',
        xVariableType: Variable.Type.CONTINUOUS,
        yVariableType: Variable.Type.CONTINUOUS,
        cardType: 'bivariate_summary',
        label: 'Kendall Tau'
    },
    {
        property: 'mutualInformation',
        xVariableType: Variable.Type.CATEGORICAL,
        yVariableType: Variable.Type.CATEGORICAL,
        cardType: 'bivariate_summary',
        label: 'Normalized mutual information'
    },
    {
        property: 'completeCount',
        cardType: 'bivariate_summary',
        label: 'N finite'
    },
    {
        property: 'chi2',
        xVariableType: Variable.Type.CATEGORICAL,
        yVariableType: Variable.Type.CATEGORICAL,
        cardType: 'bivariate_summary',
        label: 'Chi2',
    },
    {
        // One-way ANOVA - usual case
        property: 'oneWayAnova',
        xVariableType: Variable.Type.CATEGORICAL,
        yVariableType: Variable.Type.CONTINUOUS,
        cardType: 'bivariate_summary',
        label: 'One-way ANOVA',
    },
    {
        // One-way ANOVA - symmetric case
        property: 'oneWayAnova',
        xVariableType: Variable.Type.CONTINUOUS,
        yVariableType: Variable.Type.CATEGORICAL,
        cardType: 'bivariate_summary',
        label: 'One-way ANOVA',
    },
    {
        // N-sample Mood - usual case
        property: 'nSampleMood',
        xVariableType: Variable.Type.CATEGORICAL,
        yVariableType: Variable.Type.CONTINUOUS,
        cardType: 'bivariate_summary',
        label: 'N-Sample Mood',
    },
    {
        // N-sample Mood - symmetric case
        property: 'nSampleMood',
        xVariableType: Variable.Type.CONTINUOUS,
        yVariableType: Variable.Type.CATEGORICAL,
        cardType: 'bivariate_summary',
        label: 'N-Sample Mood',
    },
    {
        // Kolmogorov-Smirnov - usual case
        property: 'kolmogorovSmirnov',
        xVariableType: Variable.Type.CATEGORICAL,
        yVariableType: Variable.Type.CONTINUOUS,
        cardType: 'bivariate_summary',
        label: 'Kolmogorov-Smirnov',
    },
    {
        // Kolmogorov-Smirnov - symmetric case
        property: 'kolmogorovSmirnov',
        xVariableType: Variable.Type.CONTINUOUS,
        yVariableType: Variable.Type.CATEGORICAL,
        cardType: 'bivariate_summary',
        label: 'Kolmogorov-Smirnov',
    },
];

// Determine if a stat is visible/configurable depending on card type & variable(s) type(s)
function isItemDisplayed(params: SupportedCards, item: StatItem): boolean {
    if (item.cardType !== params.type) {
        return false;
    }

    if (item.cardType === 'bivariate_summary' && params.type === 'bivariate_summary') {
        if (item.xVariableType && item.xVariableType !== params.xColumn.type) {
            return false;
        }
        if (item.yVariableType && item.yVariableType !== params.yColumn.type) {
            return false;
        }
    }

    if (item.cardType === 'univariate_summary' && params.type === 'univariate_summary') {
        if (item.variableType && item.variableType !== params.column.type) {
            return false;
        }
    }

    return true;
}

@Component({
    selector: 'generic-summary-stats-card-config',
    templateUrl: './generic-summary-stats-card-config.component.html',
    styleUrls: [
        '../../../../shared-styles/forms.less',
        './generic-summary-stats-card-config.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericSummaryStatsCardConfigComponent implements OnChanges {
    @Input() params: SupportedCards;
    @Output() paramsChange = new EventEmitter<SupportedCards>();

    availableStatItems: StatItem[];
    localParams: SupportedCards;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.localParams = this.params;
            this.availableStatItems = allStatItems
                .filter(statItem => isItemDisplayed(this.params, statItem));
        }
    }

    toggleStat(key: StatKey, flag: boolean) {
        this.localParams = produce(this.localParams, draft => {
            (draft.compute as any)[key] = flag;
        });
        this.paramsChange.emit(this.localParams);
    }
}
