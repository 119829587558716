import '@angular/compiler';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from '@app/app.module';
import { platformBrowser } from '@angular/platform-browser';
import { downgradeModule } from '@angular/upgrade/static';
import { StaticProvider } from '@angular/core';
import { downgradeComponents } from '@migration/downgraded-components';
import { downgradeProviders } from '@migration/downgraded-providers';
import { downgradeModules } from '@migration/downgraded-modules';
import { setAutoFreeze, enableMapSet, enablePatches } from 'immer';
import * as agGrid from 'ag-grid-enterprise';
import { fairAny } from 'dku-frontend-core';

declare let angular: any;

enableMapSet();
enablePatches();
if (environment.production) {
    enableProdMode();
    setAutoFreeze(false);
}

const bootstrapFn = (extraProviders: StaticProvider[]) => {
    return platformBrowser(extraProviders).bootstrapModule(AppModule);
};

angular.module('ng-upgrade', ['dataiku', downgradeModule(bootstrapFn)]);

downgradeProviders();
downgradeComponents();
downgradeModules();

angular.bootstrap(document, ['ng-upgrade'], {
    // strictDi: true means that angular rejects old-style dependancy declaration
    // (ie using only function arguments names, because they cannot be minified)
    // https://docs.angularjs.org/api/ng/directive/ngApp
    strictDi: false
});

// Share AgGrid library into window object to make it available inside webapps
(<fairAny>window).agGrid = agGrid;
agGrid.LicenseManager.setLicenseKey('CompanyName=Dataiku,LicensedApplication=Data Science Studio,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=50,LicensedProductionInstancesCount=-1,AssetReference=AG-024440,ExpiryDate=24_March_2023_[v2]_MTY3OTYxNjAwMDAwMA==8138ec579f82622476fa9cb2ef725469');
