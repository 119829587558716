import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input, OnChanges, SimpleChanges, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Card, Variable, CardResult, SplitBySpec } from 'src/generated-sources';
import { Observable, ReplaySubject } from 'rxjs';
import { CardActionType, CardAction } from '@features/eda/worksheet/cards/events';
import { CardBodyRenderingMode } from '@features/eda/worksheet/cards/body/rendering-mode';
import { fadeInOutHeight } from '@shared/animations/fade-in-out';
import { CollapsingService, CollapsibleTopLevelCard, UpdatableCollapsingService, CollapsibleHelp } from '@features/eda/collapsing.service';
import { map, switchMap } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DeleteDialogComponent } from '../../delete-dialog/delete-dialog.component';
import { CardHelpComponent } from '../card-help/card-help.component';
import { getCardSubtitle, getBasicCardTitle, noFilterIfAll, acceptsColoring } from '@features/eda/card-utils';
import { DOCUMENT } from '@angular/common';
import { SampleContextService } from '@features/eda/sample-context.service';
import { CdkDragHandle } from '@angular/cdk/drag-drop';

@Component({
    selector: 'top-level-card',
    templateUrl: './top-level-card.component.html',
    styleUrls: ['./top-level-card.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeInOutHeight]
})
export class TopLevelCardComponent implements OnChanges {
    readonly CardBodyRenderingMode = CardBodyRenderingMode;

    @Input() params: Card;
    @Input() results: CardResult;
    @Input() readOnly: boolean;
    @Input() extendedActions = true;
    @Input() hasFixedHeight: boolean;
    @Output() action = new EventEmitter<CardAction>();

    // Emit the element having the cdkDragHandle directive
    // so that it can be used by parent component
    @Output() remoteDragHandle = new EventEmitter<HTMLElement | undefined>(true);

    @ViewChild(CdkDragHandle)
    set emitRemoteDragHandle(v: CdkDragHandle | undefined) {
        this.remoteDragHandle.emit(v && v.element.nativeElement);
    }

    variables$: Observable<Variable[]>;
    helpIsAvailable$: Observable<boolean>;
    cardCollapseState$: Observable<{ collapsed: boolean }>;
    helpCollapseState$: Observable<{ collapsed: boolean }>;
    params$ = new ReplaySubject<Card>(1);

    showCollapsingControls: boolean;
    subtitle = '';

    scrollIntoViewOnResult: boolean;

    constructor(
        sampleContextService: SampleContextService,
        private dialog: MatDialog,
        private collapsingService: CollapsingService,
        @Inject(DOCUMENT) private document: Document,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.variables$ = sampleContextService.availableVariables();
        this.cardCollapseState$ = this.params$.pipe(
            map(params => new CollapsibleTopLevelCard(params.id)),
            switchMap(collapsible => collapsingService.watchIsCollapsed(collapsible)),
            map(collapsed => ({ collapsed }))
        );
        this.helpCollapseState$ = this.params$.pipe(
            map(params => new CollapsibleHelp(params.id)),
            switchMap(collapsible => collapsingService.watchIsCollapsed(collapsible)),
            map(collapsed => ({ collapsed }))
        );
        this.helpIsAvailable$ = this.params$.pipe(
            map(params => CardHelpComponent.isAvailableForCard(params))
        );
        this.showCollapsingControls = this.collapsingService instanceof UpdatableCollapsingService;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.params$.next(this.params);
            this.subtitle = getCardSubtitle(this.params);
        }
        if (changes.results) {
            if (this.scrollIntoViewOnResult) {
                const elem = this.document.getElementById(this.params.id);
                if (elem) {
                    // differ to let the updates be performed before scrolling
                    setTimeout(() => {
                        elem.scrollIntoView();
                        this.changeDetectorRef.markForCheck();
                        if (!CardResult.isUnavailableCardResult(this.results) || this.results.reason != "NOT_COMPUTED") {
                            this.scrollIntoViewOnResult = false;
                        }
                    }, 1);
                }
            }
        }
    }

    deleteCard() {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            data: {
                title: getBasicCardTitle(this.params),
                type: this.params.type
            },
            restoreFocus: false,
            panelClass: ['modal', 'modal3', 'dku-modal-panel-narrow']
        });

        dialogRef.afterClosed().subscribe((deleteCard: boolean) => {
            if (deleteCard) {
                this.action.emit({ type: CardActionType.DELETE });
            }
        });
    }

    get canUpdateSplitBySettings(): boolean {
        if (Card.isTimeSeriesCard(this.params) || Card.isPlaygroundCard(this.params)) {
            // It does not make sense to split time series cards according to
            // another dimension (at least for now).
            return false;
        }

        return acceptsColoring(this.params) || this.params.filter == null;
    }

    get allowGroupWithAll(): boolean {
        // Grouping with all values does not make sense for cards with "color by"
        // as all the data is already present.
        return !acceptsColoring(this.params);
    }

    get splitBySettings(): SplitBySpec | null | undefined {
        return acceptsColoring(this.params) ?
            this.params.colorBy :
            this.params.splitBy;
    }

    get acceptsColoring(): boolean {
        return acceptsColoring(this.params);
    }

    updateSplitBySettings(newSplitBy: SplitBySpec | null) {
        // At the moment, a card can only support either "split by" or "color by", not both.
        const field = acceptsColoring(this.params) ? "colorBy" : "splitBy";

        this.action.emit({
            type: CardActionType.UPDATE,
            newParams: {
                ...this.params,
                [field]: newSplitBy,
            },
        });
    }

    toggleCard(newIsCollapsed: boolean) {
        const collapsible = new CollapsibleTopLevelCard(this.params.id);
        this.collapsingService.setIsCollapsed(collapsible, newIsCollapsed);
    }

    toggleHelp(newIsCollapsed: boolean) {
        const collapsible = new CollapsibleHelp(this.params.id);
        this.collapsingService.setIsCollapsed(collapsible, newIsCollapsed);
    }

    handleAction(action: CardAction) {
        if (action.type === CardActionType.DEBUG || action.type === CardActionType.ADD) {
            let card = action.card;
            if (this.params.filter) {
                card = { ...card, filter: this.params.filter };
            }
            this.action.emit({ ...action, card });
        } else if (action.type === CardActionType.HIGHLIGHT) {
            let newFilter = action.filter;
            if (this.params.filter && action.filter) {
                // Merge highlight filter + card's filter
                newFilter = noFilterIfAll({
                    type: 'and',
                    filters: [this.params.filter, action.filter]
                });
            }
            this.action.emit({ ...action, filter: newFilter });
        } else {
            this.action.emit(action);
        }
    }
}
