import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { APIError } from "@core/dataiku-api/api-error";
import { EdaRecipeService } from "@features/eda/recipe/eda-recipe.service";
import { deepDistinctUntilChanged, observeInput } from "dku-frontend-core";
import { PCARecipePayloadParams, SchemaColumn, SerializedRecipe } from "generated-sources";
import _ from "lodash";
import { distinctUntilChanged, filter, map, Observable, switchMap } from "rxjs";


/**
 * Typing for the config form values.
 */
type ConfigFormValue = {
    columns: string[],
    forwardedColumns: string[],
    addComputationTimestamp: boolean
};

@Component({
    selector: 'pca-recipe-settings',
    templateUrl: './pca-recipe-settings.component.html',
    styleUrls: [
        '../../shared-styles/forms.less',
        './pca-recipe-settings.component.less',
    ],
    providers: [EdaRecipeService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PcaRecipeSettingsComponent implements OnInit {

    /**
     * The recipe project key.
     */
    @Input() projectKey: string;

    /**
     * The recipe input.
     */
    @Input() recipeInput: SerializedRecipe.RecipeInput;

    /**
     * The recipe projections output.
     */
    @Input() projectionsOutput: SerializedRecipe.RecipeOutput | undefined;

    /**
     * The recipe payload parameters.
     */
    @Input() payload: PCARecipePayloadParams;

    /**
     * Event emitter for forwarding the payload changes.
     */
    @Output() payloadChange = new EventEmitter<PCARecipePayloadParams>();

    /**
     * Event emitter for forwarding any api error.
     */
    @Output() apiErrorChange = new EventEmitter<APIError>();

    destroyRef = inject(DestroyRef);

    configForm: UntypedFormGroup;
    columns$: Observable<SchemaColumn[]>;

    // for graceful display in the template
    MAX_CONFLICTING_COLUMNS_LABEL_LENGTH = 100;

    projectionsOutput$: Observable<SerializedRecipe.RecipeOutput | undefined> = observeInput(this, 'projectionsOutput');

    private lastEmittedPayload: PCARecipePayloadParams | null = null;

    private recipeInput$: Observable<SerializedRecipe.RecipeInput> =
        observeInput(this, "recipeInput");

    private payload$: Observable<PCARecipePayloadParams> =
        observeInput(this, "payload").pipe(
            deepDistinctUntilChanged(),
            distinctUntilChanged((prev, curr) => _.isEqual(curr, this.lastEmittedPayload)),
        );

    constructor(
        fb: UntypedFormBuilder,
        private edaRecipeService: EdaRecipeService,
    ) {
        this.configForm = fb.group({
            columns: fb.control([], [
                Validators.required,
            ]),
            forwardedColumns: fb.control([]),
            addComputationTimestamp: fb.control(false, [
                Validators.required
            ])
        });
    }

    get selectedColumns(): string[] {
        return this.configForm.controls.columns.value;
    }

    get forwardedColumns(): string[] {
        return this.configForm.controls.forwardedColumns.value;
    }

    get conflictingForwardedColumns(): string[] {
        const reserved = new Set<string>();
        for (let i = 0; i < this.selectedColumns.length; i++) {
            reserved.add(`pc${i + 1}`);
        }

        if (this.configForm.controls.addComputationTimestamp.value === true) {
            reserved.add("computation_timestamp");
        }

        return this.forwardedColumns.filter(c => reserved.has(c));
    }

    ngOnInit(): void {
        this.edaRecipeService.getError().pipe(
            filter(err => err != null),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(err => {
            this.apiErrorChange.emit(err);
        });

        this.columns$ = this.recipeInput$.pipe(
            map(input => input.ref),
            distinctUntilChanged(),
            switchMap(datasetRef =>
                this.edaRecipeService.fetchInputColumns(datasetRef, this.projectKey)
            ),
        );

        this.configForm.valueChanges.pipe(
            deepDistinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((formValue: ConfigFormValue) => {
            this.emitUpdatedPayload(formValue);
        });

        this.payload$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(payload => {
            this.patchConfigForm(payload);
        });
    }

    private emitUpdatedPayload(formValue: ConfigFormValue): void {
        const newPayload: PCARecipePayloadParams = {
            ...this.payload,
            columns: formValue.columns,
            forwardedColumns: formValue.forwardedColumns,
            addComputationTimestamp: formValue.addComputationTimestamp
        };
        this.lastEmittedPayload = newPayload;
        this.payloadChange.emit(newPayload);
    }

    private patchConfigForm(payload: PCARecipePayloadParams): void {
        this.configForm.patchValue({
            columns: payload.columns,
            forwardedColumns: payload.forwardedColumns,
            addComputationTimestamp: payload.addComputationTimestamp
        });
    }
}
