import type { AvailableResult } from './../available-result';
import type { ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { STLDecompositionParams } from './../stldecomposition-params';
import type { TimeSeriesComputation } from './time-series-computation';
import type { _AvailableResult } from './../available-result';
import type { _ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _STLDecompositionParams } from './../stldecomposition-params';
import type { _TimeSeriesComputation } from './time-series-computation';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.STLDecomposition
 * Via: UIModel annotation in the class hierarchy
 */
export interface _STLDecomposition extends _TimeSeriesComputation {
    type: "stl_decomposition";
    params: STLDecompositionParams;
}

export type STLDecomposition = _STLDecomposition;

export namespace STLDecomposition {
    /** Type of class com.dataiku.dip.eda.compute.computations.timeseries.STLDecomposition in PolyJSON mappings */
    export const type = "stl_decomposition";
    
    /** 
     * Translated from enum com.dataiku.dip.eda.compute.computations.timeseries.STLDecomposition$DecompositionType
     * Via: com.dataiku.dip.eda.compute.computations.STLDecompositionParams
     */
    export enum DecompositionType {
        MULTIPLICATIVE = "MULTIPLICATIVE",
        ADDITIVE = "ADDITIVE",
    }
    /** 
     * Translated from enum com.dataiku.dip.eda.compute.computations.timeseries.STLDecomposition$DegreeMode
     * Via: com.dataiku.dip.eda.compute.computations.STLDecompositionParams
     */
    export enum DegreeMode {
        CONSTANT = "CONSTANT",
        CONSTANT_WITH_TREND = "CONSTANT_WITH_TREND",
    }
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.STLDecomposition$STLDecompositionResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _STLDecompositionResult extends _AvailableResult {
        type: "stl_decomposition";
        guessedParams: STLDecomposition.STLDecompositionResult.STLGuessedParams;
        observed: number[];
        resid: number[];
        seasonal: number[];
        time: string[];
        trend: number[];
    }
    
    export type STLDecompositionResult = _STLDecompositionResult;

    export namespace STLDecompositionResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.timeseries.STLDecomposition$STLDecompositionResult in PolyJSON mappings */
        export const type = "stl_decomposition";
        
        /** 
         * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.STLDecomposition$STLDecompositionResult$STLGuessedParams
         * Via: com.dataiku.dip.eda.worksheets.cards.STLDecompositionCard$STLDecompositionCardResult
         */
        export interface _STLGuessedParams extends _ImmutableValueObject {
            lowPassSmoother: number;
            period: number;
            trendSmoother: number;
        }
        
        export type STLGuessedParams = _STLGuessedParams;
    }
}