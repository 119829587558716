export namespace DatetimeCyclicalEncoder {
    /** 
     * Translated from enum com.dataiku.scoring.pipelines.DatetimeCyclicalEncoder$Period
     * Via: com.dataiku.dip.shaker.analysis.NumericalVariableAnalysis
     */
    export enum Period {
        MINUTE = "MINUTE",
        HOUR = "HOUR",
        DAY = "DAY",
        WEEK = "WEEK",
        MONTH = "MONTH",
        QUARTER = "QUARTER",
        YEAR = "YEAR",
    }
}