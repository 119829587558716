import type { Aggregation } from './../../backend/model/aggregation';
import type { ChartFilter } from './chart-filter';
import type { ChartType } from './chart-type';
import type { ChartVariant } from './chart-variant';
import type { DigitGrouping } from './digit-grouping';
import type { DimensionDef } from './dimension-def';
import type { MeasureDef } from './measure-def';
import type { Multiplier } from './multiplier';
import type { NADimensionDef } from './nadimension-def';
import type { NumberFormattingOptions } from './number-formatting-options';
import type { _ChartFilter } from './chart-filter';
import type { _DimensionDef } from './dimension-def';
import type { _MeasureDef } from './measure-def';
import type { _NADimensionDef } from './nadimension-def';
import type { _NumberFormattingOptions } from './number-formatting-options';

/** 
 * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ChartDef {
    animationDimension: DimensionDef[];
    animationFrameDuration: number;
    animationRepeat: boolean;
    axis1LogScale: boolean;
    axis2LogScale: boolean;
    boxplotBreakdownDim: DimensionDef[];
    boxplotValue: ChartDef.Unaggregated[];
    bubblesOptions: ChartDef.BubblesOptions;
    chartHeight: number;
    colorMeasure: MeasureDef[];
    colorOptions: ChartDef.ColorOptions;
    computeMode: ChartDef.ComputeMode;
    displayWithECharts?: boolean | null;
    displayWithEChartsByDefault?: boolean | null;
    facetDimension: DimensionDef[];
    fillOpacity: number;
    filters: ChartFilter[];
    genericDimension0: DimensionDef[];
    genericDimension1: DimensionDef[];
    genericMeasures: MeasureDef[];
    geoLayers: ChartDef.GeometryLayer[];
    geometry: ChartDef.Unaggregated[];
    groupDimension: DimensionDef[];
    hexbinNumber: number;
    hexbinRadius: number;
    hexbinRadiusMode: ChartDef.HexbinRadiusMode;
    includeZero: boolean;
    legendFormatting?: ChartDef.TextFormattingOptions | null;
    legendPlacement: ChartDef.LegendPlacement;
    mapGridOptions: ChartDef.MapGridOptions;
    mapOptions: ChartDef.MapOptions;
    multiTooltips: boolean;
    name: string;
    pieOptions: ChartDef.PieOptions;
    pivotTableOptions: ChartDef.PivotTableOptions;
    radarOptions: ChartDef.RadarOptions;
    radialAxisFormatting: ChartDef.AxisFormattingOptions;
    referenceLines: ChartDef.ReferenceLine[];
    scatterOptions: ChartDef.ScatterOptions;
    showInChartLabels: boolean;
    showInChartTotalValues: boolean;
    showInChartValues: boolean;
    showLegend: boolean;
    showXAxis: boolean;
    singleXAxis: boolean;
    sizeMeasure: MeasureDef[];
    smoothing: boolean;
    strokeWidth: number;
    thumbnailData: string;
    tooltipMeasures: MeasureDef[];
    type: ChartType;
    uaColor: ChartDef.Unaggregated[];
    uaShape: ChartDef.Unaggregated[];
    uaSize: ChartDef.Unaggregated[];
    uaTooltip: ChartDef.Unaggregated[];
    uaXDimension: NADimensionDef[];
    uaYDimension: NADimensionDef[];
    useLiveProcessingIfAvailable: boolean;
    userEditedName: boolean;
    valuesInChartDisplayOptions: ChartDef.ValuesInChartDisplayOptions;
    variant: ChartVariant;
    webAppConfig: { [key in string]: any/* com.google.gson.JsonElement */ };
    webAppType: string;
    xAxisFormatting: ChartDef.AxisFormattingOptions;
    xAxisMode: ChartDef.XAxisMode;
    xAxisNumberFormattingOptions: NumberFormattingOptions;
    xCustomExtent: ChartDef.CustomAxisExtent;
    xDimension: DimensionDef[];
    xMeasure: MeasureDef[];
    xTicks: ChartDef.AxisTicksConfig;
    yAxisFormatting: ChartDef.AxisFormattingOptions;
    yAxisMode: ChartDef.YAxisMode;
    yAxisNumberFormattingOptions: NumberFormattingOptions;
    yCustomExtent: ChartDef.CustomAxisExtent;
    yDimension: DimensionDef[];
    yMeasure: MeasureDef[];
    yTicks: ChartDef.AxisTicksConfig;
    zoomOptions: ChartDef.ZoomOptions;
}

export type ChartDef = _ChartDef;

export namespace ChartDef {
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$AxisFormattingOptions
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _AxisFormattingOptions {
        axisTitle?: string | null;
        axisTitleFormatting?: ChartDef.TextFormattingOptions | null;
        axisValuesFormatting: ChartDef.AxisValuesFormattingOptions;
        displayAxis?: boolean | null;
        showAxisTitle: boolean;
    }
    
    export type AxisFormattingOptions = _AxisFormattingOptions;
    
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$AxisTicksConfig
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _AxisTicksConfig {
        mode: ChartDef.AxisTicksConfig.AxisTicksConfigMode;
        number?: number | null;
    }
    
    export type AxisTicksConfig = _AxisTicksConfig;
    
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$AxisValuesFormattingOptions
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$AxisFormattingOptions
     */
    export interface _AxisValuesFormattingOptions {
        axisTicksFormatting: ChartDef.TextFormattingOptions;
        numberFormatting?: NumberFormattingOptions | null;
    }
    
    export type AxisValuesFormattingOptions = _AxisValuesFormattingOptions;
    
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$BubbleShape
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$BubblesOptions
     */
    export enum BubbleShape {
        EMPTY_CIRCLE = "EMPTY_CIRCLE",
        FILLED_CIRCLE = "FILLED_CIRCLE",
    }
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$BubblesOptions
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _BubblesOptions {
        defaultRadius: number;
        singleShape: ChartDef.BubbleShape;
    }
    
    export type BubblesOptions = _BubblesOptions;
    
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$ChartLineType
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$ReferenceLine
     */
    export enum ChartLineType {
        DASHED = "DASHED",
        FILLED = "FILLED",
    }
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _ColorOptions {
        ccScaleMode: ChartDef.ColorOptions.ContinuousColorScaleMode;
        colorPalette: string;
        customColors: { [key in string]: string };
        customPalette: ChartDef.ColorOptions.Palette;
        heatDensityMapIntensity: number;
        heatDensityMapRadius: number;
        numQuantizeSteps: number;
        paletteMiddleValue: number;
        paletteType: ChartDef.ColorOptions.PaletteType;
        quantizationMode: ChartDef.ColorOptions.QuantizationMode;
        singleColor: string;
        transparency: number;
    }
    
    export type ColorOptions = _ColorOptions;
    
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$ComputeMode
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export enum ComputeMode {
        NORMAL = "NORMAL",
        INDICE_100 = "INDICE_100",
        CUMULATIVE = "CUMULATIVE",
        DIFFERENCE = "DIFFERENCE",
        LOG_SCALE = "LOG_SCALE",
        PERCENTAGE = "PERCENTAGE",
        AVG_RATIO = "AVG_RATIO",
    }
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$CustomAxisExtent
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _CustomAxisExtent {
        editMode: ChartDef.ExtentEditMode;
        manualExtent: number[];
    }
    
    export type CustomAxisExtent = _CustomAxisExtent;
    
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$ExtentEditMode
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$CustomAxisExtent
     */
    export enum ExtentEditMode {
        AUTO = "AUTO",
        MANUAL = "MANUAL",
    }
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$Geometry
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$GeometryLayer
     */
    export interface _Geometry extends ChartDef._Unaggregated {
        aggregationFunction?: Aggregation.Function | null;
    }
    
    export type Geometry = _Geometry;
    
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$GeometryLayer
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _GeometryLayer {
        colorOptions: ChartDef.ColorOptions;
        geometry: ChartDef.Geometry[];
        uaColor: ChartDef.Unaggregated[];
    }
    
    export type GeometryLayer = _GeometryLayer;
    
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$HexbinRadiusMode
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export enum HexbinRadiusMode {
        ABSOLUTE = "ABSOLUTE",
        NUM_HEXAGONS = "NUM_HEXAGONS",
    }
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$LegendPlacement
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export enum LegendPlacement {
        SIDEBAR = "SIDEBAR",
        INNER_TOP_RIGHT = "INNER_TOP_RIGHT",
        INNER_TOP_LEFT = "INNER_TOP_LEFT",
        INNER_BOTTOM_RIGHT = "INNER_BOTTOM_RIGHT",
        INNER_BOTTOM_LEFT = "INNER_BOTTOM_LEFT",
        OUTER_TOP = "OUTER_TOP",
        OUTER_RIGHT = "OUTER_RIGHT",
        OUTER_BOTTOM = "OUTER_BOTTOM",
        OUTER_LEFT = "OUTER_LEFT",
    }
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$LineStyleTypes
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$RadarOptions$LineStyle
     */
    export enum LineStyleTypes {
        SOLID = "SOLID",
        DASHED = "DASHED",
        DOTTED = "DOTTED",
    }
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$MapGridOptions
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _MapGridOptions {
        gridLatDeg: number;
        gridLonDeg: number;
    }
    
    export type MapGridOptions = _MapGridOptions;
    
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$MapOptions
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _MapOptions {
        lockSquareGrid: boolean;
        tilesLayer: string;
    }
    
    export type MapOptions = _MapOptions;
    
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$PieOptions
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _PieOptions {
        donutHoleSize: number;
    }
    
    export type PieOptions = _PieOptions;
    
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableDisplayGrandTotals
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableDisplayTotals
     */
    export interface _PivotTableDisplayGrandTotals {
        column: boolean;
        row: boolean;
    }
    
    export type PivotTableDisplayGrandTotals = _PivotTableDisplayGrandTotals;
    
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableDisplaySubTotals
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableDisplayTotals
     */
    export interface _PivotTableDisplaySubTotals {
        columns: boolean;
        rows: boolean;
    }
    
    export type PivotTableDisplaySubTotals = _PivotTableDisplaySubTotals;
    
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableDisplayTotals
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableOptions
     */
    export interface _PivotTableDisplayTotals {
        grandTotal: ChartDef.PivotTableDisplayGrandTotals;
        subTotals: ChartDef.PivotTableDisplaySubTotals;
    }
    
    export type PivotTableDisplayTotals = _PivotTableDisplayTotals;
    
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableFormatting
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableOptions
     */
    export interface _PivotTableFormatting {
        columnHeaders: ChartDef.TextFormattingOptions;
        rowHeaders: ChartDef.TextFormattingOptions;
        values: ChartDef.TextFormattingOptions;
    }
    
    export type PivotTableFormatting = _PivotTableFormatting;
    
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableMeasureDisplayMode
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableOptions
     */
    export enum PivotTableMeasureDisplayMode {
        ROWS = "ROWS",
        COLUMNS = "COLUMNS",
    }
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableOptions
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _PivotTableOptions {
        displayTotals: ChartDef.PivotTableDisplayTotals;
        measureDisplayMode: ChartDef.PivotTableMeasureDisplayMode;
        tableFormatting: ChartDef.PivotTableFormatting;
    }
    
    export type PivotTableOptions = _PivotTableOptions;
    
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$PolygonSources
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$RadarOptions
     */
    export enum PolygonSources {
        MEASURES = "MEASURES",
        DIMENSIONS = "DIMENSIONS",
    }
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$RadarOptions
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _RadarOptions {
        filled: boolean;
        lineStyle: ChartDef.RadarOptions.LineStyle;
        polygonsSource: ChartDef.PolygonSources;
    }
    
    export type RadarOptions = _RadarOptions;
    
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$ReferenceLine
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _ReferenceLine {
        axis: ChartDef.ReferenceLineAxis;
        displayValue: boolean;
        labelPosition: ChartDef.ReferenceLinePosition;
        lineColor: string;
        lineSize: number;
        lineType: ChartDef.ChartLineType;
        multiplier: ChartDef.ReferenceLineMultiplier;
        prefix: string;
        suffix: string;
        value: number;
        valueFormatting: ChartDef.TextFormattingOptions;
    }
    
    export type ReferenceLine = _ReferenceLine;
    
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$ReferenceLineAxis
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$ReferenceLine
     */
    export enum ReferenceLineAxis {
        LEFT_Y_AXIS = "LEFT_Y_AXIS",
        RIGHT_Y_AXIS = "RIGHT_Y_AXIS",
        X_AXIS = "X_AXIS",
    }
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$ReferenceLineMultiplier
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$ReferenceLine
     */
    export enum ReferenceLineMultiplier {
        Inherit = "Inherit",
        Auto = "Auto",
        None = "None",
        Thousands = "Thousands",
        Millions = "Millions",
        Billions = "Billions",
    }
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$ReferenceLinePosition
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$ReferenceLine
     */
    export enum ReferenceLinePosition {
        INSIDE_START_TOP = "INSIDE_START_TOP",
        INSIDE_END_TOP = "INSIDE_END_TOP",
        INSIDE_START_BOTTOM = "INSIDE_START_BOTTOM",
        INSIDE_END_BOTTOM = "INSIDE_END_BOTTOM",
    }
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$Regression
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$ScatterOptions
     */
    export interface _Regression {
        displayFormula: boolean;
        labelPosition: ChartDef.RegressionLinePosition;
        lineColor: string;
        lineSize: number;
        show: boolean;
        textFormatting: ChartDef.TextFormattingOptions;
        type: ChartDef.RegressionType;
    }
    
    export type Regression = _Regression;
    
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$RegressionLinePosition
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$Regression
     */
    export enum RegressionLinePosition {
        INSIDE_START = "INSIDE_START",
        INSIDE_END = "INSIDE_END",
    }
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$RegressionType
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$Regression
     */
    export enum RegressionType {
        LINEAR = "LINEAR",
        POLYNOMIAL = "POLYNOMIAL",
        EXPONENTIAL = "EXPONENTIAL",
        LOGARITHMIC = "LOGARITHMIC",
    }
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$ScatterOptions
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _ScatterOptions {
        equalScales: boolean;
        identityLine: boolean;
        numberOfRecords: number;
        regression: ChartDef.Regression;
    }
    
    export type ScatterOptions = _ScatterOptions;
    
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$TextFormattingOptions
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _TextFormattingOptions {
        backgroundColor?: string | null;
        fontColor?: string | null;
        fontSize: number;
        hasBackground?: boolean | null;
    }
    
    export type TextFormattingOptions = _TextFormattingOptions;
    
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$Unaggregated
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _Unaggregated {
        adminLevel: number;
        column: string;
        dateMode: string;
        decimalPlaces?: number | null;
        digitGrouping: DigitGrouping;
        displayLabel?: string | null;
        isA: string;
        multiplier: Multiplier;
        prefix: string;
        suffix: string;
        treatAsAlphanum: boolean;
        type: string;
    }
    
    /**
        Translate from class com.dataiku.dip.pivot.frontend.model.ChartDef$Unaggregated
        @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
    */
    export type Unaggregated = _Unaggregated;
    
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$ValuesInChartDisplayOptions
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _ValuesInChartDisplayOptions {
        overlappingStrategy?: ChartDef.ValuesInChartOverlappingStrategy | null;
        textFormatting: ChartDef.TextFormattingOptions;
    }
    
    export type ValuesInChartDisplayOptions = _ValuesInChartDisplayOptions;
    
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$ValuesInChartOverlappingStrategy
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$ValuesInChartDisplayOptions
     */
    export enum ValuesInChartOverlappingStrategy {
        AUTO = "AUTO",
        ALL = "ALL",
    }
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$XAxisMode
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export enum XAxisMode {
        NORMAL = "NORMAL",
        LOG = "LOG",
        PERCENTAGE_STACK = "PERCENTAGE_STACK",
    }
    /** 
     * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$YAxisMode
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export enum YAxisMode {
        NORMAL = "NORMAL",
        CUMULATIVE = "CUMULATIVE",
        DIFFERENCE = "DIFFERENCE",
    }
    /** 
     * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$ZoomOptions
     * Via: com.dataiku.dip.pivot.frontend.model.ChartDef
     */
    export interface _ZoomOptions {
        enabled: boolean;
        persisted: boolean;
        scale: number;
        translate: number[];
        xRange?: number[] | null;
        yRange?: number[] | null;
    }
    
    export type ZoomOptions = _ZoomOptions;

    export namespace AxisTicksConfig {
        /** 
         * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$AxisTicksConfig$AxisTicksConfigMode
         * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$AxisTicksConfig
         */
        export enum AxisTicksConfigMode {
            NUMBER = "NUMBER",
            INTERVAL = "INTERVAL",
        }
    }

    export namespace ColorOptions {
        /** 
         * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions$ContinuousColorScaleMode
         * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions
         */
        export enum ContinuousColorScaleMode {
            NORMAL = "NORMAL",
            LOG = "LOG",
            SQRT = "SQRT",
            SQUARE = "SQUARE",
        }
        /** 
         * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions$Palette
         * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions
         */
        export interface _Palette {
            colors: string[];
            fixedValues: boolean;
            id: string;
            name: string;
            values: number[];
        }
        
        export type Palette = _Palette;
        
        /** 
         * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions$PaletteType
         * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions
         */
        export enum PaletteType {
            CONTINUOUS = "CONTINUOUS",
            DIVERGING = "DIVERGING",
        }
        /** 
         * Translated from enum com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions$QuantizationMode
         * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions
         */
        export enum QuantizationMode {
            NONE = "NONE",
            LINEAR = "LINEAR",
            QUANTILES = "QUANTILES",
        }
    }

    export namespace RadarOptions {
        /** 
         * Translated from class com.dataiku.dip.pivot.frontend.model.ChartDef$RadarOptions$LineStyle
         * Via: com.dataiku.dip.pivot.frontend.model.ChartDef$RadarOptions
         */
        export interface _LineStyle {
            type: ChartDef.LineStyleTypes;
            width: number;
        }
        
        export type LineStyle = _LineStyle;
    }
}