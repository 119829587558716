import { ChangeDetectionStrategy, Component } from '@angular/core';
import { APIError, ErrorContext } from '@core/dataiku-api/api-error';
import { DataCollectionsService } from '../../services/data-collections.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { BasePermissions, ITaggingService, UIDataCollection, UsersService } from 'src/generated-sources';
import { buildDefaultCreationInfo } from '@features/data-collections/utils';
import { ROLE_LIST } from '../../models';
import { Role } from '@shared/models';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';

@Component({
    selector: 'lib-create-data-collection-modal',
    templateUrl: './create-data-collection-modal.component.html',
    styleUrls: ['./create-data-collection-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateDataCollectionModalComponent implements ErrorContext {
    error$ = new BehaviorSubject<APIError | undefined>(undefined);
    creationInfo: UIDataCollection.CreationInfo;
    canNext: boolean = false;
    currentTabIndex: number = 0;
    objectType = ITaggingService.TaggableType.DATA_COLLECTION;
    roleList: Role[] = ROLE_LIST;
    users$ = this.dataikuAPI.security.listUsers() as Observable<UsersService.UIUser[]>;
    groups$ = this.dataikuAPI.security.listGroups(false).pipe(map(groups => ['$$ALL_USERS$$', ...groups]));

    constructor(
        private dialogRef: MatDialogRef<CreateDataCollectionModalComponent, string>,
        private dataCollectionsService: DataCollectionsService,
        private dataikuAPI: DataikuAPIService
    ) {
        this.creationInfo = buildDefaultCreationInfo();
    }

    pushError(error?: APIError) {
        this.error$.next(error);
    }

    resetError() {
        this.pushError();
    }

    onPermissionsChange(changes: { permissions: BasePermissions.PermissionItem[] }) {
        this.creationInfo.permissions = changes.permissions;
    }

    onDataCollectionChange(): void {
        this.canNext = !!this.creationInfo.displayName && !!this.creationInfo.color;
    }

    onColorChange(color: string): void {
        this.creationInfo.color = color;
        this.onDataCollectionChange();
    }

    previousTab(): void {
        this.currentTabIndex--;
    }

    nextTab(): void {
        this.currentTabIndex++;
    }

    confirm(): void {
        this.dataCollectionsService.create(this.creationInfo).subscribe({
            next: (id: string) => this.dialogRef.close(id),
            error: (error: APIError) => this.pushError(error)
        });
    }

    cancel(): void {
        this.dialogRef.close();
    }

}
