import { AxisDef } from '@model-main/pivot/backend/model/axis-def';
import { AxisSortPrune } from '@model-main/pivot/backend/model/axis-sort-prune';
import { PivotTableResponse } from '@model-main/pivot/backend/model/pivot-table-response';
import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { SamplingParam } from '@model-main/datasets/sampling-param';

export const pivotTableTensorResponseWithColors: PivotTableTensorResponse = {
    axisLabels: [
        [
            {
                label: '___dku_no_value___'
            },
            {
                label: 'M'
            },
            {
                label: 'F'
            }
        ],
        [
            {
                label: 'Tennis Shirt'
            },
            {
                label: 'Hoodie'
            },
            {
                label: 'White T-Shirt M'
            },
            {
                label: 'Black T-Shirt M'
            },
            {
                label: 'Black T-Shirt F'
            },
            {
                label: 'White T-Shirt F'
            }
        ]
    ] as Array<any>,
    axisDefs: [
        {
            column: 'gender',
            type: AxisDef.Type.ALPHANUM,
            sortPrune: {
                sortType: AxisSortPrune.SortType.AGGREGATION,
                sortAscending: false,
                aggregationSortId: 0,
                generateOthersCategory: true,
                forceLastPositionOthers: true,
                filters: [],
                maxValues: 20
            }
        },
        {
            column: 'tshirt_category',
            type: AxisDef.Type.ALPHANUM,
            sortPrune: {
                sortType: AxisSortPrune.SortType.AGGREGATION,
                sortAscending: false,
                aggregationSortId: 0,
                generateOthersCategory: true,
                forceLastPositionOthers: true,
                filters: [],
                maxValues: 20
            }
        }
    ] as Array<any>,
    sequenceId: 0,
    aggregations: [
        {
            numAxes: 2,
            axisLengths: [
                4,
                7
            ],
            tensor: [
                80.09302325581395,
                80.13759213759214,
                69.84573002754821,
                0,
                66.62553191489361,
                60.46178600160901,
                58.626097867001256,
                0,
                63.53846153846154,
                53.395953757225435,
                53.42635658914729,
                0,
                53.124378109452735,
                50.32786885245902,
                46,
                0,
                45.208333333333336,
                45.1171875,
                48.18889970788705,
                0,
                44.31543624161074,
                43,
                45.26720647773279,
                0,
                0,
                0,
                0,
                0
            ],
            axes: [
                [
                    58.773549000951476,
                    56.43458781362007,
                    51.54113712374582,
                    0
                ],
                [
                    75.76869158878505,
                    60.45538461538462,
                    54.5565749235474,
                    49.62654668166479,
                    47.6,
                    44.962017026850035,
                    0
                ]
            ],
            multipliers: [
                1,
                4
            ]
        }
    ] as Array<any>,
    counts: {
        numAxes: 2,
        axisLengths: [
            4,
            7
        ],
        tensor: [
            86,
            407,
            363,
            0,
            235,
            1243,
            797,
            0,
            260,
            1384,
            645,
            0,
            201,
            1159,
            418,
            0,
            120,
            128,
            1027,
            0,
            149,
            143,
            1235,
            0,
            0,
            0,
            0,
            0
        ],
        axes: [
            [
                1051,
                4464,
                4485,
                0
            ],
            [
                856,
                2275,
                2289,
                1778,
                1275,
                1527,
                0
            ]
        ],
        multipliers: [
            1,
            4
        ]
    },
    filterFacets: [],
    beforeFilterRecords: 10000,
    afterFilterRecords: 10000,
    engine: PivotTableResponse.PivotEngine.LINO,
    sampleMetadata: {
        samplingMethod: SamplingParam.SamplingMethod.HEAD_SEQUENTIAL,
        maxRecords: 10000,
        targetRatio: 0.02,
        hasFilter: false,
        partitionCount: 0,
        sampleIsWholeDataset: false,
        sampleRecordCount: -1,
        datasetRecordCount: -1,
        recordCountIsApproximate: false,
        recordCountIsObsolete: false,
        memoryLimitInMB: 150,
        memoryLimitReached: false
    },
    columnsSummary: undefined as any
};
