import type { DashboardPage } from './../dashboard-page';
import type { Insight } from './../insight';
import type { _DashboardPage } from './../dashboard-page';
import type { _Insight } from './../insight';

/** 
 * Translated from class com.dataiku.dip.dashboards.model.ui.EnrichedDashboardPage
 * Via: UIModel annotation in the class hierarchy
 */
export interface _EnrichedDashboardPage extends _DashboardPage {
    insightAccessData: { [key in string]: EnrichedDashboardPage.InsightAccessState };
    insightsData: { [key in string]: Insight };
}

export type EnrichedDashboardPage = _EnrichedDashboardPage;

export namespace EnrichedDashboardPage {
    /** 
     * Translated from enum com.dataiku.dip.dashboards.model.ui.EnrichedDashboardPage$InsightAccessState
     * Via: com.dataiku.dip.dashboards.model.ui.EnrichedDashboardPage
     */
    export enum InsightAccessState {
        DELETED = "DELETED",
        NOT_EXPOSED = "NOT_EXPOSED",
        ANALYST = "ANALYST",
        READER = "READER",
    }
}