import { Inject, Injectable } from '@angular/core';
import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { PTScatterResponse } from '@model-main/pivot/backend/model/scatter/ptscatter-response';
import { ScatterAxesDef } from '../interfaces';
import { ChartScatterDataWrapper, ChartTensorDataWrapper } from '../models';
import { ChartFormattingService } from './formatting';

@Injectable({
    providedIn: 'root'
})
export class ChartDataWrapperFactoryService {
    constructor(
        private chartFormattingService: ChartFormattingService,
        @Inject('ChartColorUtils') private chartColorUtilsService: any,
        @Inject('ChartDataUtils') private chartDataUtilsService: any
    ) {
    }

    public chartScatterDataWrapper(data: PTScatterResponse, axesDef: ScatterAxesDef) {
        return new ChartScatterDataWrapper(data, axesDef, this.chartColorUtilsService, this.chartFormattingService, this.chartDataUtilsService);
    }

    public chartTensorDataWrapper(data: PivotTableTensorResponse, axesDef: Record<string, number>) {
        return new ChartTensorDataWrapper(data, axesDef);
    }
}
