import type { Abstract2SampTestCard } from './common/abstract2-samp-test-card';
import type { EmpiricalCDF } from './../../compute/computations/univariate/empirical-cdf';
import type { _Abstract2SampTestCard } from './common/abstract2-samp-test-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.KsTest2SampCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _KsTest2SampCard extends _Abstract2SampTestCard {
    type: "ks_test_2samp";
}

export type KsTest2SampCard = _KsTest2SampCard;

export namespace KsTest2SampCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.KsTest2SampCard in PolyJSON mappings */
    export const type = "ks_test_2samp";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.KsTest2SampCard$KsTest2SampCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _KsTest2SampCardResult extends Abstract2SampTestCard._Abstract2SampTestCardResult {
        type: "ks_test_2samp";
        cdf1: EmpiricalCDF.EmpiricalCDFResult;
        cdf2: EmpiricalCDF.EmpiricalCDFResult;
        pvalue: number;
        statistic: number;
    }
    
    export type KsTest2SampCardResult = _KsTest2SampCardResult;

    export namespace KsTest2SampCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.KsTest2SampCard$KsTest2SampCardResult in PolyJSON mappings */
        export const type = "ks_test_2samp";
    }
}