import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { ReactiveInputModule } from '../reactive-input';
import { TextFormattingFormModule } from '../chart-forms';
import { ValuesDisplayFormComponent } from './values-display-form.component';


@NgModule({
    declarations: [
        ValuesDisplayFormComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        NgSelectModule,
        ReactiveFormsModule,
        ReactiveInputModule,
        TextFormattingFormModule
    ],
    exports: [
        ValuesDisplayFormComponent
    ]
})

export class ValuesDisplayFormModule { }
