import type { AzureMLResource } from './azure-mlresource';
import type { _AzureMLResource } from './azure-mlresource';

/** 
 * Translated from class com.dataiku.dip.externalinfras.azureml.datamodel.AzureMLOnlineDeployment
 * Via: com.dataiku.dip.externalinfras.azureml.AzureMLUtils$DSSAzureMLConsolidatedEndpointInfo
 */
export interface _AzureMLOnlineDeployment extends _AzureMLResource {
    appInsightsEnabled: boolean;
    egressPublicNetworkAccess: string;
    endpointName: string;
    environment: string;
    environmentVariables: { [key in string]: string };
    instanceCount: number;
    instanceType: string;
    livenessProbe: AzureMLOnlineDeployment.ProbeSettings;
    modelURI: string;
    provisioningState: AzureMLOnlineDeployment.DeploymentProvisioningState;
    readinessProbe: AzureMLOnlineDeployment.ProbeSettings;
}

export type AzureMLOnlineDeployment = _AzureMLOnlineDeployment;

export namespace AzureMLOnlineDeployment {
    /** 
     * Translated from enum com.dataiku.dip.externalinfras.azureml.datamodel.AzureMLOnlineDeployment$DeploymentProvisioningState
     * Via: com.dataiku.dip.externalinfras.azureml.datamodel.AzureMLOnlineDeployment
     */
    export enum DeploymentProvisioningState {
        CREATING = "CREATING",
        DELETING = "DELETING",
        SCALING = "SCALING",
        UPDATING = "UPDATING",
        SUCCEEDED = "SUCCEEDED",
        FAILED = "FAILED",
        CANCELED = "CANCELED",
    }
    /** 
     * Translated from class com.dataiku.dip.externalinfras.azureml.datamodel.AzureMLOnlineDeployment$ProbeSettings
     * Via: com.dataiku.dip.externalinfras.azureml.datamodel.AzureMLOnlineDeployment
     */
    export interface _ProbeSettings {
        failureThreshold: number;
        initialDelay: number;
        overrideProbeSettings: boolean;
        period: number;
        successThreshold: number;
        timeout: number;
    }
    
    export type ProbeSettings = _ProbeSettings;
}