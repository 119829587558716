import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { InsightSummary } from '@shared/models';

@Injectable({providedIn: 'root'})
export class PrivateWebappsAPI {
    constructor(private dkuHttp: DkuHttpService) {
    }

    getSummary(projectKey: string, webAppId: string): Observable<InsightSummary> {
        return this.dkuHttp.request('GET', '/webapps/get-summary', { projectKey, webAppId }, false);
    }
}
