import type { Curve } from './curve';
import type { _Curve } from './curve';

/** 
 * Translated from class com.dataiku.dip.eda.compute.curves.IsotonicCurve
 * Via: UIModel annotation in the class hierarchy
 */
export interface _IsotonicCurve extends _Curve {
    type: "isotonic";
}

export type IsotonicCurve = _IsotonicCurve;

export namespace IsotonicCurve {
    /** Type of class com.dataiku.dip.eda.compute.curves.IsotonicCurve in PolyJSON mappings */
    export const type = "isotonic";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.curves.IsotonicCurve$ParametrizedIsotonicCurve
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ParametrizedIsotonicCurve extends Curve._ParametrizedCurve {
        type: "isotonic";
    }
    
    export type ParametrizedIsotonicCurve = _ParametrizedIsotonicCurve;

    export namespace ParametrizedIsotonicCurve {
        /** Type of class com.dataiku.dip.eda.compute.curves.IsotonicCurve$ParametrizedIsotonicCurve in PolyJSON mappings */
        export const type = "isotonic";
    }
}