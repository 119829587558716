export namespace InterestsInternalDB {
    /** 
     * Translated from class com.dataiku.dip.dao.InterestsInternalDB$Interest
     * Via: com.dataiku.dip.datacatalog.UIDataCatalog$DatasetDetails
     */
    export interface _Interest {
        nbStarred: number;
        nbWatching: number;
        starred: boolean;
        watching: InterestsInternalDB.Watching;
    }
    
    /**
        Translate from class com.dataiku.dip.dao.InterestsInternalDB$Interest
        @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
    */
    export type Interest = _Interest;
    
    /** 
     * Translated from enum com.dataiku.dip.dao.InterestsInternalDB$Watching
     * Via: com.dataiku.dip.dao.InterestsInternalDB$Interest
     */
    export enum Watching {
        INO = "INO",
        ENO = "ENO",
        SHALLOW = "SHALLOW",
        YES = "YES",
    }
}