import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { WorkspacesError, WorkspaceSummary } from '@features/workspaces/shared';
import { Workspace  } from '@model-main/workspaces/workspace';
import { Observable } from 'rxjs';
import { getLoadingUrl } from './workspace-datastory-loading-page'

export type WorkspaceDatastoryCreationData = {
    summary: WorkspaceSummary,
    window: Window | null
}

interface WorkspaceDatastoryCreationModalOptions {
    workspace: Workspace;
    submit: (workspaceObject: Workspace.WorkspaceObject) => Observable<WorkspaceSummary>;
    error: WorkspacesError;
}

@Component({
    selector: 'workspace-datastory-creation-modal',
    templateUrl: './workspace-datastory-creation-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceDatastoryCreationModalComponent {
    datastory: Workspace.Story | null;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: WorkspaceDatastoryCreationModalOptions,
        private dialogRef: MatDialogRef<WorkspaceDatastoryCreationModalComponent, WorkspaceDatastoryCreationData | null>,
    ) {
        this.dialogRef.beforeClosed().subscribe(() => this.data.error.resetError());
    }

    confirm(): void {
        const newTabWindow = window.open(URL.createObjectURL(new Blob([getLoadingUrl(window.location.origin)], { type: "text/html" })), '_blank');
        // Subcribe to error because the submit's observable never returns an error.
        // In order to close the new tab if an error occurred.
        const errorSubscription = this.data.error.error$.subscribe((error) => {
            if (error) {
                newTabWindow?.close();
            }
        });
        this.data.submit(this.createWorkspaceObject()).subscribe(summary => {
            errorSubscription?.unsubscribe();
            if (summary) {
                this.dialogRef.close({summary, window: newTabWindow});
            }
        });
    }

    cancel(): void {
        this.dialogRef.close(null);
    }

    updateDatastory(newDatastory: Workspace.Story | null): void {
        this.datastory = newDatastory;
    }

    createWorkspaceObject(): Workspace.WorkspaceObject {
        return { story: this.datastory } as Workspace.WorkspaceObject;
    }
}
