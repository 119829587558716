export namespace PreTrainPredictionModelingParams {
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.prediction.PreTrainPredictionModelingParams$Algorithm
     * Via: com.dataiku.dip.analysis.model.prediction.PredictionModelSnippetData
     */
    export enum Algorithm {
        PYTHON_ENSEMBLE = "PYTHON_ENSEMBLE",
        SPARK_ENSEMBLE = "SPARK_ENSEMBLE",
        RANDOM_FOREST_CLASSIFICATION = "RANDOM_FOREST_CLASSIFICATION",
        EXTRA_TREES = "EXTRA_TREES",
        CUSTOM_PLUGIN = "CUSTOM_PLUGIN",
        SCIKIT_MODEL = "SCIKIT_MODEL",
        RANDOM_FOREST_REGRESSION = "RANDOM_FOREST_REGRESSION",
        RIDGE_REGRESSION = "RIDGE_REGRESSION",
        LASSO_REGRESSION = "LASSO_REGRESSION",
        LEASTSQUARE_REGRESSION = "LEASTSQUARE_REGRESSION",
        LOGISTIC_REGRESSION = "LOGISTIC_REGRESSION",
        SVC_CLASSIFICATION = "SVC_CLASSIFICATION",
        SVM_REGRESSION = "SVM_REGRESSION",
        SGD_CLASSIFICATION = "SGD_CLASSIFICATION",
        SGD_REGRESSION = "SGD_REGRESSION",
        GBT_CLASSIFICATION = "GBT_CLASSIFICATION",
        GBT_REGRESSION = "GBT_REGRESSION",
        DECISION_TREE_CLASSIFICATION = "DECISION_TREE_CLASSIFICATION",
        DECISION_TREE_REGRESSION = "DECISION_TREE_REGRESSION",
        KNN = "KNN",
        NEURAL_NETWORK = "NEURAL_NETWORK",
        LARS = "LARS",
        LIGHTGBM_CLASSIFICATION = "LIGHTGBM_CLASSIFICATION",
        LIGHTGBM_REGRESSION = "LIGHTGBM_REGRESSION",
        XGBOOST_CLASSIFICATION = "XGBOOST_CLASSIFICATION",
        XGBOOST_REGRESSION = "XGBOOST_REGRESSION",
        DEEP_NEURAL_NETWORK_CLASSIFICATION = "DEEP_NEURAL_NETWORK_CLASSIFICATION",
        DEEP_NEURAL_NETWORK_REGRESSION = "DEEP_NEURAL_NETWORK_REGRESSION",
        KERAS_CODE = "KERAS_CODE",
        SPARKLING_DEEP_LEARNING = "SPARKLING_DEEP_LEARNING",
        SPARKLING_GBM = "SPARKLING_GBM",
        SPARKLING_RF = "SPARKLING_RF",
        SPARKLING_GLM = "SPARKLING_GLM",
        SPARKLING_NB = "SPARKLING_NB",
        MLLIB_LOGISTIC_REGRESSION = "MLLIB_LOGISTIC_REGRESSION",
        MLLIB_DECISION_TREE = "MLLIB_DECISION_TREE",
        MLLIB_LINEAR_REGRESSION = "MLLIB_LINEAR_REGRESSION",
        MLLIB_RANDOM_FOREST = "MLLIB_RANDOM_FOREST",
        MLLIB_NAIVE_BAYES = "MLLIB_NAIVE_BAYES",
        MLLIB_GBT = "MLLIB_GBT",
        MLLIB_CUSTOM = "MLLIB_CUSTOM",
        VERTICA_LINEAR_REGRESSION = "VERTICA_LINEAR_REGRESSION",
        VERTICA_LOGISTIC_REGRESSION = "VERTICA_LOGISTIC_REGRESSION",
        VIRTUAL_MLFLOW_PYFUNC = "VIRTUAL_MLFLOW_PYFUNC",
        VIRTUAL_PROXY_MODEL = "VIRTUAL_PROXY_MODEL",
        TRIVIAL_IDENTITY_TIMESERIES = "TRIVIAL_IDENTITY_TIMESERIES",
        SEASONAL_NAIVE = "SEASONAL_NAIVE",
        AUTO_ARIMA = "AUTO_ARIMA",
        SEASONAL_LOESS = "SEASONAL_LOESS",
        PROPHET = "PROPHET",
        GLUONTS_NPTS_FORECASTER = "GLUONTS_NPTS_FORECASTER",
        GLUONTS_SIMPLE_FEEDFORWARD = "GLUONTS_SIMPLE_FEEDFORWARD",
        GLUONTS_DEEPAR = "GLUONTS_DEEPAR",
        GLUONTS_TRANSFORMER = "GLUONTS_TRANSFORMER",
        GLUONTS_MQCNN = "GLUONTS_MQCNN",
        CAUSAL_FOREST = "CAUSAL_FOREST",
    }
}