import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

/**
 * Button component that open a searchable select input
 */
@Component({
    selector: 'select-button',
    templateUrl: './select-button.component.html',
    styleUrls: ['./select-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectButtonComponent {

    filter = '';

    @Input() selection: Array<any>;
    @Input() options: Array<any>;
    @Input() searchPlaceholder?: string;
    @Input() searchNoEntry?: string;
    @Input() valueKey?: string;
    @Input() labelKey?: string;

    @Output() selectionChange = new EventEmitter();

    @ContentChild('optionTemplate') optionTemplateRef?:TemplateRef<any>;

    mapValue(option: any): any {
        return this.valueKey ? option[this.valueKey] : option;
    }

    mapLabel(option: any): string {
        return (this.labelKey ? option[this.labelKey] : option) ?? '';
    }
}
