import type { CardResult } from './card-result';
import type { CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { Distribution } from './../../compute/distributions/distribution';
import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { NumericalHistogramFragment } from './fragments/numerical-histogram-fragment';
import type { TestDistribution } from './../../compute/computations/univariate/test-distribution';
import type { UnivariateCard } from './univariate-card';
import type { _CardResult } from './card-result';
import type { _CardWithConfidenceLevel } from './common/card-with-confidence-level';
import type { _Distribution } from './../../compute/distributions/distribution';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _NumericalHistogramFragment } from './fragments/numerical-histogram-fragment';
import type { _UnivariateCard } from './univariate-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.FitDistributionCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _FitDistributionCard extends _UnivariateCard, _CardWithConfidenceLevel {
    type: "fit_distribution";
    confidenceLevel: number;
    distributions: FitDistributionCard.RequestedDistribution[];
}

export type FitDistributionCard = _FitDistributionCard;

export namespace FitDistributionCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.FitDistributionCard in PolyJSON mappings */
    export const type = "fit_distribution";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.FitDistributionCard$FitDistributionCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FitDistributionCardResult extends _CardResult {
        type: "fit_distribution";
        distributions: FitDistributionCard.SingleFitResult[];
        histogram: NumericalHistogramFragment;
    }
    
    export type FitDistributionCardResult = _FitDistributionCardResult;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.FitDistributionCard$RequestedDistribution
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _RequestedDistribution extends _ImmutableValueObject {
        distribution: Distribution;
        fixedParameters?: Distribution.FittedDistribution | null;
    }
    
    export type RequestedDistribution = _RequestedDistribution;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.FitDistributionCard$SingleFitResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _SingleFitResult extends _ImmutableValueObject {
        distribution: Distribution;
        fit: Distribution.FittedDistribution;
        plot: TestDistribution.PlotData;
        test: TestDistribution.StatTests;
        warnings: string[];
    }
    
    export type SingleFitResult = _SingleFitResult;

    export namespace FitDistributionCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.FitDistributionCard$FitDistributionCardResult in PolyJSON mappings */
        export const type = "fit_distribution";
    }
}