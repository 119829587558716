import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { AxisTicksConfigurationService } from '../../services';

@UntilDestroy()
@Component({
    selector: 'axis-ticks-configuration-form',
    templateUrl: './axis-ticks-configuration-form.component.html',
    styleUrls: ['./axis-ticks-configuration-form.component.less']
})

export class AxisTicksConfigurationFormComponent implements OnInit, AfterViewInit {
    @Output() ticksConfigChange = new EventEmitter<ChartDef.AxisTicksConfig>();

    public tooltip = 'Responsive display. Overlapping values are hidden.';
    public disabledTooltip$: BehaviorSubject<string>;
    public isDisabled: boolean;
    public ticksAreOverlapping: boolean = false;
    public _axisIdentifier: 'x' | 'y';

    public ticksConfigForm = new UntypedFormGroup({
        number: new FormControl(null),
        mode: new FormControl(ChartDef.AxisTicksConfig.AxisTicksConfigMode.INTERVAL)
    });

    private DEFAULT_DISABLED_TOOLTIP = 'Option is disabled as "Generate on tick per bin" is selected in the dimension';

    constructor(
        @Inject('ChartFeatures') public chartFeaturesService: any,
        public axisTicksConfigurationService: AxisTicksConfigurationService
    ) {
        this.disabledTooltip$ = new BehaviorSubject(this.DEFAULT_DISABLED_TOOLTIP);
    }

    @Input()
    set disabled(disabled: boolean) {
        this.isDisabled = disabled;
        if (disabled) {
            const numberControl = this.ticksConfigForm.get('number');
            const modeControl = this.ticksConfigForm.get('mode');
            if (numberControl && modeControl) {
                numberControl.disable({ emitEvent: false });
                modeControl.disable({ emitEvent: false });
            }
        }
    }

    @Input()
    set disabledMessage(message: string) {
        this.disabledTooltip$.next(message);
    }

    @Input()
    set ticksConfig(value: ChartDef.AxisTicksConfig) {
        if (!this.equals(value, this.ticksConfigForm.getRawValue())) {
            this.ticksConfigForm.patchValue(value);
        }
    }

    @Input()
    set axisIdentifier(value: 'x' | 'y') {
        this.ticksAreOverlapping = this.axisTicksConfigurationService.getTicksAreOverlappingByIdentifier(value);
        this._axisIdentifier = value;
    }

    ngOnInit() {
        this.axisTicksConfigurationService.onAxisOverlappingChange.subscribe((res: { identifier: 'x' | 'y', overlap: boolean }) => {
            if (res.identifier === this._axisIdentifier) {
                this.ticksAreOverlapping = res.overlap;
            }
        });
    }

    ngAfterViewInit() {
        this.ticksConfigForm.valueChanges
            .pipe(
                untilDestroyed(this),
                debounceTime(10),
                distinctUntilChanged(this.equals)
            )
            .subscribe((nextValue: ChartDef.AxisTicksConfig) => {
                this.ticksConfigChange.emit({ ...nextValue });
            });
    }

    equals(ticksConfig: ChartDef.AxisTicksConfig, ticksConfigForm: ChartDef.AxisTicksConfig): boolean {
        return ticksConfig.mode === ticksConfigForm.mode && (ticksConfig.number === ticksConfigForm.number || (isNil(ticksConfig.number) && isNil(ticksConfigForm.number)));
    }
}
