import type { CorrelationFunction } from './correlation-function';
import type { _CorrelationFunction } from './correlation-function';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.ACF
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ACF extends _CorrelationFunction {
    type: "acf";
    adjusted: boolean;
    alpha: number;
    nLags?: number | null;
}

export type ACF = _ACF;

export namespace ACF {
    /** Type of class com.dataiku.dip.eda.compute.computations.timeseries.ACF in PolyJSON mappings */
    export const type = "acf";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.timeseries.ACF$ACFResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ACFResult extends CorrelationFunction._CorrelationFunctionResult {
        type: "acf";
    }
    
    export type ACFResult = _ACFResult;

    export namespace ACFResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.timeseries.ACF$ACFResult in PolyJSON mappings */
        export const type = "acf";
    }
}