import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.TTest1Samp
 * Via: UIModel annotation in the class hierarchy
 */
export interface _TTest1Samp extends Computation._UnivariateComputation {
    type: "ttest_1samp";
    hypothesizedMean: number;
}

export type TTest1Samp = _TTest1Samp;

export namespace TTest1Samp {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.TTest1Samp in PolyJSON mappings */
    export const type = "ttest_1samp";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.TTest1Samp$TTest1SampResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _TTest1SampResult extends _AvailableResult {
        type: "ttest_1samp";
        dof: number;
        pvalue: number;
        pvalueAltGt: number;
        pvalueAltLt: number;
        statistic: number;
    }
    
    export type TTest1SampResult = _TTest1SampResult;

    export namespace TTest1SampResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.TTest1Samp$TTest1SampResult in PolyJSON mappings */
        export const type = "ttest_1samp";
    }
}