import { ColDef, GridApi, IRowNode } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { Comparable, Cloneable } from "@app/shared";
import { CustomRowData, WellDefinedColDef } from './utils';
import _ from 'lodash';

export interface Diff extends Cloneable, Comparable {

    /** Execute the action */
    execute(): void;

    /** Return the reverse action */
    revert(): Diff;

    /**
     * Combine this diff with the given diff
     * Return null if this diff was modified in-place to represent the combined diff, or a brand new diff representing the combined diff
     * @param diff other diff to be combined with this diff
     */
    combine(diff: Diff): Diff | null;
}

abstract class SimpleDiff implements Diff {

    abstract execute(): void;
    abstract revert(): Diff;
    combine(diff: Diff): Diff {
        return new MultiDiff([this, diff]);
    }
    abstract clone(): this;
    abstract equals(compared?: this): boolean;
}

export class MultiDiff implements Diff {

    constructor(private diffs: Diff[]) {}

    execute(): void {
        this.diffs.forEach((diff: Diff) => diff.execute());
    }

    revert(): Diff {
        return new MultiDiff([...this.diffs].reverse().map((diff: Diff) => diff.revert()));
    }

    combine(diff: Diff): null {
        this.diffs.push(diff);
        return null;
    }

    equals(diff: this): boolean {
        if (!(diff instanceof MultiDiff)) {
            return false;
        }

        if (this.diffs.length !== diff.diffs.length) return false;

        for (let i = 0; i < this.diffs.length; i++) {
            if (!(this.diffs[i].equals(diff.diffs[i]))) return false;
        }

        return true;
    }

    clone() {
        return new MultiDiff(this.diffs.map(diff => diff.clone())) as this;
    }
}

export class EditCell extends SimpleDiff {

    constructor(private rowNode: IRowNode, private colDef: ColDef, private gridApi: GridApi, private oldValue: string | null, private newValue: string | null) {
        super();
    }

    execute(): void {
        const field = this.colDef.field;
        if (field !== undefined) {
            // Functionally equivalent to AG Grid's setDataValue(), but faster
            _.set(this.rowNode.data, field, this.newValue); // cannot simply assign because there is a dot in field
            // Refresh using change detection (only cells that have their values changed are refreshed)
            this.gridApi.refreshCells();
        }
    }

    revert(): Diff {
        return new EditCell(this.rowNode, this.colDef, this.gridApi, this.newValue, this.oldValue);
    }

    equals(diff: this): boolean {
        return (
            diff instanceof EditCell
            && this.rowNode.id === diff.rowNode.id
            && this.colDef.field === diff.colDef.field
            && this.oldValue === diff.oldValue
            && this.newValue === diff.newValue
        );
    }

    clone() {
        return new EditCell(this.rowNode, this.colDef, this.gridApi, this.oldValue, this.newValue) as this;
    }
}

export class EditRow extends SimpleDiff {

    constructor(private rowNode: IRowNode, private oldRowData: CustomRowData, private newRowData: CustomRowData) {
        super();
    }

    execute(): void {
        this.rowNode.updateData(this.newRowData);
    }

    revert(): Diff {
        return new EditRow(this.rowNode, this.newRowData, this.oldRowData) as this;
    }

    equals(diff: this): boolean {
        return (
            diff instanceof EditRow
            && this.rowNode.id === diff.rowNode.id
            && this.oldRowData === diff.oldRowData
            && this.newRowData === diff.newRowData
        );
    }

    clone() {
        return new EditRow(this.rowNode, this.oldRowData, this.newRowData) as this;
    }
}

export class RemoveRows extends SimpleDiff {

    /**
     * Construct a diff to remove contiguous rows
     * @param rowData data of contiguous rows
     * @param rowIndex min row index
     * @param api AG Grid API
     */
    constructor(private rowData: CustomRowData[], private rowIndex: number | null, private api: GridApi) {
        // Rows must be contiguous because the reverse diff AddRows can only add rows contiguously
        // Row index useful for AddRows only
        super();
    }

    execute(): void {
        this.api.applyTransaction({ remove: this.rowData });
    }

    revert(): Diff {
        return new AddRows(this.rowData, this.rowIndex, this.api);
    }

    equals(diff: this): boolean {
        if (!(diff instanceof RemoveRows)) return false;

        if (this.rowIndex !== diff.rowIndex) return false;

        if (this.rowData.length !== diff.rowData.length) return false;

        for (let i = 0; i < this.rowData.length; i++) {
            if (this.rowData[i] !== diff.rowData[i]) return false;
        }

        return true;
    }

    clone() {
        return new RemoveRows(this.rowData, this.rowIndex, this.api) as this;
    }
}

export class AddRows extends SimpleDiff {

    /**
     * Construct a diff to add contiguous rows
     * @param rowData data of contiguous rows
     * @param rowIndex starting index of the rows to be added (i.e. index of the first rows in the row list)
     * @param api AG Grid API
     */
    constructor(private rowData: CustomRowData[], private rowIndex: number | null, private api: GridApi) {
        super();
    }

    execute(): void {
        this.api.applyTransaction({ add: this.rowData, addIndex: this.rowIndex });
    }

    revert(): Diff {
        return new RemoveRows(this.rowData, this.rowIndex, this.api);
    }

    equals(diff: this): boolean {
        if (!(diff instanceof AddRows)) return false;

        if (this.rowIndex !== diff.rowIndex) return false;

        if (this.rowData.length !== diff.rowData.length) return false;

        for (let i = 0; i < this.rowData.length; i++) {
            if (this.rowData[i] !== diff.rowData[i]) return false;
        }

        return true;
    }

    clone() {
        return new AddRows(this.rowData, this.rowIndex, this.api) as this;
    }
}

export class RemoveCols extends SimpleDiff {

    /**
     * Construct a diff to remove cols, contiguous or not
     * @param colDefs definitions of columns to remove, must be in sync with positions, must be from leftmost to rightmost
     * @param positions positions of columns to remove, must be in sync with colDefs, must be in ascending order
     * @param api AG Grid API
     */
    constructor(private colDefs: ColDef[], private positions: number[], private api: GridApi) {
        super();
    }

    execute(): void {
        const newColumnDefs = [...(this.api.getColumnDefs() as (ColDef)[])];
        // Go through positions in reverse order so indexes remain valid after splice()
        for (let i = this.positions.length - 1; i >= 0; i--) {
            newColumnDefs.splice(this.positions[i], 1);
        }
        this.api.setColumnDefs(newColumnDefs);
    }

    revert(): Diff {
        return new AddCols(this.colDefs, this.positions, this.api);
    }

    equals(diff: this): boolean {
        if (!(diff instanceof RemoveCols)) {
            return false;
        }

        if (this.colDefs.length !== diff.colDefs.length) {
            return false;
        }

        if (this.positions.length !== diff.positions.length) {
            return false;
        }

        for (let i = 0; i < this.colDefs.length; i++) {
            if (this.colDefs[i].field !== diff.colDefs[i].field) {
                return false;
            }
        }

        for (let i = 0; i < this.positions.length; i++) {
            if (this.positions[i] !== diff.positions[i]) {
                return false;
            }
        }

        return true;
    }

    clone() {
        return new RemoveCols(this.colDefs, this.positions, this.api) as this;
    }
}

export class AddCols extends SimpleDiff {

    /**
     * Construct a diff to add cols, contiguous or not
     * @param colDefs definitions of columns to add, must be in sync with positions, must be from leftmost to rightmost
     * @param positions positions of columns to add, must be in sync with colDefs, must be in ascending order
     * @param api AG Grid API
     */
    constructor(private colDefs: ColDef[], private positions: number[], private api: GridApi) {
        super();
    }

    execute(): void {
        const newColumnDefs = [...(this.api.getColumnDefs() as (ColDef)[])];
        for (let i = 0; i < this.positions.length; i++) {
            newColumnDefs.splice(this.positions[i], 0, this.colDefs[i]);
        }
        this.api.setColumnDefs(newColumnDefs);
    }

    revert(): Diff {
        return new RemoveCols(this.colDefs, this.positions, this.api);
    }

    equals(diff: this): boolean {
        if (!(diff instanceof AddCols)) {
            return false;
        }

        if (this.colDefs.length !== diff.colDefs.length) {
            return false;
        }

        if (this.positions.length !== diff.positions.length) {
            return false;
        }

        for (let i = 0; i < this.colDefs.length; i++) {
            if (this.colDefs[i].field !== diff.colDefs[i].field) {
                return false;
            }
        }

        for (let i = 0; i < this.positions.length; i++) {
            if (this.positions[i] !== diff.positions[i]) {
                return false;
            }
        }

        return true;
    }

    clone() {
        return new AddCols(this.colDefs, this.positions, this.api) as this;
    }
}

export class UpdateColDef extends SimpleDiff {

    constructor(
        private oldColDef: WellDefinedColDef,
        private newColDef: WellDefinedColDef,
        private api: GridApi
    ) {
        super();
    }

    execute(): void {
        const oldName = this.oldColDef.headerName;
        const newColDefs = [...(this.api.getColumnDefs() as (WellDefinedColDef)[])];
        const colIndex = newColDefs.map(col => col.headerName).indexOf(oldName);
        newColDefs[colIndex] = this.newColDef;
        this.api.setColumnDefs(newColDefs);
        this.api.refreshHeader();
    }

    revert(): Diff {
        return new UpdateColDef(this.newColDef, this.oldColDef, this.api);
    }

    equals(diff: this): boolean {
        return (
            diff instanceof UpdateColDef
            && this.oldColDef === diff.oldColDef
            && this.newColDef === diff.newColDef
        );
    }

    clone() {
        return new UpdateColDef(this.oldColDef, this.newColDef, this.api) as this;
    }
}
export class ReorderCol extends SimpleDiff {
    constructor(private oldOrder: ColDef[], private newOrder: ColDef[], private api: GridApi) {
        super();
    }

    execute() {
        this.api.setColumnDefs(this.newOrder);
    }

    revert(): Diff {
        return new ReorderCol(this.newOrder, this.oldOrder, this.api);
    }

    equals(diff: this): boolean {
        if (!(diff instanceof ReorderCol)) return false;

        if (this.oldOrder.length !== diff.oldOrder.length || this.newOrder.length !== diff.newOrder.length) return false;

        for (let i = 0; i < this.oldOrder.length; i++) {
            if (this.oldOrder[i].colId !== diff.oldOrder[i].colId) return false;
            if (this.oldOrder[i].field !== diff.oldOrder[i].field) return false;
        }

        for (let i = 0; i < this.newOrder.length; i++) {
            if (this.newOrder[i].colId !== diff.newOrder[i].colId) return false;
            if (this.newOrder[i].field !== diff.newOrder[i].field) return false;
        }

        return true;
    }

    clone() {
        return new ReorderCol(this.oldOrder, this.newOrder, this.api) as this;
    }
}

class Stack<T extends Comparable & Cloneable> implements Comparable, Cloneable {

    private elems: T[] = [];
    constructor(private maxSize: number = Infinity) {}

    push(elem: T): void {
        if(this.elems.length >= this.maxSize) {
            this.elems.shift();
        }
        this.elems.push(elem);
    }

    pop(): T | undefined {
        return this.elems.pop();
    }

    peek(): T | undefined {
        return this.elems[this.elems.length - 1];
    }

    isEmpty(): boolean {
        return this.elems.length === 0;
    }

    clear(): void {
        this.elems = [];
    }

    clone(): this {
        const clone = new Stack<T>(this.maxSize);
        clone.elems = this.elems.map(e => e.clone());

        return clone as this;
    }

    equals(compared?: Stack<T>): boolean {
        if (!compared) return false;
        if (this.elems.length !== compared.elems.length) return false;
        for (let i = 0; i < this.elems.length; i++) {
            if (!(this.elems[i].equals(compared.elems[i]))) {
                return false;
            }
        }
        return true;
    }
}

export class SpreadsheetDiffs implements Comparable, Cloneable {
    public diffsChanged$: BehaviorSubject<undefined>;
    private done: Stack<Diff> = new Stack<Diff>(100);
    private undone: Stack<Diff> = new Stack<Diff>(100);
    private diffWasAddedRecently: boolean = false;

    constructor() {
        this.diffsChanged$ = new BehaviorSubject<undefined>(undefined);
    }

    private static transfer(src: Stack<Diff>, dst: Stack<Diff>): void {
        if (src.isEmpty()) {
            return;
        }

        const top = src.pop();
        if (!top) {
            return;
        }

        const revert = top.revert();
        revert.execute();
        dst.push(revert);
    }

    /**
     * Undo last action and emit a signal to notify diffs changed.
     */
    undo(): void{
        SpreadsheetDiffs.transfer(this.done, this.undone);
        this.diffsChanged$.next(undefined);
    }

    /**
     * Redo last action and emit a signal to notify diffs changed.
     */
    redo(): void{
        SpreadsheetDiffs.transfer(this.undone, this.done);
        this.diffsChanged$.next(undefined);
    }

    /**
     * Register a new action.
     * Support batching actions.
     * Emit a signal to notify diffs changed.
     * Warning: the action is not executed.
     * @param diff action to be registered.
     * @param noMultiDiff set to `true` if we want to explicitly register the action as a single operation in the sheet's history. Useful when
     * multiple actions are dispatched simultaneously/very closely one to another but we need to register them as distinct diffs (i.e. not grouped together inside a single MultiDiff)
     */
    register(diff: Diff, noMultiDiff: boolean = false): void {
        if (this.diffWasAddedRecently && !this.done.isEmpty() && !noMultiDiff) {
            const combinedDiff = this.done.peek()?.combine(diff);
            if (combinedDiff !== null && combinedDiff !== undefined) {
                this.done.pop();
                this.done.push(combinedDiff);
            }
        } else {
            this.done.push(diff);
        }
        this.undone.clear();
        this.diffWasAddedRecently = true;
        setTimeout(() => {
            this.diffWasAddedRecently = false;
        }, 100);
        this.diffsChanged$.next(undefined);
    }

    /**
     * Register and execute a new action.
     * Support batching actions.
     * Emit a signal to notify diffs changed.
     * @param diff action to be registered and executed.
     * @param noMultiDiff set to `true` if we want to explicitly register the action as a single operation in the sheet's history. Useful when
     * multiple actions are dispatched simultaneously/very closely one to another but we need to register them as distinct diffs (i.e. not grouped together inside a single MultiDiff)
     */
    registerAndExecute(diff: Diff, noMultiDiff: boolean = false): void {
        this.register(diff, noMultiDiff);
        diff.execute();
    }

    clear() : void {
        this.done.clear();
        this.undone.clear();
        this.diffsChanged$.next(undefined);
    }

    canUndo(): boolean {
        return !this.done.isEmpty();
    }

    canRedo(): boolean {
        return !this.undone.isEmpty();
    }

    /**
     * Check if the `done` diff stack of the current spreadsheet is the same as the compared one.
     * @param compared subject to be compared with.
     */
    equals(compared?: SpreadsheetDiffs): boolean {
        if (!compared) return false;
        return this.done.equals(compared.done);
    }

    clone() {
        const clone = new SpreadsheetDiffs();
        clone.done = this.done.clone();
        return clone as this;
    }
}
