import type { Card } from './../cards/card';
import type { CardResult } from './../cards/card-result';
import type { Filter } from './../../compute/filtering/filter';
import type { InteractiveQuery } from './interactive-query';
import type { Variable } from './../models/variable';
import type { _Card } from './../cards/card';
import type { _CardResult } from './../cards/card-result';
import type { _Filter } from './../../compute/filtering/filter';
import type { _InteractiveQuery } from './interactive-query';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.queries.SuggestCards
 * Via: UIModel annotation in the class hierarchy
 */
export interface _SuggestCards extends _InteractiveQuery {
    type: "suggest_cards";
    assistantSettings: SuggestCards.AssistantSettings;
    selectedVariables: string[];
}

export type SuggestCards = _SuggestCards;

export namespace SuggestCards {
    /** Type of class com.dataiku.dip.eda.worksheets.queries.SuggestCards in PolyJSON mappings */
    export const type = "suggest_cards";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.queries.SuggestCards$AssistantSettings
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AssistantSettings {
        confidenceLevel: number;
        highlightFilter?: Filter | null;
        showConfidenceInterval: boolean;
    }
    
    export type AssistantSettings = _AssistantSettings;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.queries.SuggestCards$SuggestCardsResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _SuggestCardsResult extends InteractiveQuery._InteractiveQueryResult {
        type: "suggest_cards";
        suggestedCards: SuggestCards.SuggestedCard[];
        suggestedVariables: SuggestCards.SuggestedVariable[];
    }
    
    export type SuggestCardsResult = _SuggestCardsResult;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.queries.SuggestCards$SuggestedCard
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _SuggestedCard {
        card: Card;
        miniatureCard: Card;
        miniatureResult: CardResult;
        name: string;
    }
    
    export type SuggestedCard = _SuggestedCard;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.queries.SuggestCards$SuggestedVariable
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _SuggestedVariable {
        card: Card;
        explanations: string[];
        individualScore: number;
        name: string;
        result: CardResult;
        totalScore: number;
        type: Variable.Type;
    }
    
    export type SuggestedVariable = _SuggestedVariable;

    export namespace SuggestCardsResult {
        /** Type of class com.dataiku.dip.eda.worksheets.queries.SuggestCards$SuggestCardsResult in PolyJSON mappings */
        export const type = "suggest_cards";
    }
}