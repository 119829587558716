import type { AuditTrailTargetSettings } from './audit-trail-target-settings';
import type { _AuditTrailTargetSettings } from './audit-trail-target-settings';

/** 
 * Translated from class com.dataiku.dip.security.audit.model.Log4JTargetSettings
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Log4JTargetSettings extends _AuditTrailTargetSettings {
    type: "LOG4J";
    appendTopicToLogger: boolean;
    loggerSuffix: string;
}

export type Log4JTargetSettings = _Log4JTargetSettings;

export namespace Log4JTargetSettings {
    /** Type of class com.dataiku.dip.security.audit.model.Log4JTargetSettings in PolyJSON mappings */
    export const type = "LOG4J";
}