import { ChartColorPickerModule } from '../../chart-color-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NumberSelectModule } from '@shared/modules/number-select';
import { ReactiveFormsModule } from '@angular/forms';
import { ReactiveInputModule } from '../../reactive-input';
import { ResetButtonModule } from '../../reset-button';
import { TextFormattingFormComponent } from './text-formatting-form.component';


@NgModule({
    declarations: [
        TextFormattingFormComponent
    ],
    imports: [
        CommonModule,
        ChartColorPickerModule,
        ReactiveFormsModule,
        ReactiveInputModule,
        NumberSelectModule,
        ResetButtonModule
    ],
    exports: [
        TextFormattingFormComponent
    ]
})

export class TextFormattingFormModule { }
