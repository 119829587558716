import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { APIError, catchAPIError, ErrorContext } from '@core/dataiku-api/api-error';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { WT1Service } from 'dku-frontend-core';
import { WaitingService } from '@core/overlays/waiting.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { compareNumbers, compareStrings } from 'dku-frontend-core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeMap, shareReplay, startWith } from 'rxjs/operators';
import { Experiment } from 'src/generated-sources';
import { DKU_EXT_EXPERIMENT_NAME, DKU_EXT_EXPERIMENT_RUN_COUNT, DKU_EXT_LAST_RUN_START, DKU_EXT_TAGS_KEY_PREFIX } from '../custom-keys';
import { ExperimentTrackingService } from '../experiment-tracking.service';
import { YYYYMMDDHHmmssDateTimePipe } from '@shared/pipes/date-pipes/yyyymmddhhmmss-date-time.pipe';

interface ExperimentViewModel extends Experiment {
    experimentName: string | undefined;
    deletedTooltip: string | undefined;
    runCount: number | undefined;
    lastRunStart: number | undefined;
    tagsMap: Map<string, string>
    href: string
}

interface ColumnDefinition {
    columnDef: string,
    valueKey: string,
    header: string,
    isFirstGroupCell: boolean,
    isNumerical: boolean;
}

enum ExperimentGroupColumns {
    TAGS = 'group-tags',
}

@UntilDestroy()
@Component({
    selector: 'experiment-tracking',
    templateUrl: './experiment-tracking.component.html',
    styleUrls: ['./experiment-tracking.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentTrackingComponent implements OnInit, ErrorContext {
    projectKey: string;

    experiments$: Observable<ExperimentViewModel[]>;
    refresh$: Subject<void> = new ReplaySubject(1);

    tagsColumns: any[] = [];

    error?: APIError;

    selectedExperimentIds = new Set<string>();

    static readonly staticColumns = ['select', 'deleted', 'xp-name', 'xp-runCount', 'xp-lastRunStart', 'xp-creationTime'];
    tagsColumnDefMap: Map<string, ColumnDefinition>;
    displayedColumns: string[] = [];
    displayedHeaders: string[] = []; // All without tags plus group-tags
    tagsHeaders: string[] = []; // All tags columns defs

    querySearch$: Subject<string>;
    dataSource: MatTableDataSource<ExperimentViewModel> = new MatTableDataSource(new Array<ExperimentViewModel>());
    querySort$: Subject<Sort>;
    defaultSort: Sort = <Sort>{ active: "xp-lastRunStart", direction: "desc" };

    form = this.fb.group({
        viewAll: new UntypedFormControl(false, []),
        querySearch: new UntypedFormControl(undefined, [])
    })

    rightHeader: string = "";

    readonly viewAllControl = this.form.controls['viewAll'];
    readonly QUERY_SEARCH_SEPARATOR: string = ";";
    readonly TAG_PREFIX = 'tag-';

    constructor(
        private DataikuAPI: DataikuAPIService,
        private fb: UntypedFormBuilder,
        private cd: ChangeDetectorRef,
        private experimentTrackingService: ExperimentTrackingService,
        private waitingService: WaitingService,
        private wt1Service: WT1Service,
        private YYYYMMDDHHmmssDateTimePipe: YYYYMMDDHHmmssDateTimePipe
    ) {
    }

    ngOnInit(): void {
        this.projectKey = this.experimentTrackingService.projectKey;
        this.initViewAllParam();
        this.subscribeToSearchChange();
        this.querySearch$ = new BehaviorSubject<string>("");
        this.querySort$ = new BehaviorSubject(this.defaultSort);
        this.experiments$ =
            combineLatest([
                this.refresh$,
                this.viewAllControl.valueChanges.pipe(startWith(this.viewAllControl.value))
            ]).pipe(
                untilDestroyed(this),
                mergeMap(([_refresh, viewAll]) => {
                    return this.DataikuAPI.experiments.listExperiments(this.projectKey, viewAll ? "ALL" : "ACTIVE_ONLY")
                        .pipe(
                            this.waitingService.bindSpinner(),
                            catchAPIError(this, false, this.cd));
                }),
                map((experiments: Experiment[]) => {
                    this.setRightHeader(experiments);
                    this.initColumns(experiments);

                    const experimentsViewMode: ExperimentViewModel[] = experiments as ExperimentViewModel[];
                    const viewAll = this.viewAllControl.value;
                    experimentsViewMode.forEach(ex => {
                        ex.tagsMap = new Map();
                        ex.tags.forEach(t => ex.tagsMap.set(t.key, t.value));
                        ex.experimentName = ex.tagsMap.get(DKU_EXT_EXPERIMENT_NAME);
                        ex.deletedTooltip = (ex.lifecycleStage === "deleted") ? "Deleted" : undefined;
                        ex.runCount = parseInt(ex.tagsMap.get(DKU_EXT_EXPERIMENT_RUN_COUNT) || "0");
                        ex.lastRunStart = parseInt(ex.tagsMap.get(DKU_EXT_LAST_RUN_START) || "0");
                        ex.href = this.experimentTrackingService.getRunListHref([ex.id], viewAll, viewAll);
                    });
                    return experimentsViewMode;
                }),
                shareReplay(1),
            );
        combineLatest([this.experiments$, this.querySearch$, this.querySort$])
            .pipe(untilDestroyed(this))
            .subscribe(([experiments, querySearch, querySort]: [ExperimentViewModel[], string, Sort]) => {
                this.dataSource.filter = querySearch.trim().toLowerCase();

                if (querySort) {
                    this.dataSource.data = this.sortData(experiments, <Sort>{ active: querySort.active, direction: querySort.direction });
                } else {
                    this.dataSource.data = experiments;
                }
            });
        this.viewAllControl.valueChanges.pipe(untilDestroyed(this)).subscribe(viewAll => this.experimentTrackingService.goToExperimentTracking(viewAll, false, 'replace'));
    }

    ngAfterViewInit() {
        const self = this;
        const filterPredicate = function (data: ExperimentViewModel, filter: string): boolean {
            return self.filterData(data, filter);
        }

        this.dataSource.filterPredicate = filterPredicate;
        this.refresh();
    }

    setRightHeader(experiments: Experiment[]) {
        this.rightHeader = experiments.length + " Experiments";
    }

    trackExperimentBy(_index: number, item: ExperimentViewModel) {
        return item.id;
    }

    initViewAllParam() {
        this.form.patchValue({
            viewAll: this.experimentTrackingService.viewAllExperiments
        });
    }

    initColumns(experiments: Experiment[]) {
        this.initTagColumns(experiments);
        this.tagsHeaders = this.tagsColumns.map(tc => tc.columnDef);
        this.displayedHeaders = [...ExperimentTrackingComponent.staticColumns];
        if (this.tagsHeaders.length > 0) {
            this.displayedHeaders.push(ExperimentGroupColumns.TAGS);
        }
        this.displayedColumns = [...ExperimentTrackingComponent.staticColumns, ...this.tagsHeaders];
    }

    initTagColumns(experiments: Experiment[]) {
        this.tagsColumnDefMap = new Map();
        experiments.forEach(experiment => {
            experiment.tags.forEach(tag => {
                if (!tag.key || tag.key.startsWith(DKU_EXT_TAGS_KEY_PREFIX)) {
                    return;
                }
                const tagsColumnDefMapKey = this.TAG_PREFIX + tag.key;
                const existing = this.tagsColumnDefMap.get(tagsColumnDefMapKey);
                const isNumerical = isFinite(tag.value as any);
                if (!existing) {
                    this.tagsColumnDefMap.set(tagsColumnDefMapKey, {
                        columnDef: tagsColumnDefMapKey,
                        valueKey: tag.key,
                        header: tag.key,
                        isNumerical: isNumerical,
                        isFirstGroupCell: false
                    });
                } else {
                    existing.isNumerical = existing.isNumerical && isNumerical;
                }
            });
        });
        this.tagsColumns = Array.from(this.tagsColumnDefMap.keys()).sort().map((tagName, index) => {
            const column = this.tagsColumnDefMap.get(tagName);
            return {
                ...column,
                isFirstGroupCell: index === 0
            }
        });
    }

    subscribeToSearchChange(): void {
        const searchValue$ = this.form.controls['querySearch'].valueChanges
            .pipe(
                untilDestroyed(this),
                distinctUntilChanged(),
                debounceTime(300)
            );
        searchValue$.subscribe((search: string) => {
            this.querySearch$.next(search);
        });
        this.publishWT1QuerySearchEvent(searchValue$);
    }

    pushError(error: APIError): void {
        this.error = error;
    }

    formatDate(date: number): string {
        return this.YYYYMMDDHHmmssDateTimePipe.transform(date);
    }

    viewSelectionRuns() {
        const viewAll = this.viewAllControl.value;
        this.experimentTrackingService.goToRunList(Array.from(this.selectedExperimentIds), viewAll, viewAll);
    }

    refresh(): void {
        this.refresh$.next();
    }

    isAllSelected(experiments: Experiment[]): boolean {
        const anyUnselectedVisibleExperiment = experiments.find(experiment => !this.selectedExperimentIds.has(experiment.id));
        return !anyUnselectedVisibleExperiment;
    }

    masterToggle(experiments: Experiment[]) {
        if (this.isAllSelected(experiments)) {
            this.selectedExperimentIds.clear();
        } else {
            experiments.forEach(e => this.selectedExperimentIds.add(e.id));
        }
    }

    isExperimentSelected(experiment: Experiment) {
        return this.selectedExperimentIds.has(experiment.id)
    }

    toggleExperimentSelection(experiment: Experiment) {
        if (this.selectedExperimentIds.has(experiment.id)) {
            this.selectedExperimentIds.delete(experiment.id);
        } else {
            this.selectedExperimentIds.add(experiment.id);
        }
    }

    hasSelectedExperiments(): boolean {
        return this.selectedExperimentIds.size != 0;
    }

    getSelectedDeletedExperiments(experiments: Experiment[]) {
        if (!this.selectedExperimentIds) {
            return [];
        }
        return experiments.filter(e => this.selectedExperimentIds.has(e.id)
            && e.lifecycleStage === "deleted");
    }

    getSelectedActiveExperiments(experiments: Experiment[]) {
        if (!this.selectedExperimentIds) {
            return [];
        }
        return experiments.filter(e => this.selectedExperimentIds.has(e.id)
            && e.lifecycleStage === "active");
    }

    hasSelectedDeletedExperiments(experiments: Experiment[]): boolean {
        return this.getSelectedDeletedExperiments(experiments).length !== 0;
    }

    hasSelectedActiveExperiments(experiments: Experiment[]): boolean {
        return this.getSelectedActiveExperiments(experiments).length !== 0;
    }

    checkedAll(experiments: Experiment[]): string {
        if (this.hasSelectedExperiments() && this.isAllSelected(experiments)) {
            return "true";
        }
        return "";
    }

    restoreSelected(experiments: Experiment[]) {
        return this.DataikuAPI.experiments.restoreExperiments(this.projectKey, this.getSelectedDeletedExperiments(experiments).map(e => e.id))
            .pipe(
                this.waitingService.bindSpinner(),
                catchAPIError(this, false, this.cd))
            .subscribe(() => {
                this.selectedExperimentIds.clear();
                this.refresh();
            });
    }

    deleteSelected(experiments: Experiment[]) {
        return this.DataikuAPI.experiments.deleteExperiments(this.projectKey, this.getSelectedActiveExperiments(experiments).map(e => e.id))
            .pipe(
                this.waitingService.bindSpinner(),
                catchAPIError(this, false, this.cd))
            .subscribe(() => {
                this.selectedExperimentIds.clear();
                this.refresh();
            });
    }

    filterData(data: ExperimentViewModel, filter: string): boolean {
        let dataStr = "";

        this.displayedColumns.forEach((column: string) => {
            if (column === 'xp-name') {
                dataStr += data.name;
            } else if (column === 'xp-runCount') {
                dataStr += data.runCount;
            } else if (column === 'xp-lastRunStart') {
                dataStr += this.formatDate(data.lastRunStart || 0);
            } else if (column === 'xp-creationTime') {
                dataStr += this.formatDate(data.creationTime || 0);
            } else {
                const tagColumn = this.tagsColumnDefMap.get(column);
                if (tagColumn) {
                    const tagValue = data.tagsMap.get(tagColumn.valueKey) || '';
                    const isNumeric = tagColumn.isNumerical;
                    if (isNumeric) {
                        dataStr += Number(tagValue).toFixed(4);
                    } else {
                        dataStr += tagValue;
                    }

                }
            }

            dataStr += this.QUERY_SEARCH_SEPARATOR;
        });

        return dataStr.toLowerCase().indexOf(filter) != -1;
    }

    publishWT1QuerySearchEvent(querySearch$: Observable<string>) {
        querySearch$.pipe(
            untilDestroyed(this)
        ).subscribe(
            (search: string) => {
                this.wt1Service.event('experiment-tracking-experiments-query-search',
                    {
                        querySearch: search
                    });
            }
        );
    }

    onSortChange(sort: Sort) {
        this.wt1Service.event('experiment-tracking-sort-experiments-table', sort);
        this.querySort$.next(sort);
    }

    sortData(experiments: ExperimentViewModel[], sort: Sort) {
        const data = experiments.slice();
        if (!sort.active || sort.direction === '') {
            return data;
        }
        return data.sort((a: ExperimentViewModel, b: ExperimentViewModel) => {
            const isAsc = sort.direction === 'asc';
            let compareValue = 0;

            if (sort.active === 'xp-name') {
                compareValue = compareStrings(a.name, b.name, isAsc);
            } else if (sort.active === 'xp-runCount') {
                compareValue = compareNumbers(a.runCount || 0, b.runCount || 0, isAsc);
            } else if (sort.active === 'xp-lastRunStart') {
                compareValue = compareNumbers(a.lastRunStart || 0, b.lastRunStart || 0, isAsc);
            } else if (sort.active === 'xp-creationTime') {
                compareValue = compareNumbers(a.creationTime, b.creationTime, isAsc);
            } else if (sort.active === 'xp-lastRunStart') {
                compareValue = compareNumbers(a.creationTime, b.creationTime, isAsc);
            } else {
                const tagColumn = this.tagsColumnDefMap.get(sort.active);
                if (!tagColumn) {
                    compareValue = 0;
                } else {
                    const tagA = a.tagsMap.get(tagColumn.valueKey) || '';
                    const tagB = b.tagsMap.get(tagColumn.valueKey) || '';
                    const isNumeric = tagColumn.isNumerical;
                    const compareFunc = isNumeric ? compareNumbers : compareStrings;
                    compareValue = compareFunc(tagA, tagB, isAsc);
                }
            }

            return compareValue;
        });
    }


    createExperimentsDataset() {
        return this.experimentTrackingService.goToCreateExperimentsDataset(Array.from(this.selectedExperimentIds), this.viewAllControl.value);
    }
}
