import type { STLDecomposition } from './../../compute/computations/timeseries/stldecomposition';
import type { STLDecompositionParams } from './../../compute/computations/stldecomposition-params';
import type { TimeSeriesCard } from './time-series-card';
import type { _STLDecompositionParams } from './../../compute/computations/stldecomposition-params';
import type { _TimeSeriesCard } from './time-series-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.STLDecompositionCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _STLDecompositionCard extends _TimeSeriesCard {
    type: "stl_decomposition";
    params: STLDecompositionParams;
    selectedSeries: STLDecompositionCard.SeriesKind[];
    showCompactChart: boolean;
}

export type STLDecompositionCard = _STLDecompositionCard;

export namespace STLDecompositionCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.STLDecompositionCard in PolyJSON mappings */
    export const type = "stl_decomposition";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.STLDecompositionCard$STLDecompositionCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _STLDecompositionCardResult extends TimeSeriesCard._TimeSeriesCardResult {
        type: "stl_decomposition";
        guessedParams: STLDecomposition.STLDecompositionResult.STLGuessedParams;
        observed: number[];
        resid: number[];
        seasonal: number[];
        time: string[];
        trend: number[];
    }
    
    export type STLDecompositionCardResult = _STLDecompositionCardResult;
    
    /** 
     * Translated from enum com.dataiku.dip.eda.worksheets.cards.STLDecompositionCard$SeriesKind
     * Via: com.dataiku.dip.eda.worksheets.cards.STLDecompositionCard
     */
    export enum SeriesKind {
        OBSERVED = "OBSERVED",
        TREND = "TREND",
        SEASONALITY = "SEASONALITY",
        RESIDUALS = "RESIDUALS",
    }

    export namespace STLDecompositionCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.STLDecompositionCard$STLDecompositionCardResult in PolyJSON mappings */
        export const type = "stl_decomposition";
    }
}