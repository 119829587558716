<div>
    <input
        [placeholder]="placeholder || 'Filter...'"
        type="text"
        #searchInput
        (input)="ngSelect.filter(searchInput.value)"
        (keydown)="handleKeydown($event)"
        selectAll
    />
    <i class="icon-dku-search"></i>
</div>
