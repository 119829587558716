export namespace GeneralSettingsDAO {
    

    export namespace InheritableEnabledSetting {
        /** 
         * Translated from enum com.dataiku.dip.dao.GeneralSettingsDAO$InheritableEnabledSetting$LocalValue
         * Via: com.dataiku.dip.coremodel.AppManifest
         */
        export enum LocalValue {
            ENABLED = "ENABLED",
            DISABLED = "DISABLED",
            INHERIT = "INHERIT",
        }
    }
}