import type { SmartObjectRef } from './../smart-object-ref';
import type { _SmartObjectRef } from './../smart-object-ref';



export namespace SerializedProject {
    /** 
     * Translated from class com.dataiku.dip.coremodel.SerializedProject$PermissionItem
     * Via: com.dataiku.dip.security.model.ProjectScopePublicAPIKey
     */
    export interface _PermissionItem {
        admin: boolean;
        executeApp: boolean;
        exportDatasetsData: boolean;
        group: string;
        manageAdditionalDashboardUsers: boolean;
        manageDashboardAuthorizations: boolean;
        manageExposedElements: boolean;
        moderateDashboards: boolean;
        publishToDataCollections: boolean;
        readDashboards: boolean;
        readProjectContent: boolean;
        runScenarios: boolean;
        shareToWorkspaces: boolean;
        user: string;
        writeDashboards: boolean;
        writeProjectContent: boolean;
    }
    
    export type PermissionItem = _PermissionItem;
    
    /** 
     * Translated from enum com.dataiku.dip.coremodel.SerializedProject$PermissionsVersion
     * Via: com.dataiku.dip.server.services.ProjectsService$UIProject
     */
    export enum PermissionsVersion {
        LEGACY = "LEGACY",
        V2 = "V2",
    }
    /** 
     * Translated from enum com.dataiku.dip.coremodel.SerializedProject$ProjectAppType
     * Via: com.dataiku.dip.server.services.ProjectsService$UIProject
     */
    export enum ProjectAppType {
        REGULAR = "REGULAR",
        APP_INSTANCE = "APP_INSTANCE",
        APP_TEMPLATE = "APP_TEMPLATE",
    }
    /** 
     * Translated from class com.dataiku.dip.coremodel.SerializedProject$ProjectDashboardAuthorizations
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ProjectDashboardAuthorizations {
        allAuthorized: boolean;
        authorizations: SerializedProject.ReaderAuthorization[];
    }
    
    export type ProjectDashboardAuthorizations = _ProjectDashboardAuthorizations;
    
    /** 
     * Translated from enum com.dataiku.dip.coremodel.SerializedProject$ProjectType
     * Via: com.dataiku.dip.server.services.ProjectsService$UIProject
     */
    export enum ProjectType {
        REGULAR = "REGULAR",
        BUNDLE_CONTAINER = "BUNDLE_CONTAINER",
    }
    /** 
     * Translated from class com.dataiku.dip.coremodel.SerializedProject$ReaderAuthorization
     * Via: com.dataiku.dip.coremodel.SerializedProject$ProjectDashboardAuthorizations
     */
    export interface _ReaderAuthorization {
        modes: SerializedProject.ReaderAuthorization.Mode[];
        objectRef: SmartObjectRef;
    }
    
    export type ReaderAuthorization = _ReaderAuthorization;

    export namespace ReaderAuthorization {
        /** 
         * Translated from enum com.dataiku.dip.coremodel.SerializedProject$ReaderAuthorization$Mode
         * Via: com.dataiku.dip.datacollections.UIDataCollection$AddObject
         */
        export enum Mode {
            READ = "READ",
            RUN = "RUN",
            WRITE = "WRITE",
            DISCOVER = "DISCOVER",
        }
    }
}