import { Directive, Input } from '@angular/core';
import { fairAny } from '../utils';

// This directive allows to type the context of `<ng-template>`
// Its generic type is retrieved from the input `templateContextType`, which is a function (any parameters) that returns an instance of the context
// (Note that this function is never called by this directive, it is just used for the typing)
// This type is then "transfered" to the context through the use of the `ngTemplateContextGuard` (`ctx is T`)
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'ng-template[templateContextType]',
})
export class TypedNgTemplateDirective<T> {
    @Input() templateContextType: (...args: fairAny[]) => T;

    static ngTemplateContextGuard<T>(
        dir: TypedNgTemplateDirective<T>,
        ctx: unknown
    ): ctx is T {
        return true;
    }
}
