import { Component, Inject, Input, ChangeDetectionStrategy, inject, DestroyRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MeasureDef } from '@model-main/pivot/frontend/model/measure-def';
import { isEqual, cloneDeep, isNil } from 'lodash';
import { BehaviorSubject, combineLatest, debounceTime, filter, of, switchMap, take } from 'rxjs';
import { ChartLabelsService } from '../../services';
import { ChartCustomColorsService } from './chart-custom-colors.service';
import { ColorOptions, ColorPalette } from './color-options.interface';

class ColorDef {
    label: string | null;
    color: string | null;
    colorId: string;
    min?: number;
    max?: number;
    icon?: string;
}

@Component({
    selector: 'chart-custom-colors',
    templateUrl: './chart-custom-colors.component.html',
    styleUrls: ['./chart-custom-colors.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartCustomColorsComponent implements OnInit {
    private id$ = new BehaviorSubject<string | null>(null);
    private labels$ = new BehaviorSubject<Array<any>>([]);
    private genericMeasures$ = new BehaviorSubject<Array<any>>([]);
    private numberFormattingOptions$ = new BehaviorSubject<any>(null);
    private withIcons$ = new BehaviorSubject<boolean>(false);

    displayedWithIcons$ = new BehaviorSubject(false);
    isHidden$ = new BehaviorSubject(false);
    itemsPerRow$ = new BehaviorSubject<number | null>(null);
    hasColorDefs$ = new BehaviorSubject<boolean>(false);
    colorDefs$ = new BehaviorSubject<Array<ColorDef>>([]);
    colors$ = new BehaviorSubject<Array<string>>([]);
    destroyRef = inject(DestroyRef);

    @Input()
    set id(value: string) {
        this.id$.next(value);
    }

    @Input()
    set labels(value: Array<any>) {
        this.labels$.next(value);
    }

    @Input()
    set genericMeasures(value: Array<any>) {
        this.genericMeasures$.next(value);
    }

    @Input()
    set numberFormattingOptions(value: any) {
        this.numberFormattingOptions$.next(value);
    }

    @Input()
    set withIcons(value: boolean) {
        this.withIcons$.next(value);
    }

    @Input()
    set itemsPerRow(value: number) {
        this.itemsPerRow$.next(value);
    }

    @Input()
    set isHidden(value: boolean) {
        this.isHidden$.next(value);
    }

    constructor(
        @Inject('ChartColorUtils') private chartColorUtilsService: any,
        private chartCustomColorsService: ChartCustomColorsService,
        protected chartLabelsService: ChartLabelsService,
        private changeDetectionRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.id$
            .pipe(
                filter(id => !isNil(id)),
                switchMap(id => {
                    const colorOptions$ = this.chartCustomColorsService.getInputColorOptions(id);
                    return combineLatest([
                        of(id),
                        this.labels$,
                        this.genericMeasures$,
                        this.numberFormattingOptions$,
                        this.withIcons$,
                        colorOptions$
                    ]);
                }),
                //  Wait for multiple observables if many have been modified at once
                debounceTime(10),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(([id, labels, genericMeasures, numberFormattingOptions, withIcons, [prevColorOptions, nextColorOptions]]) => {
                const newColorOptions = cloneDeep(nextColorOptions);

                if (newColorOptions) {
                    if (prevColorOptions) {
                        if (prevColorOptions.colorPalette !== newColorOptions.colorPalette) {
                            newColorOptions.customColors = {};
                        }

                        if (newColorOptions.colorPalette === ColorPalette.CUSTOM) {
                            let colorIndex;
                            const prevCustomPalette = prevColorOptions.customPalette?.colors || [];
                            const nextCustomPalette = newColorOptions.customPalette?.colors || [];
                            const colorRemoved = nextCustomPalette.length < prevCustomPalette.length;
                            for (const [id, color] of Object.entries(newColorOptions.customColors)) {
                                if (color && !nextCustomPalette.includes(color)) {
                                    if (!colorRemoved && (colorIndex = prevCustomPalette.indexOf(color)) !== -1) {
                                        // Retrieve the changed color
                                        newColorOptions.customColors[id] = nextCustomPalette[colorIndex];
                                    } else {
                                        // Color has been removed from palette
                                        delete newColorOptions.customColors[id];
                                    }
                                }
                            }
                        }
                    }

                    const colors = this.getColors(newColorOptions);
                    this.colors$.next(colors);

                    const colorDefs = this.getColorDefs(labels, genericMeasures, numberFormattingOptions, newColorOptions, colors);
                    this.colorDefs$.next(colorDefs);

                    this.displayedWithIcons$.next(this.isVisible(newColorOptions, colorDefs) && withIcons);
                }

                if (!isEqual(prevColorOptions, newColorOptions)) {
                    this.chartCustomColorsService.updateOutputColorOptions(id, newColorOptions);
                    this.changeDetectionRef.detectChanges();
                }
            });

        this.colorDefs$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(colorDefs => {
            this.hasColorDefs$.next(!!colorDefs.length);
        });
    }

    private isVisible(colorOptions: ColorOptions | null, colorDefs: Array<ColorDef>): boolean {
        return colorOptions?.colorPalette !== ColorPalette.MEANING && !!colorDefs?.length;
    }

    // Retrieve colors from the current palette
    private getColors(colorOptions: ColorOptions | null): Array<string> {
        const palette = this.chartColorUtilsService.getDiscretePalette(colorOptions);

        if (palette && palette.colors) {
            return palette.colors;
        }

        return [];
    }

    private getColor(colorOptions: ColorOptions | null, colors: Array<string>, colorId: string, index: number) {
        if (colorOptions && colorId in colorOptions.customColors) {
            return colorOptions.customColors[colorId];
        }

        if (colors && colors.length) {
            return colors[index % colors.length];
        }

        return null;
    }

    private getColorDefs(
        labels: Array<any>,
        genericMeasures: Array<MeasureDef>,
        numberFormattingOptions: any,
        colorOptions: ColorOptions | null,
        colors: Array<string>
    ): Array<ColorDef> {
        let colorDefs: Array<ColorDef> = [];

        if (labels && labels.length) {
            labels.forEach((item, index) => {
                if (item.label && item.label.colorId) {
                    item.label.color = this.getColor(colorOptions, colors, item.label.colorId, index);
                    colorDefs.push(item.label);
                }
            });
        } else if (genericMeasures.length) {
            colorDefs = genericMeasures.map((measure, index) => {
                const colorId: string = this.chartColorUtilsService.getMeasureId(genericMeasures, index);

                return {
                    colorId,
                    label: this.chartLabelsService.getShortMeasureLabel(measure),
                    color: this.getColor(colorOptions, colors, colorId, index)
                };
            });
        }

        if (colorDefs.length) {
            const minValue = colorDefs[0].min;
            const maxValue = colorDefs[colorDefs.length - 1].max;
            const numValues = colorDefs.length * 2;

            colorDefs = colorDefs.map(colorDef => {
                colorDef.label = this.chartLabelsService.getFormattedLabel(colorDef, numberFormattingOptions, minValue, maxValue, numValues) || colorDef.label;
                return colorDef;
            });
        }

        return colorDefs;
    }

    setColor(colorDef: ColorDef, color: string | null) {
        const id = this.id$.getValue();
        if (!isNil(id)) {
            this.chartCustomColorsService.getOutputColorOptions(id)
                .pipe(take(1))
                .subscribe((colorOptions) => {
                    if (colorDef.color !== color && colorOptions) {
                        colorOptions.customColors[colorDef.colorId] = color;
                        this.chartCustomColorsService.updateOutputColorOptions(id, cloneDeep(colorOptions));
                    }
                });
        }
    }

    resetCustomColors() {
        const id = this.id$.getValue();
        if (!isNil(id)) {
            this.chartCustomColorsService.getOutputColorOptions(id)
                .pipe(take(1))
                .subscribe((colorOptions) => {
                    if (colorOptions) {
                        colorOptions.customColors = {};
                        this.chartCustomColorsService.updateOutputColorOptions(id, cloneDeep(colorOptions));
                    }
                });
        }
    }

    identify(_: number, item: ColorDef){
        return item.label;
    }
}
