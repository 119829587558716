import type { AxisDef } from './axis-def';

/** 
 * Translated from class com.dataiku.dip.pivot.backend.model.Unaggregated
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Unaggregated {
    column: string;
    dateMode: Unaggregated.DateMode;
    id: string;
    type: AxisDef.Type;
}

export type Unaggregated = _Unaggregated;

export namespace Unaggregated {
    /** 
     * Translated from enum com.dataiku.dip.pivot.backend.model.Unaggregated$DateMode
     * Via: com.dataiku.dip.pivot.backend.model.Unaggregated
     */
    export enum DateMode {
        RANGE = "RANGE",
        HOUR_OF_DAY = "HOUR_OF_DAY",
        DAY_OF_WEEK = "DAY_OF_WEEK",
        DAY_OF_MONTH = "DAY_OF_MONTH",
        MONTH_OF_YEAR = "MONTH_OF_YEAR",
        WEEK_OF_YEAR = "WEEK_OF_YEAR",
        QUARTER_OF_YEAR = "QUARTER_OF_YEAR",
        YEAR = "YEAR",
    }
}