import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { DkuMatTooltipModule } from '@shared/components/dku-mat-tooltip/dku-mat-tooltip.module';
import { ResetButtonComponent } from './reset-button.component';


@NgModule({
    declarations: [
        ResetButtonComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        MatTooltipModule,
        DkuMatTooltipModule
    ],
    exports: [
        ResetButtonComponent
    ]
})

export class ResetButtonModule { }
