import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { IsotonicCurve } from './isotonic-curve';
import type { PolynomialCurve } from './polynomial-curve';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _IsotonicCurve } from './isotonic-curve';
import type { _PolynomialCurve } from './polynomial-curve';

/** 
 * Translated from class com.dataiku.dip.eda.compute.curves.Curve
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Curve extends _ImmutableValueObject {
}

/** List of all concrete subclasses of com.dataiku.dip.eda.compute.curves.Curve */
export type Curve =
    | IsotonicCurve
    | PolynomialCurve;

export namespace Curve {
    /** Types of class com.dataiku.dip.eda.compute.curves.Curve in PolyJSON mappings */
    export const type = ["isotonic", "polynomial", ] as const;
    
    export function isCurve(obj: Curve): obj is Curve { return ["isotonic", "polynomial", ].includes(obj["type"]); }
    export function isIsotonicCurve(obj: Curve): obj is IsotonicCurve { return obj["type"] == "isotonic"; }
    export function isPolynomialCurve(obj: Curve): obj is PolynomialCurve { return obj["type"] == "polynomial"; }
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.curves.Curve$ParametrizedCurve
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ParametrizedCurve extends _ImmutableValueObject {
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.eda.compute.curves.Curve$ParametrizedCurve */
    export type ParametrizedCurve =
        | IsotonicCurve.ParametrizedIsotonicCurve
        | PolynomialCurve.ParametrizedPolynomialCurve;

    export namespace ParametrizedCurve {
        /** Types of class com.dataiku.dip.eda.compute.curves.Curve$ParametrizedCurve in PolyJSON mappings */
        export const type = ["isotonic", "polynomial", ] as const;
        
        export function isParametrizedCurve(obj: Curve.ParametrizedCurve): obj is Curve.ParametrizedCurve { return ["isotonic", "polynomial", ].includes(obj["type"]); }
        export function isParametrizedIsotonicCurve(obj: Curve.ParametrizedCurve): obj is IsotonicCurve.ParametrizedIsotonicCurve { return obj["type"] == "isotonic"; }
        export function isParametrizedPolynomialCurve(obj: Curve.ParametrizedCurve): obj is PolynomialCurve.ParametrizedPolynomialCurve { return obj["type"] == "polynomial"; }
    }
}