import type { ExposedObjectsService } from './../server/services/exposed-objects-service';



export namespace UIPopularDataset {
    /** 
     * Translated from class com.dataiku.dip.populardatasets.UIPopularDataset$AbstractPopularDataset
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _AbstractPopularDataset {
        datasetType: string;
        name: string;
        objectAuthorizations: ExposedObjectsService.ObjectAuthorizations;
        projectKey: string;
        projectName: string;
        score: number;
        shortDesc: string;
        tags: string[];
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.populardatasets.UIPopularDataset$AbstractPopularDataset */
    export type AbstractPopularDataset =
        | UIPopularDataset.DiscoverablePopularDataset
        | UIPopularDataset.PopularDataset;
    
    /** 
     * Translated from class com.dataiku.dip.populardatasets.UIPopularDataset$DiscoverablePopularDataset
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _DiscoverablePopularDataset extends UIPopularDataset._AbstractPopularDataset {
        type: "DISCOVERABLE-DATASET";
    }
    
    export type DiscoverablePopularDataset = _DiscoverablePopularDataset;
    
    /** 
     * Translated from class com.dataiku.dip.populardatasets.UIPopularDataset$PopularDataset
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _PopularDataset extends UIPopularDataset._AbstractPopularDataset {
        type: "DATASET";
        isFeatureGroup: boolean;
        lastBuiltOn: number;
        numberOfColumns: number;
        numberOfDataCollections: number;
        numberOfProjects: number;
    }
    
    export type PopularDataset = _PopularDataset;
    
    /** 
     * Translated from class com.dataiku.dip.populardatasets.UIPopularDataset$PopularDatasetList
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _PopularDatasetList {
        datasets: UIPopularDataset.AbstractPopularDataset[];
        status: UIPopularDataset.PopularDatasetsStatus;
    }
    
    export type PopularDatasetList = _PopularDatasetList;
    
    /** 
     * Translated from class com.dataiku.dip.populardatasets.UIPopularDataset$PopularDatasetsStatus
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _PopularDatasetsStatus {
        lastRunDuration: number;
        lastRunTimestamp: number;
        nextScheduledRun: number;
        numberOfDatasets: number;
    }
    
    export type PopularDatasetsStatus = _PopularDatasetsStatus;

    export namespace AbstractPopularDataset {
        /** Types of class com.dataiku.dip.populardatasets.UIPopularDataset$AbstractPopularDataset in PolyJSON mappings */
        export const type = ["DISCOVERABLE-DATASET", "DATASET", ] as const;
        
        export function isAbstractPopularDataset(obj: UIPopularDataset.AbstractPopularDataset): obj is UIPopularDataset.AbstractPopularDataset { return ["DISCOVERABLE-DATASET", "DATASET", ].includes(obj["type"]); }
        export function isDiscoverablePopularDataset(obj: UIPopularDataset.AbstractPopularDataset): obj is UIPopularDataset.DiscoverablePopularDataset { return obj["type"] == "DISCOVERABLE-DATASET"; }
        export function isPopularDataset(obj: UIPopularDataset.AbstractPopularDataset): obj is UIPopularDataset.PopularDataset { return obj["type"] == "DATASET"; }
    }

    export namespace DiscoverablePopularDataset {
        /** Type of class com.dataiku.dip.populardatasets.UIPopularDataset$DiscoverablePopularDataset in PolyJSON mappings */
        export const type = "DISCOVERABLE-DATASET";
    }

    export namespace PopularDataset {
        /** Type of class com.dataiku.dip.populardatasets.UIPopularDataset$PopularDataset in PolyJSON mappings */
        export const type = "DATASET";
    }
}