import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { ComputationResultDataStreamer } from './../../engine/computation-result-data-streamer';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.multivariate.FetchCSV
 * Via: UIModel annotation in the class hierarchy
 */
export interface _FetchCSV extends Computation._MultivariateComputation {
    type: "fetch_csv";
}

export type FetchCSV = _FetchCSV;

export namespace FetchCSV {
    /** Type of class com.dataiku.dip.eda.compute.computations.multivariate.FetchCSV in PolyJSON mappings */
    export const type = "fetch_csv";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.multivariate.FetchCSV$FetchCSVResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _FetchCSVResult extends _AvailableResult {
        type: "fetch_csv";
        dataStreamId: ComputationResultDataStreamer.DataStreamId;
    }
    
    export type FetchCSVResult = _FetchCSVResult;

    export namespace FetchCSVResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.multivariate.FetchCSV$FetchCSVResult in PolyJSON mappings */
        export const type = "fetch_csv";
    }
}