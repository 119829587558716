/** 
 * Translated from class com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams
 * Via: com.dataiku.dip.analysis.model.core.ResolvedPreprocessingParams
 */
export interface _FeatureGenerationParams {
    categoricals_count_transformer: FeatureGenerationParams.CategoricalsCountTransformerGenerator;
    manual_interactions: FeatureGenerationParams.ManualPairwiseInteractions;
    numericals_clustering: FeatureGenerationParams.NumericalsClusteringGenerator;
    pairwise_linear: FeatureGenerationParams.PairwiseLinearCombinations;
    polynomial_combinations: FeatureGenerationParams.PolynomialCombinations;
}

export type FeatureGenerationParams = _FeatureGenerationParams;

export namespace FeatureGenerationParams {
    /** 
     * Translated from class com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$AutomatizableFeatureGenerator
     * Via: com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$CategoricalsCountTransformerGenerator
     */
    export interface _AutomatizableFeatureGenerator {
        behavior: FeatureGenerationParams.GeneratorBehavior;
    }
    
    /**
        Translate from class com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$AutomatizableFeatureGenerator
        @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
    */
    export type AutomatizableFeatureGenerator = _AutomatizableFeatureGenerator;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$CategoricalsCountTransformerGenerator
     * Via: com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams
     */
    export interface _CategoricalsCountTransformerGenerator extends FeatureGenerationParams._AutomatizableFeatureGenerator {
        all_features: boolean;
        input_features: string[];
    }
    
    export type CategoricalsCountTransformerGenerator = _CategoricalsCountTransformerGenerator;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$FeatureInteraction
     * Via: com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$ManualPairwiseInteractions
     */
    export interface _FeatureInteraction {
        column_1: string;
        column_2: string;
        max_features: number;
        rescale: boolean;
    }
    
    export type FeatureInteraction = _FeatureInteraction;
    
    /** 
     * Translated from enum com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$GeneratorBehavior
     * Via: com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$AutomatizableFeatureGenerator
     */
    export enum GeneratorBehavior {
        DISABLED = "DISABLED",
        ENABLED_AUTOMATIC = "ENABLED_AUTOMATIC",
        ENABLED_MANUAL = "ENABLED_MANUAL",
    }
    /** 
     * Translated from class com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$ManualPairwiseInteractions
     * Via: com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams
     */
    export interface _ManualPairwiseInteractions {
        interactions: FeatureGenerationParams.FeatureInteraction[];
    }
    
    export type ManualPairwiseInteractions = _ManualPairwiseInteractions;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$NumericalsClusteringGenerator
     * Via: com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams
     */
    export interface _NumericalsClusteringGenerator extends FeatureGenerationParams._AutomatizableFeatureGenerator {
        all_features: boolean;
        input_features: string[];
        k: number;
        transformation_mode: FeatureGenerationParams.NumericalsClusteringGenerator.TransformationMode;
    }
    
    export type NumericalsClusteringGenerator = _NumericalsClusteringGenerator;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$PairwiseLinearCombinations
     * Via: com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams
     */
    export interface _PairwiseLinearCombinations extends FeatureGenerationParams._AutomatizableFeatureGenerator {
    }
    
    export type PairwiseLinearCombinations = _PairwiseLinearCombinations;
    
    /** 
     * Translated from class com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$PolynomialCombinations
     * Via: com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams
     */
    export interface _PolynomialCombinations extends FeatureGenerationParams._AutomatizableFeatureGenerator {
    }
    
    export type PolynomialCombinations = _PolynomialCombinations;

    export namespace NumericalsClusteringGenerator {
        /** 
         * Translated from enum com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$NumericalsClusteringGenerator$TransformationMode
         * Via: com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$NumericalsClusteringGenerator
         */
        export enum TransformationMode {
            REPLACE_BY_DISTANCE = "REPLACE_BY_DISTANCE",
            IMPACT_CODE_CLUSTERID = "IMPACT_CODE_CLUSTERID",
            DUMMIFY_CLUSTERID = "DUMMIFY_CLUSTERID",
        }
    }
}