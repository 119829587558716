import type { Abstract2DPivotCard } from './abstract2-dpivot-card';
import type { CardResult } from './../card-result';
import type { Filter } from './../../../compute/filtering/filter';
import type { ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _Abstract2DPivotCard } from './abstract2-dpivot-card';
import type { _CardResult } from './../card-result';
import type { _Filter } from './../../../compute/filtering/filter';
import type { _ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHistogramCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _BivariateHistogramCard extends _Abstract2DPivotCard {
    type: "bivariate_histogram";
    highlightFilter?: Filter | null;
}

export type BivariateHistogramCard = _BivariateHistogramCard;

export namespace BivariateHistogramCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHistogramCard in PolyJSON mappings */
    export const type = "bivariate_histogram";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHistogramCard$BivariateHistogramCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _BivariateHistogramCardResult extends _CardResult {
        type: "bivariate_histogram";
        histogram: BivariateHistogramCard.BivariateHistogramData;
    }
    
    export type BivariateHistogramCardResult = _BivariateHistogramCardResult;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHistogramCard$BivariateHistogramData
     * Via: com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHistogramCard$BivariateHistogramCardResult
     */
    export interface _BivariateHistogramData extends _ImmutableValueObject {
        counts: number[];
        highlightedCounts?: number[] | null;
        xBins: Filter[];
        yBins: Filter[];
    }
    
    export type BivariateHistogramData = _BivariateHistogramData;

    export namespace BivariateHistogramCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHistogramCard$BivariateHistogramCardResult in PolyJSON mappings */
        export const type = "bivariate_histogram";
    }
}