import type { OneSampleSignTestStat } from './one-sample-sign-test-stat';
import type { StatsRecipePayloadParams } from './../stats-recipe-payload-params';
import type { _OneSampleSignTestStat } from './one-sample-sign-test-stat';
import type { _StatsRecipePayloadParams } from './../stats-recipe-payload-params';

/** 
 * Translated from class com.dataiku.dip.recipes.eda.stats.OneSampleSignTestRecipePayloadParams
 * Via: UIModel annotation in the class hierarchy
 */
export interface _OneSampleSignTestRecipePayloadParams extends _StatsRecipePayloadParams<OneSampleSignTestStat> {
    type: "recipe_one-sample-sign-test";
}

export type OneSampleSignTestRecipePayloadParams = _OneSampleSignTestRecipePayloadParams;

export namespace OneSampleSignTestRecipePayloadParams {
    /** Type of class com.dataiku.dip.recipes.eda.stats.OneSampleSignTestRecipePayloadParams in PolyJSON mappings */
    export const type = "recipe_one-sample-sign-test";
}