import type { AbstractAPIServiceDeploymentManager } from './../deployments/abstract-apiservice-deployment-manager';
import type { BundleActivator } from './../../projects/importexport/bundle-activator';
import type { InfoMessage } from 'dku-frontend-core/generated-sources/dku-core/src/main/java/com/dataiku/dip/coremodel/info-message';

/** 
 * Translated from class com.dataiku.dip.apideployer.engine.DeploymentHooksReport
 * Via: UIModel annotation in the class hierarchy
 */
export interface _DeploymentHooksReport extends InfoMessage._InfoMessages {
    deploymentHookExecutionStatus: DeploymentHooksReport.HookExecutionStatus;
}

/** List of all concrete subclasses of com.dataiku.dip.apideployer.engine.DeploymentHooksReport */
export type DeploymentHooksReport =
    | AbstractAPIServiceDeploymentManager.SyncReport
    | _DeploymentHooksReport
    | BundleActivator.BundleActivationReport;

export namespace DeploymentHooksReport {
    /** 
     * Translated from class com.dataiku.dip.apideployer.engine.DeploymentHooksReport$HookExecutionStatus
     * Via: com.dataiku.dip.apideployer.engine.DeploymentHooksReport
     */
    export interface _HookExecutionStatus {
        deploymentStatus: DeploymentHooksReport.HookExecutionStatus.DeploymentStatus;
        deploymentStatusMessage: string;
        postHookResults: DeploymentHooksReport.HookResult[];
        postHooks: number;
        preHookResults: DeploymentHooksReport.HookResult[];
        preHooks: number;
    }
    
    export type HookExecutionStatus = _HookExecutionStatus;
    
    /** 
     * Translated from class com.dataiku.dip.apideployer.engine.DeploymentHooksReport$HookResult
     * Via: com.dataiku.dip.apideployer.engine.DeploymentHooksReport$HookExecutionStatus
     */
    export interface _HookResult {
        message: string;
        name: string;
        status: DeploymentHooksReport.HookResult.HookStatus;
    }
    
    export type HookResult = _HookResult;

    export namespace HookExecutionStatus {
        /** 
         * Translated from enum com.dataiku.dip.apideployer.engine.DeploymentHooksReport$HookExecutionStatus$DeploymentStatus
         * Via: com.dataiku.dip.apideployer.engine.DeploymentHooksReport$HookExecutionStatus
         */
        export enum DeploymentStatus {
            NO_HOOK = "NO_HOOK",
            SKIPPED = "SKIPPED",
            IN_PROGRESS = "IN_PROGRESS",
            FAILED = "FAILED",
        }
    }

    export namespace HookResult {
        /** 
         * Translated from enum com.dataiku.dip.apideployer.engine.DeploymentHooksReport$HookResult$HookStatus
         * Via: com.dataiku.dip.apideployer.engine.DeploymentHooksReport$HookResult
         */
        export enum HookStatus {
            SUCCESS = "SUCCESS",
            WARNING = "WARNING",
            ERROR = "ERROR",
            NOT_FINISHED = "NOT_FINISHED",
            INTERRUPTED = "INTERRUPTED",
            TIMED_OUT = "TIMED_OUT",
        }
    }
}