import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.Covariance
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Covariance extends Computation._BivariateComputation {
    type: "covariance";
}

export type Covariance = _Covariance;

export namespace Covariance {
    /** Type of class com.dataiku.dip.eda.compute.computations.bivariate.Covariance in PolyJSON mappings */
    export const type = "covariance";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.Covariance$CovarianceResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _CovarianceResult extends _AvailableResult {
        type: "covariance";
        value: number;
    }
    
    export type CovarianceResult = _CovarianceResult;

    export namespace CovarianceResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.bivariate.Covariance$CovarianceResult in PolyJSON mappings */
        export const type = "covariance";
    }
}