import { Injectable } from '@angular/core';
import { ChartZoomControlInstance } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class ChartZoomControlStoreService {
    protected zoomControlInstances: Record<string, ChartZoomControlInstance> = {};

    set(id: string, value: ChartZoomControlInstance) {
        this.zoomControlInstances[id] = value;
    }

    get(id: string) {
        return this.zoomControlInstances[id];
    }

    clear(id: string) {
        delete this.zoomControlInstances[id];
    }
}
