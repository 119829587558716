import type { FilesSelectionRule } from './files-selection-rule';
import type { _FilesSelectionRule } from './files-selection-rule';

/** 
 * Translated from class com.dataiku.dip.fs.FilesSelectionRules
 * Via: com.dataiku.dip.datasets.fs.AbstractFSDatasetHandler$AbstractFSConfig
 */
export interface _FilesSelectionRules {
    excludeRules: FilesSelectionRule[];
    explicitFiles: string[];
    includeRules: FilesSelectionRule[];
    mode: FilesSelectionRules.Mode;
}

export type FilesSelectionRules = _FilesSelectionRules;

export namespace FilesSelectionRules {
    /** 
     * Translated from enum com.dataiku.dip.fs.FilesSelectionRules$Mode
     * Via: com.dataiku.dip.fs.FilesSelectionRules
     */
    export enum Mode {
        ALL = "ALL",
        EXPLICIT_SELECT_FILES = "EXPLICIT_SELECT_FILES",
        RULES_ALL_BUT_EXCLUDED = "RULES_ALL_BUT_EXCLUDED",
        RULES_INCLUDED_ONLY = "RULES_INCLUDED_ONLY",
    }
}