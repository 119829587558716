import type { ITaggingService } from './../../server/services/itagging-service';

/** 
 * Translated from class com.dataiku.dip.timelines.workspace.WorkspaceTimelineItem
 * Via: com.dataiku.dip.timelines.workspace.WorkspaceTimeline
 */
export interface _WorkspaceTimelineItem {
    action: WorkspaceTimelineItem.WorkspaceActionType;
    details: { [key in string]: any/* com.google.gson.JsonElement */ };
    objectId: string;
    objectType: ITaggingService.TaggableType;
    projectKey: string;
    time: number;
    user: string;
    workspaceKey: string;
}

export type WorkspaceTimelineItem = _WorkspaceTimelineItem;

export namespace WorkspaceTimelineItem {
    /** 
     * Translated from enum com.dataiku.dip.timelines.workspace.WorkspaceTimelineItem$WorkspaceActionType
     * Via: com.dataiku.dip.timelines.workspace.WorkspaceTimelineItem
     */
    export enum WorkspaceActionType {
        WORKSPACE_CREATE = "WORKSPACE_CREATE",
        WORKSPACE_USER_ADD = "WORKSPACE_USER_ADD",
        WORKSPACE_USER_REMOVE = "WORKSPACE_USER_REMOVE",
        WORKSPACE_OBJECT_ADD = "WORKSPACE_OBJECT_ADD",
        WORKSPACE_OBJECT_REMOVE = "WORKSPACE_OBJECT_REMOVE",
        WORKSPACE_OBJECT_DELETE = "WORKSPACE_OBJECT_DELETE",
        WORKSPACE_UNKNOWN = "WORKSPACE_UNKNOWN",
    }
}