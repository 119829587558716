import { Injectable } from '@angular/core';
import { Aggregation } from '@model-main/pivot/backend/model/aggregation';
import { ChartType } from '@model-main/pivot/frontend/model/chart-type';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { AggregationFunctionLabels, ChartLabels } from '../enums';
import { FrontendChartDef, FrontendDimensionDef, FrontendMeasureDef } from '../interfaces';
import { ChartFormattingService, NumberFormatterService } from './formatting';
import { ChartStaticDataService } from './chart-static-data.service';
import { ChartUADimensionService } from './chart-ua-dimension.service';
import { ChartFilterUtilsService } from './chart-filter-utils.service';
import { AxisDef } from '@model-main/pivot/backend/model/axis-def';

@Injectable({
    providedIn: 'root'
})
/**
 * Set of helpers to compute labels to be used in the chart inteface.
 * (!) This service previously was in:
 * - static/dataiku/js/simple_report/services/chart-labels.service.js
 * - static/dataiku/js/simple_report/chart_view_commons.js
 */
export class ChartLabelsService {

    constructor(
        private chartStaticDataService: ChartStaticDataService,
        private numberFormatterService: NumberFormatterService,
        private chartUADimensionService: ChartUADimensionService,
        private chartFilterUtilsService: ChartFilterUtilsService,
        private chartFormattingService: ChartFormattingService
    ) {
    }

    SCOPE_LABELS = {
        IMPACTS_TOOLTIPS: 'Impacts tooltips',
        IMPACTS_CELL_CONTENT: 'Impacts cell content',
        IMPACTS_CELL_CONTENT_AND_TOOLTIPS: 'Impacts cell content and tooltips',
        IMPACTS_VALUES_DISPLAYED_AND_TOOLTIPS: 'Impacts values displayed in chart and tooltips',
        LEGEND_SUFFIX: ' and legend',
        VALUES_DISPLAYED_SUFFIX: 'values displayed in charts'
    };

    private getMeasureLabelByAggregationFunction = (aggregationFunction: Aggregation.Function, type: 'short' | 'long', columnLabel: string) => {

        let countLabel = 'Count of records';

        type LabelsByAggregationFunction = Record<Aggregation.Function, { short: string, long: string }>;

        const labelsByAggregationFunction = <LabelsByAggregationFunction>{};

        if (columnLabel) {
            countLabel = `Count of ${columnLabel}`;

            const countDLabel = `Count distinct of ${columnLabel}`;

            labelsByAggregationFunction.COUNTD = { short: countDLabel, long: countDLabel };
            labelsByAggregationFunction.SUM = { short: `Sum of ${columnLabel}`, long: `Sum of ${columnLabel}` };
            labelsByAggregationFunction.AVG = { short: `Avg of ${columnLabel}`, long: `Average of ${columnLabel}` };
            labelsByAggregationFunction.MIN = { short: `Min of ${columnLabel}`, long: `Minimum of ${columnLabel}` };
            labelsByAggregationFunction.MAX = { short: `Max of ${columnLabel}`, long: `Maximum of ${columnLabel}` };
            labelsByAggregationFunction.CUSTOM = { short: `${columnLabel}`, long: `${columnLabel}` };
        }

        labelsByAggregationFunction.COUNT = { short: countLabel, long: countLabel };

        return labelsByAggregationFunction[aggregationFunction][type] || null;
    };

    /**
     * @param   { com.dataiku.dip.pivot.frontend.model.FrontendMeasureDef }    measure               The measure from which to create a label.
     * @param   { 'short' | 'long' }                                   type                  The type of label (short or long).
     * @param   { boolean }                                            canUseDisplayLabel    Whether if the display label can be used if defined or not.
     * @returns { string | null }
     */
    private getMeasureLabel = (measure: FrontendMeasureDef | undefined | null, type: 'short' | 'long' = 'long', canUseDisplayLabel: boolean) => {
        if (!measure) {
            return null;
        }

        if (canUseDisplayLabel && measure.displayLabel && measure.displayLabel.length) {
            return measure.displayLabel;
        }

        if (!measure.function) {
            return measure.column;
        }

        return this.getMeasureLabelByAggregationFunction(measure.function, type, measure && measure.column);
    };

    private isMeasureLabelDisplayedInLegend = (chartDef: FrontendChartDef, origin: string, colorDimensionOrMeasure: any) => {

        let canMeasureLabelBeDisplayedInLegend;

        switch (origin) {
            case 'color':
            case 'size':
                return false; // A color or a size measure will be displayed as number

            default: // For standard charts, measures can be displayed in legends when there's no color dimension set
                canMeasureLabelBeDisplayedInLegend = [ChartType.grouped_columns, ChartType.stacked_bars, ChartType.multi_columns_lines,
                    ChartType.lines, ChartType.stacked_area].includes(chartDef.type);

                return canMeasureLabelBeDisplayedInLegend && colorDimensionOrMeasure === undefined;
        }
    };

    getUaLabel = (ua: any) => {
        if (ua.displayLabel && ua.displayLabel.length) {
            return ua.displayLabel;
        }

        if (this.chartUADimensionService.isDiscreteDate(ua)) {
            let suffix = '';
            const filterType = this.chartUADimensionService.getDateModes().find(({ value }) => value === ua.dateMode)?.label;
            if (filterType) {
                suffix = ` (${filterType.toLowerCase()})`;
            }
            return ua.column + suffix;
        }
        return ua.column;
    };

    getShortMeasureLabel = (measure: FrontendMeasureDef | undefined | null, canUseDisplayLabel = true) => {
        return this.getMeasureLabel(measure, 'short', canUseDisplayLabel);
    };

    getLongMeasureLabel = (measure: FrontendMeasureDef | undefined | null, canUseDisplayLabel = true): string | null => {
        return this.getMeasureLabel(measure, 'long', canUseDisplayLabel);
    };

    getDimensionLabel = (dimension: FrontendDimensionDef, canUseDisplayLabel = true) => {
        if (!dimension) {
            return null;
        }
        if (canUseDisplayLabel && dimension.displayLabel && dimension.displayLabel.length) {
            return dimension.displayLabel;
        }
        return dimension.column;
    };

    getAggregationFunctionDescription = (measure: Aggregation) => {
        if (measure.type == AxisDef.Type.CUSTOM) {
            return 'AGG';
        } else {
            return measure.function;
        }
    };

    /**
     * @param   {ChartDef}              chartDef
     * @param   {'color' | 'tooltip'}   [origin]  - To specify if we're formatting a color measure or a measure coming from a tooltip.
     * @returns {string}                - A sentence explaining which area (legend, tooltip, cell content...) will be affected by the formatting.
     */
    getMeasureNumberFormattingScopeLabel = (chartDef: FrontendChartDef, origin: 'color' | 'tooltip', canDisplayValuesinChart = false) => {
        let label;
        if (chartDef.type === ChartType.kpi) {
            label = '';
        } else if (origin === 'tooltip') {
            label = this.SCOPE_LABELS.IMPACTS_TOOLTIPS;
        } else if (chartDef.type === ChartType.pivot_table) {
            if (origin !== 'color') {
                label = this.SCOPE_LABELS.IMPACTS_CELL_CONTENT;
            } else {
                label = this.SCOPE_LABELS.IMPACTS_TOOLTIPS;
            }
        } else {
            label = this.SCOPE_LABELS.IMPACTS_TOOLTIPS;

            if (chartDef.type === ChartType.treemap && origin === 'color') {
                label += this.SCOPE_LABELS.LEGEND_SUFFIX;
            } else {
                const impactsValueInChart = canDisplayValuesinChart && chartDef.showInChartValues;

                if (impactsValueInChart) {
                    label += ' and ' + this.SCOPE_LABELS.VALUES_DISPLAYED_SUFFIX;
                }

                if (origin === 'color') {
                    label += this.SCOPE_LABELS.LEGEND_SUFFIX;
                }
            }
        }

        return label;
    };

    /**
     * @param {ChartDef}                chartDef
     * @param {'color' | 'tooltip'}     [origin]  - To specify if we're formatting a color measure or a measure coming from a tooltip.
     */
    getMeasureLabelRealiasingScopeLabel = (chartDef: FrontendChartDef, origin: string, colorDimensionOrMeasure: any) => {
        let label;

        if (chartDef.type === ChartType.kpi) {
            label = '';
        } else if (origin === 'tooltip') {
            label = this.SCOPE_LABELS.IMPACTS_TOOLTIPS;
        } else if (chartDef.type === ChartType.pivot_table) {
            if (origin != 'color') {
                label = this.SCOPE_LABELS.IMPACTS_CELL_CONTENT;
            } else {
                label = this.SCOPE_LABELS.IMPACTS_TOOLTIPS;
            }
        } else {
            label = this.SCOPE_LABELS.IMPACTS_TOOLTIPS;

            const impactsLegend = this.isMeasureLabelDisplayedInLegend(chartDef, origin, colorDimensionOrMeasure);

            if (impactsLegend) {
                label += this.SCOPE_LABELS.LEGEND_SUFFIX;
            }
        }

        return label;
    };

    /**
     * @param   {ChartDef}              chartDef
     * @param   {'color'}               [origin]  -  To specify if we're formatting a color or not
     * @returns {string}                - A sentence explaining which area (legend, tooltip, cell content...) will be affected by the formatting.
     */
    getDimensionNumberFormattingScopeLabel = (chartDef: FrontendChartDef, origin: string) => {
        let label;

        if (chartDef.type === ChartType.pivot_table) {
            label = this.SCOPE_LABELS.IMPACTS_CELL_CONTENT_AND_TOOLTIPS;
        } else if (chartDef.type === ChartType.treemap) {
            label = this.SCOPE_LABELS.IMPACTS_VALUES_DISPLAYED_AND_TOOLTIPS;
        } else if (chartDef.type === ChartType.pie) {
            label = this.SCOPE_LABELS.IMPACTS_TOOLTIPS;

            if (chartDef.showInChartLabels) {
                label += ', ' + this.SCOPE_LABELS.VALUES_DISPLAYED_SUFFIX;
            }

            label += this.SCOPE_LABELS.LEGEND_SUFFIX;
        } else {
            label = this.SCOPE_LABELS.IMPACTS_TOOLTIPS;

            if (origin === 'color') { // Numerical color dimension values will be displayed in the legend.
                label += this.SCOPE_LABELS.LEGEND_SUFFIX;
            }
        }
        return label;
    };

    /**
     * @param   {ChartDef}              chartDef
     * @returns {string}                - A sentence explaining which area (legend, tooltip, cell content...) will be affected by the formatting.
     */
    getDimensionLabelRealiasingScopeLabel = (chartDef: FrontendChartDef) => {
        let label;
        if (chartDef.type === ChartType.pivot_table) {
            label = this.SCOPE_LABELS.IMPACTS_CELL_CONTENT_AND_TOOLTIPS;
        } else {
            label = this.SCOPE_LABELS.IMPACTS_TOOLTIPS;
        }
        return label;
    };

    getAvailableAggregationsLabels = (aggregations: Array<keyof typeof AggregationFunctionLabels>) => {
        return aggregations.reduce((acc, aggregation) => {
            if (!acc[aggregation]) {
                acc[aggregation] = AggregationFunctionLabels[aggregation] || aggregation;
            }

            return acc;
        }, {} as Record<keyof typeof AggregationFunctionLabels, AggregationFunctionLabels>);
    };

    /**
     * @param {Object} input                                the label object from the backend.
     * @param {FormattingOptions} [formattingOptions]       formattingOptions the number formatting options.
     * @param {number} [minValue]                           the minimum value.
     * @param {number} [maxValue]                           the maximum value.
     * @param {number} [numValues]                          the number of values.
     */
    getFormattedLabel = (input: any, formattingOptions?: any, minValue?: any, maxValue?: any, numValues?: any) => {
        if (!input) {
            return null;
        }
        if (input.label === ChartLabels.NO_VALUE) {
            return 'No value';
        }
        /**
         * If we have a tsValue, we know for sure that we are formatting a date.
         * If we have no min, no max and no tsValue we are formatting a non-numerical value.
         */
        if (input.tsValue != null || (input.min === undefined && input.max === undefined && input.sortValue === undefined)) {
            return input.label;
        }
        // If the min and max are equals, we are formatting a single value.
        if (input.min === input.max) {
            return this.numberFormatterService.getForRange(minValue, maxValue, numValues, true, true, formattingOptions)(input.sortValue);
        }
        // Otherwise we are formatting an extent.
        return this.chartFormattingService.getForRangeThenJoin(minValue, maxValue, numValues, formattingOptions)([input.min, input.max]);
    };

    getKpiLabelPositions = (): [string, string] => {
        return [this.chartStaticDataService.LABEL_POSITIONS.TOP, this.chartStaticDataService.LABEL_POSITIONS.BOTTOM];
    };
}
