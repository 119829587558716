import { UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

/*
    Validate that at least { min } checkboxes are checked given a set of checkbox types (ids)
*/
export function minCheckedValidator(checkboxTypes: string[], min = 1): ValidatorFn {
    return function validate(formGroup: AbstractControl) {
        let checked = 0;

        checkboxTypes.forEach(checkbox => {
            const control = (formGroup as UntypedFormGroup).controls[checkbox];
            if (control.value && control.enabled) {
                checked++;
            }
        });

        return checked < min ? {
            minNotChecked: true
        } : null;
    };
}
