import type { ColumnSummary } from './column-summary';
import type { FilterFacet } from './filter-facet';
import type { PT2DDensityResponse } from './density/pt2-ddensity-response';
import type { PTBoxplotsResponse } from './boxplots/ptboxplots-response';
import type { PTMapAggrGridResponse } from './maps/ptmap-aggr-grid-response';
import type { PTMapAggrResponse } from './maps/ptmap-aggr-response';
import type { PTMapRawGeometryResponse } from './maps/ptmap-raw-geometry-response';
import type { PTMapScatterResponse } from './maps/ptmap-scatter-response';
import type { PTScatterResponse } from './scatter/ptscatter-response';
import type { PivotTableFiltersResponse } from './maps/pivot-table-filters-response';
import type { PivotTableTensorResponse } from './pivot-table-tensor-response';
import type { PivotTableWebappResponse } from './maps/pivot-table-webapp-response';
import type { SampleMetadata } from './../../../shaker/server/sample-metadata';
import type { _ColumnSummary } from './column-summary';
import type { _FilterFacet } from './filter-facet';
import type { _PT2DDensityResponse } from './density/pt2-ddensity-response';
import type { _PTBoxplotsResponse } from './boxplots/ptboxplots-response';
import type { _PTMapAggrGridResponse } from './maps/ptmap-aggr-grid-response';
import type { _PTMapAggrResponse } from './maps/ptmap-aggr-response';
import type { _PTMapRawGeometryResponse } from './maps/ptmap-raw-geometry-response';
import type { _PTMapScatterResponse } from './maps/ptmap-scatter-response';
import type { _PTScatterResponse } from './scatter/ptscatter-response';
import type { _PivotTableFiltersResponse } from './maps/pivot-table-filters-response';
import type { _PivotTableTensorResponse } from './pivot-table-tensor-response';
import type { _PivotTableWebappResponse } from './maps/pivot-table-webapp-response';
import type { _SampleMetadata } from './../../../shaker/server/sample-metadata';

/** 
 * Translated from class com.dataiku.dip.pivot.backend.model.PivotTableResponse
 * Via: UIModel annotation in the class hierarchy
 */
export interface _PivotTableResponse {
    afterFilterRecords: number;
    beforeFilterRecords: number;
    columnsSummary: ColumnSummary;
    engine: PivotTableResponse.PivotEngine;
    filterFacets: FilterFacet[];
    sampleMetadata: SampleMetadata;
}

/** List of all concrete subclasses of com.dataiku.dip.pivot.backend.model.PivotTableResponse */
export type PivotTableResponse =
    | PivotTableTensorResponse
    | PTBoxplotsResponse
    | PT2DDensityResponse
    | PTMapAggrGridResponse
    | PTMapAggrResponse
    | PTMapRawGeometryResponse
    | PTMapScatterResponse
    | PivotTableFiltersResponse
    | PivotTableWebappResponse
    | PTScatterResponse;

export namespace PivotTableResponse {
    /** 
     * Translated from enum com.dataiku.dip.pivot.backend.model.PivotTableResponse$PivotEngine
     * Via: com.dataiku.dip.pivot.backend.model.PivotTableResponse
     */
    export enum PivotEngine {
        SQL = "SQL",
        LINO = "LINO",
    }
}