import { Injectable } from '@angular/core';
import { WT1Service } from 'dku-frontend-core';
import { APIError, catchAPIError, ErrorContext } from '@core/dataiku-api/api-error';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { Workspace } from '@model-main/workspaces/workspace';
import { WorkspaceTimeline } from '@model-main/timelines/workspace/workspace-timeline';
import { WorkspaceObjectKey } from '@shared/models';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { distinctUntilChanged, withLatestFrom, tap, map, switchMap } from 'rxjs/operators';
import { WorkspacesError, WorkspaceSummary } from '../../models';
import { WorkspacesNavigationService } from '../workspace-navigation.service';
import { WorkspaceDisplayService } from '../workspace-display.service';

@Injectable({
    providedIn: 'root'
})
export class WorkspacesService implements ErrorContext {

    private workspaces$ = new BehaviorSubject<Workspace[]>([]);
    private currentWorkspace$ = new BehaviorSubject<Workspace | undefined>(undefined);
    private currentTimeline$ = new BehaviorSubject<WorkspaceTimeline | undefined>(undefined);
    private currentObject$ = new BehaviorSubject<Workspace.WorkspaceObject | undefined>(undefined);
    private error$ = new BehaviorSubject<APIError | undefined>(undefined);

    constructor(
        private dataikuAPI: DataikuAPIService,
        private workspacesNavigation: WorkspacesNavigationService,
        private workspaceDisplayService: WorkspaceDisplayService,
        private WT1: WT1Service,
    ) {
    }

    pushError(error?: APIError): void {
        this.error$.next(error);
    }

    getError(): WorkspacesError {
        return {
            error$: this.error$.asObservable().pipe(distinctUntilChanged()),
            resetError: () => this.pushError()
        };
    }

    getWorkspaces(): Observable<Workspace[]> {
        return this.workspaces$;
    }

    getCurrentWorkspace(): Observable<Workspace | undefined> {
        return this.currentWorkspace$.asObservable();
    }

    getCurrentTimeline(): Observable<WorkspaceTimeline | undefined> {
        return this.currentTimeline$.asObservable();
    }

    getCurrentObject(): Observable<Workspace.WorkspaceObject | undefined> {
        return this.currentObject$.asObservable();
    }

    getCurrentObjectSnapshot(): Workspace.WorkspaceObject | undefined {
        return this.currentObject$.getValue();
    }

    updateCurrentWorkspace(workspace: Workspace | undefined): void {
        if (workspace && workspace.workspaceKey !== this.currentWorkspace$.value?.workspaceKey) {
            this.WT1.event('workspaces-open-workspace', { switchWorkspace: !!this.currentWorkspace$.value });
        }
        this.currentWorkspace$.next(workspace);
    }

    setCurrentObject(object: Workspace.WorkspaceObject | undefined): void {
        if (object && object !== this.currentObject$.value) {
            this.WT1.event('workspaces-open-object' , { objectType: this.workspaceDisplayService.getObjectType(object) });
        }
        this.currentObject$.next(object);
    }

    fetchWorkspaces(): Observable<Workspace[]> {
        return this.dataikuAPI.workspaces.list().pipe(
            tap(workspaces => this.workspaces$.next(workspaces)),
            catchAPIError(this)
        );
    }

    getWorkspace(workspaceKey: string): Observable<WorkspaceSummary> {
        return this.dataikuAPI.workspaces.get(workspaceKey).pipe(catchAPIError(this));
    }

    fetchWorkspace(workspaceKey: string): Observable<WorkspaceSummary> {
        return this.getWorkspace(workspaceKey).pipe(
            tap(({ workspace, timeline }) => {
                this.currentWorkspace$.next(workspace);
                this.currentTimeline$.next(timeline);
                this.updateCurrentWorkspace(workspace);
            })
        );
    }

    private createWorkspace(workspace: Workspace): Observable<WorkspaceSummary> {
        return this.dataikuAPI.workspaces.create(workspace)
            .pipe(
                switchMap((updatedWorkspace) => this.getWorkspace(updatedWorkspace.workspaceKey)),
                withLatestFrom(this.workspaces$),
                tap(([summary, workspaces]) => this.workspaces$.next([...workspaces, summary.workspace].sort((ws1, ws2) => this.sortWorkspaces(ws1, ws2)))),
                map(([summary]) => summary),
                catchAPIError(this)
            );
    }

    private sortWorkspaces(ws1: Workspace, ws2: Workspace) {
        if (ws1.workspaceKey < ws2.workspaceKey) {
            return -1;
        }
        if (ws1.workspaceKey > ws2.workspaceKey) {
            return 1;
        }
        return 0;
    }

    createAndGoToWorkspace(workspace: Workspace): Observable<WorkspaceSummary> {
        return this.createWorkspace(workspace)
            .pipe(
                tap(({ workspace, timeline }) => {
                    this.currentWorkspace$.next(workspace);
                    this.currentTimeline$.next(timeline);
                    this.updateCurrentWorkspace(workspace);
                    this.workspacesNavigation.goToWorkspace(workspace.workspaceKey);
                })
            );
    }

    updateWorkspace(workspace: Workspace): Observable<WorkspaceSummary> {
        return this.dataikuAPI.workspaces.update(workspace)
            .pipe(
                switchMap((updatedWorkspace) => this.fetchWorkspace(updatedWorkspace.workspaceKey)),
                withLatestFrom(this.workspaces$),
                tap(([summary, workspaces]) => {
                    this.workspaces$.next(workspaces.map(workspace => workspace.workspaceKey === summary.workspace.workspaceKey ? summary.workspace : workspace));
                    this.currentWorkspace$.next(summary.workspace);
                    this.currentTimeline$.next(summary.timeline);
                    this.updateCurrentWorkspace(summary.workspace);
                }),
                map(([summary]) => summary),
                catchAPIError(this)
            );
    }

    deleteWorkspace(workspaceKey: string): Observable<void> {
        return this.dataikuAPI.workspaces.delete(workspaceKey).pipe(
            withLatestFrom(this.workspaces$, this.currentWorkspace$),
            tap(([_, workspaces, currentWorkspace]) => {
                this.workspaces$.next(workspaces.filter(workspace => workspace.workspaceKey !== workspaceKey));
                if (currentWorkspace?.workspaceKey === workspaceKey) {
                    this.currentTimeline$.next(undefined);
                    this.updateCurrentWorkspace(undefined);
                    this.workspacesNavigation.goToHome();
                }
            }),
            map(() => {
                return;
            }),
            catchAPIError(this)
        );
    }

    addWorkspaceObjects(workspace: Workspace, objects: Workspace.WorkspaceObject[]): Observable<WorkspaceSummary> {
        return this.dataikuAPI.workspaces.addObjects(workspace.workspaceKey, objects).pipe(
            switchMap(workspace => this.fetchWorkspace(workspace.workspaceKey)),
            catchAPIError(this)
        );
    }

    duplicateWorkspaceObjects(workspace: Workspace, objects: Workspace.WorkspaceObject[]): Observable<WorkspaceSummary> {
        const objectsToDuplicate = objects.map(object => this.assignDuplicatedStoryTitle(workspace, object));
        return this.dataikuAPI.workspaces.duplicateObjects(workspace.workspaceKey, objectsToDuplicate).pipe(
            switchMap(workspace => this.fetchWorkspace(workspace.workspaceKey)),
            catchAPIError(this)
        );
    }

    removeWorkspaceObject(workspace: Workspace, object: Workspace.WorkspaceObject): Observable<WorkspaceSummary> {
        return this.dataikuAPI.workspaces.removeObjects(workspace.workspaceKey, [ object ]).pipe(
            switchMap(workspace => this.fetchWorkspace(workspace.workspaceKey)),
            withLatestFrom(this.currentObject$),
            tap(([_, currentObject]) => {
                if (currentObject) {
                    this.setCurrentObject(undefined);
                    this.workspacesNavigation.goToWorkspace(workspace.workspaceKey);
                }
            }),
            map(([workspace]) => workspace),
            catchAPIError(this)
        );
    }

    fetchWorkspaceObject(workspaceKey: string, objectKey: WorkspaceObjectKey): Observable<Workspace.WorkspaceObject> {
        return forkJoin([
            this.fetchWorkspace(workspaceKey),
            this.dataikuAPI.workspaces.getObject(workspaceKey, objectKey)
        ]).pipe(
            tap(([_, { object }]) => this.setCurrentObject(object)),
            map(([_, { object }]) => object),
            catchAPIError(this)
        );
    }

    updateWorkspaceObject(workspaceKey: string, object: Workspace.WorkspaceObject): Observable<WorkspaceSummary> {
        return this.dataikuAPI.workspaces.updateObject(workspaceKey, object).pipe(
            switchMap(workspace => this.fetchWorkspace(workspace.workspace.workspaceKey)),
            catchAPIError(this)
            );
    }

    fetchThumbnail(workspaceKey: string, objectId: string): Observable<string> {
        return this.dataikuAPI.workspaces.thumbnail(workspaceKey, objectId).pipe(catchAPIError(this));
    }

    private assignDuplicatedStoryTitle(workspace: Workspace, object: Workspace.WorkspaceObject): Workspace.WorkspaceObject {
        if (object.story) {
            const baseTitle = `${object.story.title} - Copy`;
            let title = baseTitle;
            for (let index = 2; workspace.workspaceObjects.some(o => o.story?.title === title); index++) {
                title = `${baseTitle} ${index}`;
            }
            return {
                ...object,
                displayName: title,
                story: {
                    ...object.story,
                    title,
                }
            };
        }
        return object;
    }

}
