import type { AbstractNSampTestCard } from './common/abstract-nsamp-test-card';
import type { AbstractPairwiseTestCard } from './common/abstract-pairwise-test-card';
import type { _AbstractPairwiseTestCard } from './common/abstract-pairwise-test-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.PairwiseMoodTestCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _PairwiseMoodTestCard extends _AbstractPairwiseTestCard {
    type: "pairwise_mood";
}

export type PairwiseMoodTestCard = _PairwiseMoodTestCard;

export namespace PairwiseMoodTestCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.PairwiseMoodTestCard in PolyJSON mappings */
    export const type = "pairwise_mood";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.PairwiseMoodTestCard$PairwiseMoodTestCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _PairwiseMoodTestCardResult extends AbstractNSampTestCard._AbstractNSampleTestCardResult {
        type: "pairwise_mood";
        pvalues: number[];
        statistics: number[];
    }
    
    export type PairwiseMoodTestCardResult = _PairwiseMoodTestCardResult;

    export namespace PairwiseMoodTestCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.PairwiseMoodTestCard$PairwiseMoodTestCardResult in PolyJSON mappings */
        export const type = "pairwise_mood";
    }
}