import type { ProbeConfiguration } from './probe-configuration';
import type { ProbeMetadata } from './probe-metadata';
import type { _ProbeConfiguration } from './probe-configuration';
import type { _ProbeMetadata } from './probe-metadata';

/** 
 * Translated from class com.dataiku.dip.metrics.probes.Probe
 * Via: com.dataiku.dip.metrics.ProbesSet
 */
export interface _Probe {
    computeOnBuildMode: Probe.ComputeOnBuildMode;
    configuration: ProbeConfiguration;
    enabled: boolean;
    meta: ProbeMetadata;
    type: string;
}

export type Probe = _Probe;

export namespace Probe {
    /** 
     * Translated from enum com.dataiku.dip.metrics.probes.Probe$ComputeOnBuildMode
     * Via: com.dataiku.dip.metrics.probes.Probe
     */
    export enum ComputeOnBuildMode {
        NO = "NO",
        PARTITION = "PARTITION",
        WHOLE_DATASET = "WHOLE_DATASET",
        BOTH = "BOTH",
    }
}