import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { WorkspaceObjectKey } from '@shared/models';
import { Workspace } from '@model-main/workspaces/workspace';
import { WorkspaceTimeline } from '@model-main/timelines/workspace/workspace-timeline';

@Injectable({ providedIn: 'root' })
export class PrivateWorkspacesAPI {

    constructor(private dkuHttp: DkuHttpService) { }

    list(accessLevel = "READ"): Observable<Workspace[]> {
        return this.dkuHttp.request('GET', '/workspaces/list', { accessLevel }, false);
    }

    get(workspaceKey: string): Observable<{workspace: Workspace, timeline: WorkspaceTimeline}> {
        return this.dkuHttp.request('GET', '/workspaces/get', { workspaceKey }, false);
    }

    create(workspace: Workspace): Observable<Workspace> {
        return this.dkuHttp.request('POST', '/workspaces/create', { workspace }, false);
    }

    update(workspace: Workspace): Observable<Workspace> {
        return this.dkuHttp.request('PUT', '/workspaces/update', { workspace }, false);
    }

    save(workspace: Workspace): Observable<Workspace> {
        return this.dkuHttp.request('POST', '/workspaces/save', { workspace }, false);
    }

    delete(workspaceKey: string): Observable<void> {
        return this.dkuHttp.request('POST', '/workspaces/delete', { workspaceKey }, false);
    }

    getObject(workspaceKey: string, objectKey: WorkspaceObjectKey): Observable<{object: Workspace.WorkspaceObject, workspace: Workspace}> {
        return this.dkuHttp.request('GET', '/workspaces/get-object', { workspaceKey, objectKey }, false);
    }

    updateObject(workspaceKey: string, object: Workspace.WorkspaceObject): Observable<{object: Workspace.WorkspaceObject, workspace: Workspace}> {
        return this.dkuHttp.request('PUT', '/workspaces/update-object', { workspaceKey, object });
    }

    addObjects(workspaceKey: string, workspaceObjects: Workspace.WorkspaceObject[]): Observable<Workspace> {
        return this.dkuHttp.request('POST', '/workspaces/add-objects', { workspaceKey, workspaceObjects }, false);
    }

    duplicateObjects(workspaceKey: string, objectsToDuplicate: Workspace.WorkspaceObject[]): Observable<Workspace> {
        return this.dkuHttp.request('POST', '/workspaces/duplicate-objects', { workspaceKey, objectsToDuplicate }, false);
    }

    removeObjects(workspaceKey: string, objectsToRemove: Workspace.WorkspaceObject[]): Observable<Workspace> {
        return this.dkuHttp.request('POST', '/workspaces/remove-objects', { workspaceKey, objectsToRemove }, false);
    }

    timeline(workspaceKey: string): Observable<WorkspaceTimeline> {
        return this.dkuHttp.request('GET', '/workspaces/timeline', { workspaceKey }, false);
    }

    thumbnail(workspaceKey: string, objectId: string): Observable<string> {
        return this.dkuHttp.request('GET', `/workspaces/${workspaceKey}/thumbnail/${objectId}`, undefined, false);
    }
}
