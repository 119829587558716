/** 
 * Translated from class com.dataiku.scoring.models.overrides.MLOverridesParamsBase
 * Via: com.dataiku.dip.analysis.model.prediction.overrides.MLOverridesParams
 */
export interface _MLOverridesParamsBase<T = any> {
    overrides: MLOverridesParamsBase._MLOverride<T>[];
}

/**
    Translate from class com.dataiku.scoring.models.overrides.MLOverridesParamsBase
    @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
*/
export type MLOverridesParamsBase = _MLOverridesParamsBase;

export namespace MLOverridesParamsBase {
    /** 
     * Translated from class com.dataiku.scoring.models.overrides.MLOverridesParamsBase$MLOverride
     * Via: com.dataiku.scoring.models.overrides.MLOverridesParamsBase
     */
    export interface _MLOverride<T = any> {
        filter: T;
        name: string;
        outcome: MLOverridesParamsBase.MLOverride.Outcome;
    }
    
    export type MLOverride = _MLOverride;

    export namespace MLOverride {
        /** 
         * Translated from class com.dataiku.scoring.models.overrides.MLOverridesParamsBase$MLOverride$Outcome
         * Via: com.dataiku.scoring.models.overrides.MLOverridesParamsBase$MLOverride
         */
        export interface _Outcome {
            category: string;
            maxValue: number;
            minValue: number;
            type: MLOverridesParamsBase.MLOverride.Outcome.Type;
        }
        
        export type Outcome = _Outcome;

        export namespace Outcome {
            /** 
             * Translated from enum com.dataiku.scoring.models.overrides.MLOverridesParamsBase$MLOverride$Outcome$Type
             * Via: com.dataiku.scoring.models.overrides.MLOverridesParamsBase$MLOverride$Outcome
             */
            export enum Type {
                INTERVAL = "INTERVAL",
                CATEGORY = "CATEGORY",
            }
        }
    }
}