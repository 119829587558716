import type { Card } from './../cards/card';
import type { CardResult } from './../cards/card-result';
import type { Computation } from './../../compute/computations/computation';
import type { ComputationResult } from './../../compute/computations/computation-result';
import type { InteractiveQuery } from './interactive-query';
import type { _Card } from './../cards/card';
import type { _CardResult } from './../cards/card-result';
import type { _Computation } from './../../compute/computations/computation';
import type { _ComputationResult } from './../../compute/computations/computation-result';
import type { _InteractiveQuery } from './interactive-query';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.queries.DebugCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _DebugCard extends _InteractiveQuery {
    type: "debug_card";
    card: Card;
}

export type DebugCard = _DebugCard;

export namespace DebugCard {
    /** Type of class com.dataiku.dip.eda.worksheets.queries.DebugCard in PolyJSON mappings */
    export const type = "debug_card";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.queries.DebugCard$DebugCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _DebugCardResult extends InteractiveQuery._InteractiveQueryResult {
        type: "debug_card";
        card: Card;
        cardResult: CardResult;
        computation: Computation;
        computationResult: ComputationResult;
        estimatedCacheUsageFactorized: number;
        estimatedCacheUsageFlattened: number;
    }
    
    export type DebugCardResult = _DebugCardResult;

    export namespace DebugCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.queries.DebugCard$DebugCardResult in PolyJSON mappings */
        export const type = "debug_card";
    }
}