import type { ImageLabelingTask } from './image-labeling-task';
import type { SimpleKeyValue } from 'dku-frontend-core/generated-sources/dku-core/src/main/java/com/dataiku/dip/coremodel/simple-key-value';
import type { TaggableObjectsService } from './../server/services/taggable-objects-service';
import type { TextLabelingTask } from './text-labeling-task';
import type { _ImageLabelingTask } from './image-labeling-task';
import type { _SimpleKeyValue } from 'dku-frontend-core/generated-sources/dku-core/src/main/java/com/dataiku/dip/coremodel/simple-key-value';
import type { _TextLabelingTask } from './text-labeling-task';

/** 
 * Translated from class com.dataiku.dip.labeling.LabelingTask
 * Via: UIModel annotation in the class hierarchy
 */
export interface _LabelingTask extends TaggableObjectsService._TaggableObject {
    autoValidateAnswers: boolean;
    classes: SimpleKeyValue[];
    consensusStrategy: LabelingTask.ConsensusStrategy;
    extraColumns: string[];
    id: string;
    idColumn?: string | null;
    inputs: { [key in string]: LabelingTask.InputRole };
    instructions: string;
    labelColumnName: string;
    minNbAnnotatorsPerRecord: number;
    name: string;
    outputs: { [key in string]: LabelingTask.OutputRole };
    owner: string;
    permissions: LabelingTask.LabelingPermissionItem[];
    projectKey: string;
    type: LabelingTask.LabelingTaskType;
}

/** List of all concrete subclasses of com.dataiku.dip.labeling.LabelingTask */
export type LabelingTask =
    | ImageLabelingTask
    | TextLabelingTask;

export namespace LabelingTask {
    /** 
     * Translated from enum com.dataiku.dip.labeling.LabelingTask$ConsensusStrategy
     * Via: com.dataiku.dip.labeling.LabelingTask
     */
    export enum ConsensusStrategy {
        AVERAGE = "AVERAGE",
        MAJORITY = "MAJORITY",
    }
    /** 
     * Translated from class com.dataiku.dip.labeling.LabelingTask$InputRole
     * Via: com.dataiku.dip.labeling.LabelingTask
     */
    export interface _InputRole {
        items: LabelingTask.LabelingTaskInput[];
    }
    
    export type InputRole = _InputRole;
    
    /** 
     * Translated from class com.dataiku.dip.labeling.LabelingTask$LabelingPermissionItem
     * Via: com.dataiku.dip.labeling.LabelingTask
     */
    export interface _LabelingPermissionItem {
        annotate: boolean;
        group: string;
        review: boolean;
        user: string;
    }
    
    export type LabelingPermissionItem = _LabelingPermissionItem;
    
    /** 
     * Translated from class com.dataiku.dip.labeling.LabelingTask$LabelingTaskInput
     * Via: com.dataiku.dip.labeling.LabelingTask$InputRole
     */
    export interface _LabelingTaskInput {
        ref: string;
    }
    
    export type LabelingTaskInput = _LabelingTaskInput;
    
    /** 
     * Translated from class com.dataiku.dip.labeling.LabelingTask$LabelingTaskOutput
     * Via: com.dataiku.dip.labeling.LabelingTask$OutputRole
     */
    export interface _LabelingTaskOutput {
        ref: string;
    }
    
    export type LabelingTaskOutput = _LabelingTaskOutput;
    
    /** 
     * Translated from enum com.dataiku.dip.labeling.LabelingTask$LabelingTaskType
     * Via: com.dataiku.dip.labeling.LabelingTask
     */
    export enum LabelingTaskType {
        IMAGE_CLASSIFICATION = "IMAGE_CLASSIFICATION",
        OBJECT_DETECTION = "OBJECT_DETECTION",
        NAMED_ENTITY_EXTRACTION = "NAMED_ENTITY_EXTRACTION",
    }
    /** 
     * Translated from class com.dataiku.dip.labeling.LabelingTask$OutputRole
     * Via: com.dataiku.dip.labeling.LabelingTask
     */
    export interface _OutputRole {
        items: LabelingTask.LabelingTaskOutput[];
    }
    
    export type OutputRole = _OutputRole;
}