/** 
 * Translated from class com.dataiku.dip.labeling.VerifiedLabel
 * Via: com.dataiku.dip.labeling.LabelingAnswer
 */
export interface _VerifiedLabel {
}

/** List of all concrete subclasses of com.dataiku.dip.labeling.VerifiedLabel */
export type VerifiedLabel =
    | VerifiedLabel.ClassificationVerifiedLabel
    | VerifiedLabel.NamedEntityExtractionVerifiedLabel
    | VerifiedLabel.ObjectDetectionVerifiedLabel;

export namespace VerifiedLabel {
    /** Types of class com.dataiku.dip.labeling.VerifiedLabel in PolyJSON mappings */
    export const type = ["CLASSIFICATION", "NAMED_ENTITY_EXTRACTION", "OBJECT_DETECTION", ] as const;
    
    export function isVerifiedLabel(obj: VerifiedLabel): obj is VerifiedLabel { return ["CLASSIFICATION", "NAMED_ENTITY_EXTRACTION", "OBJECT_DETECTION", ].includes(obj["type"]); }
    export function isClassificationVerifiedLabel(obj: VerifiedLabel): obj is VerifiedLabel.ClassificationVerifiedLabel { return obj["type"] == "CLASSIFICATION"; }
    export function isNamedEntityExtractionVerifiedLabel(obj: VerifiedLabel): obj is VerifiedLabel.NamedEntityExtractionVerifiedLabel { return obj["type"] == "NAMED_ENTITY_EXTRACTION"; }
    export function isObjectDetectionVerifiedLabel(obj: VerifiedLabel): obj is VerifiedLabel.ObjectDetectionVerifiedLabel { return obj["type"] == "OBJECT_DETECTION"; }
    
    /** 
     * Translated from class com.dataiku.dip.labeling.VerifiedLabel$AnnotationPair
     * Via: com.dataiku.dip.labeling.VerifiedLabel$NamedEntityExtractionVerifiedLabel
     */
    export interface _AnnotationPair {
        annotationIdx: number;
        verifiedCategory: string;
    }
    
    /**
        Translate from class com.dataiku.dip.labeling.VerifiedLabel$AnnotationPair
        @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
    */
    export type AnnotationPair = _AnnotationPair;
    
    /** 
     * Translated from class com.dataiku.dip.labeling.VerifiedLabel$ClassificationVerifiedLabel
     * Via: com.dataiku.dip.labeling.VerifiedLabel
     */
    export interface _ClassificationVerifiedLabel extends _VerifiedLabel {
        type: "CLASSIFICATION";
        verifiedCategories: string[];
    }
    
    export type ClassificationVerifiedLabel = _ClassificationVerifiedLabel;
    
    /** 
     * Translated from class com.dataiku.dip.labeling.VerifiedLabel$NamedEntityExtractionVerifiedLabel
     * Via: com.dataiku.dip.labeling.VerifiedLabel
     */
    export interface _NamedEntityExtractionVerifiedLabel extends _VerifiedLabel {
        type: "NAMED_ENTITY_EXTRACTION";
        entityPairs: VerifiedLabel.AnnotationPair[];
    }
    
    export type NamedEntityExtractionVerifiedLabel = _NamedEntityExtractionVerifiedLabel;
    
    /** 
     * Translated from class com.dataiku.dip.labeling.VerifiedLabel$ObjectDetectionVerifiedLabel
     * Via: com.dataiku.dip.labeling.VerifiedLabel
     */
    export interface _ObjectDetectionVerifiedLabel extends _VerifiedLabel {
        type: "OBJECT_DETECTION";
        boundingBoxPairs: VerifiedLabel.ObjectDetectionVerifiedLabel.BoundingBoxPair[];
    }
    
    export type ObjectDetectionVerifiedLabel = _ObjectDetectionVerifiedLabel;

    export namespace ClassificationVerifiedLabel {
        /** Type of class com.dataiku.dip.labeling.VerifiedLabel$ClassificationVerifiedLabel in PolyJSON mappings */
        export const type = "CLASSIFICATION";
    }

    export namespace NamedEntityExtractionVerifiedLabel {
        /** Type of class com.dataiku.dip.labeling.VerifiedLabel$NamedEntityExtractionVerifiedLabel in PolyJSON mappings */
        export const type = "NAMED_ENTITY_EXTRACTION";
    }

    export namespace ObjectDetectionVerifiedLabel {
        /** Type of class com.dataiku.dip.labeling.VerifiedLabel$ObjectDetectionVerifiedLabel in PolyJSON mappings */
        export const type = "OBJECT_DETECTION";
        
        /** 
         * Translated from class com.dataiku.dip.labeling.VerifiedLabel$ObjectDetectionVerifiedLabel$BoundingBoxPair
         * Via: com.dataiku.dip.labeling.VerifiedLabel$ObjectDetectionVerifiedLabel
         */
        export interface _BoundingBoxPair extends VerifiedLabel._AnnotationPair {
            iou: number;
        }
        
        export type BoundingBoxPair = _BoundingBoxPair;
    }
}