import { Pipe, PipeTransform } from '@angular/core';
import { AllKeys, WithKey } from '../utils';

/**
 * Equivalent of 'in' operator in JS as an Angular pipe
 * (obj | hasProperty: 'prop')  == 'prop' in obj
 */
@Pipe({ name: 'hasProperty' })
export class HasPropertyPipe implements PipeTransform {
    transform<T extends object, K extends AllKeys<T>>(v: T | null | undefined, k: K): v is WithKey<T, K> {
        return v != null && k in v;
    }
}
