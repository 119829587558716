import { EventEmitter, Injectable, Output } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AxisTicksConfigurationService {
    constructor() { }

    public yTicksAreOverlapping: boolean = false;
    public xTicksAreOverlapping: boolean = false;
    @Output() public onAxisOverlappingChange = new EventEmitter<any>();


    public setYTicksAreOverlapping(overlap: boolean) {
        this.yTicksAreOverlapping = overlap;
        this.onAxisOverlappingChange.emit({ identifier: 'y', overlap });
    }

    public setXTicksAreOverlapping(overlap: boolean) {
        this.xTicksAreOverlapping = overlap;
        this.onAxisOverlappingChange.emit({ identifier: 'x', overlap });
    }

    public getTicksAreOverlappingByIdentifier(identifier: 'x' | 'y') {
        if (identifier === 'x') {
            return this.xTicksAreOverlapping;
        } else if (identifier === 'y') {
            return this.yTicksAreOverlapping;
        }

        throw new Error('Not supported axis ientifier.');
    }
}
