import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartOptionsMenuComponent } from './chart-options-menu.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
    declarations: [
        ChartOptionsMenuComponent
    ],
    imports: [
        CommonModule,
        MatMenuModule,
        MatDividerModule
    ],
    exports: [
        ChartOptionsMenuComponent
    ]
})
export class ChartOptionsMenuModule { }
