<div *ngIf="objectsToDisplay?.length" class="workspace-object-list"
    [ngClass]="{ 'workspace-object-list--list-view' : displayType === WorkspaceListType.LIST }">
    <workspace-object-list-element
        *ngFor="let object of objectsToDisplay; trackBy: trackWorkspaceObject"
        [workspaceKey]="workspaceKey"
        [object]="object"
        [displayType]="displayType"
        [editable]="editable"
        (toggleStar)="onToggleStar($event)"
        (duplicate)="onDuplicateObject(object)"
        (edit)="onEditObject(object)"
        (remove)="onRemoveObject(object)"
    ></workspace-object-list-element>
</div>
