/** 
 * Translated from class com.dataiku.dip.spark.InputDatasetsReadParams
 * Via: com.dataiku.dip.recipes.code.sparksql.SparkSQLExecutor$SparkSQLExecutorParams
 */
export interface _InputDatasetsReadParams {
    autoModeRepartitionInto: number;
    map: { [key in string]: InputDatasetsReadParams.InputDatasetReadParams };
    mode: InputDatasetsReadParams.ReadParamsMode;
}

export type InputDatasetsReadParams = _InputDatasetsReadParams;

export namespace InputDatasetsReadParams {
    /** 
     * Translated from class com.dataiku.dip.spark.InputDatasetsReadParams$InputDatasetReadParams
     * Via: com.dataiku.dip.spark.InputDatasetsReadParams
     */
    export interface _InputDatasetReadParams {
        repartition: number;
    }
    
    export type InputDatasetReadParams = _InputDatasetReadParams;
    
    /** 
     * Translated from enum com.dataiku.dip.spark.InputDatasetsReadParams$ReadParamsMode
     * Via: com.dataiku.dip.spark.InputDatasetsReadParams
     */
    export enum ReadParamsMode {
        AUTO = "AUTO",
        MANUAL = "MANUAL",
    }
}