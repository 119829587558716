import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { catchAPIError, SimpleObservableErrorContext } from '@core/dataiku-api/api-error';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fairAny } from 'dku-frontend-core';
import { BehaviorSubject, combineLatest, map, noop, shareReplay } from 'rxjs';
import { UIDataCollection } from 'src/generated-sources';
import { sortDataCollections } from '../shared/models/data-collections-filter.model';
import { DataCollectionsModalService } from '../shared/services/data-collections-modal.service';
import { LocalStorageSortingService } from '../shared/services/data-collections-sorting.service';
import { DataCollectionsService } from '../shared/services/data-collections.service';
import { DataCollectionsFilters } from './data-collections-top-bar/data-collections-top-bar.component';

function matchSearch(collection: UIDataCollection.ListItemWithDetails, lowerCaseSearch: string) {
    return collection.displayName.toLowerCase().includes(lowerCaseSearch)
        || collection.description.toLowerCase().includes(lowerCaseSearch)
        || collection.tags.some(tag => tag.toLowerCase().includes(lowerCaseSearch));
}

@UntilDestroy()
@Component({
    templateUrl: './data-collections-home.component.html',
    styleUrls: ['./data-collections-home.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCollectionsHomeComponent {
    canCreateDataCollections = this.dataCollectionsService.mayCreateDataCollections();

    errorContext = new SimpleObservableErrorContext();
    dataCollections$ = this.dataCollectionsService.listWithDetails().pipe(
        catchAPIError(this.errorContext),
        shareReplay(1),
    );
    tagsWithCount$ = this.dataCollections$.pipe(
        map(val => this.getTagsList(val))
    );

    filters$ = new BehaviorSubject<DataCollectionsFilters>(this.getDefaultFilters());
    get filter() { return this.filters$.value; }
    set filter(value: DataCollectionsFilters) { this.filters$.next(value); }

    filteredDataCollections$ = combineLatest([
        this.dataCollections$,
        this.filters$
    ]).pipe(
        map(([dataCollections, filters]) => this.applyFilters(dataCollections, filters))
    );

    itemsPerRow = 1;

    constructor(
        private dataCollectionsService: DataCollectionsService,
        private dataCollectionsModal: DataCollectionsModalService,
        private localStorageSortingService: LocalStorageSortingService,
        @Inject('$state') private $state: fairAny,
    ) {
        this.filters$.pipe(
            untilDestroyed(this)
        ).subscribe((filters) => {
            this.localStorageSortingService.setDataCollectionQuery(filters.search);
            this.localStorageSortingService.setSortByOption(filters.sortBy);
            this.localStorageSortingService.setDataCollectionTagSelection(filters.selectedTags);
            this.localStorageSortingService.setSortOrder(filters.sortOrder);
        });
    }

    recomputeItemsPerRow(width: number) {
        const gridGap = 24, cardMinSize = 400; // keep in sync with css
        this.itemsPerRow = Math.max(1, Math.floor((width + gridGap) / (cardMinSize + gridGap)));
    }

    createDataCollection(): void {
        this.dataCollectionsModal.openCreationModal().then((id) => {
            if(id !== undefined) {
                this.openCollectionPage(id);
            }
        }, noop);
    }

    private getDefaultFilters() : DataCollectionsFilters {
        return {
            search: this.localStorageSortingService.getDataCollectionQuery(),
            selectedTags: this.localStorageSortingService.getDataCollectionTagSelection(),
            sortBy: this.localStorageSortingService.getSortByOption(),
            sortOrder: this.localStorageSortingService.getSortOrder()
        };
    }

    private getTagsList(collections: UIDataCollection.ListItemWithDetails[]) {
        const tagsWithCountAsObject: {[key: string]: number} = {};
        collections.forEach((collection) => {
            collection.tags.forEach(tag => {
                tagsWithCountAsObject[tag] = (tagsWithCountAsObject[tag] || 0) + 1;
            });
        });
        return Object.entries(tagsWithCountAsObject).map(([tag, count]) => ({tag, count}));
    }

    openCollectionPage(id: string) {
        this.$state.go('^.datacollection', {dataCollectionId: id});
    }

    resetFilters() {
        this.filter = {
            ...this.filter,
            search: "",
            selectedTags: []
        };
    }

    private applyFilters(dataCollections: UIDataCollection.ListItemWithDetails[], filters: DataCollectionsFilters) {
        const lowerCaseSearch = filters.search.toLowerCase();
        let res = dataCollections.filter(collection => matchSearch(collection, lowerCaseSearch));
        if(filters.selectedTags.length > 0) {
            res = res.filter(collection => collection.tags.some(collectionTag => filters.selectedTags.includes(collectionTag)));
        }
        res.sort(sortDataCollections(filters.sortBy, filters.sortOrder));
        return res;
    }
}
