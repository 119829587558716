import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.Spearman
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Spearman extends Computation._BivariateComputation {
    type: "spearman";
}

export type Spearman = _Spearman;

export namespace Spearman {
    /** Type of class com.dataiku.dip.eda.compute.computations.bivariate.Spearman in PolyJSON mappings */
    export const type = "spearman";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.Spearman$SpearmanResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _SpearmanResult extends _AvailableResult {
        type: "spearman";
        correlation: number;
        pvalue: number;
    }
    
    export type SpearmanResult = _SpearmanResult;

    export namespace SpearmanResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.bivariate.Spearman$SpearmanResult in PolyJSON mappings */
        export const type = "spearman";
    }
}