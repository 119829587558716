import type { BivariateCard } from './../bivariate-card';
import type { CardResult } from './../card-result';
import type { Filter } from './../../../compute/filtering/filter';
import type { ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { PolynomialCurve } from './../../../compute/curves/polynomial-curve';
import type { SplitBySpec } from './../../models/split-by-spec';
import type { _BivariateCard } from './../bivariate-card';
import type { _CardResult } from './../card-result';
import type { _Filter } from './../../../compute/filtering/filter';
import type { _ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _SplitBySpec } from './../../models/split-by-spec';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.ScatterPlotCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _ScatterPlotCard extends _BivariateCard {
    type: "scatter_plot";
    colorBy?: SplitBySpec | null;
    highlightFilter?: Filter | null;
    maxNumberOfPoints: number;
    showTrend: boolean;
    symbolSize: number;
}

export type ScatterPlotCard = _ScatterPlotCard;

export namespace ScatterPlotCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.bivariate_header.ScatterPlotCard in PolyJSON mappings */
    export const type = "scatter_plot";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.ScatterPlotCard$ScatterPlotCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _ScatterPlotCardResult extends _CardResult {
        type: "scatter_plot";
        groups: Filter[];
        series: ScatterPlotCard.ScatterPlotSeries[];
    }
    
    export type ScatterPlotCardResult = _ScatterPlotCardResult;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.bivariate_header.ScatterPlotCard$ScatterPlotSeries
     * Via: com.dataiku.dip.eda.worksheets.cards.bivariate_header.ScatterPlotCard$ScatterPlotCardResult
     */
    export interface _ScatterPlotSeries extends _ImmutableValueObject {
        groupIndex: number;
        isHighlighted: boolean;
        trend?: PolynomialCurve.ParametrizedPolynomialCurve | null;
        xSeries: number[];
        ySeries: number[];
    }
    
    export type ScatterPlotSeries = _ScatterPlotSeries;

    export namespace ScatterPlotCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.bivariate_header.ScatterPlotCard$ScatterPlotCardResult in PolyJSON mappings */
        export const type = "scatter_plot";
    }
}