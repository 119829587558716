/** 
 * Translated from enum com.dataiku.dip.server.services.InstanceLicenseKind
 * Via: UIModel annotation in the class hierarchy
 */
export enum InstanceLicenseKind {
    NONE = "NONE",
    UNKNOWN = "UNKNOWN",
    DATAIKU_INTERNAL = "DATAIKU_INTERNAL",
    COMMUNITY = "COMMUNITY",
    CUSTOMER_EVAL = "CUSTOMER_EVAL",
    CUSTOMER_PROD = "CUSTOMER_PROD",
    SAAS_TRIAL = "SAAS_TRIAL",
    SAAS_TRAINING = "SAAS_TRAINING",
    PARTNER_NFR = "PARTNER_NFR",
    ACADEMIC_STUDENT = "ACADEMIC_STUDENT",
    DATAIKU_ONLINE = "DATAIKU_ONLINE",
    OTHER = "OTHER",
}