import type { ACFPlotCard } from './acfplot-card';
import type { BoxPlotFragment } from './fragments/box-plot-fragment';
import type { Card } from './card';
import type { CardResult } from './card-result';
import type { DurbinWatsonCard } from './durbin-watson-card';
import type { MannKendallTestCard } from './mann-kendall-test-card';
import type { ResamplerSettings } from './../../compute/resampling/resampler-settings';
import type { STLDecompositionCard } from './stldecomposition-card';
import type { TimeInfoFragment } from './fragments/time-info-fragment';
import type { UnitRootTestADFCard } from './unit-root-test-adfcard';
import type { UnitRootTestKPSSCard } from './unit-root-test-kpsscard';
import type { UnitRootTestZACard } from './unit-root-test-zacard';
import type { Variable } from './../models/variable';
import type { _ACFPlotCard } from './acfplot-card';
import type { _BoxPlotFragment } from './fragments/box-plot-fragment';
import type { _Card } from './card';
import type { _CardResult } from './card-result';
import type { _DurbinWatsonCard } from './durbin-watson-card';
import type { _MannKendallTestCard } from './mann-kendall-test-card';
import type { _ResamplerSettings } from './../../compute/resampling/resampler-settings';
import type { _STLDecompositionCard } from './stldecomposition-card';
import type { _TimeInfoFragment } from './fragments/time-info-fragment';
import type { _UnitRootTestADFCard } from './unit-root-test-adfcard';
import type { _UnitRootTestKPSSCard } from './unit-root-test-kpsscard';
import type { _UnitRootTestZACard } from './unit-root-test-zacard';
import type { _Variable } from './../models/variable';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _TimeSeriesCard extends _Card {
    resamplerSettings?: ResamplerSettings | null;
    seriesColumn: Variable;
    seriesIdentifiers: TimeSeriesCard.TimeSeriesIdentifier[];
    timeColumn: Variable;
}

/** List of all concrete subclasses of com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard */
export type TimeSeriesCard =
    | ACFPlotCard
    | DurbinWatsonCard
    | MannKendallTestCard
    | STLDecompositionCard
    | UnitRootTestADFCard
    | UnitRootTestKPSSCard
    | UnitRootTestZACard;

export namespace TimeSeriesCard {
    /** Types of class com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard in PolyJSON mappings */
    export const type = ["acf_plot", "durbin_watson", "mann_kendall_test", "stl_decomposition", "unit_root_test_adf", "unit_root_test_kpss", "unit_root_test_za", ] as const;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard$TimeSeriesCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _TimeSeriesCardResult extends _CardResult {
        beforeResamplingCount?: number | null;
        boxPlot: BoxPlotFragment;
        timeInfo: TimeInfoFragment;
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard$TimeSeriesCardResult */
    export type TimeSeriesCardResult =
        | ACFPlotCard.ACFPlotCardResult
        | DurbinWatsonCard.DurbinWatsonCardResult
        | MannKendallTestCard.MannKendallTestCardResult
        | STLDecompositionCard.STLDecompositionCardResult
        | UnitRootTestADFCard.UnitRootTestADFCardResult
        | UnitRootTestKPSSCard.UnitRootTestKPSSCardResult
        | UnitRootTestZACard.UnitRootTestZACardResult;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard$TimeSeriesIdentifier
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _TimeSeriesIdentifier {
        column: Variable;
        values: string[];
    }
    
    export type TimeSeriesIdentifier = _TimeSeriesIdentifier;

    export namespace TimeSeriesCardResult {
        /** Types of class com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard$TimeSeriesCardResult in PolyJSON mappings */
        export const type = ["acf_plot", "durbin_watson", "mann_kendall_test", "stl_decomposition", "unit_root_test_adf", "unit_root_test_kpss", "unit_root_test_za", ] as const;
    }
}