import type { ChecksSet } from './../metrics/checks-set';
import type { CodeEnvSelection } from './../code/code-env-selection';
import type { ConditionalOutput } from './../coremodel/conditional-output';
import type { MLTask } from './../analysis/model/mltask';
import type { PartitioningScheme } from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/partitioning/partitioning-scheme';
import type { ProbesSet } from './../metrics/probes-set';
import type { PromptStudio } from './../llm/promptstudio/prompt-studio';
import type { ProxyModelConfiguration } from './../savedmodels/proxymodels/proxy-model-configuration';
import type { SerializedDataset } from './../coremodel/serialized-dataset';
import type { TaggableObjectsService } from './../server/services/taggable-objects-service';
import type { VersionTag } from './../coremodel/version-tag';
import type { _ChecksSet } from './../metrics/checks-set';
import type { _CodeEnvSelection } from './../code/code-env-selection';
import type { _ConditionalOutput } from './../coremodel/conditional-output';
import type { _MLTask } from './../analysis/model/mltask';
import type { _PartitioningScheme } from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/partitioning/partitioning-scheme';
import type { _ProbesSet } from './../metrics/probes-set';
import type { _ProxyModelConfiguration } from './../savedmodels/proxymodels/proxy-model-configuration';
import type { _VersionTag } from './../coremodel/version-tag';

/** 
 * Translated from class com.dataiku.dip.dao.SavedModel
 * Via: UIModel annotation in the class hierarchy
 */
export interface _SavedModel extends TaggableObjectsService._TaggableObject {
    activeVersion: string;
    conditionalOutputs: ConditionalOutput[];
    contentType: string;
    finedTunedLLMType: PromptStudio.LLMType;
    flowOptions: SerializedDataset.FlowOptions;
    id: string;
    inlineVersions: SavedModel.SavedModelInlineVersion[];
    lastExportedFrom: string;
    lastTrainIndex: number;
    lastTrainJobId: string;
    llmTaskType: MLTask.MLTaskType;
    metrics: ProbesSet;
    metricsChecks: ChecksSet;
    miniTask: MLTask;
    name: string;
    partitioning: PartitioningScheme;
    projectKey: string;
    proxyModelConfiguration?: ProxyModelConfiguration | null;
    publishPolicy: SavedModel.ModelPublishPolicy;
    rebuildBehavior: SerializedDataset.RebuildBehavior;
    savedModelType: SavedModel.SavedModelType;
}

export type SavedModel = _SavedModel;

export namespace SavedModel {
    /** 
     * Translated from enum com.dataiku.dip.dao.SavedModel$ModelPublishPolicy
     * Via: com.dataiku.dip.dao.SavedModel
     */
    export enum ModelPublishPolicy {
        MANUAL = "MANUAL",
        UNCONDITIONAL = "UNCONDITIONAL",
    }
    /** 
     * Translated from class com.dataiku.dip.dao.SavedModel$SavedModelInlineVersion
     * Via: com.dataiku.dip.dao.SavedModel
     */
    export interface _SavedModelInlineVersion {
        code: string;
        codeEnvSelection: CodeEnvSelection;
        creationTag?: VersionTag | null;
        description: string;
        versionId: string;
        versionTag?: VersionTag | null;
    }
    
    export type SavedModelInlineVersion = _SavedModelInlineVersion;
    
    /** 
     * Translated from enum com.dataiku.dip.dao.SavedModel$SavedModelType
     * Via: UIModel annotation in the class hierarchy
     */
    export enum SavedModelType {
        DSS_MANAGED = "DSS_MANAGED",
        MLFLOW_PYFUNC = "MLFLOW_PYFUNC",
        PROXY_MODEL = "PROXY_MODEL",
        LANGCHAIN_INLINE = "LANGCHAIN_INLINE",
        LLM_GENERIC = "LLM_GENERIC",
    }
}