import { Inject, Injectable } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { ExposedObjectsService, ITaggingService, TaggableObjectsService } from 'generated-sources';
import { fairAny } from 'dku-frontend-core';
import { AppConfig } from '..';
import { normalizeSmartName } from '@utils/loc';

export interface Shareability {
    shareability: "nothing" | "share" | "request";
    reason: string;
}

export type Publishability = Record<'dashboard'|'featureStore'|'workspace'|'dataCollection', PublishabilityItem>;
export type PublishabilityItem = {
    show: boolean;
    enabled: boolean;
    reason: string;
};

const doNotShowPublish = (): PublishabilityItem => ({ show: false, enabled: false, reason: ''});

@Injectable({
    providedIn: 'root'
})
export class ShareAndPublishService {
    constructor(
        private DataikuAPI: DataikuAPIService,
        @Inject("ExposedObjectsService") private exposedObjectsService: fairAny,
        @Inject("CreateModalFromTemplate") private CreateModalFromTemplate: fairAny,
        @Inject('$rootScope') private $rootScope: fairAny,
    ) { }

    /**
     * Get the shareability of an object wrt a user's authorizations, and optionally a context project
     * @param targetProject if defined, considers the sharing of the object to that project.
     *  Should most likely be the current scope UIProject
     * @returns the best possible action for that object, with a reason / description (probably to be used as tooltip)
     */
    getShareability(
        object: TaggableObjectsService.TaggableObjectRefWithName,
        objectAuthorizations: ExposedObjectsService.ObjectAuthorizations,
        targetProject?: {projectKey: string, name: string},
    ): Shareability {
        if (targetProject?.projectKey === object.projectKey) {
            return {
                shareability: 'nothing',
                reason: 'Target project must be different from source'
            };
        } else if (objectAuthorizations.canManageExposedElements || (objectAuthorizations.isQuicklyShareable && objectAuthorizations.canReadObject)) {
            return {
                shareability: 'share',
                reason: targetProject ? 'Use in project ' + targetProject.name : 'Use in another project'
            };
        } else if (objectAuthorizations.isObjectSharingRequestEnabled) {
            return {
                shareability: 'request',
                reason: targetProject ? 'Request to use in project ' + targetProject.name : 'Request to use in another project'
            };
        } else {
            return {
                shareability: 'nothing',
                reason: "You don't have permission to use this dataset"
            };
        }
    }

    /**
     * Share an object to a given project (meant for one-click use when in the context of a project)
     * Send sharing-related WT1 events
     * @param object the object to share
     * @param targetProjectKey share traget
     * @param isQuicklyShareable is that object already quick sharable
     * @param wt1Context additional fields to add in the WT1 report sent with the share (typically context)
     * @returns resolve<true> if action done, reject in case of error.
     */
    doShare(object: TaggableObjectsService.TaggableObjectRefWithName,
        targetProjectKey: string,
        isQuicklyShareable: boolean,
        wt1Context: Record<string, string | number>
    ): Promise<true> {
        return this.exposedObjectsService.doExposeSingleObject(
            object.type,
            object.projectKey,
            object.id,
            targetProjectKey,
            object.displayName,
            isQuicklyShareable,
            true,
            wt1Context
        ).then(() => true);
    }

    /**
     * Open the sharing modal for an object.
     * Send sharing-related WT1 events
     * @param object the object to share
     * @param objectAuthorizations that object authorizations
     * @param wt1Context additional fields to add in the WT1 report sent with the share (typically context)
     * @returns resolve<true> if action done, resolve<false> if modal dismissed, reject in case of error.
     */
    openShareModal(object: TaggableObjectsService.TaggableObjectRefWithName,
        objectAuthorizations: ExposedObjectsService.ObjectAuthorizations,
        wt1Context: Record<string, string | number>
    ): Promise<boolean> {
        return this.exposedObjectsService.exposeSingleObjectWithAuthorization(
            objectAuthorizations,
            object.type,
            object.id,
            object.displayName,
            object.projectKey,
            wt1Context
        ).then(
            () => true,
            (err: fairAny) => { // service may reject if there was an API error or if the modal was dismissed
                if(err === 'dismissed modal') return false;
                throw err;
            }
        );
    }

    /**
     * Request share for an object
     * @param object the object to request share
     * @param targetProjectKey preselected project to request share to, or undefined to have nothing selected by default
     * @param wt1Context additional fields to add in the WT1 report sent with the share (typically context)
     * @returns resolve<true> if action done, resolve<false> if modal dismissed.
     */
    requestShare(object: TaggableObjectsService.TaggableObjectRefWithName,
        targetProjectKey: string | undefined,
        wt1Context: Record<string, string | number>
    ): Promise<boolean> {
        return this.exposedObjectsService.requestSharing(
            object.type,
            object.id,
            object.displayName,
            object.projectKey,
            targetProjectKey,
            wt1Context
        ).then(() => true, () => false); // rejected means canceled
    }


    getPublishability(object: TaggableObjectsService.TaggableObjectRef,
        objectAuthorizations: ExposedObjectsService.ObjectAuthorizations,
        contextProject: {projectKey: string, canWriteDashboards: boolean} | undefined,
        globalPermissions: AppConfig['globalPermissions'],
        isFeatureGroup: boolean
    ): Publishability {
        return {
            dashboard: this.getDashboardPublishability(object, contextProject),
            workspace: this.getWorkspacePublishability(objectAuthorizations, globalPermissions.mayShareToWorkspaces),
            featureStore: this.getFeatureStorePublishability(object, objectAuthorizations, isFeatureGroup, globalPermissions.mayManageFeatureStore),
            dataCollection: this.getDataCollectionPublishability(object, objectAuthorizations, globalPermissions.mayPublishToDataCollections)
        };
    }

    private getDashboardPublishability(object: TaggableObjectsService.TaggableObjectRef, contextProject?: {projectKey: string, canWriteDashboards: boolean}): PublishabilityItem {
        if (contextProject?.projectKey === object.projectKey) {
            return {
                show: true,
                enabled: contextProject.canWriteDashboards,
                reason: contextProject.canWriteDashboards ? "" : "You don't have the permission to share objects from this project to a dashboard"
            };
        }
        return doNotShowPublish();
    }

    private getWorkspacePublishability(objectAuthorizations: ExposedObjectsService.ObjectAuthorizations, mayShareToWorkspaces: boolean): PublishabilityItem {
        if (mayShareToWorkspaces) {
            return {
                show: true,
                enabled: objectAuthorizations.canShareToWorkspaces,
                reason: objectAuthorizations.canShareToWorkspaces ? "" : "You don't have the permission to this object to a workspace"
            };
        }
        return {
            show: true,
            enabled: false,
            reason: "You don't have the permission to share objects to a workspace",
        };
    }

    private getFeatureStorePublishability(object: TaggableObjectsService.TaggableObjectRef,
        objectAuthorizations: ExposedObjectsService.ObjectAuthorizations,
        isFeatureGroup: boolean, mayManageFeatureStore: boolean
    ): PublishabilityItem {
        if (object.type != ITaggingService.TaggableType.DATASET) {
            return doNotShowPublish();
        } else if (!mayManageFeatureStore) {
            return {
                show: true,
                enabled: false,
                reason: "You don't have the permission to add datasets to the feature store"
            };
        } else if (isFeatureGroup) {
            return {
                show: true,
                enabled: false,
                reason: "Dataset is already in the feature store"
            };
        } else {
            return {
                show: true,
                enabled: objectAuthorizations.canWriteObject,
                reason: objectAuthorizations.canWriteObject ? "" : "You don't have the permission to add this dataset to the feature store"
            };
        }
    }

    getDataCollectionPublishability(object: TaggableObjectsService.TaggableObjectRef,
        objectAuthorizations: ExposedObjectsService.ObjectAuthorizations,
        mayPublishToDataCollections: boolean
    ): PublishabilityItem {
        if(object.type != ITaggingService.TaggableType.DATASET) {
            return doNotShowPublish();
        } else if (!mayPublishToDataCollections) {
            return {
                show: true,
                enabled: false,
                reason: 'You don\'t have the permission to publish objects to a data collection'
            };
        } else {
            return {
                show: true,
                enabled: objectAuthorizations.canPublishToDataCollections,
                reason: objectAuthorizations.canPublishToDataCollections ? '' : 'You don\'t have the permission to publish objects from this project to a data collection'
            };
        }
    }

    /**
     * Open modal to publish a dataset to a dashboard
     * @returns resolve<true> if action done, resolve<false> if modal dismissed.
     */
    shareDatasetToDashboard(projectKey: string, datasetName: string, contextProjectKey: string) {
        const datasetSmartName = normalizeSmartName(contextProjectKey, `${projectKey}.${datasetName}`);
        const insight = {
            projectKey: contextProjectKey,
            type: 'dataset_table',
            params: { datasetSmartName },
            name: datasetName,
        };
        return this.CreateModalFromTemplate("/templates/dashboards/insights/create-and-pin-insight-modal.html", this.$rootScope.$new(), "CreateAndPinInsightModalController", function (newScope: fairAny) {
            newScope.init(insight);
        });
    }

    /**
     * Open modal to share an object to a workspace
     * @returns resolve<true> if action done, resolve<false> if modal dismissed.
     */
    shareToWorkspace(object: TaggableObjectsService.TaggableObjectRef) {
        return this.CreateModalFromTemplate('/templates/dialogs/share-in-workspace.html', this.$rootScope.$new(), undefined, (newScope: fairAny) => {
            newScope.init([{ reference: object }]);
        });
    }

    /**
     * Open modal to add an object to the feature store
     * @returns resolve<true> if action done, resolve<false> if modal dismissed.
     */
    addToFeatureStore(projectKey: string, datasetName: string, objectAuthorizations: ExposedObjectsService.ObjectAuthorizations): Promise<boolean> {
        return this.CreateModalFromTemplate(
            "/templates/featurestore/promote-as-feature-group-modal.html",
            this.$rootScope.$new(),
            "PromoteAsFeatureGroupModalController",
            (newScope: fairAny) => {
                newScope.init({
                    projectKey,
                    smartName: datasetName,
                }, objectAuthorizations);
            }
        ).then(() => true, () => false); // rejected means canceled
    }

    /**
     * Open modal to add an object to a data-collection (from the object)
     * @returns resolve<true> if action done, resolve<false> if modal dismissed.
     */
    addToDataCollection(object: TaggableObjectsService.TaggableObjectRef, wt1Context: Record<string, string | number>) {
        return this.CreateModalFromTemplate(
            '/templates/dialogs/add-to-data-collection.html',
            this.$rootScope.$new(),
            undefined,
            (newScope: fairAny) => {
                newScope.init([object], wt1Context);
            }
        );
    }
}


