import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { ReactiveFormsModule } from '@angular/forms';
import { DkuMatTooltipModule } from '@shared/components/dku-mat-tooltip/dku-mat-tooltip.module';
import { ChartColorPickerComponent } from './chart-color-picker.component';
import { ChartColorPickerItemComponent } from './chart-color-picker-item';
import { ChartColorPickerAlphaComponent } from './chart-color-picker-alpha';

@NgModule({
    declarations: [
        ChartColorPickerComponent,
        ChartColorPickerItemComponent,
        ChartColorPickerAlphaComponent
    ],
    imports: [
        CommonModule,
        OverlayModule,
        ReactiveFormsModule,
        DkuMatTooltipModule
    ],
    exports: [
        ChartColorPickerComponent
    ]
})
export class ChartColorPickerModule { }
