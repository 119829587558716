import { SamplingParam } from "@model-main/datasets/sampling-param";
import { AxisDef } from "@model-main/pivot/backend/model/axis-def";
import { AxisSortPrune } from "@model-main/pivot/backend/model/axis-sort-prune";
import { PivotTableResponse } from "@model-main/pivot/backend/model/pivot-table-response";
import { PivotTableTensorResponse } from "@model-main/pivot/backend/model/pivot-table-tensor-response";

export const radarNumericalByAlphanumericalPivotResponse: PivotTableTensorResponse = {
    axisLabels: [
        [
            {
                label: "Bl Tshirt F",
            },
            {
                label: "Bl Tshirt M",
            },
            {
                label: "Black T-Shirt F",
            },
            {
                label: "Black T-Shirt M",
            },
            {
                label: "Hoodie",
            },
            {
                label: "Others",
            },
        ],
    ] as Array<any>,
    axisDefs: [
        {
            column: "tshirt_category",
            type: AxisDef.Type.ALPHANUM,
            sortPrune: {
                sortType: AxisSortPrune.SortType.NATURAL,
                sortAscending: true,
                aggregationSortId: -1,
                generateOthersCategory: true,
                forceLastPositionOthers: true,
                filters: [],
                maxValues: 5,
            },
        },
    ] as Array<any>,
    sequenceId: 0,
    aggregations: [
        {
            numAxes: 1,
            axisLengths: [6],
            tensor: [11, 17, 26, 25, 29, 29],
            axes: [[0, 0, 0, 0, 0, 0]],
            multipliers: [1],
        },
        {
            numAxes: 1,
            axisLengths: [6],
            tensor: [
                12.53191489361702, 11.972222222222221, 10.834249803613512, 10.724893487522824, 10.910964912280702,
                10.882829610162068,
            ],
            axes: [[0, 0, 0, 0, 0, 0]],
            multipliers: [1],
        },
        {
            numAxes: 1,
            axisLengths: [6],
            tensor: [17.5, 19, 17.5, 19, 22.987719298245615, 20.113885238720982],
            axes: [[0, 0, 0, 0, 0, 0]],
            multipliers: [1],
        },
    ] as Array<any>,
    counts: {
        numAxes: 1,
        axisLengths: [6],
        tensor: [94, 144, 1273, 1643, 2280, 4566],
        axes: [[0, 0, 0, 0, 0, 0]],
        multipliers: [1],
    },
    filterFacets: [],
    beforeFilterRecords: 10000,
    afterFilterRecords: 10000,
    engine: PivotTableResponse.PivotEngine.LINO,
    sampleMetadata: {
        samplingMethod: SamplingParam.SamplingMethod.HEAD_SEQUENTIAL,
        maxRecords: 10000,
        targetRatio: 0.02,
        hasFilter: false,
        partitionCount: 0,
        sampleIsWholeDataset: false,
        sampleRecordCount: 10000,
        datasetRecordCount: -1,
        recordCountIsApproximate: false,
        recordCountIsObsolete: false,
        memoryLimitReached: false,
        memoryLimitInMB: 0,
    },
    columnsSummary: undefined as any,
};

export const radarNumericalAndAlphanumericalByNumericalPivotResponse: PivotTableTensorResponse = {
    axisLabels: [
        [
            {
                sortValue: 18.3125,
                min: 17.5,
                max: 19.125,
                label: "18-19",
            },
            {
                sortValue: 19.9375,
                min: 19.125,
                max: 20.75,
                label: "19-21",
            },
            {
                sortValue: 21.5625,
                min: 20.75,
                max: 22.375,
                label: "21-22",
            },
            {
                sortValue: 23.1875,
                min: 22.375,
                max: 24,
                label: "22-24",
            },
        ],
    ] as Array<any>,
    axisDefs: [
        {
            column: "tshirt_price",
            type: "NUMERICAL",
            sortPrune: {
                sortType: "NATURAL",
                sortAscending: true,
                aggregationSortId: -1,
                generateOthersCategory: false,
                filters: [],
                maxValues: 0,
            },
            numParams: {
                mode: "FIXED_NB",
                emptyBinsMode: "ZEROS",
                nbBins: 4,
                binSize: 100,
                niceBounds: false,
            },
        },
    ] as Array<any>,
    sequenceId: 0,
    aggregations: [
        {
            numAxes: 1,
            axisLengths: [4],
            tensor: [26, 29, 0, 29],
            axes: [[0, 0, 0, 0]],
            multipliers: [1],
        },
        {
            numAxes: 1,
            axisLengths: [4],
            tensor: [10.880496299521115, 10.95054704595186, 0, 10.854213393143223],
            axes: [[0, 0, 0, 0]],
            multipliers: [1],
        },
        {
            numAxes: 1,
            axisLengths: [4],
            tensor: [8, 4, 0, 2],
            axes: [[0, 0, 0, 0]],
            multipliers: [1],
        },
    ] as Array<any>,
    counts: {
        numAxes: 1,
        axisLengths: [4],
        tensor: [4594, 2285, 0, 3121],
        axes: [[0, 0, 0, 0]],
        multipliers: [1],
    },
    filterFacets: [],
    beforeFilterRecords: 10000,
    afterFilterRecords: 10000,
    engine: PivotTableResponse.PivotEngine.LINO,
    sampleMetadata: {
        samplingMethod: SamplingParam.SamplingMethod.HEAD_SEQUENTIAL,
        maxRecords: 10000,
        targetRatio: 0.02,
        hasFilter: false,
        partitionCount: 0,
        sampleIsWholeDataset: false,
        sampleRecordCount: 10000,
        datasetRecordCount: -1,
        recordCountIsApproximate: false,
        recordCountIsObsolete: false,
        memoryLimitReached: false,
        memoryLimitInMB: 0,
    },
    columnsSummary: undefined as any,
};

export const radarNumericalByDatePivotResponse: PivotTableTensorResponse = {
    axisLabels: [
        [
            {
                tsValue: 1293840000000,
                sortValue: 24132,
                min: 1293840000000,
                max: 1296518399999,
                label: "01/2011",
            },
            {
                tsValue: 1296518400000,
                sortValue: 24133,
                min: 1296518400000,
                max: 1298937599999,
                label: "02/2011",
            },
            {
                tsValue: 1298937600000,
                sortValue: 24134,
                min: 1298937600000,
                max: 1301615999999,
                label: "03/2011",
            },
            {
                tsValue: 1301616000000,
                sortValue: 24135,
                min: 1301616000000,
                max: 1304207999999,
                label: "04/2011",
            },
            {
                tsValue: 1304208000000,
                sortValue: 24136,
                min: 1304208000000,
                max: 1306886399999,
                label: "05/2011",
            },
            {
                tsValue: 1306886400000,
                sortValue: 24137,
                min: 1306886400000,
                max: 1309478399999,
                label: "06/2011",
            },
            {
                tsValue: 1309478400000,
                sortValue: 24138,
                min: 1309478400000,
                max: 1312156799999,
                label: "07/2011",
            },
        ],
    ] as Array<any>,
    axisDefs: [
        {
            column: "day",
            type: "DATE",
            sortPrune: {
                sortType: "NATURAL",
                sortAscending: true,
                aggregationSortId: -1,
                generateOthersCategory: true,
                forceLastPositionOthers: true,
                filters: [],
                maxValues: 0,
            },
            dateParams: {
                mode: "MONTH",
                maxBinNumberForAutomaticMode: 0,
            },
        },
    ] as Array<any>,
    sequenceId: 0,
    aggregations: [
        {
            numAxes: 1,
            axisLengths: [8],
            tensor: [
                71.16218637992831, 437.4072164948454, 192.26239316239315, 155.41286863270778, 66.84718498659518,
                277.925831202046, 59.73026315789474, 0,
            ],
            axes: [[0, 0, 0, 0, 0, 0, 0, 0]],
            multipliers: [1],
        },
        {
            numAxes: 1,
            axisLengths: [8],
            tensor: [
                -15.888978494623649, -14.08341924398624, -10.536752136752144, -4.962109025916003, -1.7961572832886505,
                -1.025319693094628, 0.367105263157895, 0,
            ],
            axes: [[0, 0, 0, 0, 0, 0, 0, 0]],
            multipliers: [1],
        },
        {
            numAxes: 1,
            axisLengths: [8],
            tensor: [
                4.121370967741949, 5.91474441580757, 9.382044314381277, 14.906423145665736, 18.20311662198385,
                18.946334185848418, 20.780098684210515, 0,
            ],
            axes: [[0, 0, 0, 0, 0, 0, 0, 0]],
            multipliers: [1],
        },
        {
            numAxes: 1,
            axisLengths: [8],
            tensor: [1116, 1164, 1170, 1119, 1119, 1173, 152, 0],
            axes: [[0, 0, 0, 0, 0, 0, 0, 0]],
            multipliers: [1],
        },
    ] as Array<any>,
    counts: {
        numAxes: 1,
        axisLengths: [8],
        tensor: [1116, 1164, 1170, 1119, 1119, 1173, 152, 0],
        axes: [[0, 0, 0, 0, 0, 0, 0, 0]],
        multipliers: [1],
    },
    filterFacets: [
        {
            filterIdx: 0,
            isRange: false,
            filteredRecords: 2987,
            isAGlobalFilter: false,
            values: [],
            totalNbValues: 0,
            count: 0,
            isTruncated: false,
            minValue: 11,
            maxValue: 4471,
            globalMinValue: 11,
            globalMaxValue: 4471,
            histogramBars: [],
        },
    ] as Array<any>,
    beforeFilterRecords: 10000,
    afterFilterRecords: 7013,
    engine: PivotTableResponse.PivotEngine.LINO,
    sampleMetadata: {
        samplingMethod: SamplingParam.SamplingMethod.HEAD_SEQUENTIAL,
        maxRecords: 10000,
        targetRatio: 0.02,
        hasFilter: false,
        partitionCount: 0,
        sampleIsWholeDataset: false,
        sampleRecordCount: 10000,
        datasetRecordCount: -1,
        recordCountIsApproximate: false,
        recordCountIsObsolete: false,
        memoryLimitReached: false,
        memoryLimitInMB: 0,
    },
    columnsSummary: undefined as any,
};
