import type { AbstractNSampTestCard } from './common/abstract-nsamp-test-card';
import type { _AbstractNSampTestCard } from './common/abstract-nsamp-test-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.MoodTestNSampCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _MoodTestNSampCard extends _AbstractNSampTestCard {
    type: "mood_nsamp";
}

export type MoodTestNSampCard = _MoodTestNSampCard;

export namespace MoodTestNSampCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.MoodTestNSampCard in PolyJSON mappings */
    export const type = "mood_nsamp";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.MoodTestNSampCard$MoodTestNSampCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _MoodTestNSampCardResult extends AbstractNSampTestCard._AbstractNSampleTestCardResult {
        type: "mood_nsamp";
        pvalue: number;
        statistic: number;
    }
    
    export type MoodTestNSampCardResult = _MoodTestNSampCardResult;

    export namespace MoodTestNSampCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.MoodTestNSampCard$MoodTestNSampCardResult in PolyJSON mappings */
        export const type = "mood_nsamp";
    }
}