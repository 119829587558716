import type { AzureMLUtils } from './../../externalinfras/azureml/azure-mlutils';
import type { SageMakerUtils } from './../../apideployer/deployments/sage-maker-utils';
import type { VertexAIUtils } from './../../apideployer/deployments/vertex-aiutils';



export namespace ProxyModelVersionConfiguration {
    /** 
     * Translated from class com.dataiku.dip.savedmodels.proxymodelversions.ProxyModelVersionConfiguration$ConsolidatedEndpointInfo
     * Via: com.dataiku.dip.analysis.model.ModelDetailsBase
     */
    export interface _ConsolidatedEndpointInfo {
    }
    
    /** List of all concrete subclasses of com.dataiku.dip.savedmodels.proxymodelversions.ProxyModelVersionConfiguration$ConsolidatedEndpointInfo */
    export type ConsolidatedEndpointInfo =
        | SageMakerUtils.DSSSageMakerConsolidatedEndpointInfo
        | VertexAIUtils.DSSVertexAIConsolidatedEndpointInfo
        | AzureMLUtils.DSSAzureMLConsolidatedEndpointInfo
        | ProxyModelVersionConfiguration.ErrorEndpointInfo;
    
    /** 
     * Translated from class com.dataiku.dip.savedmodels.proxymodelversions.ProxyModelVersionConfiguration$ErrorEndpointInfo
     * Via: com.dataiku.dip.savedmodels.proxymodelversions.ProxyModelVersionConfiguration$ConsolidatedEndpointInfo
     */
    export interface _ErrorEndpointInfo extends ProxyModelVersionConfiguration._ConsolidatedEndpointInfo {
        type: "ErrorEndpointInfo";
        errorMessage: string;
        stackTraceStr: string;
    }
    
    export type ErrorEndpointInfo = _ErrorEndpointInfo;

    export namespace ConsolidatedEndpointInfo {
        /** Types of class com.dataiku.dip.savedmodels.proxymodelversions.ProxyModelVersionConfiguration$ConsolidatedEndpointInfo in PolyJSON mappings */
        export const type = ["DSSSageMakerConsolidatedEndpointInfo", "DSSVertexAIConsolidatedEndpointInfo", "DSSAzureMLConsolidatedEndpointInfo", "ErrorEndpointInfo", ] as const;
        
        export function isDSSSageMakerConsolidatedEndpointInfo(obj: ProxyModelVersionConfiguration.ConsolidatedEndpointInfo): obj is SageMakerUtils.DSSSageMakerConsolidatedEndpointInfo { return obj["type"] == "DSSSageMakerConsolidatedEndpointInfo"; }
        export function isDSSVertexAIConsolidatedEndpointInfo(obj: ProxyModelVersionConfiguration.ConsolidatedEndpointInfo): obj is VertexAIUtils.DSSVertexAIConsolidatedEndpointInfo { return obj["type"] == "DSSVertexAIConsolidatedEndpointInfo"; }
        export function isDSSAzureMLConsolidatedEndpointInfo(obj: ProxyModelVersionConfiguration.ConsolidatedEndpointInfo): obj is AzureMLUtils.DSSAzureMLConsolidatedEndpointInfo { return obj["type"] == "DSSAzureMLConsolidatedEndpointInfo"; }
        export function isConsolidatedEndpointInfo(obj: ProxyModelVersionConfiguration.ConsolidatedEndpointInfo): obj is ProxyModelVersionConfiguration.ConsolidatedEndpointInfo { return ["DSSSageMakerConsolidatedEndpointInfo", "DSSVertexAIConsolidatedEndpointInfo", "DSSAzureMLConsolidatedEndpointInfo", "ErrorEndpointInfo", ].includes(obj["type"]); }
        export function isErrorEndpointInfo(obj: ProxyModelVersionConfiguration.ConsolidatedEndpointInfo): obj is ProxyModelVersionConfiguration.ErrorEndpointInfo { return obj["type"] == "ErrorEndpointInfo"; }
    }

    export namespace ErrorEndpointInfo {
        /** Type of class com.dataiku.dip.savedmodels.proxymodelversions.ProxyModelVersionConfiguration$ErrorEndpointInfo in PolyJSON mappings */
        export const type = "ErrorEndpointInfo";
    }
}