import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Observable, combineLatest, debounceTime, map } from "rxjs";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { deepDistinctUntilChanged, observeInput } from "dku-frontend-core";
import { markAllAsDirty } from "@utils/form-controls";
import { BINNING_MODES } from "@features/eda/worksheet/cards/config/binning-config/binning-config.component";
import { BinningMode, SchemaColumn, SplitBySpec, Variable } from "generated-sources";

/**
 * Local data types and helpers
 */

const NO_SPLIT = {
    name: "No split" as const,
    type: null,
};

type SelectableColumn =
    { name: string, type: string } |
    typeof NO_SPLIT;

function asSelectable(column: SchemaColumn): SelectableColumn {
    return { name: column.name, type: column.type };
}

function makeSelectableColumns(columns: SchemaColumn[]): SelectableColumn[] {
    const all_columns: SelectableColumn[] = columns.map(asSelectable);
    return [ NO_SPLIT, ...all_columns ];
}

function trackSelectableColumn(column: SelectableColumn): string {
    // derive a unique key to identify the selected column
    return column === NO_SPLIT ? "nosplit" : `split:${column.name}`;
}

/**
 * Form values typing
 */

type FormValue = {
    selection: SelectableColumn,
    selectionType: Variable.Type,
    groupAll: boolean,
    groupOthers: boolean,
    binningMode: BinningMode,
    maxValues?: number | null, // undefined when the control is disabled, can be manually set to null (invalid)
    customBoundaries?: number[], // undefined when then control is disabled
};

const MAX_VALUES_VALIDATORS = [
    Validators.required,
    Validators.min(1),
];

const FORM_DEFAULTS = {
    selection: NO_SPLIT,
    selectionType: Variable.Type.CATEGORICAL,
    groupAll: false,
    groupOthers: false,
    binningMode: BinningMode.AUTO,
    maxValues: 5,
    customBoundaries: {
        value: [],
        disabled: true, // disabled because default binning mode is AUTO
    },
};

/**
 * The main component
 */

@Component({
    selector: "recipe-split-by-editor",
    templateUrl: "./recipe-split-by-editor.component.html",
    styleUrls: [
        '../../shared-styles/forms.less',
        "./recipe-split-by-editor.component.less",
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipeSplitByEditorComponent {

    @Input() columns: SchemaColumn[];
    @Input() splitBy: SplitBySpec | null;

    @Output() splitByChange = new EventEmitter<SplitBySpec | null>();
    @Output() validityChange = new EventEmitter<boolean>();

    destroyRef = inject(DestroyRef);

    configForm: UntypedFormGroup;
    selectableColumns$: Observable<SelectableColumn[]>;

    trackSelectableColumn = trackSelectableColumn;
    BINNING_MODES = BINNING_MODES;
    COLUMN_TYPES = [
        { label: "Categorical", key: Variable.Type.CATEGORICAL },
        { label: "Continuous", key: Variable.Type.CONTINUOUS },
    ];

    private columns$: Observable<SchemaColumn[]> = observeInput(this, "columns");
    private splitBy$: Observable<SplitBySpec | null> = observeInput(this, "splitBy");

    constructor(
        fb: UntypedFormBuilder,
    ) {
        this.configForm = fb.group({
            selection: fb.control(FORM_DEFAULTS.selection, Validators.required),
            selectionType: fb.control(FORM_DEFAULTS.selectionType, Validators.required),
            groupAll: fb.control(FORM_DEFAULTS.groupAll, Validators.required),
            groupOthers: fb.control(FORM_DEFAULTS.groupOthers, Validators.required),
            binningMode: fb.control(FORM_DEFAULTS.binningMode, Validators.required),
            maxValues: fb.control(FORM_DEFAULTS.maxValues, MAX_VALUES_VALIDATORS),
            customBoundaries: fb.control(FORM_DEFAULTS.customBoundaries, Validators.required),
        });

        combineLatest([
            this.configForm.controls.selectionType.valueChanges,
            this.configForm.controls.binningMode.valueChanges,
        ]).pipe(
            deepDistinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(([type, mode]) => {
            this.toggleControls(type, mode);
        });

        this.configForm.valueChanges.pipe(
            // multiple events can be fired rapidly (when patching, enabling controls...)
            // -> we only care about the last one
            debounceTime(100),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((form: FormValue) => {
            this.handleFormChanges(form);
        });

        this.selectableColumns$ = this.columns$.pipe(
            map(columns => makeSelectableColumns(columns)),
        );

        combineLatest([this.splitBy$, this.selectableColumns$]).pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(([splitBy, selectableColumns]) => {
            this.patchConfigForm(splitBy, selectableColumns);
        });
    }

    get uiData() {
        const { selection, selectionType, binningMode } = this.configForm.value as FormValue;

        return {
            isSplitByEnabled: selection !== NO_SPLIT,
            isCategorical: selectionType === Variable.Type.CATEGORICAL,
            isAutoBinning: binningMode === BinningMode.AUTO,
            isFixedNumberBinning: binningMode === BinningMode.FIXED_NB,
            isCustomBinning: binningMode === BinningMode.CUSTOM,
        };
    }

    private toggleControls(type: Variable.Type, mode: BinningMode): void {
        const { maxValues, customBoundaries } = this.configForm.controls;

        if (type === Variable.Type.CONTINUOUS && mode === BinningMode.CUSTOM) {
            if (maxValues.enabled) maxValues.disable();
            if (customBoundaries.disabled) customBoundaries.enable();

        } else {
            if (maxValues.disabled) maxValues.enable();
            if (customBoundaries.enabled) customBoundaries.disable();
        }
    }

    private patchConfigForm(splitBy: SplitBySpec | null, selectableColumns: SelectableColumn[]): void {
        if (splitBy == null) {
            this.configForm.patchValue({ selection: NO_SPLIT });
            return;
        }

        // find the corresponding selectable column for the split-by
        const selected: SelectableColumn =
            selectableColumns.find(c => c.name === splitBy.groupingColumn.name) ??
            {
                name: splitBy.groupingColumn.name,
                type: ""
            };

        this.configForm.patchValue({
            selection: selected,
            selectionType: splitBy.groupingColumn.type,
            groupAll: splitBy.groupWithAll,
            groupOthers: splitBy.groupWithOthers,
            binningMode: splitBy.binningMode,
            maxValues: splitBy.maxValues,
            customBoundaries: splitBy.customBinningBoundaries,
        });

        // better looking invalid controls after init
        markAllAsDirty(this.configForm);
    }

    private handleFormChanges(form: FormValue): void {
        if (form.selection === NO_SPLIT) {
            this.splitByChange.emit(null);
            this.validityChange.emit(true);
            return;
        }

        const splitBy: SplitBySpec = {
            groupingColumn: {
                name: form.selection.name,
                type: form.selectionType,
            },
            groupWithAll: form.groupAll,
            groupWithOthers: form.groupOthers,
            binningMode: form.binningMode,
            maxValues: form.maxValues,
            customBinningBoundaries: form.customBoundaries ?? [],
        };

        this.splitByChange.emit(splitBy);
        this.validityChange.emit(this.configForm.valid);
    }
}
