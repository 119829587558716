import type { CardResult } from './card-result';
import type { Filter } from './../../compute/filtering/filter';
import type { ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { SplitBySpec } from './../models/split-by-spec';
import type { UnivariateCard } from './univariate-card';
import type { _CardResult } from './card-result';
import type { _Filter } from './../../compute/filtering/filter';
import type { _ImmutableValueObject } from './../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _SplitBySpec } from './../models/split-by-spec';
import type { _UnivariateCard } from './univariate-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.CDFPlotCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _CDFPlotCard extends _UnivariateCard {
    type: "cdf_plot";
    colorBy?: SplitBySpec | null;
    highlightFilter?: Filter | null;
}

export type CDFPlotCard = _CDFPlotCard;

export namespace CDFPlotCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.CDFPlotCard in PolyJSON mappings */
    export const type = "cdf_plot";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.CDFPlotCard$CDFItem
     * Via: com.dataiku.dip.eda.worksheets.cards.CDFPlotCard$CDFPlotCardResult
     */
    export interface _CDFItem extends _ImmutableValueObject {
        groupIndex: number;
        isHighlighted: boolean;
        probs: number[];
        xvals: number[];
    }
    
    export type CDFItem = _CDFItem;
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.CDFPlotCard$CDFPlotCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _CDFPlotCardResult extends _CardResult {
        type: "cdf_plot";
        cdfs: CDFPlotCard.CDFItem[];
        groups: Filter[];
    }
    
    export type CDFPlotCardResult = _CDFPlotCardResult;

    export namespace CDFPlotCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.CDFPlotCard$CDFPlotCardResult in PolyJSON mappings */
        export const type = "cdf_plot";
    }
}