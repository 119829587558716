import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { map, switchMap, distinctUntilChanged, startWith } from 'rxjs';
import { ProjectsCRUDController, AccessibleObjectsService, ITaggingService } from 'src/generated-sources';
import type { IScope } from 'angular';
import { fairAny, filterNonNull } from 'dku-frontend-core';
import { observeFormControl } from '@utils/form-control-observer';
import { WaitingService } from '@core/overlays/waiting.service';
import { APIError, catchAPIError, ErrorContext } from '@core/dataiku-api/api-error';

@UntilDestroy()
@Component({
    selector: 'duplicate-worksheet-dialog',
    templateUrl: './duplicate-worksheet-dialog.component.html',
    styleUrls: ['./duplicate-worksheet-dialog.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DuplicateWorksheetDialogComponent implements OnInit, ErrorContext {
    projects: ProjectsCRUDController.ProjectHead[] = [];
    datasets: AccessibleObjectsService.AccessibleObject[] = [];
    projectSelectParams: { noneSelectedText: 'No project selected' };
    form = this.fb.group({
        project: this.fb.control(null, [Validators.required]),
        dataset: this.fb.control(null, [Validators.required])
    });
    apiError?: APIError;
    ITaggingService = ITaggingService;

    constructor(
        private currentRoute: CurrentRouteService,
        private DataikuAPI: DataikuAPIService,
        private changeDetectionRef: ChangeDetectorRef,
        public dialogRef: MatDialogRef<DuplicateWorksheetDialogComponent>,
        @Inject('$state') private $state: fairAny,
        private waitingService: WaitingService,
        @Inject('$rootScope') private $rootScope: IScope,
        private fb: UntypedFormBuilder
    ) { }

    ngOnInit() {
        const originalProjectKey = this.currentRoute.projectKey;
        const originalDatasetName = this.currentRoute.datasetName;

        this.DataikuAPI.projects.listHeads('WRITE_CONF')
            .pipe(
                catchAPIError(this),
                untilDestroyed(this)
            )
            .subscribe((writableProjects) => {
                if (writableProjects && writableProjects.length) {
                    const currentProjectWritable = writableProjects.some(_ => _.projectKey === originalProjectKey);
                    const selectedProject = currentProjectWritable ? originalProjectKey : writableProjects[0].projectKey;

                    this.projects = writableProjects;

                    this.form.patchValue({
                        project: selectedProject
                    });

                    this.changeDetectionRef.markForCheck();
                }
            });

        observeFormControl(this.form.controls.project).pipe(
            filterNonNull(),
            distinctUntilChanged(),
            switchMap(projectKey => this.DataikuAPI.taggableObjects.listAccessibleObjects(projectKey, "DATASET", 'WRITE')
                .pipe(
                    this.waitingService.bindSpinner(),
                    startWith([]),
                    map(datasets => ({ projectKey, datasets })),
                    catchAPIError(this),
                )
            ),
            untilDestroyed(this)
        ).subscribe(({ projectKey, datasets }) => {
            if (projectKey === originalProjectKey) {
                this.form.patchValue({
                    dataset: datasets.find(d => d.id == originalDatasetName)
                });
            }
            this.datasets = datasets;
            this.changeDetectionRef.markForCheck();
        });
    }

    pushError(error: APIError) {
        this.apiError = error;
        this.changeDetectionRef.markForCheck();
    }

    importDataset(projectKey: string) {
        this.$state.go('projects.project.datasets.new', {
            projectKey
        });
    }

    confirm() {
        this.dialogRef.close({
            projectKey: this.form.controls.project.value,
            datasetName: this.form.controls.dataset.value.smartId,
        });
    }
}
