import type { AutoFeatureGenerationRecipePayloadParams } from './../autofeaturegeneration/auto-feature-generation-recipe-payload-params';
import type { ComputedColumn } from './../computedcolumn/computed-column';
import type { FilterDesc } from './../../../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type { InputDescBase } from './input-desc-base';
import type { JoinRecipePayloadParams } from './../join/join-recipe-payload-params';
import type { _ComputedColumn } from './../computedcolumn/computed-column';
import type { _FilterDesc } from './../../../../../../../../../dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
import type { _InputDescBase } from './input-desc-base';

/** 
 * Translated from class com.dataiku.dip.dataflow.exec.joinlike.JoinInputDescBase
 * Via: UIModel annotation in the class hierarchy
 */
export interface _JoinInputDescBase extends _InputDescBase {
    alias: string;
    computedColumns: ComputedColumn[];
    outputColumnsSelectionMode: JoinInputDescBase.OutputColumnsSelectionMode;
    preFilter: FilterDesc;
    prefix: string;
}

/** List of all concrete subclasses of com.dataiku.dip.dataflow.exec.joinlike.JoinInputDescBase */
export type JoinInputDescBase =
    | AutoFeatureGenerationRecipePayloadParams.InputDesc
    | JoinRecipePayloadParams.InputDesc
    | _JoinInputDescBase;

export namespace JoinInputDescBase {
    /** 
     * Translated from enum com.dataiku.dip.dataflow.exec.joinlike.JoinInputDescBase$OutputColumnsSelectionMode
     * Via: com.dataiku.dip.dataflow.exec.joinlike.JoinInputDescBase
     */
    export enum OutputColumnsSelectionMode {
        MANUAL = "MANUAL",
        ALL = "ALL",
        AUTO_NON_CONFLICTING = "AUTO_NON_CONFLICTING",
    }
}