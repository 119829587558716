export namespace MemColumn {
    /** 
     * Translated from enum com.dataiku.dip.datalayer.memimpl.MemColumn$MeaningOrigin
     * Via: com.dataiku.dip.shaker.server.SerializedMemTableV2$Header
     */
    export enum MeaningOrigin {
        INFERENCE = "INFERENCE",
        PROCESSOR = "PROCESSOR",
        USER = "USER",
    }
}