import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, OnInit, inject, DestroyRef } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { MiscOptions } from './misc-options.interface';

@Component({
    selector: 'misc-form',
    templateUrl: './misc-form.component.html',
    styleUrls: ['../forms.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiscFormComponent implements OnInit {
    @Output() miscOptionsChange = new EventEmitter<MiscOptions>();

    public displayCompatibleAxisForm$ = new BehaviorSubject<boolean>(false);
    public displayNumberOfRecordsForm$ = new BehaviorSubject<boolean>(false);
    public miscForm = new UntypedFormGroup({
        equalScales: new FormControl<boolean>(false),
        identityLine: new FormControl<boolean>(false),
        numberOfRecords: new FormControl<number | null>(100000)
    });

    private destroyRef = inject(DestroyRef);

    @Input()
    set displayCompatibleAxisForm(value: boolean) {
        this.displayCompatibleAxisForm$.next(!!value);
    }

    @Input()
    set displayNumberOfRecordsForm(value: boolean) {
        this.displayNumberOfRecordsForm$.next(!!value);
    }

    @Input()
    set miscOptions(value: MiscOptions | undefined) {
        if (value) {
            this.miscForm.patchValue(value);
        }
    }

    ngOnInit(): void {
        this.miscForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(miscOptions => {
            this.miscOptionsChange.emit(miscOptions);
        });
    }
}
