/** 
 * Translated from class com.dataiku.dip.datacollections.AbstractDataCollectionItemRef
 * Via: UIModel annotation in the class hierarchy
 */
export interface _AbstractDataCollectionItemRef {
}

/** List of all concrete subclasses of com.dataiku.dip.datacollections.AbstractDataCollectionItemRef */
export type AbstractDataCollectionItemRef = AbstractDataCollectionItemRef.DatasetRef;

export namespace AbstractDataCollectionItemRef {
    /** Types of class com.dataiku.dip.datacollections.AbstractDataCollectionItemRef in PolyJSON mappings */
    export const type = ["DATASET", ] as const;
    
    export function isAbstractDataCollectionItemRef(obj: AbstractDataCollectionItemRef): obj is AbstractDataCollectionItemRef { return ["DATASET", ].includes(obj["type"]); }
    export function isDatasetRef(obj: AbstractDataCollectionItemRef): obj is AbstractDataCollectionItemRef.DatasetRef { return obj["type"] == "DATASET"; }
    
    /** 
     * Translated from class com.dataiku.dip.datacollections.AbstractDataCollectionItemRef$DatasetRef
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _DatasetRef extends _AbstractDataCollectionItemRef {
        type: "DATASET";
        id: string;
        projectKey: string;
    }
    
    export type DatasetRef = _DatasetRef;

    export namespace DatasetRef {
        /** Type of class com.dataiku.dip.datacollections.AbstractDataCollectionItemRef$DatasetRef in PolyJSON mappings */
        export const type = "DATASET";
    }
}