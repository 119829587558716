import type { TimeSeriesCard } from './time-series-card';
import type { _TimeSeriesCard } from './time-series-card';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.DurbinWatsonCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _DurbinWatsonCard extends _TimeSeriesCard {
    type: "durbin_watson";
}

export type DurbinWatsonCard = _DurbinWatsonCard;

export namespace DurbinWatsonCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.DurbinWatsonCard in PolyJSON mappings */
    export const type = "durbin_watson";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.DurbinWatsonCard$DurbinWatsonCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _DurbinWatsonCardResult extends TimeSeriesCard._TimeSeriesCardResult {
        type: "durbin_watson";
        statistic: number;
    }
    
    export type DurbinWatsonCardResult = _DurbinWatsonCardResult;

    export namespace DurbinWatsonCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.DurbinWatsonCard$DurbinWatsonCardResult in PolyJSON mappings */
        export const type = "durbin_watson";
    }
}