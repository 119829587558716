import { Inject, Injectable } from "@angular/core";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { CurrentRouteService } from "@core/nav/current-route.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { fairAny } from "dku-frontend-core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AnyLoc, type Run, RunMetric, RunParam, RunTag, type Model, Experiment } from 'src/generated-sources';
import { DKU_EXT_CLASS_NAMES, DKU_EXT_CODE_ENV, DKU_EXT_EXPERIMENT_NAME, DKU_EXT_PREDICTION_TYPE, DKU_EXT_TAGS_KEY_PREFIX, DKU_EXT_TARGET, DKU_EXT_DSS_USER, DKU_EXT_DSS_GIT_COMMIT, DKU_EXT_DSS_GIT_BRANCH, DKU_EXT_DSS_GIT_MESSAGE, DKU_EXT_TAG_ORIGIN, DKU_EXT_TAG_FULL_MODEL_ID, DKU_EXT_TAG_ANALYSIS_ID, DKU_EXT_TAG_MLTASK_TYPE, DKU_EXT_TAG_DISPLAY_NAME, DKU_EXT_TAG_KEPT_EPOCH, DKU_EXT_TAG_KEPT_EPOCH_TIMESTAMP } from './custom-keys';
import { ETBreadcrumbItem } from "./experiment-tracking-header/experiment-tracking-header.component";
import { MLFLOW_SYSTEM_TAGS_KEY_PREFIX, MLFLOW_SYSTEM_TAGS_RUN_NAME } from './mlflow-system-keys';

export class MetricValue {
    step: number;
    timestamp: number;
    value: number | null | undefined;
    invalidValue: string | null | undefined;
    runId: string;
    runName: string;
}

export class MetricInfo {
    serie: MetricValue[];
    keptEpochValue: number | undefined;
    public displaySteps = false;
    public hasInvalidValue = false;

    constructor(public key: string) {
        this.serie = [];
    }

    pushValue(step: number, timestamp: number, value: number | null | undefined, invalidValue: string | null | undefined, runId: string, runName: string) {
        if (invalidValue) {
            this.hasInvalidValue = true;
        }
        this.serie.push(
            {
                step,
                timestamp,
                value: invalidValue ? null : value,
                invalidValue,
                runId,
                runName
            }
        );
    }

    pushMetricInfo(metricInfo: MetricInfo | undefined) {
        if (!metricInfo) {
            return;
        }
        for (const metricValue of metricInfo.serie) {
            this.pushValue(metricValue.step, metricValue.timestamp, metricValue.value, metricValue.invalidValue, metricValue.runId, metricValue.runName);
        }
    }

    setKeptEpochValue(value: number | undefined) {
        this.keptEpochValue = value;
    }

    get lastValue(): number | null | undefined {
        if(this.keptEpochValue) {
            return this.keptEpochValue;
        }
        return this.serie[this.serie.length - 1].value;
    }

    get lastInvalidValue(): string | null | undefined {
        return this.serie[this.serie.length - 1].invalidValue;
    }

    get serieLength(): number {
        return this.serie.length;
    }

    get isMultiStep(): boolean {
        return this.serieLength > 1;
    }
}

export interface RunViewModel extends Run {
    keptEpoch: number | undefined;
    keptEpochTimestamp: number | undefined;
    experimentName: string | undefined;
    deletedTooltip: string | undefined;
    metrics: Map<string, MetricInfo>;
    params: Map<string, any>;
    models: Map<string, any>;
    tags: Map<string, any>;
    dssUser: string;
    dssGitCommit: string;
    dssGitCommitHref: string;
    dssGitBranch: string;
    dssGitMessage: string;
    origin: string | undefined;
    analysisId: string | undefined;
    fullModelId: string | undefined;
    taskType: string | undefined;
    analysisModelHref: string | undefined;
    systemTags: Map<string, any>;
    runNameId: string;
    runId: string;
    href?: string;
    classes: string[],
    predictionType: string,
    codeEnvName: string,
    target: string
    artifactHref?: string;
    artifactInfo: ArtifactInfo;
}

export interface ManagedFolderInfo {
    projectKey: string;
    loc: AnyLoc;
    managedFolder: any;
}

export interface ArtifactInfo {
    uri: string;
    subfolder: string;
    managedFolderName: string;
}

export interface ModelArtifactInfo extends Model {
    artifactHref: string
}

@Injectable({
    providedIn: 'root'
})
@UntilDestroy()
export class ExperimentTrackingService {

    constructor(
        private DataikuAPI: DataikuAPIService,
        @Inject('$state') private $state: fairAny,
        @Inject('StateUtils') private stateUtils: fairAny,
        @Inject('FullModelLikeIdUtils') private fmiUtils: fairAny,
        private currentRouterService: CurrentRouteService,
    ) {
    }

    mapRun(run: Run): RunViewModel {
        const runViewModel: RunViewModel = run as RunViewModel;

        runViewModel.metrics = new Map();
        runViewModel.params = new Map();
        runViewModel.models = new Map();
        runViewModel.tags = new Map();
        runViewModel.systemTags = new Map();
        runViewModel.runId = run.info.runId;

        const experimentIds = this.experimentIds || [];
        runViewModel.href = this.getRunIdHref(run.info.runId, experimentIds, this.viewAllExperiments, this.viewAllRuns);
        runViewModel.artifactHref = this.getRunIdArtifactHref(run.info.runId, experimentIds, this.viewAllExperiments, this.viewAllRuns);
        runViewModel.artifactInfo = <ArtifactInfo>{
            uri: run.info.artifactUri,
            subfolder: this.getArtifactSubfolder(run),
            managedFolderName: run.managedFolderName
        };

        runViewModel.deletedTooltip = (run.info.lifecycleStage === "deleted") ? "Deleted" : undefined;
        if (run.data) {
            run.data.runParams
                .forEach((param: RunParam) => {
                    runViewModel.params.set(param.key, param.value);
                });
            run.data.models
                .forEach((model: Model) => {
                    const modelArtifactInfo = model as ModelArtifactInfo;
                    modelArtifactInfo.artifactHref = this.getRunIdArtifactHref(model.runId, experimentIds, this.viewAllExperiments, this.viewAllRuns, model.artifactPath);
                    // The artifact path is unique across a run
                    runViewModel.models.set(model.artifactPath, modelArtifactInfo);
                });

            run.data.runTags
                .forEach((tag: RunTag) => {
                    const tagKey = tag.key;

                    // MLFLOW System tags starts with reserved keyword mlflow.
                    if (tagKey.startsWith(MLFLOW_SYSTEM_TAGS_KEY_PREFIX)) {
                        runViewModel.systemTags.set(tagKey, tag.value);
                        if (tagKey == MLFLOW_SYSTEM_TAGS_KEY_PREFIX + MLFLOW_SYSTEM_TAGS_RUN_NAME) {
                            runViewModel.runNameId = `${tag.value} (${run.info.runId})`;
                        }
                    } else if (tagKey.startsWith(DKU_EXT_TAGS_KEY_PREFIX)) {
                        if (tagKey === DKU_EXT_EXPERIMENT_NAME) {
                            runViewModel.experimentName = tag.value;
                        } else if (tagKey === DKU_EXT_CLASS_NAMES) {
                            runViewModel.classes = JSON.parse(tag.value);
                        } else if (tagKey === DKU_EXT_PREDICTION_TYPE) {
                            runViewModel.predictionType = tag.value;
                        } else if (tagKey === DKU_EXT_CODE_ENV) {
                            runViewModel.codeEnvName = tag.value;
                        } else if (tagKey === DKU_EXT_TARGET) {
                            runViewModel.target = tag.value;
                        } else if (tagKey === DKU_EXT_DSS_USER) {
                            runViewModel.dssUser = tag.value;
                        } else if (tagKey === DKU_EXT_DSS_GIT_COMMIT) {
                            runViewModel.dssGitCommit = tag.value;
                        } else if (tagKey === DKU_EXT_DSS_GIT_MESSAGE) {
                            runViewModel.dssGitMessage = tag.value;
                        } else if (tagKey === DKU_EXT_DSS_GIT_BRANCH) {
                            runViewModel.dssGitBranch = tag.value;
                        } else if (tagKey === DKU_EXT_TAG_ORIGIN) {
                            runViewModel.origin = tag.value;
                        } else if (tagKey === DKU_EXT_TAG_FULL_MODEL_ID) {
                            runViewModel.fullModelId = tag.value;
                        }  else if (tagKey === DKU_EXT_TAG_ANALYSIS_ID) {
                            runViewModel.analysisId = tag.value;
                        } else if (tagKey === DKU_EXT_TAG_MLTASK_TYPE) {
                            runViewModel.taskType = tag.value;
                        } else if (tagKey === DKU_EXT_TAG_DISPLAY_NAME) {
                            runViewModel.runNameId = tag.value;
                        } else if (tagKey === DKU_EXT_TAG_KEPT_EPOCH) {
                            runViewModel.keptEpoch = parseInt(tag.value);
                        } else if (tagKey === DKU_EXT_TAG_KEPT_EPOCH_TIMESTAMP) {
                            runViewModel.keptEpochTimestamp = parseInt(tag.value);
                        }
                    } else {
                        runViewModel.tags.set(tagKey, tag.value);
                    }
                });
            if (!runViewModel.runNameId) {
                runViewModel.runNameId = run.info.runId;
            }
            if (runViewModel.fullModelId && runViewModel.taskType) {
                runViewModel.analysisModelHref = this.stateUtils.href.fullModelLikeId(runViewModel.fullModelId, runViewModel.taskType);
            }
            run.data.metrics
                .forEach((metric: RunMetric) => {
                    if (!runViewModel.metrics.has(metric.key)) {
                        runViewModel.metrics.set(metric.key, new MetricInfo(metric.key));
                    }
                    runViewModel.metrics.get(metric.key)?.pushValue(metric.step, metric.timestamp, metric.value, metric.invalidValue, runViewModel.runId, runViewModel.runNameId);
                });
            if(runViewModel.origin == "analysis" && runViewModel.keptEpoch) {
                // Analysis model exported to Experiment Tracking with steps/epochs should have their "kept epoch value" displayed for evaluation metrics to be consistent with the lab UI
                runViewModel.metrics.forEach((metric: MetricInfo) => {
                    let values = metric.serie.map((v: MetricValue) => v.value).map(n => n === null || n === undefined ? 0 : n);
                    if(values.length > runViewModel.keptEpoch!) {
                        const bestVal = values[runViewModel.keptEpoch!];
                        metric.setKeptEpochValue(bestVal);
                    }
                    
                });
            }
        }

        if (runViewModel.dssGitCommit && runViewModel.dssGitBranch) {
            runViewModel.dssGitCommitHref = this.getRunCommitHref(runViewModel.dssGitCommit, runViewModel.dssGitBranch);
        }

        return runViewModel;
    }

    mapRuns(runs: Run[]): RunViewModel[] {
        return runs.map((run) => this.mapRun(run));
    }

    getTags(run: RunViewModel): RunTag[] {
        return run?.data?.runTags.filter((runTag: RunTag) => run.tags.has(runTag.key)) || [];
    }

    getSystemTags(run: RunViewModel): RunTag[] {
        return run?.data?.runTags.filter((runTag: RunTag) => run.systemTags.has(runTag.key)) || [];
    }

    getExperimentTrackingHref(viewAllExperiments: boolean): string {
        return this.$state.href('projects.project.experiment-tracking.list', {
            projectKey: this.projectKey,
            viewAllExperiments
        });
    }

    goToExperimentTracking(viewAllExperiments: string, reload: boolean = false, location: boolean | 'replace' = true): string {
        return this.$state.go('projects.project.experiment-tracking.list', {
            projectKey: this.projectKey,
            viewAllExperiments
        }, { reload, location });
    }

    getRunIdHref(runId: string, experimentIds: string[], viewAllExperiments: boolean, viewAllRuns: boolean): string {
        return this.$state.href('projects.project.experiment-tracking.run-details', {
            projectKey: this.projectKey,
            runId,
            experimentIds: (experimentIds.length) ? experimentIds.join(',') : undefined,
            viewAllExperiments,
            viewAllRuns
        });
    }

    getRunIdArtifactHref(runId: string, experimentIds: string[], viewAllExperiments: boolean, viewAllRuns: boolean, subfolder: string = ''): string {
        return this.$state.href('projects.project.experiment-tracking.run-artifacts', {
            projectKey: this.projectKey,
            runId,
            experimentIds: (experimentIds.length) ? experimentIds.join(',') : undefined,
            subfolder: subfolder || undefined,
            viewAllExperiments,
            viewAllRuns
        });
    }

    getRunCommitHref(commitId: string, branch: string): string {
        return this.$state.href('projects.project.version-control', {
            projectKey: this.projectKey,
            commitId,
            branch
        });
    }

    goToRunId(runId: string, experimentIds: string[], viewAllExperiments: boolean, viewAllRuns: boolean): void {
        this.$state.go('projects.project.experiment-tracking.run-details', {
            projectKey: this.projectKey,
            runId,
            experimentIds: (experimentIds.length) ? experimentIds.join(',') : undefined,
            viewAllExperiments,
            viewAllRuns
        });
    }

    goToArtifacts(runId: string, experimentIds: string[], viewAllExperiments: boolean, viewAllRuns: boolean, subFolder: string): void {
        this.$state.go('projects.project.experiment-tracking.run-artifacts', {
            projectKey: this.projectKey,
            runId,
            experimentIds: (experimentIds.length) ? experimentIds.join(',') : undefined,
            subFolder: subFolder || undefined,
            viewAllExperiments,
            viewAllRuns
        });
    }

    getRunListHref(experimentIds: string[], viewAllExperiments: boolean, viewAllRuns: boolean): string {
        return this.$state.href('projects.project.experiment-tracking.runs-list', {
            projectKey: this.projectKey,
            experimentIds: (experimentIds.length) ? experimentIds.join(',') : undefined,
            viewAllExperiments,
            viewAllRuns
        });
    }

    getArtifactSubfolder(run: Run): string {
        let subfolder = "";

        try {
            const [loc, resolvedSubfolder] = this.resolveRunArtifactUri(run);
            subfolder = resolvedSubfolder;
        } catch (error) {
            console.warn(error);
        }

        return subfolder;
    }

    resolveSmartName(contextProjectKey: string, managedFolderSmartId: string): AnyLoc {
        const chunks = managedFolderSmartId.split('.');
        if (chunks.length === 1) {
            return { projectKey: contextProjectKey, id: chunks[0] };
        } else if (chunks.length === 2) {
            return { projectKey: chunks[0], id: chunks[1] };
        }

        throw new Error('Invalid smart name: ' + managedFolderSmartId);
    }

    getArtifactUri(run: Run): (string | undefined) {
        return run.info?.artifactUri;
    }

    getRunArtifactUriChunks(run: Run): string[] {
        const uri = this.getArtifactUri(run)?.replace(/^dss-managed-folder:[/]*/, '');
        if (!uri) {
            throw new Error(`Empty artifact URI.`);
        }
        const pathChunks = uri.split('/');
        if (pathChunks.length < 2) {
            throw new Error(`Invalid artifact URI: ${uri}`);
        }

        return pathChunks;
    }

    resolveRunArtifactUri(run: Run): [AnyLoc, string] {
        const pathChunks = this.getRunArtifactUriChunks(run);
        const managedFolderSmartId = pathChunks[0];
        const subFolder = pathChunks.slice(1).join('/');
        return [this.resolveSmartName(this.projectKey, managedFolderSmartId), subFolder];
    }

    goToRunList(experimentIds: string[], viewAllExperiments: boolean, viewAllRuns: boolean, reload: boolean = false, location: boolean | 'replace' = true): void {
        this.$state.go('projects.project.experiment-tracking.runs-list', {
            projectKey: this.projectKey,
            experimentIds: (experimentIds.length) ? experimentIds.join(',') : undefined,
            viewAllExperiments,
            viewAllRuns
        }, { reload, location });
    }

    goToCreateExperimentsDataset(experimentIds: string[], viewAll: boolean): void {
        const prefillParams:any = {
            viewType: viewAll ? "ALL" : "ACTIVE_ONLY"
        };
        const joinedExperimentIds = experimentIds.join(',');
        if (joinedExperimentIds) {
            prefillParams.experimentIds = joinedExperimentIds;
        }
        this.$state.go('projects.project.datasets.new_with_type.settings', {
            type: 'ExperimentsDB',
            prefillParams: JSON.stringify(prefillParams)
        });
    }

    getRunsListBreadcrumb(): Observable<ETBreadcrumbItem> {
        let runsListBreadcrumbItem$: Observable<ETBreadcrumbItem>;

        const experimentIds = this.experimentIds;
        const runsListHref = this.currentUrl;

        if (experimentIds.length == 1) {
            runsListBreadcrumbItem$ = this.DataikuAPI.experiments.getExperiment(this.projectKey, experimentIds[0])
                .pipe(
                    untilDestroyed(this),
                    map((experiment: Experiment): ETBreadcrumbItem => {
                        return <ETBreadcrumbItem>{
                            displayName: experiment.name || experiment.id,
                            href: runsListHref
                        };
                    }));
        } else {
            runsListBreadcrumbItem$ = of(<ETBreadcrumbItem>{
                displayName: `${experimentIds.length} Experiments selected`,
                href: runsListHref
            });
        }

        return runsListBreadcrumbItem$;
    }

    getRunsListBreadcrumbFromRun(run$: Observable<RunViewModel>): Observable<ETBreadcrumbItem> {
        return run$.
            pipe(
                untilDestroyed(this),
                map((run: RunViewModel): ETBreadcrumbItem => {
                    let viewAllExperiments;
                    let viewAllRuns;
                    let experimentIds;
                    if (this.experimentIds) {
                        viewAllExperiments = this.viewAllExperiments;
                        viewAllRuns = this.viewAllRuns;
                        experimentIds = this.experimentIds;
                    } else {
                        const viewAll = run.info.lifecycleStage === "deleted";
                        viewAllExperiments = viewAll;
                        viewAllRuns = viewAll;
                        experimentIds = [run.info.experimentId];
                    }
                    let displayName = `${experimentIds.length} Experiments selected`;

                    if (experimentIds.length == 1) {
                        displayName = run.experimentName || run.info.experimentId;
                    }

                    return <ETBreadcrumbItem>{
                        displayName,
                        href: this.getRunListHref(experimentIds, viewAllExperiments, viewAllRuns)
                    };
                }));
    }

    getRunNameBreadcrumb(run$: Observable<RunViewModel>): Observable<ETBreadcrumbItem> {
        return run$.
            pipe(
                untilDestroyed(this),
                map((run: RunViewModel): ETBreadcrumbItem => {
                    return <ETBreadcrumbItem>{
                        displayName: run.runNameId,
                        href: this.currentUrl
                    };
                }));
    }

    get runId() {
        return this.currentRouterService.runId;
    }

    get projectKey() {
        return this.currentRouterService.projectKey;
    }

    get experimentIds() {
        return this.currentRouterService.experimentIds;
    }

    get viewAllExperiments(): boolean {
        return (/true/i).test(this.currentRouterService.viewAllExperiments);
    }

    get viewAllRuns(): boolean {
        return (/true/i).test(this.currentRouterService.viewAllRuns);
    }

    get subfolder() {
        return this.currentRouterService.subfolder;
    }

    get currentUrl(): string {
        return this.$state.href(this.$state.current.name, this.$state.params);
    }

    get stateName(): string {
        return this.$state.current.name;
    }
}
