import type { Card } from './card';
import type { CardResult } from './card-result';
import type { _Card } from './card';
import type { _CardResult } from './card-result';

/** 
 * Translated from class com.dataiku.dip.eda.worksheets.cards.PlaygroundCard
 * Via: UIModel annotation in the class hierarchy
 */
export interface _PlaygroundCard extends _Card {
    type: "playground";
    rawComputation: string;
}

export type PlaygroundCard = _PlaygroundCard;

export namespace PlaygroundCard {
    /** Type of class com.dataiku.dip.eda.worksheets.cards.PlaygroundCard in PolyJSON mappings */
    export const type = "playground";
    
    /** 
     * Translated from class com.dataiku.dip.eda.worksheets.cards.PlaygroundCard$PlaygroundCardResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _PlaygroundCardResult extends _CardResult {
        type: "playground";
        errorMessage?: string | null;
        rawResult?: string | null;
    }
    
    export type PlaygroundCardResult = _PlaygroundCardResult;

    export namespace PlaygroundCardResult {
        /** Type of class com.dataiku.dip.eda.worksheets.cards.PlaygroundCard$PlaygroundCardResult in PolyJSON mappings */
        export const type = "playground";
    }
}