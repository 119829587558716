import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { PredictionMLTask } from 'src/generated-sources';
import { ModalShape, ModalsService } from '@shared/modals/modals.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FeedImageModalParams } from '@shared/components/feed-image-modal/feed-image-modal.component';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { DeephubDesignDataFetcherService } from '../services/deephub-design-data-fetcher.service';
import { ImageFeedModalComponent } from '@shared/components/image-feed-modal/image-feed-modal.component';

@UntilDestroy()
@Component({
    selector: 'deephub-design-image-feed',
    templateUrl: './deephub-design-image-feed.component.html',
    styleUrls: ['./deephub-design-image-feed.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeephubDesignImageFeedComponent implements OnInit, OnChanges {
    @Input() mltask: PredictionMLTask.DeepHubPredictionMLTask;
    @Input() selectedCategories?: string[];
    // Explicitly pass pathColumn and managedFolderSmartId to trigger change from Angular
    @Input() pathColumn: string;
    @Input() managedFolderSmartId: string;

    categoriesList: string[];

    form = this.fb.group({
        selectedCategories: new UntypedFormControl(undefined, [Validators.required])
    })

    public constructor(
        private currentRoute: CurrentRouteService,
        private modalsService: ModalsService,
        private dataFetcher: DeephubDesignDataFetcherService,
        private viewContainerRef: ViewContainerRef,
        private fb: UntypedFormBuilder,
        private colorMapService: ColorMapContextService,
        @Inject('COLOR_PALETTES') private COLOR_PALETTES: { [palette: string]: string[]; }
    ) {}

    ngOnInit(): void {
        this.categoriesList = this.mltask.preprocessing.target_remapping.map(v => v.sourceValue);
        this.colorMapService.setMapping(this.categoriesList, this.COLOR_PALETTES.highContrast);

        this.form.get('selectedCategories')!.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe(
            values => {
                this.dataFetcher.setFilteredCategories(values);
            }
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataFetcher.setMLTaskInfo(this.currentRoute.projectKey, this.currentRoute.analysisId, this.mltask);

        if (changes.selectedCategories?.currentValue) {
            this.form.patchValue({
                selectedCategories: this.selectedCategories
            })
        }
    }

    openModal({ imageId }: FeedImageModalParams) {
        this.modalsService
            .open(ImageFeedModalComponent, {
                imageId,
             }, ModalShape.FULL, this.viewContainerRef)
            .then(() => {}, () => {});
    }
}

