/** 
 * Translated from enum com.dataiku.dip.security.auth.UserSourceType
 * Via: UIModel annotation in the class hierarchy
 */
export enum UserSourceType {
    LOCAL = "LOCAL",
    LOCAL_NO_AUTH = "LOCAL_NO_AUTH",
    LDAP = "LDAP",
    SAAS = "SAAS",
    AZURE_AD = "AZURE_AD",
    PAM = "PAM",
    CUSTOM = "CUSTOM",
}