import type { AvailableResult } from './../available-result';
import type { CompactedArray } from './../../utils/compacted-array';
import type { Computation } from './../computation';
import type { Distribution2D } from './../../distributions/distribution2-d';
import type { ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';
import type { _AvailableResult } from './../available-result';
import type { _CompactedArray } from './../../utils/compacted-array';
import type { _Distribution2D } from './../../distributions/distribution2-d';
import type { _ImmutableValueObject } from './../../../../../../../../../../dku-core/src/main/java/com/dataiku/dip/utils/immutable-value-object';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Fit2DDistribution extends Computation._BivariateComputation {
    type: "fit_2d_distribution";
    distribution: Distribution2D;
    xResolution: number;
    yResolution: number;
}

export type Fit2DDistribution = _Fit2DDistribution;

export namespace Fit2DDistribution {
    /** Type of class com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution in PolyJSON mappings */
    export const type = "fit_2d_distribution";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution$Density
     * Via: com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution$Fit2DDistributionResult
     */
    export interface _Density extends _ImmutableValueObject {
        data: CompactedArray;
        xMax: number;
        xMin: number;
        yMax: number;
        yMin: number;
    }
    
    export type Density = _Density;
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution$Fit2DDistributionResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _Fit2DDistributionResult extends _AvailableResult {
        type: "fit_2d_distribution";
        density: Fit2DDistribution.Density;
        model: Distribution2D.FittedDistribution2D;
    }
    
    export type Fit2DDistributionResult = _Fit2DDistributionResult;

    export namespace Fit2DDistributionResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution$Fit2DDistributionResult in PolyJSON mappings */
        export const type = "fit_2d_distribution";
    }
}