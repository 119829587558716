import { FutureResponse, SerializedError } from 'dku-frontend-core';
import { Dict, fairAny, Nullable } from 'dku-frontend-core';
import { EnrichedPromise } from '@core/dataiku-api/utils/enriched-promise';
import { BackendResponse } from '@core/requests/dku-legacy-http.service';
import { PublicUser } from 'generated-sources';
import { TransitionData } from '@shared/components/dialogs/legacy-dialogs.service';

// This file groups all the Providers (in particular for Services) defined in AngularJS but used in Angular
// If possible, move those providers to Angular and downgrade them to keep them accessible in AngularJS if necessary

//
// ** WARNING **
//
// Need to export factories for prod compilation (make sure ng buid --prod works if you change this)

export function stateUtilsFactory($injector: { get: Function }) {
    return $injector.get('StateUtils');
}

const stateUtilsProvider = {
    provide: 'StateUtils',
    useFactory: stateUtilsFactory,
    deps: ['$injector']
};

// -----------------

export function APIXHRServiceFactory($injector: { get: Function }) {
    return $injector.get('APIXHRService');
}

const APIXHRServiceProvider = {
    provide: 'APIXHRService',
    useFactory: APIXHRServiceFactory,
    deps: ['$injector']
};

// -----------------

export function DataikuAPIFactory($injector: { get: Function }) {
    return $injector.get('DataikuAPI');
}

const DataikuAPIProvider = {
    provide: 'DataikuAPI',
    useFactory: DataikuAPIFactory,
    deps: ['$injector']
};

// -----------------

export function GraphZoomTrackerServiceFactory($injector: { get: Function }) {
    return $injector.get('GraphZoomTrackerService');
}

const GraphZoomTrackerServiceProvider = {
    provide: 'GraphZoomTrackerService',
    useFactory: GraphZoomTrackerServiceFactory,
    deps: ['$injector']
};


// -----------------

export function DatasetUtilsFactory($injector: { get: Function }) {
    return $injector.get('DatasetUtils');
}

const DatasetUtilsProvider = {
    provide: 'DatasetUtils',
    useFactory: DatasetUtilsFactory,
    deps: ['$injector']
};

// -----------------

export function CachedAPICallsFactory($injector: { get: Function }) {
    return $injector.get('CachedAPICalls');
}

const CachedAPICallsProvider = {
    provide: 'CachedAPICalls',
    useFactory: CachedAPICallsFactory,
    deps: ['$injector']
};

// -----------------

export function FutureWatcherFactory($injector: { get: Function }) {
    return $injector.get('FutureWatcher');
}

const FutureWatcherProvider = {
    provide: 'FutureWatcher',
    useFactory: FutureWatcherFactory,
    deps: ['$injector']
};
export interface FutureWatcherType {
    watchJobId(jobId: string): EnrichedPromise<BackendResponse<FutureResponse>>;
}

// -----------------

export function FutureProgressModalFactory($injector: { get: Function }) {
    return $injector.get('FutureProgressModal');
}

const FutureProgressModalProvider = {
    provide: 'FutureProgressModal',
    useFactory: FutureProgressModalFactory,
    deps: ['$injector']
};
export interface FutureProgressModalType {
    showPeekOnlyIfRunning(parentScope: fairAny, jobId: string, modalTitle: string): Promise<BackendResponse<FutureResponse>>;
    show(parentScope: fairAny, data: fairAny, modalTitle: string): fairAny;
}

// -----------------

export function FutureWaitingFactory($injector: { get: Function }) {
    return $injector.get('futureWaiting');
}

const FutureWaitingProvider = {
    provide: 'futureWaiting',
    useFactory: FutureWaitingFactory,
    deps: ['$injector', 'DataikuAPI']
};

// -----------------

export function ProgressStackMessageBuilderFactory($injector: { get: Function }) {
    return $injector.get('ProgressStackMessageBuilder');
}

const ProgressStackMessageBuilderProvider = {
    provide: 'ProgressStackMessageBuilder',
    useFactory: ProgressStackMessageBuilderFactory,
    deps: ['$injector',]
};

// -----------------

export function DKUConstantsFactory($injector: { get: Function }) {
    return $injector.get('DKUConstants');
}

const DKUConstantsProvider = {
    provide: 'DKUConstants',
    useFactory: DKUConstantsFactory,
    deps: ['$injector']
};

// -----------------

export function LegacyDialogsFactory($injector: { get: Function }) {
    return $injector.get('Dialogs');
}

const LegacyDialogsProvider = {
    provide: 'legacyDialogs',
    useFactory: LegacyDialogsFactory,
    deps: ['$injector', 'CreateModalFromTemplate', '$state', 'DKUConstants']
};

export interface LegacyDialogs {
    displaySerializedError($scope: any, e: SerializedError): void;
    checkChangesBeforeLeaving($scope: any, dirty: (data: TransitionData) => boolean, message?: string, allowedTransitions?: string[] | TransitionData): void;
    infoMessagesDisplayOnly($scope: fairAny, title: string, data: fairAny): EnrichedPromise<BackendResponse<FutureResponse>>;
}
// -----------------

export function $stateFactory($injector: { get: Function }) {
    return $injector.get('$state');
}

const $stateProvider = {
    provide: '$state',
    useFactory: $stateFactory,
    deps: ['$injector']
};
export type $stateType = { current: { name: string, url: string }, go: Function, href: Function, params: Dict<string> };

// -----------------

export function $locationFactory($injector: { get: Function }) {
    return $injector.get('$location');
}

const $locationProvider = {
    provide: '$location',
    useFactory: $locationFactory,
    deps: ['$injector']
};

// -----------------

export function $stateParamsFactory($injector: { get: Function }) {
    return $injector.get('$stateParams');
}

const $stateParamsProvider = {
    provide: '$stateParams',
    useFactory: $stateParamsFactory,
    deps: ['$injector']
};

// -----------------

export function StringUtilsFactory($injector: { get: Function }) {
    return $injector.get('StringUtils');
}

const StringUtilsProvider = {
    provide: 'StringUtils',
    useFactory: StringUtilsFactory,
    deps: ['$injector']
};

// -----------------

export function TopNavFactory($injector: { get: Function }) {
    return $injector.get('TopNav');
}

const TopNavProvider = {
    provide: 'TopNav',
    useFactory: TopNavFactory,
    deps: ['$injector']
};

// -----------------

export function WT1Factory($injector: { get: Function }) {
    return $injector.get('WT1');
}

const WT1Provider = {
    provide: 'WT1',
    useFactory: WT1Factory,
    deps: ['$injector']
};

// -----------------

export function $dkuSanitizeFactory($injector: { get: Function }) {
    return $injector.get('$dkuSanitize');
}

const $dkuSanitizeProvider = {
    provide: '$dkuSanitize',
    useFactory: $dkuSanitizeFactory,
    deps: ['$injector']
};

// -----------------

export function ThrottleFactory($injector: { get: Function }) {
    return $injector.get('Throttle');
}

const ThrottleProvider = {
    provide: 'Throttle',
    useFactory: ThrottleFactory,
    deps: ['$injector']
};

// -----------------

export function $rootScopeFactory($injector: { get: Function }) {
    return $injector.get('$rootScope');
}

const $rootScopeProvider = {
    provide: '$rootScope',
    useFactory: $rootScopeFactory,
    deps: ['$injector']
};

// -----------------

export function ExportUtilsFactory($injector: { get: Function }) {
    return $injector.get('ExportUtils');
}

const ExportUtilsProvider = {
    provide: 'ExportUtils',
    useFactory: ExportUtilsFactory,
    deps: ['$injector']
};

// -----------------

export function ExpressionsFactory($injector: { get: Function }) {
    return $injector.get('Expressions');
}

const ExpressionsProvider = {
    provide: 'Expressions',
    useFactory: ExpressionsFactory,
    deps: ['$injector']
};
// -----------------

export function UpgradedFiltersFactory($injector: { get: Function }) {
    return $injector.get('UpgradedFilters');
}

const UpgradedFiltersProvider = {
    provide: 'UpgradedFilters',
    useFactory: UpgradedFiltersFactory,
    deps: ['$injector']
};

// -----------------

export function localStorageServiceFactory($injector: { get: Function }) {
    return $injector.get('localStorageService');
}

const localStorageServiceProvider = {
    provide: 'localStorageService',
    useFactory: localStorageServiceFactory,
    deps: ['$injector']
};

// -----------------

export function SamplingDataFactory($injector: { get: Function }) {
    return $injector.get('SamplingData');
}

const SamplingDataProvider = {
    provide: 'SamplingData',
    useFactory: SamplingDataFactory,
    deps: ['$injector']
};

// -----------------

export function LoggerFactory($injector: { get: Function }) {
    return $injector.get('Logger');
}

const LoggerProvider = {
    provide: 'Logger',
    useFactory: LoggerFactory,
    deps: ['$injector']
};

// -----------------

export function CreateModalFromTemplateFactory($injector: { get: Function }) {
    return $injector.get('CreateModalFromTemplate');
}

const CreateModalFromTemplateProvider = {
    provide: 'CreateModalFromTemplate',
    useFactory: CreateModalFromTemplateFactory,
    deps: ['$injector']
};

// -----------------

export function SpinnerServiceFactory($injector: { get: Function }) {
    return $injector.get('SpinnerService');
}

const SpinnerServiceProvider = {
    provide: 'SpinnerService',
    useFactory: SpinnerServiceFactory,
    deps: ['$injector']
};

// -----------------

export function ColorPaletteFactory($injector: { get: Function }) {
    return $injector.get('COLOR_PALETTES');
}

const ColorPaletteFactoryProvider = {
    provide: 'COLOR_PALETTES',
    useFactory: ColorPaletteFactory,
    deps: ['$injector']
};

// -----------------

export function UserImageUrlFactory($injector: { get: Function }) {
    return $injector.get('UserImageUrl');
}

const UserImageUrlProvider = {
    provide: 'UserImageUrl',
    useFactory: UserImageUrlFactory,
    deps: ['$injector']
};

// -----------------

export function PluginsServiceFactory($injector: { get: Function }) {
    return $injector.get('PluginsService');
}

const PluginsServiceProvider = {
    provide: 'PluginsService',
    useFactory: PluginsServiceFactory,
    deps: ['$injector']
};

// -----------------

export function RecipeDescServiceFactory($injector: { get: Function }) {
    return $injector.get('RecipeDescService');
}

const RecipeDescServiceProvider = {
    provide: 'RecipeDescService',
    useFactory: RecipeDescServiceFactory,
    deps: ['$injector']
};


// -----------------

export function DashboardUtilsFactory($injector: { get: Function }) {
    return $injector.get('DashboardUtils');
}

const DashboardUtilsProvider = {
    provide: 'DashboardUtils',
    useFactory: DashboardUtilsFactory,
    deps: ['$injector']
};

// -----------------

export function TypeMappingServiceFactory($injector: { get: Function }) {
    return $injector.get('TypeMappingService');
}

const TypeMappingServiceProvider = {
    provide: 'TypeMappingService',
    useFactory: TypeMappingServiceFactory,
    deps: ['$injector']
};

// -----------------

export function ATSurveyService($injector: { get: Function }) {
    return $injector.get('ATSurveyService');
}

const ATSurveyServiceProvider = {
    provide: 'ATSurveyService',
    useFactory: ATSurveyService,
    deps: ['$injector']
};


// -----------------

export function NavigatorFactory($injector: { get: Function }) {
    return $injector.get('Navigator');
}

const NavigatorProvider = {
    provide: 'Navigator',
    useFactory: NavigatorFactory,
    deps: ['$injector']
};

// -----------------

export function TaggingServiceFactory($injector: { get: Function }) {
    return $injector.get('TaggingService');
}

const TaggingServiceProvider = {
    provide: 'TaggingService',
    useFactory: TaggingServiceFactory,
    deps: ['$injector']
};

// -----------------

export function ColumnTypeConstantsFactory($injector: { get: Function }) {
    return $injector.get('ColumnTypeConstants');
}

const ColumnTypeConstantsProvider = {
    provide: 'ColumnTypeConstants',
    useFactory: ColumnTypeConstantsFactory,
    deps: ['$injector']
};

// -----------------

export function ExposedObjectsServiceFactory($injector: { get: Function }) {
    return $injector.get('ExposedObjectsService');
}

const ExposedObjectsServiceProvider = {
    provide: 'ExposedObjectsService',
    useFactory: ExposedObjectsServiceFactory,
    deps: ['$injector']
};

// -----------------

export function CatalogRefreshUtilsFactory($injector: { get: Function }) {
    return $injector.get('CatalogRefreshUtils');
}

const CatalogRefreshUtilsProvider = {
    provide: 'CatalogRefreshUtils',
    useFactory: CatalogRefreshUtilsFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function ChartIconUtilsFactory($injector: { get: Function }) {
    return $injector.get('ChartIconUtils');
}

const ChartIconUtilsProvider = {
    provide: 'ChartIconUtils',
    useFactory: ChartIconUtilsFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function ChartFormattingOptionsFactory($injector: { get: Function }) {
    return $injector.get('CHART_FORMATTING_OPTIONS');
}

const ChartFormattingOptionsProvider = {
    provide: 'CHART_FORMATTING_OPTIONS',
    useFactory: ChartFormattingOptionsFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function ChartStoreFactoryFactory($injector: { get: Function }) {
    return $injector.get('ChartStoreFactory');
}

const ChartStoreFactoryProvider = {
    provide: 'ChartStoreFactory',
    useFactory: ChartStoreFactoryFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function ChartFormattingFactory($injector: { get: Function }) {
    return $injector.get('ChartFormatting');
}

const ChartFormattingProvider = {
    provide: 'ChartFormatting',
    useFactory: ChartFormattingFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function ChartDimensionFactory($injector: { get: Function }) {
    return $injector.get('ChartDimension');
}

const ChartDimensionProvider = {
    provide: 'ChartDimension',
    useFactory: ChartDimensionFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function ChartUADimensionFactory($injector: { get: Function }) {
    return $injector.get('ChartUADimension');
}

const ChartUADimensionProvider = {
    provide: 'ChartUADimension',
    useFactory: ChartUADimensionFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function ChartAxesUtilsFactory($injector: { get: Function }) {
    return $injector.get('ChartAxesUtils');
}

const ChartAxesUtilsProvider = {
    provide: 'ChartAxesUtils',
    useFactory: ChartAxesUtilsFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function D3ChartAxesFactory($injector: { get: Function }) {
    return $injector.get('D3ChartAxes');
}

const D3ChartAxesProvider = {
    provide: 'D3ChartAxes',
    useFactory: D3ChartAxesFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function NumberFormatterFactory($injector: { get: Function }) {
    return $injector.get('NumberFormatter');
}

const NumberFormatterProvider = {
    provide: 'NumberFormatter',
    useFactory: NumberFormatterFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function HierarchicalChartsUtilsFactory($injector: { get: Function }) {
    return $injector.get('HierarchicalChartsUtils');
}

const HierarchicalChartsUtilsProvider = {
    provide: 'HierarchicalChartsUtils',
    useFactory: HierarchicalChartsUtilsFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function ChartColorScalesFactory($injector: { get: Function }) {
    return $injector.get('ChartColorScales');
}

const ChartColorScalesProvider = {
    provide: 'ChartColorScales',
    useFactory: ChartColorScalesFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function ChartLegendUtilsFactory($injector: { get: Function }) {
    return $injector.get('ChartLegendUtils');
}

const ChartLegendUtilsProvider = {
    provide: 'ChartLegendUtils',
    useFactory: ChartLegendUtilsFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function ChartDataUtilsFactory($injector: { get: Function }) {
    return $injector.get('ChartDataUtils');
}

const ChartDataUtilsProvider = {
    provide: 'ChartDataUtils',
    useFactory: ChartDataUtilsFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function ChartTypeChangeUtilsFactory($injector: { get: Function }) {
    return $injector.get('ChartTypeChangeUtils');
}

const ChartTypeChangeUtilsProvider = {
    provide: 'ChartTypeChangeUtils',
    useFactory: ChartTypeChangeUtilsFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function ChartColorUtilsFactory($injector: { get: Function }) {
    return $injector.get('ChartColorUtils');
}

const ChartColorUtilsProvider = {
    provide: 'ChartColorUtils',
    useFactory: ChartColorUtilsFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function ChartFeaturesFactory($injector: { get: Function }) {
    return $injector.get('ChartFeatures');
}

const ChartFeaturesProvider = {
    provide: 'ChartFeatures',
    useFactory: ChartFeaturesFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function ChartTooltipsFactory($injector: { get: Function }) {
    return $injector.get('ChartTooltips');
}

const ChartTooltipsProvider = {
    provide: 'ChartTooltips',
    useFactory: ChartTooltipsFactory,
    deps: ['$injector']
};

// -----------------

// -----------------

export function ActivityIndicatorFactory($injector: { get: Function }) {
    return $injector.get('ActivityIndicator');
}

const ActivityIndicatorProvider = {
    provide: 'ActivityIndicator',
    useFactory: ActivityIndicatorFactory,
    deps: ['$injector']
};

// -----------------

export function DatasetDetailsUtilsFactory($injector: { get: Function }) {
    return $injector.get('DatasetDetailsUtils');
}

const DatasetDetailsUtilsProvider = {
    provide: 'DatasetDetailsUtils',
    useFactory: DatasetDetailsUtilsFactory,
    deps: ['$injector']
};

// -----------------

export function PMLSettingsFactory($injector: { get: Function }) {
    return $injector.get('PMLSettings');
}

const PMLSettingsProvider = {
    provide: 'PMLSettings',
    useFactory: PMLSettingsFactory,
    deps: ['$injector']
};

// -----------------

export function ActivityIndicatorManagerFactory($injector: { get: Function }) {
    return $injector.get('ActivityIndicatorManager');
}

const ActivityIndicatorManagerProvider = {
    provide: 'ActivityIndicatorManager',
    useFactory: ActivityIndicatorManagerFactory,
    deps: ['$injector']
};

// -----------------

export function AnimatedChartsUtilsFactory($injector: { get: Function }) {
    return $injector.get('AnimatedChartsUtils');
}

const AnimatedChartsUtilsProvider = {
    provide: 'AnimatedChartsUtils',
    useFactory: AnimatedChartsUtilsFactory,
    deps: ['$injector']
};

// -----------------

export function ExportServiceFactory($injector: { get: Function }) {
    return $injector.get('ExportService');
}

const ExportServiceProvider = {
    provide: 'ExportService',
    useFactory: ExportServiceFactory,
    deps: ['$injector']
};

// -----------------

export function OpalsServiceFactory($injector: { get: Function }) {
    return $injector.get('OpalsService');
}

const OpalsServiceProvider = {
    provide: 'OpalsService',
    useFactory: OpalsServiceFactory,
    deps: ['$injector']
};

// -----------------

export function ConnectionExplorationServiceFactory($injector: { get: Function }) {
    return $injector.get('ConnectionExplorationService');
}

const ConnectionExplorationServiceProvider = {
    provide: 'ConnectionExplorationService',
    useFactory: ConnectionExplorationServiceFactory,
    deps: ['$injector']
};

// -----------------

export function ChartRequestComputerFactory($injector: { get: Function }) {
    return $injector.get('ChartRequestComputer');
}

const ChartRequestComputerProvider = {
    provide: 'ChartRequestComputer',
    useFactory: ChartRequestComputerFactory,
    deps: ['$injector']
};

// -----------------

export function FilterFacetsServiceFactory($injector: { get: Function }) {
    return $injector.get('FilterFacetsService');
}

const FilterFacetsServiceProvider = {
    provide: 'FilterFacetsService',
    useFactory: FilterFacetsServiceFactory,
    deps: ['$injector']
};

// -----------------

export function EditDatasetDataStewardModalService($injector: { get: Function }) {
    return $injector.get('EditDatasetDataStewardModalService');
}

export interface EditDatasetDataStewardModalService {
    showEditDataStewardModal(parentScope: fairAny, projectKey: string, datasetId: string, dataSteward: Nullable<PublicUser>, wt1Context: Record<string, string>): Promise<Nullable<PublicUser>>;
}

const EditDatasetDataStewardModalProvider = {
    provide: 'EditDatasetDataStewardModalService',
    useFactory: EditDatasetDataStewardModalService,
    deps: ['$injector']
};

// -----------------

export function fullModelLikeIdUtilsFactory($injector: { get: Function }) {
    return $injector.get('FullModelLikeIdUtils');
}

const FullModelLikeIdUtilsProvider = {
    provide: 'FullModelLikeIdUtils',
    useFactory: fullModelLikeIdUtilsFactory,
    deps: ['$injector']
};

// -----------------

export function modelDataUtilsFactory($injector: { get: Function }) {
    return $injector.get('ModelDataUtils');
}

const ModelDataUtilsProvider = {
    provide: 'ModelDataUtils',
    useFactory: modelDataUtilsFactory,
    deps: ['$injector']
};

// -----------------

export function ClipboardUtilsFactory($injector: { get: Function }) {
    return $injector.get('ClipboardUtils');
}

const ClipboardUtilsProvider = {
    provide: 'ClipboardUtils',
    useFactory: ClipboardUtilsFactory,
    deps: ['$injector']
};

// -----------------

export function DetectUtilsFactory($injector: { get: Function }) {
    return $injector.get('DetectUtils');
}

const DetectUtilsProvider = {
    provide: 'DetectUtils',
    useFactory: DetectUtilsFactory,
    deps: ['$injector']
};

// -----------------

export function ChartsAvailableTypesFactory($injector: { get: Function }) {
    return $injector.get('ChartsAvailableTypes');
}

const ChartsAvailableTypesProvider = {
    provide: 'ChartsAvailableTypes',
    useFactory: ChartsAvailableTypesFactory,
    deps: ['$injector']
};

// -----------------

export function ChartTypeChangeHandlerFactory($injector: { get: Function }) {
    return $injector.get('ChartTypeChangeHandler');
}

const ChartTypeChangeHandlerProvider = {
    provide: 'ChartTypeChangeHandler',
    useFactory: ChartTypeChangeHandlerFactory,
    deps: ['$injector']
};


// -----------------
export function upgradedProviders() {
    return [
        // Long term imports, to be removed when we totally remove AngularJS
        $stateProvider,
        $locationProvider,
        $stateParamsProvider,

        // Services kept here until they are moved to Angular
        stateUtilsProvider,
        APIXHRServiceProvider,
        DataikuAPIProvider,
        DatasetUtilsProvider,
        TopNavProvider,
        WT1Provider,
        FutureWatcherProvider,
        FutureProgressModalProvider,
        FutureWaitingProvider,
        ProgressStackMessageBuilderProvider,
        ThrottleProvider,
        $rootScopeProvider,
        CachedAPICallsProvider,
        $dkuSanitizeProvider,
        ExportUtilsProvider,
        UpgradedFiltersProvider,
        localStorageServiceProvider,
        GraphZoomTrackerServiceProvider,
        LoggerProvider,
        CreateModalFromTemplateProvider,
        SpinnerServiceProvider,
        DKUConstantsProvider,
        LegacyDialogsProvider,
        StringUtilsProvider,
        ExpressionsProvider,
        SamplingDataProvider,
        ColorPaletteFactoryProvider,
        UserImageUrlProvider,
        PluginsServiceProvider,
        RecipeDescServiceProvider,
        DashboardUtilsProvider,
        TypeMappingServiceProvider,
        ATSurveyServiceProvider,
        NavigatorProvider,
        TaggingServiceProvider,
        ColumnTypeConstantsProvider,
        ExposedObjectsServiceProvider,
        CatalogRefreshUtilsProvider,
        ChartIconUtilsProvider,
        ChartFormattingOptionsProvider,
        ChartStoreFactoryProvider,
        ChartFormattingProvider,
        ChartDimensionProvider,
        ChartUADimensionProvider,
        ChartAxesUtilsProvider,
        D3ChartAxesProvider,
        NumberFormatterProvider,
        HierarchicalChartsUtilsProvider,
        ChartColorScalesProvider,
        ChartLegendUtilsProvider,
        ChartDataUtilsProvider,
        ChartTypeChangeUtilsProvider,
        ChartColorUtilsProvider,
        DatasetDetailsUtilsProvider,
        ChartFeaturesProvider,
        ChartTooltipsProvider,
        ActivityIndicatorProvider,
        ActivityIndicatorManagerProvider,
        AnimatedChartsUtilsProvider,
        PMLSettingsProvider,
        ExportServiceProvider,
        OpalsServiceProvider,
        ConnectionExplorationServiceProvider,
        ChartRequestComputerProvider,
        FilterFacetsServiceProvider,
        EditDatasetDataStewardModalProvider,
        FullModelLikeIdUtilsProvider,
        ModelDataUtilsProvider,
        ClipboardUtilsProvider,
        ChartsAvailableTypesProvider,
        DetectUtilsProvider,
        ChartTypeChangeHandlerProvider
    ];
}
