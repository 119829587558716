import type { AvailableResult } from './../available-result';
import type { Computation } from './../computation';
import type { _AvailableResult } from './../available-result';

/** 
 * Translated from class com.dataiku.dip.eda.compute.computations.univariate.Sum
 * Via: UIModel annotation in the class hierarchy
 */
export interface _Sum extends Computation._UnivariateComputation {
    type: "sum";
    confidence?: number | null;
}

export type Sum = _Sum;

export namespace Sum {
    /** Type of class com.dataiku.dip.eda.compute.computations.univariate.Sum in PolyJSON mappings */
    export const type = "sum";
    
    /** 
     * Translated from class com.dataiku.dip.eda.compute.computations.univariate.Sum$SumResult
     * Via: UIModel annotation in the class hierarchy
     */
    export interface _SumResult extends _AvailableResult {
        type: "sum";
        lower?: number | null;
        upper?: number | null;
        value: number;
    }
    
    export type SumResult = _SumResult;

    export namespace SumResult {
        /** Type of class com.dataiku.dip.eda.compute.computations.univariate.Sum$SumResult in PolyJSON mappings */
        export const type = "sum";
    }
}