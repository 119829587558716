import type { CodeRecipeParams } from './code-recipe-params';
import type { SparkOverrideConfig } from './../spark/spark-override-config';
import type { _CodeRecipeParams } from './code-recipe-params';
import type { _SparkOverrideConfig } from './../spark/spark-override-config';

/** 
 * Translated from class com.dataiku.dip.recipes.AbstractSparkRecipeParams
 * Via: com.dataiku.dip.recipes.code.sparksql.SparkSQLExecutor$SparkSQLExecutorParams
 */
export interface _AbstractSparkRecipeParams extends _CodeRecipeParams {
    executionEngine: AbstractSparkRecipeParams.SparkExecutionEngine;
    pipelineAllowMerge: boolean;
    pipelineAllowStart: boolean;
    sparkConfig: SparkOverrideConfig;
}

/**
    Translate from class com.dataiku.dip.recipes.AbstractSparkRecipeParams
    @deprecated Translation is incomplete: annotate it with UIModel to generate a union of all concrete subclasses
*/
export type AbstractSparkRecipeParams = _AbstractSparkRecipeParams;

export namespace AbstractSparkRecipeParams {
    /** 
     * Translated from enum com.dataiku.dip.recipes.AbstractSparkRecipeParams$SparkExecutionEngine
     * Via: com.dataiku.dip.analysis.ml.MLSparkParams
     */
    export enum SparkExecutionEngine {
        SPARK_SUBMIT = "SPARK_SUBMIT",
        LIVY_BATCH = "LIVY_BATCH",
        LIVY_SESSION = "LIVY_SESSION",
        DATABRICKS = "DATABRICKS",
    }
}