import { Inject, Injectable } from '@angular/core';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { ChartIconUtils } from '@shared/models/chart-icon-utils.model';
import { fairAny, WT1Service } from 'dku-frontend-core';

@Injectable({
    providedIn: 'root'
})
export class ChartConfigurationCopyPasteService {
    private webApps = [];

    private static INVALID_CHART_ERROR = 'Invalid chart pasted. Please verify that the chart was copied correctly.';
    private static INVALID_VERSION_ERROR = 'Pasted chart is from a different DSS version. Please paste a chart from a matching version.';

    constructor(
        @Inject('ClipboardUtils') private clipboardUtilsService: any,
        @Inject('$rootScope') private $rootScope: fairAny,
        @Inject('ChartIconUtils') private chartIconUtils: ChartIconUtils,
        @Inject('ChartsAvailableTypes') private chartsAvailableTypes: any,
        @Inject('CreateModalFromTemplate') private CreateModalFromTemplate: fairAny,
        @Inject('ChartTypeChangeHandler') private chartTypeChangeHandler: fairAny,
        private wt1Service: WT1Service
    ) {}

    public pasteChartFromClipboard(parameters: { valid: boolean, isInInsight: boolean }, stringData: ChartDef | undefined) {
        return new Promise<{ chartDef: any, pasteAfter: boolean }>((resolve, reject) => {
            const newScope = this.$rootScope.$new();
            newScope.data = stringData;
            newScope.version = this.$rootScope.appConfig.version.product_version;
            newScope.defaultNewChart = this.chartTypeChangeHandler.defaultNewChart();
            return this.CreateModalFromTemplate('/templates/chart-config-copy-paste-modal.html', newScope, 'PasteModalController', (modalScope: fairAny) => {
                const buildPropertiesSummary = (data: any) => {
                    if (data && data.chart && data.chart.length) {
                        const def = {
                            ...modalScope.defaultNewChart,
                            ...data.chart[0]
                        };

                        const webApp: any = this.webApps.find((w: any) => w.variant === def.variant && w.type === def.type);
                        const chartType = this.chartsAvailableTypes.getAvailableChartTypes().find((c: any) => c.variant === def.variant && c.type === def.type);
                        const displayName = chartType && chartType.displayName || webApp && webApp.displayName;
                        const imgSrc = this.chartIconUtils.computeChartIcon(def.type, def.variant, false, webApp && webApp.webappType)
                        const res = {
                            chart: def,
                            version: data.version,
                            canReplace: parameters.valid,
                            canPasteAfter: !parameters.isInInsight && parameters.valid,
                            items: [
                                `${webApp ? `<i class='chart-type-icon-paste-modal ${imgSrc}'></i>` : `<img class='chart-type-icon-paste-modal' src='${imgSrc}'/>`}
                                    <span>1 <strong>${ displayName }</strong> configuration was found in the clipboard.</span>`
                            ]
                        };

                        return {
                            ...res,
                            subText: !res.canReplace ?
                                'You can paste the chart.' :
                                (res.canPasteAfter ? 'You can replace the current chart or paste after it.' : 'You can replace the current chart.')
                        };

                    } else {
                        throw Error('No valid chart configuration was found');
                    }
                };

                const onPasteText = (data: string) => {
                    try {
                        const { chart, items, subText, canReplace, canPasteAfter, version } = buildPropertiesSummary(JSON.parse(data));
                        if (version !== modalScope.version) {
                            modalScope.uiState.hasError = true;
                            modalScope.uiState.errorMessage = ChartConfigurationCopyPasteService.INVALID_VERSION_ERROR;
                        } else {
                            modalScope.uiState = { items, subText, canReplace, canPasteAfter };
                            modalScope.uiState.chart = chart;
                            modalScope.uiState.editMode = false;
                            modalScope.uiState.hasError = false;
                        }
                    } catch (error) {
                        modalScope.uiState.hasError = true;
                        modalScope.uiState.errorMessage = ChartConfigurationCopyPasteService.INVALID_CHART_ERROR;
                        reject(error);
                    }
                };

                if (modalScope.data) {
                    onPasteText(modalScope.data);
                } else {
                    try {
                        navigator.clipboard.readText().then(data => onPasteText(data));
                    } catch(e) {}
                }

                modalScope.onPasteText = (event: any) => onPasteText(event.originalEvent.clipboardData.getData('text/plain'));
                modalScope.pasteItems = (_: any, data: { replace: boolean, pasteAfter: boolean }) => {
                    this.wt1Service.event(
                        'chart-paste-action', 
                        { 
                            replace: data.replace, 
                            pasteAfter: data.pasteAfter 
                        });
                    resolve({ chartDef: { def: modalScope.uiState.chart }, pasteAfter: data.pasteAfter });
                };
            });
  
        });
    }

    public copyChartToClipboard(chartDef: ChartDef, version: string) {
        this.clipboardUtilsService.copyToClipboard(JSON.stringify( { chart: [chartDef], version }));
    }
    
    public setWebApps(webApps: []): void {
        this.webApps = webApps;
    }
}
